import React, { Component } from 'react';
import DatePicker from './DatePicker';
import LayoutMenu from './LayoutMenu';

class Toolbar extends Component {
    render() {
        return (
            <div className="hub-rmUtil-toolbar">
                <LayoutMenu selectLayout={this.props.selectLayout} layout={this.props.layout} editLayoutModal={this.props.editLayoutModal} newLayoutModal={this.props.newLayoutModal} />
                <DatePicker dateRange={this.props.dateRange} setDate={this.props.setDate} />
            </div>
        );
    }
}

export default Toolbar;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class DetailItem extends Component {
    render() {
        return (
            <div className="detailItem" style={{paddingLeft:(this.props.level?this.props.level*18:0)}}>
                <div className="icon"> <FontAwesomeIcon size="sm" color={"#AAB7CC"} icon={this.props.icon} /> </div>
                <div className="label"> {this.props.label} </div>
            </div>
        );
    }
}

export default DetailItem;
import React, { Component } from 'react'
import MainButton from '../../../../common/buttons/MainButton'
import TextArea from '../../../../common/inputControls/textArea2/textArea'
import TextField from '../../../../common/inputControls/textField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCircle } from '@fortawesome/pro-light-svg-icons'
import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons'
export default class toDoNewInput extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         value:"",
         isCompleted:false,
         Status:"Loading",
         isDisabled:true,
      }
    }

    toggleValue=(a,b)=>{
        //console.log(a)
        //console.log(b)
        this.setState({...this.state,value:b})
    }
    componentDidMount(){
        this.setState({...this.state,
          value:this.props.todo?.name!==undefined?this.props.todo?.name:"", 
          Status:"Load",
          isCompleted:this.props.todo?.isCompleted
        })
    }


    //method,Id,object,taskIndex,ColumnIndex, todoIndex
    crud=(method)=>{
        //console.log(method)
        let toDo={uid:this.props.todo?.uid,  orderNumber: this.props.todo?.orderNumber,name:this.state.value, isCompleted:this.state.isCompleted ,taskId:{uid:this.props.task.uid}}
         this.props.crud(method!=="DELETE"?this.props.method:method,this.props.todo?.uid,toDo,this.props.columnIndex,this.props.taskIndex,this.props.index)

         if(this.props.method==="POST")
         {
            this.setState({...this.state,value:"",isCompleted:false})
         }

         if(this.props.method==="PATCH" && !this.state.isDisabled )
         {
            this.setState({...this.state,isDisabled:true})
         }
        // 
    }

    handleKeyDown=(e)=> {
      // console.log("handleKeyDown");
       if (e.key === 'Enter' || e.key==="NumpadEnter") {
        //this.props.crud(this.props.method,this.state.value,this.props.changeArea,this.props.todo?.uid,this.props.todo)
        //this.setState({...this.state,value:""})
        this.crud(this.props.method)
        //this.toggleIsDisabled()
       }
   }

   setIsCompleted=()=>
   {
    this.setState({...this.state,isCompleted:!this.state.isCompleted},
      
      ()=>{  if(this.props.method!=="POST"){this.crud(this.props.method)} }
        )
   }

     
   toggleIsDisabled = ()=>{
    this.setState({...this.state,isDisabled:!this.state.isDisabled})
}
   

   
  render() {
   // let t=this.props.todo;

   
    return (
        this.state.Status==="Load"?
          <div className='card-to-do-wrapper'>
              <FontAwesomeIcon 
                title="Is Completed" 
                icon={this.state.isCompleted?faCircleCheck:faCircleCheck} style={(this.state.isCompleted?{height:20, marginRight:10, cursor:"pointer", "--fa-primary-color": "#ffffff", "--fa-secondary-color": "#005eff", "--fa-secondary-opacity": "1",}:{height:20, marginRight:10, color:"#D2DEF2", cursor:"pointer"})} 
                onClick={this.setIsCompleted}
              />
              <div className='new-to-do-description-wrapper'>
          
                  {!this.state.isDisabled || this.props.method==="POST"?
                    <div className='new-to-do' onDoubleClick={this.toggleIsDisabled}>
                      <TextField style={{width:"100%"}} onChange={this.toggleValue} onKeyDown={this.handleKeyDown} changeArea={this.props.changeArea} value={this.state.value} className={"to-do-input"}/>
                    </div>
                    :
                    <div className='new-to-do-description' onDoubleClick={this.toggleIsDisabled}>{this.state.value}</div>
                    }
             
             </div>
          </div>:false
    )
  }
}

import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../../auth/Auth.js";
import StatusScreen from "../../../common/statusScreen/statusScreen.js";

import BodyArea from "../../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../../common/sideBarPageContent/pageArea/pageArea.js";

import TextField from "../../../common/inputControls/textField";

import PercentField from "../../../common/inputControls/percentField";

import { DateUtils } from "./../../../common/Commons";
import { Utils } from "./../../../common/Commons";
import DateArea from "../../../common/inputControls/dateArea/index.js";
import DynamicLookup from "../../../common/inputControls/lookup/DynamicLookup.js";
import ButtonArea from "../../../common/sideBarPageContent/buttonArea/buttonArea.js";
import MainButton from "../../../common/buttons/MainButton.js";

class NewActivity extends Component {
  constructor() {
    super();

    this.du = new DateUtils();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        name: "",
        startDate: "",
        finishDate: "",
        extType: "",
        wbsId: "",
      },

      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
        extType: { message: "", error: false, warning: false },

        wbsId: { message: "", error: false, warning: false },
      },

      requiredFields: [
        { id: "name", label: "Name" },
        { id: "extType", label: "Milestone Type" },
        { id: "wbsId", label: "WBS" },
      ],
    };
  }

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesExtType = (e, val) => {
    //console.log("handleChangesExtType",e,val)
    let items = { ...this.state.formData };

    let isMilestone = val.name.includes("Milestone");
    let errors = { ...this.state.errorMessages };
    if (isMilestone) {
      items.finishDate = items.startDate;

      errors.finishDate.message = "Finish Date synced to Start Date";
      errors.finishDate.error = false;
      errors.finishDate.warning = true;
    }

    items[e] = val.name;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesParentWbs = (e, val) => {
    console.log("handleChangesExtType", e, val);
    let items = { ...this.state.formData };

    items[e] = val.uid;

    let selectedRecord = { ...this.state.selectedRecord };

    selectedRecord.uid = val.uid;
    selectedRecord.name = val.name;

    if (this.state.formData.startDate === "") {
      items["startDate"] = val.startDate;
    }
    if (this.state.formData.finishDate === "") {
      items["finishDate"] = val.finishDate;
    }
    this.setState(
      {
        ...this.state,
        formData: items,
        selectedRecord: selectedRecord,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] > this.state.formData.finishDate) {
      errors[e].message = "Start Date can not be later than Finish Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;
      errors.finishDate.message = "";
      errors.finishDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] < this.state.formData.startDate) {
      errors[e].message = "Finish Date can not be earlier than Start Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.startDate.message = "";
      errors.startDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  componentDidMount() {
    //console.log("componentDidMount")
    // console.log(this.props)
    this.getData();
  }

  /*
    componentDidUpdate(prevProps) {

            // console.log("componentDidUpdate!!");

             //console.log(this.props);
          //
        if (prevProps.selectedRecord !== this.props.selectedRecord) {
            //console.log("componentDidUpdate!!");
          //  this.setState({data: this.props.user})
            //console.log(this.props.user);

            this.setState({Loading:true, Status:"Loading"})

           // this.GetDatas();
            
        }
           

      }
*/

  createFormData = (data) => {
    console.log("createFormData", data);
    let formdata = this.state.formData;

    formdata.startDate = data.startDate;
    formdata.finishDate = data.finishDate;
    formdata.name = "";
    formdata.wbsId = data.uid;
    formdata.extType = data.extType;
    //formdata.projectId=this.props?.project?.uid
    return formdata;
  };

  getData = () => {
    //console.log("getData",this.props.createType);

    if (this.props.createType === "") {
      this.setState({ ...this.state, Loading: false });
      return;
    } else {
      let record = this.props.selectedRecord;

      if (this.props.createType === "sibling") {
        //console.log("sibling girdik");
        record.uid = this.props.selectedRecord.parentWbs;
      }

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.check(),
        },
      };

      fetch(
        "/api/projects/" +
          this.props.selectedRecord.projectId +
          "/wbs/" +
          this.props.selectedRecord.uid,
        requestOptions
      )
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((r) => {
          //console.log(r);
          //console.log(this.props.selectedRecord);

          if (r.status === 200) {
            this.setState({
              ...this.state,
              Loading: false,
              Status: "Load",
              formData: this.createFormData(r.body.object[0]),
              selectedRecord: r.body.object[0],
            });

            //console.log(this.state.formData?.actuals)
            // console.log(this.state.period.open)
          } else if (r.status == 401) {
            window.location.replace("/login");
          } else if (r.status == 403) {
            this.setState({
              ...this.state,
              Status: "Error",
              LoadingError: true,
              LoadingErrorText:
                "You are not authorized to see this page. Please contact your system administrator.",
            });
          } else {
            this.setState({
              ...this.state,
              Status: "Error",
              LoadingError: true,
              LoadingErrorText:
                "An unexpected error occured. Please contact your system administrator.",
            });
          }
        });
    }
  };

  create = () => {
    // console.log(this.state.formData)
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(this.state.formData),
    };

    fetch("/api/activity", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(requestOptions);
        console.log("activity", r);
        if (r.status === 200) {
          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  create2 = () => {
    //console.log("create2",this.state.formData)

    let errors = { ...this.state.errorMessages };

    if (this.state.formData.name === "") {
      errors.name.message = "Name is required";
      errors.name.error = true;
      errors.name.warning = false;
    }
    if (this.state.formData.startDate === "") {
      errors.startDate.message = "Start Date is required";
      errors.startDate.error = true;
      errors.startDate.warning = false;
    }
    if (this.state.formData.finishDate === "") {
      errors.finishDate.message = "Finish Date is required";
      errors.finishDate.error = true;
      errors.finishDate.warning = false;
    }
    if (this.state.formData.extType === "") {
      errors.extType.message = "Activity Type is required";
      errors.extType.error = true;
      errors.extType.warning = false;
    }

    if (this.state.formData.wbsId === "") {
      errors.wbsId.message = "WBS is required";
      errors.wbsId.error = true;
      errors.wbsId.warning = false;
    }

    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      let form = this.state.formData;

      form.projectId = this.props.project.uid;
      this.props.save("post", "/api/activity", form, "middle");
    }
  };

  render() {
    //console.log("state",this.state)
    //console.log("props",this.props)
    let isMilestone = this.state.formData?.extType?.includes("Milestone");
    return !this.state.Loading ? (
      <PageArea>
        <BodyArea>
          <DynamicLookup
            endpoint={
              "/api/projects/" + this.props.selectedRecord.projectId + "/wbs"
            }
            required={true}
            onChange={this.handleChangesParentWbs}
            value={{
              value: this.state.selectedRecord?.uid,
              label: this.state.selectedRecord?.name,
            }}
            formId="wbsId"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="100%"
            label="Parent WBS"
          />

          {/* <TextField width="100%" onChange= {this.handleChanges} changeArea="code" label="Activity Code" value={this.state.formData?.code} /> */}
          <TextField
            width="100%"
            onChange={this.handleChanges}
            changeArea="name"
            label="Name"
            value={this.state.formData?.name}
            required={true}
          />
          {/*<TextField width="100%" onChange= {this.handleChanges}  changeArea="Obs" label="IPT" value={this.state.formData?.obsInfo?.name} />  
                        <TextField width="100%" onChange= {this.handleChanges}  changeArea="responsible" label="Responsible IPT (WBS)" value={this.state.formData?.responsible?.fullName} />   
        */}
          <div className="input-grid">
            {" "}
            <DateArea
              width="100%"
              onChange={this.handleChangesStartDate}
              changeArea="startDate"
              label="Start Date"
              value={this.state.formData.startDate}
              required={true}
              errorMessage={this.state.errorMessages["startDate"].message}
              error={this.state?.errorMessages["startDate"]?.error}
              warning={this.state?.errorMessages["startDate"]?.warning}
            />
            <DateArea
              disabled={isMilestone ? true : false}
              width="100%"
              onChange={this.handleChangesFinishDate}
              changeArea="finishDate"
              label="Finish Date"
              value={this.state.formData.finishDate}
              required={true}
              errorMessage={this.state?.errorMessages["finishDate"]?.message}
              error={this.state?.errorMessages["finishDate"]?.error}
              warning={this.state?.errorMessages["finishDate"]?.warning}
            />
            <DynamicLookup
              endpoint={"/api/valuelist/code/activity_type"}
              required={true}
              onChange={this.handleChangesExtType}
              extraObject={"valueListOptions"}
              //value={{value:this.state.formData.owner?.id, label: this.state.formData.owner?.fullName }}
              formId="extType"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="100%"
              label="Activity Type"
            />{" "}
          </div>
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.create2}
            key="newTaskButton"
            className="button-area-action"
            label="Create"
          />
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default NewActivity;

import React, { Component } from "react";
import ButtonArea from "../../../common/sideBarPageContent/buttonArea/buttonArea.js";
import NoteArea from "../../../common/sideBarPageContent/noteArea/noteArea.js";
import BodyArea from "../../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../../common/sideBarPageContent/pageArea/pageArea.js";

import TextArea from "../../../common/inputControls/textArea2/textArea";
import Auth from "../../../auth/Auth";
import { useParams } from "react-router";
import NoteList from "../../../common/note/noteList/noteList";
class ChangeSetComments extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",
      abc: {},
      formData: {
        note: "",
        objectId: "",
        objectType: "ChangeSet",
      },

      note: {
        note: "sadsad",
        objectId: "",
        objectType: "ChangeSet",
      },
    };
  }

  componentDidMount() {
    // console.log("componentDidMount");
    // console.log(this.props);
    this.UpdateObjectID();
    this.GetDatas();
  }

  /*
          componentDidUpdate(prevProps) {

              if (prevProps.selectedRecord.uid !== this.props.selectedRecord.uid) {
                 // console.log("componentDidUpdate!!");
                //  this.setState({data: this.props.user})
                  //console.log(this.props.user);

                  this.setState({Loading:true, Status:"Loading"})
                   this.UpdateObjectID();
                  this.GetDatas();
                  
              }
                

            }

            */

  UpdateObjectID = () => {
    let items = { ...this.state.note };
    // console.log(items);
    items.objectId = this.props.params.uid;
    this.setState({
      ...this.state,
      note: items,
    });
    // console.log(this.state);
  };

  handleChanges = (e, val) => {
    /* console.log("handle changes");
              console.log(e);
              console.log(val);
              */
    let items2 = { ...this.state.note };

    items2[e] = val;

    this.setState(
      {
        ...this.state,
        note: items2,
      },
      () => this.CreateNote()
    );
  };

  GetDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/note/ChangeSet/" + this.props.params.uid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r);
        if (r.status == 200 && r.isSuccess) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object,
          });

          //console.log(this.state)
          // console.log(this.state.period.open)
        } else if (!r.isSuccess) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object,
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  CreateNote = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify([this.state.note]),
    };

    fetch("/api/note", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(requestOptions);
        // console.log(r);
        if (r.status === 200) {
          /* 
                    console.log("oluşturuldu");
                        console.log(requestOptions);
                        console.log(this.state.data.id);
                  

                    */

          //  console.log(r);
          // this.removeItem();

          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
          this.GetDatas();
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <NoteList dataSet={this.state.formData} changeState={this.GetDatas} />
        </BodyArea>
        <NoteArea>
          <TextArea
            width="100%"
            rows="1"
            label={false}
            changeArea="note"
            placeholder="Type a Note (Press Enter Key to Save)"
            onKeyDown={this.handleChanges}
          />
        </NoteArea>
      </PageArea>
    ) : (
      ""
    );
  }
}
export default (props) => <ChangeSetComments {...props} params={useParams()} />;

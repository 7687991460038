import React from "react";

import { extractStatusColor } from "../Commons";

const TDColorText = ({ text }) => {
  const { bgColor, textColor } = extractStatusColor(text);
  return (
    <div
      className="status-wrapper"
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <p className="text">{text}</p>
    </div>
  );
};

export default TDColorText;

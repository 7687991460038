import React, { Component } from 'react'
import DataTable from '../../../common/dataTable';

import GanttSidebar from '../../task/GanttSidebar';
import { useParams } from 'react-router';
class ProgramRoadmap extends Component {
    render() {

        //console.log(this.props.params.programid)
        const dtConfig = {
            endPoint: '/api/program/'+this.props.params.programid+'/projects?sort=projectId&sort=sequenceNumber&sort=level&start=startDate', 
            idAttribute: "uid",
            nameAttribute: "name",
            columns: [
                {id:"code", dataKey:"code", label:"ID", type:"text"}
                ,{id:"name", dataKey:"name", label:"Name", type:"text"}
                ,{id:"objectType", dataKey:"objectTypeInfo.name", label:"Type", type:"text"}
                ,{id:"startDate", dataKey:"startDate", label:"Start", type:"date"}
                ,{id:"finishDate", dataKey:"finishDate", label:"Finish", type:"date"}
                ,{id:"status", dataKey:"status.name", label:"Status", type:"text"}
                ,{id:"percentComplete", dataKey:"percentComplete", label:"Percent Complete", type:"percentage"}
                ,{id:"actualStart", dataKey:"actualStart", label:"Actual Start", type:"date"}
                ,{id:"actualFinish", dataKey:"actualFinish", label:"Actual Finish", type:"date"}

                ,{id:"level", dataKey:"level", label:"Level", type:"text"}

                ,{id:"obsInfo", dataKey:"obsInfo.name", label:"OBS", type:"text"}
                
                ,{id:"pendingActual", dataKey:"pendingActual", label:"Pending Actual", type:"text"}


                ,{id:"p6Plan.plannedStart", dataKey:"p6Plan.plannedStart", label:"Planned Start (P6)", type:"date"}
                ,{id:"p6Plan.plannedFinish", dataKey:"p6Plan.plannedFinish", label:"Planned Finish (P6)", type:"date"}
                ,{id:"p6Plan.plannedDuration", dataKey:"p6Plan.plannedDuration", label:"Planned Duration (P6)", type:"day"}
                ,{id:"p6Plan.plannedUnits", dataKey:"p6Plan.plannedUnits", label:"Planned Units (P6)", type:"hour"}
                
                ,{id:"p6Plan.actualStart", dataKey:"p6Plan.actualStart", label:"Actual Start (P6)", type:"date"}
                ,{id:"p6Plan.actualFinish", dataKey:"p6Plan.actualFinish", label:"Actual Finish (P6)", type:"date"}
                ,{id:"p6Plan.actualDuration", dataKey:"p6Plan.actualDuration", label:"Actual Duration (P6)", type:"day"}
                ,{id:"p6Plan.actualUnits", dataKey:"p6Plan.actualUnits", label:"Actual Units (P6)", type:"hour"}

                ,{id:"p6Plan.baselineStart", dataKey:"p6Plan.baselineStart", label:"Baseline Start (P6)", type:"date"}
                ,{id:"p6Plan.baselineFinish", dataKey:"p6Plan.baselineFinish", label:"Baseline Finish (P6)", type:"date"}
                ,{id:"p6Plan.baselineDuration", dataKey:"p6Plan.baselineDuration", label:"Baseline Duration (P6)", type:"day"}
                ,{id:"p6Plan.baselineUnits", dataKey:"p6Plan.baselineUnits", label:"Baseline Units (P6)", type:"hour"}

                ,{id:"p6Plan.remainingDuration", dataKey:"p6Plan.remainingDuration", label:"Remaining Duration (P6)", type:"day"}
                ,{id:"p6Plan.remainingUnits", dataKey:"p6Plan.remainingUnits", label:"Remaining Units (P6)", type:"hour"}

                ,{id:"p6Plan.atCompletionDuration", dataKey:"p6Plan.atCompletionDuration", label:"At Completion Duration (P6)", type:"day"}
                ,{id:"p6Plan.atCompletionUnits", dataKey:"p6Plan.atCompletionUnits", label:"At Completion Units (P6)", type:"hour"}


                ,{id:"plannedStart", dataKey:"plannedStart", label:"Planned Start", type:"date"}
                ,{id:"plannedFinish", dataKey:"plannedFinish", label:"Planned Finish", type:"date"}
              
                ,{id:"plannedUnits", dataKey:"plannedUnit", label:"Planned Units", type:"hour"}
                
               
            
                ,{id:"actualUnits", dataKey:"actualUnit", label:"Actual Units", type:"hour"}

            
              
                ,{id:"remainingUnits", dataKey:"remainingUnit", label:"Remaining Units", type:"hour"}

              
                ,{id:"atCompletionUnits", dataKey:"atCompletionUnit", label:"At Completion Units", type:"hour"}
                ,{id:"ipt", dataKey:"ipt", label:"Responsible IPT", type:"text"}
                ,{id:"responsible", dataKey:"responsible.fullName", label:"Responsible PM", type:"text"}
                ,{id:"mspWbsCode", dataKey:"mspWbsCode", label:"MSP WBS Code", type:"text"}


            ], 
            childAttribute: "children",
            sideBar: <GanttSidebar roadmap={true} />,
            view: {
                visibleColumnList: ["code", "name","objectType", "startDate", "finishDate", "status","percentComplete","actualStart","actualFinish"],
                mode:"gantt",
                hideSelectbox:true,
                gantt: { 
                    
                }
            },
        }

        return (
            <div className="hub-page" >
               <DataTable config={dtConfig} roadmap={true}  />
            </div>
        );
    }

}


export default (props) => (
    <ProgramRoadmap 
        {...props}
        params={useParams()}
        />
); 
import React, { Component } from 'react';
import PageNavigation from '../../common/page-navigation';
import PageHeader from '../../common/pageElements/pageHeader/'

import {
    Routes,
    Route,
  } from "react-router-dom";
import Details from './Details';
//import Roadmap from './Roadmap';
//import WBSOld from './wbsold/WBS';
import WBS from './wbs/WBS';

import { useParams } from 'react-router';
//import TextField from  './TextField';
import Auth from '../../auth/Auth';
import Dashboard from '../dashboard/dashboard';

class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                {to:"dashboard", id:"projectDashboard", label:"Dashboard", icon: ""},
                {to:"details", id:"projectDetailsDetails", label:"Properties", icon: ""},
                {to:"wbs", id:"projectDetailsWBS", label:"Tasks", icon: ""}
               
                //,{to:"wbsold", id:"projectDetailsWBSOld", label:"Tasks Old", icon: ""},
                //{to:"roadmap", id:"projectDetailsRoadmap", label:"Roadmap", icon: ""}
            ],  
            project: {},
            defaultPage:2,
            loading:true
        }
    }

    getRecord = (e) => {
        //console.log("getRecord",e);
        //console.log(e);
         this.setState({...this.state, project:e});

         this.props.setSelectedRecord(e)
    }

    componentDidMount() { 
        //console.log("componentDidMount",this.props.params['*'])
       // this.setState({...this.state, loading:false,defaultPage:this.props.params['*']==="details"?0:1});

        this.getData()
     }

     
    getData = () =>{
         const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch('/api/projects/'+this.props.params.projectid, requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                //console.log('Status',r.body.status)
                if(r.body.status==200) {
                    this.setState({...this.state, loading:false, defaultPage:this.props.params['*']==="details"?0:1, project:r.body.object[0]});

                   // this.props.selectedRecord(r.body.object[0])
                }
                else if(r.body.status==401) {
                    window.location.replace("/login");
                }
                else if(r.body.status==403) {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    }

    render() {

        return (
            <>
            <PageHeader
                title={(this.state.project?.name?this.state.project.name:"")}
                left={<PageNavigation links = {this.state.links} selectedRecord={this.state.project} setSelectedRecord={this.props.setSelectedRecord} defaultPage={2}/>            }
                /> 
            <div className="hub-page">
                <Routes>
                    <Route path="/details" element={<Details  selectedRecord={this.getRecord} project={this.state.project}/>} />
                    <Route path="/wbs" element={<WBS  selectedRecord={this.getRecord} project={this.state.project}/>} />
                    <Route path="/dashboard" element={<Dashboard  selectedRecord={this.getRecord} project={this.state.project}/>} />
                    {/*<Route path="/wbsold" element={<WBSOld />} />
                        <Route path="/roadmap" element={<Roadmap />} />*/}


                </Routes>
            </div>
            </>
        );
    }
}

export default (props) => (
    <ProjectDetails 
        {...props}
        params={useParams()}
        />
);
const HiTimelineCursor = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
    >
      <g clip-path="url(#clip0_10291_22069)">
        <path
          d="M10.768 -0.232045L5.5 5.03591L0.232045 -0.232045H10.768Z"
          fill="#1769F1"
        />
      </g>
      <defs>
        <clipPath id="clip0_10291_22069">
          <rect width="10" height="7" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HiTimelineCursor;

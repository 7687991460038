import React, { Component } from "react";
import "./filterPicker.css";
import TooltipWithPage from "../tooltipWithPage/tooltipWithPage";

import OBSList from "../../pages/taskBoard/OBSList";
import TableWithSearch from "../tableWithSearch";
import Auth from "../../auth/Auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as icon from "@fortawesome/pro-regular-svg-icons";
import { renderFilterIcon } from "../Commons";
import HiArrowShortDown from "../icons/HiArrowShortDown";
import HubIcon from "../hubIcon/HubIcon";

class FilterPicker extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isOpen: false,
      isBarOpen: false,
      options: this.props.options ? this.props.options : [],
      selectedOption: this.props.selectedOption
        ? this.props.selectedOption
        : {},
      selected: {},
    };
  }

  componentDidMount() {
    // console.log("componentDidMount",this.props)
    //this.setState({...this.state,selected:this.props.selected })
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.column?.beAffected &&
      this.props.affectAll !== null &&
      this.props.affectAll?.value !== prevProps.affectAll?.value
    ) {
      // console.log("1componentDidUpdate",prevProps.affectAll, this.props)

      this.getData();
    }
  }

  getData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    //  console.log(obs)

    let filterString = "";
    //console.log("generateData",this.props.selectedFilters)
    if (this.props.column?.beAffected && this.props.affectAll !== null) {
      filterString += "?";
      filterString +=
        this.props.affectAll.dataKey +
        "=" +
        this.props.affectAll.value.toString();
    }

    fetch(
      /*'/api/obs/teamboard'*/ this.props.column.endpoint + filterString,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log("r",r)

        if (r.status === 200) {
          // console.log("r",r,r.body.object[0])

          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            data: r.body.object,
            selectedOBS: r.body.object[0],
          });

          //  console.log(this.state)
          // console.log(this.state.period.open)
        } else if (r.status === 401) {
          window.location.replace("/login");
        } else if (r.status === 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  toggleSelectBar = (e, group) => {
    //console.log("toggleSelectBar",group,group?.name)
    //console.log(group)

    this.setState(
      {
        ...this.state,
        isBarOpen: !this.state.isBarOpen,
        selected: group?.name ? group : this.state.selected,
      },
      () => {
        window.addEventListener("mousedown", this.handleClickOutside);
      }
    );
    if (group?.uid !== undefined) {
      this.props.toggleSelected(group);
      //this.setState({...this.state, isBarOpen:false})
    }
  };

  closePanel = () => {
    this.setState({ ...this.state, isBarOpen: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event?.target)) {
      this.closePanel();
    }
  };

  setFilter = (a, b) => {
    // console.log(a,b,this.props.column)

    this.props.setFilter(
      this.props.column,
      b,
      b[this.props.column.labelAttribute],
      this.props.index
    );

    this.setState({
      ...this.state,
      selectedFilter: b[this.props.column.labelAttribute],
      isOpen: false,
    });
  };

  render() {
    return (
      <div className="hub-filter-picker-wrapper">
        <div className="hub-filter-picker">
          <div className="label">
            {renderFilterIcon(this.props.column.id)}

            {this.props.column.label}
          </div>
          <div className="menu" onClick={this.toggleSelectBar}>
            {this.props.column.selectedFilter !== null
              ? this.props.column.selectedFilter
              : "Select"}
            <HubIcon fontSize={14} lineColor="#1769F1">
              <HiArrowShortDown />
            </HubIcon>
          </div>
        </div>
        {this.state.isBarOpen ? (
          <div ref={this.wrapperRef}>
            <TooltipWithPage
              title={this.state.selected?.name}
              closePanel={this.closePanel}
              style={{
                width: this.props.column.id === "obs" ? "450px" : "250px",
                transform: "translate(-16%)",
              }}
              arrowLocaiton={{ marginLeft: "80px" }}
            >
              {this.props.column.id === "obs" ? (
                <OBSList
                  labelKey={this.props.column.labelAttribute}
                  items={this.state.data}
                  toggleSelectBar={this.setFilter}
                  closePanel={this.closePanel}
                />
              ) : (
                <TableWithSearch
                  labelKey={this.props.column.labelAttribute}
                  items={this.state.data}
                  toggleSelectBar={this.setFilter}
                  closePanel={this.closePanel}
                />
              )}
            </TooltipWithPage>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default FilterPicker;

import React, { Component } from 'react';
import {Utils, DateUtils} from './../../../common/Commons';
import {Link} from 'react-router-dom';

class ReportPeriod extends Component {

    handleClick = () => {
        this.props.selectPeriod(this.props.ReportPeriod.id);
    }
    
    render() {
        let dateUtils = new DateUtils();
        let utils = new Utils();

        let  to = "/timesheet/detail?resourceId="+utils.findGetParameter("resourceId")+"&"+"timeperiodId="+this.props.ReportPeriod.id
        
        return (
            <Link onClick = {this.handleClick} to={to}> 
                <div className={this.props.isSelected?"hub-ts-ReportPeriod selected":"hub-ts-ReportPeriod"}>
                    <div className="date">{dateUtils.Date2MonthDay(this.props.ReportPeriod.startDate)} - {dateUtils.Date2MonthDay(this.props.ReportPeriod.finishDate)}</div>
                    <div className="status">{this.props.ReportPeriod.timesheetStage.name}</div>
                </div>
            </Link>
        );
    }
}

export default ReportPeriod;
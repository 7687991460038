import React, { Component } from 'react';

import ProjectTileMilestoneBoxContainer from './ProjectTileMilestoneBoxContainer'

class ProjetTileTimeline extends Component {

     constructor(props) {
    super(props)
  
    this.state = {
   
       width:100,
        Status:"Loading"
    }
  }



    setUpperWidth=(e)=>{

       // console.log("setUpperWidth: "+e)
            this.setState({...this.state,Status:"Load", width:e});
    }


  

    render() {

       // console.log( this.state)
        return (
       
            <div className="timeline">
                <div className="bg" style={{width: this.state.width+"%"}} >  
                    <div className={"bar "+this.props.color} style={{width: this.props.percentComplete+"%"}}> </div>   
                    <ProjectTileMilestoneBoxContainer  project={this.props.project} color={this.props.color} upperWidth={this.setUpperWidth}  percentComplete={this.props.percentComplete}/>
                    
                </div>  
                
                <div className="labels">
                    <span>{this.props.startDate}</span>
                    <span>{this.props.finishDate} </span>
                </div>
            </div>
        );
    }
}

export default ProjetTileTimeline;
import React from "react";
import BarText from "./BarText";

const WbsGanttBar = ({ data, onMouseOver, onMouseLeave, isSelectedRow }) => {
  const { ganttWidth, ganttLeft, name } = data;

  return (
    <div
      onMouseMove={onMouseOver}
      onMouseLeave={onMouseLeave}
      className="hub-gantt-item-wbs"
      style={{
        width: ganttWidth,
        left: ganttLeft,
        height: 13,
        top: "50%",
        transform: "translate(0, -50%)",
      }}
    >
      <BarText name={name} ganttWidth={ganttWidth} />
      <span
        className="start-mark"
        style={{
          position: "absolute",
          left: "0px",
          top: 8,
          width: "4px",
          height: "8px",
          backgroundColor: "#8225FA",
          borderRadius: "2px",
        }}
      />
      <span
        className="shape-element"
        style={{
          position: "absolute",
          left: "4px",
          top: 10,
          width: ganttWidth - 8,
          height: "6px",
          backgroundColor: isSelectedRow ? "#eef7ff" : "#fff",
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
        }}
      />
      <span
        className="start-mark"
        style={{
          position: "absolute",
          right: "0px",
          top: 8,
          width: "4px",
          height: "8px",
          backgroundColor: "#8225FA",
          borderRadius: "4px",
        }}
      />
    </div>
  );
};

export default WbsGanttBar;

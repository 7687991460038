import {faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class LayoutModalLevel extends Component {

    setLevelUp = () => {
        this.props.setLevelUp(this.props.level.code);
    }

    setLevelDown = () => {
        this.props.setLevelDown(this.props.level.code);
    }

    toggleLevel = () => {
        this.props.toggleLevel(this.props.level.code);
    }

    render() {
        return (
            <div className="hub-rmUtil-layoutModalLevel" style={{opacity:(this.props.isDisabled?0.3:1)}}>
                <div className="reorder">
                    <div onClick={this.setLevelUp} style={{borderBottom: "1px solid #EDF1F6", height:21}}>
                        <FontAwesomeIcon icon={faChevronUp} color="#CAD4E4" />
                    </div>
                    <div onClick={this.setLevelDown} style={{height:22}}>
                        <FontAwesomeIcon icon={faChevronDown}  color="#CAD4E4" />
                    </div>
                </div>
                <div className="name" onClick={this.toggleLevel}>
                    <FontAwesomeIcon icon={this.props.level?.icon} size={"sm"}  color="#000000" />
                    {this.props.level?.name}
                </div>
                <div className="toggle" onClick={this.toggleLevel}>
                    <FontAwesomeIcon icon={faEye} size={"lg"}  color="#1D5BBF" />
                </div>

            </div>
        );
    }
}

export default LayoutModalLevel;
import React from "react";

const HiRoadmap = ({ bgColor, lineColor }) => {
  return (
    <svg
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M2.84452 8.95373C2.3484 8.7608 2.10034 8.66433 2.02792 8.52533C1.96514 8.40483 1.96505 8.26129 2.02769 8.14071C2.09995 8.00163 2.3479 7.90486 2.84379 7.71134L16.9169 2.21939C17.3646 2.0447 17.5884 1.95736 17.7314 2.00514C17.8556 2.04663 17.9531 2.1441 17.9946 2.26831C18.0424 2.41133 17.955 2.63516 17.7803 3.08281L12.2884 17.1559C12.0949 17.6518 11.9981 17.8998 11.859 17.972C11.7384 18.0347 11.5949 18.0346 11.4744 17.9718C11.3354 17.8994 11.2389 17.6513 11.046 17.1552L8.8559 11.5235C8.81673 11.4228 8.79715 11.3725 8.76691 11.3301C8.7401 11.2925 8.70724 11.2596 8.66965 11.2328C8.62725 11.2026 8.5769 11.183 8.47619 11.1438L2.84452 8.95373Z"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiRoadmap;

import React from "react";
import BarText from "./BarText";

const TaskGanttBar = ({
  data,
  onMouseOver,
  onMouseLeave,
  ganttBaselineWidth,
  ganttBaselineLeft,
}) => {
  const { ganttWidth, ganttLeft, percentComplete, name } = data;

  return (
    <div
      onMouseMove={onMouseOver}
      onMouseLeave={onMouseLeave}
      className="hub-gantt-item-task"
      style={{
        width: ganttWidth,
        left: ganttLeft,
        height: 16,
        top: "50%",
        transform: "translate(0, -50%)",
      }}
    >
      <div className="percentage" style={{ width: `${percentComplete}%` }} />
      <span
        className="task-line"
        style={{
          width: ganttBaselineWidth,
          left: ganttBaselineLeft - ganttLeft,
        }}
      />
      <BarText name={name} ganttWidth={ganttWidth} />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="46"
        height="2"
        viewBox="0 0 46 2"
        fill="none"
      >
        <rect width="2" height="2" rx="1" fill="#1769F1" />
        <rect x="4" width="2" height="2" rx="1" fill="#1769F1" />
        <rect x="8" width="2" height="2" rx="1" fill="#1769F1" />
        <rect x="12" width="2" height="2" rx="1" fill="#1769F1" />
        <rect x="16" width="2" height="2" rx="1" fill="#1769F1" />
        <rect x="20" width="2" height="2" rx="1" fill="#1769F1" />
        <rect x="24" width="2" height="2" rx="1" fill="#1769F1" />
      </svg>
    </div>
  );
};

export default TaskGanttBar;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'


class AddTaskModalFilter extends Component {
    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        this.props.onChange(this.props.filterId, e.target.value);
    }
    
    render() {
        return (
            <div className="item">
                <FontAwesomeIcon icon={faSearch} style={{color:"rgba(0,0,0,.38) "}}  />
                <input type="text" onChange={this.handleChange} valoe={this.props.filterValue} placeholder={this.props.placeholder} />
            </div>
        );
    }
}

export default AddTaskModalFilter;
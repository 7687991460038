import React, { Component } from "react";

import HierarchicalTable from "../../../../common/hierarchicalTable/hierarchicalTable";
import TooltipSearch from "../../../../common/tooltipWithPage/TooltipSearch";
export default class OBSList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: "",
      description: false,
    };
  }

  setSearchString = (v) => {
    this.setState({ ...this.state, searchString: v });
  };

  render() {
    return (
      <>
        <TooltipSearch
          onChange={this.setSearchString}
          value={this.state.searchString}
        />
        <HierarchicalTable
          isSelected={this.props.isSelected}
          labelKey={this.props.labelKey}
          data={this.props.items}
          searchString={this.state.searchString}
          toggleSelectBar={this.props.toggleSelectBar}
        />
      </>
    );
  }
}

import React, { Component } from "react";
import MainButton from "./../buttons/MainButton";
import PrebuiltFilter from "./filters/PrebuiltFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPaste, faListCheck } from "@fortawesome/pro-duotone-svg-icons";
import {
  faBolt,
  faSquareCheck,
  faSquareMinus,
} from "@fortawesome/free-solid-svg-icons";
import TooltipWithPage from "../tooltipWithPage/tooltipWithPage";
import ActionBarActions from "./ActionBarActions";
import HiSelectAll from "../icons/HiSelectAll";
import "./ActionBar.css";

import HiDeselectAll from "../../common/icons/HiDeselectAll";
import HiExpand from "../../common/icons/HiExpand";
import HiCollapse from "../../common/icons/HiCollapse";
import HiTrash from "../../common/icons/HiTrash";
import HiCopy from "../../common/icons/HiCopy";
import HiList from "../icons/HiList";
import HiCut from "../icons/HiCut";
import HiAddCircle from "../icons/HiAddCircle";
class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      showTooltipClear: false,
      showPreBultFilter: false,
      showActions: false,
    };
  }

  toggleTooltip = () => {
    this.setState({ ...this.state, showTooltip: !this.state.showTooltip });
  };

  toggleTooltipClear = () => {
    this.setState({
      ...this.state,
      showTooltipClear: !this.state.showTooltipClear,
    });
  };

  toggleShowActions = () => {
    this.setState({ ...this.state, showActions: !this.state.showActions });
  };

  render() {
    // console.log("actionbar",this.props)
    return (
      <div className="hub-dataTable-tableArea-actionBar">
        <div className="hub-dataTable-tableArea-actionButtons">
          {this.props.projectSource !== undefined ? (
            <div className="items" style={{ marginRight: "3px" }}>
              <MainButton
                iconSize={14}
                label={"Add"}
                icon={<HiAddCircle />}
                className={"tiny-text black"}
                /*icon={this.state.showActions?faAngleDown:faAngleRight}*/ onClick={
                  this.toggleShowActions
                }
              />

              {this.state.showActions ? (
                <div style={{ position: "relative" }}>
                  <ActionBarActions
                    openNewSideBar={this.props.openNewSideBar}
                    toggleShowActions={this.toggleShowActions}
                    projectSource={this.props.projectSource}
                  />
                </div>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}

          {!this.props.hideSelectbox ? (
            <MainButton
              className="tiny-text black"
              icon={<HiSelectAll />}
              label={"Select All"}
              onClick={this.props.selectAll}
              iconSize={14}
            />
          ) : (
            false
          )}
          {!this.props.hideSelectbox ? (
            <MainButton
              className="tiny-text black"
              icon={<HiDeselectAll />}
              disabled={this.props.selectedRows.length > 0 ? false : true}
              label={"Deselect All"}
              onClick={this.props.deselectAll}
              iconSize={14}
            />
          ) : (
            false
          )}
          {!this.props.hideSelectbox && this.props.selectedRows.length > 0 ? (
            <div className="hub-dataTable-action-bar-info-buttons">
              <div className="action-bar-info">
                {this.props.selectedRows.length} Items Selected
              </div>
              {this.props.deleteAvailable ? (
                <div className="items" style={{ position: "relative" }}>
                  <MainButton
                    icon={<HiTrash />}
                    className="tiny-text black"
                    label={"Delete"}
                    iconSize={14}
                    onClick={
                      /*()=>this.props.deleteRecord(this.props.deleteAvailable)*/ this
                        .toggleTooltip
                    }
                  />
                  {this.state.showTooltip ? (
                    <TooltipWithPage
                      title={"Delete Records"}
                      closePanel={this.toggleTooltip}
                      style={{ width: "220px" }}
                    >
                      <span>
                        {this.props.selectedRows.length} record(s) will be
                        deleted.
                      </span>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <MainButton
                          className="button-1"
                          label={"Delete"}
                          onClick={() => {
                            this.props.deleteRecord(
                              this.props.deleteAvailable,
                              true
                            );
                            this.toggleTooltip();
                          }}
                        />
                        <MainButton
                          className="button-2"
                          label={"Cancel"}
                          onClick={this.toggleTooltip}
                        />
                      </div>
                    </TooltipWithPage>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}
              {this.props.timesheetApproveAvailable ? (
                <div className="items" style={{ position: "relative" }}>
                  <MainButton
                    icon={<HiList />}
                    className="tiny-text black"
                    label={"Timesheet Approve"}
                    onClick={
                      /*()=>this.props.deleteRecord(this.props.deleteAvailable)*/ this
                        .toggleTooltip
                    }
                  />
                  {this.state.showTooltip ? (
                    <TooltipWithPage
                      title={"Timesheet Approve"}
                      closePanel={this.toggleTooltip}
                      style={{ width: "220px" }}
                    >
                      <span>
                        {this.props.selectedRows.length} record(s) will be
                        approved.
                      </span>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <MainButton
                          className="button-1"
                          label={"Approve"}
                          onClick={() => {
                            this.props.timesheetApprove(
                              this.props.timesheetApproveAvailable,
                              "approve"
                            );
                            this.toggleTooltip();
                          }}
                        />
                        <MainButton
                          className="button-2"
                          label={"Cancel"}
                          onClick={this.toggleTooltip}
                        />
                      </div>
                    </TooltipWithPage>
                  ) : (
                    false
                  )}
                </div>
              ) : (
                false
              )}
              {this.props.copyAvailable ? (
                <MainButton
                  icon={<HiCopy />}
                  className="tiny-text black"
                  label={"Copy"}
                  onClick={this.props.copyTask}
                />
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}

          {!this.props.hideSelectbox &&
          this.props.copyAvailable &&
          this.props.copyTaskListCount > 0 ? (
            <div className="hub-dataTable-action-bar-info-buttons">
              <div
                className="action-bar-info"
                style={{ cursor: "pointer" }}
                onClick={this.toggleTooltipClear}
              >
                {this.props.copyTaskListCount} tasks copied
                <div style={{ position: "relative" }}>
                  {this.state.showTooltipClear ? (
                    <TooltipWithPage
                      title={"Clear Copied Tasks"}
                      closePanel={this.toggleTooltipClear}
                      style={{
                        width: "250px",
                        transform: "translate(-65%,10%)",
                      }}
                    >
                      <span>Copied task list will be cleared.</span>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <MainButton
                          className="button-1"
                          label={"Clear"}
                          onClick={() => {
                            this.props.copyClear();
                            this.toggleTooltipClear();
                          }}
                        />
                        <MainButton
                          className="button-2"
                          label={"Cancel"}
                          onClick={this.toggleTooltipClear}
                        />
                      </div>
                    </TooltipWithPage>
                  ) : (
                    false
                  )}
                </div>
              </div>

              {this.props.selectedRecordType === "task" ||
              this.props.selectedRecordType === "activity" ? (
                <>
                  <MainButton
                    icon={<HiCopy />}
                    className="tiny-text black"
                    label={"Paste as Child"}
                    onClick={() => this.props.copyControl(1)}
                  />
                  {this.props.selectedRecordType === "task" ? (
                    <MainButton
                      icon={<HiCut />}
                      className="tiny-text black"
                      label={"Paste as Sibling"}
                      onClick={() => this.props.copyControl(2)}
                    />
                  ) : (
                    false
                  )}
                </>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}

          {this.props.isExpandable ? (
            <MainButton
              icon={<HiExpand />}
              className="tiny-text black"
              label="Expand All"
              onClick={this.props.handleExpandAll}
              iconSize={14}
            />
          ) : (
            false
          )}
          {this.props.isExpandable ? (
            <MainButton
              icon={<HiCollapse />}
              className="tiny-text black"
              label="Collapse All"
              onClick={this.props.handleCollapseAll}
              iconSize={14}
            />
          ) : (
            false
          )}

          {this.props.actionButtons?.map((m) => {
            return (
              <MainButton
                className="button-2"
                key={m.code}
                label={m.label}
                onClick={m.onClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default ActionBar;

import React, { Component } from 'react';
import ChangeSetActivitiesTable from './activitiesTable';

import sampleData from './activitiesSampleData';

import {DateUtils} from '../../../common/Commons';
import Auth from '../../../auth/Auth';
import { useParams } from 'react-router';

import LoadingScreen from '../../../common/statusScreen/loadingScreen';



import { faEmptySet} from '@fortawesome/pro-duotone-svg-icons'

//Burası dökümandaki kıyas alanları. Daha sonra admin paneline eklenip ordan okunabilir. Impact high olan direkt kırmızı olur.
let config={

    compareFields:[
        {id:"remainingUnit",label:"Remaining Unit", type:"float",  fields:[
            {left:"remainingUnit",operator:">", right:"remainingUnit",rightType:"p6",color:"red"},
            {left:"remainingUnit",operator:"==", right:"remainingUnit",rightType:"p6",color:"white"},
            {left:"remainingUnit",operator:"<", right:"remainingUnit",rightType:"p6",color:"green"},
        ]},
        {id:"actualUnit",label:"Actual Unit", type:"float", fields:[
            {left:"actualUnit",operator:">", right:"actualUnit",rightType:"p6",color:"blue"},
            {left:"actualUnit",operator:"==", right:"actualUnit",rightType:"p6",color:"white"},
          //  {left:"actualUnit",operator:"<", right:"actualUnit",rightType:"p6",color:"green"},
        ]},
      
         {id:"atCompletionUnit",label:"At Completion Unit", type:"float", fields:[
            {left:"atCompletionUnit",operator:">", right:"atCompletionUnit",rightType:"p6",color:"red", impact:"High" },
            {left:"atCompletionUnit",operator:"==", right:"atCompletionUnit",rightType:"p6",color:"white"},
            {left:"atCompletionUnit",operator:"<", right:"atCompletionUnit",rightType:"p6",color:"green"},
        ]},
       /*  {id:"plannedUnit",label:"Planned Unit", type:"float", fields:[
            {left:"plannedUnit",operator:">", right:"plannedUnit",rightType:"p6",color:"red"},
            {left:"plannedUnit",operator:"==", right:"plannedUnit",rightType:"p6",color:"white"},
            {left:"plannedUnit",operator:"<", right:"plannedUnit",rightType:"p6",color:"green"},
        ]},*/

         {id:"actualStart",label:"Actual Start", type:"date", fields:[
            {left:"actualStart",operator:">", right:"actualStart",rightType:"p6",color:"red" , impact:"High" },
            {left:"actualStart",operator:"==", right:"actualStart",rightType:"p6",color:"white"},
            {left:"actualStart",operator:"<", right:"actualStart",rightType:"p6",color:"green"},
        ]},
         {id:"actualFinish",label:"Actual Finish", type:"date", fields:[
            {left:"actualFinish",operator:">", right:"actualFinish",rightType:"p6",color:"red", impact:"High" },
            {left:"actualFinish",operator:"==", right:"actualFinish",rightType:"p6",color:"white"},
            {left:"actualFinish",operator:"<", right:"actualFinish",rightType:"p6",color:"green"},
        ]},
         {id:"plannedStart",label:"Planned Start", type:"date", fields:[
            {left:"plannedStart",operator:">", right:"plannedStart",rightType:"p6",color:"red"},
            {left:"plannedStart",operator:"==", right:"plannedStart",rightType:"p6",color:"white"},
            {left:"plannedStart",operator:"<", right:"plannedStart",rightType:"p6",color:"green"},
        ]},
         {id:"plannedFinish",label:"Planned Finish", type:"date", fields:[
            {left:"plannedFinish",operator:">", right:"plannedFinish",rightType:"p6",color:"red"},
            {left:"plannedFinish",operator:"==", right:"plannedFinish",rightType:"p6",color:"white"},
            {left:"plannedFinish",operator:"<", right:"plannedFinish",rightType:"p6",color:"green"},
        ]}
]
}

//Kıyaslama operatörleri
var operators = {
        '===': function (a, b) { return a === b; },
        '==': function (a, b) { return a == b; },
        '<=': function (a, b) { return a <= b; },
        '>=': function (a, b) { return a >= b; },
        '<': function (a, b) {if (a===null && b===null) {return false} else if (a===null && b!==null) {return false}  else if (a!==null && b==null) {return false} else {return a < b}; },
        '>': function (a, b) { if (a===null && b===null) {return false} else if (a===null && b!==null) {return false} else if (a!==null && b==null) {return false}  else {return a > b}; }
    };

class ChangeSetActivities extends Component {

    constructor(props) {


        
        super(props);

        this.du = new DateUtils();
        this.state={activites:[], Loading: true}
    }

    componentDidMount() {
        //console.log(this.props)
        this.getData();
    }
    
/*
    getData = () => {
        this.setState({Loading:false, activites:sampleData})
    }
*/

    getData = () =>{
        const requestOptions = {
           method: 'GET',
           headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
       };

       fetch('/api/changeset/'+this.props.params.uid+'/activity', requestOptions)
           .then(response => response.json().then(data => ({status:response.status, body:data})))
           .then(r => {
               if(r.status==200) {
                   //this.createImpacts(r.body.object);
                  // this.setState({...this.state, Loading:false, activites:r.body.object});
                this.setState({...this.state, Loading:false, activites: this.createImpacts(r.body.object)});
                   //console.log(this.state.activites)
               }
               else if(r.status==401) {
                   window.location.replace("/login");
               }
               else if(r.status==403) {
                   this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
               }
               else {
                   this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
               }
           });
   }


   createImpacts =(activities)=>{
    

    
    let newArray=activities;

    if(activities!==null)
    {
        let compareObject=[];
        let left="";
        let right="";
        var leftDate =null;
        var rightDate=null;

        for (let i=0; i < newArray.length; i++ )
        {
            //newArray[i].hub.actualUnit={value:5,impact:"red"}

            //console.log(Object.keys(newArray[i].hub))
            //console.log(Object.keys(newArray[i].hub).length)
            let a={...newArray[i].hub}
            Object.assign(newArray[i], {hubImpact:a,impactCounts:{red:0,green:0},impact:"Low", changeAreas:[]} );
            let obj=Object.keys(newArray[i].hubImpact);
            //var size = Object.keys(myObj).length;
            for (let j=0; j < obj.length; j++ )
            {
                {
                    //obj[j]={impact:"red"}
                    //console.log(obj)
                    if(obj[j]!=="uid")
                    {
                        newArray[i].hubImpact[obj[j]]={impact:"white"}
                    }

                    leftDate =null;
                    rightDate=null;


                    compareObject=config.compareFields.find(x => x.id ===obj[j] );
                //  console.log("compareObject",obj[j],compareObject)

                    compareObject?.fields.map((cmp)=>{
                    // console.log(compareObject.type)
                        if(compareObject.type==="date" && newArray[i].hub[cmp.left]!==0 && newArray[i].hub[cmp.left]!==null)
                        {
                            let newDate=this.du.Date2Str(newArray[i].hub[cmp.left]);
                        // console.log(newDate)
                        //console.log(newArray[i].hub[cmp.left])
                        //console.log(parseInt(newArray[i].hub[cmp.left].substring(0,4)))
                        //console.log(parseInt(newArray[i].hub[cmp.left].substring(6,7))-1)
                        //console.log(parseInt(newArray[i].hub[cmp.left].substring(8,10)))

                        // console.log(parseInt(newDate.substring(6,10)));
                        // console.log(parseInt(newDate.substring(3,5)));
                        // console.log(parseInt(newDate.substring(0,2)));
                            leftDate = new Date(parseInt(newDate.substring(6,10)),parseInt(newDate.substring(3,5))-1,parseInt(newDate.substring(0,2))); 
                            //console.log("leftDate",cmp.left,leftDate);
                        }
                            
                        if(compareObject.type==="date" && newArray[i][cmp.rightType][cmp.right]!==0 && newArray[i][cmp.rightType][cmp.right]!==null)
                        {
                            let newRightDate=this.du.Date2Str(newArray[i][cmp.rightType][cmp.right]);
                            rightDate = new Date(parseInt(newRightDate.substring(6,10)),parseInt(newRightDate.substring(3,5))-1,parseInt(newRightDate.substring(0,2))); 
                        // console.log("rightDate",rightDate);

                        }
                        
                        

                        left=compareObject.type==="float"?newArray[i].hub[cmp.left]:leftDate;
                        right=compareObject.type==="float"?newArray[i][cmp.rightType][cmp.right]:rightDate;
                    //  console.log("sonuç", cmp,newArray[i].hub[cmp.left],newArray[i].p6[cmp.right],operators[cmp.operator](newArray[i].hub[cmp.left], newArray[i].p6[cmp.right]))
                        //console.log(left);

                        //2017-02-07T21:00:00
                        //2017-02-07T21:00:00.000+0000

                        
                        

                        if(operators[cmp.operator](left, right)===true)
                        {
                        // console.log("ife",cmp.color)
                        // console.log(compareObject.id,cmp.operator,left,right,operators[cmp.operator](left, right))

                        //console.log("ife",cmp.left)
                            let array2=newArray[i].changeAreas;
                            //array.push(left.toString()+" "+(cmp.operator).toString()+" "+right.toString())

                            if(cmp.operator!== "==")
                            {
                                array2.push(compareObject.label+" "+(cmp.operator==="<"?"⇓":"⇑")+" : "+(compareObject.type==="date"?this.du.Date2Str(right):parseInt(right))+" => "+(compareObject.type==="date"?this.du.Date2Str(left):parseInt(left)) );
                                //array2.push(cmp.left+" "+cmp.operator+" : "+right+" => "+ (compareObject.type==="date"?left:left ));
                            }

                            //console.log(array2)

                            newArray[i].hubImpact[obj[j]]={impact:cmp.color, changeAreas:array2} 

                            if(cmp.impact==="High")
                            {
                                newArray[i].impact="High"
                            }


                            //Kırmızı sayısını belirlemek için lazım. Kırmızı sayısına impact High değilse göre sarı olacak kırmızı sayısı 0 ise yeil olacak
                            if(cmp.color==="red")
                            {
                                newArray[i].impactCounts.red=newArray[i].impactCounts.red+1
                            }
                            

                        }
                        

                        
                    })


                }
            }
        }
        
        //Kırmızı sayısına göre sarı yap.
        newArray.map((arr)=>{
            if(arr.impactCounts.red>0 && arr.impact!=="High")
            {
                //Object.assign(newArray[i], {hubImpact:a,impactCounts:{red:0,green:0},impact:"Low"} );
                arr.impact="Medium";
            }
            //console.log(arr.impactCounts.red)
        })
    }
    //console.log(newArray)
    return newArray;
   }

    render() {
       // console.log(this.state.activites)
        return (
           this.state.activites!==null? 
           <div className="pageDetails" style={{flex:1, flexDirection:"column", overflow:"auto",display: "flex", alignContent: "stretch"}}> 
                {
                    this.state.Loading===true?
                        <LoadingScreen/>
                        :
                        <ChangeSetActivitiesTable data={this.state.activites} />
                }
            </div>: <LoadingScreen  icon={faEmptySet} message="No Sync" noSpin/>
        );
    }
}

export default (props) => (
    <ChangeSetActivities 
        {...props}
        params={useParams()}
        />
);
import React, { Component } from 'react';
import { Resizable } from "re-resizable";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faAngleDown,faAngleUp } from '@fortawesome/pro-light-svg-icons'

class TH extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ordered:false,
            sortOrder:"asc",
            sortColumn:""
        }
    }

    componentDidMount(){
        this.setState({...this.state,  sortColumn:this.props.column.dataKey})
    }

    getColumnInfo =() =>
    {
        this.setState({...this.state,  sortOrder:this.state.sortOrder==="asc"?"desc":"asc", sortColumn:this.props.column.dataKey},this.props.handleSort(this.props.column.dataKey,this.state.sortOrder))
    }


    render() {
        
        return (
            <th style={{width: this.props.column.width, overflow:"hidden"}} onClick={this.getColumnInfo} >
                <Resizable 
                    enable={ {top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
                    minWidth={100}
                    size={{ width: this.props.column.width }}
                    onResizeStop={(e, direction, ref, d) => {
                        this.props.handleResizeColumn(this.props.ind, d.width); 
                    }}
                    handleClasses={{right:"th-resize-handler"}}
                >
                    <div className="th" style={{width: this.props.column.width, overflow:"hidden"}}>
                        <span>{this.props.column.label}</span>
                        {this.state.sortColumn===this.props.sortColumn?
                            <FontAwesomeIcon className="pointer" onClick={this.props.onCollapse} icon={this.state.sortOrder==="asc"?faAngleDown:faAngleUp} color="grey" size="xl" />
                            :''}
                    </div>
                </Resizable>
            </th>
        );
    }
}

export default TH;
import React, { Component } from "react";
import Item from "./Item";

class Row extends Component {
  render() {
    return (
      <div
        className="hub-thub-tline-row"
        style={{ width: this.props.innerWidth }}
      >
        {this.props.items.map((i) => {
          return (
            <Item
              mode={this.props.mode}
              item={i}
              boardType={this.props.boardType}
            />
          );
        })}
      </div>
    );
  }
}

export default Row;

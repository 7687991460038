import React, { Component } from "react";
import {
  faDownToBracket,
  faFilters,
  faSidebarFlip,
  faBarsStaggered,
  faRotateRight,
  faBan,
  faDoNotEnter,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faTableList,
  faGrid2,
  faBarsProgress,
} from "@fortawesome/pro-light-svg-icons";
import {
  faLineColumns,
  faMagnifyingGlass,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ButtonSelect from "./ButtonSelect";
import MainButton from "./../../common/buttons/MainButton";
import SearchArea from "../../common/inputControls/searchArea";
//import ColumnPicker from './ColumnPicker';

import HiBlock from "../../common/icons/HiBlock";
import HiLayoutRight from "../../common/icons/HiLayoutRight";
import HiRefresh from "../../common/icons/HiRefresh";
import HubIcon from "../../common/hubIcon/HubIcon";
import HiClose from "../../common/icons/HiClose";
import HiLine from "../../common/icons/HiLine";

class Toolbar extends Component {
  render() {
    return (
      <div className="hub-dataTable-toolBar">
           {this.props.boardType==="Task"?
              <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                {/* <ButtonSelect  options={this.props.options} selected={this.props.selected} toggleSelected={this.props.toggleSelected}/>
                          <MainButton className="tiny-text"  label={this.props.collapse?"Expand All":"Collapse All"} onClick={this.props.toggleCollapse} />
                          <MainButton className="tiny-text"  label={this.props.description?"Close Description":"Open Description"} onClick={this.props.toggleDescription} />
                          <MainButton className={this.props.isFilterOpen?"icon-button-2 active":"icon-button-2"} onClick={this.props.toggleFilter} icon={faFilters} superscript={this.props.config.view.filterList.length>0?this.props.config.view.filterList.length:false}/>
                          <ColumnPicker applyVisibleColumns={this.props.applyVisibleColumns} columns={this.props.config.columns} visibleColumnList={this.props.config.view.visibleColumnList} />*/}
            
                  <div onClick={() => {this.props.setFilter(1);}}
                    className={`hub-dataTable-prebuiltFilter ${
                      this.props.selectedFilter.findIndex((x) => x.id === 1) !== -1
                        ? "selected-filter"
                        : ""
                    }`}
                  >
                    <span
                      className="icon"
                      style={{
                        backgroundColor: "rgb(27, 201, 76)",
                        padding:
                          this.props.selectedFilter.findIndex((x) => x.id === 1) !== -1
                            ? "2.5px"
                            : "0px",
                      }}
                    >
                      {this.props.selectedFilter.findIndex((x) => x.id === 1) !== -1 ? (
                        <HubIcon lineColor="#fff" fontSize={13}>
                          <HiClose />
                        </HubIcon>
                      ) : (
                        <HiLine lineColor="rgb(27, 201, 76)" />
                      )}
                    </span>
                    <span className="label"> Only My Tasks</span>
                  </div>
            
                <div
                  onClick={() => {
                    this.props.setFilter(2);
                  }}
                  className={`hub-dataTable-prebuiltFilter ${
                    this.props.selectedFilter.findIndex((x) => x.id === 2) !== -1
                      ? "selected-filter"
                      : ""
                  }`}
                >
                  <span
                    className="icon"
                    style={{
                      backgroundColor: "rgb(234, 34, 70)",
                      padding:
                        this.props.selectedFilter.findIndex((x) => x.id === 2) !== -1
                          ? "2.5px"
                          : "0px",
                    }}
                  >
                    {this.props.selectedFilter.findIndex((x) => x.id === 2) !== -1 ? (
                      <HubIcon lineColor="#fff" fontSize={13}>
                        <HiClose />
                      </HubIcon>
                    ) : (
                      <HiLine lineColor="rgb(234, 34, 70)" />
                    )}
                  </span>
                  <span className="label"> Blocked</span>
                </div>

                <div
                  onClick={() => {
                    this.props.setFilter(3);
                  }}
                  className={`hub-dataTable-prebuiltFilter ${
                    this.props.selectedFilter.findIndex((x) => x.id === 3) !== -1
                      ? "selected-filter"
                      : ""
                  }`}
                >
                  <span
                    className="icon"
                    style={{
                      backgroundColor: "rgb(255, 184, 0)",
                      padding:
                        this.props.selectedFilter.findIndex((x) => x.id === 3) !== -1
                          ? "2.5px"
                          : "0px",
                    }}
                  >
                    {this.props.selectedFilter.findIndex((x) => x.id === 3) !== -1 ? (
                      <HubIcon lineColor="#fff" fontSize={13}>
                        <HiClose />
                      </HubIcon>
                    ) : (
                      <HiLine lineColor="rgb(255, 184, 0)" />
                    )}
                  </span>
                  <span className="label"> Favorites</span>
                </div>
              </div>
           :false}
        <div style={{ display: "flex", alignItems: "center" }}>
          <SearchArea
            onChange={this.props.setSearchString}
            value={this.props.searchString}
          />
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {" "}
            <MainButton
              className="hub-button new-icon-button"
              icon={<HiRefresh />}
              onClick={this.props.refresh}
              tooltip={"Refresh"}
            />
            {/*<MainButton className="icon-button-2" icon={faDownToBracket}  tooltip={"Export"}/> */}
            <MainButton
              className="hub-button new-icon-button"
              onClick={() => this.props.openSidebar("detail")}
              tooltip={"Sidebar"}
              icon={<HiLayoutRight />}
            />
            <MainButton
              className="hub-button new-icon-button"
              onClick={() => this.props.openSidebar("block")}
              tooltip={"Block List"}
              icon={<HiBlock />}
            />
          </div>

          {/* {this.props.config.view.mode==="gantt"?<MainButton className={this.props.hideGanttbar?"icon-button-2":"icon-button-2 active"} onClick={this.props.toggleGanttbar} tooltip={"Toggle Gantt"} icon={faBarsProgress}/>:false}
                    {this.props.config.sideBar?<MainButton className={this.props.isSideBarOpen?"icon-button-2 active":"icon-button-2"} onClick={this.props.toggleSidebar} tooltip={"Sidebar"} icon={faSidebarFlip}/>:false}
                    {this.props.config.tile?<MainButton className={this.props.config.view.mode=="tile"?"icon-button-2 active":"icon-button-2"} onClick={() => this.props.setViewMode("tile")} tooltip={"Sidebar"} icon={faGrid2}/>:false}
                    {this.props.config.view.gantt?<MainButton className={this.props.config.view.mode=="gantt"?"icon-button-2 active":"icon-button-2"} onClick={() => this.props.setViewMode("gantt")} tooltip={"Gantt View"} icon={faBarsStaggered}/>:false}
                    <MainButton className={this.props.config.view.mode=="list"?"icon-button-2 active":"icon-button-2"} onClick={() => this.props.setViewMode("list")} tooltip={"List View"}  icon={faTableList}/>*/}
        </div>
      </div>
    );
  }
}

export default Toolbar;

import React, { Component } from 'react';
import './FilterItemForm.css';
import Select from 'react-select';


class FilterItemFormText extends Component {
    constructor(props) {
        super(props);
        this.state = {options:[], filter:{ option: null, value: null}}

    }

    componentDidMount() {

        const options = [
            { value: 'is', label: 'Yes', indicator:"Yes", },
            ,{ value: 'isNot', label: 'No', indicator:"No" }
          ];
        

        if(this.props.column.filter){
            this.setState({options: options, filter: this.props.column.filter});
        } else {
            this.setState({...this.state, options: options});
        }

    }

    setFilter = () => {
        // filter olarak filter:{option:"", value:{}} değerini döner. option seçili koşulun codu, value ise türe göre çeşitli değerler
        if(this.state.filter){
                this.props.setFilter({option: this.state.filter.option, value:null});
        } else {
            this.props.setFilter(null);
        }
    }

    handleChange = (attr, value) => {
        let newFilter = {...this.state.filter};
        newFilter[attr] = value;
        this.setState({...this.state, filter: newFilter}, 
            () => {
                this.setFilter();
            })
    }

     

 
    render() {
        return (
            <div className="hub-dataTable-filterItem-form-wrapper">
                <div className="hub-dataTable-filterItem-form-row">
                    <Select
                        onChange = {(v) => {this.handleChange("option", v) }}
                        options={this.state.options}
                        value={this.state.filter.option}
                        isClearable = {false}
                        styles = {{
                            container: base => ({
                                ...base,
                                flex: "1",
                              }),
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default FilterItemFormText;
import React, { Component } from 'react';
import './FilterItemForm.css';
import Select from 'react-select';


class FilterItemFormDate extends Component {
    constructor(props) {
        super(props);
        this.state = {options:[], filter:{ option: null, value: []}}
    }

    componentDidMount() {

        const options = [
            { value: 'dateIs', label: 'Is', indicator:"=", },
            ,{ value: 'dateIsNot', label: 'Is Not', indicator:'\u2260' }
            ,{ value: 'null', label: 'Is Empty', indicator:"Is Empty", },
            ,{ value: 'notnull', label: 'Is Not Empty', indicator:"Is Not Empty" }
            ,{ value: 'dateBefore', label: 'Is Before', indicator:"<" }
            ,{ value: 'dateAfter', label: 'Is After', indicator:">" }
            ,{ value: 'dateBetween', label: 'Is Between', indicator: "Between" }
            ,{ value: 'dateNotBetween', label: 'Is Not Between', indicator:"Not Between" }
          ];
        

        if(this.props.column.filter){
            this.setState({options: options, filter: this.props.column.filter});
        } else {
            this.setState({options: options, filter: {option: { value: 'dateBetween', label: 'Is Between', indicator:"Between" }, value: []}});
        }

    }

    setFilter = () => {
        // filter olarak filter:{option:"", value:{}} değerini döner. option seçili koşulun codu, value ise türe göre çeşitli değerler
        if(this.state.filter){
            if(this.state.filter.option.value==="null" || this.state.filter.option.value==="notnull") {
                this.props.setFilter({option: this.state.filter.option, value:null});
            } else {
                let inCheck = ["dateIs", "dateIsNot", "dateBefore", "dateAfter"];
               
                if (this.state.filter.value[0]!==undefined && this.state.filter.value[0]!==null && inCheck.indexOf(this.state.filter.option.value)>-1 ){
                    //console.log(0)
                    this.props.setFilter({option: this.state.filter.option, value:[this.state.filter.value[0], null]});
                } else if (this.state.filter.value[0]!==null && this.state.filter.value[1]!==null && this.state.filter.value[0]!==undefined && this.state.filter.value[1]!==undefined){
                    this.props.setFilter(this.state.filter);  
                } else {
                    this.props.setFilter(null);
                }
            }
        } else {
            this.props.setFilter(null);
        }
    }

    handleChange = (attr, value) => {
        let newFilter = {...this.state.filter};
        newFilter[attr] = value;
        this.setState({...this.state, filter: newFilter}, 
            () => {
                this.setFilter();
            })
    }

 
    setValue = (i, e) => {

        //console.log(i, e.target.value);
        let newFilter = {...this.state.filter};
        newFilter.value[i] = e.target.value!==""?e.target.value:null;
        this.setState({...this.state, filter: newFilter}, () => {
            this.setFilter();
        })
    }

    handleKeyPress(e){
        if(e.key === "Enter"){
            e.target.blur(); 
          }
    }
     

 
    render() {
        return (
            <div className="hub-dataTable-filterItem-form-wrapper">
                <div className="hub-dataTable-filterItem-form-row">
                    <Select
                        onChange = {(v) => {this.handleChange("option", v) }}
                        options={this.state.options}
                        value={this.state.filter.option}
                        isClearable = {false}
                        styles = {{
                            container: base => ({
                                ...base,
                                flex: "1",
                              }),
                        }}
                    />
                </div>
                {(this.state.filter.option !== null && this.state.filter.option?.value !== "null" && this.state.filter.option.value !== "notnull")||this.state.filter.option===null?
                  <div className="hub-dataTable-filterItem-form-row">
                    <input type="date" onChange={(e) => {this.setValue(0, e)}} value={this.state.filter.value[0]} onKeyPress={this.handleKeyPress}  onBlur={this.setFilter}  className="hub-dataTable-filterItem-form-input" />
                    {this.state.filter?.option?.value === "dateBetween" || this.state.filter?.option?.value === "dateNotBetween"?
                        <input type="date" onChange={(e) => {this.setValue(1, e)}} value={this.state.filter.value[1]} onKeyPress={this.handleKeyPress}  onBlur={this.setFilter}  className="hub-dataTable-filterItem-form-input" />
                        :false}
                  </div>:false
                }
              
            </div>
        );
    }
}

export default FilterItemFormDate;
import React, { Component } from 'react'
import MainButton from '../../../common/buttons/MainButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBan, faPenToSquare, faCheck } from '@fortawesome/pro-regular-svg-icons'

import DynamicLookup from '../../../common/inputControls/lookup/DynamicLookup';


import TextArea from '../../../common/inputControls/textArea2/textArea';
import CardDetailSection from '../../taskBoard/card/comps/CardDetailSection';

import { DateUtils, Utils } from '../../../common/Commons';
import '../../taskBoard/card/block/cardBlock.css'


import { faC } from '@fortawesome/pro-thin-svg-icons';

export default class ActivityBlock extends Component {

  constructor(props) {
    super(props);
    this.state = {    
        showBlockResolveTooltip:false,
        showBlockTooltip:false,
        mode: "",  // new, blocked, resolved
        blockResolveDescription: "",
        blockDescription:"",
        blockOwner:"",
        blockReason:"",
      }
  }
  
  componentDidMount() { 

    //console.log("componentDidMount",this.props.task.blockReason)
    
    
    this.setState({...this.state, 
      mode: this.props.task.isBlocked?"blocked":""
 
      })
   }


  toggleBlockTooltip = () => {
    this.setState({...this.state, showBlockTooltip: !this.state.showBlockTooltip});
  }
  
  toggleBlockResolveTooltip = () => {    
    this.setState({...this.state, showBlockResolveTooltip:!this.state.showBlockResolveTooltip  }); 
  }

  // form ID işlevsiz gibi ancak aslında tek bir alan üzerinden formId ile ilerlesi planlanan bir form olması sebebiyle böyle görünüyor. Alt bölümün değişmesi lazım;
  onChangeBlockDescription = (formId, value) => {this.setState({...this.state, blockDescription: value}); }
  onChangeBlockReason=(formId, value) => { this.setState({...this.state, blockReason: value});  }
  onChangeBlockOwner= (formId, value) => { this.setState({...this.state, blockOwner: value});}
  onChangeBlockResolveDescription=(formId,value)=>{
    this.setState({...this.state, blockResolveDescription: value}); 
    
  }


  //  new, blocked, resolved, resolve
  chageMode=(mode)=>
  {
    this.setState({...this.state, mode: mode})
  }

  cancel=()=>
  {
   // console.log("cancel",this.state)
    this.setState({...this.state, mode: this.props.task.isBlocked===false && this.props.task.blockResolveDescription===null ?"new":this.props.task.blockResolveDescription===null ?"blocked":"resolved"})

    if(this.state.mode==="new" && this.state.blockDescription===null)
    {
      this.props.closeBlock()
    }
  }



  

  render() {
      //console.log("block",this.props)

      let util = new DateUtils();
      let t=this.props.task;
      if(this.props.activeTab === "block" && this.state.mode!=="") {
        return (
        
        <CardDetailSection markerLeft={94+(this.props.markerLeft)} key="cardBlockSection" show={this.props.show}>
          <div className="card-section-header">
            <div className="title">
              <span><FontAwesomeIcon icon={faBan} /></span>
              <span>Block List</span>
            </div>
          
          </div>
   
        {this.state.mode==="blocked" || this.state.mode==="resolved" || this.state.mode==="resolve"?

        t.blockedTasks.map((bt)=>{

        
           return <div className={'block'+(this.state.mode==="resolved"?" resolved":"")}>
              <div className={'block-task-name'}>{bt?.name}</div>
              <div className='block-header'>
                <div className={'block-reason'+(this.state.mode==="resolved"?" resolved":"")}>{bt.blockReason?.name}</div>
                <div className='block-date'> {util.Date2StrMonthDayHour(bt.blockCreatedDate)}</div>
              </div>
                
                
              {bt.blockOwner?
                  <div title="Block Owner and Created Date" className='block-owner'>{bt.blockOwner?.fullName}</div>
              :<div  className='block-footer'/>}

              <div className='block-description'>{bt.blockDescription}</div>
            </div>
        })
        :false}

        {this.state.mode==="resolved"?
        <>
          <div className='block-line'></div>
          <div className='resolve'>

                    <div className='block-header'>
                      <div className='block-resolve-reason'>Resolved</div>
                      <div className='block-date'> {util.Date2StrHour(t.blockResolveDate) }</div>
                    </div>
                  
                  {t.blockResolveBy?
                    <div title="Resolved By and Resolve Date"  className='block-owner'>{t.blockResolveBy?.fullName}</div>
                  :<div  className='block-footer'/>}
                  <div className='block-description'>{t.blockResolveDescription}</div>
          </div>
        </>
        :false}

 
      </CardDetailSection> );  
      } else { return false };
   }
}

import React, { Component } from "react";
import DataTable from "../../common/dataTable";
import TaskAssignmentSidebar from "./TaskAssignmentSidebar.js";
import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import MainButton from "../../common/buttons/MainButton";
import Auth from "../../auth/Auth";

class TaskAssignmentExpanded extends Component {
  constructor(props) {
    super(props);
    this._dataTable = React.createRef();
    this.state = {
      isNew: false,
    };
  }
  openSidebar = () => {
    this.setState(
      { ...this.state, isNew: !this.state.isNew },
      this._dataTable.current.toggleSidebar()
    );
  };

  render() {
    var responsibleOBS = this.props.selectedRecord?.owner?.obsInfo?.uid;

    // console.log(this.props.selectedRecord?.owner)
    var sessionUserOBS = Auth.getResourceObject().obsId;
    var sessionUserManagerOBS =
      Auth.getResourceObject().managerInfo?.obsInfo?.uid;
    let secProfile = Auth.getResourceObject().securityProfile?.name;

    let dtConfig = {
      endPoint:
        "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/task/" +
        this.props.selectedRecord.uid +
        "/assignment",
      columns: [
        { id: "uid", dataKey: "uid", label: "UID", type: "text" },
        { id: "code", dataKey: "code", label: "Code", type: "text" },
        {
          id: "ownerInfo",
          dataKey: "owner.fullName",
          label: "Resource",
          type: "text",
        },
        {
          id: "roleInfo",
          dataKey: "role.name",
          label: "Assignment Role",
          type: "text",
        },
        {
          id: "startDate",
          dataKey: "startDate",
          label: "Start Date",
          type: "date",
        },
        {
          id: "finishDate",
          dataKey: "finishDate",
          label: "Finish Date",
          type: "date",
        },
        {
          id: "actuals",
          dataKey: "actualUnit",
          label: "Actual Units",
          type: "hour",
        },
        {
          id: "remainingUnit",
          dataKey: "remainingUnit",
          label: "Remainig Units",
          type: "hour",
        },
      ],
      idAttribute: "uid",
      nameAttribute: "name",
      view: {
        visibleColumnList: [
          "ownerInfo",
          "roleInfo",
          "etc",
          "startDate",
          "finishDate",
          "actuals",
          "remainingUnit",
        ],
        mode: "list",
        hideToolbar: false,
        hideActionbar: false,
        hideSelectbox: false,
      },
      sideBar: this.props.selectedRecord.isTask ? (
        <TaskAssignmentSidebar
          parentRecord={this.props}
          updateTask={this.props.updateTask}
        />
      ) : (
        ""
      ),
      parentRecord: this.props.selectedRecord,
      button:
        ((secProfile == "BMUH" || secProfile == "PY") &&
          (responsibleOBS == sessionUserOBS ||
            responsibleOBS == sessionUserManagerOBS)) ||
        secProfile != "BMUH" ||
        (secProfile !== "PY" && secProfile == "MUH")
          ? [
              {
                key: "newAssignment",
                className: "",
                onClick: this.toggleSidebar,
                label: "New Assignment",
                type: "new",
              },
              {
                key: "deleteAssignment",
                className: "",
                onClick: this.toggleSidebar,
                label: "Delete",
                type: "delete",
                endpoint: "/api/assignment",
              },
            ]
          : secProfile == "MUH"
          ? [
              {
                key: "newAssignment",
                className: "",
                onClick: this.toggleSidebar,
                label: "New Assignment",
                type: "new",
              },
            ]
          : "",
    };

    //console.log("activityExpanded",this.props.selectedRecord.isTask,secProfile,responsibleOBS,sessionUserOBS)

    //  console.log("taskaasignexpanded", this.props)
    //<MainButton onClick={this.toggleSidebar} key="newAssignmentButton" className="button-2" icon={false} label="New Assignment" />
    return (
      <PageArea>
        <DataTable
          ref={this._dataTable}
          key="OWAssignmentTable"
          config={dtConfig}
        />
      </PageArea>
    );
  }
}

export default TaskAssignmentExpanded;

import "./TRBar.scss";

/**
 * TRBar is a component that renders a styled container, typically used for table rows with additional content or actions.
 * It accepts custom styles and children elements, making it flexible for various use cases within table layouts.
 *
 * @param {object} [style] Additional inline styles to apply to the component.
 * @param {JSX.Element | JSX.Element[]} children The content to be rendered inside the component, typically representing a table row's additional bar or actions.
 * @returns {JSX.Element} The rendered TRBar component.
 *
 * @example
 * // Render a TRBar component with custom content
 * <TRBar style={{ backgroundColor: '#e0e0e0' }}>
 *   <div>Additional Row Content</div>
 *   <div>Actions</div>
 * </TRBar>
 */
const TRBar = ({
  children,
  isSelected,
  onClick,
  style,
  onMouseEnter,
  onMouseLeave,
  isHovered
}) => {
  return (
    <div
      className={`hud-dt-td-row-bar ${isSelected ? "selected" : ""} ${
        isHovered ? "hovered" : ""
      }`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...style }}
    >
      {children}
    </div>
  );
};

export default TRBar;

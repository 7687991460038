import React, { Component, useEffect, useState } from "react";

import CardAvatar from "./CardAvatar.js";
import NewAssignmentIcon from "./NewAssignmentIcon.js";

const CardAvatarGroup = ({
  task,
  taskResource,
  isExpanded,
  setAssignmentTab,
  index,
  columnIndex,
  assignmentOperations,
  openAssignmentPanel,
  closeAssignmentPanel,
}) => {
  const [selectedResourceIndex, setSelectedResourceIndex] = useState(null);
  const [extraAvatarCount, setExtraAvatarCount] = useState(0);

  let taskIds = task.uid;
  let limit = 2;
  let left = -7;
  //let resourceArray=taskResource.find(x => x.taskId === taskId).resourceList;
  let assignmentArray = taskResource;
  // assignmentArray = [...assignmentArray, ...assignmentArray, ...assignmentArray, ...assignmentArray]
  let length = assignmentArray?.length;
  let remainingLength = length - limit;
  let newArray = assignmentArray?.slice(0, limit);

  const processArray = (arr) => {
    const length = arr.length;

    if (length <= 7) {
      return arr.slice(0, 7); // Return all elements if there are 7 or fewer
    }

    if (length === 8) {
      return arr.slice(0, 8); // Return all 8 elements if there are exactly 8
    }

    if (length > 8) {
      return arr.slice(0, 7);
    }
  };

  const updatedArray = processArray(assignmentArray);

  useEffect(() => {
    !isExpanded && setSelectedResourceIndex(null);
    if (!!isExpanded && length !== 8 && length > 7) {
      setExtraAvatarCount(length - 7);
    } else if (!isExpanded && length > 2) {
      setExtraAvatarCount(length - 2);
    } else {
      setExtraAvatarCount(undefined);
    }
  }, [isExpanded]);

  return (
    <div
      className={"avatar-group" + (isExpanded ? " active" : "")}
      style={{ width: "100%" }}
    >
      <NewAssignmentIcon
        setAssignmentTab={() => {
          setSelectedResourceIndex("add");
          setAssignmentTab("newAssignment", "new");
        }}
        task={task}
        taskIndex={index}
        columnIndex={columnIndex}
        assignmentOperations={assignmentOperations}
        isSelected={selectedResourceIndex === "add"}
      />
      {updatedArray?.map((val, i) => {
        return (
          <CardAvatar
            key={val.uid}
            setAssignmentTab={() => {
              setSelectedResourceIndex(i);
              setAssignmentTab("newAssignment", "edit", val, i);
            }}
            openAssignmentPanel={openAssignmentPanel}
            closeAssignmentPanel={closeAssignmentPanel}
            style={{
              marginLeft: !isExpanded ? (i >= limit ? -30 : left) : 0,
            }}
            task={task}
            assignment={val}
            assignmentIndex={i}
            taskIndex={index}
            columnIndex={columnIndex}
            assignmentOperations={assignmentOperations}
            isSelected={selectedResourceIndex === i}
          />
        );
      })}
      {!!extraAvatarCount && (
        <CardAvatar size={extraAvatarCount} right={left} />
      )}
    </div>
  );
};

export default CardAvatarGroup;

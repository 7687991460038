import React, { Component } from 'react';
import DataTable from './../../../common/dataTable';
import {Utils} from './../../../common/Commons';
import { useParams } from 'react-router';

import GanttSidebar from './../../../pages/task/GanttSidebar';
import ModalTask from '../../../pages/task/modalTask';



class WBS extends Component {

    render() {
        const dtConfig = {
            endPoint: '/api/projects/'+this.props.params.projectid+"/wbs", 
            idAttribute: "uid",
            nameAttribute: "name",
            columns: [
                {id:"code", dataKey:"code", label:"ID", type:"text"}
                ,{id:"name", dataKey:"name", label:"Name", type:"text"}
                ,{id:"objectType", dataKey:"objectTypeInfo.name", label:"Type", type:"text"}
                ,{id:"startDate", dataKey:"startDate", label:"Start", type:"date"}
                ,{id:"finishDate", dataKey:"finishDate", label:"Finish", type:"date"}
                ,{id:"status", dataKey:"status.name", label:"Status", type:"text", color: "task_status_color"}
                ,{id:"percentComplete", dataKey:"percentComplete", label:"% Complete", type:"percentage"}
                ,{id:"actualStart", dataKey:"actualStart", label:"Actual Start", type:"date"}
                ,{id:"actualFinish", dataKey:"actualFinish", label:"Actual Finish", type:"date"}
                ,{id:"owner", dataKey:"owner.fullName", label:"Responsible", type:"text"}
                
                ,{id:"createdBy", dataKey:"createdBy.fullName", label:"Created By", type:"text"}
                ,{id:"createdDate", dataKey:"createdDate", label:"Created Date", type:"date"}
                ,{id:"extCode", dataKey:"extCode", label:"P6 Object ID", type:"text"}
                ,{id:"extType", dataKey:"extType", label:"Activity Type", type:"text"}

              
                ,{id:"level", dataKey:"level", label:"Level", type:"text"}

                ,{id:"obsInfo", dataKey:"obsInfo.name", label:"OBS", type:"text"}
                
                ,{id:"pendingActual", dataKey:"pendingActual", label:"Pending Actual (h)", type:"text"}


                ,{id:"p6Plan.plannedStart", dataKey:"p6Plan.plannedStart", label:"Planned Start (P6)", type:"date"}
                ,{id:"p6Plan.plannedFinish", dataKey:"p6Plan.plannedFinish", label:"Planned Finish (P6)", type:"date"}
                ,{id:"p6Plan.plannedDuration", dataKey:"p6Plan.plannedDuration", label:"Planned Duration (P6)", type:"day"}
                ,{id:"p6Plan.plannedUnits", dataKey:"p6Plan.plannedUnits", label:"Planned Units (P6)", type:"hour"}
                
                ,{id:"p6Plan.actualStart", dataKey:"p6Plan.actualStart", label:"Actual Start (P6)", type:"date"}
                ,{id:"p6Plan.actualFinish", dataKey:"p6Plan.actualFinish", label:"Actual Finish (P6)", type:"date"}
                ,{id:"p6Plan.actualDuration", dataKey:"p6Plan.actualDuration", label:"Actual Duration (P6)", type:"day"}
                ,{id:"p6Plan.actualUnits", dataKey:"p6Plan.actualUnits", label:"Actual Units (P6)", type:"hour"}

                ,{id:"p6Plan.baselineStart", dataKey:"p6Plan.baselineStart", label:"Baseline Start (P6)", type:"date"}
                ,{id:"p6Plan.baselineFinish", dataKey:"p6Plan.baselineFinish", label:"Baseline Finish (P6)", type:"date"}
                ,{id:"p6Plan.baselineDuration", dataKey:"p6Plan.baselineDuration", label:"Baseline Duration (P6)", type:"day"}
                ,{id:"p6Plan.baselineUnits", dataKey:"p6Plan.baselineUnits", label:"Baseline Units (P6)", type:"hour"}

                ,{id:"p6Plan.remainingDuration", dataKey:"p6Plan.remainingDuration", label:"Remaining Duration (P6)", type:"day"}
                ,{id:"p6Plan.remainingUnits", dataKey:"p6Plan.remainingUnits", label:"Remaining Units (P6)", type:"hour"}

                ,{id:"p6Plan.atCompletionDuration", dataKey:"p6Plan.atCompletionDuration", label:"At Completion Duration (P6)", type:"day"}
                ,{id:"p6Plan.atCompletionUnits", dataKey:"p6Plan.atCompletionUnits", label:"At Completion Units (P6)", type:"hour"}


                ,{id:"plannedStart", dataKey:"plannedStart", label:"Planned Start", type:"date"}
                ,{id:"plannedFinish", dataKey:"plannedFinish", label:"Planned Finish", type:"date"}
              
                ,{id:"plannedUnits", dataKey:"plannedUnit", label:"Planned Units", type:"hour"}
                

            
                ,{id:"actualUnits", dataKey:"actualUnit", label:"Actual Units", type:"hour"}

            
              
                ,{id:"remainingUnit", dataKey:"remainingUnit", label:"Remaining Units", type:"hour"}

            
                ,{id:"atCompletionUnits", dataKey:"atCompletionUnit", label:"At Completion Units", type:"hour"}

                ,{id:"predecessor", dataKey:"predecessor", label:"Predecessor", type:"text"}

                ,{id:"isBlocked", dataKey:"isBlocked", label:"Is Blocked", type:"boolean"}
                ,{id:"blockCreatedDate", dataKey:"blockCreatedDate", label:"Block Created Date", type:"date"}
                ,{id:"blockOwner", dataKey:"blockOwner.fullName", label:"Block Owner", type:"text"}
                ,{id:"blockReason", dataKey:"blockReason.name", label:"Block Reason", type:"text"}
                ,{id:"blockDescription", dataKey:"blockDescription", label:"Block Description", type:"text"}

                ,{id:"blockResolveBy", dataKey:"blockResolveBy.fullName", label:"Block Resolve By", type:"text"}
                ,{id:"blockResolveDate", dataKey:"blockResolveDate", label:"Block Resolve Date", type:"date"}
                ,{id:"blockResolveDescription", dataKey:"blockResolveDescription", label:"Block Resolve Description", type:"text"}
                ,{id:"taskAssigmentNames", dataKey:"taskAssigmentNames", label:"Assigments", type:"text"}
                ,{id:"childrenCount", dataKey:"childrenCount", label:"Children Count", type:"number"}
                ,{id:"tag", dataKey:"tag", label:"Tag", type:"tag"}
                ,{id:"responsible", dataKey:"responsible.fullName", label:"Responsible PM", type:"text"}
                //,{id:"successor", dataKey:"successor", label:"Successor", type:"text"}
                ,{id:"ipt", dataKey:"ipt", label:"Responsible IPT", type:"text"}
                ,{id:"mspWbsCode", dataKey:"mspWbsCode", label:"MSP WBS Code", type:"text"}
                

            ], 
            childAttribute: "children",
            sideBar: <GanttSidebar project={this.props.project} />,
            modal: <ModalTask />,
            fileName: "Project Tasks",
            pageId:"wbsList",
            projectId:parseInt(this.props.params.projectid),
            project:this.props.project,
            modalOptions:{height:"50%",width:"50%"},
            view: {
                visibleColumnList: ["code", "name","objectType", "startDate", "finishDate", "status","percentComplete","actualStart","actualFinish","createdBy","createdDate"],
                mode:"gantt",
                hideSelectbox:false,
                selectBoxCondition:{key:"isTask",value:true},
                deleteAvailable:"/api/task",
                showCollapse:true,
                copyAvailable:true,
                gantt: { 
                    
                }
            },

            prebuiltFilters: [
                {
                    code:"completedTasks1",
                    label: "Completed Tasks",
                    color: "#1BC94C",
                    filterSet:[{                

                        id:"percentComplete", dataKey:"percentComplete", label:"% Complete", type:"percentage"
                        ,filter: {
                            option:{indicator: "=", label: "Is Equal", value: "equal"},
                            value: ['100',null],
                            valueIds:[] }
                        
                        }
                    ]
                },
                {
                    code:"notCompletedTasks",
                    label: "Not Completed Tasks",
                    color: "#EA2246",
                    filterSet:[{                

                        id:"percentComplete", dataKey:"percentComplete", label:"% Complete", type:"percentage"
                        ,filter: {
                            option:{indicator: "<", label: "Is Lower Than", value: "numberLt"},
                            value: ['100',null],
                            valueIds:[] }
                        
                        }
                    ]
                },
                {
                    code:"undetailedActivity",
                    label: "Empty Activities",
                    color: "#69215e",
                    filterSet:[
                        {                
                           id:"objectType", dataKey:"objectTypeInfo.name", label:"Type", type:"text"
                            ,filter: {
                                option:{indicator: "*=*", label: "Contains", value: "contains"},
                                value: "activity",
                                }
                        
                        },
                        {                
                            id:"extType", dataKey:"extType", label:"Activity Type", type:"text"
                             ,filter: {
                                 option:{indicator: "*!=*", label: "Not Contains", value: "notcontains"},
                                 value: "Milestone",
                                 }
                         
                         },
                         
                         {                
                            id:"childrenCount", dataKey:"childrenCount", label:"Children Count", type:"number"
                             ,filter: {
                                 option:{indicator: "=", label: "Is Equal", value: "equal"},
                                 value: ['0', null],
                                 }
                         
                         }

                         
                    ]
                },
                {
                    code:"assignmentMoreThan1",
                    label: "Assignments > 1",
                    color: "#FFB800",
                    filterSet:[{                

                        id:"taskAssigmentNumber", dataKey:"taskAssigmentNumber", label:"Assigment Count", type:"number"
                        ,filter: {
                            option:{indicator: ">", label: "Is Greater Than", value: "numberGt"},
                            value: ['1',null],
                            valueIds:[] }
                        
                        }
                    ]
                }
            ],
        }

        //console.log("wbs",this.props)
        return (
            <div className="hub-page" >
                <DataTable config={dtConfig}  />
            </div>
        );
    }
}



export default (props) => (
    <WBS 
        {...props}
        params={useParams()}
        />
);
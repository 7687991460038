import { useEffect, useRef, useState, useContext } from "react";

import "./ProfileSettings.scss";
import ImageCropper from "../../common/imageCropper";
import Avatar from "../../common/avatar";
import MainButton from "../../common/buttons/MainButton";
import HiUpload from "../../common/icons/HiUpload";
import HubIcon from "../../common/hubIcon/HubIcon";
import HiCamera from "../../common/icons/HiCamera";
import HiEdit1 from "../../common/icons/HiEdit1";
import Auth from "../../auth/Auth";
import { Utils, checkFileSize, formatBytes } from "../../common/Commons";
import CommonContext from "../../store/common-context";

const urlToFile = async (url, filename, mimeType) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
};

const ProfileSettings = () => {
  const [isShownOverlay, setIsShownOverlay] = useState(false);
  const [isCropMode, setIsCropMode] = useState(false);
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [mode, setMode] = useState(null);

  const { userInfo, configs } = useContext(CommonContext);

  const ref = useRef();

  useEffect(() => {
    if (mode === "edit") {
      setImage(
        `${window.location.origin}/api/profileImage/${userInfo?.uid}/resourceImageType?imageType=profile`
      );
      handleConvert();
    }
  }, [mode]);

  const maxSize = formatBytes(+configs?.fileConfig?.valueString);

  const handleConvert = async () => {
    try {
      const file = await urlToFile(
        `${window.location.origin}/api/profileImage/${userInfo?.uid}/resourceImageType?imageType=profile`,
        `${userInfo?.profileImage.profileImageName}${userInfo?.profileImage.profileImageExtension}`,
        userInfo?.profileImage.profileImageMimeType
      );
      setFile(file);
    } catch (error) {
      console.error("Error converting URL to file:", error);
    }
  };

  const utils = new Utils();

  const handleSelectFile = () => {
    ref.current.click();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const fileData = e.dataTransfer.files[0];

    const isAccepted = checkFileSize(
      +configs?.fileConfig?.valueString,
      fileData?.size
    );

    const validFile =
      fileData?.type === "image/jpeg" || fileData?.type === "image/png";
    if (validFile && isAccepted) {
      setFile(fileData);
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setImage(reader.result);
        setIsCropMode(true);
        setMode("new");
      };
      reader.readAsDataURL(fileData);
    } else if (!validFile) {
      utils.addNotification("error", "Only JPEG and PNG files are allowed!");
    } else if (!isAccepted) {
      utils.addNotification(
        "error",
        `File is too large. Please upload a file smaller than ${maxSize}.`
      );
    }
    ref.current.value = "";
  };

  const handleFileChange = (event) => {
    const fileData = event.target.files[0];

    const isAccepted = checkFileSize(
      +configs?.fileConfig?.valueString,
      fileData?.size
    );

    if (isAccepted) {
      setFile(fileData);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setIsCropMode(true);
        setMode("new");
      };
      reader.readAsDataURL(fileData);
    } else {
      utils.addNotification(
        "error",
        `File is too large. Please upload a file smaller than ${maxSize}.`
      );
    }

    ref.current.value = "";
  };

  const handleDragOver = (e) => {
    !isShownOverlay && setIsShownOverlay(true);
    e.preventDefault();
  };

  const handleCloseCropper = (uploadedAvatar = false) => {
    setIsShownOverlay(false);
    setIsCropMode(false);
  };

  const handleClickEdit = () => {
    setMode("edit");
    setIsCropMode(true);
  };

  return (
    <div className="profile-settings">
      {isCropMode && image ? (
        <ImageCropper
          uid={userInfo?.uid}
          image={image}
          onSelectFile={handleSelectFile}
          onCloseCropper={handleCloseCropper}
          mainFile={file}
          mode={mode}
        />
      ) : (
        <div className="hub-new-avatar-section" onDragOver={handleDragOver}>
          <div
            className="hub-new-avatar-wrapper"
            onMouseEnter={() => setIsShownOverlay(true)}
            onMouseLeave={() => setIsShownOverlay(false)}
          >
            <Avatar
              firstName={userInfo?.firstName}
              lastName={userInfo?.lastName}
              uid={userInfo?.uid}
              color={userInfo?.color}
              style={{
                width: 132,
                height: 132,
              }}
              size="lg"
              hasAvatar={
                userInfo?.profileImage?.cropImageHash &&
                userInfo?.profileImage?.profileImageHash
              }
            />
            <div className="avatar-action-buttons">
              {userInfo?.profileImage?.cropImageHash &&
              userInfo?.profileImage?.profileImageHash ? (
                <MainButton
                  onClick={handleClickEdit}
                  className="secondary"
                  icon={<HiEdit1 />}
                  style={{
                    width: 30,
                    height: 34,
                  }}
                />
              ) : (
                <MainButton
                  onClick={handleSelectFile}
                  className="secondary"
                  icon={<HiUpload />}
                  style={{
                    width: 30,
                    height: 34,
                  }}
                />
              )}
            </div>
            <div
              className={`avatar-overlay ${isShownOverlay ? "" : "hide"}`}
              onDrop={handleDrop}
              onClick={handleSelectFile}
            >
              <HubIcon fontSize={32} lineColor="#fff">
                <HiCamera />
              </HubIcon>
              <p>Drag photo here</p>
            </div>
          </div>
        </div>
      )}

      <p className="user-name">{userInfo?.fullName}</p>
      <p className="user-role">{userInfo?.roleInfo?.name}</p>
      <input
        id="fileInput"
        ref={ref}
        style={{ display: "none" }}
        type="file"
        accept="image/jpeg, image/png"
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ProfileSettings;

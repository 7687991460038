import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowDownLong, faPeopleGroup, faBriefcase, faUser, faFolderGrid, faNetworkWired, faBolt, faUserGroup}  from '@fortawesome/pro-solid-svg-icons'


class LevelIcon extends Component {

     /* types
        obsParent
        obs
        resource
        role
        project
        wbs
        activity
        
    */

    render() {

        const icons = {
            obsParent: faPeopleGroup, 
            obs: faUserGroup, 
            resource: faUser, 
            role:faBriefcase,
            project: faFolderGrid, 
            wbs: faNetworkWired, 
            activity: faBolt
        };
        return (<FontAwesomeIcon icon={icons[this.props.type]} {...this.props} />);
    }
}

export default LevelIcon;
import React, { Component } from 'react';
import './ChangeSetActivitiesTable.css';
import TD from './TD';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHexagonExclamation}  from '@fortawesome/pro-duotone-svg-icons'
import ActivitiesTableHexaIcon from './activitiesTableHexaIcon';
//import TD from './../../../common/dataTable/TD';

const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object)

class ChangeSetActivitiesTable extends Component {

    getWarnClass = (impact) => {
        switch (impact) {
            case "Low":
                return "hub-changeSet-activityTable-warnGreen"
                break;
            case "Medium":
                return "hub-changeSet-activityTable-warnYellow"
                break;
            case "High":
                return "hub-changeSet-activityTable-warnRed"
                break;
            default:
                return "";
                break;
        }
    }

    ganttContainerScrollHorizontal = (e) => {
        document.getElementById("hub-changeSet-activityTable-p6Plan").scrollTop = e.target.scrollTop;
        document.getElementById("hub-changeSet-activityTable-hubPlan").scrollTop = e.target.scrollTop;
        document.getElementById("hub-changeSet-activityTable-activities").scrollTop = e.target.scrollTop;

    }


    ganttContainerScrollHandler = (e) => {
        
        //document.getElementById("hub-gantt-table-header").scrollLeft = e.target.scrollLeft;
        document.getElementById("hub-changeSet-activityTable-hubPlan").scrollLeft = e.target.scrollLeft;
        document.getElementById("hub-changeSet-activityTable-p6Plan").scrollLeft = e.target.scrollLeft;

        document.getElementById("hub-changeSet-activityTable-p6Plan").scrollTop = e.target.scrollTop;
        document.getElementById("hub-changeSet-activityTable-hubPlan").scrollTop = e.target.scrollTop;
        document.getElementById("hub-changeSet-activityTable-activities").scrollTop = e.target.scrollTop;
        //document.getElementById("hub-gantt-header").style.top =  e.target.scrollTop.toString() + "px";
    }

    render() {
        const columnMap = [
            { code: "plannedStart", width:"110px", dataKey:"plannedStart", label:"Planned Start", type:"date" }
            ,{ code: "plannedFinish", width:"110px", dataKey:"plannedFinish", label:"Planned Finish", type:"date" }
            ,{ code: "actualStart", width:"110px", dataKey:"actualStart", label:"Actual Start", type:"date" }
            ,{ code: "actualFinish", width:"110px", dataKey:"actualFinish", label:"Actual Finish", type:"date" }
           // ,{ code: "plannedUnits", width:"110px", dataKey:"plannedUnit", label:"Planned Units", type:"hour" }
            ,{ code: "actualUnits", width:"110px", dataKey:"actualUnit", label:"Actual Units", type:"hour" }
            ,{ code: "remainingUnits", width:"130px", dataKey:"remainingUnit", label:"Remaining Units", type:"hour" }
            ,{ code: "atCompletionUnits", width:"150px", dataKey:"atCompletionUnit", label:"At Completion Units", type:"hour" }
           // ,{ code: "unitPctComplete", width:"150px", dataKey:"unitPctComplete", label:"Unit % Complete", type:"number" }
        ];
        return (
            <>
            <div style={{backgroundColor:"#f5f7fa", width:"calc(100% - 90px)", position:"absolute", margin:"16px", height:"32px", borderBottom: "1px solid #d1d2d5"}}></div>
            <div className="hub-changeSet-activityTable">
                <div className="activities" id="hub-changeSet-activityTable-activities" onScroll={this.ganttContainerScrollHorizontal}>
                    <div className="tableHeader">ACTIVITY INFO</div>
                    <table cellPadding="0" border="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th width="24px"><div style={{width:"24px", textAlign:"center"}}></div></th>
                                <th width="120px"><div style={{width:"120px", textAlign:"center"}}>Name</div></th>
                                <th width="120px"><div style={{width:"120px", textAlign:"center"}}>ID</div></th>
                                <th width="120px"><div style={{width:"120px", textAlign:"center"}}>WBS</div></th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.data.map(d => <tr>
                            <td align="center">
                               <ActivitiesTableHexaIcon activity={d}/>
                            </td>
                            <TD width="120px" value={resolvePath(d,"activityName")} type="text" />
                            <TD width="120px" value={resolvePath(d,"activityCode")} align="center" type="text" />

                            <TD width="120px" value={resolvePath(d,"wbsCode")} type="text" />
                            </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="hubPlan" id="hub-changeSet-activityTable-hubPlan" onScroll={this.ganttContainerScrollHandler}>
                    <div className="tableHeader">HUB</div>
                    <table cellPadding="0" border="0" cellSpacing="0">
                        <thead>
                            <tr>
                            {columnMap.map(c=><th width={c.width}><div style={{width:c.width, textAlign:"center"}}>{c.label}</div></th>)}
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.data.map(d => <tr>
                            {columnMap.map( (c, ix) => {
                                //console.log(c.dataKey,d.hubImpact[c.dataKey])
                                return <TD className={"td-"+d.hubImpact[c.dataKey].impact} value={resolvePath(d.hub,c.dataKey)} align="center" type={c.type} />
                            })}
                        </tr>)}
                        </tbody>
                    </table>
                </div>
                <div className="p6Plan" id="hub-changeSet-activityTable-p6Plan" onScroll={this.ganttContainerScrollHandler}>
                    <div className="tableHeader">P6</div>
                    <table cellPadding="0" border="0" cellSpacing="0">
                        <thead>
                            <tr>
                                {columnMap.map(c=><th width={c.width}><div style={{width:c.width, textAlign:"center"}}>{c.label}</div></th>)}
                            </tr>
                        </thead>
                        <tbody>
                        {this.props.data.map(d => <tr>
                            {columnMap.map( (c, ix) => {
                                return <TD value={resolvePath(d.p6,c.dataKey)} align="center" type={c.type} />
                            })}
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>
            </>
        );
    }
}

export default ChangeSetActivitiesTable;
import React from "react";

const HiTimeSheets = ({ bgColor, lineColor }) => {
  return (
    <svg
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17.5 9.58333V7.33333C17.5 5.9332 17.5 5.23313 17.2275 4.69835C16.9878 4.22795 16.6054 3.8455 16.135 3.60581C15.6002 3.33333 14.9001 3.33333 13.5 3.33333H6.5C5.09987 3.33333 4.3998 3.33333 3.86502 3.60581C3.39462 3.8455 3.01217 4.22795 2.77248 4.69835C2.5 5.23313 2.5 5.9332 2.5 7.33333V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333H10.4167M17.5 8.33333H2.5M13.3333 1.66666V5M6.66667 1.66666V5M15 17.5V12.5M12.5 15H17.5"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiTimeSheets;

import React, { Component } from "react";
import TimesheetProgressBar from "./tsdetails/TimesheetProgressBar";
import TimesheetTable from "./tsdetails/TimesheetTable";

import "./TimesheetSideSummary.css";

import { Link } from "react-router-dom";

import Modal from "./../../common/modal";

import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";

import { faFileSpreadsheet } from "@fortawesome/pro-light-svg-icons";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

import Auth from "./../../auth/Auth";
import { Utils } from "./../../common/Commons";
import MainButton from "../../common/buttons/MainButton";

export default class TimesheetSideSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      timesheet: {},
      showModal: false,
    };
  }

  componentDidMount() {
    this.getDetails();
  }

  showModal = () => {
    this.setState({ ...this.state, showModal: true }, () => {
      document
        .getElementById("root")
        .appendChild(document.getElementById("hub-modalWrapper"));
    });
  };

  hideModal = () => {
    this.setState({ ...this.state, showModal: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedRecord.uid !== this.props.selectedRecord.uid) {
      this.setState({ Loading: true, Status: "Loading" });
      this.getDetails();
    }
  }
  routeWF(action) {
    // Action değeri Approve, Revision, Submit değerlerinden birini alabilir.
    fetch("/api/timesheet/" + this.state.timesheet.uid + "/action/" + action, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status == 200) {
          if (r.body.isSuccess) {
            this.getDetails();
          }
        } else if (r.status == 401) {
          //window.location.replace("/login");
        } else if (r.status == 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  }

  getDetails = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    // Timesheet varsa detayları alınıyor.
    fetch(
      "/api/timesheetdetails/timesheet/resource/" +
        this.props.selectedRecord.resource.uid +
        "/timeperiod/" +
        this.props.selectedRecord.timeperiodId,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status == 200) {
          let newTimesheet = { ...r.body.object };
          let totals = [];

          // totals dizisi reportperiod dizisi üzerinden oluşturuluyor.
          newTimesheet.Days.map((m) => {
            totals.push(0.0);
          });

          let responsibles = []; //Program Manager'lar buttonlar için

          //reportLine'lar totals'a ekleniyor.
          newTimesheet.timeReportLines.map((l) => {
            if (l.responsible) {
              if (l.responsible.uid) {
                responsibles.push(l.responsible.uid);
              }
            }
            l.worklogs.map((w, i) => {
              totals[i] = totals[i] + w.actual;
            });
          });

          newTimesheet.totals = totals;

          const loggedInResourceId = Auth.checkResourceId();

          // Butonların gösterimi
          let showWFButtons = 0;
          // stageler
          // open
          // chief_engineer_approval
          // program_manager_approval
          // approved
          // posted
          // revision

          // Show WF Buttons
          // 1 -> Submiti göster
          // 2 -> Approve Reject
          // 3 -> Sadece Reject

          if (
            "open" == newTimesheet.status.code &&
            newTimesheet.resource.uid == loggedInResourceId
          ) {
            showWFButtons = 1;
          }

          if (
            newTimesheet.stage.code == "chief_engineer_approval" &&
            newTimesheet.resource.managerId == loggedInResourceId
          ) {
            showWFButtons = 2;
          }

          if (
            newTimesheet.stage.code == "program_manager_approval" &&
            responsibles.indexOf(parseInt(loggedInResourceId)) > -1
          ) {
            showWFButtons = 2;
          }

          this.setState({
            ...this.state,
            Loading: false,
            timesheet: newTimesheet,
            showWFButtons: showWFButtons,
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    return (
      <PageArea>
        {!this.state.Loading ? (
          <>
            <BodyArea>
              <TimesheetProgressBar
                style={{ maxWidth: "100%" }}
                availability={this.state.timesheet.availability}
                actual={this.state.timesheet.actual}
              />
              <h2 className="hub-timesheetSideSummary-h2">Time Entries</h2>
              <div className="hub-timesheetSideSummary-table">
                <table cellPadding="0" cellSpacing="0" width="100%">
                  <thead>
                    <tr>
                      <th>Work</th>
                      <th style={{ textAlign: "center" }}>Actuals</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.timesheet.timeReportLines.map((l) => {
                      return (
                        <tr>
                          <td width="80%">
                            <span className="project">{l.project}</span>
                            <span className="task"> {l.task} </span>
                            <span className="activity"> {l.activity}</span>
                          </td>
                          <td width="20%" align="center">
                            {l.actual?.toFixed(2)}
                          </td>
                        </tr>
                      );
                    })}
                    {this.state.timesheet.timeReportLines.length == 0 ? (
                      <tr>
                        <td colSpan="2">
                          <span className="placeholder">
                            No tasks added to timesheet yet!
                          </span>
                        </td>
                      </tr>
                    ) : (
                      false
                    )}
                  </tbody>
                </table>
              </div>

              <Modal
                title="Review Timesheet"
                icon={faFileSpreadsheet}
                show={this.state.showModal}
                onClose={this.hideModal}
              >
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "#ebeff4",
                    textAlign: "right",
                  }}
                >
                  {this.state.showWFButtons == 2 ? (
                    <MainButton
                      style={{
                        color: "#19470a",
                        borderColor: "#19470a",
                        backgroundColor: "#d2f0c7",
                      }}
                      onClick={() => {
                        this.routeWF("Approve");
                      }}
                      label="Approve"
                    />
                  ) : (
                    false
                  )}
                  {this.state.showWFButtons == 2 ||
                  this.state.showWFButtons == 3 ? (
                    <MainButton
                      style={{
                        color: "#78180d",
                        borderColor: "#78180d",
                        backgroundColor: "#fadfdc",
                      }}
                      onClick={() => {
                        this.routeWF("Revision");
                      }}
                      label="Send Back to Revision"
                    />
                  ) : (
                    false
                  )}
                  {this.state.showWFButtons == 1 ? (
                    <MainButton
                      onClick={() => {
                        this.routeWF("Submit");
                      }}
                      label="Submit"
                    />
                  ) : (
                    false
                  )}
                </div>
                <TimesheetTable
                  isEditable={false}
                  timesheet={this.state.timesheet}
                  selectedPeriod={{ days: this.state.timesheet.Days }}
                />
              </Modal>
            </BodyArea>
            <ButtonArea>
              <Link
                to={
                  "/timesheet/detail?resourceId=" +
                  this.props.selectedRecord.resource.uid +
                  "&timeperiodId=" +
                  this.props.selectedRecord.timeperiodId
                }
              >
                <MainButton
                  label="Go to Timesheet"
                  className="button-area-action"
                />
              </Link>
              <MainButton
                label="Review / Approve"
                onClick={this.showModal}
                className="button-3"
              />
            </ButtonArea>
          </>
        ) : (
          <BodyArea>Loading</BodyArea>
        )}
      </PageArea>
    );
  }
}

import React, { Component } from 'react';
import DataTable from '../../common/dataTable';
import Modal from '../../common/modal';
import DynamicLookup from '../../common/inputControls/lookup/DynamicLookup';
import PageHeader from '../../common/pageElements/pageHeader/'

import sampleData from './sampleData';

import {faCodeMerge}  from '@fortawesome/pro-light-svg-icons'
import {Navigate} from 'react-router-dom';

import MainButton from '../../common/buttons/MainButton';

import Auth from '../../auth/Auth';
class ChangeSetList extends Component {
    constructor(props) {
        super(props);
        this.state={modalOpen:false, selectedProject:{}, selectProjectError:false, selectProjectErrorMessage:"", redirect:false, redirectTo:""}
    }

    openModal = () => {
        this.setState({...this.state, modalOpen:true});
    }

    closeModal = () => {
        this.setState({...this.state, modalOpen:false});
    }

    /*createChangeSet = () => {
       // this.setState({...this.state, modalOpen:false, redirectTo:"/changeset/1/details ", redirect:true});
    }
*/

    
    createChangeSet = () =>{
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
       };

       fetch('/api/changeset/project/'+this.state.selectedProject.uid, requestOptions)
           .then(response => response.json().then(data => ({status:response.status, body:data})))
           .then(r => {
               if(r.status==200) {
                  // this.setState({...this.state, Loading:false, activites:r.body.object});
                    this.setState({...this.state, modalOpen:false, redirectTo:"/changeset/"+ r.body.object.uid+"/details ", redirect:true});
                   //console.log(this.state.activites)
               }
               else if(r.status==401) {
                   window.location.replace("/login");
               }
               else if(r.status==403) {
                   this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
               }
               else {
                   this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
               }
           });
   }

    setProject = (a,b) => {

        //console.log(a);
        //console.log(b);
        // Burayı oktaya bırakıyorum.
        this.setState({...this.state, selectedProject:b});
    }
    
    render() {
            const dtConfig = {
                endPoint: "/api/changeset/",
                columns: [
                    {id:"code", dataKey:"Code", label:"Code", type:"text", linkTo:"/changeset/{uid}/details"}
                    ,{id:"project", dataKey:"project.name", label:"Project", type:"text"}
                    //,{id:"impact", dataKey:"impact", label:"Impact", type:"text"}
                    //,{id:"activityCount", dataKey:"activityCount", label:"Changes", type:"text" }
                    ,{id:"status", dataKey:"status", label:"Status", type:"lookup"}
                    ,{id:"stage", dataKey:"stage", label:"Stage", type:"lookup"}
                    ,{id:"ipt", dataKey:"iptapprovedBy", label:"Created By", type:"text"}
                    ,{id:"programManager", dataKey:"pmapprovedBy", label:"Program Manager", type:"text"}
                    //,{id:"centralPM", dataKey:"createdBy", label:"Central PM", type:"text"}
                    //,{id:"approvalDate", dataKey:"createdBy", label:"Approval Date", type:"text"}
                    //,{id:"postDate", dataKey:"createdBy", label:"Post Date", type:"text"}
                    //,{id:"finishDate", dataKey:"finishDate", label:"Finish Date", type:"date"}
                    //,{id:"actuals", dataKey:"actuals", label:"Actuals Units", type:"number"}
                ], 
                idAttribute: "uid",
                nameAttribute: "name",
                actionButtons: [{
                    code:"newChangeSet",
                    label: "New Change Set",
                    onClick: this.openModal
                }],
                view: {
                    visibleColumnList: ["code","project", "impact", "activityCount", "stage", "createdBy"],
                    hideSelectbox: true
                }
            }
    
            return (
                <>
                <PageHeader
                title={"Change Sets"}
                /> 
                <div className="hub-page" >
                    <DataTable config={dtConfig} />
                </div>
                <Modal onClose={this.closeModal} show={this.state.modalOpen} title="New Change Set" icon={faCodeMerge}>
                    <div style={{padding:"16px" ,display:"flex",flexDirection:"column"}}>
                        <DynamicLookup 
                        error={this.state.selectProjectError} 
                        errorMessage={this.state.selectProjectErrorMessage} 
                        label="Select Project to Create a Change Set" 
                        required endpoint="/api/projects?" 
                        labelAttribute="name" 
                        valueAttribute="uid" searchAttribute="name"
                        value={{value:this.state.selectedProject?.uid, label: this.state.selectedProject?.name }} 
                        onChange={this.setProject} 
                        formId="selectedProject" 
                          />

                        
                       
            
                        <MainButton onClick={this.createChangeSet} label="Create Change Set" />
                   </div>
                </Modal>
                {this.state.redirect?<Navigate to={this.state.redirectTo}/>:false
}
                </>
            );
    }
}

export default ChangeSetList;
import React, { Component } from "react";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea";
import TextField from "../../common/inputControls/textField";
import DateArea from "../../common/inputControls/dateArea";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";

import Section from "../../common/pageElements/section";
import SectionContainer from "../../common/pageElements/sectionContainer";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea";
import MainButton from "../../common/buttons/MainButton";
import { faFloppyDisk } from "@fortawesome/pro-light-svg-icons";
import Auth from "../../auth/Auth";
import TextArea from "../../common/inputControls/textArea2/textArea";
import { DateUtils, Utils } from "./../../common/Commons";
export default class DemandCreate extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        code: "",
        name: "",
        description: "",
        startDate: "",
        finishDate: "",
        source: {
          code: "hub",
        },
        scheduler: {
          code: "hub",
        },
      },
      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
        code: { message: "", error: false, warning: false },
        description: { message: "", error: false, warning: false },
      },

      requiredFields: [
        { id: "name", label: "Name" },
        { id: "code", label: "code" },
        { id: "startDate", label: "startDate" },
        { id: "finishDate", label: "finishDate" },
        { id: "description", label: "description" },
      ],
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] > this.state.formData.finishDate) {
      errors[e].message = "Start Date can not be later than Finish Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;
      errors.finishDate.message = "";
      errors.finishDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] < this.state.formData.startDate) {
      errors[e].message = "Finish Date can not be earlier than Start Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.startDate.message = "";
      errors.startDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  handleChangesLookup = (e, val) => {
    console.log("handleChangesLookup", e, val);
    //console.log(val)

    let items = { ...this.state.formData };

    items[e] = val;
    items.programManagerInfo = { uid: val.uid, fullName: val.fullName };
    this.setState({
      ...this.state,
      formData: items,
    });

    console.log("items", items);
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };

  update = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(this.state.formData),
    };

    fetch("/api/idea", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        console.log("R", this.state.formData, r);
        if (r.status === 200) {
          //this.setState({...this.state, Status: "Update" ,ErrorMessage:"Updated Succesfully"}, this.props.selectedRecord(r.body.object));
          this.notify("success", "Project created");
        } else if (r.status == 201) {
          window.location.replace("/demand/" + r.body.object.uid + "/details");
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.notify("error", r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            ErrorMessage: r.body.message,
          });
        } else if (r.status == 500) {
          this.notify("error", "Fill all areas");
          // this.setState({...this.state,Status: "Error", LoadingError: true, ErrorMessage:r.body.message});
        } else {
          //console.log(r.body.message);

          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  updateCheck = () => {
    let errors = { ...this.state.errorMessages };

    if (this.state.formData.name === "") {
      errors.name.message = "Name is required";
      errors.name.error = true;
      errors.name.warning = false;
    }
    if (this.state.formData.code === "") {
      errors.code.message = "ID is required";
      errors.code.error = true;
      errors.code.warning = false;
    }
    if (this.state.formData.description === "") {
      errors.description.message = "Description is required";
      errors.description.error = true;
      errors.description.warning = false;
    }
    if (this.state.formData.startDate === "") {
      errors.startDate.message = "Start Date is required";
      errors.startDate.error = true;
      errors.startDate.warning = false;
    }
    if (this.state.formData.finishDate === "") {
      errors.finishDate.message = "Finish Date is required";
      errors.finishDate.error = true;
      errors.finishDate.warning = false;
    }

    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      this.update();
    }
  };

  render() {
    // console.log("this state",this.state)
    return (
      <div
        style={{
          paddingLeft: "36px",
          paddingTop: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            width="45%"
            onChange={this.handleChanges}
            changeArea="code"
            label="ID"
            value={this.state.formData.code}
            required={true}
          />
          <TextField
            width="45%"
            onChange={this.handleChanges}
            changeArea="name"
            label="Name"
            value={this.state.formData.name}
            required={true}
          />
        </div>
        <div style={{ height: "200px" }}>
          <TextArea
            width="92%"
            rows="5"
            label="Description"
            value={this.state.formData.description}
            onChange={this.handleChanges}
            changeArea="description"
            required={true}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <DateArea
            width="45%"
            onChange={this.handleChangesStartDate}
            changeArea="startDate"
            label="Start Date"
            value={this.state.formData.startDate}
            required={true}
            errorMessage={this.state.errorMessages["startDate"].message}
            error={this.state?.errorMessages["startDate"]?.error}
            warning={this.state?.errorMessages["startDate"]?.warning}
          />
          <DateArea
            width="45%"
            onChange={this.handleChangesFinishDate}
            changeArea="finishDate"
            label="Finish Date"
            value={this.state.formData.finishDate}
            required={true}
            errorMessage={this.state?.errorMessages["finishDate"]?.message}
            error={this.state?.errorMessages["finishDate"]?.error}
            warning={this.state?.errorMessages["finishDate"]?.warning}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "95%" }}>
          <DynamicLookup
            endpoint={"/api/valuelist/code/idea_size"}
            required={true}
            onChange={this.handleChangesLookup}
            extraObject={"valueListOptions"}
            value={{
              value: this.state.formData?.size?.uid,
              label: this.state.formData?.size?.name,
            }}
            formId="size"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="93%"
            label="Size"
            disabled={false}
          />

          <DynamicLookup
            endpoint={"/api/valuelist/code/idea_type"}
            required={true}
            onChange={this.handleChangesLookup}
            extraObject={"valueListOptions"}
            value={{
              value: this.state.formData?.type?.uid,
              label: this.state.formData?.type?.name,
            }}
            formId="type"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="93%"
            label="Type"
            disabled={false}
          />

          <DynamicLookup
            endpoint={"/api/valuelist/code/idea_priority"}
            required={true}
            onChange={this.handleChangesLookup}
            extraObject={"valueListOptions"}
            value={{
              value: this.state.formData?.priority?.uid,
              label: this.state.formData?.priority?.name,
            }}
            formId="priority"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="93%"
            label="Priority"
            disabled={false}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "row" }}>
          <DynamicLookup
            endpoint="/api/resource"
            value={{
              value: this.state.formData.responsibleManager?.uid,
              label: this.state.formData.responsibleManager?.fullName,
            }}
            onChange={this.handleChangesLookup}
            formId="responsibleManager"
            searchAttribute="fullName"
            valueAttribute="uid"
            labelAttribute="fullName"
            width="45%"
            label="Responsible Manager"
          />
          <DynamicLookup
            endpoint="/api/obs"
            value={{
              value: this.state.formData.responsibleUnit?.uid,
              label: this.state.formData.responsibleUnit?.name,
            }}
            onChange={this.handleChangesLookup}
            formId="responsibleUnit"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="45%"
            label="Responsible Unit"
          />
        </div>

        <div style={{ marginTop: "10px", display: "flex" }}>
          <MainButton
            onClick={this.updateCheck}
            label="Create Demand"
            className=""
          />
        </div>
      </div>
    );
  }
}

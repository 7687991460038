import React from "react";

const PageArea = ({ children, style }) => {
  return (
    <div className="page-area" style={style}>
      {children}
    </div>
  );
};

export default PageArea;

import React, { Component } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./searchArea.css";
import HubIcon from "../../hubIcon/HubIcon";
import HiSearch from "../../icons/HiSearch";

class SearchArea extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <div className="hub-inputControl-searchField">
        <HubIcon>
          <HiSearch />
        </HubIcon>
        <input
          onChange={this.onChange}
          value={this.props.value}
          placeholder="Search..."
        />
      </div>
    );
  }
}

export default SearchArea;

import React from "react";

const HiMore = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M8.76185 7.99998C8.76185 7.57919 8.42073 7.23807 7.99994 7.23807C7.57915 7.23807 7.23804 7.57919 7.23804 7.99998C7.23804 8.42077 7.57915 8.76188 7.99994 8.76188C8.42073 8.76188 8.76185 8.42076 8.76185 7.99998Z"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76185 2.66664C8.76185 2.24585 8.42073 1.90474 7.99994 1.90474C7.57915 1.90474 7.23804 2.24585 7.23804 2.66664C7.23804 3.08743 7.57915 3.42855 7.99994 3.42855C8.42073 3.42855 8.76185 3.08743 8.76185 2.66664Z"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.76185 13.3333C8.76185 12.9125 8.42073 12.5714 7.99994 12.5714C7.57915 12.5714 7.23804 12.9125 7.23804 13.3333C7.23804 13.7541 7.57915 14.0952 7.99994 14.0952C8.42073 14.0952 8.76185 13.7541 8.76185 13.3333Z"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiMore;

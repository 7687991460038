import React, { Component } from "react";
import FilterItem from "./FilterItem";
import FilterSelector from "./FilterSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/pro-light-svg-icons";

import TooltipWithPage from "../../../common/tooltipWithPage/tooltipWithPage";
import PrebuiltFilter from "./PrebuiltFilter";
import MainButton from "../../buttons/MainButton";

class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
      showTooltipClear: false,
      showPreBultFilter: false,
      showActions: false,
    };
  }

  render() {
    return (
      <div
        className={
          this.props.isFilterOpened
            ? "hub-dataTable-filterBar"
            : "hub-dataTable-filterBar filter-hide"
        }
      >
        <FilterSelector
          addFilter={this.props.addFilter}
          filters={this.props.filters}
          filterList={this.props.filterList}
          columns={this.props.columns}
        />
        {this.props.filters.map((f) => {
          return (
            <FilterItem
              setFilter={this.props.setFilter}
              key={"filterItem-" + f.id}
              removeFilter={this.props.removeFilter}
              column={f}
            />
          );
        })}
        {this.props.preBuiltFilters?.length > 0 ? (
          <div className="selectedRows">
            <span className="title-label">Quick Filters</span>

            <div className="quick-filter-list">
              {this.props.preBuiltFilters?.map((f) => (
                <PrebuiltFilter
                  applyPrebuiltFilter={this.props.applyPrebuiltFilter}
                  filter={f}
                  key={f.code}
                  /*    onRemove={this.props.clearFilters} */
                />
              ))}
              <MainButton
                className="tiny-text black"
                label={"Clear Filter"}
                onClick={this.props.clearFilters}
              />
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default FilterBar;

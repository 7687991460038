import React from "react";

import NoteInfo from "./noteInfo";
import NoteDescription from "./noteDescription";
import NoteFileList from "./NoteFileList";
import MainButton from "../../buttons/MainButton";
import HiSend from "../../icons/HiSend";

const NoteRightPanel = ({
  dataSet,
  retrieveConversation,
  changeState,
  deleteNote,
  hasScroll,
}) => {
  return (
    <div className="right-panel">
      <NoteInfo
        dataSet={dataSet}
        changeState={changeState}
        retrieveConversation={retrieveConversation}
        deleteNote={deleteNote}
        hasScroll={hasScroll}
      />
      <NoteDescription dataSet={dataSet} changeState={changeState} />
      {!!dataSet?.conversationFiles?.length && (
        <NoteFileList dataSet={dataSet} />
      )}
      {/*  <div className="note-reply-button">
        <MainButton
          className="button-area-action"
          iconLineColor="#262628"
          style={{
            backgroundColor: "#fff",
          }}
          icon={<HiSend />}
          label="Reply"
        />
      </div> */}
    </div>
  );
};

export default NoteRightPanel;

import React, { Component } from 'react'

import Auth from '../../auth/Auth';
import {DateUtils} from '../../common/Commons'
import ProjectTileMilestoneBox from './ProjectTileMilestoneBox'
export default class ProjectTileMilestoneBoxContainer extends Component {
     constructor(props) {
    super(props)
  
    this.state = {
   
       formData:[],
       Status:"Loading"
    }
  }

  

    componentDidMount(){
        this.getDatas();
    }



   getDatas = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch("/api/projects/"+this.props.project.uid+"/milestone", requestOptions)
        
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                
               // console.log(r)
               if(r.status==200) {
                  // this.createObject(r.body.object);
                this.setState({...this.state, Loading:false,  Status: "Load",formData:this.createObject(r.body.object)});

                //console.log(this.state)
               // console.log(this.state.period.open)
            }
                else if(r.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    this.setState({...this.state, Status: "Error",LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state,Status: "Error", LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });



        }
        //gereken bilgileri ekler. percent, date, ismilestonePassed
        createObject =(objectArray)=>{
            let util = new DateUtils();
            let array=objectArray;
            //console.log(array[0]);
            //console.log(array.length)
            let lastPercent=[];
            if(array!=null)
            {
             const dateDiff=util.getDifferenceInDays(this.props.project.startDate,this.props.project.finishDate);
                for(var i=0; i<array.length; i++)
                    {
                //        console.log(array[i]);
                            let dateDiffMilestone=util.getDifferenceInDays(this.props.project.startDate,array[i].finishDate);
                            
                            let percent=parseInt(util.calculatePercent(dateDiffMilestone,dateDiff));
                            let milestoneDate= util.Date2Str(array[i].finishDate);
                            if(array[i].finishDate<this.props.project.startDate)
                            {
                               // console.log("silme");
                                array.splice(i,1);
                                i--;
                            }
                            else
                            {
                                        if(lastPercent.includes(percent))
                                            {
                                                //lastPercent.push(lastPercent[lastPercent.length - 1]+2);
                                                //percent=lastPercent[lastPercent.length - 1]+2
                                            }
                                            else
                                            {
                                                lastPercent.push(percent);
                                            }
                                    Object.assign(array[i], {percent: percent,milestoneDate:milestoneDate,  isMilestonePassed:this.props.percentComplete>percent||this.props.percentComplete===100?true:false});

                            }

                           
                    }
                    //aynı tarihte bitenlerin taşmamısı için oranlama
                     this.props.upperWidth(95);
                    /*if(parseInt(lastPercent[lastPercent.length - 1])>=100)
                        {
                             //console.log(lastPercent[lastPercent.length - 1]);
                             let bgWidth=parseInt(util.calculatePercent(95,lastPercent[lastPercent.length - 1]));
                             //console.log(bgWidth);
                             this.props.upperWidth(parseInt(bgWidth));
                        }
                        */
            // console.log(lastPercent);
          // console.log(array);
                    }
            return array;

        }

  
 

  render() {
   
            return (

            this.state.Status==="Load"?
                            this.state.formData&&this.props.project.startDate?this.state.formData.map( (c) => {      
                            
                            
                                                    return   <div className='milestone-area' style={{left:c.percent+'%'}} > 
                                                                <ProjectTileMilestoneBox color={this.props.color} milestone={c} />
                                                            </div> 
                                                            
                                                    }):""
                                                    
                                                    :""
            
            )
  }
}

import React, { Component } from 'react';
import {faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function format(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${year}-${month}-${date}`;
  }

  
class MonthlyPicker extends Component {

    constructor(props){ 
        super(props);
        const now = new Date();
        this.period = "monthly";

        this.months = [
            {monthId: 1, monthIdStr:"01", monthLabel:"Jan", monthName:"January"},
            {monthId: 2, monthIdStr:"02", monthLabel:"Feb", monthName:"February"},
            {monthId: 3, monthIdStr:"03", monthLabel:"Mar", monthName:"March"},
            {monthId: 4, monthIdStr:"04", monthLabel:"Apr", monthName:"April"},
            {monthId: 5, monthIdStr:"05", monthLabel:"May", monthName:"May"},
            {monthId: 6, monthIdStr:"06", monthLabel:"Jun", monthName:"June"},
            {monthId: 7, monthIdStr:"07", monthLabel:"Jul", monthName:"July"},
            {monthId: 8, monthIdStr:"08", monthLabel:"Aug", monthName:"August"},
            {monthId: 9, monthIdStr:"09", monthLabel:"Sep", monthName:"September"},
            {monthId: 10, monthIdStr:"10", monthLabel:"Oct", monthName:"October"},
            {monthId: 11, monthIdStr:"11", monthLabel:"Nov", monthName:"November"},
            {monthId: 12, monthIdStr:"12", monthLabel:"Dec", monthName:"December"}
          ];

        this.arrays = []; 
        let size = 3;
        
        for (let i = 0; i < this.months.length; i += size)
            this.arrays.push(this.months.slice(i, i + size));

        this.aligns = ["left", "center", "right"];

        this.state={
            startPeriod: now.getFullYear(),
            currentPeriod: now.getFullYear().toString()+"-"+(now.getMonth() + 1).toString().padStart(2, "0")+"-01",
            hover: "",
            limit: 48 // En fazla kaç ay seçileceği*
        }
    }

    nextPeriod = () => {
        this.setState({...this.state, startPeriod:this.state.startPeriod+1})
    }
    
    prevPeriod = () => {
        this.setState({...this.state, startPeriod:this.state.startPeriod-1})
    }

    selectDate = (d) => {
        this.props.selectDate(d);
    } 

    hoverDate = (d) => {
        this.setState({...this.state, hover:d})
    }

    clearHover = () => {
        this.setState({...this.state, hover:""})
    }

    render() {
        let firstPeriod = this.state.startPeriod.toString();
        let secondPeriod = (this.state.startPeriod+1).toString();
        
        // Tarih sınırı
        let dateLimit = "9999-99-99";
        if (this.props.startDate !== "" ) {
            // 48 olarak ekliyorum.
            let dt  = new Date(this.props.startDate);
            var newDate = new Date(dt.setMonth(dt.getMonth()+this.state.limit-1));
            dateLimit = format(newDate);
        }
        
        return (
            <>
            <div className="pickerWrapper">
                <div className="pickerColumn">
                    <div className="periodSelector">
                        <div className="icon" onClick={this.prevPeriod} ><FontAwesomeIcon icon={faChevronLeft} size="sm"/> </div>
                        <div className="label">{firstPeriod}</div> 
                    </div>    
                    <div className="periodBody">
                        {this.arrays.map(i=>{
                            return (<div className="row">{i.map((j, ind)=>{

                                let dateKey = firstPeriod.toString()+"-"+j.monthIdStr+"-"+"01";

                                let isHovered = "";
                                let isSelected = "";
                                let isSelectedFinish = "";
                                let isDisabled = "";

                                if(this.props.activeLayout === this.period) {

                                    // Eğer değer seçili ise o değer esas alınır, eğer seçili değilse dateRange'deki daha önce seçili değer esas alınır.
                                    let compStart = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.start:this.props.startDate;
                                    let compFinish = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.finish:this.props.finishDate;
    
                                    isHovered = (((compStart!=="" && compFinish==="" && compStart<dateKey&&this.state.hover>=dateKey && dateKey < dateLimit)||(compStart<dateKey&&compFinish>=dateKey))?" hovered":"");
                                    isSelected =  (compStart===dateKey?" selected":"");
                                    isSelectedFinish =  (compFinish===dateKey?" selectedFinish":"");
                                    isDisabled = (dateLimit<dateKey)?" disabled": "";
                                }
    

                                return (<div 
                                    onMouseOver={()=>{this.hoverDate(dateKey)}}
                                    onMouseLeave={this.clearHover} 
                                    onClick={()=>{if(dateLimit>=dateKey){this.selectDate(dateKey)}}}  
                                    className={"monthItem" + isSelected + isHovered + isSelectedFinish + isDisabled}
                                    style={{textAlign:(this.aligns[ind%3])}}
                                    >
                                        <div className={"cellItem"+ ((this.state.currentPeriod===dateKey)?" today":"")}>{j.monthLabel}</div>
                                    </div>)
                            })}</div>)
                        })}
                    </div>
                </div>
                {/** SECOND COLUMN */}
                <div className="pickerColumn">
                    <div className="periodSelector">
                        <div className="label">{secondPeriod}</div> 
                        <div className="icon" onClick={this.nextPeriod} ><FontAwesomeIcon icon={faChevronRight} size="sm"/> </div>
                    </div>   
                    <div className="periodBody">
                        {this.arrays.map(i=>{
                            return (<div className="row">{i.map((j, ind)=>{
                                let dateKey = secondPeriod.toString()+"-"+j.monthIdStr+"-"+"01";
                                
                                let isHovered = "";
                                let isSelected = "";
                                let isSelectedFinish = "";
                                let isDisabled = "";

                                if(this.props.activeLayout === this.period) {
                                    // Eğer değer seçili ise o değer esas alınır, eğer seçili değilse dateRange'deki daha önce seçili değer esas alınır.
                                    let compStart = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.start:this.props.startDate;
                                    let compFinish = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.finish:this.props.finishDate;
    
                                    isHovered = (((compStart!=="" && compFinish==="" && compStart<dateKey&&this.state.hover>=dateKey && dateKey < dateLimit)||(compStart<dateKey&&compFinish>=dateKey))?" hovered":"");
                                    isSelected =  (compStart===dateKey?" selected":"");
                                    isSelectedFinish =  (compFinish===dateKey?" selectedFinish":"");
                                    isDisabled = (dateLimit<dateKey)?" disabled": "";
                                }
    
                                return (<div 
                                    onMouseOver={()=>{this.hoverDate(dateKey)}} 
                                    onClick={()=>{if(dateLimit>=dateKey){this.selectDate(dateKey)}}}  
                                    className={"monthItem" + isSelected + isHovered + isSelectedFinish + isDisabled}
                                    style={{textAlign:(this.aligns[ind%3])}}
                                    >
                                        <div className={"cellItem"+ ((this.state.currentPeriod===dateKey)?" today":"")}>{j.monthLabel}</div>
                                    </div>)
                                    
                            })}</div>)
                        })}
                    </div>
                </div>
            </div>
            <div className="footNote">You can choose up to 48 months</div>
            </>
        );
    }
}

export default MonthlyPicker;
import React, { Component } from 'react';
import './CardDetailSection.css';

class CardDetailSection extends Component {
    render() {
        return (<div className="card-detail-section">
                <div className="marker" style={{left:this.props.markerLeft}}></div>
                <div className="card-section-body">
                {this.props.children}
                </div>
            </div>);
    }
}

export default CardDetailSection;
import React, { Component } from 'react'
import Container from '../container/Container'
import ChildCreator from './childCreator'
export default class containerCreator extends Component {
  render() {
    let c=this.props.container
    return ( <Container id={c.id} direction={c.direction} width={c.width?c.width:"auto"} stretch={c.stretch?true:false}>
               <ChildCreator childs={c.childs} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
            </Container>  )
  }
}

import React from "react";

const HiDepartment = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M11.4101 10.2387C11.2296 10.4428 11.0402 10.6443 10.8422 10.8423C7.97834 13.7061 4.3904 14.7614 2.82831 13.1993C1.75737 12.1284 1.91664 10.1053 3.04548 8.01612M4.59295 5.8168C4.78076 5.60326 4.97832 5.39246 5.18533 5.18545C8.04917 2.32161 11.6371 1.26633 13.1992 2.82843C14.2709 3.9001 14.1107 5.92526 12.9797 8.01596M10.8422 5.18545C13.706 8.0493 14.7613 11.6372 13.1992 13.1993C11.6371 14.7614 8.04917 13.7061 5.18533 10.8423C2.32148 7.97846 1.26621 4.39053 2.82831 2.82843C4.3904 1.26633 7.97834 2.32161 10.8422 5.18545ZM8.66655 7.99995C8.66655 8.36814 8.36807 8.66662 7.99988 8.66662C7.63169 8.66662 7.33321 8.36814 7.33321 7.99995C7.33321 7.63176 7.63169 7.33329 7.99988 7.33329C8.36807 7.33329 8.66655 7.63176 8.66655 7.99995Z"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiDepartment;

import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./cards.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";

import CardBlock from "./block/cardBlock";

import ToDo from "./todo/ToDo";
import CardFavorite from "./favorite/cardFavorite";
import ProgressBar from "./progressBar.js/progressBar";
import CardToolbarIcon from "./CardToolbarIcon";
import CardAvatarGroup from "./assignments/CardAvatarGroup";

import { DateUtils } from "../../../common/Commons";
import TagGroup from "./tags/tagGroup";
import NewAssignment from "./assignments/NewAssignment";
import HubIcon from "../../../common/hubIcon/HubIcon";
import HiTodo from "../../../common/icons/HiTodo";
import HiBlock from "../../../common/icons/HiBlock";
export default class card extends Component {
  constructor(props) {
    super(props);
    this.du = new DateUtils();
    this.state = {
      activeTab: this.props.task.isBlocked ? "block" : "",
      assignmentType: "",
      assignment: {},
      showToDo: false,
      showBlock: false,
      showAssignments: false,
      isAssignmentPanelOpen: false, // Assignment panelini açıksa assigment bölümü kapanmayacak.
      selectedResourceIndex: null,
      markerDistance: 300,
    };
  }

  setActiveTab = (tab) => {
    this.setState({
      ...this.state,
      activeTab: tab === this.state.activeTab ? "" : tab,
    });
  };
  hideAssignments = () => {
    this.setState({ ...this.state, showAssignments: false });
  };
  showAssignments = () => {
    this.setState({ ...this.state, showAssignments: true });
  };
  openAssignmentPanel = () => {
    this.setState({ ...this.state, isAssignmentPanelOpen: true });
  };
  closeAssignmentPanel = () => {
    this.setState({
      ...this.state,
      showAssignments: false,
      isAssignmentPanelOpen: false,
    });
  };

  setAssignmentTab = (tab, assignmentType, assignment, index) => {
    let newTab = tab;

    let distance = index === undefined ? 262 : (8 - index) * 29;

    if (
      tab === this.state.activeTab &&
      assignmentType === "new" &&
      this.state.assignmentType === "new"
    ) {
      newTab = "";
    }
    this.setState({
      ...this.state,
      activeTab: newTab,
      assignmentType: assignmentType,
      assignment: assignment,
      markerDistance: distance,
    });
  };

  setFavorite = () => {
    this.props.setFavorite(
      { objectUid: this.props.task.uid, objectType: { code: "task", uid: 52 } },
      !this.props.task.isFavorite,
      this.props.columnIndex,
      this.props.index
    );
  };

  render() {
    let assignmentArray = this.props.taskResource?.find(
      (x) => x.taskId === this.props.task.uid
    )?.assignmentList;

    let toDoLength = this.props.task?.toDo?.length;
    return (
      <Draggable
        isDragDisabled={
          this.props.dragDisabled ? this.props.dragDisabled : false
        }
        key={this.props.task.uid}
        draggableId={this.props.task.uid.toString()}
        index={this.props.index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <>
            <div
              className="card-drag-area"
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
            >
              {draggableProvided.placeholder}
              <div
                className={
                  "hub-teamHub-card " +
                  (this.props.selectedCard?.uid === this.props.task.uid
                    ? " selected"
                    : "")
                }
                style={{
                  borderColor: this.props.task.isBlocked ? "#f30000" : "",
                }}
                onClick={() =>
                  this.props.selectRecord(
                    this.props.task,
                    this.props.columnIndex,
                    this.props.index
                  )
                }
              >
                <div
                  className="body"
                  onDoubleClick={() => this.props.openSidebar("detail")}
                >
                  <div style={{ position: "relative" }}>
                    <TagGroup tags={this.props.task?.tag} />
                  </div>
                  <div className="title" {...draggableProvided.dragHandleProps}>
                    {this.props.task.name}
                  </div>
                  <div className="details">
                    <div
                      title="Go to Project"
                      className={
                        "tag fl " + this.props.task.color?.code.toString()
                      }
                    >
                      <a
                        href={
                          "/project/" +
                          this.props.task?.projectId.toString() +
                          "/wbs?task=" +
                          this.props.task.code
                        }
                        className={this.props.task.color?.code.toString()}
                      >
                        {this.props.task.code}
                      </a>
                    </div>
                    <ProgressBar
                      remaining={this.props.task.remainingUnit}
                      actual={this.props.task.actualUnit}
                      color={this.props.task.color?.code.toString()}
                    />

                    {/*<div style={{marginLeft:5}} className={"tag fl" + this.props.task.color?.code.toString()}><a className={"gray"}>%15</a></div>*/}
                    <div
                      className={"tag fr"}
                      title={this.props.task.finishDate}
                    >
                      <a
                        className={
                          this.du.getDifferenceToday(
                            this.props.task.finishDate
                          ) <= 0
                            ? "lightblue"
                            : "red"
                        }
                      >
                        {this.du.getDifferenceToday(
                          this.props.task.finishDate
                        ) > 0 ? (
                          <FontAwesomeIcon
                            style={{ cursor: "pointer", marginRight: "4px" }}
                            color="#ffffff"
                            icon={faCircleExclamation}
                          />
                        ) : (
                          false
                        )}
                        {this.du.Date2MonthDay(this.props.task.finishDate)}
                      </a>
                    </div>

                    <div className="cb"></div>
                  </div>

                  <div className="toolbar">
                    <div
                      className="left"
                      style={{
                        display: "flex",
                        gap: "3px",
                        width:
                          (this.state.showAssignments ||
                            this.state.isAssignmentPanelOpen) &&
                          assignmentArray.length > 3
                            ? "0px"
                            : false,
                        opacity:
                          (this.state.showAssignments ||
                            this.state.isAssignmentPanelOpen) &&
                          assignmentArray.length > 3
                            ? 0
                            : 1,
                      }}
                    >
                      <CardFavorite
                        setFavorite={this.setFavorite}
                        isFavorite={this.props.task.isFavorite}
                      />
                      <CardToolbarIcon
                        className="todo"
                        selected={this.state.activeTab === "todo"}
                        key="todoIcon"
                        active={
                          toDoLength > 0 && this.state.activeTab !== "todo"
                            ? true
                            : false
                        }
                        label={
                          toDoLength > 0 ? this.props.task?.toDo?.length : ""
                        }
                        onClick={() => this.setActiveTab("todo")}
                        icon={
                          <HubIcon
                            fontSize={20}
                            lineColor={
                              this.state.activeTab === "todo"
                                ? "#fff"
                                : toDoLength > 0 &&
                                  this.state.activeTab !== "todo"
                                ? "#174999"
                                : "#DAE3F2"
                            }
                          >
                            <HiTodo />
                          </HubIcon>
                        }
                      />
                      <CardToolbarIcon
                        className={
                          "blockIcon" +
                          (this.props.task.isBlocked
                            ? " blocked"
                            : this.props.task.blockResolveDescription
                            ? " resolved"
                            : "")
                        }
                        selected={this.state.activeTab === "block"}
                        active={
                          this.props.task.isBlocked
                            ? true
                            : this.props.task.blockResolveDescription
                            ? true
                            : false
                        }
                        key="banIcon"
                        onClick={() => this.setActiveTab("block")}
                        icon={
                          <HubIcon
                            fontSize={20}
                            lineColor={
                              this.state.activeTab === "block" ||
                              this.props.task.isBlocked
                                ? "#fff"
                                : "#DAE3F2"
                            }
                          >
                            <HiBlock />
                          </HubIcon>
                        }
                      />
                    </div>
                    <div
                      className="right"
                      onMouseEnter={this.showAssignments}
                      onMouseLeave={(e) => {
                        e.stopPropagation();
                        this.state.activeTab !== "newAssignment"
                          ? this.hideAssignments()
                          : this.showAssignments();
                      }}
                    >
                      <CardAvatarGroup
                        setAssignmentTab={this.setAssignmentTab}
                        isAssignmentPanelOpen={this.state.isAssignmentPanelOpen}
                        openAssignmentPanel={this.openAssignmentPanel}
                        closeAssignmentPanel={this.closeAssignmentPanel}
                        isExpanded={
                          this.state.showAssignments ||
                          this.state.isAssignmentPanelOpen
                        }
                        task={this.props.task}
                        taskResource={assignmentArray}
                        taskIndex={this.props.index}
                        columnIndex={this.props.columnIndex}
                        assignmentOperations={this.props.assignmentOperations}
                      />
                      <div className="cb"></div>
                    </div>
                  </div>
                  {/*<CardInfo task={this.props.task}/>*/}
                </div>
                <ToDo
                  activeTab={this.state.activeTab}
                  task={this.props.task}
                  toDoOperations={this.props.toDoOperations}
                  taskIndex={this.props.index}
                  columnIndex={this.props.columnIndex}
                  markerLeft={toDoLength > 0 ? 10 : 0}
                />
                <CardBlock
                  activeTab={this.state.activeTab}
                  blockTask={this.props.blockTask}
                  task={this.props.task}
                  toggleBlock={this.props.toggleBlock}
                  closeBlock={() => this.setActiveTab("")}
                  taskIndex={this.props.index}
                  columnIndex={this.props.columnIndex}
                  markerLeft={toDoLength > 9 ? 24 : toDoLength > 0 ? 15 : 0}
                />
                {this.state.activeTab === "newAssignment" ? (
                  <NewAssignment
                    onShrinkAvatars={this.hideAssignments}
                    activeTab={this.state.activeTab}
                    assignmentType={this.state.assignmentType}
                    assignment={this.state.assignment}
                    task={this.props.task}
                    taskResource={assignmentArray}
                    taskIndex={this.props.index}
                    columnIndex={this.props.columnIndex}
                    assignmentOperations={this.props.assignmentOperations}
                    closePanel={() => {
                      this.setState({
                        ...this.state,
                        showAssignments: false,
                        activeTab: "",
                      });
                    }}
                    markerLeft={this.state.markerDistance}
                    setAssignmentTab={this.setAssignmentTab}
                  />
                ) : (
                  false
                )}
              </div>
            </div>
          </>
        )}
      </Draggable>
    );
  }
}

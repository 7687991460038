import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea";
import MainButton from "../../common/buttons/MainButton";
import TextField from "../../common/inputControls/textField";

import PercentField from "../../common/inputControls/percentField";

import { DateUtils } from "./../../common/Commons";
import { Utils } from "./../../common/Commons";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup.js";
import DateArea from "../../common/inputControls/dateArea/index.js";
import HiSave from "../../common/icons/HiSave.js";
class SideBarWbsDetails extends Component {
  /**/
  constructor() {
    super();

    this.du = new DateUtils();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
        actuals: "5",
      },
      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
      },

      requiredFields: [{ id: "name", label: "Name" }],
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesParentWBS = (e, val) => {
    //  console.log("handleChangesParentWBS",e,val)

    let items = { ...this.state.formData };

    items[e] = val.uid;

    items.parentWbsInfo = { uid: val.uid, name: val.name };

    /* */
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  componentDidMount() {
    //console.log("componentDidMount")
    // console.log(this.props)
    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r);
        //console.log(this.props.selectedRecord);

        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object[0],
          });

          //console.log(this.state.formData?.actuals)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  Update = () => {
    // console.log(this.state.formData)
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(this.state.formData),
    };

    fetch("/api/resource/" + this.state.formData?.id, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(requestOptions);
        //console.log(r);
        if (r.status === 200) {
          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  updateRecord = async () => {
    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      // let data=this.state.formData

      let data = {
        uid: this.state.formData.uid,
        name: this.state.formData.name,
        parentWbs: this.state.formData.parentWbs,
        responsible: this.state.formData.responsible,
      };

      //console.log("data",data)

      await this.props.save(
        "patch",
        "/api/wbs/" + this.props.selectedRecord.uid,
        data,
        this.props.selectedRecord,
        null,
        null,
        "wbs"
      );
    }
  };

  render() {
    // console.log('DD',this.state.formData);

    let source = this.props?.project?.source?.code;
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="input-grid">
            {source === "hub" && this.props.selectedRecord?.level > 1 ? (
              <DynamicLookup
                endpoint={
                  "/api/projects/" +
                  this.props.selectedRecord.projectId +
                  "/wbs/notIn/" +
                  this.state.formData?.uid
                }
                required={true}
                onChange={this.handleChangesParentWBS}
                value={{
                  value: this.state.formData?.parentWbsInfo?.uid,
                  label: this.state.formData?.parentWbsInfo?.name,
                }}
                formId="parentWbs"
                searchAttribute="name"
                valueAttribute="uid"
                labelAttribute="name"
                width="100%"
                label="Parent WBS"
              />
            ) : (
              false
            )}
            {/*<TextField width="100%" onChange= {this.handleChanges} changeArea="code" label="WBS Code" value={this.state.formData?.code} disabled={true} required={true} />*/}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="name"
              label="Name"
              value={this.state.formData?.name}
              disabled={source === "hub" ? false : true}
              required={true}
            />
            {1 === 1 ? (
              false
            ) : (
              <TextField
                width="100%"
                onChange={this.handleChanges}
                changeArea="Obs"
                label="IPT"
                value={this.state.formData?.obsInfo?.name}
                disabled={true}
              />
            )}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="startDate"
              label="Start Date"
              value={this.du.Date2Str(this.state.formData?.startDate)}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="Finish Date"
              value={this.du.Date2Str(this.state.formData?.finishDate)}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="status"
              label="Status"
              value={this.state.formData?.status?.name}
              disabled={true}
            />
            <PercentField
              width="100%"
              onChange={this.handleChanges}
              changeArea="actuals"
              label="WBS % Complete"
              value={
                this.state.formData?.percentComplete
                  ? this.state.formData?.percentComplete.toString()
                  : 0
              }
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="actuals"
              label="Actual Units (h)"
              value={
                this.state.formData?.actualUnit
                  ? this.state.formData?.actualUnit.toString()
                  : "0"
              }
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="remainingUnits"
              label="Remaining Units (h)"
              value={
                this.state.formData?.remainingUnit
                  ? this.state.formData?.remainingUnit.toString()
                  : "0"
              }
              disabled={true}
            />
            {(
              <TextField
                width="100%"
                onChange={this.handleChanges}
                changeArea="responsibleOBS"
                label="Responsible Department"
                value={this.state.formData?.obsInfo?.name}
                disabled={true}
              />
            )}
          </div>
        </BodyArea>
        <ButtonArea>
          {source === "hub" ? (
            <MainButton
              onClick={this.updateRecord}
              key="saveActivity"
              className="button-area-action"
              iconLineColor="#fff"
              icon={<HiSave />}
              disabled={this.state.formData.status.uid === 33 ? true : false}
              label="Save WBS"
            />
          ) : (
            false
          )}
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default SideBarWbsDetails;

import React from "react";

const HiImage = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14 15"
      fill={bgColor ?? "none"}
    >
      <path
        d="M9.45 12.75H4.0433C3.68991 12.75 3.51321 12.75 3.43139 12.6801C3.3604 12.6195 3.32272 12.5285 3.33004 12.4354C3.33849 12.3282 3.46343 12.2032 3.71332 11.9533L8.67337 6.9933C8.90437 6.76229 9.01988 6.64678 9.15307 6.60351C9.27023 6.56544 9.39643 6.56544 9.51359 6.60351C9.64678 6.64678 9.76229 6.76229 9.9933 6.9933L12.25 9.25V9.95M9.45 12.75C10.4301 12.75 10.9201 12.75 11.2945 12.5593C11.6238 12.3915 11.8915 12.1238 12.0593 11.7945C12.25 11.4201 12.25 10.9301 12.25 9.95M9.45 12.75H4.55C3.56991 12.75 3.07986 12.75 2.70552 12.5593C2.37623 12.3915 2.10852 12.1238 1.94074 11.7945C1.75 11.4201 1.75 10.9301 1.75 9.95V5.05C1.75 4.06991 1.75 3.57986 1.94074 3.20552C2.10852 2.87623 2.37623 2.60852 2.70552 2.44074C3.07986 2.25 3.56991 2.25 4.55 2.25H9.45C10.4301 2.25 10.9201 2.25 11.2945 2.44074C11.6238 2.60852 11.8915 2.87623 12.0593 3.20552C12.25 3.57986 12.25 4.06991 12.25 5.05V9.95M6.125 5.45833C6.125 6.10267 5.60267 6.625 4.95833 6.625C4.314 6.625 3.79167 6.10267 3.79167 5.45833C3.79167 4.814 4.314 4.29167 4.95833 4.29167C5.60267 4.29167 6.125 4.814 6.125 5.45833Z"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiImage;

import React, { Component } from 'react';
import './TimesheetWorklog.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAngleUp, faAngleDown} from '@fortawesome/pro-regular-svg-icons'


class TimesheetWorklog extends Component {
    
    constructor(props) {
        super(props);
        this.state={val: this.props.val, focus: false, step: this.props.step?this.props.step:0.25}
    }

    
    handleOnChange = (e) => {

        const re = new RegExp(/^[0-9\b.]+$/);
        
        // if value is not blank, then test the reg
        if(e.target.value === 'NaN' || e.target.value === '' || isNaN(Number(e.target.value)))
        {
            this.setState({val:0});
        }
        else if (re.test(e.target.value)) {
            this.setState({val:e.target.value});
        }

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.val !== this.state.val) {
          this.setState({ val: nextProps.val, focus: false  });
        }
    }

    setWorklogHours = () => {
        if(this.props.worklogRow===99)
        {
             this.props.setRemainingHour( this.state.val);
        }
        else
        {
             this.props.setWorklogHours(this.props.worklogRow, this.state.val);
        }
    }

      
    handleBlur = () => {
        this.setState({...this.state, focus: false}, ()=> {this.setWorklogHours()});
    }

    handleFocus = () => {
        this.setState({...this.state, focus: true});
    }

    handleIncrease = () => {
        let newVal = this.state.val*1.00 + this.state.step;
        this.setState({...this.state, val:newVal}, ()=> {this.setWorklogHours()});
    }

    handleDecrease = () => {
        let newVal = (this.state.val<this.state.step)?0:(this.state.val*1.00 - this.state.step);
        this.setState({...this.state, val:newVal}, ()=> {this.setWorklogHours()});
    }
    
    render() {
        
        return (
            <div className={"hub-ts-worklog" + (this.state.focus?" focused": "") + (!this.props.disabled?" disabled": "")}> 
                <div className="hub-ts-worklog-input"> 
                    <input disabled={!this.props.disabled} onFocus={this.handleFocus} onBlur={this.handleBlur} onChange={this.handleOnChange} value={this.props.readOnly?this.props.val:this.state.val} />
                </div>
                {this.props.hideIcons?false:<div className="hub-ts-worklog-icons">
                    <FontAwesomeIcon onClick={this.handleIncrease} icon={faAngleUp} />
                    <div className="seperator"></div>
                    <FontAwesomeIcon onClick={this.handleDecrease} icon={faAngleDown} />
                </div>}
            </div>
        );
    }
}

export default TimesheetWorklog;
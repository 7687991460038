import React, { Component } from 'react';

import InputControl from '../InputControl';




class PercentField extends Component {

    constructor(props) {
      super(props);
      this.state={v: '', focus:false, error:false, errorMessage: '',percentSembol:'%'}
      this.handleFocus = this.handleFocus.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    handleFocus(t) {
      let errorMessage = t===false&&this.props.required&&this.state.v.length==0?'This field is required.':'';
      let error = t===false&&this.props.required&&this.state.v.length==0?true:false;
      this.setState({...this.state, focus:t, error:error, errorMessage: errorMessage}) 
    }


    componentDidMount(){

      //console.log("componentDidMount TextField");
      //console.log(this.props);
      this.setState({...this.state, v:this.props.value?this.props.value:''}) 
    }


    onChange(e){
      console.log(e.target.value)
      this.setState({...this.state, v: e.target.value}); 
      this.props.onChange(this.props.changeArea,e.target.value )
    }

    onKeyDown (e){
      if(this.props.onKeyDown!==undefined)
      {

         this.props.onKeyDown(e);
      }
     
    }

    render() {
        return (
            <InputControl inputValue={this.state.v} error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props}>
              <input  format={"### %"} type="text" onKeyDown={(e)=>this.onKeyDown(e)} onChange={this.onChange} disabled={this.props.disabled}  value={this.state.v?(this.state.v).toString()+'%':0} onFocus={()=>this.handleFocus(true)} onBlur={()=>this.handleFocus(false)}/>             
            </InputControl>
        );
    }

}

export default PercentField;
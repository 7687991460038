import { useRef } from "react";

import HiAttachment from "../icons/HiAttachment";
import HubIcon from "../hubIcon/HubIcon";
import "./UploadButton.scss";

const UploadButton = ({ uploadFile, uploadProgress, readOnly }) => {
  const fileInputRef = useRef(null);

  const handleClickAttach = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(file);
    }

    fileInputRef.current.value = '';
  };

  let isUploading = uploadProgress > 0 && uploadProgress < 100;

  return (
    <div className="upload-button-wrapper">
      <div
        onClick={handleClickAttach}
        className="upload-button"
        style={{
          pointerEvents: isUploading || readOnly ? "none" : "all",
        }}
      >
        <HubIcon fontSize={20} lineColor="#647795">
          <HiAttachment />
        </HubIcon>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        /* accept="image/png, image/jpeg" */
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {isUploading && (
        <div className="upload-progress-circle-wrapper">
          <div
            className="circle"
            style={{
              background: `radial-gradient(closest-side, white 1%, transparent 10% 100%),conic-gradient(#1769F1 ${uploadProgress}%, white ${0})`,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default UploadButton;

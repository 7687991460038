import React, { Component } from 'react'
import Auth from '../../../auth/Auth';
import BlockList from './blocked/blockList';
import BlockDetails from './blocked/blockDetails';

export default class blockedTasks extends Component {

    constructor(props) {    

        super(props);
        this.state = {
            Loading:true,
            LoadingError:false,
            LoadingErrorText:"",
            data:[],
            showDetails:false,
            selectedRecord:{}
        }
    }
    
    componentDidMount() {
      this.getData();
    }

    toggleShowDetails=(record) => {
        this.setState({...this.state, showDetails:!this.state.showDetails, selectedRecord:record})
    }

    getData = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch('/api/task/block', requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {   
                if(r.body.status==200) {
                    this.setState({...this.state, Loading:false, LoadingError:false,data:r.body.object});
                } else if(r.body.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, Loading:false, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    
    }


    resolveBlock = (uid, object, stage) => {

        let data=this.state.data;
        let task=data.find(x=>x.uid===uid)
        task.isBlocked=false;
        task.blockResolveDescription=object.blockResolveDescription

        let selectedRecord=this.state.selectedRecord
        selectedRecord.blockResolveDescription=object.blockResolveDescription
        selectedRecord.isBlocked=false;
        selectedRecord.blockResolveDate=Date.now();
        selectedRecord.blockResolveBy=Auth.getResourceObject()

        this.setState({...this.state,data:data, selectedRecord:selectedRecord},  this.props.blockTask(uid,object, this.props.columnIndex, this.props.cardIndex,stage)) 

    }





  render() {
    return (
        <div className='block-wrapper' style={{height:"100%"}}>
            {!this.state.showDetails?
                <BlockList data={this.state.data} toggleShowDetails={this.toggleShowDetails} refresh={this.getData}/>:
                <BlockDetails blockTask={this.resolveBlock} toggleShowDetails={this.toggleShowDetails} selectedRecord={this.state.selectedRecord}/>
            }
        </div>
    )
  }
}

import React from "react";

import Tag from "../Tag";
import TooltipWithPage from "../../tooltipWithPage/tooltipWithPage";
import "../TagGroup/TagGroup.scss";

const TagGroup = ({ tags, style, collapsedCount = 2 }) => {
  return (
    !!tags?.length && (
      <div className="hub-tag-container" style={{ ...style }}>
        {tags.map((t, i) => {
          return (
            <Tag
              tagName={t.tag_name}
              tagColor={t.tag_color}
              collapsed={i > collapsedCount - 1}
              key={t.tag_uid}
            />
          );
        })}

        <div className="hub-card-tag-tooltip-wrapper">
          <TooltipWithPage
            title={"Tags"}
            style={{ width: "220px" /**/, top: "29px" }}
            arrowLocaiton={{ display: "none" }}
            noCloseIcon
          >
            <div
              className="hub-tag-container"
              style={{ rowGap: "5px", flexWrap: "wrap" }}
            >
              {tags.map((t, i) => {
                return (
                  <Tag
                    key={t.tag_uid}
                    tagName={t.tag_name}
                    tagColor={t.tag_color}
                  />
                );
              })}
            </div>
          </TooltipWithPage>
        </div>
      </div>
    )
  );
};

export default TagGroup;

import React, { Component } from 'react';

class PanelWrapper extends Component {
    render() {
        return (
            <div id={this.props.id} className="hub-rmUtil-panel-wrapper" onScroll={this.props.onScroll} style={{...this.props.style}}>
                {this.props.children}
            </div>
        );
    }
}

export default PanelWrapper;
import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

import Tab from '../../common/tabs/Tab';
import TabGroup from '../../common/tabs/TabGroup';

import TabContentGroup from '../../common/tabs/TabContentGroup';
import TabContent from '../../common/tabs/TabContent';
import MainButton from '../../common/buttons/MainButton';


import TimesheetSideNotes from './TimesheetSideNotes';
import TimesheetSideSummary from './TimesheetSideSummary';
import { browserHistory } from 'react-router';

import TimesheetSideBarDetails from './TimesheetSideBarDetails';
class TimesheetSideBar extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            activePage: "timeSum"
        }
    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }

      goToUrl = () =>{

        let path = "/timesheet/detail?resourceId="+this.props.selectedRecord.resource.uid+"&timeperiodId="+this.props.selectedRecord.timeperiodId; 
         //console.log(this.props)
        window.location.replace(path);
      }

    render() {
      
        
        return (
          this.props.selectedRecord.uid!==-1?
          <div className="hub-tabGroup-wrapper" >
            <TabGroup>
               <Tab to="timeSum" onClick={this.setActivePage} label="Timesheet Summary" activePage={this.state.activePage} />
               <Tab to="timeNotes" onClick={this.setActivePage} label="Conversation" activePage={this.state.activePage} />
               <Tab to="timeDetail" onClick={this.setActivePage} label="Details" activePage={this.state.activePage} />
            </TabGroup> 
            <TabContent to="timeSum" activePage={this.state.activePage}>
                <TimesheetSideSummary activePage={this.state.activePage} selectedRecord={this.props.selectedRecord} title={this.props.title} />
            </TabContent>
             <TabContent to="timeNotes" activePage={this.state.activePage}>
                <TimesheetSideNotes selectedRecord={this.props.selectedRecord} title={this.props.title} />
            </TabContent>
             <TabContent to="timeDetail" activePage={this.state.activePage}>
                <TimesheetSideBarDetails selectedRecord={this.props.selectedRecord} title={this.props.title} />
            </TabContent> 
          </div>:
          <div style={{textAlign: "center", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div className='label'>Timesheet for related period is not created yet.</div>
             <br />
             <MainButton onClick={this.goToUrl} key="newTaskButton" className="button-2" icon={false} label="Go to Timesheet" /> 
          </div>
        );
    }
}




export default TimesheetSideBar;

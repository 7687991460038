import "./App.css";
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import ProtectedRouter from "./ProtectedRouter";
import Layout from "./common/Layout";

import Modal from ".//common/modal";

import ProjectList from "./projects/list";
import ProjectTiles from "./projects/projecttiles";
import ProjectDetails from "./projects/details";

import ProgramDetails from "./pages/program/details";

import Login from "./login";
import MyTimesheet from "./pages/ts/MyTimesheet";
import TimesheetList from "./pages/ts/TimesheetList";
import OtherWorkList from "./pages/otherWork/OtherWorkList";

import TaskBoard from "./pages/taskBoard";
import Timesheet from "./pages/ts/Timesheet";

import ResourceList from "./pages/rm/ResourceList";
import ResourceUtilization from "./pages/rm/newRmUtil/";

import Roadmap from "./pages/projects/roadmap";

import ChangeSetList from "./pages/changeSet";
import ChangeSetDetails from "./pages/changeSet/details";
import ProgramList from "./pages/program/programList";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/dashboard";
import TeamHub from "./pages/teamHub";

import DemandList from "./pages/demand/DemandList";
import DemandDetails from "./pages/demand/DemandDetails";
import DemandTabController from "./pages/demand/DemandTabController";
import { registerIntercepts } from "./api/http-common";
import Auth, { resourceObject } from "./auth/Auth";
import CommonContext from "./store/common-context";
import { getConfig } from "./api/config";
import { configCodes } from "./static/config";

const App = () => {
  /*   const [sessionId, setSessionId] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false); */
  const [selectedRecord, setSelectedRecord] = useState({});

  const { setUserInfo, setConfigs } = useContext(CommonContext);

  useEffect(() => {
    const hasLoggedIn = Auth.check();
    registerIntercepts();
    if (hasLoggedIn) {
      const resourceInfo = JSON.parse(localStorage.getItem(resourceObject));
      setUserInfo({ ...resourceInfo });
      getConfig(configCodes.fileSize)
        .then((res) => {
          if (res.status === 200) {
            let configs = { fileConfig: res?.data?.object };
            setConfigs({ ...configs });
          }
        })
        .catch((err) => console.log("err", err));
    }

    /* if (isAuthenticated) window.location.pathname = "/imp"; */
  }, []);

  /*  const setAuth = (isAuth, sid) = {
  setSessionId(sid);
  setIsAuthenticated(isAuth);
  } */

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/projecttiles"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="projectTiles"
                navId="pfProjects"
                pageTitle="Projects"
              >
                <ProjectTiles />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="/project"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="projectList"
                navId="pfProjects"
                pageTitle="Projects"
              >
                <ProjectList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="/changeset"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="changeSetList"
                navId="pfChangeSet"
                pageTitle="Change Sets"
              >
                <ChangeSetList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="/changeset/:uid/*"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="changeSetDetails"
                navId="pfChangeSet"
                pageTitle="Change Set Details"
              >
                <ChangeSetDetails />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="/roadmap"
          element={
            <ProtectedRouter>
              <Layout pageCode="roadmap" navId="pfRoadmap" pageTitle="Roadmap">
                <Roadmap />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="/program"
          element={
            <ProtectedRouter>
              <Layout pageCode="program" navId="pfProgram" pageTitle="Program">
                <ProgramList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/program/:programid/*"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="programDetails"
                navId="pfPrograms"
                pageTitle="Programs"
                selectedRecord={selectedRecord}
              >
                <ProgramDetails
                  setSelectedRecord={setSelectedRecord}
                  selectedRecord={selectedRecord}
                />
              </Layout>
            </ProtectedRouter>
          }
        />

        <Route
          path="/teamHub/*"
          element={
            <ProtectedRouter>
              <Layout pageCode="tmHub" navId="tmHub" pageTitle="Team Hub">
                <TeamHub />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/project/:projectid/*"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="projectDetails"
                navId="pfProjects"
                pageTitle="Projects"
                selectedRecord={selectedRecord}
              >
                <ProjectDetails
                  setSelectedRecord={setSelectedRecord}
                  selectedRecord={selectedRecord}
                />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/mytimesheet"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="myTimesheet"
                navId="tsMyTimesheet"
                pageTitle="Timesheets"
              >
                <MyTimesheet />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/timesheets"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="timesheetList"
                navId="tsTimesheets"
                pageTitle="Timesheets"
              >
                <TimesheetList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/timesheet/detail"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="timesheetDetails"
                navId="tsMyTimesheet"
                pageTitle="Timesheets"
              >
                <Timesheet />
              </Layout>
            </ProtectedRouter>
          }
        />

        <Route
          exact
          path="/resources"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="resourceList"
                navId="rmResources"
                pageTitle="Resources"
              >
                <ResourceList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/resourceUtilization"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="resourceUtilization"
                navId="rmResourceUtil"
                pageTitle="Resource Utilization"
              >
                <ResourceUtilization />
              </Layout>
            </ProtectedRouter>
          }
        />
        {/*<Route exact path="/taskBoard" element={<ProtectedRouter><Layout pageCode="taskBoard" navId="pfTaskBoard" pageTitle="Tasks Board" ><TaskBoard /></Layout></ProtectedRouter>} />*/}
        <Route
          exact
          path="/teamBoard"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="teamBoard"
                navId="tmBoard"
                pageTitle="Team Board"
              >
                <TaskBoard />
              </Layout>
            </ProtectedRouter>
          }
        />

        <Route
          exact
          path="/otherwork"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="otherWorkList"
                navId="pfOtherWorks"
                pageTitle="Other Work"
              >
                <OtherWorkList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="/demand"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="demandList"
                navId="pfDemands"
                pageTitle="Demands"
              >
                <DemandList />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          exact
          path="/demand/:demandid/*"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="demandDetails"
                navId="pfDemands"
                pageTitle="Demands"
                selectedRecord={selectedRecord}
              >
                <DemandTabController
                  setSelectedRecord={setSelectedRecord}
                  selectedRecord={selectedRecord}
                />
              </Layout>
            </ProtectedRouter>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRouter>
              <Layout
                pageCode="dashboard"
                navId="cmnHome"
                pageTitle="Dashboard"
              >
                <Dashboard />
              </Layout>
            </ProtectedRouter>
          }
        />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        limit={3}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default App;

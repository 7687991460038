import React, { Component } from "react";
import { Resizable } from "re-resizable";

class GanttTH extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ordered: false,
      sortOrder: "asc",
      sortColumn: "",
    };
  }
  /*
    componentDidUpdate(prevProps,prevState)
    {
     
        if(prevState.ordered!==prevProps.sorted)
        {    
             console.log(this.props.column.dataKey)
            console.log("ife girdik")
         console.log(prevState)
         console.log(prevProps)
            //console.log(this.state.ordered)
       

           this.setState({...this.state,  ordered:!this.state.ordered})
        }
        
    }

*/
  componentDidMount() {
    //console.log("componentDidMount")
    this.setState({
      ...this.state,
      sortColumn: this.props.column.dataKey,
    });
  }

  render() {
    //console.log("ganttth", this.props)

    return (
      <th
        style={{
          width: this.props.column.width + (this.props.ind === 0 ? 0 : 0),
          overflow: "hidden",
        }}
      >
        <Resizable
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minWidth={80}
          size={{
            width: this.props.column.width + (this.props.ind === 0 ? 32 : 0),
          }}
          onResizeStop={(e, direction, ref, d) => {
            this.props.handleResizeColumn(this.props.ind, d.width);
          }}
          handleClasses={{ right: "th-resize-handler" }}
        >
          <div
            className="th"
            style={{
              width: this.props.column.width /*+ (this.props.ind===0?20:0)*/,
              overflow: "hidden",
            }}
          >
            <span>{this.props.column.label}</span>
          </div>
        </Resizable>
      </th>
    );
  }
}

export default GanttTH;

import React, { Component } from 'react';
import DataTable from '../../common/dataTable';
import TaskAssignmentSidebar from './TaskAssignmentSidebar.js'
import ButtonArea from '../../common/sideBarPageContent/buttonArea/buttonArea.js';
import BodyArea from '../../common/sideBarPageContent/bodyArea/bodyArea.js';
import PageArea from '../../common/sideBarPageContent/pageArea/pageArea.js';
import MainButton from '../../common/buttons/MainButton';

import ActivityAssignmentSidebar from './ActivityAssignmentSidebar';
class ActivityAssignmentExpanded extends Component {


constructor(props) {
  super(props)
    this._dataTable = React.createRef();
  this.state = {
     isNew:false
  }
}
    openSidebar = () => {
        

        this.setState({...this.state, isNew:!this.state.isNew},this._dataTable.current.toggleSidebar() );
        
    }



    





    render() {

        //console.log("ss",this.props)

        let source=this.props.project?.source?.code
     
        let dtConfig = {
            endPoint: "/api/projects/"+this.props.selectedRecord.projectId+"/wbs/"+this.props.selectedRecord.wbsId+"/activity/"+this.props.selectedRecord.uid+"/activityAssignment",
            columns: [
                 
                 ,{id:"uid", dataKey:"uid", label:"Uid", type:"text" }
                 ,{id:"code", dataKey:"code", label:"Code", type:"text" }
                ,{id:"role", dataKey:"role.name", label:"Role", type:"text" }
              


               ,{id:"p6Plan.plannedStart", dataKey:"p6Plan.plannedStart", label:"Planned Start"+ (source!=="hub"?" (P6)":""), type:"date"}
                ,{id:"p6Plan.plannedFinish", dataKey:"p6Plan.plannedFinish", label:"Planned Finish"+ (source!=="hub"?" (P6)":""), type:"date"}
                ,{id:"p6Plan.plannedDuration", dataKey:"p6Plan.plannedDuration", label:"Planned Duration"+ (source!=="hub"?" (P6)":""), type:"day"}
                ,{id:"p6Plan.plannedUnits", dataKey:"p6Plan.plannedUnits", label:"Planned Units"+ (source!=="hub"?" (P6)":""), type:"hour"}
                
                ,{id:"p6Plan.actualStart", dataKey:"p6Plan.actualStart", label:"Actual Start"+ (source!=="hub"?" (P6)":""), type:"date"}
                ,{id:"p6Plan.actualFinish", dataKey:"p6Plan.actualFinish", label:"Actual Finish"+ (source!=="hub"?" (P6)":""), type:"date"}
                ,{id:"p6Plan.actualDuration", dataKey:"p6Plan.actualDuration", label:"Actual Duration"+ (source!=="hub"?" (P6)":""), type:"day"}
                ,{id:"p6Plan.actualUnits", dataKey:"p6Plan.actualUnits", label:"Actual Units"+ (source!=="hub"?" (P6)":""), type:"hour"}

                ,{id:"p6Plan.baselineStart", dataKey:"p6Plan.baselineStart", label:"Baseline Start"+ (source!=="hub"?" (P6)":""), type:"date"}
                ,{id:"p6Plan.baselineFinish", dataKey:"p6Plan.baselineFinish", label:"Baseline Finish"+ (source!=="hub"?" (P6)":""), type:"date"}
                ,{id:"p6Plan.baselineDuration", dataKey:"p6Plan.baselineDuration", label:"Baseline Duration"+ (source!=="hub"?" (P6)":""), type:"day"}
                ,{id:"p6Plan.baselineUnits", dataKey:"p6Plan.baselineUnits", label:"Baseline Units"+ (source!=="hub"?" (P6)":""), type:"hour"}

                ,{id:"p6Plan.remainingDuration", dataKey:"p6Plan.remainingDuration", label:"Remaining Duration"+ (source!=="hub"?" (P6)":""), type:"day"}
                ,{id:"p6Plan.remainingUnits", dataKey:"p6Plan.remainingUnits", label:"Remaining Units"+ (source!=="hub"?" (P6)":""), type:"hour"}

                ,{id:"p6Plan.atCompletionDuration", dataKey:"p6Plan.atCompletionDuration", label:"At Completion Duration"+ (source!=="hub"?" (P6)":""), type:"day"}
                ,{id:"p6Plan.atCompletionUnits", dataKey:"p6Plan.atCompletionUnits", label:"At Completion Units"+ (source!=="hub"?" (P6)":""), type:"hour"}
              
            ], 
            idAttribute: "uid",
            nameAttribute: "name",
            view: {
                visibleColumnList: [ "role","p6Plan.plannedStart","p6Plan.plannedFinish","p6Plan.baselineUnits","p6Plan.plannedUnits","p6Plan.actualUnits","p6Plan.remainingUnits","p6Plan.atCompletionUnits"],
                mode: "list",
                 hideToolbar: false,
                hideActionbar: false,
                hideSelectbox: false
            },   
            sideBar: <ActivityAssignmentSidebar selectedRecord={this.props.selectedRecord} project={this.props.project}  />  ,
            parentRecord:   this.props.selectedRecord ,

             button:(source==="hub")?
            [    
                {key:"newAssignment", className:"", onClick: this.toggleSidebar, label:"New Assignment" , type:"new"},
                {key:"deleteAssignment", className:"", onClick: this.toggleSidebar, label:"Delete", type:"delete", endpoint:"/api/activityAssignment"}
            ] :false
           
         
        }
 //<MainButton onClick={this.toggleSidebar} key="newAssignmentButton" className="button-2" icon={false} label="New Assignment" /> 
        return (
            <PageArea>
                                 
                        <DataTable ref={this._dataTable} key="OWAssignmentTable" config={dtConfig}  />
                  
            </PageArea>
        );
    }
}

export default ActivityAssignmentExpanded
import "./TheadBasic.scss";

/**
 * TheadBasic is a component that renders a styled container, typically used for wrapping table header rows.
 * It accepts custom styles and children elements, allowing for flexible usage in table layouts.
 *
 * @param {object} [style] Additional inline styles to apply to the component.
 * @param {JSX.Element | JSX.Element[]} children The content to be rendered inside the component, usually table headers.
 * @returns {JSX.Element} The rendered TheadBasic component.
 *
 * @example
 * // Render a TheadBasic component with table headers
 * <TheadBasic style={{ backgroundColor: '#f5f5f5' }}>
 * <THBasic style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
 *   <span>Header Title</span>
 * </THBasic>
 *  * <THBasic style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
 *   <span>Header Title</span>
 * </THBasic>
 * </TheadBasic>
 */
const TheadBasic = ({ style, children }) => {
  return (
    <div className="hud-dt-th-row" style={{ ...style }}>
      {children}
    </div>
  );
};

export default TheadBasic;

import axios from "axios";

//Set Global baseURL
const axiosApiGlobal = axios.create({
  baseURL: window.location.origin,
});

//Set Global Token in Axios
axiosApiGlobal.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("hubtoken")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const axiosFileUpload = axios.create({
  baseURL: window.location.origin,
});

axiosFileUpload.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem("hubtoken")}`,
      "Content-Type": "multipart/form-data",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const handleSuccessResponse = (response) => {
  return response;
};

const handleErrorResponse = (error, setNotification) => {
  const errorMessage = error.response.message;
  console.log({ errorMessage });
  /* setNotification({ isNotified: true, message: errorMessage, type: "error" }); */
  return Promise.reject(error);
};

export const registerIntercepts = (setNotification) => {
  axiosApiGlobal.interceptors.response.use(handleSuccessResponse, (error) =>
    handleErrorResponse(error, setNotification)
  );

  axiosFileUpload.interceptors.response.use(handleSuccessResponse, (error) =>
    handleErrorResponse(error, setNotification)
  );
};

export default axiosApiGlobal;

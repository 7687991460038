import { skeletonSet } from "../../../static/config";
import SkeletonLoader from "../../skeletonLoader";
import "./Conversations.scss";

const Conversations = () => {
  const { avatar, label } = skeletonSet;

  return (
    <div className="conversations">
      <div className="convo-wrapper">
        <SkeletonLoader
          width={avatar.width}
          height={avatar.height}
          borderRadius={avatar.borderRadius}
        />
        <div className="label-section">
          <SkeletonLoader
            width={195}
            height={label.height}
            borderRadius={label.borderRadius}
          />
          <SkeletonLoader
            width={336}
            height={label.height}
            borderRadius={label.borderRadius}
          />
        </div>
        <SkeletonLoader
          width={avatar.width}
          height={avatar.height}
          borderRadius={avatar.borderRadius}
        />
      </div>
      <div className="divider" />
      <div className="convo-wrapper">
        <SkeletonLoader
          width={avatar.width}
          height={avatar.height}
          borderRadius={avatar.borderRadius}
        />
        <div className="label-section">
          <SkeletonLoader
            width={195}
            height={label.height}
            borderRadius={label.borderRadius}
          />
          <SkeletonLoader
            width={336}
            height={label.height}
            borderRadius={label.borderRadius}
          />
        </div>
        <SkeletonLoader
          width={avatar.width}
          height={avatar.height}
          borderRadius={avatar.borderRadius}
        />
      </div>
    </div>
  );
};

export default Conversations;

import React, { Component } from 'react';
import './Badge.css';

class Badge extends Component {

    /*
        Şimdilik aldığı değer sadece value ve opsiyonel olarak style
        ileride backgroundColor, color, 
    */
    render() {
        return (
            <div className="hub-cmn-badge-wrapper">
                {this.props.children}
                {(this.props.value>0&&this.props.variant!=="dot")?<div style={{...this.props.style}} className="badge">{this.props.value>99?"99+":this.props.value}</div>:false}
                {(this.props.value>0&&this.props.variant==="dot")?<div style={{...this.props.style, width:8, height:8, padding:0, minWidth:8}} className="badge"></div>:false}
            </div>
        );
    }
}

export default Badge;
import React, { Component } from 'react';
import TabGroup from '../../common/tabs/TabGroup';
import Tab from '../../common/tabs/Tab';
import TabContent from '../../common/tabs/TabContent';

import Auth from '../../auth/Auth';

import PageLayout from '../../common/pageElements/pageLayout/pageLayout';
import PageHeader from '../../common/pageElements/pageHeader/'

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state={ 
            activePage: "hub.myActions",
            pages:[]
        
        }
    }


    
    componentDidMount() 
    { 
        this.getData() 
    }

    


    getData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
        };
        fetch('/api/pageActive', requestOptions)
        .then(response => response.json().then(data => ({status:response.status, body:data})))
        .then(r => {
            //console.log("getData",r.body.object.sort((a, b) => a.pageOrder < b.pageOrder ? 1 : -1))
            if (r.status===200) {
                let array=r.body.object.sort((a, b) => a.pageOrder > b.pageOrder ? 1 : -1)
              //  console.log("r.body.object.filters",JSON.parse(r.body.object.filters))
              
                this.setState({...this.state, pages:array, activePage:"hub."+array[0]?.code });
            } else if (r.status===401) {
                window.location.replace("/login");
            } else if (r.status===403) {
                this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
            } else {
                this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
            }
        });
    }

    handleClick = (to) => { this.setState({...this.state, activePage:to})}

    checkTabAuthorization()
    {
        let pages=this.state.pages;
        let resource=Auth.getResourceObject();
        let secProfile=resource.securityProfile?.name;
        let resourceUid=resource.uid
        
        let authorization=true
  
        return <TabGroup>
            { pages.map((p)=>
                    {            
                        //console.log("page",p.pageAuthorization)

                        if(p.pageAuthorization!==null)
                        {
                            authorization=eval(p.pageAuthorization)

                            //console.log("authorization",authorization)
                        }

                        else
                        {
                            authorization=true
                        }
                        if(authorization)
                        {
                            
                            return <Tab label={p.name} activePage={this.state.activePage} onClick={this.handleClick} to={"hub."+ p.code} />
                        }
                      
                    })}

                </TabGroup>
            
    }

    render() {
        
        //console.log("dashboard index: ", this.state.pages)
        let pages=this.state.pages
        return (
            <>
            <PageHeader
              title={"Dashboard"}
              left={ this.checkTabAuthorization() }
            /> 
            
            <div className="hub-page">
            
            { pages.map((p)=>
                    {
                        return  <TabContent to={"hub."+ p.code} activePage={this.state.activePage}>  
                                    < PageLayout code={ p.code}/>
                                </TabContent>
        
                    })
                }       
           
            </div>
            </>
        );
    }
}

export default Dashboard;
import React from "react";

const HiColors = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_2519_27636)">
        <path
          d="M1.33325 8.00004C1.33325 11.6819 4.31802 14.6667 7.99992 14.6667C9.10449 14.6667 9.99992 13.7713 9.99992 12.6667V12.3334C9.99992 12.0238 9.99992 11.869 10.017 11.739C10.1352 10.8415 10.8414 10.1353 11.7389 10.0172C11.8688 10 12.0236 10 12.3333 10H12.6666C13.7712 10 14.6666 9.10461 14.6666 8.00004C14.6666 4.31814 11.6818 1.33337 7.99992 1.33337C4.31802 1.33337 1.33325 4.31814 1.33325 8.00004Z"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={lineColor}
        />
        <path
          d="M4.66659 8.66671C5.03477 8.66671 5.33325 8.36823 5.33325 8.00004C5.33325 7.63185 5.03477 7.33337 4.66659 7.33337C4.2984 7.33337 3.99992 7.63185 3.99992 8.00004C3.99992 8.36823 4.2984 8.66671 4.66659 8.66671Z"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={lineColor}
        />
        <path
          d="M10.6666 6.00004C11.0348 6.00004 11.3333 5.70156 11.3333 5.33337C11.3333 4.96518 11.0348 4.66671 10.6666 4.66671C10.2984 4.66671 9.99992 4.96518 9.99992 5.33337C9.99992 5.70156 10.2984 6.00004 10.6666 6.00004Z"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={lineColor}
        />
        <path
          d="M6.66658 5.33337C7.03477 5.33337 7.33325 5.0349 7.33325 4.66671C7.33325 4.29852 7.03477 4.00004 6.66658 4.00004C6.2984 4.00004 5.99992 4.29852 5.99992 4.66671C5.99992 5.0349 6.2984 5.33337 6.66658 5.33337Z"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={lineColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2519_27636">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HiColors;

import React, { Component } from 'react';
import "./ToggleSwitch.css";



class ToggleSwitch extends Component {
  constructor(props) {
      super(props);
  }

  handleChange = (e) => {
      this.props.onChange(this.props.filterId,e.target.checked);
  }
  
  render() {
  return (
    <div className="togglecontainer">
      {this.props.label}{" "}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox" name={this.props.label} id={this.props.label} onChange={this.handleChange}  defaultChecked={this.props.checked}/>
        <label className="label" htmlFor={this.props.label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
   );
  }
}
  
export default ToggleSwitch;
import React, { Component } from "react";
import "./FilterItem.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";

import FilterItemFormText from "./FilterItemFormText";
import FilterItemFormDate from "./FilterItemFormDate";
import FilterItemFormNumber from "./FilterItemFormNumber";
import FilterItemFormDynamicLookup from "./FilterItemFormDynamicLookup";
import FilterItemFormStaticLookup from "./FilterItemFormStaticLookup";
import FilterItemFormBoolean from "./FilterItemFormBoolean";

import { DateUtils } from "../../Commons";
import HubIcon from "../../hubIcon/HubIcon";
import HiClose from "../../icons/HiClose";
import HiArrowShortDown from "../../icons/HiArrowShortDown";

class FilterItem extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = { isOpen: false };
    this.dutils = new DateUtils();
  }

  onRemove = () => {
    this.props.removeFilter(this.props.column);
  };

  open = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, isOpen: true }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
    });
  };

  close = () => {
    this.setState({ ...this.state, isOpen: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  setFilter = (filter) => {
    this.props.setFilter(this.props.column.id, filter);
  };

  /**
   *
   * Set the wrapper ref
   *
   **/

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.close();
    }
  };

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  FilterItemForm = (t) => {
    // t: type
    switch (t) {
      case "date":
        return (
          <FilterItemFormDate
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      case "number":
        return (
          <FilterItemFormNumber
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      case "percentage":
        return (
          <FilterItemFormNumber
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      case "text":
        return (
          <FilterItemFormText
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      case "dynamicLookup":
        return (
          <FilterItemFormDynamicLookup
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      case "staticLookup":
        return (
          <FilterItemFormStaticLookup
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      case "boolean":
        return (
          <FilterItemFormBoolean
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
      default:
        return (
          <FilterItemFormText
            setFilter={this.setFilter}
            column={this.props.column}
          />
        );
        break;
    }
  };

  FilterItemExpression = (t) => {
    switch (t) {
      case "text":
        return (
          <>
            <span className="option-indicator">{this.props.column.filter.option.indicator}</span>
            <span className="filter-value">
              {" "}
              {this.props.column.filter.value !== null
                ? " " + this.props.column.filter.value
                : ""}
            </span>
          </>
        );
        break;
      case "date":
        return (
          this.props.column.filter.option.indicator +
          (this.props.column.filter.value[0] !== null
            ? " " + this.dutils.Date2Str(this.props.column.filter.value[0])
            : "") +
          (this.props.column.filter.value[1] !== null
            ? " - " + this.dutils.Date2Str(this.props.column.filter.value[1])
            : "")
        );
        break;
      case "number":
        return (
          this.props.column.filter.option.indicator +
          (this.props.column.filter.value[0] !== null
            ? " " + this.props.column.filter.value[0].toString()
            : "") +
          (this.props.column.filter.value[1] !== null
            ? " - " + this.props.column.filter.value[1].toString()
            : "")
        );
        break;
      case "percentage":
        return (
          this.props.column.filter.option.indicator +
          (this.props.column.filter.value[0] !== null
            ? " " + this.props.column.filter.value[0].toString()
            : "") +
          (this.props.column.filter.value[1] !== null
            ? " - " + this.props.column.filter.value[1].toString()
            : "")
        );
        break;
      case "dynamicLookup":
        return (
          this.props.column.filter.option.indicator +
          (this.props.column.filter?.value?.length > 0
            ? " " + this.props.column.filter.value.map((e) => e.label).join()
            : "")
        );
        break;
      case "staticLookup":
        return (
          this.props.column.filter.option.indicator +
          (this.props.column.filter?.value?.length > 0
            ? " " + this.props.column.filter.value.map((e) => e.label).join()
            : "")
        );
        break;
      case "boolean":
        return this.props.column.filter.option.indicator;
        break;
      default:
        return "";
        break;
    }
  };

  render() {
    //console.log("filterItem",this.props.column)
    return (
      <div className="hub-dataTable-filterItem-wrapper" ref={this.wrapperRef}>
        <div
          className="hub-dataTable-filterItem"
          style={this.state.isOpen ? { backgroundColor: "#accbfc" } : {}}
        >
          <div className="close" onClick={this.onRemove}>
            <HubIcon fontSize={14} lineColor="#779DD9">
              <HiClose />
            </HubIcon>
          </div>
          <div className="text" onClick={this.open}>
            <span className="attribute">{this.props.column.label}</span>
            {this.props.column.filter &&
            this.props.column.filter.option !== null ? (
              <span className="expression">
                {this.FilterItemExpression(this.props.column.type)}
              </span>
            ) : (
              <span className="expression">Select</span>
            )}
            <span className="arrow-down">
              <HubIcon fontSize={16}>
                <HiArrowShortDown />
              </HubIcon>
            </span>
          </div>
        </div>
        {this.state.isOpen
          ? this.FilterItemForm(this.props.column.type)
          : false}
      </div>
    );
  }
}

export default FilterItem;

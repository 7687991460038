import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { formatDecimal, mergeConfigs } from "../../common/Commons";

const BarChart = ({ data, configuration }) => {
  const [graphConfig, setGraphConfig] = useState({});

  const convertData = (data) => {
    if (data.length > 0) {
      let outputArray = [];

      // Extract keys from the first object in the input array
      let keys = Object.keys(data[0]);

      // Exclude "month_label" from keys
      keys = keys.filter((key) => key !== "month_label");

      // Iterate over each key
      keys.forEach((key) => {
        // Extract the data for the current key
        let currentData = data.map((item) => (item !== 0 ? item[key] : null));

        // Change every 0 item to null
        let filteredData = currentData.map((item) =>
          item !== 0 ? item : null
        );

        // Extract label for proper item
        const label = configuration.legendLabels[key]?.label;

        label && outputArray.push({ name: label, data: filteredData });
      });

      return outputArray;
    }
  };

  let convertedData = convertData(data);

  const categories = data.map((item) => {
    return item.month_label ?? "";
  });

  const defaultConfig = {
    height: "auto",
    type: "bar",
    series: convertedData?.reverse(),
    options: {
      chart: {
        toolbar: {
          show: false, // Hide toolbar
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          horizontal: false, // bar direction
          columnWidth: "40%", // bar width
          dataLabels: {
            orientation: "vertical", // bar value label
            position: "top", // bottom/center/top
          },
        },
      },
      dataLabels: {
        enabled: true,
          formatter: (w) => {
          return formatDecimal(w);
        },
        style: {
          colors: ["#051530"],
          fontFamily: "Noto Sans",
          fontSize: "11px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        },
        offsetY: 5,
      },
      stroke: {
        show: true,
        width: 4, // bar gap
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories,

        labels: {
          formatter: (w) => {
            return typeof w === "number" ? formatDecimal(w) : w;
          },
          offsetY: 0.5,
          style: {
            colors: "#051530",
            fontFamily: "Noto Sans",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (w) => {
            return typeof value === "number" ? formatDecimal(w) : w;
          },
          offsetY: 0.5,
          style: {
            colors: "#051530",
            fontFamily: "Noto Sans",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
        },
      },

      fill: {
        opacity: 1,
      },
      tooltip: {
        style: {
          colors: "#051530",
          fontSize: "11px",
          fontFamily: "Noto Sans",
          fontStyle: "normal",
          fontWeight: "400",
        },
      },

      colors: ["#1769F1", "#8225FA", "#FBBF26", "#E95A63", "#5AF3E1"], // bar color set
      legend: {
        offsetY: 15, // legend y position
        labels: {
          colors: "#051530",
        },
        fontFamily: "Open Sans",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "15px",
        markers: {
          width: 12,
          height: 4,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 4,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0.5,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 15,
        },
      },
    },
  };

  useEffect(() => {
    mergeConfigs(defaultConfig, configuration);
    setGraphConfig(defaultConfig);
  }, []);

  return (
    data.length > 0 &&
    !!graphConfig.type && (
      <div className="chart-container bar">
        <Chart {...graphConfig} />
      </div>
    )
  );
};

export default BarChart;

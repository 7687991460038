import React, { Component } from "react";
import { Navigate, Route } from "react-router-dom";
import auth from "./auth/Auth";

class ProtectedRouter extends Component {
  render() {
    return auth.check() ? (
      this.props.children
    ) : (
      <Navigate to="/login" replace />
    );
  }
}

export default ProtectedRouter;

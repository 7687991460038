import React, { Component } from "react";
import Hirerarchicaltem from "./hirerarchicaltem";
import "./hierarchicalTable.css";
export default class hierarchicalTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: "",
      description: false,
      data: [],
    };
  }

  componentDidMount() {
    this.makeHiearData();
  }

  //Datayı parent çocuk şekilde levele göre sıralar
  makeHiearData = () => {
    let newData = [];
    let data = [...this.props.data];

    // console.log("makeHiearData",this.props.data)

    let i = 0;
    let parentInd = 0;
    let childs = [];

    if (data.filter((x) => x.parent === null).length > 0) {
      childs = data.filter((x) => x.parent === null);
    } else {
      childs.push(data[0]);
    }
    let k = data.length;

    // console.log("childs",childs)
    while (childs.length > 0 && i <= k) {
      for (let j = 0; j < childs.length; j++) {
        if (childs[j].parent !== null) {
          childs[j].parentInd = parentInd;
          if (newData[parentInd]) {
            newData[parentInd].hasChild = true;
            if (parentInd === 0) {
              newData[parentInd].parentInd = -1;
            }
          }
        }
        childs[j].isExpanded = true;
        childs[j].isVisible = true;
        newData.splice(parentInd + 1, 0, childs[j]);
        //console.log("newdata",newData)
        data.splice(
          data.findIndex((y) => y.uid === childs[j].uid),
          1
        );
        childs.splice(j, 1);
      }

      childs = data.filter((x) => x.parent === newData[parentInd]?.uid);
      // console.log("while child",data.filter(x=>x.parent===newData[parentInd].uid).length,childs.length,parentInd<=newData.length)
      while (
        data.filter((x) => x.parent === newData[parentInd]?.uid).length === 0 &&
        childs.length === 0 &&
        parentInd <= newData.length
      ) {
        parentInd++;
        childs = data.filter((x) => x.parent === newData[parentInd]?.uid);
      }
      //console.log("childs",childs)
      i++;
    }

    // console.log("newData",newData,"childs",childs)

    this.setState({ ...this.state, data: newData });
  };

  toggleIsExpanded = (i) => {
    let newData = [...this.state.data];

    newData[i].isExpanded = !newData[i].isExpanded;
    let childs = newData.filter((x) => x.parentInd === i);
    // console.log("toggleIsExpanded",i,childs)
    let j = 0;
    let tempChilds = [...childs];

    while (tempChilds.length > 0 && j < 100) {
      for (let k = 0; k < tempChilds.length; k++) {
        if (newData.filter((x) => x.parent === tempChilds[k].uid).length > 0) {
          newData
            .filter((x) => x.parent === tempChilds[k].uid)
            .map((a) => {
              childs.push(a);
              tempChilds.push(a);
            });
        }
        tempChilds.splice(k, 1);
      }

      j++;
    }
    childs.map((d) => {
      d.isVisible = newData[i].isExpanded;
    });

    this.setState({ ...this.state, data: newData });
  };

  render() {
    return (
      <div className="hier-table-wrapper">
        {this.state.data?.map((val, index) => {
          return new RegExp(this.props.searchString, "i").test(val.name) &&
            val.isVisible ? (
            <Hirerarchicaltem
              label={
                this.props.labelKey
                  ? val[this.props.labelKey]
                  : val.name
              }
              isSelected={this.props.isSelected.name === val.name}
              hasChild={val.hasChild}
              isExpanded={val.isExpanded}
              hierLevel={val.level}
              onClick={(e) => {
                this.props.toggleSelectBar(val);
              }}
              index={index}
              toggleIsExpanded={this.toggleIsExpanded}
              isHierarchicalMode={this.props.searchString === ""}
            />
          ) : (
            false
          );
        })}
      </div>
    );
  }
}

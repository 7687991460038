import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownLeftAndUpRightToCenter, faBan, faUpRightAndDownLeftFromCenter } from '@fortawesome/pro-light-svg-icons'

class ColumnHeader extends Component {

  setCollapsed=()=>{
 

    this.props.setCollapsed(this.props.index);

  }

    render() {

      //console.log(this.props)
        return (
              <div className='header'>
                    {this.props.column.boardColumns.isCollapsed?
                      <div className="column-toolbar">
                        <FontAwesomeIcon style={{cursor: "pointer", marginTop: 4}} 
                          title="Collapse"  
                          icon={this.props.column.boardColumns.isCollapsed?faUpRightAndDownLeftFromCenter:faDownLeftAndUpRightToCenter}  
                          size="1x" 
                          onClick={this.setCollapsed} />
                        <span className="toolbar-number">{this.props.column?.task?.length} / {'\u221E'}</span>
                      </div>
                    :false
                    }
                    <div className='column-name'>
                        {this.props.column.name} 
                        <span className="toolbar-number">{this.props.column?.task?.length}</span>
                        {/*this.props.column.code==="planning"?<FontAwesomeIcon style={{marginLeft:4, }} title="Planned tasks can not be moved on the board."  icon={faBan}  size="1x"  />:false*/}
                    </div>
                    {/*this.props.column.boardColumns.isCollapsed?false:
                      <div className="column-toolbar">
                        <span className="toolbar-number">{this.props.column?.task?.length} / {'\u221E'}</span>
                        <FontAwesomeIcon style={{marginLeft:16, cursor: "pointer"}} title="Collapse"  icon={this.props.column.boardColumns.isCollapsed?faUpRightAndDownLeftFromCenter:faDownLeftAndUpRightToCenter}  size="1x" onClick={this.setCollapsed} />
                      </div>
                    */}
                  {this.props.description?<div className='column-description'>{this.props.column.boardColumns.description}</div>:""}

            
             </div>
        );
    }
}

export default ColumnHeader;
import React, { Component } from 'react'
import Tooltip from '../../common/tooltip/tooltip.js'
export default class ProjectTileMilestoneBox extends Component {
 constructor(props) {
    super(props)
  
    this.state = {
       toggleTooltip:true
       
  }
 }


     handleTooltip = ()=>
        {
           // console.log("changeColor")
            this.setState({...this.state,  toggleTooltip:!this.state.toggleTooltip})
           
           // this.setState({...this.state,  toggleTooltip:!this.state.toggleTooltip})  
        }


    render() {
        return (
                <div className={this.props.milestone.isMilestonePassed?"milestone "+this.props.color:"milestone not-started"} onMouseOver={() => this.handleTooltip()} onMouseOut={() => this.handleTooltip()}> 

                    <Tooltip label={this.props.milestone.name  } date={this.props.milestone.milestoneDate } milestoneType={this.props.milestone.extType } close={this.state.toggleTooltip} class={"top-milestone"} />
                                                        
                </div>
        )
    }
    }

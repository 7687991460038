import React, { Component } from "react";
import "./GaugeChart.css";
import TooltipWithPage from "../../common/tooltipWithPage/tooltipWithPage";
import Chart from "react-apexcharts";

class GaugeChart extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      description: "",
      showBlockResolveTooltip: false,
    };
  }
  /*
    Proplar,
    min: 
    max: 
    value:
    subText:  
    as 
  */

  toggleBlockResolveTooltip = () => {
    this.setState({
      ...this.state,
      showBlockResolveTooltip: !this.state.showBlockResolveTooltip,
    });
  };

  openPanel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ ...this.state, showBlockResolveTooltip: true }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
    });
  };
  closePanel = () => {
    this.setState({ ...this.state, showBlockResolveTooltip: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event?.target)) {
      this.closePanel();
    }
  };

  sortedIndex(array, value) {
    var low = 0,
      high = array.length;

    while (low < high) {
      var mid = (low + high) >>> 1;
      if (array[mid] < value) low = mid + 1;
      else high = mid;
    }
    return low;
  }

  // * configde percent alanı var. Eğer percent false ise yüzde değil sayı gösterir. Hesaplama buna göre değişiyor.

  /*
     Örnek kod;
     3 treshold varsa 6 renk olmalı. dolu kısım ve boş kısım.

     Select 
      58 as value,
      100 as max,
      0 as min,
      50 as mid,
      '25,55,80' as treshold,
      'green,#b8e9b9,yellow,#e9e6b9,red,#f79999' as treshold_color
 */
  render() {
    //console.log("props",this.props,this.props.data.treshold)
    let percent =
      this.props.config?.configuration?.percent !== undefined
        ? this.props.config?.configuration?.percent
        : true;
    //console.log("percent",this.props.config?.configuration?.percent ,percent)
    let d = this.props.data;
    let max = 100;
    if (!percent && d?.max !== undefined) {
      max = d?.max ? d.max : 100;
    }

    let ratio = max / d?.value;

    let colors = this.props.config?.configuration?.colors;
    let tresholds = [];
    let colorIndex = 0;
    let emptyColorIndex = 1;

    if (this.props.data?.treshold) {
      tresholds = this.props.data?.treshold.split(",").map(Number);

      colorIndex = (this.sortedIndex(tresholds, d.value) - 1) * 2;
      emptyColorIndex = colorIndex + 1;

      //console.log("array index",colorIndex)

      if (this.props.data?.treshold_color && colorIndex >= 0) {
        colors = this.props.data?.treshold_color.split(",");
      } else {
        colors = [];
        colors[0] = "#d92020";
        colors[1] = "#e7abab";
      }
    }

    const minValue = d?.min ? d.min : 0;
    const maxValue = d?.max ? d.max : 100;

    // Define the displayed value and calculate the percentage relative to the range
    const displayedValue = d?.value;

    const percentage =
      ((displayedValue - minValue) / (maxValue - minValue)) * 100;

    return (
      <div className="widget-wrapper">
        <div class="hub-do-gauge-chart-wrapper">
          <div className="chart-container">
            <span className="score mid-number"> {d?.mid ? d.mid : 50}</span>
            <span
              className="score start-number"
              style={{
                left: percent ? "0%" : "5%",
                width: percent ? "25px" : "auto",
                textAlign: percent ? "right" : "center",
              }}
            >
              {" "}
              {d?.min ? d.min : 0}
            </span>

            <Chart
              type="radialBar"
              series={[percentage]} // Percentage of the gauge bar
              options={{
                type: "radialBar",
                chart: {
                  dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 5,
                    blur: 10,
                    opacity: 0.15,
                    color: "#1769f1",
                  },
                  offsetY: 0,
                  sparkline: {
                    enabled: false,
                  },
                },
                plotOptions: {
                  radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                      margin: 40,
                      size: "55%",
                      background: "transparent",
                      image: undefined,
                      imageOffsetX: 0,
                      imageOffsetY: 0,
                      position: "front",
                    },
                    track: {
                      background: "#e8eff9",
                      strokeWidth: "100%",
                      margin: 0,
                    },
                    dataLabels: {
                      name: {
                        show: false,
                      },

                      value: {
                        offsetY: 3,
                        color: "var(--hub900, #051530)",
                        textAlign: "center",
                        fontFamily: "Noto Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        formatter: (w) => {
                          // Display the calculated value
                          return percent
                            ? `%${w}`
                            : `${((w * 2) / 100).toFixed(2)}`;
                        },
                      },
                    },
                  },
                },

                colors: ["#1769f1"],
                stroke: {
                  lineCap: "round",
                },
              }}
              {...this.props.configuration}
            />
            <span
              className="score end-number"
              style={{ right: percent ? "0%" : "5%" }}
            >
              {" "}
              {d?.max ? d.max : 100}
            </span>
          </div>

          <div style={{ position: "relative" }}>
            {this.props.data?.treshold && this.state.showBlockResolveTooltip ? (
              <div ref={this.wrapperRef}>
                <TooltipWithPage
                  title={"Tresholds"}
                  closePanel={this.closePanel}
                  style={{ width: "150px" }}
                >
                  <div className="gauge-treshold-list">
                    {tresholds.map((t, i) => {
                      return (
                        <span
                          style={{
                            color:
                              this.props.data?.treshold_color.split(",")[i * 2],
                          }}
                        >
                          {i + 1}. Treshold: {t}
                        </span>
                      );
                    })}
                  </div>
                </TooltipWithPage>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default GaugeChart;

import React, { Component } from "react";
import ButtonArea from "./../../../common/pageElements/buttonArea";
import Section from "./../../../common/pageElements/section";
import SectionContainer from "./../../../common/pageElements/sectionContainer";
import MainButton from "./../../../common/buttons/MainButton";
import Auth from "./../../../auth/Auth";

import { faRotate, faPaperPlaneTop } from "@fortawesome/pro-light-svg-icons";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";

import TextField from "../../../common/inputControls/textField";

import { DateUtils, Utils } from "./../../../common/Commons";

import { useParams } from "react-router";

import LoadingScreen from "../../../common/statusScreen/loadingScreen";

import "./ChangeSetDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProjectSection from "../../../common/pageElements/projectSection";
import HiRefresh from "../../../common/icons/HiRefresh";
import HiCheck from "../../../common/icons/HiCheck";
import HubIcon from "../../../common/hubIcon/HubIcon";
import HiSend from "../../../common/icons/HiSend";
import HiClose from "../../../common/icons/HiClose";

class ChangeSetDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingText: "Loading Project Details",
      Loading: true,
      LoadingError: false,
      LoadingErrorText: "",
      changeSet: {},
    };
  }

  componentDidMount() {
    //console.log(this.props)
    this.getData();
  }

  getData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/changeset/" + this.props.params.uid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        console.log(r);
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            changeSet: r.body.object,
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  submitButtonController(action) {
    if (action === "Submit") {
      //console.log("Submit")

      if (this.state.changeSet?.stage?.code !== "posted") {
        // console.log("ife girdik")
        this.notify("error", "Stage Must Be Posted");
        return 0;
      } else {
        this.routeWF(action);
      }
    }
  }

  routeWF(action) {
    // Action değeri Approve, Revision, Submit değerlerinden birini alabilir.

    document.location.reload();
    fetch("/api/changeset/" + this.state.changeSet.uid + "/action/" + action, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        console.log(r);
        if (r.status == 200) {
          this.notify("success", "Submitted");
          if (r.body.isSuccess) {
            document.location.reload();
          }
        } else if (r.status == 401) {
          //window.location.replace("/login");
        } else if (r.status == 400) {
          this.notify("error", r.body.message);
        } else if (r.status == 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  }

  sync = () => {
    // Action değeri Approve, Revision, Submit değerlerinden birini alabilir.
    document.location.reload();

    fetch("/api/changeset/" + this.state.changeSet.uid + "/sync", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log(r)
        if (r.status == 200) {
          this.notify("success", "Synced");
          if (r.body.isSuccess) {
            document.location.reload();
          }
        } else if (r.status == 401) {
          //window.location.replace("/login");
        } else if (r.status == 400) {
          this.notify("error", r.body.message);
        } else if (r.status == 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };

  render() {
    let util = new DateUtils();

    const changedSize = this.state.changeSet?.changeSetActivity?.filter(
      (x) => x.isChanged === true
    );
    console.log("changedSize", changedSize);
    return (
      <>
        {this.state.Loading ? (
          <LoadingScreen />
        ) : (
          <div className="pageDetails">
            {this.state.changeSet?.stage?.code !== "posted" ? (
              <div
               /*  style={{
                  padding: "8px 16px",
                  textAlign: "left",
                  height: "32px",
                }} */

                className="hub-page-buttonArea"
              >
                <div style={{ float: "left" }}>
                  <MainButton
                    onClick={this.sync}
                    label="Sync Activities"
                    iconLineColor="#fff"
                    icon={<HiRefresh />}
                    className="hub-button button-area-action"
                  />
                </div>
                <div style={{ float: "right", display: "flex" }}>
                  {this.state.changeSet?.stage?.code !== "migration" &&
                  this.state.changeSet?.stage?.code !== "posted" &&
                  this.state.changeSet?.stage?.code !== "open" ? (
                    <MainButton
                      iconLineColor="#fff"
                      icon={<HiCheck />}
                      onClick={() => {
                        this.routeWF("Approve");
                      }}
                      label="Approve"
                      className="hub-button button-area-action"
                    />
                  ) : (
                    ""
                  )}

                  {this.state.changeSet?.stage?.code === "IPT_approval" ||
                  this.state.changeSet?.stage?.code ===
                    "program_manager_approval" ? (
                    <MainButton
                      iconLineColor="#fff"
                      style={{
                        color: "#78180d",
                        backgroundColor: "#fadfdc",
                      }}
                      className="hub-button button-area-action"
                      icon={<HiClose />}
                      onClick={() => {
                        this.routeWF("Revision");
                      }}
                      label="Send Back to Revision"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.changeSet?.status?.code === "waiting_action" ? (
                    <MainButton
                      iconLineColor="#fff"
                      className="hub-button button-area-action"
                      icon={<HiSend />}
                      onClick={() => {
                        this.routeWF("Submit");
                      }}
                      label="Ready For Posting"
                    />
                  ) : (
                    ""
                  )}
                  {this.state.changeSet?.status?.code === "waiting_approval" ? (
                    <MainButton
                      iconLineColor="#fff"
                      className="hub-button button-area-action"
                      icon={<HiSend />}
                      onClick={() => {
                        this.routeWF("post");
                      }}
                      label="Post to P6"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div style={{ clear: "both" }}></div>
              </div>
            ) : (
              <div className="change-set-label">
                <HubIcon>
                  <HiSend />
                </HubIcon>
                Posted
              </div>
            )}
            <ProjectSection title="General">
              <TextField
                width="288px"
                required={true}
                disabled
                value={this.state.changeSet.Code}
                label="Code"
              />
              <TextField
                disabled
                value={this.state.changeSet.createdBy?.fullName}
                width="288px"
                required={true}
                label="Created By"
              />
              <TextField
                disabled
                value={this.state.changeSet.project?.name}
                width="288px"
                required={true}
                label="Project"
              />

              <TextField
                disabled
                value={this.state.changeSet.project?.pmoResponsible?.fullName}
                width="288px"
                label="Central PM"
              />
              <TextField
                disabled
                value={
                  this.state.changeSet.project?.programManagerInfo?.fullName
                }
                width="288px"
                label="Program Manager"
              />
            </ProjectSection>
            <ProjectSection title="Status">
              <TextField
                disabled
                value={this.state.changeSet.status?.name}
                width="288px"
                label="Status"
              />
              <TextField
                disabled
                value={this.state.changeSet.stage?.name}
                width="288px"
                label="Stage"
              />
              <TextField
                disabled
                value={
                  this.state.changeSet?.createdDate !== null
                    ? util.Date2Str(this.state.changeSet?.createdDate)
                    : ""
                }
                width="288px"
                label="Create Date"
              />
              <TextField
                disabled
                value={
                  this.state.changeSet?.migrationDate !== null
                    ? util.Date2Str(this.state.changeSet?.migrationDate)
                    : ""
                }
                width="288px"
                label="Post Date"
              />
            </ProjectSection>
            <ProjectSection title="Change Set">
              <TextField
                disabled
                value="High"
                width="288px"
                label="Impact Level"
              />
              <TextField
                disabled
                value={changedSize.length}
                width="288px"
                label="Changes"
              />

              {/*<TextField disabled value={this.state.changeSet?.pmapprovedDate!==null?util.Date2Str(this.state.changeSet?.pmapprovedDate):""} width="19%" label="Approval Date" />*/}
            </ProjectSection>
          </div>
        )}
      </>
    );
  }
}

export default (props) => <ChangeSetDetails {...props} params={useParams()} />;

import React, { Component } from "react";
import Row from "./Row";
import "./Timeline.css";
import { DateUtils } from "../../../common/Commons";
import { Utils } from "../../../common/Commons";
import Auth from "../../../auth/Auth";
import Pagination from "../../../common/dataTable/Pagination";

import LoadingScreen from "../../../common/statusScreen/loadingScreen";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";

import {
  faDiagramSubtask,
  faDiagramProject,
  faSidebarFlip,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faShare,
} from "@fortawesome/pro-light-svg-icons";

import { faSpinner, faDownToBracket } from "@fortawesome/pro-duotone-svg-icons";

import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import Select from "react-select";
import MainButton from "../../../common/buttons/MainButton";

import SideBar from "../../../common/dataTable/SideBar";

import ActivityBoardSidebarContainer from "../../activityBoard/sidebar/ActivityBoardSidebarContainer";
import HiDownload from "../../../common/icons/HiDownload";

function pad2(n) {
  return n < 10 ? "0" + n : n;
}

const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

const getHeaderLists = (startDate, finishDate) => {
  let weeks = [];
  let months = [];
  let years = [];

  let start = getMonday(startDate);

  for (var d = start; d <= finishDate; d.setDate(d.getDate() + 7)) {
    months.push(new Date(d));
    if (years[d.getFullYear()] !== undefined) {
      years[d.getFullYear()] = years[d.getFullYear()] + 1;
    } else {
      years[d.getFullYear()] = 1;
    }
  }

  return { months: months, years: years };
};

class ActivityTimeline extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.state = {
      rawTasks: [],
      tasks: [],
      data: [],
      periods: [],
      rawData: [],
      filterLists: {
        projects: [],
        resources: [],
      },
      filter: null,
      page: 0,
      perPage: 25,
      groupedData: {},
      groupedDataList: [],
      ganttStart: new Date(),
      ganttFinish: new Date(),
      Loading: true,
      mode: "hierarchy",
      isSideBarOpen: false,
      sidebarWidth: 700,
      selectedRecord: {},
      selectedRecordIndex: [],
      collapseStatus: false,
      expandArray: [],
      scroll: 0,
      boardType: "Activity",
    };
    this.dutils = new DateUtils();
  }

  setMode = (mode) => {
    this.setState({ ...this.state, mode: mode }, () => {
      this.updateUserView("mode", mode);
    });
  };

  updateUserView = (attributeName, attribute, attribute2) => {
    if (true) {
      let mode = this.state.mode;
      let expandArray = this.state.expandArray;
      let selectedRecord = {};
      if (this.state.selectedRecord?.uid) {
        selectedRecord = { uid: this.state.selectedRecord?.uid };
      }

      let isSideBarOpen = this.state.isSideBarOpen;
      let scroll = this.state.scroll;
      let sidebarWidth = this.state.sidebarWidth;
      let collapseStatus = this.state.collapseStatus;
      let selectedRecordIndex = this.state.selectedRecordIndex;

      switch (attributeName) {
        case "mode":
          mode = attribute;
          break;
        case "expandArray":
          expandArray = attribute;
          break;
        case "collapseStatus":
          collapseStatus = attribute;
          break;
        case "selectedRecord":
          selectedRecord = attribute;
          selectedRecordIndex = attribute2;
          break;
        case "isSideBarOpen":
          isSideBarOpen = attribute;
          break;

        case "scroll":
          scroll = attribute;
          break;
        case "sidebarWidth":
          sidebarWidth = attribute;
          break;
        default:

        // code block
      }

      this.utils.updateUserView("timeline", {
        mode: mode,
        expandArray: expandArray,
        selectedRecord: selectedRecord,
        isSideBarOpen: isSideBarOpen,
        collapseStatus: collapseStatus,
        scroll: scroll,
        sidebarWidth: sidebarWidth,
        selectedRecordIndex: selectedRecordIndex,
      });
    }
  };

  exportToCSV = () => {
    // Prepare Data Array
    let rows = [];

    // Push Column Headers
    let row = [
      "Resource",
      "Project",
      "WBS",
      "Activity",
      "Task",
      "Start",
      "Finish",
      "Planned Units",
      "Actual Units",
      "Remaining Units",
      "Task % Complete",
      "Assignment % Complete",
    ];
    rows.push(row);

    // Push Values if data exists
    if (this.state.rawData) {
      this.state.rawData.object?.map((d) => {
        let row = [
          '"' + d.first_name + " " + d.last_name + '"', // Resource
          '"' + d.project_name + '"',
          '"' + d.wbs_name + '"',
          '"' + d.activity_name + '"',
          '"' + d.name + '"',
          '"' + this.dutils.Date2Str(d.start_date) + '"',
          '"' + this.dutils.Date2Str(d.finish_date) + '"',
          '"' + d.planned_unit + '"',
          '"' + d.actual_unit + '"',
          '"' + d.remaining_unit + '"',
          '"' + Math.round(d.percent_complete * 100) + '"',
          '"' + Math.round(d.assignee_complete * 100) + '"',
        ];
        rows.push(row);
      });
    }

    // Prepare Content

    var universalBOM = "\uFEFF";
    let csvContent =
      "data:text/csv;charset=utf-8," +
      universalBOM +
      rows.map((e) => e.join(";")).join("\n");

    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);

    // File Name
    var date = new Date();
    let dateSuffix =
      date.getFullYear().toString() +
      pad2(date.getMonth() + 1) +
      pad2(date.getDate()) +
      pad2(date.getHours()) +
      pad2(date.getMinutes()) +
      pad2(date.getSeconds());

    let fileName = "timeline" + "_" + dateSuffix;
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    document.body.removeChild(link);
  };

  toggleSidebar = () => {
    this.setState(
      { ...this.state, isSideBarOpen: !this.state.isSideBarOpen },
      () => {
        this.updateUserView("isSideBarOpen", this.state.isSideBarOpen);
      }
    );
  };

  openSidebar = () => {
    //console.log("openSidebar",type.target)
    this.setState({ ...this.state, isSideBarOpen: true });
  };

  setSidebarWidth = (width) => {
    //console.log("isSideBarOpen",width)
    this.setState({ ...this.state, sidebarWidth: width }, () => {
      this.updateUserView("sidebarWidth", width);
    });
  };

  selectedRecord = (record, resource, index, rowNum) => {
    //console.log(record,resource,index,rowNum)
    let createdBy = {
      uid: record.created_by,
      fullName: record.created_by_name,
    };
    let timelineIndex = { resource: resource, index: index };
    Object.assign(record, {
      wbsId: record.wbs_id,
      activityId: record.activity_id,
      projectId: record.project_id,
      createdDate: record.created_date,
      createdBy: createdBy,
      timelineIndex: timelineIndex,
    });

    //console.log("selectedRecord",record,resource,index)
    this.setState(
      {
        ...this.state,
        selectedRecord: record,
        selectedRecordIndex: [resource, index],
      },

      () => {
        this.updateUserView("selectedRecord", { uid: record.uid }, [
          resource,
          index,
        ]);
        this.findTaskPosition(record.left - 50);
      }
    );
  };

  componentDidMount() {
    // console.log("componentDidMount");
    this.getData(
      this.props.selectedOBS,
      this.props.startDate,
      this.props.finishDate,
      (x) => {
        this.groupData(x);
      }
    );
  }

  componentDidUpdate(prevProps) {
    //console.log("componentDidUpdate",this.props.selectedOBS);
    if (
      prevProps.selectedOBS.uid !== this.props.selectedOBS.uid ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.finishDate !== this.props.finishDate
    ) {
      this.getData(
        this.props.selectedOBS,
        this.props.startDate,
        this.props.finishDate,
        (x) => {
          this.groupData(x);
        }
      );
    }
  }

  filterRawData = (filters, body) => {
    return body;
  };

  /*
        Lazım olur diye biıraktım, ne işe yaradığı anlaşılamadığında yorumlu bölge silinebilir.
        dateDiff = (startingDate, endingDate) => {
    
            var startDate = startingDate;
            var endDate = endingDate;
        
            if (startDate > endDate) {
                var swap = startDate;
                startDate = endDate;
                endDate = swap;
            }
            var startYear = startDate.getFullYear();
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
            var yearDiff = endDate.getFullYear() - startYear;
            var monthDiff = endDate.getMonth() - startDate.getMonth();
            if (monthDiff < 0) {
                yearDiff--;
                monthDiff += 12;
            }
            var dayDiff = endDate.getDate() - startDate.getDate();
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--;
                } else {
                    yearDiff--;
                    monthDiff = 11;
                }
                dayDiff += daysInMonth[startDate.getMonth()];
            }
            
            return yearDiff*12.0+ + monthDiff*1.0 + dayDiff/daysInMonth[endDate.getMonth()];
        }
    */

  dateDiff = (startingDate, endingDate) => {
    const diffTime = Math.abs(endingDate - startingDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays / 7;
  };

  getWidth = (
    startDate,
    finishDate,
    roadmapLeft = null,
    roadmapRight = null
  ) => {
    if (startDate === null || finishDate === null) {
      return 0;
    }
    if (roadmapLeft !== null && roadmapLeft > startDate) {
      startDate = roadmapLeft;
    }
    return finishDate > startDate
      ? Math.round(this.dateDiff(startDate, finishDate) * 120)
      : 0;
  };

  getData = async (
    obs,
    startDate,
    finishDate,
    callback = () => {
      void 0;
    }
  ) => {
    //console.log("timeline get data",obs,startDate, finishDate)
    //console.log("startDate",startDate,obs!==undefined )

    let viewObject = await this.utils.getUserView("timeline");

    let view = viewObject?.general;

    //  console.log("viewObject",view)
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    let serchString = "";
    if (
      obs !== undefined ||
      (startDate !== undefined && startDate !== "") ||
      (finishDate !== undefined && finishDate !== "")
    ) {
      serchString += "?";
    }

    if (obs !== undefined) {
      serchString += "obsId=" + obs.uid.toString();
      // serchString='?obsId='+obs.uid;
    }
    /**/
    if (startDate !== undefined && startDate !== "") {
      serchString += "&startDate=" + startDate;
      // serchString='?obsId='+obs.uid;
    } else if (startDate === undefined || startDate === "") {
      serchString += "&startDate=";
    }
    if (finishDate !== undefined && finishDate !== "") {
      serchString += "&finishDate=" + finishDate;
      // serchString='?obsId='+obs.uid;
    } else if (finishDate === undefined || finishDate === "") {
      serchString += "&finishDate=";
    }

    //console.log("serchString",serchString)
    // query/code/completedOnBudgetPie
    fetch(
      "/api/query/code/hub_timeline_activities" + serchString,
      requestOptions
    )
      //fetch('/api/timeline'+serchString, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status === 200) {
          //console.log("r",r,"/api/query/code/hub_timeline_tasks"+serchString)

          //console.log("timeline",r.body.object)

          let filteredData = this.filterRawData({}, r.body.object);
          let dutils = new DateUtils();

          let groupedData = {};
          let groupedDataList = [];
          let ganttStart = new Date();
          let ganttFinish = new Date();

          // Data tarihe göre sıralanıyor.
          if (filteredData !== null) {
            filteredData.sort((a, b) => {
              return new Date(a.start_date) - new Date(b.start_date);
            });

            filteredData.map((d) => {
              // İlk döngü kayıtları kaynaklara göre grupluyor.
              // Aynı zamanda ilk başlayan ve son biten kayıtlar üzerinden gantt start ve gantt finishi oluşturuyor.

              if (!groupedData[d.project_code]) {
                groupedData[d.project_code] = {
                  project_name: d.project_name,
                  role_name: d.role_name,
                  project_id: d.project_id,
                  is_expanded: false,
                  tasks: [],
                  rows: [],
                };
                groupedDataList.push(d.project_code);
              }

              d.assignee_complete = d.prj_percent_complete;

              groupedData[d.project_code].tasks.push(d);

              if (ganttStart > new Date(d.start_date)) {
                ganttStart = new Date(d.start_date);
              }

              if (ganttFinish < new Date(d.finish_date)) {
                ganttFinish = new Date(d.finish_date);
              }
            });
          }

          // Gantt Start ilgili haftanın ilk gününe alınıyor.

          ganttStart = getMonday(ganttStart); //Burada getMonday dateUtils den kullanılmalı. Teknik Borç

          // Gantt Start bir hafta öne çekilir, Gantt Finish 2 hafta ileri itilir.
          ganttStart.setDate(ganttStart.getDate() - 7);

          ganttFinish = getMonday(ganttFinish);
          ganttFinish.setDate(ganttFinish.getDate() + 21);

          // satırlar oluşuyor ve data satırlara dağıtılıyor.
          // Aynı zamanda width'ler ve left'ler hesaplanıyor.

          groupedDataList.map((g) => {
            let newGroupedDataItem = groupedData[g];

            // İlk row'u oluştur.

            newGroupedDataItem.rows.push({
              finishDate: new Date(0),
              tasks: [],
            });

            newGroupedDataItem.tasks.map((t) => {
              let newTask = t;

              //console.log("newTask", newTask);

              // Taskın width ve leftini hesapla
              newTask.width = this.getWidth(
                new Date(t.start_date),
                new Date(t.finish_date)
              );
              newTask.left = this.getWidth(ganttStart, new Date(t.start_date));

              // Taskı bir lane'e at

              // lane dağıtımı
              var w = 0;

              while (newGroupedDataItem.rows[w] !== undefined) {
                w++; // continue'dan dolayı en başta yapıyoruz arttırımı.
                let j = w - 1; // ama kontrollerde arttırılmamışı kullanmamız gerkeiyor.

                //Eğer lane'e uyarsa bu lane'e ekliyor.
                if (
                  newGroupedDataItem.rows[j].finishDate < new Date(t.start_date)
                ) {
                  newGroupedDataItem.rows[j].tasks.push(newTask);
                  newGroupedDataItem.rows[j].finishDate = new Date(
                    t.finish_date
                  );
                  break;
                }
                //Eğer hiç bir lane'e uymaz ve başka lane yoksa yeni lane ekliyor.
                if (newGroupedDataItem.rows[w] === undefined) {
                  newGroupedDataItem.rows.push({
                    finishDate: new Date(0),
                    tasks: [],
                  });
                }
              }
            });

            groupedData[g] = newGroupedDataItem;
          });

          let periods = dutils.getWeeks(ganttStart, ganttFinish);

          // Listeyi alfabetik sıralıyoruz.
          groupedDataList.sort((a, b) => {
            return groupedData[a].project_name > groupedData[b].project_name
              ? 1
              : -1;
          });

          // Filterleri oluşturuyoruz.
          let resourceFiltreList = [];
          groupedDataList.map((fr) => {
            resourceFiltreList.push({
              value: fr,
              label: groupedData[fr].project_name,
            });
          });

          // toolbar'daki tarih metinleri için.
          let dataStartDate = new Date();
          dataStartDate.setDate(dataStartDate.getDate() - 30);
          let dataFinishDate = new Date();
          dataFinishDate.setDate(dataFinishDate.getDate() + 60);

          // bugün çizgisi için soldan ne kadar uzak?
          let todayLeft = this.getWidth(ganttStart, new Date());

          //console.log("todayLeft",todayLeft)

          this.setState(
            {
              ...this.state,
              Loading: false,
              data: filteredData,
              rawData: r.body,
              ganttStart: ganttStart,
              ganttFinish: ganttFinish,
              dataStartDate: dataStartDate,
              dataFinishDate: dataFinishDate,
              todayLeft: todayLeft,
              groupedData: groupedData,
              groupedDataList: groupedDataList,
              periods: periods,
              filterLists: { resources: resourceFiltreList },
            },
            () => {
              this.initializeExpandStatus(view);
            }
          );
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  //Veri geldikten sonraki user viewları düzenler
  initializeExpandStatus = (view) => {
    // console.log("initializeExpandStatus",view,this.state)

    let selectedRecord = this.state.selectedRecord;

    let selectedRecordIndex = this.state.selectedRecordIndex;
    let scroll = this.state.scroll;

    let newData = this.state.groupedData;
    let list = this.state.groupedDataList;
    let expandArray = [];

    /*
        if(view?.expandArray)
        {
            expandArray=view?.expandArray
        }

        if(view?.collapseStatus!==undefined)
        {
            let isExpand=view.collapseStatus
            list.map((lst)=>
            {

                
               // console.log(lst,expandArray.findIndex(x=>x.code===lst))
                if(expandArray.findIndex(x=>x.code===lst)!==-1)
                {
                    isExpand=!view.collapseStatus
                }
                else
                {
                    isExpand=view.collapseStatus
                }
                //console.log(lst)
                newData[lst].is_expanded=isExpand;
            })
        }


        if(view?.selectedRecord)
        {
            //console.log("selectedRecord",this.state)
            if(this.state.data.find(x=>x.uid===view?.selectedRecord?.uid))
            {
                selectedRecord=this.state.data.find(x=>x.uid===view?.selectedRecord?.uid)
                selectedRecordIndex=view?.selectedRecordIndex

                let createdBy={uid:selectedRecord.created_by, fullName:selectedRecord.created_by_name}
                //let timelineIndex={resource:resource, index:index}
                Object.assign(selectedRecord,{
                    wbsId:selectedRecord.wbs_id,
                    activityId:selectedRecord.activity_id,
                    projectId:selectedRecord.project_id, 
                    createdDate:selectedRecord.created_date,
                    createdBy:createdBy, 
                    /*timelineIndex:timelineIndex*})
            }
            
        }
       
       

        
        if (view?.scroll&&view?.scroll>0 )
        {
            scroll=view?.scroll
            
        }
        */

    /* eğer Gantt linki tıklandıysa o kaydın açılma özelliği */

    this.setState(
      {
        ...this.state,
        //data: newData,
        //rawData: newRawData,
        // expandMode:projectView?.expandMode?projectView?.expandMode:this.state.expandMode,
        //expandArray:projectView?.expandArray?projectView?.expandArray:this.state.expandArray,
        selectedRecord: selectedRecord ? selectedRecord : {},
        selectedRecordIndex: selectedRecordIndex,
        isSideBarOpen: view?.isSideBarOpen
          ? view?.isSideBarOpen
          : this.state.isSideBarOpen,
        mode: view?.mode ? view?.mode : this.state.mode,
        groupedData: newData,
        collapseStatus: view?.collapseStatus
          ? view?.collapseStatus
          : this.state.collapseStatus,
        sidebarWidth: view?.sidebarWidth
          ? view?.sidebarWidth
          : this.state.sidebarWidth,
        expandArray: expandArray,
        scroll: scroll,
      },
      () => {
        this.findToday();
        this.findScrollPositionVertical(scroll);
      }
    );
  };

  //onScroll={this.ganttContainerScrollHandler}

  leftPaneScrollHandler = (e) => {
    //console.log(e.target.scrollTop,e.target.scrollLeft)

    document.getElementById("hub-thub-tline-right-body").scrollTop =
      e.target.scrollTop;
    document.getElementById("hub-thub-tline-left-header").scrollLeft =
      e.target.scrollLeft;

    if (Math.abs(e.target.scrollTop - this.state.scroll) > 100) {
      this.setState({ ...this.state, scroll: e.target.scrollTop }, () => {
        this.updateUserView("scroll", e.target.scrollTop);
      });
    }
  };

  RightPaneScrollHandler = (e) => {
    //console.log("leftPaneScrollHandler",e.target.scrollTop)

    document.getElementById("hub-thub-tline-left").scrollTop =
      e.target.scrollTop;
    document.getElementById("hub-thub-tline-right-header").scrollLeft =
      e.target.scrollLeft;
  };

  findToday = () => {
    //document.getElementById("hub-thub-tline-right-header").scrollLeft = this.state.todayLeft-100;
    document.getElementById("hub-thub-tline-right-body").scrollLeft =
      this.state.todayLeft - 100;
  };

  findScrollPositionVertical = (scroll) => {
    document.getElementById("hub-thub-tline-left").scrollTop = scroll;
    document.getElementById("hub-thub-tline-right-body").scrollTop = scroll;
  };

  findTaskPosition = (leftIndex) => {
    document.getElementById("hub-thub-tline-right-header").scrollLeft =
      leftIndex;
    document.getElementById("hub-thub-tline-right-body").scrollLeft = leftIndex;
  };

  setPage = (page) => {
    this.setState({ ...this.state, page: page });
  };

  setFilter = (v) => {
    this.setState({ ...this.state, filter: v });
  };

  collapseExpandAll = () => {
    let newData = this.state.groupedData;
    let list = this.state.groupedDataList;

    list.map((lst) => {
      newData[lst].is_expanded = !this.state.collapseStatus;
    });
    this.setState(
      {
        ...this.state,
        groupedData: newData,
        collapseStatus: !this.state.collapseStatus,
        expandArray: [],
      },
      () => {
        this.updateUserView("collapseStatus", this.state.collapseStatus);
      }
    );
  };

  handleExpand = (x) => {
    let newGroupedData = { ...this.state.groupedData };

    //console.log("x", x, newGroupedData[x], this.state.collapseStatus);
    newGroupedData[x].is_expanded = !newGroupedData[x].is_expanded;

    let expandArray = this.configureExpandArray(x);

    //console.log("expandArray",expandArray)

    this.setState(
      { ...this.state, groupedData: newGroupedData, expandArray: expandArray },
      () => {
        this.updateUserView("expandArray", expandArray);
      }
    );
  };

  configureExpandArray = (code, data) => {
    let newData = this.state.groupedData[code];
    // * ExpandArray açılacak veya kapanacak kayıtları tutar.
    // Collapse mod ise sadece açıkları tutar
    // Expand mode ise sadece kapalıları tutar.
    // ilk 2 if collapse mode için, son iki if expande mode için

    let expandArray = this.state.expandArray;

    //console.log("newData", newData,this.state.collapseStatus , expandArray)

    if (this.state.collapseStatus && !newData.is_expanded) {
      expandArray.push({ code: code, project_id: newData.project_id });
    } else if (
      this.state.collapseStatus &&
      newData.is_expanded &&
      expandArray.findIndex(
        (x) => x.code === code && x.project_id === newData.project_id
      ) !== -1
    ) {
      //console.log("else if")
      expandArray.splice(
        expandArray.findIndex(
          (x) => x.code === code && x.project_id === newData.project_id
        ),
        1
      );
    }

    // * Expand mode için tutulan kayıtlar
    else if (!this.state.collapseStatus && newData.is_expanded) {
      expandArray.push({ code: code, project_id: newData.project_id });
    } else if (
      !this.state.collapseStatus &&
      !newData.is_expanded &&
      expandArray.findIndex(
        (x) => x.code === code && x.project_id === newData.project_id
      ) !== -1
    ) {
      //console.log("else if")
      expandArray.splice(
        expandArray.findIndex(
          (x) => x.code === code && x.project_id === newData.project_id
        ),
        1
      );
    }

    //  this.updateUserView("expandMode",this.state.expandMode,expandArray)

    //console.log("configureExpandArray",expandArray)

    return expandArray;
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };
  //sidebar update
  updateRecord = (method, endpoint, data, selectedRecord) => {
    //console.log("updateRecord",data,selectedRecord)
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(data),
    };

    fetch(endpoint, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(JSON.stringify(data));
        // console.log(r);
        if (r.status === 200) {
          //  console.log(r);
          // this.removeItem();

          //this.setState({...this.state, Status: "Update" ,ErrorMessage:"Updated Succesfully"});

          let selectedRecord2 = selectedRecord;

          selectedRecord2.name = data.name;
          selectedRecord2.start_date = data.startDate;
          selectedRecord2.finish_date = data.finishDate;
          let newGroupedData = this.state.groupedData;

          //let task=r.body.object;
          //console.log(data.columnIndex,data.cardIndex);
          newGroupedData[selectedRecord.timelineIndex.resource].tasks[
            selectedRecord.timelineIndex.index
          ] = selectedRecord;

          this.setState({ ...this.state, groupedData: newGroupedData });
          /* */
          this.notify("success", "Updated");
        } else if (r.status === 201) {
          let newColumns = this.state.columns;

          let task = r.body.object;

          Object.assign(task, { toDo: [] });

          let taskResource = {
            taskId: task.uid,
            resourceList: null,
            assignmentList: [],
          };

          newColumns[0].task.unshift(r.body.object);
          newColumns[0].taskResource.push(taskResource);
          //console.log("taskId",task.uid)
          //  console.log("newcolumns",newColumns)

          this.setState({ ...this.state, columns: newColumns });

          this.notify("success", "Created");
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });

          this.notify("error", r.body.message);
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    //console.log("timeline",this.state)

    let innerWidth = this.state.periods.length * 120;
    let pagedData = this.state.groupedDataList
      ? this.state.groupedDataList.slice(
          this.state.page * this.state.perPage,
          (this.state.page + 1) * this.state.perPage
        )
      : "";
    let dutils = new DateUtils();

    if (this.state.filter?.value) {
      pagedData = this.state.groupedDataList.filter(
        (w) => w === this.state.filter.value
      );
    }

    let rowCount = 0;
    if (this.state.mode === "hierarchy") {
      pagedData.map((x) => {
        rowCount =
          rowCount +
          (this.state.groupedData[x].is_expanded
            ? this.state.groupedData[x].tasks.length + 1
            : 1);
      });
      // console.log("rowCount",rowCount)
    } else {
      pagedData.map((x) => {
        rowCount = rowCount + this.state.groupedData[x].rows.length;
      });
    }

    //console.log("pagedData",pagedData)

    return !this.state.Loading ? (
      <div className="hub-thub-tline">
        <div className="hub-thub-tline-toolbar">
          <div className="left-panel">
            <div style={{ width: 300, paddingLeft: 8 }}>
              <Select
                value={this.state.filter}
                onChange={this.setFilter}
                placeholder={"Search Project"}
                defaultOptions={this.state.filterLists.resources}
                options={this.state.filterLists.resources}
                menuPlacement="bottom"
                maxMenuHeight={160}
                isClearable
                styles={{
                  container: (base) => ({
                    ...base,
                    flex: "1",
                  }),
                  multiValue: (base) => ({
                    ...base,
                    backgroundColor: "none",
                    fontFamily: "Open Sans",
                    border: "1px dashed #0E73F6",
                  }),
                }}
              />
            </div>

            <div className="left-panel-buttons">
              {this.state.mode === "hierarchy" ? (
                <MainButton
                  className="hub-button button-3"
                  label={this.state.collapseStatus ? "Collapse" : "Expand"}
                  onClick={this.collapseExpandAll}
                />
              ) : (
                false
              )}
              <MainButton
                className="hub-button button-3"
                label={"Scroll to Today"}
                onClick={this.findToday}
              />
            </div>
          </div>
          <div className="right-panel">
            {/*<div className="date-info"><b>Data Range:</b> {dutils.Date2StrNew(this.state.dataStartDate)} - {dutils.Date2StrNew(this.state.dataFinishDate)}</div>*/}
            <MainButton
              className="icon-button-2"
              style={{ marginRight: "15px" }}
              iconLineColor="#1d5bbf"
              iconSize={18}
              icon={<HiDownload />}
              onClick={this.exportToCSV}
              tooltip={"Export to CSV"}
            />
            <MainButton
              className={this.state.mode === "timeline" ? "button" : "button-2"}
              style={{
                margin: 0,
                borderTopRightRadius: 0,
                height: "40px",
                padding: "4px 8px",
                borderBottomRightRadius: 0,
              }}
              onClick={() => {
                this.setMode("timeline");
              }}
              label={"Timeline"}
            />
            <MainButton
              className={
                this.state.mode === "hierarchy" ? "button" : "button-2"
              }
              style={{
                margin: 0,
                borderTopLeftRadius: 0,
                height: "40px",
                padding: "4px 8px",
                borderBottomLeftRadius: 0,
              }}
              onClick={() => {
                this.setMode("hierarchy");
              }}
              label={"Hierarchy"}
            />
            {/* <MainButton className={this.state.isSideBarOpen?"icon-button-2 active":"icon-button-2"} onClick={this.toggleSidebar} icon={faSidebarFlip}/>*/}
          </div>
        </div>
        <div className="hub-thub-tline-body-wrapper">
          <div className="hub-thub-tline-wrapper">
            <div className="hub-thub-tline-header">
              <div
                className="hub-thub-tline-left"
                id="hub-thub-tline-left-header"
                style={{ overflow: "hidden" }}
              >
                {this.state.mode === "timeline" ? (
                  <div
                    className="hub-thub-tline-left-header"
                    style={{ paddingLeft: 16 }}
                  >
                    Project
                  </div>
                ) : (
                  <div style={{ width: 865 }}>
                    <div
                      className="hub-thub-tline-left-header fl"
                      style={{ paddingLeft: 16, width: 400 }}
                    >
                      Project / Task
                    </div>
                    <div
                      className="hub-thub-tline-left-header fl"
                      style={{ paddingLeft: 16, width: 150 }}
                    >
                      Start Date
                    </div>
                    <div
                      className="hub-thub-tline-left-header fl"
                      style={{ paddingLeft: 16, width: 150 }}
                    >
                      Finish Date
                    </div>
                    <div
                      className="hub-thub-tline-left-header fl"
                      style={{ paddingLeft: 16, width: 150 }}
                    >
                      % Complete
                    </div>
                    <div className="cb"></div>
                  </div>
                )}
              </div>
              <div
                className="hub-thub-tline-right"
                id="hub-thub-tline-right-header"
                style={{ overflow: "hidden" }}
              >
                <div
                  className="hub-gantt-header"
                  id="hub-gantt-header"
                  style={{ width: innerWidth + 15, position: "relative" }}
                >
                  {this.state.periods.map((m) => {
                    return (
                      <div
                        key={"ganttMonth-" + m.str}
                        className="hub-gantt-header-month"
                        style={{ width: 120 }}
                      >
                        {m.str}
                      </div>
                    );
                  })}
                  <div
                    className="hub-thub-tline-marker-mark"
                    style={{ left: this.state.todayLeft }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="hub-thub-tline-body">
              <div
                className="hub-thub-tline-left"
                id="hub-thub-tline-left"
                onScroll={this.leftPaneScrollHandler}
              >
                <div
                  style={{
                    width: this.state.mode === "timeline" ? false : 850,
                  }}
                >
                  {pagedData.map((y, k) => {
                    let t = this.state.groupedData[y];
                    if (this.state.mode === "timeline") {
                      return (
                        <div
                          className="hub-thub-tline-left-row"
                          style={{
                            height: t.rows.length * 30,
                            lineHeight: (t.rows.length * 30).toString() + "px",
                          }}
                        >
                          {t.project_name}
                        </div>
                      );
                    } else {
                      return (
                        <>
                          <div
                            className="hub-thub-tline-left-row"
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                            onClick={() => this.handleExpand(y)}
                          >
                            {" "}
                            <FontAwesomeIcon
                              icon={t.is_expanded ? faAngleDown : faAngleRight}
                              color="#444"
                            />{" "}
                            &nbsp; {t.project_name}
                          </div>
                          {t.is_expanded
                            ? t.tasks.map((x, i) => {
                                return (
                                  <div>
                                    <div
                                      onClick={(e) =>
                                        this.selectedRecord(x, y, i, k)
                                      }
                                      onDoubleClick={() => this.toggleSidebar()}
                                      className={
                                        "hub-thub-tline-left-row fl " +
                                        (this.state.selectedRecordIndex[0] ===
                                          y &&
                                        this.state.selectedRecordIndex[1] === i
                                          ? "active"
                                          : "")
                                      }
                                      style={{
                                        paddingLeft: 32,
                                        width: 400,
                                        color:
                                          x.assignee_complete === 1
                                            ? "green"
                                            : "",
                                        fontWeight:
                                          x.assignee_complete === 1
                                            ? "bold"
                                            : "",
                                      }}
                                    >
                                      {x.name}
                                    </div>
                                    <div
                                      className="hub-thub-tline-left-row fl"
                                      style={{ paddingLeft: 16, width: 150 }}
                                    >
                                      {dutils.Date2Str(x.start_date)}
                                    </div>
                                    <div
                                      className="hub-thub-tline-left-row fl"
                                      style={{ paddingLeft: 16, width: 150 }}
                                    >
                                      {dutils.Date2Str(x.finish_date)}
                                    </div>
                                    <div
                                      className="hub-thub-tline-left-row fl"
                                      style={{ paddingLeft: 16, width: 150 }}
                                    >
                                      % {Math.round(x.percent_complete * 100)}
                                    </div>
                                    <div className="cb"></div>
                                  </div>
                                );
                              })
                            : false}
                        </>
                      );
                    }
                  })}
                </div>
              </div>
              <div
                className="hub-thub-tline-right"
                style={{ position: "relative" }}
                id="hub-thub-tline-right-body"
                onScroll={this.RightPaneScrollHandler}
              >
                <div
                  className="hub-thub-tline-marker-line"
                  style={{ left: this.state.todayLeft, height: rowCount * 30 }}
                ></div>
                {pagedData.map((t) => {
                  if (this.state.mode === "timeline") {
                    return this.state.groupedData[t].rows.map((r) => {
                      return (
                        <Row
                          mode={this.state.mode}
                          innerWidth={innerWidth}
                          items={r.tasks}
                          boardType={this.state.boardType}
                        />
                      );
                    });
                  } else {
                    return (
                      <>
                        <Row innerWidth={innerWidth} items={[]} />
                        {this.state.groupedData[t].is_expanded
                          ? this.state.groupedData[t].tasks.map((r) => {
                              let newArray = [];
                              newArray.push(r);
                              return (
                                <Row
                                  mode={this.state.mode}
                                  innerWidth={innerWidth}
                                  items={newArray}
                                  boardType={this.state.boardType}
                                />
                              );
                            })
                          : false}
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          {this.state.isSideBarOpen ? (
            <SideBar
              parentRecord={this.state.selectedRecord}
              selectedRecord={this.state.selectedRecord}
              idAttribute={"uid"}
              nameAttribute={"name"}
              toggleSidebar={this.toggleSidebar}
              isOpen={this.state.isSideBarOpen}
              setSidebarWidth={this.setSidebarWidth}
              sidebarWidth={this.state.sidebarWidth}
            >
              {/* */}
              <ActivityBoardSidebarContainer
                selectedRecord={this.state.selectedRecord}
                save={this.updateRecord}
                isNewTask={this.state.isNewTask}
              />
            </SideBar>
          ) : (
            false
          )}
        </div>
        <div className="hub-thub-tline-footer">
          {!this.state.filter?.value ? (
            <Pagination
              setPage={this.setPage}
              totalRows={
                this.state.groupedDataList
                  ? this.state.groupedDataList?.length
                  : 0
              }
              perPage={this.state.perPage}
              page={this.state.page}
            />
          ) : (
            <Pagination
              setPage={this.setPage}
              totalRows={1}
              perPage={1}
              page={0}
            />
          )}
        </div>
      </div>
    ) : (
      <LoadingScreen />
    );
  }
}

export default ActivityTimeline;

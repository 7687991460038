import React, { Component } from "react";

import { Link } from "react-router-dom";

import { DateUtils, extractProgressColor } from "./../Commons";
import TDBool from "./TDBool";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  faEllipsisStrokeVertical,
  faCirclePlus,
  faTrashXmark,
} from "@fortawesome/pro-duotone-svg-icons";
import ProgressCircle from "../progressCircle/ProgressCircle";
import TagGroup from "../../pages/taskBoard/card/tags/tagGroup";
import TDColorText from "./TDColorText";
class TD extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleExpand = (e) => {
    this.props.toggleIsExpanded(e, this.props.rowNum);
  };

  openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ ...this.state, isOpen: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, isOpen: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  handleRightClick = (e) => {
    this.props.handleRightClick(e);
  };

  render() {
    let util = new DateUtils();

    let label = "";
    if (this.props.value !== null) {
      switch (this.props.type) {
        case "text":
          label = !!this.props.hasColor ? (
            <TDColorText text={this.props.value} />
          ) : (
            this.props.value
          );
          break;
        case "date":
          label = util.Date2Str(this.props.value);
          break;
        case "number":
          label = this.props.value;
          break;
        case "staticLookup":
          label = this.props.value?.name;
          break;
        case "dynamicLookup":
          label = this.props.value?.name;
          break;
        case "lookup":
          label = this.props.value?.name;
          break;
        case "percentage":
          // label = this.props.value?(this.props.value).toFixed(0)+'%':'0%';
          const colors = extractProgressColor(this.props?.objectType);

          label = (
            <ProgressCircle
              percentColor={colors?.percentColor}
              trackColor={colors?.trackColor}
              value={this.props.value?.toFixed(0) ?? 0}
            />
          );
          break;
        case "boolean":
          label = <TDBool value={this.props.value} />;
          break;
        case "hour":
          label = this.props.value ? this.props.value.toFixed(0) + "h" : "0h";
          break;
        case "day":
          label = this.props.value ? this.props.value.toFixed(0) + "d" : "0d";
          break;

        case "tag":
          //console.log("tag",this.props.value)
          label =
            this.props.value !== undefined ? (
              <TagGroup tags={this.props.value} style={{ marginBottom: 0 }} />
            ) : (
              ""
            );
          break;
        default:
          return <td></td>;
      }
    }

    return (
      <td style={{ overflow: this.props.type === "tag" ? "unset" : "hidden" }}>
        <div
          className="tds"
          style={{
            width: this.props.width + 8,
            overflow: this.props.type === "tag" ? "unset" : "hidden",
          }}
          onContextMenu={this.handleRightClick}
        >
          {this.props.expandable ? (
            <div
              style={{
                float: "left",
                width: "16px",
                marginLeft: (this.props.hierLevel - 1) * 16,
              }}
            >
              {this.props.hasChild > 0 ? (
                <FontAwesomeIcon
                  onClick={this.handleExpand}
                  icon={this.props.isExpanded ? faAngleDown : faAngleRight}
                  color="#444"
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <span>&nbsp;</span>
              )}
            </div>
          ) : (
            false
          )}
          {this.props.type !== "tag" ? (
            <span
              style={{
                width:
                  this.props.width - (26 + (this.props.hierLevel - 1) * 16),
                overflow: "hidden",
                float: "left",
                display: "block",
                fontWeight: this.props.hasChild > 0 ? "bold" : "normal",
              }}
            >
              {this.props.linkTo === undefined ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <font
                    style={{
                      marginRight: "5px",
                      color: this.props.deleted ? "red" : "",
                    }}
                  >
                    {label}{" "}
                  </font>
                  {this.props.deleted ? (
                    <FontAwesomeIcon
                      title="This Activity Deleted From Primavera"
                      icon={faTrashXmark}
                      color="red"
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <Link to={this.props.linkTo}>
                  {this.props.hierLevel ? this.props.hierLevel : false} {label}
                </Link>
              )}
            </span>
          ) : (
            label
          )}
        </div>
      </td>
    );
  }
}

export default TD;

import React, { Component } from 'react';
import AreaChartRunner from './areaChartRunner';
import Widget from '../Widget';


class AreaChart extends Component {
    render() {
        return (
            <Widget title={this.props.config?.title}  link={this.props.config?.link}>
                <AreaChartRunner config={this.props.config} dataSet = {this.props.dataSet} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
            </Widget>
        );
    }
}

export default AreaChart;
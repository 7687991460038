import React, { Component } from 'react';

import Auth from '../../../auth/Auth';
import MainButton from '../../../common/buttons/MainButton';
import SelectArea from '../../../common/inputControls/Select/index.js';
import StatusScreen from '../../../common/statusScreen/statusScreen.js';

import ButtonArea from '../../../common/sideBarPageContent/buttonArea/buttonArea.js';
import BodyArea from '../../../common/sideBarPageContent/bodyArea/bodyArea.js';
import PageArea from '../../../common/sideBarPageContent/pageArea/pageArea.js';


import {Utils} from './../../../common/Commons';

import Tab from '../../../common/tabs/Tab';
import TabGroup from '../../../common/tabs/TabGroup';

import BooleanArea from  '../../../common/inputControls/booleanArea/booleanArea.js';
import TextField from  '../../../common/inputControls/textField';
import NumberField from  '../../../common/inputControls/numberField';

import TextArea from  '../../../common/inputControls/textarea/TextArea';
import DynamicLookup from '../../../common/inputControls/lookup/DynamicLookup';
import DateArea from '../../../common/inputControls/dateArea';
import HiSave from '../../../common/icons/HiSave.js';


class NewActivityAssignment extends Component {
/**/
constructor() {
    super();
    
    this.state = {
        
           data:[],
           Loading:true,
           Status: "Load",
           ErrorMessage:"",
           roleId:0,
           roleInfo:"",
            formData: {
              projectId: "",
              wbsId: "",
              activityId: "",
              p6Plan: {remainingUnits:0 , startDate:"", finishDate:""},
              role:""
            },

             errorMessages: {
              role:{message:"",error:false},
              remainingUnits:{message:"",error:false},
              startDate:{message:"",error:false},
              finishDate:{message:"",error:false},

            },

            requiredFields:[
              {id:"owner",label:"Resource"},
              {id:"remainingUnits",label:"remaining Units"},
              {id:"role",label:"Role"}

            ]

        
        };
  } 





  
 handleChanges = (e,val) =>{
        let items = {...this.state.formData};
      
        items[e]=val;
        if (e==="role")
        {
          this.setState({...this.state, formData:items,roleId:val.uid, roleInfo:val.name},this.chechkRequiredFields(e,val))
        }
        else
        {

          this.setState({...this.state, formData:items,})
              
        }
       
    //console.log(items);

         
    }


    
    
    handleChangesP6 = (e,val) =>{

        //console.log("handleChangesP6",e,val)
        //console.log(val)
 
        let items = {...this.state.formData};
      
        items.p6Plan[e]=val;
        this.setState({
             ...this.state, formData:items
        
        },this.chechkRequiredFields(e,val))
         
    }


    
    
    handleChangesStartDate = (e,val) =>{

    // console.log(this.props)
        //console.log(val)
    


        let errors = {...this.state.errorMessages};
        let items = {...this.state.formData}; 
        items.p6Plan[e]=val;
        const dateFormat = new Date(val).toLocaleDateString('tr-tr', { year:"numeric", month:"numeric", day:"numeric"});
        const dateYear = new Date(val).getFullYear();
        if(items.p6Plan[e]>this.state.formData?.p6Plan?.finishDate)
        {
          errors[e].message="Start Date can not be later than Finish Date";
          errors[e].error=true;      
          errors[e].warning=false;         
        }
        else if(dateFormat=="Invalid Date" || dateYear>2099 || dateYear<1900){
            errors[e].message="Invalid Date Format";
            errors[e].error=true;
            errors[e].warning=false;      
        } 
      
        
        else
        {
           errors[e].message="";
           errors[e].error=false;
           errors.finishDate.message="";
           errors.finishDate.error=false;
        }

         this.setState({ ...this.state, formData:items,  errorMessages:errors })
        
    }



      handleChangesFinishDate = (e,val) =>{
        let errors = {...this.state.errorMessages};
        let items = {...this.state.formData};
        items.p6Plan[e]=val;
        const dateFormat = new Date(val).toLocaleDateString('tr-tr', { year:"numeric", month:"numeric", day:"numeric"});
        const dateYear = new Date(val).getFullYear();
        if(items.p6Plan[e]<this.state.formData?.p6Plan?.startDate)
        {
            errors[e].message="Finish Date can not be earlier than Start Date";
            errors[e].error=true; 
            errors[e].warning=false;      
        }
        else if(dateFormat=="Invalid Date" || dateYear>2099 || dateYear<1900){
            errors[e].message="Invalid Date Format";
            errors[e].error=true;
            errors[e].warning=false;
        } 
       
        else
        {
           errors[e].message="";
           errors[e].error=false;
           
           errors.startDate.message="";
           errors.startDate.error=false;
        }
       
         this.setState({ ...this.state, formData:items,  errorMessages:errors })
        
    }


    componentDidMount()
    {
        //console.log("newAssignment")
       //console.log(this.props)
       // this.GetDatas();
       let data= {
              projectId:this.props.parentRecord.projectId,
              wbsId:this.props.parentRecord.wbsId,
              activityId: this.props.parentRecord.uid,
              role:"",
             p6Plan: {remainingUnits:0 , startDate:this.props.parentRecord.startDate, finishDate:this.props.parentRecord.finishDate},
             
            }
        

        this.setState({
             ...this.state, formData:data
        
        } )
       
    }

    componentDidUpdate(prevProps) {

            //console.log("newSidebar!!");

            // console.log(this.props);
          //
        if (prevProps.selectedRecord !== this.props.selectedRecord) {
            //console.log("componentDidUpdate!!");
          //  this.setState({data: this.props.user})
          //  console.log(this.props);

            this.setState({Loading:true, Status:"Loading"})

          //  this.GetDatas();
            
        }
           

    }

    

    
  chechkRequiredFields(fieldName,fieldValue)

  {

    //console.log("chechkRequiredFields",fieldName,fieldValue)

     let requiredFields=[...this.state.requiredFields]
      
     let errors = {...this.state.errorMessages};
       for(var i=0; i<  requiredFields.length; i++)
             {
               
               if(requiredFields[i].id===fieldName)
               {

                  if(fieldValue==="")
                  {
                     errors[fieldName].message= requiredFields[i].label+ " is required.";
                     errors[fieldName].error=true;
                  }
                  if(fieldValue!=="")
                  {
                     errors[fieldName].message= "";
                     errors[fieldName].error=false;
                  }

                 // console.log("if üzeri",fieldName,fieldValue)
                   if(fieldName==="remainingUnits" &&fieldValue>0)
                  {

                   //  console.log("if içi",fieldName,fieldValue,errors[fieldName])
                     errors[fieldName].message= "";
                     errors[fieldName].error=false;
                  }

                  //console.log(val)
                   //console.log(requiredFields[i]);
                   

                    this.setState({ ...this.state, errorMessages:errors })
               }

             
            }


  }

    
    checkErrors()
    {
        let errorArray={error:false, message:""};
            //Object.entries(this.state.errorMessages).map((item,i) => {
        //console.log((Object.entries(this.state.errorMessages)).length)

        let arrayErrors=Object.entries(this.state.errorMessages)
            for(var i=0; i<  arrayErrors.length; i++)
                {
                //console.log(arrayErrors[i][1])


                if(arrayErrors[i][1].error && !arrayErrors[i][1].warning  )
                    {
                        //console.log("ife girdik")
                        errorArray.error=arrayErrors[i][1].error;
                        errorArray.message=arrayErrors[i][1].message;
                        break;
                    }
                
                }

                return errorArray;
    }




      

    Create = () => {
           
        
        let errors = {...this.state.errorMessages};

        if(this.state.formData.p6Plan.remainingUnits<=0)
        {
            //console.log("burası mı")
            errors.remainingUnits.message="Remaining Unit can not be zero.";
            errors.remainingUnits.error=true; 
            errors.remainingUnits.warning=false;

        }
          if(this.state.formData.p6Plan.startDate==="")
        {
            errors.startDate.message="Start Date is required";
            errors.startDate.error=true; 
            errors.startDate.warning=false;

        }
          if(this.state.formData.p6Plan.finishDate==="")
        {
            errors.finishDate.message="Finish Date is required";
            errors.finishDate.error=true; 
            errors.finishDate.warning=false;

        }
          if(this.state.formData.role==="")
        {
            errors.role.message="Role is required";
            errors.role.error=true; 
            errors.role.warning=false;

        }
        
            
        if(this.checkErrors().error) {
            const u = new Utils();  u.addNotification("error", this.checkErrors().message)
        } else {
              this.props.save("post","/api/activityAssignment",this.state.formData);
        }
            //
           
    }


     
    render() {
       //console.log("newActivityAssignmen",this.props)
      // console.log("newActivityAssignmen state",this.state)
       return (
         
           this.state.Status==="Load"?
          
               <PageArea>
                  
              
               
                 <BodyArea >
                  
                    {/*<TextField width="45%" onChange= {this.handleChanges} changeArea="code" label="code" required={true}/>*/}
            
                     
                 
                   <NumberField width="45%" 
                    onChange = {this.handleChangesP6} 
                    changeArea="remainingUnits" 
                    value="0" 
                    label="Remaining Units (h)" 
                    required={true}
                    errorMessage={this.state.errorMessages["remaining"]?.message} 
                    error={this.state?.errorMessages["remaining"]?.error}/>
                 
                 
                   <DynamicLookup endpoint="/api/resourcerole"  
                    onChange={this.handleChanges} 
                    required={true}
                    formId="role" 
                    searchAttribute="name" 
                    valueAttribute="uid" 
                    labelAttribute="name"  
                    width="45%" 
                    label="Assignment Role" 
                    value={{value:this.state?.roleId, label:this.state?.roleInfo }} 
                   />

                    <DateArea width="45%" onChange = {this.handleChangesStartDate} changeArea="startDate" label="Start Date" value={this.state.formData?.p6Plan?.startDate} required={true} errorMessage={this.state.errorMessages["startDate"].message} error={this.state?.errorMessages["startDate"]?.error} warning={this.state?.errorMessages["startDate"]?.warning} />
                    <DateArea width="45%" onChange= {this.handleChangesFinishDate} changeArea="finishDate" label="Finish Date" value={this.state.formData?.p6Plan?.finishDate} required={true} errorMessage={this.state?.errorMessages["finishDate"]?.message} error={this.state?.errorMessages["finishDate"]?.error} warning={this.state?.errorMessages["finishDate"]?.warning} />   
               
                    </BodyArea>
                    <ButtonArea>
                        <MainButton onClick={this.Create} key="newTaskButton" className="button-area-action" iconLineColor = "#fff" icon={<HiSave />} label="Save" /> 
                    </ButtonArea>
               </PageArea>
              
           :
             <PageArea>
                <BodyArea>
                    <StatusScreen  loadingType="inputs" label={this.state.Status} message={this.state.ErrorMessage} onClick={this.GetDatas}/>
                </BodyArea>
            </PageArea>
        );
    }
}

export default NewActivityAssignment;
import React, { Component } from 'react';
import Auth from './../../../auth/Auth';
import {DateUtils, Utils}  from './../../../common/Commons';
import PageHeader from './../../../common/pageElements/pageHeader/'

import Toolbar from './Toolbar';

import './ResourceUtilization.css';
import ProgressView from './ProgressView';
import PanelWrapper from './PanelWrapper';
import HeaderCol from './HeaderCol';
import { Resizable } from 're-resizable';
import GroupName from './GroupName';
import Total from './Total';
import LayoutModal from './LayoutModal';

import {faTheta}  from '@fortawesome/pro-regular-svg-icons'


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


class ResourceUtilization extends Component {
    constructor(props) {
        super(props);

        this.fields = {
            planned: {code: "planned", name: "Planned", icon:faTheta},
            actual: {code: "actual", name: "Actual", icon:faTheta},
            atCompletion: {code: "atCompletion", name: "At Completion", icon:faTheta},
            remaining: {code: "remaining", name: "Remaining", icon:faTheta},
            capacity: {code: "capacity", name: "Capacity", icon:faTheta},
          }

        /*
          this.levels = {
            obs: {code: "obs", name: "Department", icon:faPeopleGroup},
            resource: {code: "resource", name: "Resource", icon: faUser},
            role: {code: "role", name: "Role", icon: faBriefcase},
            project: {code: "project", name: "Project", icon: faFolderGrid},
            wbs: {code: "wbs", name: "WBS", icon: faNetworkWired},
            activity: {code: "activity", name: "Activity", icon: faBolt}
            }


        */

        this.bgColors = Array(99).fill("#FFFFFF")
        this.bgColors[0] = [ "#E6EDF8", "#EDF2FA", "#F8FAFD"]
        this.bgColors = this.bgColors.flat()
        this.state={
            loading: true,
            layout:{
                layouts: [
                    {  
                      uid: 0,
                      code: "hub_obs_managements",
                      name: "Department Utilization",
                      levels: ["obs", "project", "wbs", "activity"],
                      primaryField: "atCompletion",
                      secondaryField: "capacity", 
                      isModified: false,
                      isSystem: true
                    },
                    {  
                      uid: 1,
                      code: "hub_obs_project",
                      name: "Department Capacity Analysis",
                      levels: ["obs", "role", "resource", "project", "wbs", "activity"],
                      primaryField: "atCompletion",
                      secondaryField: "capacity", 
                      isModified: false,
                      isSystem: true
                    },
                    {  
                      uid: 2,
                      code: "hub_resource_activity",
                      name: "Resource Utilization",
                      levels: ["role", "resource"],
                      primaryField: "atCompletion",
                      secondaryField: "capacity", 
                      isModified: false,
                      isSystem: true
                    },
                    {  
                      uid: 3,
                      code: "hub_resource_project",
                      name: "Project Effort Efficiency",
                      levels: ["project", "wbs", "activity"],
                      primaryField: "atCompletion",
                      secondaryField: "planned", 
                      isModified: false,
                      isSystem: true
                    }
                  ],
                layoutList: ["hub_obs_managements", "hub_obs_project", "hub_resource_activity", "hub_resource_project"],
                selectedLayout: "",
            
            },
            dateRange:{
                start: formatDate(new Date(new Date().getFullYear(), 0, 1)),
                finish: formatDate(new Date(new Date().getFullYear(), 11, 31)),
                period: 'monthly' //"daily-weekly-monthly-quarterly-yearly"
            },
            data: [],
            rawData: [],
            periods: [],
            isModalOpen: false,
            modalMode: "",
            leftPanelWidth: window.outerWidth*0.45,
            hover:-1,
            config: {
                columns: [
                    {id:"group", initialWidth: 337, dataKey:"group", label:"Group", type:"text"}
                    ,{id:"atCompletion", dataKey:"At Completion", label:"Project", type:"hour"}
                    ,{id:"planned", dataKey:"planned", label:"Planned", type:"hour"}
                    ,{id:"actual", dataKey:"actual", label:"Actual", type:"hour"}
                    ,{id:"remaining", dataKey:"remaining", label:"Remaining", type:"hour"}
                    ,{id:"capacity", dataKey:"capacity", label:"Capacity", type:"hour"}
                ], 
                idAttribute: "uid",
                nameAttribute: "resource.fullName",
                pageCode:"newRmUtil",
                childAttribute: "children",

                ganttWidth: this.props.ganttWidth,
                handleGanttResize: this.props.handleGanttResize,

                rowHeight: 45,
                view: {
                    visibleColumnList: ["group","atCompletion", "planned"],
                    hideSelectbox: true,
                    mode: "util"
                },
            }
        }
    }
  
      
    componentDidMount() {
        let data = this.prepareInitalData([...this.state.rawData]);
        this.setState({...this.state, data: data});
    }

    prepareInitalData = (rawData) => { 
        let newData = [];
        rawData.map((x,i) => {
            if(x.level===0){
                newData.push(x);
            }
        })
        return newData;
    } 

    toggleIsExpanded = (e, ind) => {

        // Bunu muhtemelen smart render'in için bu şekilde 
        // diziye eleman ekleyip çıkarma olarak
        // geliştirildi.
        //console.log("toggleIsExpanded", ind,this.state.data[ind].expanded)

        // * Bu kısım sayfa açılınca collapse-expand durumunu tutmak için kullanılır.
        // * en altta setState fonksiyonunda var
        
    
        e.preventDefault();
        e.stopPropagation();    
                
        let newData = [];

        const xInd = this.state.data[ind].sort;

        let removeCount = 0;
        if (this.state.data[ind].expanded) {

            for (let i=ind; i<this.state.data.length; i++){
                if(i>ind && this.state.data[ind].level >= this.state.data[i].level){
                    break;
                }
                removeCount = removeCount+1;
            }

            newData = [...this.state.data];
            newData.splice(ind+1, removeCount-1);

        } else {
            
            // Açılınca eklenecek satırlar döngüyle buraya girer.
            let appendTasks = [];
            let checkPoint = null; // checkPoint değişkeni açılırken aradaki kapalıları kapalı tutmak için.
            
            for (let i=xInd; i<this.state.rawData.length; i++){

                //console.log(i,xInd,this.state.rawData[xInd].hierLevel, this.state.rawData[i].hierLevel)
                if(i>xInd && this.state.rawData[xInd].level >= this.state.rawData[i].level){
                    break;
                }
                if(i>xInd){
                    // Eğer açılan satırın altında kapalı bir satır varsa 
                    // kapalı satırın indexi checkpointe yazılır. 
                    // eğer checkPoint null değilse döngü o sırada kapalı bir satırın alt verisinin içindedir ve continue ile loop atlatılır.
                    // eğer aynı veya daha büyük bir hierLevel'a denk gelirse, kapalı bloktan çıkılmıştır. checkPoint değişkeni null olarak set edilir.
                    if(checkPoint!==null){
                        if( this.state.rawData[checkPoint].level < this.state.rawData[i].level ){
                            continue;
                        } else {
                            checkPoint=null;
                        }
                    }

                    appendTasks.push(this.state.rawData[i]);
                    
                    if(this.state.rawData[i].expanded===false) {
                        checkPoint = i;
                    }
                }
                removeCount = removeCount+1;
            }

            newData  = [...this.state.data];

            const x = [...this.state.rawData]
            newData.splice(ind+1, 0, ...appendTasks)
        }

        newData[ind].expanded = !newData[ind].expanded

        // satırın Expand Collapse durumunun üst seviye kapanıp açıldığında da saklanması için raw data'ya isExpanded bilgisi taşınır. 
        let newRawData = [...this.state.rawData];        
        newRawData[xInd].expanded = newData[ind].expanded;
        //let newSelectedRows=[...this.state.selectedRows]

        //console.log("isExpanded", ind,this.state.data[ind].expanded)
        let deleteds=[]
    

        this.setState({...this.state, data: newData, rawData: newRawData});
        
    }
    
    handleResizeColumn = (w) => {
        let newState = {...this.state};
        newState.ganttWidth = newState.ganttWidth + w
        this.setState(newState);
    }
    
    createLayoutModal = () => {
        this.setState({...this.state, isModalOpen:true, modalMode: "create"})
    }

    setHover = (i) => {
        this.setState({...this.state, hover:i})
    }

    editLayoutModal = (layoutCode) => {
        let modalLayout = {...this.state.layout.layouts[this.state.layout.layoutList.indexOf(layoutCode)]}
        this.setState({...this.state, isModalOpen:true, modalMode:"edit", modalLayout:modalLayout});
    }

    addLayout = (st) => {
        // st modal componentin stateinin bir kopyasını alıyor. 

        // örnek st 
        /*
            this.state = {
            levelList: ['obs', 'resource', 'role', 'project', 'wbs', 'activity'],
            disabled: [],
            name: "",
            primaryField: "",
            secondaryField: "",
            showSecondaryFields: false
            } 
        */
        // hedef format
        /*
            {  
                uid: 0,
                code: "hub_obs_managements",
                name: "Department Utilization",
                levels: ["obs", "project", "wbs", "activity"],
                primaryField: "atCompletion",
                secondaryField: "capacity", 
                isModified: false,
                isSystem: true
            }
        */

        // uid ve code'u o anki açık layout sayısı üzerinden oluşturuyorum. normalde servisten dönecek. 
        
        let newLayoutItem = {
            uid: this.state.layout.layouts.length,
            code: this.state.layout.layouts.length.toString(),
            name: st.name,
            levels: st.levelList.filter((x)=>{ return st.disabled.indexOf(x)===-1}),
            primaryField: st.primaryField,
            secondaryField: st.secondaryField, 
            isModified: true,
            isSystem: false
        }

        let newLayout = {...this.state.layout}
        newLayout.layouts.push(newLayoutItem);
        newLayout.layoutList.push(newLayoutItem.code);
        newLayout.selectedLayout = newLayoutItem.code;

        this.setState({...this.state, isModalOpen:false, layout:newLayout}, () => {this.fetchData()} );

    }

    saveLayout = (st) => {


        let newLayout = {...this.state.layout}
        let newLayoutItem = newLayout.layouts[newLayout.layoutList.indexOf(st.code)];

        newLayoutItem = {
            ...newLayoutItem,
            name: st.name,
            levels: st.levelList.filter((x)=>{ return st.disabled.indexOf(x)===-1}),
            primaryField: st.primaryField,
            secondaryField: st.secondaryField, 
            isModified: true,
            isSystem: false
        }

        newLayout.layouts[newLayout.layoutList.indexOf(st.code)] = newLayoutItem ;

        this.setState({...this.state, isModalOpen:false, layout:newLayout}, () => {this.fetchData()} );
    }


    deleteLayout = (code) => {
        let newLayout = {...this.state.layout}
        newLayout.layouts.splice(newLayout.layoutList.indexOf(code),1)
        newLayout.layoutList.splice(newLayout.layoutList.indexOf(code),1);
        newLayout.selectedLayout = newLayout.selectedLayout===code?"":newLayout.selectedLayout;
        this.setState({...this.state, isModalOpen:false, layout:newLayout}, () => {console.log(this.state.layout)});
    }

    closeModal = () => {
        this.setState({...this.state, isModalOpen:false})
    }

    fetchData = () => {

        /*
        {
            "viewType": "daily-weekly-monthly-quarterly-yearly",
            "start": "dd-MM-yyyy",
            "finish": "dd-MM-yyyy",
            "groupFields": ["obs", "role", "resource", "project", "wbs", "activity"],
            "totalFields": ["actual", "capacity", "remaining", "planned", "atCompletion"]
        }
        */

        let selectedLayout = {}

        if(this.state.layout.selectedLayout && this.state.layout.selectedLayout!==""){
            selectedLayout = this.state.layout.layouts[this.state.layout.layoutList.indexOf(this.state.layout.selectedLayout)]
        } else {
            // Select Layout Gelmeli..
            return;
        }

        let totalFieldsArray=[];
        if(selectedLayout.primaryField && selectedLayout.primaryField !== "") { totalFieldsArray.push(selectedLayout.primaryField)} 
        if(selectedLayout.secondaryField && selectedLayout.secondaryField !== "") { totalFieldsArray.push(selectedLayout.secondaryField   )} 


        let params = {
            viewType: this.state.dateRange.period,
            start: this.formatDateStr(this.state.dateRange.start),
            finish: this.formatDateStr(this.state.dateRange.finish),
            groupFields: selectedLayout.levels,
            totalFields: totalFieldsArray,
            dataType: "hour"
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
            body: JSON.stringify(params)
        };

        fetch('/api/utilization', requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
               
                const u = new Utils();  
               
                if(r.status===200) {
                    let data = this.prepareInitalData([...r.body.object.rawData]);
                    this.setState({...this.state, rawData:r.body.object.rawData, data:data, periods:r.body.object.periods, isModalOpen:false})
                }
                else if(r.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    u.addNotification("error", "You are not authorized to take this action. Please contact your system administrator..");
                    this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else if(r.status==400) {
                    u.addNotification("error", r.body.message);
                    this.setState({...this.state,Status: "Error", LoadingError: true, ErrorMessage:r.body.message});
                }
                else {
                    //console.log(r.body.message);
                    u.addNotification("error", r.body.message);
                    this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    }

    // Gecici fonksiyon - tarih formatı standartta olmadığı için kullanılacak sonra silinecek
    formatDateStr = (dateStr) => {
        // 2022-06-01
        return dateStr.substring(8, 10) + "-" + dateStr.substring(5, 7) + "-" + dateStr.substring(0, 4) 
    }

    setDate = (start, finish, period) => {
        this.setState({...this.state, dateRange:{start:start, finish:finish, period:period}}, () => {
            this.fetchData();
        })
    }


    selectLayout = (layout) => {
        /*
        {s
            "viewType": "daily-weekly-monthly-quarterly-yearly",
            "start": "dd-MM-yyyy",
            "finish": "dd-MM-yyyy",
            "groupFields": ["obs", "role", "resource", "project", "wbs", "activity"],
            "totalFields": ["actual", "capacity", "remaining", "planned", "atCompletion"]
        }
        */ 

        this.setState({...this.state, layout:{...this.state.layout, selectedLayout: layout}}, () => {
            this.fetchData();
        });

    }


    /* Scroll Bar */
    dataContainerScrollHandler = (e) => {
        //document.getElementById("hub-gantt-table-header").scrollLeft = e.target.scrollLeft;
        document.getElementById("hub-rmUtil-metricContainer").scrollTop = e.target.scrollTop;
        //document.getElementById("hub-gantt-header").style.top =  e.target.scrollTop.toString() + "px";
    }  


    metricContainerScrollHandler = (e) => {
        document.getElementById("hub-rmUtil-dataContainer").scrollTop = e.target.scrollTop;
        document.getElementById("hub-rmUtil-metricHeader").scrollLeft = e.target.scrollLeft;
    }  



    render() {

        let selectedLayout = {}

        if(this.state.layout.selectedLayout && this.state.layout.selectedLayout!==""){
            selectedLayout = this.state.layout.layouts[this.state.layout.layoutList.indexOf(this.state.layout.selectedLayout)]
        } 
        return (
            <>
            <PageHeader
              title={"Resource Utilization"}
            /> 
            <div className="hub-page" >
                <Toolbar dateRange = {this.state.dateRange} setDate={this.setDate} selectLayout={this.selectLayout} layout={this.state.layout} newLayoutModal={this.createLayoutModal} editLayoutModal={this.editLayoutModal}/>
                <div className="hub-rmUtil-panel"> 
                    <div className="hub-rmUtil-panel-left"> 
                    <Resizable 
                            enable={ {top:false, right:true, bottom:false, left:false, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
                            minWidth={760}
                            defaultSize={ {width:760} }
                            size={{ width: this.state.ganttWidth, height: "100%"}}
                            onResizeStop={(e, direction, ref, d) => {
                                this.handleResizeColumn(d.width); 
                            }}
                            style={{overflow:"auto", display:"flex", flexDirection:"column", minHeight:"100%", borderRight:"4px solid #E2E8F1"}}
                            handleClasses={{left:"hub-gantt-resize-handler"}}>
                        <PanelWrapper style={{display:"flex", flexDirection:"row",height: 60, width:this.state.ganttWidth+15, borderBottom: "1px solid #E2E8F1"}}>
                            <HeaderCol label="Groups" style={{minWidth:420, flex:"1"}} />
                            {selectedLayout?.primaryField?<HeaderCol label={this.fields[selectedLayout?.primaryField].name} width={120} />:false}
                            {selectedLayout?.secondaryField?<HeaderCol label={this.fields[selectedLayout?.secondaryField].name} width={120} />:false}
                        </PanelWrapper>
                        <PanelWrapper id="hub-rmUtil-dataContainer" onScroll={this.dataContainerScrollHandler} style={{width:this.state.ganttWidth+15, flexDirection:"column", flex:1, overflow:"auto"}}>
                            {this.state.data.map((d,i) => {
                                return (<div onMouseEnter={()=>{this.setHover(i)}}  key={"dataRow"+i.toString()} className={"hub-rmUtil-panel-row" + (this.state.hover===i?" hover":"")}style={{ width:this.state.ganttWidth, backgroundColor:this.bgColors[d.level]}}>
                                     <div className="hub-rmUtil-panel-cell" style={{minWidth:420, flex:"1"}}> <GroupName hasChild={d.hasChild} level={d.level} type={d.type} isExpanded={d.expanded } ind={i} handleExpand={this.toggleIsExpanded} label={d.name} /> </div>
                                     {selectedLayout?.primaryField?<div className="hub-rmUtil-panel-cell" style={{width:120}}> <Total value={d[selectedLayout?.primaryField+"Sum"]} unit="hr" /></div>:false}
                                     {selectedLayout?.secondaryField?<div className="hub-rmUtil-panel-cell" style={{width:120}}> <Total value={d[selectedLayout?.secondaryField+"Sum"]} unit="hr" /> </div>:false}
                                </div>)
                            })}
                        </PanelWrapper>
                        </Resizable>
                    </div>
                    
                    <div className="hub-rmUtil-panel-right">
                        <PanelWrapper id="hub-rmUtil-metricHeader" style={{height: 60, overflow:"hidden",borderBottom: "1px solid #E2E8F1"}}>
                            <PanelWrapper style={{flex:0, height: 60, width: this.state.periods.length*120, borderBottom: "1px solid #E2E8F1"}}>
                            {this.state.periods.map((x,i) => { 
                                return <HeaderCol key={"metricHeader"+i.toString()} label={x.label} width={120} style={{textAlign: "center", padding: 0, borderRight: "1px solid #E2E8F1" }} />
                            })}
                            </PanelWrapper>
                        </PanelWrapper>
                        <PanelWrapper onScroll={this.metricContainerScrollHandler} id="hub-rmUtil-metricContainer" style={{ flexDirection:"column", flex:1, overflow:"auto"}}>
                            <PanelWrapper style={{ flexDirection:"column", flex:1, width: this.state.periods.length*120}}>
                            {this.state.data.map((d, j)=>{
                                return (
                                <div key={"metricRow"+j.toString()}  onMouseEnter={()=>{this.setHover(j)}}  className={"hub-rmUtil-panel-row" + (this.state.hover===j?" hover":"")}>
                                    {this.state.periods.map((x, i) => { 
                                        let colorCode = "green";
                                        // yeşilin aralığı 1.05 - 0.95 olmuş durumda
                                        if(selectedLayout.secondaryField!==""){
                                            if(d[selectedLayout.primaryField][i] > d[selectedLayout.secondaryField][i]*1.05) {
                                                colorCode = "red"
                                            }
                                            if(d[selectedLayout.primaryField][i] < d[selectedLayout.secondaryField][i]*0.95) {
                                                colorCode = "yellow"
                                            }
                                        }
                                        return <div className="hub-rmUtil-panel-cell data"> 
                                            <ProgressView key={"pw"+j.toString()+"x"+i.toString()} primaryValue={d[selectedLayout.primaryField][i]} secondaryValue={(selectedLayout.secondaryField!==""?d[selectedLayout.secondaryField][i]:null)} color={colorCode} /> 
                                        </div>
                                    })}
                                </div>)
                            })}
                            </PanelWrapper>
                        </PanelWrapper>
                    </div>
                </div>
                <LayoutModal mode={this.state.modalMode} modalLayout={this.state.modalLayout} addLayout={this.addLayout} saveLayout={this.saveLayout} deleteLayout={this.deleteLayout} key={"newRMUtil-modal"} title={"New Layout"} show={this.state.isModalOpen} onClose = {this.closeModal} /> 
            </div>
            </>
        );
    }
}

export default ResourceUtilization;
import React, { Component } from "react";
import DataTable from "../../common/dataTable";
import PageHeader from "../../common/pageElements/pageHeader/";

class ProgramList extends Component {
  render() {
    const dtConfig = {
      endPoint: "/api/program",
      columns: [
        { id: "programId", dataKey: "code", label: "Program ID", type: "text" },
        {
          id: "programName",
          dataKey: "name",
          label: "Program Name",
          type: "text",
          linkTo: "/program/{uid}/roadmap",
        },
        {
          id: "startDate",
          dataKey: "startDate",
          label: "Start Date",
          type: "date",
        },
        {
          id: "finishDate",
          dataKey: "finishDate",
          label: "Finish Date",
          type: "date",
        },
      ],
      view: {
        visibleColumnList: [
          "programId",
          "programName",
          "startDate",
          "finishDate",
        ],
        mode: "list",
      },
      // tile: <ProjectTile />
    };

    return (
      <>
        <PageHeader title={"Programs"} />
        <div className="hub-page">
          <DataTable config={dtConfig} />
        </div>
      </>
    );
  }
}

export default ProgramList;

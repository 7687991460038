import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

import Tab from '../../../common/tabs/Tab';
import TabGroup from '../../../common/tabs/TabGroup';


import TabContent from '../../../common/tabs/TabContent';


import SideBarTaskDetails from './../../task/SideBarTaskDetails'
import TaskAssignments from './../../task/TaskAssignments'

import TaskSideNotes from './../../task/TaskSideNotes'

import SideBarTaskStatus from './../../task/SideBarTaskStatus'



import SideBarTaskRecurrence from './../../task/SideBarTaskRecurrence';

class TaskSidebar extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            activePage: "task"
        }
    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }


    render() {
      let selectedRecord=this.props.selectedRecord
          selectedRecord.projectId=selectedRecord.project_id
          selectedRecord.activityId=selectedRecord.activity_id
          selectedRecord.wbsId=selectedRecord.wbs_id
        return (
          <div className="hub-tabGroup-wrapper" >
            <TabGroup>
              <Tab to="task" onClick={this.setActivePage} label="Details" activePage={this.state.activePage}  />
              <Tab to="taskAssignment" onClick={this.setActivePage} label="Assignments" activePage={this.state.activePage} />
              <Tab to="taskStatus" onClick={this.setActivePage} label="Status" activePage={this.state.activePage} />
              {/*<Tab to="taskRecurrence" onClick={this.setActivePage} label="Recurrence" activePage={this.state.activePage} /> */}
              <Tab to="taskNotes" onClick={this.setActivePage} label="Conversation" activePage={this.state.activePage} />
            </TabGroup> 
                <TabContent to="task" activePage={this.state.activePage}>
                    <SideBarTaskDetails selectedRecord={selectedRecord} parentRecord={this.props} save={this.props.save}/>
                </TabContent>
                <TabContent to="taskAssignment" activePage={this.state.activePage}>
                    <TaskAssignments selectedRecord={selectedRecord} title={this.props.title}/>
                </TabContent>
                  <TabContent to="taskStatus" activePage={this.state.activePage}>
                    <SideBarTaskStatus selectedRecord={selectedRecord} title={this.props.title}/>
                </TabContent>
                <TabContent to="taskRecurrence" activePage={this.state.activePage}>
                  <SideBarTaskRecurrence selectedRecord={selectedRecord} title={this.props.title} save={this.props.save}/>
                </TabContent>
                <TabContent to="taskNotes" activePage={this.state.activePage}>
                    <TaskSideNotes selectedRecord={selectedRecord} title={this.props.title} />
                </TabContent>
          </div>
        );
    }
}




export default TaskSidebar;

import React, { Component } from 'react'

export default class noteArea extends Component {
  render() {
    return (
       <div className="note-area">
                {this.props.children}
            </div>
    )
  }
}

import React from "react";
import "./progressCircle.css";

const ProgressCircle = ({ value, trackColor, percentColor }) => {
  return (
    <div className="hub-progress-circle-wrapper">
      <div
        className="hub-progress-circle"
        style={{
          background: `radial-gradient(closest-side, white 59%, transparent 80% 100%),conic-gradient(${percentColor} ${value}%, ${trackColor} ${0})`,
        }}
      ></div>
      <div className="hub-progress-circle-label">{value} %</div>
    </div>
  );
};

export default ProgressCircle;

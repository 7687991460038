import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { mergeConfigs } from "../../common/Commons";

const AreaChart = ({ data, configuration }) => {
  const [graphConfig, setGraphConfig] = useState({});

  const categories = data[0]?.data.map((item) => item.x);

  const seriesArr = data.map((item) => {
    return { name: item.id, data: item.data.map((item) => item.y) };
  });

  const defaultConfig = {
    type: "area",
    height: 350,
    series: seriesArr,
    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false, // Hide toolbar
        },
        zoom: {
          enabled: false, // Disable Zoom
        },
      },
      markers: {
        size: 5,
        colors: "#fff",
        strokeColors: ["#1769F1", "#8225FA", "#FBBF26", "#E95A63", "#5AF3E1"],
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 0,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },

      dataLabels: {
        enabled: false,
      },

      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        categories: categories,
        labels: {
          offsetY: 3,
          style: {
            colors: "#051530",
            fontFamily: "Noto Sans",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: 0,
            fontFamily: 0,
          },
        },
      },
      yaxis: {
        labels: {
          offsetY: 0.5,
          style: {
            colors: "#051530",
            fontFamily: "Noto Sans",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "30px",
          },
        },
      },
      tooltip: {
        style: {
          colors: "#051530",
          fontSize: "11px",
          fontFamily: "Noto Sans",
          fontStyle: "normal",
          fontWeight: "400",
        },
      },
      colors: ["#1769F1", "#8225FA", "#FBBF26", "#E95A63", "#5AF3E1"],
      legend: {
        offsetY: 20,
        labels: {
          colors: "#051530",
        },
        fontFamily: "Open Sans",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "15px",
        markers: {
          width: 12,
          height: 4,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: undefined,
          radius: 4,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0.5,
        },
        itemMargin: {
          horizontal: 20,
          vertical: 15,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.4,
          stops: [0, 100],
        },
      },
    },
  };

  useEffect(() => {
    mergeConfigs(defaultConfig, configuration);
    setGraphConfig(defaultConfig);
  }, []);

  return data.length > 0 && !!graphConfig.type && <Chart {...graphConfig} />;
};

export default AreaChart;

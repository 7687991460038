import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-duotone-svg-icons";

import { faStar as faStarThin } from "@fortawesome/pro-regular-svg-icons";

import "./../CardToolbarIcon.css";
import HubIcon from "../../../../common/hubIcon/HubIcon";
import HiFavorite from "../../../../common/icons/HiFavorite";

export default class CardFavorite extends Component {
  render() {
    return (
      <div
        onClick={this.props.setFavorite}
        className={
          "hub-cardToolbarIcon" +
          (this.props.isFavorite ? " favorite active" : "") +
          (this.props.hovered ? " hovered" : "")
        }
      >
        <div
          className={"icon" + (this.props.isFavorite ? " favorite active" : "")}
        >
          <HubIcon
            fontSize={20}
            lineColor={this.props.isFavorite ? "#FFCF23" : "#DAE3F2"}
            bgColor={this.props.isFavorite ? "#FFCF23" : "none"}
          >
            <HiFavorite />
          </HubIcon>
        </div>
      </div>
    );
  }
}

import React from "react";

const HiTeamHub = ({ bgColor, lineColor }) => {
  return (
    <svg
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.125 17.2917L9.35248 17.9737C9.58881 18.105 9.70698 18.1706 9.83213 18.1964C9.94288 18.2191 10.0571 18.2191 10.1679 18.1964C10.293 18.1706 10.4112 18.105 10.6475 17.9737L11.875 17.2917M4.375 15.2084L3.18581 14.5478C2.93621 14.4091 2.8114 14.3397 2.72053 14.2411C2.64013 14.1539 2.57929 14.0505 2.54207 13.9378C2.5 13.8105 2.5 13.6677 2.5 13.3822V12.0834M2.5 7.91675V6.61795C2.5 6.33242 2.5 6.18965 2.54207 6.06232C2.57929 5.94967 2.64013 5.84627 2.72053 5.75903C2.8114 5.66041 2.93621 5.59108 3.18581 5.45241L4.375 4.79175M8.125 2.70841L9.35248 2.02648C9.58881 1.89518 9.70698 1.82954 9.83213 1.8038C9.94288 1.78102 10.0571 1.78102 10.1679 1.8038C10.293 1.82953 10.4112 1.89518 10.6475 2.02648L11.875 2.70841M15.625 4.79175L16.8142 5.45241C17.0638 5.59108 17.1886 5.66041 17.2795 5.75903C17.3599 5.84627 17.4207 5.94967 17.4579 6.06232C17.5 6.18965 17.5 6.33242 17.5 6.61795V7.91675M17.5 12.0834V13.3822C17.5 13.6677 17.5 13.8105 17.4579 13.9378C17.4207 14.0505 17.3599 14.1539 17.2795 14.2411C17.1886 14.3397 17.0638 14.4091 16.8142 14.5478L15.625 15.2084M8.125 8.95841L10 10.0001M10 10.0001L11.875 8.95841M10 10.0001V12.0834M2.5 5.83341L4.375 6.87508M15.625 6.87508L17.5 5.83341M10 16.2501V18.3334"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiTeamHub;

import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

import {faCircleCheck} from '@fortawesome/pro-light-svg-icons'
import {faCheck} from '@fortawesome/free-solid-svg-icons'
import {faCircleXmark} from '@fortawesome/pro-light-svg-icons'
export default class TDBool extends Component {


    

    
  render() {
    
    return (
        
       <div><FontAwesomeIcon  icon={ this.props.value?faCheck:null} style={{color:"blue"}} size="1x" /> </div>

  
    )
  }
}

import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";

import { DateUtils } from "./../../common/Commons";

import { Utils } from "./../../common/Commons";

import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import TextArea from "../../common/inputControls/textarea/TextArea";
import PercentField from "../../common/inputControls/percentField";

import DateArea from "../../common/inputControls/dateArea/index.js";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup.js";

class SideBarActivityDetails extends Component {
  /**/
  constructor() {
    super();

    this.du = new DateUtils();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
        actuals: "5",
      },
      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
        extType: { message: "", error: false, warning: false },
      },

      requiredFields: [
        { id: "name", label: "Name" },
        { id: "extType", label: "Activity Type" },
      ],
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesParentWBS = (e, val) => {
    // console.log("handleChangesParentWBS",e,val)

    let items = { ...this.state.formData };

    items[e] = val.uid;

    let wbs = { uid: val.uid, name: val.name };

    /* */
    this.setState({
      ...this.state,
      formData: items,
      wbs: wbs,
    });
  };

  handleChangesExtType = (e, val) => {
    //console.log("handleChangesExtType",e,val)
    let items = { ...this.state.formData };

    items[e] = val.name;

    let isMilestone = val.name.includes("Milestone");
    let oldFinishDate = items.finishDate;
    let errors = { ...this.state.errorMessages };
    if (isMilestone) {
      items.finishDate = items.startDate;

      errors.finishDate.message = "Finish Date synced to Start Date";
      errors.finishDate.error = false;
      errors.finishDate.warning = true;
    } else {
      if (this.state.oldFinishDate) {
        items.finishDate = this.state.oldFinishDate;
      }
      errors.finishDate.message = "";
      errors.finishDate.error = false;
      errors.finishDate.warning = false;
    }

    this.setState(
      {
        ...this.state,
        formData: items,
        oldFinishDate: oldFinishDate,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] > this.state.formData.finishDate) {
      errors[e].message = "Start Date can not be later than Finish Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;
      errors.finishDate.message = "";
      errors.finishDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] < this.state.formData.startDate) {
      errors[e].message = "Finish Date can not be earlier than Start Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.startDate.message = "";
      errors.startDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  componentDidMount() {
    //console.log("componentDidMount")
    //  console.log(this.props)
    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r);
        if (r.status == 200) {
          this.setState(
            { ...this.state, Loading: false, formData: r.body.object[0] },
            this.getWbs()
          );

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  getWbs = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r);
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            wbs: r.body.object[0],
          });

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  update = () => {
    // console.log(this.state.formData)
    //console.log(JSON.stringify(this.state.formData))

    let body = { status: { uid: 33 } };

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(body),
    };

    fetch("/api/activity/" + this.state.formData.uid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(requestOptions);
        //console.log(r);
        if (r.status === 200) {
          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  commit = async () => {
    //await this.update();
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      //body: JSON.stringify(formData)
    };

    fetch(
      "/api/activity/commit/" + this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log(requestOptions);
        console.log(r);
        if (r.status === 200) {
          let items = { ...this.state.formData };
          items.isCommitted = true;

          //this.notify("success","Updated")
          //  console.log(r);
          // this.removeItem();

          this.setState({ ...this.state, formData: items });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });

          this.notify("error", r.body.message);
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  updateRecord = async () => {
    let errors = { ...this.state.errorMessages };
    if (
      this.state.formData.extType.includes("Milestone") &&
      this.props.selectedRecord.children?.length > 0
    ) {
      errors.extType.message = "Milestones can not have tasks.";
      errors.extType.error = true;
      errors.extType.warning = false;
    }

    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      let data = this.state.formData;
      let refresh =
        this.props.selectedRecord?.wbsId === data?.wbsId ? false : true;

      console.log("activity update", this.props.selectedRecord, data, refresh);

      await this.props.save(
        "patch",
        "/api/activity/" + this.props.selectedRecord.uid,
        data,
        this.props.selectedRecord,
        null,
        null,
        "activity",
        refresh
      );
    }
  };

  render() {
    //console.log('A',this.props)
    //console.log('state',this.state.formData)
    let source = this.props?.project?.source?.code;
    let isMilestone = this.state.formData?.extType?.includes("Milestone");
    //console.log("isMilestone",isMilestone)
    //console.log(this.state.formData.wbsResponsible?.uid,Auth.getResourceObject().uid)
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="input-grid">
            {" "}
            {source === "hub" ? (
              <DynamicLookup
                endpoint={
                  "/api/projects/" +
                  this.props.selectedRecord.projectId +
                  "/wbs"
                }
                required={true}
                onChange={this.handleChangesParentWBS}
                value={{
                  value: this.state.wbs?.uid,
                  label: this.state.wbs?.name,
                }}
                formId="wbsId"
                searchAttribute="name"
                valueAttribute="uid"
                labelAttribute="name"
                width="100%"
                label="Parent WBS"
              />
            ) : (
              false
            )}
            {source !== "hub" ? (
              <TextField
                width="100%"
                onChange={this.handleChanges}
                changeArea="code"
                label="ID"
                value={this.state.formData.code}
                disabled={true}
              />
            ) : (
              false
            )}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="name"
              label="Name"
              value={this.state.formData.name}
              disabled={source === "hub" ? false : true}
              required={true}
            />
            {source === "hub" ? (
              <DynamicLookup
                endpoint={"/api/valuelist/code/activity_type"}
                required={true}
                onChange={this.handleChangesExtType}
                extraObject={"valueListOptions"}
                value={{
                  value: this.state.formData.extType,
                  label: this.state.formData.extType,
                }}
                formId="extType"
                searchAttribute="name"
                valueAttribute="uid"
                labelAttribute="name"
                width="100%"
                label="Activity Type"
              />
            ) : (
              <TextField
                width="100%"
                onChange={this.handleChanges}
                changeArea="status"
                label="Activity Type"
                value={this.state.formData.extType}
                disabled={true}
              />
            )}
            {source === "hub" && isMilestone ? (
              <DynamicLookup
                endpoint={"/api/valuelist/code/hub_task_status"}
                required={true}
                onChange={this.handleChanges}
                extraObject={"valueListOptions"}
                value={{
                  value: this.state.formData?.status?.uid,
                  label: this.state.formData?.status?.name,
                }}
                formId="status"
                searchAttribute="name"
                valueAttribute="uid"
                labelAttribute="name"
                width="100%"
                label="Status"
              />
            ) : (
              <TextField
                width="100%"
                onChange={this.handleChanges}
                changeArea="status"
                label="Status"
                value={this.state.formData.status.name}
                disabled={true}
              />
            )}
            <DateArea
              disabled={source === "hub" ? false : true}
              width="100%"
              onChange={this.handleChangesStartDate}
              changeArea="startDate"
              label="Start Date"
              value={this.state.formData?.startDate}
              required={true}
              errorMessage={this.state.errorMessages["startDate"].message}
              error={this.state?.errorMessages["startDate"]?.error}
              warning={this.state?.errorMessages["startDate"]?.warning}
            />
            <DateArea
              disabled={source === "hub" && !isMilestone ? false : true}
              width="100%"
              onChange={this.handleChangesFinishDate}
              changeArea="finishDate"
              label="Finish Date"
              value={this.state.formData.finishDate}
              required={true}
              errorMessage={this.state?.errorMessages["finishDate"]?.message}
              error={this.state?.errorMessages["finishDate"]?.error}
              warning={this.state?.errorMessages["finishDate"]?.warning}
            />
            {/* <TextField width="100%" onChange= {this.handleChanges} changeArea="startDate" label="Start Date" value={this.du.Date2Str(this.state.formData.startDate)} disabled={true}/>
                        <TextField width="100%" onChange= {this.handleChanges}  changeArea="finishDate" label="Finish Date" value={this.du.Date2Str(this.state.formData.finishDate)} disabled={true}/>   
                        */}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="actuals"
              label="Actual Units (h)"
              value={
                this.state.formData.actualUnit
                  ? this.state.formData.actualUnit.toFixed(0).toString()
                  : "0"
              }
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="remainingUnits"
              label="Remaining Units (h)"
              value={
                this.state.formData.remainingUnit
                  ? this.state.formData.remainingUnit.toFixed(0).toString()
                  : "0"
              }
              disabled={true}
            />
            <PercentField
              width="100%"
              onChange={this.handleChanges}
              changeArea="complete"
              label="Activity % Complete"
              value={
                this.state.formData.percentComplete
                  ? this.state.formData.percentComplete.toString()
                  : "0"
              }
              disabled={true}
            />
            {
              <TextField
                width="100%"
                label="Responsible Department"
                value={this.state.formData?.obsInfo?.name}
                disabled={true}
              />
            }
            {source === "hub" ? (
              <BooleanArea
                width="100%"
                onChange={this.handleChanges}
                changeArea="isCommitted"
                label="Is Committed"
                value={this.state.formData?.isCommitted}
                disabled={true}
              />
            ) : (
              false
            )}
          </div>
        </BodyArea>
        <ButtonArea>
          {this.state.formData?.wbsResponsible?.uid ===
          Auth.getResourceObject().uid ? (
            <MainButton
              onClick={this.update}
              key="completeActivity"
              className="button-area-action"
              disabled={this.state.formData.status.uid === 32 ? false : true}
              label="Complete Activity"
            />
          ) : (
            false
          )}
          {source === "hub" ? (
            <>
              <MainButton
                onClick={this.updateRecord}
                key="saveActivity"
                className="button-area-action"
                disabled={this.state.formData.status.uid === 33 ? true : false}
                label="Save Activity"
              />
              {!this.state.formData?.isCommitted ? (
                <MainButton
                  onClick={this.commit}
                  key="commitActivity"
                  className="button-area-action"
                  disabled={
                    this.state.formData.status.uid === 33 ? true : false
                  }
                  label="Commit"
                />
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default SideBarActivityDetails;

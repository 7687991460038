import React, { Component } from 'react';

class ProgressView extends Component {

    nFormatter = (num, digits) => {
        const lookup = [
          { value: 1e0, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
      }
      
    render() {
        let barWidth = Math.round(100*(this.props.primaryValue/this.props.secondaryValue), 0) ;
        return (
            <>{(this.props.secondaryValue === null || this.props.secondaryValue==="" )?<div style={{textAlign:"center"}}>{this.props.primaryValue?this.nFormatter(this.props.primaryValue):this.props.primaryValue?.toFixed(1)}</div>:<div className={this.props.color?"hub-rm-progressView " + this.props.color:"hub-rm-progressView "} >
                    <div className="hub-rm-progressView-barWrapper">
                        <div style={{width: barWidth.toString()+"px" }} className="hub-rm-progressView-bar"></div>
                        <div className="hub-rm-progressView-barBg"></div>
                    </div>
                    <div className="hub-rm-progressView-values">                
                        <div className="primaryValue">{this.props.primaryValue?this.nFormatter(this.props.primaryValue, 1):this.props.primaryValue?.toFixed(1)}</div>
                        <div className="seperator">/</div>
                        <div className="secondaryValue">{this.props.secondaryValue?this.nFormatter(this.props.secondaryValue, 1):this.props.secondaryValue?.toFixed(1)}</div>
                    </div>
                    </div>
                }   
            </>
        );
    }
}

export default ProgressView;
import React, { Component } from "react";
import "./Section.css";
import HubIcon from "../../hubIcon/HubIcon";
import HiClose from "../../icons/HiClose";
import HiArrowShortDown from "../../icons/HiArrowShortDown";
import HiArrowShortUp from "../../icons/HiArrowShortUp";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed:
        this.props.isCollapsed == null ? false : this.props.isCollapsed,
    };
    this.onCollapse = this.onCollapse.bind(this);
  }
  onCollapse() {
    //console.log(this.props.isCollapsed)
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    return (
      <div
        className={
          this.state.isCollapsed
            ? "hub-pageSection collapsed"
            : "hub-pageSection"
        }
      >
        <div
          onClick={() => {
            this.onCollapse();
          }}
          className="hub-pageSectionHeader"
        >
          <span className="label">{this.props.title}</span>
          <div className="icon">
            <HubIcon lineColor="#1254C1" fontSize={20}>
              {this.state.isCollapsed ? (
                <HiArrowShortDown />
              ) : (
                <HiArrowShortUp />
              )}
            </HubIcon>
          </div>
          {/*  <div style={{ clear: "both" }}></div> */}
        </div>
        <div
          className="hub-pageSectionBody"
          style={
            this.state.isCollapsed
              ? { maxHeight: "0px", overflow: "hidden", padding: 0 }
              : {}
          }
        >
          {this.props.children}
          {/* <div style={{ clear: "both" }}></div> */}
        </div>
      </div>
    );
  }
}

export default Section;

import React, { Component } from 'react'
import './contextMenu.css'
export default class contextMenu extends Component {
  render() {
    return (
        <div className='hub-context-menu'>
          {this.props.children}
        </div>
    )
  }
}

import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";

import { DateUtils } from "./../../common/Commons";

import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import TextArea from "../../common/inputControls/textarea/TextArea";
import DateArea from "../../common/inputControls/dateArea";
import NumberField from "../../common/inputControls/numberField";
import { Utils } from "./../../common/Commons";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import HiSave from "../../common/icons/HiSave.js";
class SideBarTaskStatus extends Component {
  /**/
  constructor() {
    super();

    this.du = new DateUtils();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
        actuals: "5",
        actualStart: "",
        actualFinish: "",
        percentComplete: "",
      },
      errorMessages: {
        actualStart: { message: "", warning: false },
        actualFinish: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
        progressCalculation: { message: "", error: false, warning: false },
        percentComplete: { message: "", error: false, warning: false },
      },

      requiredFields: [
        { id: "name", label: "Name" },
        { id: "progressCalculation", label: "progress Calculation" },
        { id: "percentComplete", label: "percentComplete" },
        { id: "actualStart", label: "actualStart" },
        { id: "actualFinish", label: "actualFinish" },
      ],
    };
  }

  handleChanges = (e, val) => {
    console.log("handleChanges", e, val);

    let items = { ...this.state.formData };

    items[e] = val;

    let errors = { ...this.state.errorMessages };
    if (e === "percentComplete") {
      //console.log("percentComplete",items.actualStart,val)
      if (val > 0 && items.actualStart === null) {
        errors.actualStart.message =
          "Actual Start must entered when percent Complete > 0";
        errors.actualStart.error = true;
        errors.actualStart.warning = false;
      }

      if (val === 100 && items.actualFinish === null) {
        errors.actualFinish.message =
          "Actual Finish must entered when percent Complete = 100";
        errors.actualFinish.error = true;
        errors.actualFinish.warning = false;
      }

      if (val > 100) {
        //console.log("val>100",e,val)
        errors.percentComplete.message =
          "Percent Complete must be lest than 100";
        errors.percentComplete.error = true;
        errors.percentComplete.warning = false;
        //items[e]=100;
      }
    }

    // console.log("errors",errors)

    this.setState(
      {
        ...this.state,
        formData: items,
        errorMessages: errors,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (
          fieldValue !== "" &&
          fieldValue !== null &&
          fieldName !== "percentComplete"
        ) {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }
        if (
          fieldValue !== "" &&
          fieldValue !== null &&
          fieldValue <= 100 &&
          fieldValue > 0 &&
          fieldName === "percentComplete"
        ) {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] > this.state.formData.actualFinish) {
      errors[e].message = "Actual Start can not be later than Actual Finish";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (items[e] < this.state.parentRecord?.p6Plan?.actualStart) {
      //console.log("hataaaa");

      //errors[e].message="Start Date can not be earlier than Activity's Start Date";
      errors[e].message = "Actual Start is earlier than Activity's Start Date";
      errors[e].error = true;
      errors[e].warning = true;
    } /**/ else {
      errors[e].message = "";
      errors[e].error = false;
      //errors.actualFinish.message="";
      //errors.actualFinish.error=false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] < this.state.formData.actualStart) {
      errors[e].message = "Actual Finish can not be earlier than Actual Start";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (items[e] > this.state.parentRecord?.p6Plan?.actualFinish) {
      errors[e].message = "Actual Finish is later than Activity's Finish Date";
      errors[e].error = true;
      errors[e].warning = true;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.actualStart.message = "";
      errors.actualStart.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  componentDidMount() {
    //console.log("componentDidMount")
    //  console.log(this.props)
    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }
  compareSession = () => {
    var responsible = this.props.selectedRecord?.owner?.uid.toString();
    var createdBy = this.props.selectedRecord.createdBy?.uid.toString();
    var sessionUser = Auth.checkResourceId().toString();
    let secProfile = Auth.getResourceObject().securityProfile?.name;
    //   console.log(secProfile)
    if (
      createdBy === sessionUser ||
      sessionUser === responsible ||
      secProfile === "ADMIN"
    ) {
      return true;
    } else {
      return false;
    }
  };

  GetDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/task/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r.body.object[0]);
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object[0],
          });

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  update = async () => {
    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      let data = Object.assign(this.state.formData, {
        columnIndex: this.props.selectedRecord.columnIndex,
        cardIndex: this.props.selectedRecord.cardIndex,
      });
      await this.props.save(
        "patch",
        "/api/task/progressCalculation/" + this.state.formData.uid,
        data,
        undefined,
        undefined,
        undefined,
        false
      );
    }
  };

  render() {
    let calcMethod = this.state.formData?.progressCalculation
      ? this.state.formData?.progressCalculation?.code
      : "unit";

    //console.log("calcMethod",calcMethod)
    //console.log("state", this.state)
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="input-grid">
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="plannedStart"
              label="Planned Start Date"
              value={this.du.Date2Str(this.state.formData.plannedStart)}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="plannedFinish"
              label="Planned Finish Date"
              value={this.du.Date2Str(this.state.formData.plannedFinish)}
              disabled={true}
            />{" "}
            <DateArea
              width="100%"
              onChange={this.handleChangesStartDate}
              changeArea="actualStart"
              label="Actual Start"
              value={this.state.formData.actualStart}
              required={true}
              errorMessage={this.state.errorMessages["actualStart"].message}
              error={this.state?.errorMessages["actualStart"]?.error}
              warning={this.state?.errorMessages["actualStart"]?.warning}
              disabled={
                calcMethod === "manual"
                  ? false
                  : true || !this.compareSession()
                  ? true
                  : false
              }
            />
            <DateArea
              width="100%"
              onChange={this.handleChangesFinishDate}
              changeArea="actualFinish"
              label="Actual Finish"
              value={this.state.formData.actualFinish}
              required={true}
              errorMessage={this.state?.errorMessages["actualFinish"]?.message}
              error={this.state?.errorMessages["actualFinish"]?.error}
              warning={this.state?.errorMessages["actualFinish"]?.warning}
              disabled={
                calcMethod === "manual"
                  ? false
                  : true || !this.compareSession()
                  ? true
                  : false
              }
            />{" "}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="plannedUnit"
              label="Planned Units (h)"
              value={this.state.formData.plannedUnit?.toString()}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="actualUnit"
              label="Actual Units (h)"
              value={
                this.state.formData.actualUnit
                  ? this.state.formData.actualUnit.toString()
                  : "0"
              }
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="remainingUnits"
              label="Remaining Units (h)"
              value={
                this.state.formData.remainingUnit
                  ? this.state.formData.remainingUnit.toString()
                  : "0"
              }
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="atCompletionUnit"
              label="At Completion Units (h)"
              value={this.state.formData.atCompletionUnit?.toString()}
              disabled={true}
            />
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="percentComplete"
              max={100}
              value={this.state.formData?.percentComplete}
              label="Percent Complete"
              disabled={calcMethod === "manual" ? false : true}
              required={true}
              errorMessage={
                this.state.errorMessages["percentComplete"]?.message
              }
              error={this.state?.errorMessages["percentComplete"]?.error}
              warning={this.state?.errorMessages["percentComplete"]?.warning}
            />
            <DynamicLookup
              endpoint={"/api/valuelist/code/hub_task_progress_calculation"}
              required={true}
              onChange={this.handleChanges}
              extraObject={"valueListOptions"}
              value={{
                value: this.state.formData?.progressCalculation?.uid,
                label: this.state.formData?.progressCalculation?.name,
              }}
              formId="progressCalculation"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="100%"
              label="Progress Calculation Method"
              disabled={true}
            />
            <BooleanArea
              width="100%"
              onChange={this.handleChanges}
              changeArea="isOpenForTimeEntry"
              label="Is Open for Time Entry"
              value={this.state.formData.isOpenForTimeEntry}
              disabled={true}
            />
          </div>
        </BodyArea>
        <ButtonArea>
          {calcMethod === "manual" ? (
            <MainButton
              onClick={this.update}
              key="newTaskButton"
              className="button-area-action"
              iconLineColor="#fff"
              icon={<HiSave />}
              label="Save"
            />
          ) : (
            false
          )}
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default SideBarTaskStatus;

import ReactDOM from "react-dom";

import "./NewModal.scss";
import Backdrop from "./Backdrop";
import HiClose from "../icons/HiClose";
import MainButton from "../buttons/MainButton";

const ModalOverlay = ({ children, onClose, title }) => {
  return (
    <div className="hub-new-modal">
      <div
        className="title-section"
        style={{
          backgroundColor: title ? "var(--hub25, #f4f7fc)" : "transparent",
          justifyContent: title ? "space-between" : "flex-end",
          padding: title ? "16px" : "16px 16px 0px 16px",
        }}
      >
        {title && <h3 className="title">{title}</h3>}

        <MainButton
          onClick={onClose}
          icon={<HiClose />}
          iconSize={16}
          className="secondary"
          style={{ width: 34, height: 34, justifyContent: "center" }}
        />
      </div>

      <div className="content">{children}</div>
    </div>
  );
};

const Modal = ({ onClose, children, title }) => {
  const overlaysRoot = document.getElementById("overlays");
  return (
    <>
      {ReactDOM.createPortal(<Backdrop onClose={onClose} />, overlaysRoot)}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={onClose} title={title}>
          {children}
        </ModalOverlay>,
        overlaysRoot
      )}
    </>
  );
};

export default Modal;

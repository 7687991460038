import React, { Component } from 'react'
import './tag.css'
export default class Tag extends Component {
  render() {
    return (
        <div className={'hub-card-tag-body '  + this.props.tagColor+ (this.props.collapsed?" collapsed":"")} >

                <div className='hub-card-tag-label' >{this.props.tagName}</div>

        </div>
    )
  }
}

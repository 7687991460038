import React, { Component } from 'react';
import './Navigation.css';
import NavigationLink from './NavigationLink';
import NavigationSectionHeader from './NavigationSectionHeader';
import Auth from '../auth/Auth';

class NavigationSection extends Component {
    
    render() {
        let secProfile=Auth.getResourceObject().securityProfile?.name;
     
        return (
            <>
                {this.props.section.items.map(e=>{
                     if(e.authGroup.indexOf(secProfile)>-1){
                         return <NavigationLink activeNavId={this.props.activeNavId} key={e.id} link={e} icon={e.icon} collapsed={this.props.collapsed}/>
                        }
                    
                })}
            </>
        );
    }
}

export default NavigationSection;
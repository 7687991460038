import { Utils } from "../common/Commons";
export const tokenVar = "hubtoken";
export const resourceId = "hubresourceId";
export const resourceFullName = "hubresourceFullName";
export const resourceObject = "resourceObject";

class Auth {
  constructor() {
    this.authenticated = true;
  }

  logout() {
    let utils = new Utils();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };
    fetch("/api/session", requestOptions);
    localStorage.removeItem(tokenVar);
    localStorage.removeItem(resourceFullName);
    localStorage.removeItem(resourceId);
    utils.removeCopyTaskList();
    utils.removeCopyTaskListObject();
    window.location.replace("/login");
  }

  refresh() {
    console.log("token süresinin uzatılması");
  }

  check() {
    return localStorage.getItem(tokenVar);
  }

  checkResourceName() {
    return localStorage.getItem(resourceFullName);
  }

  checkResourceId() {
    return localStorage.getItem(resourceId);
  }

  getResourceObject() {
    //console.log("getResourceObject")
    //console.log(JSON.parse(localStorage.getItem(resourceObject)))
    return JSON.parse(localStorage.getItem(resourceObject));
  }
}

export default new Auth();

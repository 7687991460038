import React, { Component, useState } from "react";
import InputControl from "../InputControl";
import Auth from "../../../auth/Auth";
import "./Lookup.css";

import AsyncSelect from "react-select/async";

import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import HubIcon from "../../hubIcon/HubIcon";
import HiArrowShortDown from "../../icons/HiArrowShortDown";
import Avatar from "../../avatar";
class DynamicLookup extends Component {
  /* 
        Dynamic component'ı HUB üzerindeki statik lookup'ların görüntülendiği ve seçildiği tekil inputtur.
        Prop olarak valueListId değerini alır. (Bunu ileride koda döndürebiliriz)
        Aldığı proplar: 
        endpoint="" , labelAttr, 
        valueAttribute
        labelAttribute
        searchAttribute
        value: Değerin 
    */

  constructor(props) {
    super(props);
    this.state = {
      values: [],
      searchString: "",
      focus: false,
      selectedValue: {},
    };
  }

  componentDidMount() {
    this.loadOptions("", (x) => {
      this.setState({ ...this.state, values: x });
    });
  }

  handleSearch = (e) => {
    this.setState({ ...this.state, searchString: e.target.value });
  };

  openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, searchString: "", focus: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, focus: false, toggleTooltip: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  loadOptions = async (inputText, callback) => {
    // console.log("endpoint",this.props.endpoint)
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    const response = await fetch(
      `${this.props.endpoint}?size=1000000&search=${this.props.searchAttribute}:${inputText}`,
      requestOptions
    );
    const json = await response.json();
    let result = json.object;
    if (this.props.extraObject) {
      result = json.object[this.props.extraObject];
    }
    //console.log("json",json.object[null])
    callback(
      result.map((i) => ({
        label: i[this.props.labelAttribute],
        value: i[this.props.valueAttribute],
        object: i,
      }))
    );

    // console.log("dynamic Response",response)
  };

  handleChange = (selectedValue) => {
    //console.log(selectedValue)
    this.props.onChange(this.props.formId, selectedValue.object);
  };

  onFocus = () => {
    this.setState({ ...this.state, focus: true });
  };

  onBlur = () => {
    this.setState({ ...this.state, focus: false });
  };

  render() {
    //console.log(this.state)
    //console.log(this.props)
    let colourStyles = {
      control: (base) => ({
        ...base,
        border: "0",
        outline: "none",
        padding: "0",
        minHeight: "31px",
        boxShadow: "none",
        "&:hover": {
          border: "0",
          outline: "none",
          boxShadow: "none",
        },
      }),
      container: (base) => ({
        ...base,
        width: "100%",
        border: "0",
        outline: "none",
        padding: "0",
        boxShadow: "none",
        "&:hover": {
          border: "0",
          outline: "none",
          boxShadow: "none",
        },
      }),
      valueContainer: (base) => ({
        ...base,
        padding: "0",
      }),
      input: (base) => ({
        ...base,
        padding: "0",
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none !important",
      }),
      dropdownIndicator: (base) => ({
        ...base,
      }),
      placeholder: (base, state) => ({
        ...base,
        color: "#051530",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "18px" /* 128.571% */,
        opacity: "0.15",
      }),
      menu: (provided, state) => ({
        ...provided,
        width: "calc(100% + 24px)",
        left: "-12px !important",
        borderRadius: "6px",
        border: "1px solid var(--hub100, #D2DEF2)",
        background: "#FFF",
        boxShadow: "0px 20px 30px 0px rgba(18, 84, 193, 0.25)",
        padding: "5px",
        overflowY: "auto",
        scrollbarWidth: "7px !important",
        scrollbarColor: "#E3E3E3 white",
        "&::-webkit-scrollbar": {
          backgroundColor: "#E3E3E3;",
          width: "7px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E3E3E3;",
          borderRadius: "1px",
          width: "7px !important",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
          borderRadius: "1px",
          width: "7px !important",
        },
        "& > div:last-child": {
          borderBottom: "none", // Remove bottom border from last option
        },
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderBottom: "1px solid #E8EFF9",
        padding: "10px",
        height: "auto",
        color: state.isSelected ? "#fff" : "var(--hub900, #051530)",
        background: state.isSelected ? "var(--hub500, #1769F1)" : "#fff",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        borderRadius: "3px",

        "&:last-child": {
          borderBottom: "none", // Remove bottom border from last option
        },

        "&:hover": {
          background: "var(--hub10, #F4F7FC)",
          color: "#051530",
        },
      }),
    };

    if (this.props.type === "card") {
      let colourStyles = {
        control: (base) => ({
          ...base,
          border: "0",
          outline: "none",
          padding: "0",
          minHeight: "31px",
          boxShadow: "none",
          "&:hover": {
            border: "0",
            outline: "none",
            boxShadow: "none",
          },
        }),
        container: (base) => ({
          ...base,
          border: "0",
          outline: "none",
          padding: "0",
          boxShadow: "none",
          "&:hover": {
            border: "0",
            outline: "none",
            boxShadow: "none",
          },
        }),
        valueContainer: (base) => ({
          ...base,
          padding: "0",
        }),
        input: (base) => ({
          ...base,
          padding: "0",
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: "none !important",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          /*  padding: "6px 8px !important", */
        }),
        placeholder: (base, state) => ({
          ...base,
          color: "#051530",
          fontFamily: "Noto Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "18px" /* 128.571% */,
          opacity: "0.15",
        }),
        menu: (provided, state) => ({
          ...provided,
          width: "calc(100% + 24px)",
          left: "-12px !important",
          borderRadius: "6px",
          border: "1px solid var(--hub100, #D2DEF2)",
          background: "#FFF",
          boxShadow: "0px 20px 30px 0px rgba(18, 84, 193, 0.25)",
          padding: "5px",
          overflowY: "auto",
          scrollbarWidth: "3px !important",
          scrollbarColor: "#779dd9 white",
          "&::-webkit-scrollbar": {
            width: "3px !important",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#779dd9",
            borderRadius: "3px",
            width: "3px !important",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#779dd9",
            borderRadius: "3px",
            width: "3px !important",
          },
          "& > div:last-child": {
            borderBottom: "none", // Remove bottom border from last option
          },
        }),
        option: (provided, state) => ({
          ...provided,
          cursor: "pointer",
          borderBottom: "1px solid #E8EFF9",
          padding: "10px",
          height: "auto",
          color: state.isSelected ? "#fff" : "var(--hub900, #051530)",
          background: state.isSelected ? "var(--hub500, #1769F1)" : "#fff",
          fontFamily: "Noto Sans",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          borderRadius: "3px",

          "&:last-child": {
            borderBottom: "none", // Remove bottom border from last option
          },

          "&:hover": {
            background: "var(--hub10, #F4F7FC)",
            color: "#051530",
          },
        }),
      };
    }

    const DropdownIndicator = (props) => {
      return (
        <div {...props}>
          <HubIcon>
            <HiArrowShortDown />
          </HubIcon>
        </div>
      );
    };

    const ValueContainer = ({ children, ...props }) => {
      return (
        components.ValueContainer && (
          <components.ValueContainer {...props}>
            <div className="lookup-dropdown">
              {this.props.color ? (
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{ color: this.props.value.color }}
                  size="1x"
                />
              ) : (
                ""
              )}

              {children}
            </div>
          </components.ValueContainer>
        )
      );
    };

    const { Option } = components;
    const IconOption = (props) => {
      return (
        <Option {...props}>
          <div
            className="lookup-dropdown"
            style={{ display: "flex", alignItems: "center" }}
          >
            {this.props.withAvatar && (
              <Avatar
                firstName={props?.data?.object?.firstName}
                lastName={props?.data?.object?.lastName}
                uid={props?.data?.object?.uid}
                color={props?.data?.object?.color}
                style={{
                  width: 20,
                  height: 20,
                }}
                size="sm"
                letterCount={1}
                hasAvatar={
                  props?.data?.object?.profileImage?.cropImageHash &&
                  props?.data?.object?.profileImage?.profileImageHash
                }
              />
            )}
            {this.props.color ? (
              <FontAwesomeIcon
                icon={faCircle}
                style={{ color: props.data.object.color }}
                size="1x"
              />
            ) : (
              ""
            )}

            {props.data.label}
          </div>
        </Option>
      );
    };

    return (
      <>
        <InputControl
          inputValue={this.state.v}
          error={this.state.error}
          errorMessage={this.state.errorMessage}
          focus={this.state.focus}
          {...this.props}
        >
          {!this.props.disabled ? (
            <AsyncSelect
              value={this.props.value}
              styles={colourStyles}
              onChange={this.handleChange}
              placeholder={"Search..."}
              defaultOptions={this.state.values}
              loadOptions={this.loadOptions}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              autoFocus={this.props.autoFocus ? true : false}
              menuPlacement="bottom"
              maxMenuHeight={190}
              components={{ Option: IconOption, DropdownIndicator }}
              isDisabled={this.props.disabled}
              isMulti={this.props.isMulti}
            />
          ) : (
            <input
              disabled={this.props.disabled}
              value={this.props.value?.label}
            />
          )}
        </InputControl>
      </>
    );
  }
}

export default DynamicLookup;

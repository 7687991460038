import { useState } from "react";
import "./Buttons.css";
import "../spinner.css";
import Tooltip from "../../common/tooltip/tooltip.js";
import HubIcon from "../hubIcon/HubIcon.js";
import loadingImage from "../../assets/img/loading-white.png";

/**
 * Loading component renders a loading spinner image.
 *
 * @returns {JSX.Element} The loading spinner component.
 */
const Loading = () => {
  return <img src={loadingImage} className="spinner-reversed" />;
};

/**
 * MainButton component is a customizable button with optional loading state, icon, and tooltip.
 *
 * @param {string} [superscript] Text displayed as superscript above the button.
 * @param {string} label The text label of the button.
 * @param {boolean} [disabled=false] Indicates whether the button is disabled.
 * @param {string} [type="button"] The type attribute for the button (e.g., "button", "submit").
 * @param {function} onClick The function to be called when the button is clicked.
 * @param {object} [style] Inline styles to apply to the button.
 * @param {string} [className] Additional CSS classes to apply to the button.
 * @param {number} [iconSize=16] The size of the icon in pixels.
 * @param {string} [iconLineColor="#051530"] The line color of the icon.
 * @param {JSX.Element} [icon] The icon to display within the button.
 * @param {string} [tooltip] The text to display in a tooltip on hover.
 * @param {boolean} [isLoading=false] Indicates whether the button is in a loading state.
 * @param {string} [tooltipLocation="bottom-center"] Tooltip location.
 * @returns {JSX.Element} The rendered MainButton component.
 *
 * @example
 * // Render a MainButton with a label, icon, and tooltip
 * <MainButton
 *   label="Submit"
 *   icon={<YourIconComponent />}
 *   tooltip="Click to submit"
 *   onClick={handleSubmit}
 * />
 *
 * @example
 * // Render a disabled MainButton with loading state
 * <MainButton
 *   label="Loading"
 *   isLoading={true}
 *   disabled={true}
 * />
 */
const MainButton = ({
  superscript,
  label,
  disabled,
  type,
  onClick,
  style,
  className,
  iconSize,
  iconLineColor,
  icon,
  tooltip,
  isLoading,
  tooltipLocation = "bottom-center",
}) => {
  const [isShownTooltip, setIsShownTooltip] = useState(undefined);

  const handleTooltip = () => {
    setIsShownTooltip((prevState) => !prevState);
  };

  const handleClick = (e) => {
    !isLoading && onClick(e);
  };

  return (
    <div className="hub-button-wrapper">
      {superscript ? <div className="superscript">{superscript}</div> : false}
      <button
        disabled={disabled}
        type={type ? type : "button"}
        onClick={handleClick}
        style={{ ...style }}
        className={className ? "hub-button " + className : "hub-button"}
        onMouseOver={handleTooltip}
        onMouseOut={handleTooltip}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {icon && (
              <span className="icon">
                <HubIcon
                  fontSize={iconSize ?? 16}
                  lineColor={iconLineColor ?? "#051530"}
                >
                  {icon}
                </HubIcon>
              </span>
            )}
            {label && <span className="label">{label}</span>}
          </>
        )}
      </button>
      {tooltip !== undefined && (
        <Tooltip
          label={tooltip}
          close={isShownTooltip}
          class={tooltipLocation}
        />
      )}
    </div>
  );
};

export default MainButton;

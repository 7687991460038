import React, { Component } from "react";
import "./ProjectDetails.css";
import Auth from "./../../auth/Auth";
import TextField from "../../common/inputControls/textField/index.js";
import TextArea from "./TextArea";
import ButtonArea from "./../../common/pageElements/buttonArea";
import Section from "./../../common/pageElements/section";
import SectionContainer from "./../../common/pageElements/sectionContainer";
import MainButton from "./../../common/buttons/MainButton";
import { faFloppyDisk } from "@fortawesome/pro-light-svg-icons";
import Loader from "../../Loader.js";
import TextArea2 from "../../common/inputControls/textArea2/textArea";

import { useParams } from "react-router";

import { DateUtils, Utils } from "./../../common/Commons";

import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";

import LoadingScreen from "../../common/statusScreen/loadingScreen";

import DateArea from "../../common/inputControls/dateArea";
import HiSave from "../../common/icons/HiSave.js";
import ProjectSection from "../../common/pageElements/projectSection";

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingText: "Loading Project Details",
      Loading: true,
      LoadingError: false,
      LoadingErrorText: "",
      project: {},

      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
      },

      requiredFields: [{ id: "name", label: "Name" }],
    };
  }

  handleChanges = (e, val) => {
    //console.log(e)
    //console.log(val)
    let items = { ...this.state.project };

    items[e] = val;
    this.setState({
      ...this.state,
      project: items,
    });

    // console.log(items)
  };

  handleChangesLookup = (e, val) => {
    // console.log(e)
    //console.log(val)

    let items = { ...this.state.project };

    items[e] = val.uid;
    items.programManagerInfo = { uid: val.uid, fullName: val.fullName };
    this.setState({
      ...this.state,
      project: items,
    });

    //console.log(items)
  };

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.project };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] > this.state.project.finishDate) {
      errors[e].message = "Start Date can not be later than Finish Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;
      errors.finishDate.message = "";
      errors.finishDate.error = false;
    }

    this.setState({ ...this.state, project: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.project };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] < this.state.project.startDate) {
      errors[e].message = "Finish Date can not be earlier than Start Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.startDate.message = "";
      errors.startDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  componentDidMount() {
    //this.getData();
    this.setState({
      ...this.state,
      Loading: false,
      project: this.props.project,
    });
  }

  getData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/projects/" + this.props.params.projectid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log('Status',r.body.status)
        if (r.body.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            project: r.body.object[0],
          });

          this.props.selectedRecord(r.body.object[0]);
        } else if (r.body.status == 401) {
          window.location.replace("/login");
        } else if (r.body.status == 403) {
          this.setState({
            ...this.state,
            Loading: false,
            LoadingError: true,
            LoadingText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Loading: false,
            LoadingError: true,
            LoadingText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  update = () => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(this.state.project),
    };

    fetch("/api/projects/" + this.props.params.projectid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        const u = new Utils();

        if (r.status === 200) {
          this.setState(
            {
              ...this.state,
              Status: "Update",
              ErrorMessage: "Updated Succesfully",
            },
            this.props.selectedRecord(r.body.object)
          );
          this.notify();
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          u.addNotification(
            "error",
            "You are not authorized to take this action. Please contact your system administrator.."
          );
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          u.addNotification("error", r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            ErrorMessage: r.body.message,
          });
        } else {
          //console.log(r.body.message);
          u.addNotification("error", r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  updateCheck = () => {
    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      this.update();
    }
  };

  notify = () => {
    const u = new Utils();
    u.addNotification("success", "Project saved successfully.");
  };

  render() {
    //const du  = new DateUtils();
    //console.log(this.state)

    return this.state.Loading ? (
      <LoadingScreen />
    ) : (
      <div className="pageDetails">
        <ButtonArea>
          <MainButton
            onClick={this.updateCheck}
            iconLineColor="#fff"
            label="Save Project"
            icon={<HiSave />}
            className="hub-button button-area-action"
          />
        </ButtonArea>
        <ProjectSection title="Project Details">
          <TextField
            width="288px"
            required={true}
            disabled
            pct="20"
            label="Project ID"
            value={this.state.project.code}
          />
          <TextField
            width="288px"
            required={true}
            disabled
            pct="20"
            label="Project Name"
            value={this.state.project.name}
          />
          {/*<TextField disabled pct="30" label="IPT" value="Air Frame" />*/}
          {/*<ProjectSectionContainer width="70%">
                        <TextArea2 width="95%" rows="4"  label="Objective" value={this.state.project.description} onChange={this.handleChanges} changeArea="description" />
                        </SectionContainer>*/}
       
            {/*<TextField disabled pct="100" label="Work Status" value={this.state.project.projectStatus}  />*/}
       
        </ProjectSection>
        <ProjectSection title="Project Status">
       
            {/* <TextField disabled pct="100" label="Start" value={du.Date2Str(this.state.project.startDate)} />*/}
            <DateArea
              width="288px"
              onChange={this.handleChangesStartDate}
              changeArea="startDate"
              label="Start Date"
              value={this.state.project.startDate}
              required={true}
              errorMessage={this.state.errorMessages["startDate"].message}
              error={this.state?.errorMessages["startDate"]?.error}
              warning={this.state?.errorMessages["startDate"]?.warning}
            />

            <TextField
              width="288px"
              disabled
              pct="100"
              label="Actual Units"
              value={
                this.state.project.actualUnit
                  ? this.state.project.actualUnit + "h"
                  : "0h"
              }
            />
            {/* <TextField disabled pct="100" label="Planned Start" value={du.Date2Str(this.state.project.plannedStart)} />
                        <TextField disabled pct="100" label="Planned Finish" value={du.Date2Str(this.state.project.plannedFinish)} />*/}
        
        
            {/* <TextField disabled pct="100" label="Finish" value={du.Date2Str(this.state.project.finishDate)} />*/}
            <DateArea
              width="288px"
              onChange={this.handleChangesFinishDate}
              changeArea="finishDate"
              label="Finish Date"
              value={this.state.project.finishDate}
              required={true}
              errorMessage={this.state?.errorMessages["finishDate"]?.message}
              error={this.state?.errorMessages["finishDate"]?.error}
              warning={this.state?.errorMessages["finishDate"]?.warning}
            />

            {/*  <TextField disabled pct="100" label="Remaining Units" value={this.state.project.remainingUnit?this.state.project.remainingUnit+'h':'0h'} />
                        <TextField disabled pct="100" label="Planned Units" value={this.state.project.plannedUnit?this.state.project.plannedUnit+'h':'0h'} />*/}
            <TextField
              width="288px"
              disabled
              pct="100"
              label="At Completion Unit"
              value={
                this.state.project.atCompletionUnit
                  ? this.state.project.atCompletionUnit + "h"
                  : "0h"
              }
            />
       
         
            {/*<TextField disabled pct="100" label="% Complete" value={this.state.project.percentComplete} />
                           <TextField disabled pct="100" label="Unit % Complete" value="63%" />*/}
         
        </ProjectSection>
        <ProjectSection title="Stakeholders">
        
            {/* <DynamicLookup endpoint="/api/resource" value={{value:this.state.project.iptResponsible?.id, label: this.state.project.iptResponsible?.fullName }} onChange={this.handleChanges} formId="iptResponsible" searchAttribute="fullName" valueAttribute="id" labelAttribute="fullName"  width="90%" label="IPT Responsible"/>
            
                         <DynamicLookup endpoint="/api/obs" value={{value:this.state.project.iptTeam?.uid, label: this.state.project.iptTeam?.name }} onChange={this.handleChanges} formId="iptTeam" searchAttribute="name" valueAttribute="uid" labelAttribute="name"  width="90%" label="IPT Team"/>*/}
            <DynamicLookup
              endpoint="/api/resource"
              value={{
                value: this.state.project.programManagerInfo?.uid,
                label: this.state.project.programManagerInfo?.fullName,
              }}
              onChange={this.handleChangesLookup}
              formId="ProgramManager"
              searchAttribute="fullName"
              valueAttribute="uid"
              labelAttribute="fullName"
              width="288px"
              label="Program Manager"
            />
         
         
            <DynamicLookup
              endpoint="/api/resource"
              value={{
                value: this.state.project.pmoResponsible?.uid,
                label: this.state.project.pmoResponsible?.fullName,
              }}
              onChange={this.handleChanges}
              formId="pmoResponsible"
              searchAttribute="fullName"
              valueAttribute="uid"
              labelAttribute="fullName"
              width="288px"
              label="Central PM"
            />
        
        </ProjectSection>
        <ProjectSection title="Settings">
          <DynamicLookup
            endpoint="/api/valuelistoptions/parent/20"
            value={{
              value: this.state.project.color?.uid,
              label: this.state.project.color?.name,
            }}
            onChange={this.handleChanges}
            formId="color"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="288px"
            label="Banner Color"
            color={true}
          />
          <DynamicLookup
            endpoint="/api/program"
            value={{
              value: this.state.project.program?.uid,
              label: this.state.project.program?.name,
            }}
            onChange={this.handleChanges}
            formId="program"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="288px"
            label="Program"
          />

          <TextField
            width="288px"
            disabled
            pct="100"
            label="Source"
            value={this.state.project?.source?.name}
          />
        </ProjectSection>
      </div>
    );
  }
}

export default (props) => <Details {...props} params={useParams()} />;

import React, { Component } from 'react'




export default class TableItem extends Component {
  render() {
    //console.log("TableItem",this.props)
    return (
        <div className='hier-item-wrapper' style={{paddingLeft:(this.props.hierLevel*20)+"px"}}>
           
            <span className={this.props.hasChild?"bold":""}onClick={this.props.onClick}>{this.props.label}</span>
        </div>
    )
  }
}

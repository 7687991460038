import { Link } from "react-router-dom";

const TextContent = ({ value, linkTo }) => {
  const isLinkText = !!linkTo;

  const label = value ?? "";

  return isLinkText ? <Link to={linkTo}>{label}</Link> : label;
};

export default TextContent;

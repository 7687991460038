import React, { Component } from "react";
import MainButton from "../../../common/buttons/MainButton";
import { DateUtils, Utils } from "./../../../common/Commons";

import "./DatePicker.css";

import { faCalendar, faArrowRight } from "@fortawesome/pro-regular-svg-icons";

import LayoutItem from "./LayoutItem";
import DailyPicker from "./DailyPicker";
import WeeklyPicker from "./WeeklyPicker";
import MonthlyPicker from "./MonthlyPicker";
import QuarterlyPicker from "./QuarterlyPicker";
import YearlyPicker from "./YearlyPicker";
import { getDate } from "date-fns";
import { th } from "date-fns/locale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.viewTypes = {
      daily: { code: "daily", name: "Daily" },
      weekly: { code: "weekly", name: "Weekly" },
      monthly: { code: "monthly", name: "Monthly" },
      quarterly: { code: "quarterly", name: "Quarterly" },
      yearly: { code: "yearly", name: "Yearly" },
    };

    this.viewTypeList = ["daily", "weekly", "monthly", "quarterly"];

    this.wrapperRef = React.createRef();
    this.xbuttonRef = React.createRef();
    this.state = { loading: true, isOpen: false };
  }

  componentDidMount() {
    const now = new Date();

    this.setState({
      ...this.state,
      startDate: this.props.dateRange.start,
      finishDate: this.props.dateRange.finish,
      activeLayout: this.props.dateRange.period,
    });
  }

  togglePanel = () => {
    this.setState({ ...this.state, isOpen: !this.state.isOpen }, () => {
      setTimeout(() => {
        document.getElementsByClassName(
          "hub-rmUtil-datePicker-wrapper"
        )[0].style.opacity = "1";
      });
      window.addEventListener("mouseup", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.closeSelector(event);
    }
  };

  closeSelector = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, isOpen: false }, () => {
      window.removeEventListener("mouseup", this.handleClickOutside);
    });
  };

  setActive = (l) => {
    if (this.state.activeLayout !== l.code) {
      this.setState({
        ...this.state,
        activeLayout: l.code,
        startDate: "",
        finishDate: "",
      });
    }
  };

  selectDate = (d) => {
    if (
      this.state.startDate === "" ||
      d < this.state.startDate ||
      (this.state.startDate !== "" && this.state.finishDate !== "")
    ) {
      this.setState({ ...this.state, startDate: d, finishDate: "" });
    } else if (this.state.startDate == d) {
      this.setState({ ...this.state, finishDate: "", startDate: "" });
    } else {
      this.setState({ ...this.state, finishDate: d });
    }
  };

  setDate = (e) => {
    // Eğer start veya finish seçilmeden Done'a basılırsa hata veririz.
    e.preventDefault();
    e.stopPropagation();
    const u = new Utils();

    if (
      this.state.startDate === "" &&
      this.state.finishDate === "" &&
      this.state.activeLayout !== ""
    ) {
      this.setState({ ...this.state, isOpen: false });
    } else if (
      this.state.startDate === "" ||
      this.state.finishDate === "" ||
      this.state.activeLayout === ""
    ) {
      u.addNotification(
        "error",
        "You should select Start Date and Finish Date to complete date setting."
      );
    } else {
      this.setState({ ...this.state, isOpen: false }, () => {
        window.removeEventListener("mouseup", this.handleClickOutside);
        this.props.setDate(
          this.state.startDate,
          this.state.finishDate,
          this.state.activeLayout
        );
      });
    }
  };

  dateToString = (date_string, period = "") => {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let p = period === "" ? this.props.dateRange.period : period;

    if (date_string === "") {
      return "";
    } else if (p === "daily") {
      const d = new Date(date_string);
      return (
        date_string.substring(8, 10) +
        " " +
        monthNames[d.getMonth()] +
        " " +
        date_string.substring(0, 4)
      );
    } else if (p === "weekly") {
      const d = new Date(date_string);
      return (
        date_string.substring(8, 10) +
        " " +
        monthNames[d.getMonth()] +
        " " +
        date_string.substring(0, 4)
      );
    } else if (p === "monthly") {
      const d = new Date(date_string);
      return monthNames[d.getMonth()] + " " + date_string.substring(0, 4);
    } else if (p === "quarterly") {
      monthNames = [
        "Q1",
        "Q1",
        "Q1",
        "Q2",
        "Q2",
        "Q2",
        "Q3",
        "Q3",
        "Q3",
        "Q4",
        "Q4",
        "Q4",
      ];
      const d = new Date(date_string);
      return monthNames[d.getMonth()] + " " + date_string.substring(0, 4);
    } else {
      return "";
    }
  };

  weekFinishString = (date_string) => {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    var d = new Date(date_string);
    var first = d.getDate() - d.getDay(); // First day is the day of the month - the day of the week
    var last = first + 7; // last day is the first day + 6
    var lastday = new Date(d.setDate(last));

    return (
      d.getDate().toString().padStart(2, "0") +
      " " +
      monthNames[d.getMonth()] +
      " " +
      d.getFullYear()
    );
  };

  render() {
    return (
      <>
        <MainButton
          onClick={this.togglePanel}
          label={
            "Date Range: " +
            this.dateToString(this.props.dateRange.start) +
            " - " +
            (this.props.dateRange.period === "weekly"
              ? this.weekFinishString(this.props.dateRange.finish)
              : this.dateToString(this.props.dateRange.finish)) +
            ", " +
            this.viewTypes[this.props.dateRange.period].name
          }
          className="button-4"
        />
        {this.state.isOpen ? (
          <div ref={this.wrapperRef} className="hub-rmUtil-datePicker-wrapper">
            <div className="arrow"></div>
            <div className="hub-rmUtil-datePicker">
              <div className="header">
                <div className="title">
                  Date Range
                  {this.state.startDate !== "" ? (
                    <>
                      <div className="desc" style={{ marginLeft: 15 }}>
                        {this.dateToString(
                          this.state.startDate,
                          this.state.activeLayout
                        )}{" "}
                      </div>
                      <FontAwesomeIcon icon={faArrowRight} />
                      {this.state.finishDate === "" ? (
                        "..."
                      ) : (
                        <div className="desc">
                          {this.state.activeLayout === "weekly"
                            ? this.weekFinishString(this.state.finishDate)
                            : this.dateToString(
                                this.state.finishDate,
                                this.state.activeLayout
                              )}{" "}
                        </div>
                      )}
                    </>
                  ) : (
                    false
                  )}
                </div>
                <div className="buttonBar">
                  <MainButton
                    onClick={this.setDate}
                    disabled={
                      this.state.startDate !== "" &&
                      this.state.finishDate === ""
                    }
                    style={{ height: 32, border: "1px solid #DFE4ED" }}
                    className="new-main-button"
                    label="Done"
                  />
                </div>
              </div>
              <div className="body">
                <div
                  className="column"
                  style={{ width: 164, borderRight: "4px solid #F9FAFC" }}
                >
                  <div className="detailHeader">View Type</div>
                  <div style={{ marginTop: 15 }}>
                    {this.viewTypeList.map((x) => {
                      return (
                        <LayoutItem
                          onClick={this.setActive}
                          item={this.viewTypes[x]}
                          selected={
                            this.viewTypes[x].code ===
                            this.props.dateRange.period
                          }
                          active={
                            this.viewTypes[x].code === this.state.activeLayout
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div
                  className="column"
                  style={{ width: 476, padding: "20px 26px 7px 26px" }}
                >
                  <div className="detailHeader">
                    {this.viewTypes[this.state.activeLayout]?.name} Picker
                  </div>
                  {this.state.activeLayout === "daily" ? (
                    <DailyPicker
                      dateRange={this.props.dateRange}
                      activeLayout={this.state.activeLayout}
                      selectDate={this.selectDate}
                      startDate={this.state.startDate}
                      finishDate={this.state.finishDate}
                    />
                  ) : (
                    false
                  )}
                  {this.state.activeLayout === "weekly" ? (
                    <WeeklyPicker
                      dateRange={this.props.dateRange}
                      activeLayout={this.state.activeLayout}
                      selectDate={this.selectDate}
                      startDate={this.state.startDate}
                      finishDate={this.state.finishDate}
                    />
                  ) : (
                    false
                  )}
                  {this.state.activeLayout === "monthly" ? (
                    <MonthlyPicker
                      dateRange={this.props.dateRange}
                      activeLayout={this.state.activeLayout}
                      selectDate={this.selectDate}
                      startDate={this.state.startDate}
                      finishDate={this.state.finishDate}
                    />
                  ) : (
                    false
                  )}
                  {this.state.activeLayout === "quarterly" ? (
                    <QuarterlyPicker
                      dateRange={this.props.dateRange}
                      activeLayout={this.state.activeLayout}
                      selectDate={this.selectDate}
                      startDate={this.state.startDate}
                      finishDate={this.state.finishDate}
                    />
                  ) : (
                    false
                  )}
                  {this.state.activeLayout === "yearly" ? (
                    <YearlyPicker
                      dateRange={this.props.dateRange}
                      activeLayout={this.state.activeLayout}
                      selectDate={this.selectDate}
                      startDate={this.state.startDate}
                      finishDate={this.state.finishDate}
                    />
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          false
        )}
      </>
    );
  }
}

export default DatePicker;

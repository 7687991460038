import React, { Component } from 'react';
import GanttSidebar from './../../../pages/task/GanttSidebar';
import DataTable from './../../../common/dataTable';
import ActivityBoardSidebarContainer from '../../activityBoard/sidebar/ActivityBoardSidebarContainer';


//import TaskSidebar from './TaskSidebar';
class ActivityList extends Component {
    render() {

         let serchString=""
         let obs=this.props.selectedOBS;
         let startDate=this.props.startDate;
         let finishDate=this.props.finishDate
            if(obs!==undefined || (startDate!==undefined && startDate!=="") || (finishDate!==undefined && finishDate!==""))
            {
            serchString+="?"
            }

            if(obs!==undefined )
            {
            serchString+='obsId='+obs.uid.toString();
            // serchString='?obsId='+obs.uid;
            }
            /**/
            if(startDate!==undefined && startDate!=="")
            {
            serchString+='&startDate='+startDate;
            // serchString='?obsId='+obs.uid;
            }
            else if(startDate===undefined || startDate==="")
            {
                serchString+="&startDate=";
            }
            if(finishDate!==undefined && finishDate!=="")
            {
            serchString+='&finishDate='+finishDate;
            // serchString='?obsId='+obs.uid;
            }
            
            else if(finishDate===undefined || finishDate==="")
            {
                serchString+="&finishDate=";
            }


           const dtConfig = {
            endPoint: '/api/activity/teamboard'+serchString, 
            idAttribute: "uid",
            nameAttribute: "name",
            columns: [
                {id:"code", dataKey:"code", label:"ID", type:"text"}
                ,{id:"projectName", dataKey:"project.name", label:"Project", type:"text"}
                
                ,{id:"name", dataKey:"name", label:"Name", type:"text"}
                ,{id:"atCompletionUnit", dataKey:"atCompletionUnit", label:"At Completion Unit", type:"text"}

                ,{id:"task_stage_name", dataKey:"task_stage_name", label:"Stage", type:"text"}
                ,{id:"status", dataKey:"status.name", label:"Status", type:"text", color : "color.name"}
                
                ,{id:"full_name", dataKey:"full_name", label:"Owner", type:"text"}
                ,{id:"obsName", dataKey:"obsInfo.name", label:"Department", type:"text"}

                ,{id:"startDate", dataKey:"startDate", label:"Start date", type:"date"}
                ,{id:"finishDate", dataKey:"finishDate", label:"Finish Date", type:"date"}
               


            ], 
           
            sideBar: <ActivityBoardSidebarContainer />,
            view: {
                visibleColumnList: ["code", "projectName","name","atCompletionUnit","status", "startDate", "finishDate","obsName"],
                hideActionbar: true,
                hideSelectbox:true,
                
            },
        }
        return (
            <div style={{height: "100%", overflow:"auto", display:"flex"}} >
                <DataTable config={dtConfig} counter={this.props.counter}  />
            </div>
        );
    }
}

export default ActivityList;
import { toast, Slide } from "react-toastify";
import { jsx } from "slate-hyperscript";

import "./Utils.css";
import Auth from "../auth/Auth";
import HubIcon from "./hubIcon/HubIcon";
import HiDepartment from "../common/icons/HiDepartment";
import HiFilter from "../common/icons/HiFilter";
import HiUsers from "../common/icons/HiUsers";
import HiCalender from "../common/icons/HiCalendar";

export const checkFileSize = (maxSize, fileSize) => {
  return maxSize >= fileSize;
};

export const resolvePath = (object, path, defaultValue) =>
  path?.split(".")?.reduce((o, p) => (o ? o[p] : defaultValue), object);

export const parseLink = (link, record) => {
  const reps = link.match(/{(.|)*?}/gi);
  let newLink = link;

  reps.map((r) => {
    var value = resolvePath(record, r.substring(1, r.length - 1));
    newLink = newLink.replace(r, value);
  });

  return newLink;
};

export const sliceFileName = (fileName, letterCount) => {
  const slicedName =
    fileName?.length > letterCount
      ? fileName?.slice(0, letterCount) + ".."
      : fileName;

  return slicedName;
};

export const imageDetector = (fileData) => {
  const isImage =
    fileData.extension === ".svg" ||
    fileData.extension === ".png" ||
    fileData.extension === ".jpeg";

  return isImage;
};

export const date2Str = (d) => {
  if (d === null || d === "") {
    return "";
  } else {
    const x = new Date(d).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    return x !== "Invalid Date" ? x : "";
  }
};

export const compareDates = (date1, date2) => {
  const dateObj1 = new Date(date1);
  const dateObj2 = new Date(date2);

  return dateObj1.getTime() !== dateObj2.getTime();
};

export const htmlToSlate = (htmlValue) => {
  const document = new DOMParser().parseFromString(htmlValue, "text/html");
  const nodeValue = deserialize(document.body);
  return nodeValue;
};

export const deserialize = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx("text", markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes };

  switch (el.nodeName) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    case "BLOCKQUOTE":
      return jsx("element", { type: "quote" }, children);
    case "P":
      return jsx("element", { type: "paragraph" }, children);
    case "A":
      return jsx(
        "element",
        { type: "link", url: el.getAttribute("href") },
        children
      );
    case "UL":
      return jsx("element", { type: "bulleted-list" }, children);
    case "OL":
      return jsx("element", { type: "numbered-list" }, children);
    case "LI":
      return jsx("element", { type: "list-item" }, children);
    case "SPAN":
      const mention = el.getAttribute("data-mention");
      if (mention) {
        console.log("run mention");
        return jsx(
          "element",
          { type: "mention", character: { fullName: mention } },
          children
        );
      }
      break;
    default:
      return children;
  }
};

export const formatBytes = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const detectOperatingSystem = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf("Win") !== -1) {
    return "Windows";
  } else if (userAgent.indexOf("Mac") !== -1) {
    return "Mac";
  } else {
    return "Unknown"; // Handle other operating systems if needed
  }
};

export const extractColumnWidth = (column) => {
  let minWidth = 150;
  if (
    column.type === "date" ||
    column.id === "percentComplete" ||
    column.id === "status" ||
    column.id === "objectType"
  ) {
    minWidth = 100;
  } else if (column.id === "code" || column.id === "name") {
    minWidth = 240;
  }

  return minWidth;
};

export const extractProgressColor = (objectType) => {
  let trackColor = "#FBBF2633";
  let percentColor = "#FBBF26";
  if (objectType === "wbs") {
    trackColor = "#701EF633";
    percentColor = "#6716D0";
  } else if (objectType === "activity") {
    trackColor = "#35D69C33";
    percentColor = "#1CAF7A";
  } else if (objectType === "task") {
    trackColor = "#1769F133";
    percentColor = "#0C57D4";
  }

  return { trackColor, percentColor };
};

export const mergeConfigs = (target, source) => {
  for (const key in source) {
    if (typeof source[key] === "object" && !Array.isArray(source[key])) {
      if (!target[key]) {
        target[key] = {};
      }
      mergeConfigs(target[key], source[key]);
    } else {
      if (!target[key] || target[key] !== source[key]) {
        target[key] = source[key];
      }
    }
  }
};

export const extractStatusColor = (text) => {
  let bgColor = "#fff";
  let textColor = "#000000";
  if (text === "In Progress") {
    bgColor = "rgba(236, 144, 6, 0.20)";
    textColor = "rgba(236, 144, 6, 1)";
  } else if (text === "Completed") {
    bgColor = "rgba(58, 180, 93, 0.20)";
    textColor = "rgba(58, 180, 93, 1)";
  } else if (text === "Not Started") {
    bgColor = "#fff";
    textColor = "#B8B8B8";
  }

  return { bgColor, textColor };
};

export const renderFilterIcon = (id) => {
  if (id === "obs") {
    return (
      <HubIcon>
        <HiDepartment />
      </HubIcon>
    );
  } else if (id === "date") {
    return (
      <HubIcon>
        <HiCalender />
      </HubIcon>
    );
  } else if (id === "resource") {
    return (
      <HubIcon>
        <HiUsers />
      </HubIcon>
    );
  } else {
    <HubIcon>
      <HiFilter />
    </HubIcon>;
  }
};

export class DateUtils {
  Date2Str = (d) => {
    if (d === null || d === "") {
      return "";
    } else {
      const x = new Date(d).toLocaleDateString("tr-tr", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      return x !== "Invalid Date" ? x : "";
    }
  };

  Date2MonthDay = (d) => {
    const x = new Date(d).toLocaleDateString("en-en", {
      month: "short",
      day: "numeric",
    });
    return x !== "Invalid Date" ? x : "";
  };
  //
  Date2StrNew = (d) => {
    const x = new Date(d).toLocaleDateString("en-en", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    return x !== "Invalid Date" ? x : "";
  };
  //2022-11-25
  Date2StrWithLine = (d) => {
    const x = new Date(d).toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    });
    return x !== "Invalid Date" ? x : "";
  };

  Date2StrHour = (d) => {
    const x = new Date(d).toLocaleDateString("en-en", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return x !== "Invalid Date" ? x : "";
  };

  Date2StrMonthDayHour = (d) => {
    const x = new Date(d).toLocaleDateString("en-en", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
    return x !== "Invalid Date" ? x : "";
  };

  Date2StrOnlyDay = (d) => {
    const x = new Date(d).toLocaleDateString("en-en", {
      day: "numeric",
      hour12: false,
    });
    return x !== "Invalid Date" ? x : "";
  };

  Date2StrOnlyMonthShort = (d) => {
    const x = new Date(d).toLocaleDateString("en-en", {
      month: "short",
      hour12: false,
    });
    return x !== "Invalid Date" ? x : "";
  };

  // İki Tarih arası gün hesaplar
  getDifferenceInDays = (date1, date2) => {
    //  console.log(date2)
    const diffInMs = Math.abs(new Date(date2) - new Date(date1));
    //console.log("diffInMs")
    //console.log(diffInMs)
    //console.log(this.Date2Str(Date.now()))
    return diffInMs / (1000 * 60 * 60 * 24);
  };

  getDifferenceInMonths = (date1_p, date2_p) => {
    const date1 = new Date(date1_p);
    const date2 = new Date(date2_p);

    return (
      (date2.getFullYear - date1.getFullYear) * 12 +
      (date2.getMonth - date1.getMonth)
    );
  };

  // Bugün ile verilen Tarih arası gün hesaplar
  getDifferenceToday = (date1) => {
    // console.log("mahmut: ")
    const diffInMs = new Date(Date.now()) - new Date(date1);

    return (diffInMs / (1000 * 60 * 60 * 24)).toFixed(0);
  };

  //Yüzdeyi hesaplar
  calculatePercent = (number1, number2) => {
    var result;
    //console.log(number1)
    if (number1 === undefined || number2 === undefined || number2 === 0) {
      //console.log("ife girdik")
      return 0;
    }
    if ((number1 / number2) * 100 >= 100) {
      result = 100;
    } else {
      result = ((number1 / number2) * 100).toFixed(0);
    }

    return result;
  };

  getPercentagesBeetwenDates = (date1, date2) => {
    return this.calculatePercent(
      this.getDifferenceToday(date1),
      this.getDifferenceInDays(date1, date2)
    );
  };

  getMonths = (startDate, endDate) => {
    var resultList = [];
    var date = startDate;
    var monthNameList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    while (date <= endDate) {
      var stringDate =
        monthNameList[date.getMonth()] + " " + date.getFullYear();

      //get first and last day of month
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      resultList.push({
        str: stringDate,
        first: firstDay,
        last: lastDay,
      });
      date.setMonth(date.getMonth() + 1);
    }

    return resultList;
  };

  getMonday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  };

  getWeeks = (startDate, endDate) => {
    var resultList = [];
    var date = this.getMonday(startDate);
    var monthNameList = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    while (date <= endDate) {
      var stringDate =
        date.getDate().toString() +
        " " +
        monthNameList[date.getMonth()] +
        " " +
        date.getFullYear();

      //get first and last day of month
      var lastDay = new Date(date);
      lastDay.setDate(date.getDate() + 6);

      resultList.push({
        str: stringDate,
        first: date,
        last: lastDay,
      });
      date.setDate(date.getDate() + 7);
    }

    return resultList;
  };
}

export const formatDecimal = (value) => {
  // Check if the value has decimal places
  if (value % 1 !== 0) {
    // Round to 2 decimal places
    return Math.round(value * 100) / 100;
  } else if (!!value || value === 0) {
    // If no decimal places, return the value as is
    return value;
  }
};

export class Utils {
  findGetParameter = (p) => {
    // http://www.google.com.au?token=123 url'inden token değerini almak için const token = findGetParameter.get('token')
    const query = new URLSearchParams(window.location.search);
    return query.get(p);
  };

  //Task kopyalama
  copyTask = (taskList) => {
    localStorage.setItem("copiedTasks", JSON.stringify(taskList));
  };

  copyTaskObject = (taskList) => {
    localStorage.setItem("copiedTasksObject", JSON.stringify(taskList));
  };

  getCopyTaskList() {
    return JSON.parse(localStorage.getItem("copiedTasks"));
  }

  getCopyTaskListObject() {
    return JSON.parse(localStorage.getItem("copiedTasksObject"));
  }

  removeCopyTaskList() {
    return localStorage.removeItem("copiedTasks");
  }

  removeCopyTaskListObject() {
    return localStorage.removeItem("copiedTasksObject");
  }

  // !* Kullanıcı için tanımlanmış user viewları çeker.
  // !* var ise json olarak döner yoksa null döner.
  // !* http://localhost:8082/api/userview/test
  //
  getUserView = async (pageCode, projectId) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    //  console.log(obs)

    let view = null;

    await fetch("/api/userview/" + pageCode, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log("r",pageCode,r)

        if (r.status === 200) {
          if (r.body.status !== 204) {
            let general = undefined;

            //console.log("general",r?.body?.object?.find(x=>x.pageCode===pageCode))
            if (r?.body?.object?.find((x) => x.pageCode === pageCode)) {
              general = JSON.parse(
                r?.body?.object?.find((x) => x.pageCode === pageCode)?.view
              );
            }

            // console.log("general",general)
            view = {};
            if (
              r?.body?.object?.find(
                (x) => x.pageCode !== pageCode && x.pageCode.includes(projectId)
              )?.view
            ) {
              let project = JSON.parse(
                r?.body?.object?.find(
                  (x) =>
                    x.pageCode !== pageCode && x.pageCode.includes(projectId)
                )?.view
              ); //.find(x=>x.code.includes(projectId.toString))
              view.projectView = project;
            }

            //console.log("project",project)

            view.general = general;
          }

          //console.log("r",view,r)

          //view=r.body.status!==204? :null;
        }
      });

    //console.log("common view",view)
    return view;
  };

  // !* Kullanıcı için tanımlanmış user viewları update veya create eder.
  updateUserView = (pageCode, view) => {
    let body = {};
    body.pageCode = pageCode;
    body.view = JSON.stringify(view);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(body),
    };

    fetch("/api/userview", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log("requestOptions",requestOptions);
        //console.log(r);
        if (r.status === 200) {
          //this.notify("success","Updated")
          //  console.log("User View Updated",r);
        } else {
          console.log("User View Not Updated", r);
        }
      });
  };

  addNotification = (notificationType, text) => {
    switch (notificationType) {
      case "success":
        toast.success(text, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
          progress: undefined,
          transition: Slide,
        });
        break;
      case "error":
        toast.error(text, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          closeOnClick: true,
          closeButton: false,
          progress: undefined,
          hideProgressBar: false,
          transition: Slide,
        });
        break;
      default:
      case "success":
        toast.info(text, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
          progress: undefined,
          transition: Slide,
        });
        break;
        break;
    }
  };
}

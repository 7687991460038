import React, { Component } from 'react';
import './MetricChart.css';
import Widget from './../Widget'
import MetricChartRunner from './metricChartRunner';

class MetricChart extends Component {
    
    /* 
        props  
            header: üst metin 
            value: string olarak değer
            subtext:  alttaki ifade
    */

            


    render() {
        return (
            <Widget title={this.props.config.title}  link = {this.props.config.link} info={this.props.config.info} icon={this.props.config.icon}>
                
                    <MetricChartRunner config={this.props.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
            

            </Widget>
           
        );
    }
}

export default MetricChart;
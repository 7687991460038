import React, { Component } from 'react';

class TextField extends Component {

    render() {
        return (
            <div className="hub-textField" style={{width:(this.props.pct?this.props.pct:'20')+"%"}}>
                <div className="label">{this.props.label}</div>
                <div className={this.props.disabled?"formInput disabled":"formInput"}><input disabled={this.props.disabled?true:false} value={this.props.value===null?'':this.props.value} onChange={(e)=>this.props.onChange(this.props.changeArea,e.target.value)} /></div>
            </div>
        );
    }

}

export default TextField;
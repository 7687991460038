import React, { Component } from 'react';
import {faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function format(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${year}-${month}-${date}`;
  }


  Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  }
  

  
class WeeklyPicker extends Component {

    constructor(props){ 
        super(props);
        const now = new Date();
        const nextDate = new Date();
        nextDate.setMonth(nextDate.getMonth()+1);
        this.period = "weekly";
        
        let d = new Date();
        var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
        d.setDate(diff);


        this.months = [
            {monthId: 1, monthIdStr:"01", monthLabel:"Jan", monthName:"January"},
            {monthId: 2, monthIdStr:"02", monthLabel:"Feb", monthName:"February"},
            {monthId: 3, monthIdStr:"03", monthLabel:"Mar", monthName:"March"},
            {monthId: 4, monthIdStr:"04", monthLabel:"Apr", monthName:"April"},
            {monthId: 5, monthIdStr:"05", monthLabel:"May", monthName:"May"},
            {monthId: 6, monthIdStr:"06", monthLabel:"Jun", monthName:"June"},
            {monthId: 7, monthIdStr:"07", monthLabel:"Jul", monthName:"July"},
            {monthId: 8, monthIdStr:"08", monthLabel:"Aug", monthName:"August"},
            {monthId: 9, monthIdStr:"09", monthLabel:"Sep", monthName:"September"},
            {monthId: 10, monthIdStr:"10", monthLabel:"Oct", monthName:"October"},
            {monthId: 11, monthIdStr:"11", monthLabel:"Nov", monthName:"November"},
            {monthId: 12, monthIdStr:"12", monthLabel:"Dec", monthName:"December"}
          ];

          
        // ay günleri
          

        this.state={
            firstPeriod: now.getFullYear().toString()+"-"+(now.getMonth() + 1).toString().padStart(2, "0"),
            firstPeriodString: this.months[now.getMonth()].monthName + " " + now.getFullYear().toString(),
            firstPeriodDays: this.getDaysArray(now.getMonth()+1, now.getFullYear()),
            secondPeriod: nextDate.getFullYear().toString()+"-"+(nextDate.getMonth() + 1).toString().padStart(2, "0"),
            secondPeriodString: this.months[nextDate.getMonth()].monthName + " " + nextDate.getFullYear().toString(),
            secondPeriodDays: this.getDaysArray(nextDate.getMonth()+1, nextDate.getFullYear()),
            currentPeriod: d.getFullYear().toString()+"-"+(d.getMonth() + 1).toString().padStart(2, "0")+"-"+(d.getDate()).toString().padStart(2, "0"),
            hover: "",
            limit: 24 // En fazla kaç dönem seçileceği*
        }
        //console.log(this.state);
    }

    nextPeriod = () => {
        this.setPeriod(1);
    }
    
    getDaysArray = (month, year) => {
        // month değişkeni ayın değerinin bir eksiği verilmeMElidir. JS getMonth ile alınan değer +1 ile iletilmelidir.
        
        //console.log(year,month);
        //console.log(new Date(year, month, 0));
        
        let monLen = new Date(year, month, 0).getDate(); // Ay uzunluğu

        let firstDay = new Date(year, month-1, 1); // Ayın ilk günü
        let firstWeekDay = firstDay.getDay()>0?firstDay.getDay()-1:6;
        //console.log("monLen", monLen);
        //console.log("firstWeekDay", firstWeekDay);

        let arrSize = Math.ceil((monLen+firstWeekDay)/7.0);

        let rows = []; 
        let dayInd = 0;
        let dayNum = 1;
        let startWeek = (firstDay.getWeek()+1)%52;
        for (let i = 0; i < arrSize; i++) {
            let days = [];
            let weekDateKey = "";
            let weekEndDateKey= "";
            for (let j = 0; j < 7; j++) {
                if(dayInd<firstWeekDay || (dayInd+1-firstWeekDay)>monLen) {
                    days.push(null);

                } else {
                    days.push(dayNum);
                    weekDateKey = weekDateKey===""?(year.toString() + "-" + month.toString().padStart(2, "0")+"-"+dayNum.toString().padStart(2, "0")):weekDateKey;
                    dayNum++;

                   //Bir sonraki haftanın başı
                    weekEndDateKey = (year.toString() + "-" + month.toString().padStart(2, "0")+"-"+dayNum.toString().padStart(2, "0"));
                    if(dayNum>monLen)
                    {
                        weekEndDateKey = (year.toString() + "-" + (month+1).toString().padStart(2, "0")+"-01");
                    }
                    
                    
                }
                dayInd++;
            }
            rows.push({week: startWeek, weekDateKey:weekDateKey,weekEndDateKey:weekEndDateKey, days: days});
            startWeek++;
        }

        return rows;
    }

    prevPeriod = () => {
        this.setPeriod(-1);
        //this.setState({...this.state, startPeriod:this.state.startPeriod-1})
    }

    setPeriod = (i) =>  {

        // Tarih sınırı

        let dt = new Date(this.state.firstPeriod);
        dt.setMonth(dt.getMonth()+i);
        let dt2 = new Date(dt);
        dt2.setMonth(dt2.getMonth()+1);

        this.setState({...this.state, firstPeriod: dt.getFullYear().toString()+"-"+(dt.getMonth() + 1).toString().padStart(2, "0"),
        firstPeriodString: this.months[dt.getMonth()].monthName + " " + dt.getFullYear().toString(),
        firstPeriodDays: this.getDaysArray(dt.getMonth()+1, dt.getFullYear()),
        secondPeriod: dt2.getFullYear().toString()+"-"+(dt2.getMonth() + 1).toString().padStart(2, "0"),
        secondPeriodString: this.months[dt2.getMonth()].monthName + " " + dt2.getFullYear().toString(),
        secondPeriodDays: this.getDaysArray(dt2.getMonth()+1, dt2.getFullYear())})

    }

    selectDate = (d) => {
        this.props.selectDate(d);
    } 

    hoverDate = (d) => {
        this.setState({...this.state, hover:d})
    }

    clearHover = () => {
        this.setState({...this.state, hover:""})
    }

    render() {
        // Tarih sınırı
        let dateLimit = "9999-99-99";
        if ( this.props.startDate !== "" ) {
            // 48 olarak ekliyorum.
            let dt  = new Date(this.props.startDate);
            var newDate = new Date(dt.setDate(dt.getDate()+(this.state.limit-1)*7));
            dateLimit = format(newDate);
        }
        
        //console.log("weekly",this.state)
        return (
            <>
            <div className="pickerWrapper">
                <div className="pickerColumn" style={{width:196}}>
                    <div className="periodSelector">
                        <div className="icon" onClick={this.prevPeriod}><FontAwesomeIcon icon={faChevronLeft} size="sm"/> </div>
                        <div className="label">{this.state.firstPeriodString}</div> 
                    </div>    
                    <div className="periodBody" style={{marginTop:8, gap:0}} >
                        <div className="headerRow">
                            <div>W</div>
                            <div style={{width:24}}>Mo</div>
                            <div style={{width:24}}>Tu</div>
                            <div style={{width:24}}>We</div>
                            <div style={{width:24}}>Th</div>
                            <div style={{width:24}}>Fr</div>
                            <div style={{width:24}}>Sa</div>
                            <div style={{width:24}}>Su</div>
                        </div>
                        {this.state.firstPeriodDays.map(i=>{
                             //let dateKey = i.weekDateKey;
                             let dateKey = i.weekEndDateKey;

                             let isHovered = "";
                             let isSelected = "";
                             let isSelectedFinish = "";
                             let isDisabled = "";

                             if(this.props.activeLayout === this.period) {

                                 // Eğer değer seçili ise o değer esas alınır, eğer seçili değilse dateRange'deki daha önce seçili değer esas alınır.
                                 let compStart = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.start:this.props.startDate;
                                 let compFinish = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.finish:this.props.finishDate;
 
                                 isHovered = (((compStart!=="" && compFinish==="" && compStart<dateKey&&this.state.hover>=dateKey && dateKey < dateLimit)||(compStart<dateKey&&compFinish>=dateKey))?" rowHovered":"");
                                 isSelected =  (compStart===dateKey?" rowSelected":"");
                                 isSelectedFinish =  (compFinish===dateKey?" rowSelectedFinish":"");
                                 isDisabled = (dateLimit<dateKey)?" disabled": "";
                             }

                            return (<div className="row" style={{height:28}}><div 
                            onMouseOver={()=>{this.hoverDate(dateKey)}} 
                            onClick={()=>{if(dateLimit>=dateKey){this.selectDate(dateKey)}}}  
                            className={"monthItem" + isSelected + isHovered + isSelectedFinish + isDisabled}
                            style={{width:"100%", height:28, lineHeight:"28px" }}
                            >
                                <div className={"cellItem rowHeader"+ ((this.state.currentPeriod===dateKey)?" today":"")}
                                style={{width:28, height:28, lineHeight:"28px", border:0}}>{i.week}</div>
                                
                                {i.days.map((j, ind)=>{

                                return (<div className="cellItem" 
                                        style={{width:24, height:28, lineHeight:"28px", border:0 }}>{j}</div>)

                            })}</div></div>)
                        })}
                    </div>
                </div>
                {/** SECOND COLUMN */}
                <div className="pickerColumn" style={{width:196}}>
                    <div className="periodSelector">
                        <div className="label">{this.state.secondPeriodString}</div> 
                        <div className="icon" onClick={this.nextPeriod}><FontAwesomeIcon icon={faChevronRight} size="sm"/> </div>
                    </div>   
                    <div className="periodBody" style={{marginTop:8, gap:0}} >
                        <div className="headerRow">
                            <div>W</div>
                            <div style={{width:24}}>Mo</div>
                            <div style={{width:24}}>Tu</div>
                            <div style={{width:24}}>We</div>
                            <div style={{width:24}}>Th</div>
                            <div style={{width:24}}>Fr</div>
                            <div style={{width:24}}>Sa</div>
                            <div style={{width:24}}>Su</div>
                        </div>
                        {this.state.secondPeriodDays.map(i=>{
                             //let dateKey = i.weekDateKey;
                             let dateKey = i.weekEndDateKey;
                             let isHovered = "";
                             let isSelected = "";
                             let isSelectedFinish = "";
                             let isDisabled = "";

                             if(this.props.activeLayout === this.period) {

                                 // Eğer değer seçili ise o değer esas alınır, eğer seçili değilse dateRange'deki daha önce seçili değer esas alınır.
                                 let compStart = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.start:this.props.startDate;
                                 let compFinish = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.finish:this.props.finishDate;
 
                                 isHovered = (((compStart!=="" && compFinish==="" && compStart<dateKey&&this.state.hover>=dateKey && dateKey < dateLimit)||(compStart<dateKey&&compFinish>=dateKey))?" rowHovered":"");
                                 isSelected =  (compStart===dateKey?" rowSelected":"");
                                 isSelectedFinish =  (compFinish===dateKey?" rowSelectedFinish":"");
                                 isDisabled = (dateLimit<dateKey)?" disabled": "";
                             }

                            return (<div className="row" style={{height:28}}><div 
                            onMouseOver={()=>{this.hoverDate(dateKey)}} 
                            onClick={()=>{if(dateLimit>=dateKey){this.selectDate(dateKey)}}}  
                            className={"monthItem" + isSelected + isHovered + isSelectedFinish + isDisabled}
                            style={{width:"100%", height:28, lineHeight:"28px" }}
                            >
                                <div className={"cellItem rowHeader"+ ((this.state.currentPeriod===dateKey)?" today":"")}
                                style={{width:28, height:28, lineHeight:"28px", border:0}}>{i.week}</div>
                                
                                {i.days.map((j, ind)=>{

                                return (<div className="cellItem" 
                                        style={{width:24, height:28, lineHeight:"28px", border:0 }}>{j}</div>)

                            })}</div></div>)
                        })}
                    </div>
                </div>
            </div>
            <div className="footNote" style={{lineHeight:"26px", height:26}}>You can choose up to {this.state.limit} weeks</div>
            </>
        );
    }
}

export default WeeklyPicker;
import React, { useEffect, useState } from "react";
import { Resizable } from "re-resizable";

import "./TH.scss";
import HubIcon from "../../hubIcon/HubIcon";
import HiSort from "../../icons/HiSort";

/**
 * TH is a table header cell component that supports column resizing and sorting. It allows
 * customization of the header cell's appearance and behavior, such as setting the minimum width,
 * handling resizing events, and toggling sort states.
 *
 * @param {object} props The component props.
 * @param {React.ReactNode} props.children The content to be displayed inside the header cell.
 * @param {object} [props.style] Additional inline styles to apply to the header cell.
 * @param {object} props.column The column configuration object, including properties like `label` and `width`.
 * @param {number} [props.minWidth] The minimum width that the column can be resized to.
 * @param {function} props.onResizeColumn Callback function triggered when the column is resized. Receives the column ID and the new width as arguments.
 * @param {function} props.setSortedColumn Callback function to set the currently sorted column.
 * @param {string} [props.sortedColumn] The label of the currently sorted column.
 * @param {object} [props.provided] Provided object from `react-beautiful-dnd` for drag-and-drop functionality.
 * @param {object} [props.snapshot] Snapshot object from `react-beautiful-dnd` to represent the drag-and-drop state.
 *
 * @returns {JSX.Element} The rendered TH component.
 *
 * @example
 * // Render a resizable and sortable table header cell
 * <TH
 *   column={{ id: 'name', label: 'Name', width: 150 }}
 *   minWidth={100}
 *   onResizeColumn={(id, width) => console.log(`Column ${id} resized to ${width}px`)}
 *   setSortedColumn={(label) => console.log(`Sorted by ${label}`)}
 *   sortedColumn="Name"
 * >
 *   Name
 * </TH>
 */
const TH = ({
  children,
  style,
  column,
  minWidth,
  onResizeColumn,
  setSortedColumn,
  sortedColumn,
  provided,
  snapshot,
}) => {
  const [isResizing, setIsResizing] = useState(false);
  const [sort, setSort] = useState("default");
  const [isShownArrow, setIsShownArrow] = useState(false);

  useEffect(() => {
    sortedColumn && setIsShownArrow(sortedColumn === column?.label);
    sortedColumn !== column?.label && setSort("default");
  }, [sortedColumn]);

  const handleResizeStart = () => {
    setIsResizing(true);
  };

  const handleResizeStop = () => {
    setIsResizing(false);
  };

  const handleShowArrow = () => {
    setIsShownArrow(true);
  };

  const handleHideArrow = () => {
    if (sortedColumn !== column?.label || sort === "default") {
      setIsShownArrow(false);
    }
  };

  const handleClickCell = () => {
    if (sort === "default") {
      setSortedColumn(column?.label);
      setSort("asc");
      setIsShownArrow(true);
    } else if (sort === "asc") {
      setSortedColumn(column?.label);
      setSort("desc");
      setIsShownArrow(true);
    } else if (sort === "desc") {
      setSort("default");
      setSortedColumn(null);
    }
  };

  return (
    <Resizable
      enable={{
        top: false,
        right: true,
        bottom: false,
        left: false,
        topRight: false,
        bottomRight: false,
        bottomLeft: false,
        topLeft: false,
      }}
      minWidth={minWidth}
      size={{ width: column?.width ?? 120 }}
      onResizeStart={handleResizeStart}
      onResizeStop={handleResizeStop}
      onResize={(e, direction, ref, d) => {
        const newWidth = ref?.style.width.replace("px", "");
        onResizeColumn(column?.id, +newWidth);
      }}
      handleClasses={{
        right: `hub-dt-th-resizer ${isResizing ? "resizing" : ""}`,
      }}
    >
      <div
        onMouseEnter={handleShowArrow}
        onMouseLeave={handleHideArrow}
        onClick={handleClickCell}
        className={`hub-dt-th ${snapshot?.isDragging ? "dragging-header" : ""}`}
        style={{ ...style }}
        ref={provided?.innerRef}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}
      >
        {children}
        {isShownArrow && (
          <span className={`sort-${sort}`}>
            <HubIcon fontSize={12}>
              <HiSort />
            </HubIcon>
          </span>
        )}
      </div>
    </Resizable>
  );
};

export default TH;

import React from "react";

import "./Tag.scss";

const Tag = ({ tagColor, collapsed, tagName }) => {
  return (
    <div
      className={"hub-tag-body " + tagColor + (collapsed ? " collapsed" : "")}
    >
      <div className="hub-card-tag-label">{tagName}</div>
    </div>
  );
};

export default Tag;

import React, { Component } from "react";
import { DateUtils } from "../Commons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import WbsGanttBar from "./WbsGanttBar";
import ActivityGanttBar from "./ActivityGanttBar";
import TaskGanttBar from "./TaskGanttBar";
class GanttItem extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorpoint: {}, showSummary: false };
  }

  hasBaseline = () => {
    return (
      this.props.data.ganttBlWidth !== undefined &&
      this.props.data.ganttBlWidth !== null &&
      this.props.data.ganttBlLeft !== undefined &&
      this.props.data.ganttBlLeft !== null
    );
  };

  getBaseline = () => {
    if (this.hasBaseline()) {
      if (!this.props.isMilestone) {
        return (
          <div
            onMouseMove={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            className={
              this.props.data.objectTypeInfo.code === "wbs"
                ? "hub-gantt-item-wbs-baseline"
                : "hub-gantt-item-baseline " + this.props.color
            }
            style={{
              width: this.props.data.ganttBlWidth,
              left: this.props.data.ganttBlLeft,
              height: 4,
            }}
          ></div>
        );
      } else {
        return (
          <div
            onMouseMove={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            className={"hub-gantt-milestone-baseline " + this.props.color}
            style={{ left: this.props.data.ganttBlLeft }}
          ></div>
        );
      }
    } else {
      return false;
    }
  };

  getSummary = () => {
    let du = new DateUtils();
    if (this.state.showSummary) {
      return (
        <div
          className={"hub-gantt-summary"}
          style={{
            top: this.state.anchorpoint.y + 5,
            left: this.state.anchorpoint.x + 5,
          }}
        >
          <div className="hub-gantt-summary-header">{this.props.data.name}</div>
          <div className="hub-gantt-summary-body-wrapper">
            <div className="hub-gantt-summary-body-items">
              <div className="hub-gantt-summary-body-item-title">Progress</div>
              <div className="hub-gantt-summary-body-item-value">
                {parseInt(this.props.data.percentComplete)}%
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="hub-gantt-summary-body-items">
                <div className="hub-gantt-summary-body-item-title">Start</div>
                <div className="hub-gantt-summary-body-item-value">
                  {du.Date2Str(this.props.data.startDate)}
                </div>
              </div>

              <FontAwesomeIcon
                icon={faArrowRight}
                color={"black"}
                size={"2x"}
              />

              <div className="hub-gantt-summary-body-items">
                <div className="hub-gantt-summary-body-item-title">Finish</div>
                <div className="hub-gantt-summary-body-item-value">
                  {du.Date2Str(this.props.data.finishDate)}
                </div>
              </div>
            </div>

            {this.hasBaseline() ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="hub-gantt-summary-body-items">
                  <div className="hub-gantt-summary-body-item-title">
                    {" "}
                    {this.props.data.objectTypeInfo.code === "task"
                      ? "Planned"
                      : "Baseline"}{" "}
                    Start
                  </div>
                  <div className="hub-gantt-summary-body-item-value">
                    {du.Date2Str(
                      this.props.data.objectTypeInfo.code === "task"
                        ? this.props.data.plannedStart
                        : this.props.data.p6Plan?.baselineStart
                    )}
                  </div>
                </div>
                <FontAwesomeIcon
                  icon={faArrowRight}
                  color={"black"}
                  size={"2x"}
                />
                <div className="hub-gantt-summary-body-items">
                  <div className="hub-gantt-summary-body-item-title">
                    {this.props.data.objectTypeInfo.code === "task"
                      ? "Planned"
                      : "Baseline"}{" "}
                    Finish
                  </div>
                  <div className="hub-gantt-summary-body-item-value">
                    {" "}
                    {du.Date2Str(
                      this.props.data.objectTypeInfo.code === "task"
                        ? this.props.data.plannedFinish
                        : this.props.data.p6Plan?.baselineFinish
                    )}
                  </div>
                </div>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
      );
    } else {
      return false;
    }
  };

  getGanttBar = () => {
    if (this.props.data.objectTypeInfo?.code === "wbs") {
      return (
        <WbsGanttBar
          data={this.props.data}
          onMouseOver={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          isSelectedRow={this.props.isSelectedRow}
        />
      );
    } else if (this.props.data.objectTypeInfo?.code === "activity") {
      return (
        <ActivityGanttBar
          data={this.props.data}
          onMouseOver={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          isSelectedRow={this.props.isSelectedRow}
        />
      );
    } else if (this.props.data.objectTypeInfo?.code === "task") {
      return (
        <TaskGanttBar
          data={this.props.data}
          onMouseOver={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          ganttBaselineWidth={this.props.data.ganttBlWidth}
          ganttBaselineLeft={this.props.data.ganttBlLeft}
        />
      );
    }
  };

  handleMouseOver = (e) => {
    this.setState({
      ...this.state,
      anchorpoint: { x: e.pageX, y: e.pageY },
      showSummary: true,
    });
  };

  handleMouseLeave = (e) => {
    this.setState({ ...this.state, anchorpoint: {}, showSummary: false });
  };

  render() {
    /* console.log("props", this.props); */

    let gantItem = (
      <>
        {/*   {this.props.isMilestone ? (
          <div
            onMouseMove={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            id={"hub-gantt-milestone"}
            className={"hub-gantt-milestone " + this.props.color}
            style={{ left: this.props.data.ganttLeft - 9 }}
          ></div>
        ) : (
          this.getGanttBar()
        )} */}
        {this.getGanttBar()}
        {/*  {this.getBaseline()} */}
        {this.getSummary()}
      </>
    );

    return gantItem;
  }
}

export default GanttItem;

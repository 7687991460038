import React, { Component } from 'react';
import ProjectTileMetricPie from './ProjectTileMetricPie';
import ProjetTileTimeline from './ProjetTileTimeline';
import ProjectTimeMetricMilestone from './ProjectTimeMetricMilestone';
import ProjectTileToolbar from './ProjectTileToolbar';

import {DateUtils} from './../../common/Commons'
import {Link} from 'react-router-dom';

class ProjectTile extends Component {
    render() {
        let util = new DateUtils();
        const p = this.props.project;
        const startDate=p.startDate;
        const finishDate=p.finishDate


        const dateDiff=util.getPercentagesBeetwenDates(startDate,finishDate);

  
        const efforSpent=util.calculatePercent(p.p6Plan?.actualUnits,p.p6Plan?.plannedUnits);

        const nextMilestone=p.nextMilestoneDate!==null?p.nextMilestoneDate:p.p6Plan?.actualFinish!==null?p.p6Plan?.actualFinish:p.p6Plan?.plannedFinish
        //console.log(p)
           // console.log(efforSpent)
        
        return (
            <Link to={"/project/"+p.uid+"/wbs"}>
            <div className="hub-projectTile-tileBox">
                <div className={"hub-projectTile-tile "+p.color?.code}>
                   <div className={"hub-projectTile-tileHeader "+p.color?.code}> 
                       <h4>{p.name}</h4>
                       <span>{p.code}</span>
                   </div>
                   <div className="hub-projectTile-tileBody">
                       <ProjetTileTimeline startDate={util.Date2Str(startDate)} finishDate={util.Date2Str(finishDate)} percentComplete={dateDiff} color={p.color?.code} project={p}/>
                       <div className="hub-projectTile-metrics"> 
                            {/*<ProjectTileMetricPie value={efforSpent} label="EFFORT SPENT" color="#0d736a" /> 
                            <ProjectTileMetricPie value={p.percentComplete===null?0:p.percentComplete} label="% COMPLETE" color="#6e665b"/> */}
                            <ProjectTimeMetricMilestone value="28.0" month={util.Date2StrOnlyMonthShort(nextMilestone).toUpperCase()} day={util.Date2StrOnlyDay(nextMilestone)} label="Next Milestone" color="#864cb0"/>
                       </div>
                   </div>
                </div>
            </div>
            </Link>
        );
    }
}

export default ProjectTile;
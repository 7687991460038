import React from "react";

const HiServer = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M3.50008 5.16671H3.50591M3.50008 9.83337H3.50591M3.50008 7.50004H10.5001M3.50008 7.50004C2.21142 7.50004 1.16675 6.45537 1.16675 5.16671C1.16675 3.87804 2.21142 2.83337 3.50008 2.83337H10.5001C11.7887 2.83337 12.8334 3.87804 12.8334 5.16671C12.8334 6.45537 11.7887 7.50004 10.5001 7.50004M3.50008 7.50004C2.21142 7.50004 1.16675 8.54471 1.16675 9.83337C1.16675 11.122 2.21142 12.1667 3.50008 12.1667H10.5001C11.7887 12.1667 12.8334 11.122 12.8334 9.83337C12.8334 8.54471 11.7887 7.50004 10.5001 7.50004"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiServer;

import React, { Component } from "react";
import DataTable from "../../common/dataTable";
import OtherWorkAssignmentExpanded from "./OtherWorkAssignmentExpanded";
import MainButton from "./../../common/buttons/MainButton";
import Modal from "./../../common/modal";

import { faArrowsMaximize } from "@fortawesome/pro-light-svg-icons";
import HiExpand from "../../common/icons/HiExpand";

class OtherWorkAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  showModal = () => {
    this.setState({ ...this.state, showModal: true }, () => {
      document
        .getElementById("root")
        .appendChild(document.getElementById("hub-modalWrapper"));
    });
  };

  hideModal = () => {
    this.setState({ ...this.state, showModal: false });
  };

  render() {
    //console.log(this.props)
    const dtConfig = {
      endPoint:
        "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/task/" +
        this.props.selectedRecord.uid +
        "/assignment",
      columns: [
        ,
        {
          id: "ownerInfo",
          dataKey: "owner.fullName",
          label: "Owner",
          type: "text",
        },
        {
          id: "roleInfo",
          dataKey: "role.name",
          label: "Assignment Role",
          type: "text",
        },
        {
          id: "startDate",
          dataKey: "startDate",
          label: "Start Date",
          type: "date",
        },
        {
          id: "finishDate",
          dataKey: "finishDate",
          label: "Finish Date",
          type: "date",
        },
        {
          id: "actuals",
          dataKey: "actualUnit",
          label: "Actual Units",
          type: "hour",
        },
        {
          id: "etc",
          dataKey: "remainingUnit",
          label: "Remaining Units",
          type: "hour",
        },
      ],
      idAttribute: "uid",
      nameAttribute: "name",
      view: {
        visibleColumnList: [
          "code",
          "ownerInfo",
          "roleInfo",
          "etc",
          "startDate",
          "finishDate",
          "actuals",
        ],
        mode: "list",
        hideToolbar: true,
        hideActionbar: true,
        hideSelectbox: true,
        isSmallTable: true,
      },
    };

    return (
      <>
        <div className="hub-table-wrapper">
          <DataTable
            key="OWAssignmentTable"
            config={dtConfig}
            onExpand={this.showModal}
            isSidebarTable
          />
        </div>

        <Modal
          key="OWAssignmentTable-modal"
          title={this.props.selectedRecord.name}
          show={this.state.showModal}
          onClose={this.hideModal}
          height={"80%"}
          width={"90%"}
        >
          {/*  <DataTable key="OWAssignmentTable" config={dtConfig}  />*/}
          <OtherWorkAssignmentExpanded
            selectedRecord={this.props.selectedRecord}
          />
        </Modal>
      </>
    );
  }
}

export default OtherWorkAssignments;

import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

import Tab from '../../common/tabs/Tab';
import TabGroup from '../../common/tabs/TabGroup';

import TabContentGroup from '../../common/tabs/TabContentGroup';
import TabContent from '../../common/tabs/TabContent';
import SideBarWbsDetails from './SideBarWbsDetails'
import SideBarWbsStatus from './SideBarWbsStatus'
import SideBarActivityDetails from './SideBarActivityDetails'
import SideBarActivityStatus from './SideBarActivityStatus'
import SideBarTaskDetails from './SideBarTaskDetails'
import TaskAssignments from './TaskAssignments'
import NewTask from './NewTask'

import TaskSideNotes from './TaskSideNotes'
import SideBarTaskStatus from './SideBarTaskStatus'

import SideBarActivityAssignments from './SideBarActivityAssignments';

import SideBarTaskRecurrence from './SideBarTaskRecurrence';

import NewWbs from './newObjects/newWbs';
import NewActivity from './newObjects/newActivity';
class GanttSidebar extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            activePage: "wbs",
            activePageGroup:"wbs",
            loading:true
        }



    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }
/**/
    componentDidMount()
    {
      //console.log("componentDidMount")
        this.setState({...this.state, sidebarType:this.props.sidebarType, activePage:this.props.newCreate?"newTask":this.props.sidebarType===""?this.props.selectedRecord?.objectTypeInfo?.code:this.props.sidebarType, activePageGroup:this.props.selectedRecord?.objectTypeInfo?.code,loading:false});
    }

    componentDidUpdate(prevProps)
    {

       //console.log("componentDidUpdate",this.props.sidebarType,prevProps.sidebarType)
       //console.log(this.state)
        if(prevProps.selectedRecord!==this.props.selectedRecord  )
        {

           //console.log("selectedRecord")
         
            this.setState({...this.state,sidebarType:"", activePage:this.props.selectedRecord.objectTypeInfo.code, activePageGroup:this.props.selectedRecord.objectTypeInfo.code});

        }

          if(this.props.sidebarType!=="" && prevProps.sidebarType!==this.props.sidebarType )
        {
         // console.log(this.state.sidebarType, this.props.sidebarType)
          
            this.setState({...this.state, sidebarType:this.props.sidebarType, activePage:this.props.sidebarType});

        }

    /*
          if(prevProps.newCreate===false && this.props.newCreate===true)
        {
          //console.log("new true")
          
            this.setState({...this.state, activePage:"newTask",activePageGroup:"activity"});

        }

        

         if(prevProps.newCreate===true && this.props.newCreate===false)
        {
         // console.log("other true")
          
            this.setState({...this.state, activePage:this.props.selectedRecord.objectTypeInfo.code,activePageGroup:this.props.selectedRecord.objectTypeInfo.code});

        }

        */



        
    }


 

        getTabs(){

          let source=this.props.project?.source?.code;
             switch (this.props.selectedRecord.objectTypeInfo.code) {
              case "wbs":
                return  <>
                  <Tab to="wbs" onClick={this.setActivePage} label="Details" activePage={this.state.activePage} />
                  <Tab to="wbsStatus" onClick={this.setActivePage} label={"Status"+(source!=="hub"?" (P6)":"")} activePage={this.state.activePage} />
                  </>
            
              case "activity":
                  switch (this.props.newCreate) {
                      case false:
                        
                        return <>
                                  <Tab to="activity" onClick={this.setActivePage} label="Details" activePage={this.state.activePage} />
                                  <Tab to="activityStatus" onClick={this.setActivePage} label={"Status"+(source!=="hub"?" (P6)":"")} activePage={this.state.activePage} />
                                  <Tab to="activityAssignment" onClick={this.setActivePage} label="Assignments" activePage={this.state.activePage} />
                              </>

                      case true:
                  
                        return <> 
                                <Tab to="newTask" onClick={this.setActivePage} label="New Task" activePage={this.state.activePage} objectType="activity" />
                              </>

                    }
              case "task":
                 switch (this.props.newCreate) {
                      case false:
                       return <> 
                        <Tab to="task" onClick={this.setActivePage} label="Details" activePage={this.state.activePage}  />
                        {this.props.selectedRecord.isTask == true ? <Tab to="taskAssignment" onClick={this.setActivePage} label="Assignments" activePage={this.state.activePage} />:false}
                        <Tab to="taskStatus" onClick={this.setActivePage} label="Status" activePage={this.state.activePage} />
                        {((parseInt(this.props.selectedRecord.pendingActual?this.props.selectedRecord.pendingActual:0 +this.props.selectedRecord.actualUnit?this.props.selectedRecord.actualUnit:0) > 0 || this.props.selectedRecord.isTask == true))?
                          <Tab to="taskRecurrence" onClick={this.setActivePage} label="Recurrence" activePage={this.state.activePage} />:false}
                        <Tab to="taskNotes" onClick={this.setActivePage} label="Conversation" activePage={this.state.activePage} />
                      </>
                        case true:
                          return <> 
                                <Tab to="newTask" onClick={this.setActivePage} label="New Task" activePage={this.state.activePage} objectType="task" />
                              </>

                 }

                }
            }

              getTabContent(){
                 switch (this.state.activePageGroup) {
                    case "wbs":
                     // console.log("getTabContent:",this.state.activePageGroup)
                        return <>
                                    <TabContent to="wbs" activePage={this.state.activePage}>
                                        <SideBarWbsDetails selectedRecord={this.props.selectedRecord} project={this.props.project} save={this.props.save}/>
                                    </TabContent>
                                    <TabContent to="wbsStatus" activePage={this.state.activePage}>
                                        <SideBarWbsStatus selectedRecord={this.props.selectedRecord}/>
                                    </TabContent>
                        </>
                    case "activity":
                       switch (this.props.newCreate) {
                         case false:
                            return <>
                                        <TabContent to="activity" activePage={this.state.activePage}>
                                            <SideBarActivityDetails selectedRecord={this.props.selectedRecord} project={this.props.project}  save={this.props.save}/>
                                        </TabContent>
                                        <TabContent to="activityStatus" activePage={this.state.activePage} >
                                            <SideBarActivityStatus selectedRecord={this.props.selectedRecord}/>
                                        </TabContent>
                                        <TabContent to="activityAssignment" activePage={this.state.activePage}  >
                                          <SideBarActivityAssignments selectedRecord={this.props.selectedRecord} project={this.props.project}/>
                                            
                                        </TabContent>
                                  </>
                                  
                         case true:
                 
                        return <>
                                    <TabContent to="newTask" activePage={this.state.activePage}>
                                       <NewTask selectedRecord={this.props.selectedRecord} parentRecord={this.props.selectedRecord} save={this.props.save} objectType={this.props.selectedRecord.objectTypeInfo.code} />
                                    </TabContent>
                                   
                        </>
                       }
                     case "task":
                      
                  switch (this.props.newCreate) {
                         case false:
                        return <>
                                    <TabContent to="task" activePage={this.state.activePage}>
                                       <SideBarTaskDetails selectedRecord={this.props.selectedRecord} parentRecord={this.props} save={this.props.save}/>
                                    </TabContent>
                                    <TabContent to="taskAssignment" activePage={this.state.activePage}>
                                       <TaskAssignments selectedRecord={this.props.selectedRecord} title={this.props.title} parentRecord={this.props}  updateTask={this.props.updateTask}/>
                                    </TabContent>
                                     <TabContent to="taskStatus" activePage={this.state.activePage}>
                                       <SideBarTaskStatus selectedRecord={this.props.selectedRecord} title={this.props.title} parentRecord={this.props} save={this.props.save}/>
                                    </TabContent>
                                     <TabContent to="taskRecurrence" activePage={this.state.activePage}>
                                      <SideBarTaskRecurrence selectedRecord={this.props.selectedRecord} title={this.props.title} save={this.props.save}/>
                                    </TabContent>
                                    <TabContent to="taskNotes" activePage={this.state.activePage}>
                                        <TaskSideNotes selectedRecord={this.props.selectedRecord} title={this.props.title} />
                                    </TabContent>
                                </>

                      case true:
               
                        return <>
                                    <TabContent to="newTask" activePage={"newTask"}>
                                       <NewTask selectedRecord={this.props.selectedRecord} parentRecord={this.props.selectedRecord} save={this.props.save} objectType={this.props.selectedRecord.objectTypeInfo.code} />
                                    </TabContent> </>
                                    
                       }
                       


              }
            }

            getNewObejctTabs()
            {
              switch (this.props.sidebarType) {
                case "wbsCreate":
                  return  <>
                     <Tab to="wbsCreate" onClick={this.setActivePage} label="WBS Create" activePage={this.state.activePage} />
                    
                    </>
              
                case "activityCreate":
                  return  <>
                    <Tab to="activityCreate" onClick={this.setActivePage} label="Activity Create" activePage={this.state.activePage} />
                    
                    </>
                case "taskCreateScratch":
                 return  <>
                    <Tab to="taskCreateScratch" onClick={this.setActivePage} label="Task Create" activePage={this.state.activePage} />
                  
                  </>
                  case "taskCreate":
                    return  <>
                       <Tab to="taskCreate" onClick={this.setActivePage} label="Task Create" activePage={this.state.activePage} />
                     
                     </>
                   default:
                    return <></>
              }

            }

              getNewObjectContent(){

                switch (this.props.sidebarType) {
                case "wbsCreate":
                  return  <>
                    <TabContent to="wbsCreate" activePage={"wbsCreate"}>
                          <NewWbs selectedRecord={this.props.selectedRecord} parentRecord={this.props.selectedRecord} save={this.props.save} objectType={this.props.selectedRecord?.objectTypeInfo?.code} project={this.props.project} createType={this.props.createType}/>
                      </TabContent>
                    
                    </>
              
                case "activityCreate":
                  return  <>
                    <TabContent to="activityCreate" activePage={"activityCreate"}>
                          <NewActivity selectedRecord={this.props.selectedRecord} parentRecord={this.props.selectedRecord} save={this.props.save} objectType={this.props.selectedRecord?.objectTypeInfo?.code} createType={this.props.createType}  project={this.props.project}/>
                      </TabContent>
                    </>
                    // ! daha iyi bir çözüm bulunmalı.
                    // ! taskCreateScratch sıfırdan oluşturmak parent seçimi olmalı. diğeri bir 
                case "taskCreateScratch":
                 return  <>
                    <TabContent to="taskCreateScratch" activePage={"taskCreateScratch"}>
                         <NewTask selectedRecord={this.props.selectedRecord} parentRecord={this.props.selectedRecord} save={this.props.save} objectType={this.props.selectedRecord?.objectTypeInfo?.code} project={this.props.project} fromActions/>
                      </TabContent>
                  </>
                  case "taskCreate":
                    return  <>
                       <TabContent to="taskCreate" activePage={"taskCreate"}>
                            <NewTask selectedRecord={this.props.selectedRecord} parentRecord={this.props.selectedRecord} save={this.props.save} objectType={this.props.selectedRecord?.objectTypeInfo?.code} project={this.props.project} />
                         </TabContent>
                     </>
                   default:
                    return <></>
              }

              }



    render() {
     
      //console.log("ganttsidebar", this.props)
        return (

          !this.state.loading?
          <div className="hub-tabGroup-wrapper" >
            <TabGroup>
               { this.state.sidebarType===""?this.getTabs():this.getNewObejctTabs() }
            </TabGroup> 

             { this.state.sidebarType===""?this.getTabContent():this.getNewObjectContent() }
           
             
          </div>
          :false
        );
    }
}




export default GanttSidebar;

import React, { useState } from "react";

const CommonContext = React.createContext({
  userInfo: {},
  setUserInfo: () => {},
  configs: {},
  setConfigs: () => {},
});

export const CommonProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({});
  const [configs, setConfigs] = useState({});

  const contextValue = {
    userInfo,
    setUserInfo,
    configs,
    setConfigs,
  };

  return (
    <CommonContext.Provider value={contextValue}>
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;

import React, { Component } from 'react';

class Total extends Component {

    nFormatter = (num, digits) => {
        const lookup = [
          { value: 1e0, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
          return num >= item.value;
        });
        return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
    }

    render() {
        return (
            <div className="hub-rmUtil-total">
                <span className="value">{this.props.value!==null?(this.props.value?this.nFormatter(this.props.value, 1):this.props.value?.toFixed(1)):"-"}</span>&nbsp;
                <span className="unit">{this.props.unit}</span>
            </div>
        );
    }
    
}

export default Total;
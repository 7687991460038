import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faQuestion} from '@fortawesome/free-solid-svg-icons'

import InfoIcon from '../common/infoIcon/InfoIcon'
import WidgetOptions from './WidgetOptions'
export default class WidgetHeader extends Component {
     

  render() {
    return (
      <div className='hub-widget-header'>
        <div className='hub-widget-header-title-wrapper'>
            {this.props.icon!==undefined?
              <div className='title-icon' >
                  <FontAwesomeIcon icon={this.props.icon} color={"#2F5AB9"}   size={"1x"} />
              </div>
            :false}
            <div className={"title" + (this.props.icon?" icon":"")}>{this.props.title}</div>
        

            {this.props.info?
                <InfoIcon info={this.props.info} />
            :false
            }
        </div>
        <WidgetOptions info={this.props.info} widgetRef={this.props.widgetRef}/>

      </div>
    )
  }
}

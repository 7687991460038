import React from "react";

import HubIcon from "../../../hubIcon/HubIcon";
import HiCheck from "../../../icons/HiCheck";
import HiClose from "../../../icons/HiClose";

const BooleanContent = ({ isChecked }) => {
  return isChecked ? (
    <HubIcon lineColor="var(--hub500, #1769F1)">
      <HiCheck />
    </HubIcon>
  ) : (
    <HubIcon lineColor="var(--red, #DC3545)">
      <HiClose />
    </HubIcon>
  );
};

export default BooleanContent;

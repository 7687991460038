import React from "react";

const HiSort = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
    >
      <path
        d="M6 10L6 2M6 2L3 5M6 2L9 5"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiSort;

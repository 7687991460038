import React, { Component } from "react";
import DataTable from "../../common/dataTable";
import TimesheetSideBar from "./TimesheetSideBar";
import Auth from "../../auth/Auth";
import PageHeader from "../../common/pageElements/pageHeader";

class TimesheetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      LoadingText: "Loading Projects",
      Loading: true,
      LoadingError: false,
      LoadingErrorText: "",
    };
  }

  render() {
    const dtConfig = {
      endPoint: "/api/timesheet?page=0&size=5000&",
      columns: [
        ,
        {
          id: "resourceName",
          dataKey: "resource.fullName",
          label: "Resource Name",
          type: "text",
        },
        {
          id: "resourceId",
          dataKey: "resource.uid",
          label: "Resource Id",
          type: "text",
        },
        {
          id: "resourceManager",
          dataKey: "resource.Manager",
          label: "Resource Manager",
          type: "text",
        },
        // ,{id:"roleInfo", dataKey: "roleInfo", label:"Role", type:"dynamicLookup", endpoint:"/api/resourcerole", searchAttribute:"name", valueAttribute:"uid", labelAttribute:"name" }

        {
          id: "roleInfo",
          dataKey: "resource.roleInfo",
          label: "Role",
          type: "dynamicLookup",
          endpoint: "/api/resourcerole",
          searchAttribute: "name",
          valueAttribute: "uid",
          labelAttribute: "name",
        },
        {
          id: "periodStart",
          dataKey: "period_start",
          label: "Period Start Date",
          type: "date",
          linkTo:
            "/timesheet/detail?resourceId={resource.uid}&timeperiodId={timeperiodId}",
        },
        {
          id: "periodFinish",
          dataKey: "period_finish",
          label: "Period Finish Date",
          type: "date",
        },
        {
          id: "status",
          dataKey: "status",
          label: "Status",
          type: "staticLookup",
          valueListUid: 7,
        },
        {
          id: "stage",
          dataKey: "stage",
          label: "Stage",
          type: "staticLookup",
          valueListUid: 9,
        },
        { id: "total", dataKey: "actual", label: "Total Actual", type: "hour" },
        {
          id: "availability",
          dataKey: "availability",
          label: "Availability",
          type: "number",
        },
        {
          id: "waitingMyApproval",
          dataKey: "waitingMyApproval",
          label: "Is Waiting My Approval",
          type: "boolean",
        },
      ],
      idAttribute: "uid",
      nameAttribute: "resource.fullName",
      pageCode: "timesheetList",
      prebuiltFilters: [
        {
          code: "chiefEngineerApproval",
          label: "Manager Approval",
          color: "#FFB800",
          // defaultSelected:false,
          filterSet: [
            {
              id: "resourceManager",
              dataKey: "resource.Manager",
              label: "Resource Manager",
              type: "text",
              filter: {
                option: {
                  indicator: "*=*",
                  label: "Contains",
                  value: "contains",
                },
                value: Auth.checkResourceName(),
                valueIds: [],
              },
            },
            {
              id: "stage",
              dataKey: "stage",
              label: "Stage",
              type: "staticLookup",
              valueListUid: 9,
              filter: {
                option: {
                  indicator: "=",
                  label: "Is One of",
                  value: "isOneOf",
                },
                value: {
                  label: "Manager Approval",
                  object: {
                    code: "chief_engineer_approval",
                    color: null,
                    name: "Manager Approval",
                    parent: 9,
                    uid: 15,
                  },
                  value: 15,
                },
                valueIds: [15],
              },
            },
          ],
        },

        {
          code: "waitingMyApproval",
          label: "Waiting My Approval",
          color: "#1BC94C",
          defaultSelected: true,
          filterSet: [
            {
              id: "waitingMyApproval",
              dataKey: "waitingMyApproval",
              label: "Waiting My Approval",
              type: "boolean",
              filter: {
                option: { indicator: "Yes", label: "Yes", value: "is" },
                value: null,
                valueIds: [],
              },
            },
          ],
        },
      ],
      view: {
        visibleColumnList: [
          "resourceName",
          "periodStart",
          "periodFinish",
          "stage",
          "status",
          "total",
          "availability",
        ],
        hideSelectbox: false,

        selectBoxCondition: { key: "waitingMyApproval", value: true },
        timesheetApproveAvailable: "/api/timesheet/bulkApprove",
      },
      sideBar: <TimesheetSideBar />,
    };

    return (
      <>
        <PageHeader title="Timesheets" />
        <div className="hub-page">
          <DataTable feSorting={true} config={dtConfig} />
        </div>
      </>
    );
  }
}

export default TimesheetList;

import React, { Component } from 'react';
import Widget from '../Widget';
import GaugeChartRunner from './GaugeChartRunner';

class GaugeChart extends Component {
    render() {
        return (
            <Widget title={this.props.config?.title}  link={this.props.config?.link} info={this.props.config?.info} icon={this.props.config?.icon}>
                <GaugeChartRunner config={this.props.config} dataSet = {this.props.dataSet}  selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
            </Widget>
        );
    }
}

export default GaugeChart;
import React, { Component } from "react";
import Auth from "../../auth/Auth";

import TextField from "../../common/inputControls/textField";

import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import { DateUtils } from "./../../common/Commons";

class TimesheetSideBarDetails extends Component {
  constructor(props) {
    super(props);
    this.du = new DateUtils();

    this.state = {
      Loading: true,
      Status: "Loading",
      data: {},
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[val] = e;
    this.setState({
      ...this.state,
      formData: items,
    });
  };
  componentDidMount() {
    //console.log("componentDidMount")
    //console.log(this.props);
    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    //console.log("this.props.selectedRecord");
    // console.log(this.props.selectedRecord);
    /*  console.log("prevProps");
        console.log(prevProps);
*/
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    const t = this.props.selectedRecord;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/timesheetdetails/timesheet/resource/" +
        t.resource.uid +
        "/timeperiod/" +
        t.timeperiodId,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log("r");
        // console.log(r);
        if (r.status === 200 && r.body.isSuccess) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            data: r.body.object,
          });

          //console.log(this.state.data.timeReportLines[0].worklogs[0].day)
          // console.log(this.state.period.open)
        } else if (r.status === 401) {
          window.location.replace("/login");
        } else if (r.status === 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (!r.body.isSuccess) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText: r.body.message,
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    return this.state.Status === "Load" ? (
      <PageArea>
        {/* <ButtonArea>
                        <MainButton onClick={this.Update} key="newTaskButton" className="button-2" icon={false} label="Save" /> 
                    </ButtonArea>
                 */}

        <BodyArea>
          <TextField
            width="45%"
            label="Resource Name"
            value={this.state.data.resource.fullName}
            disabled={true}
          />
          <TextField
            width="45%"
            label="Resource Manager"
            value={this.state.data.resource.Manager}
            disabled={true}
          />
          <TextField
            width="45%"
            label="Status Name"
            value={this.state.data.status.name}
            disabled={true}
          />

          <TextField
            width="45%"
            label="Stage Name"
            value={this.state.data.stage.name}
            disabled={true}
          />

          <TextField
            width="45%"
            label="Availability (h)"
            value={this.state.data.availability}
            disabled={true}
          />
          <TextField
            width="45%"
            label="Actual (h)"
            value={this.state.data.actual?.toFixed(2)}
            disabled={true}
          />
          {/* */}
          <TextField
            width="45%"
            type="text"
            label="Period Start"
            value={this.du.Date2Str(this.props.selectedRecord.period_start)}
            disabled={true}
          />
          <TextField
            width="45%"
            type="text"
            label="Period Finish"
            value={this.du.Date2Str(this.props.selectedRecord.period_finish)}
            disabled={true}
          />
        </BodyArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default TimesheetSideBarDetails;

import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import DateArea from "../../common/inputControls/dateArea";

import { Utils } from "../../common/Commons";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import TextArea from "../../common/inputControls/textarea/TextArea";

import NumberField from "../../common/inputControls/numberField";

import TextArea2 from "../../common/inputControls/textArea2/textArea";

class SideBarTaskRecurrence extends Component {
  /**/
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      recurData: {
        weekly: false,
        monthly: false,
        biWeekly: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        repCount: 0,
        monthlyStartDate: null,
      },
    };
  }

  handleChangesWeekMonth = (e, val) => {
    let items = { ...this.state.recurData };

    items[e] = val;
    if (e === "weekly" && val) {
      items.monthly = !val;
      items.biWeekly = !val;
    }
    if (e === "monthly" && val) {
      items.weekly = !val;
      items.biWeekly = !val;
    }
    if (e === "biWeekly" && val) {
      items.weekly = !val;
      items.monthly = !val;
    }
    this.setState({ ...this.state, recurData: items });
  };

  handleChangesWeekDays = (e, val) => {
    //  console.log(e,val)
    let items = { ...this.state.recurData };
    items[e] = val;
    // let weekDays=Object.entries(this.state.recurData.weekDays)
    let weekDays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    /*weekDays.map((days)=>
        {
           
            if(days.toString()!==e.toString() && val )
            {
               // console.log("if",days[0],e,val)
                items[days]=!val;
            }
        })
        */
    //console.log(items)
    this.setState({ ...this.state, recurData: items });
  };

  handleChanges = (e, val) => {
    let items = { ...this.state.recurData };

    items[e] = val;
    this.setState({ ...this.state, recurData: items });
  };

  componentDidMount() {
    //console.log("componentDidMount")
    // console.log(this.props)

    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*/*",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/task/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //   console.log(r);
        if (r.status == 200) {
          let secProfile = Auth.getResourceObject().securityProfile?.name;

          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object[0],
            secProfile: secProfile,
          });

          // console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };

  create = () => {
    //console.log(JSON.stringify(this.state.formData))
    let data = this.state.recurData;
    // console.log(data,this.state.recurData.repCount)

    if (
      this.state.recurData.repCount === 0 ||
      this.state.recurData.repCount === null
    ) {
      this.notify("error", "Repetition must entered");
    } else if (
      !this.state.recurData.weekly &&
      !this.state.recurData.monthly &&
      !this.state.recurData.biWeekly
    ) {
      this.notify("error", "Monthly or weekly must selected");
    } else if (
      this.state.recurData.weekly &&
      !this.state.recurData.monday &&
      !this.state.recurData.tuesday &&
      !this.state.recurData.wednesday &&
      !this.state.recurData.thursday &&
      !this.state.recurData.friday &&
      !this.state.recurData.saturday &&
      !this.state.recurData.sunday
    ) {
      this.notify("error", "Weekdays must selected");
    } else if (
      this.state.recurData.monthly &&
      this.state.recurData.monthlyStartDate === null
    ) {
      this.notify("error", "Start Date must selected");
    } else if (this.state.recurData.repCount > 0) {
      //   this.notify("success","Created")
      // console.log(r);
      // this.removeItem();

      this.props.save(
        "post",
        "/api/task/" + this.props.selectedRecord.uid,
        this.state.recurData,
        "middle",
        "array"
      );

      this.setState({
        ...this.state,
        recurData: {
          weekly: false,
          monthly: false,
          biWeekly: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
          repCount: 0,
          monthlyStartDate: null,
        },
      });
    }
  };

  render() {
    console.log("recur", this.state, this.props);
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="frequency">Frequency</div>
          <div className="check-grid">
            <BooleanArea
              width="100%"
              onChange={this.handleChangesWeekMonth}
              changeArea="weekly"
              label="Weekly "
              value={this.state.recurData.weekly}
              disabled={false}
            />
            <BooleanArea
              width="100%"
              onChange={this.handleChangesWeekMonth}
              changeArea="biWeekly"
              label="Bi Weekly "
              value={this.state.recurData.biWeekly}
              disabled={false}
            />
            <BooleanArea
              width="100%"
              onChange={this.handleChangesWeekMonth}
              changeArea="monthly"
              label="Monthly "
              value={this.state.recurData.monthly}
              disabled={false}
            />
          </div>
          {this.state.recurData.weekly || this.state.recurData.biWeekly ? (
            <>
              <div
                style={{
                  fontWeight: "bold",
                  backgroundColor: "#ddd",
                  paddingLeft: "8px",
                  paddingRight: "5px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  marginTop: "60px",
                  marginLeft: "-10px",
                }}
              >
                Start By
              </div>

              <div className="check-grid">
                {" "}
                <BooleanArea
                  width="100%"
                  onChange={this.handleChangesWeekDays}
                  changeArea="monday"
                  label="Monday "
                  value={this.state.recurData.monday}
                  disabled={false}
                />
                <BooleanArea
                  width="100%"
                  onChange={this.handleChangesWeekDays}
                  changeArea="tuesday"
                  label="Tuesday"
                  value={this.state.recurData.tuesday}
                  disabled={false}
                />
                <BooleanArea
                  width="100%"
                  onChange={this.handleChangesWeekDays}
                  changeArea="wednesday"
                  label="Wednesday"
                  value={this.state.recurData.wednesday}
                  disabled={false}
                />
                <BooleanArea
                  width="100%"
                  onChange={this.handleChangesWeekDays}
                  changeArea="thursday"
                  label="Thursday"
                  value={this.state.recurData.thursday}
                  disabled={false}
                />
                <BooleanArea
                  width="100%"
                  onChange={this.handleChangesWeekDays}
                  changeArea="friday"
                  label="Friday"
                  value={this.state.recurData.friday}
                  disabled={false}
                />
              </div>

              {/*<BooleanArea width="25%" onChange= {this.handleChangesWeekDays}  changeArea="saturday" label="Saturday" value={this.state.recurData.saturday} disabled={false}/>   
                    <BooleanArea width="25%" onChange= {this.handleChangesWeekDays}  changeArea="sunday" label="Sunday" value={this.state.recurData.sunday} disabled={false}/> */}
              <div style={{ width: "100%", height: "75px" }}></div>
            </>
          ) : this.state.recurData.monthly ? (
            <div className="input-grid">
              <DateArea
                width="100%"
                onChange={this.handleChanges}
                changeArea="monthlyStartDate"
                label="Date"
                value={this.state.recurData.monthlyStartDate}
                required={true}
              />
              <div style={{ width: "100%", height: "150px" }}></div>
            </div>
          ) : (
            false
          )}
          <div className="input-grid">
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="repCount"
              label="Repetitions"
              value={this.state.recurData.repCount}
              disabled={false}
              required={true}
            />
          </div>
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.create}
            key="newTaskButton"
            className="button-area-action"
            icon={false}
            label="Create Recursive Task"
          />
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default SideBarTaskRecurrence;

import React, { Component } from 'react';


import './ButtonSelectBar.css'
import MainButton from './../../common/buttons/MainButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipWithPage from '../../common/tooltipWithPage/tooltipWithPage';
import OBSList from './OBSList';
class ButtonSelect extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state = {
            isOpen:false,
            isBarOpen:false,
            options:(this.props.options?this.props.options:[]),
            selectedOption:(this.props.selectedOption?this.props.selectedOption:{}),
            selected:{}
        }
    }

    componentDidMount()
    {
        this.setState({...this.state,selected:this.props.selected })
    }

    toggleSelectBar =(e,group) => {
      //console.log("toggleSelectBar",group,group?.name)
        //console.log(group)
      

        this.setState({...this.state,isBarOpen:!this.state.isBarOpen, selected:group?.name?group:this.state.selected } ,() => {
            window.addEventListener("mousedown", this.handleClickOutside);
        })
        if(group?.uid!==undefined)
        {
            this.props.toggleSelected(group);
             //this.setState({...this.state, isBarOpen:false})
        }   
        
    }

    closePanel = () => { this.setState({...this.state, isBarOpen:false}, () => {
        window.removeEventListener("mousedown", this.handleClickOutside);
    }); }

   handleClickOutside = (event) => {
        
        if (this.wrapperRef && !this.wrapperRef.current?.contains(event?.target)) {
          this.closePanel();
        }
      }

    render() {
       // console.log("ButtonSelect")
        //console.log("ButtonSelect",this.state)
        return (
            <div className="hub-rmUtil-buttonSelect">
               <MainButton style={{width:"270px",background:"rgba(243,220,243,1)",color:"rgb(60,50,60)"}} className={this.state.isOpen?"button-2 active":"button-2"} onClick={this.toggleSelectBar} label={this.state.selected.name}/>
               {this.state.isBarOpen?
                    <div ref={this.wrapperRef}>
                        <TooltipWithPage title={this.state.selected.name} closePanel={this.closePanel} style={{width:"450px",transform:"translate(-30%)"}} arrowLocaiton={{marginLeft:"123px"}} >
                            <OBSList items={this.props.options} toggleSelectBar={this.toggleSelectBar} closePanel={this.closePanel} />
                        </TooltipWithPage>
                    </div>
                :false}   
            </div>
        );
    }
}

export default ButtonSelect;
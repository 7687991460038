import React, { Component } from 'react'

import './../hierarchicalTable/hierarchicalTable.css'
import TableItem from './tableItem'
export default class Table extends Component {
    constructor(props) {
    super(props)
  
    this.state = {
       searchString:"",
       description:false,
       data:[]
    }
  }



  render() {
    //console.log("Table",this.props)
    return (
        <div className='hier-table-wrapper'>  {this.props.data?.map((val,index)=>
                    {
                       
                       return  (((new RegExp(this.props.searchString, "i")).test(val[this.props.labelKey])) ?
                        
                            <TableItem label={this.props.labelKey?val[this.props.labelKey]:""} 
                                onClick={(e)=>{this.props.toggleSelectBar(e,val)}} index={index} />

                       :false)

                    })}
        </div>
    )
  }
}

import React, { Component } from "react";

import "./LayoutModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainButton from "./../../../common/buttons/MainButton";

import LayoutModalLevel from "./LayoutModalLevel";
import LayoutModalField from "./LayoutModalField";
import { faTrashCan, faTheta } from "@fortawesome/pro-regular-svg-icons";
import {
  faXmark,
  faFloppyDisk,
  faPlus,
  faPeopleGroup,
  faBriefcase,
  faUser,
  faFolderGrid,
  faNetworkWired,
  faBolt,
  faUserGroup,
} from "@fortawesome/pro-solid-svg-icons";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HiTrash from "../../../common/icons/HiTrash";

class LayoutModal extends Component {
  constructor(props) {
    super(props);
    this.levels = {
      obs: { code: "obs", name: "Department", icon: faPeopleGroup },
      resource: { code: "resource", name: "Resource", icon: faUser },
      role: { code: "role", name: "Role", icon: faBriefcase },
      project: { code: "project", name: "Project", icon: faFolderGrid },
      wbs: { code: "wbs", name: "WBS", icon: faNetworkWired },
      activity: { code: "activity", name: "Activity", icon: faBolt },
    };

    this.fields = {
      planned: { code: "planned", name: "Planned", icon: faTheta },
      actual: { code: "actual", name: "Actual", icon: faTheta },
      atCompletion: {
        code: "atCompletion",
        name: "At Completion",
        icon: faTheta,
      },
      remaining: { code: "remaining", name: "Remaining", icon: faTheta },
      capacity: { code: "capacity", name: "Capacity", icon: faTheta },
    };

    this.fieldList = [
      "planned",
      "actual",
      "atCompletion",
      "remaining",
      "capacity",
    ];

    this.state = {
      levelList: ["obs", "resource", "role", "project", "wbs", "activity"],
      disabled: [],
      name: "",
      missingMetric: 0,
      missingName: 0,
      missingLevel: 0,
      primaryField: "",
      secondaryField: "",
      showSecondaryFields: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show === false && this.props.show === true) {
      if (this.props.mode === "create") {
        this.setState({
          levelList: ["obs", "resource", "role", "project", "wbs", "activity"],
          disabled: [],
          name: "My Layout",
          missingMetric: 0,
          missingName: 0,
          missingLevel: 0,
          primaryField: "",
          secondaryField: "",
          showSecondaryFields: false,
        });
      } else {
        let levelList = this.props.modalLayout.levels;
        let disabled = [];
        ["obs", "resource", "role", "project", "wbs", "activity"].map((x) => {
          if (this.props.modalLayout.levels.indexOf(x) === -1) {
            levelList.push(x);
            disabled.push(x);
          }
        });

        this.setState({
          levelList: levelList,
          disabled: disabled,
          missingName: 0,
          missingMetric: 0,
          missingLevel: 0,
          code: this.props.modalLayout.code,
          name: this.props.modalLayout.name,
          primaryField: this.props.modalLayout.primaryField,
          secondaryField: this.props.modalLayout.secondaryField,
          showSecondaryFields: this.props.modalLayout.secondaryField !== "",
        });
      }
    }
  }

  setName = (e) => {
    this.setState({ ...this.state, name: e.target.value });
  };

  setLevelUp = (level) => {
    let levelList = [...this.state.levelList];
    // ['obs', 'resource', 'role', 'project', 'wbs', 'activity']
    let pos = this.state.levelList.indexOf(level);
    levelList.splice(pos, 1);
    levelList.splice(pos - 1, 0, level);

    this.setState({ ...this.state, levelList: levelList });
  };

  setLevelDown = (level) => {
    let levelList = [...this.state.levelList];
    let pos = this.state.levelList.indexOf(level);

    levelList.splice(pos, 1);
    levelList.splice(pos + 1, 0, level);

    this.setState({ ...this.state, levelList: levelList });
  };

  toggleLevel = (level) => {
    let disabled = [...this.state.disabled];
    let pos = this.state.disabled.indexOf(level);
    if (pos === -1) {
      disabled.push(level);
    } else {
      disabled.splice(pos, 1);
    }
    this.setState({ ...this.state, disabled: disabled });
  };

  setPrimaryField = (field) => {
    // Eğer aynı alan tekrar tıklanırsa seçim alanı boşaltılır.
    this.setState({
      ...this.state,
      primaryField: this.state.primaryField === field ? "" : field,
    });
  };

  setSecondaryField = (field) => {
    // Eğer aynı alan tekrar tıklanırsa seçim alanı boşaltılır.
    this.setState({
      ...this.state,
      secondaryField: this.state.secondaryField === field ? "" : field,
    });
  };

  toggleSecondaryFields = () => {
    // Eğer aynı alan tekrar tıklanırsa seçim alanı boşaltılır.
    this.setState({
      ...this.state,
      showSecondaryFields: !this.state.showSecondaryFields,
      secondaryField: "",
    });
  };

  addLayout = () => {
    console.log(
      this.state.name !== "",
      this.state.primaryField !== "",
      this.state.levelList.length === this.state.disabled.length
    );
    if (
      this.state.name !== "" &&
      this.state.primaryField !== "" &&
      this.state.levelList.length !== this.state.disabled.length
    ) {
      this.props.addLayout({ ...this.state });
    } else {
      this.setState({
        ...this.state,
        missingLevel:
          this.state.levelList.length !== this.state.disabled.length ? 0 : 1,
        missingName: this.state.name !== "" ? 0 : 1,
        missingMetric: this.state.primaryField !== "" ? 0 : 1,
      });
    }
  };
  saveLayout = () => {
    this.props.saveLayout({ ...this.state });
  };

  deleteLayout = () => {
    this.props.deleteLayout(this.state.code);
  };

  onDragEnd = (result) => {
    const newItems = [...this.state.levelList];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    this.setState({ ...this.state, levelList: newItems }, () => {
      console.log(this.state);
    });
  };

  render() {
    let linkTop = 0;
    let linkHeight = 0;
    let leftStyle = {};
    let rightStyle = {};

    if (this.state.primaryField !== "" && this.state.secondaryField !== "") {
      let startInd = this.fieldList.indexOf(this.state.primaryField);
      let finishInd = this.fieldList.indexOf(this.state.secondaryField);
      linkTop = 80 + (finishInd < startInd ? finishInd : startInd) * 60;
      linkHeight = Math.abs(finishInd - startInd) * 60;
      linkHeight = linkHeight === 0 ? 1 : linkHeight;

      leftStyle = {
        borderWidth: finishInd > startInd ? "2px 2px 0 0" : "0 2px 2px 0px",
        borderTopRightRadius: finishInd > startInd ? "5px" : "0px",
        borderBottomRightRadius: finishInd > startInd ? "0px" : "5px",
        marginTop: finishInd > startInd ? "0px" : "20px",
        height: linkHeight - 20,
      };
      rightStyle = {
        borderWidth:
          finishInd > startInd ? "0px 0px 2px 2px" : "2px 0px 0px 2px",
        borderTopLeftRadius: finishInd > startInd ? "0px" : "5px",
        borderBottomLeftRadius: finishInd > startInd ? "5px" : "0px",
        marginTop: finishInd > startInd ? "20px" : "0px",
        height: linkHeight - 20,
      };
    }

    return (
      <div
        id="hub-modalWrapper"
        className="hub-rmUtil-modalWrapper"
        style={{ display: this.props.show ? "block" : "none" }}
      >
        <div className="hub-rmUtil-modal">
          <div className="hub-rmUtil-modalHeader">
            <div className="hub-rmUtil-modalHeader-title">
              {this.props.mode === "edit" ? (
                <MainButton
                  onClick={this.deleteLayout}
                  iconStyle={{ color: "#E45151" }}
                  style={{ height: 32, border: "1px solid #DFE4ED" }}
                  color="#E45151"
                  className="tiny-icon"
                  icon={<HiTrash />}
                />
              ) : (
                false
              )}
              <input
                type="text"
                placeholder="Layout Name"
                style={{
                  border:
                    this.state.missingName === 1 ? "2px solid #ff0000" : "0",
                }}
                onChange={this.setName}
                value={this.state.name}
                id="hub-rmUtil-modalInput"
              />
            </div>
            <div className="hub-rmUtil-modalHeader-buttons">
              {this.props.mode === "edit" ? (
                <MainButton
                  className="button"
                  onClick={this.saveLayout}
                  label="Save Layout"
                />
              ) : (
                false
              )}
              {this.props.mode === "create" ? (
                <MainButton
                  className="button"
                  onClick={this.addLayout}
                  label="Add to Layout List"
                />
              ) : (
                false
              )}
              <MainButton
                className="button-2"
                onClick={this.props.onClose}
                label="Close"
              />
            </div>
          </div>
          <div className="hub-rmUtil-modalBody">
            <div className="left">
              <div
                className="columnHeader"
                style={{
                  color: this.state.missingLevel === 1 ? "#ff0000" : "#000000",
                }}
              >
                Hierarchy
              </div>
              <div className="columnDescription">
                Please select data hierarchy for utilization analysis.
              </div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      style={{ marginTop: 34 }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {this.state.levelList.map((x, index) => {
                        return (
                          <Draggable
                            key={"layoutModalDraggable-" + this.levels[x].code}
                            draggableId={this.levels[x].code}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <LayoutModalLevel
                                  key={
                                    "layoutModalLevel-" + this.levels[x].code
                                  }
                                  isDisabled={
                                    this.state.disabled.indexOf(
                                      this.levels[x].code
                                    ) > -1
                                  }
                                  toggleLevel={this.toggleLevel}
                                  setLevelUp={this.setLevelUp}
                                  setLevelDown={this.setLevelDown}
                                  level={this.levels[x]}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="right">
              <div
                className="columnHeader"
                style={{
                  color: this.state.missingMetric === 1 ? "#ff0000" : "#000000",
                }}
              >
                Fields
              </div>
              <div className="columnDescription">
                Please select utilization metrics to compare.
              </div>
              <div className="fields">
                <div className="fieldColumn">
                  <div className="fieldColumnHeader">Primary Field</div>
                  <div className="fieldList">
                    {this.fieldList.map((x, i) => {
                      return (
                        <LayoutModalField
                          key={"layoutModalPrimaryField-" + this.fields[x].code}
                          isDisabled={
                            this.state.secondaryField === this.fields[x].code
                          }
                          isActive={
                            this.state.primaryField === this.fields[x].code
                          }
                          setField={this.setPrimaryField}
                          field={this.fields[x]}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="fieldColumnLink">
                  <div
                    className="linkWrapper"
                    style={{ height: linkHeight, top: linkTop }}
                  >
                    {this.state.primaryField !== "" &&
                    this.state.secondaryField !== "" ? (
                      <>
                        <div className="leftLink" style={leftStyle}></div>
                        <div className="rightLink" style={rightStyle}></div>
                      </>
                    ) : (
                      false
                    )}
                  </div>
                </div>
                <div className="fieldColumn">
                  <div
                    className="fieldColumnHeader"
                    style={{
                      marginTop: this.state.showSecondaryFields ? -5 : 0,
                      opacity: this.state.showSecondaryFields ? 1 : 0.2,
                    }}
                  >
                    Secondary Field
                    {this.state.showSecondaryFields ? (
                      <div
                        onClick={this.toggleSecondaryFields}
                        className="icon"
                      >
                        <FontAwesomeIcon color="#1D5BBF" icon={faXmark} />
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div
                    className="fieldList"
                    style={{
                      marginTop: this.state.showSecondaryFields ? 21 : 25,
                    }}
                  >
                    {this.state.showSecondaryFields ? (
                      <>
                        {this.fieldList.map((x, i) => {
                          return (
                            <LayoutModalField
                              key={
                                "layoutModalSecondaryField-" +
                                this.fields[x].code
                              }
                              isActive={
                                this.state.secondaryField ===
                                this.fields[x].code
                              }
                              isDisabled={
                                this.state.primaryField === this.fields[x].code
                              }
                              setField={this.setSecondaryField}
                              field={this.fields[x]}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <div
                        onClick={this.toggleSecondaryFields}
                        className="placeholder"
                      >
                        <div className="icon">
                          <FontAwesomeIcon color="#1D5BBF" icon={faPlus} />
                        </div>
                        <div className="label">Add Second Field to Compare</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LayoutModal;

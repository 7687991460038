import React, { Component } from 'react';
import './ButtonArea.css'

class ButtonArea extends Component {
    render() {
        return (
            <div className="hub-page-buttonArea">
                {this.props.children}
            </div>
        );
    }
}

export default ButtonArea;
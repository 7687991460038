import React, { Component } from 'react'
import Auth from '../../../../auth/Auth';
import InputControl from '../../InputControl';
import './colorPicker.css'
import { tagColors } from '../../../colors/colors';
export default class ColorPicker extends Component {


    
    constructor(props) {
        super(props)
        this.state = {
            values: [],
           
            selectedColor:{}
        }
    }
    
    componentDidMount() {   
        this.loadOptions("", (x) => {
            this.setState({...this.state, values:x})
        });   

        
    }


    loadOptions = async (inputText, callback) => {

       // console.log("endpoint",this.props.endpoint)
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
        };
        const response = await fetch(`${this.props.endpoint}?size=1000000&search=${this.props.searchAttribute}:${inputText}`, requestOptions);
        const json = await response.json();
        let result=json.object
        if(this.props.extraObject )
        {
            result=json.object[this.props.extraObject]
        }
        //console.log("json",json.object[null])
        callback(result.map(i=>({label: i[this.props.labelAttribute], value:i[this.props.valueAttribute], object:i
         
        } ))) 

       // console.log("dynamic Response",response)
    }

    setColor=(color)=>{
       //console.log("setColor",color)
        this.setState({...this.state, selectedColor:color.object}, this.props.onChange( this.props.formId, color.object ) )
       
    }


  render() {
    let color="white"
    return (
        <InputControl inputValue={this.state.v} error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props} type="multi">
            <div className="hub-color-picker-wrapper">
                {this.state.values?.map((c,i)=>
                {
                     color= tagColors.find(x=>x.name===c?.object?.code)?.backgroundColor
               
                    return <div className="hub-color-picker-color" style={{background:color, marginTop:i>6?"11px":"0" ,boxShadow:this.state.selectedColor?.code===c?.object?.code?("0px 0px 0px 2px white,0px 0px 0px 4px "+color):"unset" }} onClick={()=>this.setColor(c)}></div>
                })}
            </div>
      </InputControl>
    )
  }
}

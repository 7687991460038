import React from "react";

const HiProjects = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      viewBox="0 0 20 20"
    >
      <path
        d="M1.66669 10L9.70188 14.0176C9.8112 14.0723 9.86585 14.0996 9.92319 14.1104C9.97396 14.1199 10.0261 14.1199 10.0769 14.1104C10.1342 14.0996 10.1888 14.0723 10.2982 14.0176L18.3334 10M1.66669 14.1667L9.70188 18.1843C9.8112 18.239 9.86585 18.2663 9.92319 18.2771C9.97396 18.2866 10.0261 18.2866 10.0769 18.2771C10.1342 18.2663 10.1888 18.239 10.2982 18.1843L18.3334 14.1667M1.66669 5.83338L9.70188 1.81579C9.8112 1.76113 9.86585 1.7338 9.92319 1.72304C9.97396 1.71351 10.0261 1.71351 10.0769 1.72304C10.1342 1.7338 10.1888 1.76113 10.2982 1.81579L18.3334 5.83338L10.2982 9.85098C10.1888 9.90564 10.1342 9.93297 10.0769 9.94372C10.0261 9.95325 9.97396 9.95325 9.92319 9.94372C9.86585 9.93297 9.8112 9.90564 9.70188 9.85098L1.66669 5.83338Z"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiProjects;

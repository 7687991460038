import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import { Utils } from "../../common/Commons";

import NumberField from "../../common/inputControls/numberField";
import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import TextArea from "../../common/inputControls/textarea/TextArea";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import HiSave from "../../common/icons/HiSave.js";
class AssignmentDetails extends Component {
  /**/
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      roleId: 0,
      roleInfo: "",
      ErrorMessage: "",
      errorMessages: {
        owner: { message: "" },
        remaining: { message: "" },
        finishDate: { message: "" },
        name: { message: "", error: false },
      },
      formData: {
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };
    let errors = { ...this.state.errorMessages };
    items[e] = val;
    if (e === "owner") {
      errors.owner.message = "";

      this.setState({
        ...this.state,
        formData: items,
        errorMessages: errors,
        roleId: items.owner?.roleId,
        roleInfo: items.owner.roleInfo?.name,
      });
    } else if (e === "remainingUnit") {
      errors.remaining.message = "";
      items.atCompletionUnit = items.actualUnit + val;
      this.setState({ ...this.state, formData: items, errorMessages: errors });
    } else if (e === "role") {
      this.setState({
        ...this.state,
        formData: items,
        roleId: val.uid,
        roleInfo: val.name,
      });
    } else if (e === "actualUnit") {
      let oldUnit = this.state.oldActualUnit;
      let diff = 0;
      //console.log("actualUnit",e,val,this.state.oldActualUnit)
      if (val > oldUnit) {
        diff = val - oldUnit;
        items.remainingUnit =
          items.remainingUnit - diff >= 0 ? items.remainingUnit - diff : 0;
      }

      this.setState({
        ...this.state,
        formData: items,
        oldActualUnit: val > oldUnit ? val : oldUnit,
      });
    } else {
      this.setState({ ...this.state, formData: items });
    }
  };

  componentDidMount() {
    //console.log("componentDidMount")
    //console.log("componentDidMount",this.props ,Object.keys(this.props.selectedRecord).length )

    if (Object.keys(this.props.selectedRecord).length > 0) {
      this.GetDatas();
    }
  }

  componentDidUpdate(prevProps) {
    //console.log("componentDidUpdate!!",this.props,this.props.selectedRecord,);

    //console.log(this.props);
    //
    if (
      prevProps.selectedRecord !== this.props.selectedRecord &&
      Object.keys(this.props.selectedRecord).length > 0
    ) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    //console.log("GetDatas",a,this.props);

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/task/" +
        this.props.selectedRecord.taskId +
        "/assignment/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log(r.body.object[0])

        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object[0],
            oldActualUnit: r.body?.object[0]?.actualUnit,
            initialActualUnit: r.body?.object[0]?.actualUnit,
          });

          // console.log(this.state)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  Update = () => {
    if (this.state.formData.owner === undefined) {
      //let errors = {...this.state.errorMessages};
      const u = new Utils();
      u.addNotification("error", "Resource Must Selected.");
      let errors = { ...this.state.errorMessages };
      errors.owner.message = "Resource Must Selected.";
      this.setState({ ...this.state, errorMessages: errors });
    }

    if (
      this.state.formData.remainingUnit <= 0 &&
      this.state.formData?.taskInfo?.progressCalculation?.code !== "manual"
    ) {
      const u = new Utils();
      u.addNotification("error", "Remaining Must Be Higher Than 0");
      let errors = { ...this.state.errorMessages };
      errors.remaining.message = "Remaining Must Be Higher Than 0.";
      this.setState({ ...this.state, errorMessages: errors });
    } else {
      this.props.save(
        "patch",
        "/api/assignment/" + this.state.formData.uid,
        this.state.formData
      );
    }
  };

  responsibleCheck = () => {
    var responsible = this.props.selectedRecord?.taskInfo?.owner?.id;
    var sessionUser = Auth.getResourceObject().id;
    let secProfile = Auth.getResourceObject().securityProfile?.name;
    let assignee = this.state.formData.owner?.id;

    if (
      sessionUser === assignee ||
      secProfile === "ADMIN" ||
      responsible === sessionUser
    ) {
      return true;
    } else {
      return false;
    }
  };

  updatableCheck = () => {
    if (
      this.responsibleCheck() &&
      this.state.formData.status.code != "completed"
    ) {
      // console.log(this.state.formData.status.code)
      if (this.state.formData.status.code == "started") {
        return (
          <>
            {" "}
            <TextField
              formId="owner"
              searchAttribute="fullName"
              valueAttribute="id"
              labelAttribute="fullName"
              width="100%"
              label="Resource"
              required={true}
              value={this.state.formData.owner?.fullName}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="role"
              value={this.state.formData.role?.name}
              label="Assignment Role"
              disabled={true}
            />
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="remainingUnit"
              label="Remaining Units (h)"
              value={
                this.state.formData.remainingUnit
                  ? this.state.formData.remainingUnit.toString()
                  : "0"
              }
              disabled={false}
              required={true}
            />
          </>
        );
      } else {
        return (
          <>
            <DynamicLookup
              endpoint="/api/resource/assignment"
              onChange={this.handleChanges}
              value={{
                value: this.state.formData.owner?.id,
                label: this.state.formData.owner?.fullName,
              }}
              formId="owner"
              searchAttribute="fullName"
              valueAttribute="id"
              labelAttribute="fullName"
              width="100%"
              label="Resource"
              required={true}
              errorMessage={this.state.errorMessages["owner"].message}
              error={this.state?.errorMessages["owner"]?.error}
            />
            <DynamicLookup
              endpoint="/api/resourcerole"
              onChange={this.handleChanges}
              formId="role"
              searchAttribute="name"
              valueAttribute="id"
              labelAttribute="name"
              width="100%"
              label="Assignment Role"
              value={{
                value:
                  this.state.formData.role != null
                    ? this.state.formData.role.uid
                    : null,
                label: this.state.formData.role?.name,
              }}
            />
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="remainingUnit"
              label="Remaining Units (h)"
              value={
                this.state.formData.remainingUnit
                  ? this.state.formData.remainingUnit.toString()
                  : "0"
              }
              disabled={false}
              required={true}
            />
          </>
        );
      }
    } else
      return (
        <>
          <TextField
            formId="owner"
            searchAttribute="fullName"
            valueAttribute="id"
            labelAttribute="fullName"
            width="100%"
            label="Resource"
            required={true}
            value={this.state.formData.owner?.fullName}
            disabled={true}
          />
          <TextField
            width="100%"
            onChange={this.handleChanges}
            changeArea="role"
            value={this.state.formData.role?.name}
            label="Assignment Role"
            disabled={true}
          />
          <NumberField
            width="100%"
            onChange={this.handleChanges}
            changeArea="remainingUnit"
            label="Remaining Units (h)"
            value={
              this.state.formData.remainingUnit
                ? this.state.formData.remainingUnit.toString()
                : "0"
            }
            disabled={true}
            required={true}
          />
        </>
      );
  };

  render() {
    //console.log(this.state)
    let calcMethod = this.state.formData?.taskInfo?.progressCalculation
      ? this.state.formData?.taskInfo?.progressCalculation?.code
      : "unit";

    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          {/*  <TextField width="100%" label="ID" value={this.state.formData.uid} disabled={true}/>
                    <TextField width="100%" onChange= {this.handleChanges} changeArea="code" label="code" value={this.state.formData.code} disabled={true}/>
        */}
          <div className="input-grid">
            {this.updatableCheck()}
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="plannedUnit"
              label="Planned Unit (h)"
              value={this.state.formData.plannedUnit?.toString()}
              disabled={true}
            />
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="actualUnit"
              label="Actual Units (h)"
              value={
                this.state.formData.actualUnit
                  ? this.state.formData.actualUnit
                  : 0
              }
              disabled={calcMethod === "manual" ? false : true}
            />
            {/*<TextField width="100%"  type="date" onChange = {this.handleChanges} changeArea="startDate" label="Start Date" value={this.state.formData.startDate} />
                    <TextField width="100%"  type="date" onChange = {this.handleChanges} changeArea="finishDate" label="Finish Date" value={this.state.formData.finishDate} />*/}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="pendingActual"
              label="Pending Actual"
              value={
                this.state.formData.pendingActual
                  ? this.state.formData.pendingActual.toFixed(2).toString()
                  : "0"
              }
              disabled={true}
            />
            <NumberField
              width="100%"
              onChange={this.handleChanges}
              changeArea="atCompletionUnit"
              label="At Completion"
              value={this.state.formData.atCompletionUnit
                ?.toFixed(0)
                .toString()}
              disabled={true}
            />
            {/*<DynamicLookup endpoint="/api/valuelistoptions/parent/13" value={{value:this.state.formData.status.uid, label: this.state.formData.status.name }} onChange={this.handleChanges} formId="status" searchAttribute="name" valueAttribute="uid" labelAttribute="name"  width="100%" label="Status"/>*/}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="plannedUnit"
              label="Status"
              value={this.state.formData.status.name}
              disabled={true}
            />
          </div>
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.Update}
            key="newTaskButton"
            className="button-area-action"
            iconLineColor="#fff"
            icon={<HiSave />}
            label="Save"
          />
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default AssignmentDetails;

import React, { Component } from "react";
import {
  faColumns3,
  faMagnifyingGlass,
} from "@fortawesome/pro-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import MainButton from "./../buttons/MainButton";
import "./ColumnPicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HiFields from "../../common/icons/HiFields";

class ColumnPicker extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isOpen: false,
      search: "",
      selectedItems: this.props.visibleColumnList,
    };
  }

  togglePanel = () => {
    this.setState({ ...this.state, isOpen: !this.state.isOpen }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleSearch = (e) => {
    this.setState({ ...this.state, search: e.target.value });
  };

  toggleSelect = (i) => {
    // Buranın daha verimli çalışması gerekebilir.

    let newSelectedItems = [...this.state.selectedItems];
    let index = newSelectedItems.indexOf(i);
    if (index >= 0) {
      newSelectedItems.splice(index, 1);
    } else {
      newSelectedItems.push(i);
    }

    this.setState({ ...this.state, selectedItems: newSelectedItems });
  };

  apply = () => {
    this.setState({ ...this.state, isOpen: false }, () => {
      this.props.applyVisibleColumns(this.state.selectedItems);
    });
  };
  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
      this.closeSelector(event);
    }
  };
  closeSelector = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, isOpen: false, filterText: "" }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  render() {
    let selected = [];
    let available = [];

    this.props.columns.map((c) => {
      if (
        this.state.search == "" ||
        c.label?.toLowerCase().search(this.state.search.toLocaleLowerCase()) >
          -1
      ) {
        if (this.state.selectedItems.indexOf(c.id) > -1) {
          selected.push(c);
        } else {
          available.push(c);
        }
      }
    });

    //let buttonStyle = (this.state.isOpen?{background: "rgba(0,0,0,.05)", borderColor: "#5691f0"}:{});

    return (
      <div ref={this.wrapperRef}>
        <MainButton
          className={
            this.state.isOpen
              ? "new-toolbar-button active"
              : "new-toolbar-button"
          }
          icon={<HiFields />}
          onClick={this.togglePanel}
          label="Fields"
        />
        {this.state.isOpen ? (
          <div className="hub-columnPicker-wrapper">
            <div className="arrow"></div>
            <div
              className="hub-columnPicker"
              ref={(x) => (this.filterSearch = x)}
            >
              <div className="title">
                <span>Show Fields</span>
                <FontAwesomeIcon
                  style={{ cursor: "pointer" }}
                  color="#3272d9"
                  icon={faXmark}
                  onClick={this.togglePanel}
                />
              </div>
              <div className="search">
                <input onChange={this.handleSearch} value={this.state.search} />
                <FontAwesomeIcon
                  style={{ position: "relative", top: "-26px", left: "12px" }}
                  color="#3272d9"
                  icon={faMagnifyingGlass}
                />
              </div>
              <div className="inner">
                {selected.length > 0 ? (
                  <div className="title">Selected</div>
                ) : (
                  false
                )}
                {selected.map((c) => {
                  return (
                    <div key={"pick-" + c.id} className="column">
                      <input
                        type="checkbox"
                        checked={this.state.selectedItems.indexOf(c.id) > -1}
                        onChange={() => {
                          this.toggleSelect(c.id);
                        }}
                      />
                      {c.label}
                    </div>
                  );
                })}
                {available.length > 0 ? (
                  <div className="title">Available</div>
                ) : (
                  false
                )}
                {available.map((c) => {
                  return (
                    <div key={"pick-" + c.id} className="column">
                      <input
                        type="checkbox"
                        checked={this.state.selectedItems.indexOf(c.id) > -1}
                        onChange={() => {
                          this.toggleSelect(c.id);
                        }}
                      />
                      {c.label}
                    </div>
                  );
                })}
              </div>
              <div className="buttonBar">
                <MainButton label="Apply" onClick={this.apply} />
              </div>
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default ColumnPicker;

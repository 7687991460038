import React, { Component } from 'react'
import SearchArea from '../inputControls/searchArea'


import Table from './table'
export default class TableWithSearch extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       searchString:"",
       description:false
    }
  }



    setSearchString = (v) => {this.setState({...this.state, searchString:v})}


  render() {

    
    return (
      <div>
        <div className='obs-search-area' style={{width:"90%"}}>
            <SearchArea onChange={this.setSearchString} value={this.state.searchString} />
        </div>
        <Table labelKey={this.props.labelKey} data={this.props.items} searchString={this.state.searchString} toggleSelectBar={this.props.toggleSelectBar}/>
    
         
      </div>
    )
  }
}

import React from "react";
import BarText from "./BarText";

const ActivityGanttBar = ({
  data,
  onMouseOver,
  onMouseLeave,
  isSelectedRow,
}) => {
  const { ganttWidth, ganttLeft, name } = data;
  return (
    <div
      onMouseMove={onMouseOver}
      onMouseLeave={onMouseLeave}
      /* className={"hub-gantt-item-activity" + (hasChild ? " summary" : "")} */
      className={"hub-gantt-item-activity"}
      style={{
        width: ganttWidth,
        left: ganttLeft,
        height: 10,
        top: "50%",
        transform: "translate(0, -50%)",
      }}
    >
      <BarText name={name} ganttWidth={ganttWidth} />
      <span
        className="start-mark"
        style={{
          position: "absolute",
          left: "0px",
          top: -3,
          width: "4px",
          height: "16px",
          backgroundColor: "#35D69C",
          borderRadius: "2px",
        }}
      />
      <span
        className="shape-element"
        style={{
          position: "absolute",
          left: "4px",
          top: -3,
          width: ganttWidth - 8,
          height: "6px",
          backgroundColor: isSelectedRow ? "#eef7ff" : "#fff",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        }}
      />
      <span
        className="shape-element"
        style={{
          position: "absolute",
          left: "4px",
          top: 7,
          width: ganttWidth - 8,
          height: "6px",
          backgroundColor: isSelectedRow ? "#eef7ff" : "#fff",
          borderTopLeftRadius: "3px",
          borderTopRightRadius: "3px",
        }}
      />
      <span
        className="start-mark"
        style={{
          position: "absolute",
          right: "0px",
          top: -3,
          width: "4px",
          height: "16px",
          backgroundColor: "#35D69C",
          borderRadius: "4px",
        }}
      />
    </div>
  );
};

export default ActivityGanttBar;

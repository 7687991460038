import React, { useState } from "react";

import NavigationSection from "./NavigationSection";
import "./Navigation.css";
import HiHome from "../common/icons/HiHome";
import HiUsers from "../common/icons/HiUsers";
import HiTeamHub from "../common/icons/HiTeamHub";
import HiProjects from "../common/icons/HiProjects";
import HiRoadmap from "../common/icons/HiRoadmap";
import HiProgram from "../common/icons/HiProgram";
import HiChangeSet from "../common/icons/HiChangeSet";
import HiOtherWorks from "../common/icons/HiOtherWorks";
import HiResourceUtilization from "../common/icons/HiResourceUtilization";
import HiTimeSheets from "../common/icons/HiTimeSheets";
import HiMyTimeSheets from "../common/icons/HiMyTimeSheets";
import HiFields from "../common/icons/HiFields";
import MainAvatar from "../components/MainAvatar";

const menuElements = {
  sectionList: ["hub"],
  sections: {
    hub: {
      title: "Hub",
      items: [
        /*,{to:"/taskBoard", id:"pfTaskBoard", label:"Task Board", icon: "faClockRotateLeft"}*/
        {
          to: "/",
          id: "cmnHome",
          label: "Dashboard",
          icon: <HiHome />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        {
          to: "/teamHub",
          id: "tmHub",
          label: "Team Hub",
          icon: <HiTeamHub />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        /*   {
          to: "/demand",
          id: "pfDemands",
          label: "Demands",
          icon: <HiFields />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        }, */
        {
          to: "/project",
          id: "pfProjects",
          label: "Projects",
          icon: <HiProjects />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        {
          to: "/roadmap",
          id: "pfRoadmap",
          label: "Roadmap",
          icon: <HiRoadmap />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        {
          to: "/program",
          id: "pfProgram",
          label: "Programs",
          icon: <HiProgram />,
          authGroup: ["ADMIN"],
        },
        {
          to: "/changeset",
          id: "pfChangeSet",
          label: "Change Sets",
          icon: <HiChangeSet />,
          authGroup: ["ADMIN"],
        },
        {
          to: "/otherwork",
          id: "pfOtherWorks",
          label: "Other Works",
          icon: <HiOtherWorks />,
          authGroup: ["ADMIN"],
        },
        {
          to: "/resources",
          id: "rmResources",
          label: "Resources",
          icon: <HiUsers />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        {
          to: "/resourceUtilization",
          id: "rmResourceUtil",
          label: "Resource Utilization",
          icon: <HiResourceUtilization />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        ,
        {
          to: "/timesheets",
          id: "tsTimesheets",
          label: "Timesheets",
          icon: <HiTimeSheets />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
        ,
        {
          to: "/mytimesheet",
          id: "tsMyTimesheet",
          label: "My Timesheet",
          icon: <HiMyTimeSheets />,
          authGroup: [
            "ADMIN",
            "BMUH",
            "MUH",
            "PY",
            "UBMUH",
            "MUDUR",
            "DIREKTOR",
            "MANAGER",
            "USER",
            "IPT",
          ],
        },
      ],
    },
  },
};

const Navigation = ({ activeNavId, collapsed }) => {
  const [menuItems, setMenuItems] = useState(menuElements);

  return (
    <nav class="hub-nav">
      <MainAvatar />
      <div class="hub-navMenu">
        <div class="hub-navLogo hub-navLogo-arp">
          <div class="hub-navLogoCollapse">
            <img src="https://i.ibb.co/xXvZ6Zk/logo-hub-collapse.png" />
          </div>
          <div class="hub-navLogoExpand">
            <img src="https://i.ibb.co/s69P1rR/logo-hub-expand.png" />
          </div>
        </div>
        <div class="hub-navMenuItems">
          {menuItems.sectionList.map((e) => {
            return (
              <NavigationSection
                key={e}
                activeNavId={activeNavId}
                section={menuItems.sections[e]}
                tooltip="Export"
                collapsed={collapsed}
              />
            );
          })}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;

import React from "react";

const HiDownload = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M5.33325 7.99988L7.99992 10.6665M7.99992 10.6665L10.6666 7.99988M7.99992 10.6665V4.53321C7.99992 3.60607 7.99992 3.1425 7.63291 2.62348C7.38906 2.27862 6.68697 1.853 6.26844 1.79629C5.63851 1.71095 5.3993 1.83573 4.92087 2.08531C2.78881 3.1975 1.33325 5.42871 1.33325 7.99988C1.33325 11.6818 4.31802 14.6665 7.99992 14.6665C11.6818 14.6665 14.6666 11.6818 14.6666 7.99988C14.6666 5.53227 13.3259 3.37779 11.3333 2.22509"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiDownload;

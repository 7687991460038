import React, { Component } from 'react';


import Tab from '../../../common/tabs/Tab';
import TabGroup from '../../../common/tabs/TabGroup';


import TabContent from '../../../common/tabs/TabContent';
//import SideBarTaskDetails from './SideBarTaskDetails'
import SideBarTaskDetails from '../../task/SideBarTaskDetails';

//import TaskAssignments from './TaskAssignments'
import TaskSideNotes from './TaskSideNotes'
//import SideBarTaskStatus from './SideBarTaskStatus'
import SideBarTaskStatus from '../../task/SideBarTaskStatus';

import TaskAssignments from '../../task/TaskAssignments';
import BlockedTasks from './blockedTasks'

//import NewTask from './NewTask';

import NewTask from '../../task/NewTask';
class TaskBoardSidebarContainer extends Component {
      
  constructor(props) {
      super(props);
      this.state = {
          activePage: "task"
      }
  }


  setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
  }


  componentDidMount()
  {
   // console.log("componentDidMount",this.props)
      this.setState({...this.state, activePage:this.props.isNewTask?"newTask":(this.props.sideBarTab?this.props.sideBarTab:"task")});
  }

  componentDidUpdate(prevProps) {
    
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      this.setActivePage((this.props.sideBarTab?this.props.sideBarTab:"task"))   
    } 
     

}

       getTabs(){
             switch (this.props.sideBarType) {
              case "detail":
               default:
                return  <div className="hub-tabGroup-wrapper" >
                          <TabGroup>
                            <Tab to="task" onClick={this.setActivePage} label="Details" activePage={this.state.activePage}  />
                              <Tab to="taskAssignment" onClick={this.setActivePage} label="Assignments" activePage={this.state.activePage} />
                              <Tab to="taskStatus" onClick={this.setActivePage} label="Status" activePage={this.state.activePage} />
                              <Tab to="taskNotes" badgeContent={this.props.selectedRecord?.unreadCount} onClick={this.setActivePage} label="Conversation" activePage={this.state.activePage} />
                          </TabGroup> 
                          <TabContent to="task" activePage={this.state.activePage}>
                              <SideBarTaskDetails selectedRecord={this.props.selectedRecord} parentRecord={this.props} save={this.props.save}  handleDragEndCreator={this.props.handleDragEndCreator} pageType={"board"}/>
                          </TabContent>
                          <TabContent to="taskAssignment" activePage={this.state.activePage}>
                              <TaskAssignments selectedRecord={this.props.selectedRecord} title={this.props.title}/>
                          </TabContent>
                            <TabContent to="taskStatus" activePage={this.state.activePage}>
                              <SideBarTaskStatus selectedRecord={this.props.selectedRecord} title={this.props.title} parentRecord={this.props} save={this.props.save}/>
                          </TabContent>
                          <TabContent to="taskNotes" activePage={this.state.activePage}>
                              <TaskSideNotes selectedRecord={this.props.selectedRecord} title={this.props.title} />
                          </TabContent>
                        </div>
            
                        case "new":
                          
                          return <div className="hub-tabGroup-wrapper" >
                                    <TabGroup>
                                      <Tab to="task" onClick={this.setActivePage} label="New Task" activePage={this.state.activePage}  />
                                    </TabGroup> 
                                    <TabContent to="task" activePage={this.state.activePage}>
                                        <NewTask selectedRecord={this.props.selectedRecord} parentRecord={this.props} save={this.props.save}  pageType={"board"}/>
                                    </TabContent>
                                  
                                  </div>

                          case "block":
                          
                          return <div className="hub-tabGroup-wrapper" >
                                   <BlockedTasks blockTask={this.props.blockTask}/>
                                  </div>
                   

                 

                }
            }



    render() {
     //  console.log("sidebarcontainer",this.props)
       //console.log(this.props)
        
        return (
          this.getTabs() 

        );
    }
}




export default TaskBoardSidebarContainer;

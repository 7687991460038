import { skeletonSet } from "../../../static/config";
import SkeletonLoader from "../../skeletonLoader";
import "./Table.scss";

const Table = () => {
  const { label } = skeletonSet;

  const contents = [
    {
      id: 1,
      content: (
        <SkeletonLoader
          width={100}
          height={label.height}
          borderRadius={label.borderRadius}
        />
      ),
    },
    {
      id: 2,
      content: (
        <SkeletonLoader
          width={100}
          height={label.height}
          borderRadius={label.borderRadius}
        />
      ),
    },
    {
      id: 3,
      content: (
        <SkeletonLoader
          width={100}
          height={label.height}
          borderRadius={label.borderRadius}
        />
      ),
    },
    {
      id: 4,
      content: (
        <SkeletonLoader
          width={100}
          height={label.height}
          borderRadius={label.borderRadius}
        />
      ),
    },
  ];

  return (
    <div className="skeleton-table-container">
      <div className="skeleton-table-wrapper">
        <div className="skeleton-th-row">
          {contents.map((column) => (
            <div className="skeleton-th" key={column.id}>
              {column.content}
            </div>
          ))}
        </div>
        <div className="skeleton-tbody">
          {contents.map((row) => (
            <div key={row?.id} className="skeleton-td-row ">
              {contents.map((column) => {
                return (
                  <div key={column?.id} className="skeleton-td">
                    {row.content}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;

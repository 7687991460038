import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner, faEmptySet } from "@fortawesome/pro-duotone-svg-icons";
import Table from "../skeletonPatterns/Table";
export default class loadingScreen extends Component {
  render() {
    return this.props.isSidebarLoader ? (
      <Table />
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          left: "50%",
          top: "50%",
          fontWeight: "bold",
          transform: "translate(-50%,-50%)",
        }}
      >
        <FontAwesomeIcon
          icon={
            this.props.icon
              ? this.props.icon
              : this.props.noContent
              ? faEmptySet
              : faSpinner
          }
          color={"#D7EDFF"}
          size={"4x"}
          className={
            this.props.noSpin || this.props.noContent ? "" : "spinner2"
          }
        />
        <br />{" "}
        {this.props.message
          ? this.props.message
          : this.props.noContent
          ? "No Record"
          : "Loading..."}
      </div>
    );
  }
}

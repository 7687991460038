import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";
import PercentField from "../../common/inputControls/percentField";
import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";

import { DateUtils } from "./../../common/Commons";

import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import TextArea from "../../common/inputControls/textarea/TextArea";
class SideBarActivityDetails extends Component {
  /**/
  constructor() {
    super();

    this.du = new DateUtils();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
        actuals: "5",
      },
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[val] = e;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  componentDidMount() {
    //console.log("componentDidMount")
    //  console.log(this.props)
    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //  console.log(r);
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object[0],
          });

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  Update = () => {
    // console.log(this.state.formData)
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(this.state.formData),
    };

    fetch("/api/resource/" + this.state.formData.id, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(requestOptions);
        //console.log(r);
        if (r.status === 200) {
          /* 
                    console.log("oluşturuldu");
                        console.log(requestOptions);
                        console.log(this.state.data.id);
                  

                    */

          //  console.log(r);
          // this.removeItem();

          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="input-grid">
            {" "}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="startDate"
              label="Planned Start Date"
              value={this.du.Date2Str(this.state.formData.p6Plan?.plannedStart)}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="Planned Finish Date"
              value={this.du.Date2Str(
                this.state.formData.p6Plan?.plannedFinish
              )}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="startDate"
              label="Actual Start"
              value={this.du.Date2Str(this.state.formData.p6Plan?.actualStart)}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="Actual Finish"
              value={this.du.Date2Str(this.state.formData.p6Plan?.actualFinish)}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="Planned Units (h)"
              value={this.state.formData.p6Plan?.plannedUnits
                ?.toFixed(0)
                .toString()}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="Actual Units (h)"
              value={this.state.formData.p6Plan?.actualUnits
                ?.toFixed(0)
                .toString()}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="Remaining Units (h)"
              value={this.state.formData.p6Plan?.remainingUnits
                ?.toFixed(0)
                .toString()}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="At Completion Units (h)"
              value={this.state.formData.p6Plan?.atCompletionUnits
                ?.toFixed(0)
                .toString()}
              disabled={true}
            />
            <PercentField
              width="100%"
              onChange={this.handleChanges}
              changeArea="finishDate"
              label="% Complete"
              value={
                this.state.formData.p6Plan?.percentComplete
                  ? this.state.formData.p6Plan?.percentComplete
                  : "0"
              }
              disabled={true}
            />{" "}
          </div>
        </BodyArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default SideBarActivityDetails;

import React, { useEffect, useState } from "react";
import "./TR.scss";
import CheckBox from "../../checkbox";
import HubIcon from "../../hubIcon/HubIcon";
import HiArrowShortDown from "../../icons/HiArrowShortDown";
import HiArrowShortRight from "../../icons/HiArrowShortRight";

/**
 * TR is a table row component that supports selectable, expandable, and interactive rows.
 * It can be customized with checkboxes, hierarchical expansion, and custom click handlers.
 *
 * @param {object} props The component props.
 * @param {React.ReactNode} props.children The content to be rendered inside the row.
 * @param {function} [props.onClick] Optional click handler for the row.
 * @param {function} [props.onDoubleClick] Optional double-click handler for the row.
 * @param {object} props.row The data associated with the current row.
 * @param {number} props.rowIndex The index of the current row.
 * @param {boolean} [props.isSelected=false] Whether the row is selected.
 * @param {boolean} [props.isExpandable=false] Whether the row can be expanded to show child rows.
 * @param {boolean} [props.hasSelectBox=false] Whether the row should include a checkbox for selection.
 * @param {function} [props.onSelectRow] Function to call when the row is selected.
 * @param {boolean} [props.isSelectedAll=false] Whether all rows are selected.
 * @param {function} [props.setSelectedRows] Function to update the selected rows.
 * @param {object} [props.style] Additional inline styles to apply to the row.
 *
 * @returns {JSX.Element} The rendered TR component.
 *
 * @example
 * // Render a selectable and expandable table row with custom content
 * <TR
 *   row={{ uid: "1", hierLevel: 1, children: [] }}
 *   rowIndex={0}
 *   isSelected={false}
 *   isExpandable={true}
 *   hasSelectBox={true}
 *   onSelectRow={(rowIndex) => console.log("Row selected:", rowIndex)}
 *   isSelectedAll={false}
 *   setSelectedRows={(rows) => console.log("Selected rows:", rows)}
 * >
 *  <TD
 *   column={{ type: "percentage", dataKey: "progress" }}
 *   row={{ progress: 75 }}
 *   width={100}
 * />
 * </TR>
 */
const TR = ({
  children,
  onClick,
  onDoubleClick,
  onMouseEnter,
  onMouseLeave,
  row,
  rowIndex,
  isSelected,
  isExpandable,
  hasSelectBox,
  onSelectRow,
  isSelectedAll,
  setSelectedRows,
  isHovered,
  style,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    hasSelectBox && setIsChecked(isSelectedAll);
  }, [isSelectedAll]);

  const handleToggleExpand = (e) => {
    e.stopPropagation();
    setIsExpanded((prevState) => !prevState);
  };

  const handleClickCheck = (e) => {
    e.stopPropagation();
    setIsChecked((prevState) => !prevState);
    setSelectedRows((prevState) => {
      if (prevState.includes(rowIndex)) {
        // rowIndex is in the array, subtract it
        return prevState.filter((n) => n !== rowIndex);
      } else {
        // rowIndex is not in the array, add it
        return [...prevState, rowIndex];
      }
    });
  };

  return (
    <div
      className={`hud-dt-td-row ${isSelected ? "selected" : ""} ${
        isHovered ? "hovered" : ""
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...style }}
    >
      {hasSelectBox && (
        <div className="check-wrapper">
          <CheckBox
            id={row?.uid}
            onChange={handleClickCheck}
            isChecked={isChecked}
          />
        </div>
      )}
      {isExpandable && (
        <div
          className="hier-space"
          style={{
            paddingLeft: row?.hierLevel * 30,
          }}
          onClick={handleToggleExpand}
        >
          {!!row?.children?.length &&
            (isExpanded ? (
              <HubIcon fontSize={18} lineColor="#1769F1">
                <HiArrowShortDown />
              </HubIcon>
            ) : (
              <HubIcon fontSize={18}>
                <HiArrowShortRight />
              </HubIcon>
            ))}
        </div>
      )}
      {children}
    </div>
  );
};

export default TR;

import React, { Component } from 'react';

import InputControl from '../InputControl';

class TextField extends Component {

    constructor(props) {
      super(props);
      this.state={v: '', focus:false, error:false, errorMessage: ''}
      this.handleFocus = this.handleFocus.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    handleFocus(t) {
      let errorMessage = t===false&&this.props.required&&this.state.v.length==0?'This field is required.':'';
      let error = t===false&&this.props.required&&this.state.v.length==0?true:false;
      this.setState({...this.state, focus:t, error:error, errorMessage: errorMessage}) 
    }


    componentDidMount(){

      //console.log("componentDidMount TextField");
      //console.log(this.props);
      this.setState({...this.state, v:this.props.value?this.props.value:''}) 
    }

    componentDidUpdate(prevProps)
    {
      if(prevProps.value!==this.props.value)
      {
         this.setState({...this.state, v:this.props.value?this.props.value:''}) 
      }
    }


    onChange(e){this.setState({...this.state, v: e.target.value}); this.props.onChange(this.props.changeArea,e.target.value )}

    onKeyDown (e){
      if(this.props.onKeyDown!==undefined)
      {

         this.props.onKeyDown(e);
      }
     
    }

    render() {
        return (
            <InputControl inputValue={this.state.v} error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props}>
              <input onKeyDown={(e)=>this.onKeyDown(e)} onChange={this.onChange} disabled={this.props.disabled}  type={this.props.type?this.props.type:"text"} value={this.state.v} onFocus={()=>this.handleFocus(true)} onBlur={()=>this.handleFocus(false)} style={{...this.props.style}}/>
            </InputControl>
        );
    }

}

export default TextField;
import { useEffect, useRef } from "react";

const BodyArea = ({ style, children, setHasScroll }) => {
  const listRef = useRef(null);

  useEffect(() => {
    const checkForScroll = () => {
      if (listRef.current) {
        const { scrollHeight, clientHeight } = listRef.current;
        setHasScroll && setHasScroll(scrollHeight > clientHeight);
      }
    };

    // Check initially and whenever the component updates
    checkForScroll();

    // Optionally, set up a resize observer to check when the container's size changes
    const resizeObserver = new ResizeObserver(checkForScroll);
    resizeObserver.observe(listRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={listRef} className="body-area" style={style}>
      {children}
    </div>
  );
};

export default BodyArea;

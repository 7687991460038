import React, { Component } from "react";
import DataTable from "../../common/dataTable";
import TimesheetSideBar from "./../ts/TimesheetSideBar";
import PageHeader from "../../common/pageElements/pageHeader/";

import ResourceDetails from "./ResourceDetails";
import ResourceSidebar from "./ResourceSidebar";
import Auth from "./../../auth/Auth";
import SkeletonLoader from "../../common/skeletonLoader";

class ResourceList extends Component {
  render() {
    let data = Auth.getResourceObject();
    //console.log(data)
    const dtConfig = {
      endPoint: "/api/resource/childs",
      //endPoint: "/api/resource",
      columns: [
        ,
        {
          id: "uniqueName",
          dataKey: "obsInfo.uniqueName",
          label: "OBS",
          type: "text",
        },
        { id: "name", dataKey: "fullName", label: "Full Name", type: "text" },
        {
          id: "firstName",
          dataKey: "firstName",
          label: "First Name",
          type: "text",
        },
        {
          id: "last_name",
          dataKey: "lastName",
          label: "Last Name",
          type: "text",
        },
        {
          id: "user_name",
          dataKey: "uniqueName",
          label: "Username",
          type: "text",
        },
        { id: "email", dataKey: "email", label: "Email Address", type: "text" },
        {
          id: "roleInfo",
          dataKey: "roleInfo",
          label: "Role",
          type: "dynamicLookup",
          endpoint: "/api/resourcerole",
          searchAttribute: "name",
          valueAttribute: "uid",
          labelAttribute: "name",
        },
        {
          id: "isActive",
          dataKey: "isActive",
          label: "Is Active",
          type: "boolean",
        },
        {
          id: "subContractor",
          dataKey: "subContractor.name",
          label: "Sub Contractor",
          type: "text",
        },
      ],
      idAttribute: "uid",
      nameAttribute: "fullName",
      prebuiltFilters: [
        {
          code: "emptyRole",
          label: "Missing Role",
          filterSet: [
            {
              id: "roleInfo",
              dataKey: "roleInfo",
              label: "Role",
              type: "dynamicLookup",
              endpoint: "/api/resourcerole",
              searchAttribute: "name",
              valueAttribute: "uid",
              labelAttribute: "name",
              filter: {
                option: {
                  value: "null",
                  label: "Is Empty",
                  indicator: "Is Empty",
                },
                value: [],
                valueIds: [],
              },
            },
          ],
        },
      ],
      view: {
        visibleColumnList: [
          "uniqueName",
          "firstName",
          "last_name",
          "user_name",
          "email",
          "roleInfo",
          "isActive",
        ],
        hideSelectbox: true,
        hideActionbar: true,
      },
      sideBar: <ResourceSidebar />,
    };

    return (
      <>
        <PageHeader title="Resources" />

        <div className="hub-page">
          <DataTable config={dtConfig} />
        </div>
      </>
    );
  }
}

export default ResourceList;

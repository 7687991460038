import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

import Tab from '../../common/tabs/Tab';
import TabGroup from '../../common/tabs/TabGroup';

import TabContentGroup from '../../common/tabs/TabContentGroup';
import TabContent from '../../common/tabs/TabContent';


import OtherWorkDetails from './OtherWorkDetails';
import OtherWorkAssignments from './OtherWorkAssignments';
class OtherWorkSidebar extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            activePage: "otherDetail"
        }
    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }


    render() {
       
        
        return (
          <div className="hub-tabGroup-wrapper" >
            <TabGroup>
               <Tab to="otherDetail" onClick={this.setActivePage} label="Other Work Detail" activePage={this.state.activePage} />
               <Tab to="assignments" onClick={this.setActivePage} label="Assignments" activePage={this.state.activePage} />
            </TabGroup> 
            <TabContent to="otherDetail" activePage={this.state.activePage}>
                <OtherWorkDetails selectedRecord={this.props.selectedRecord} title={this.props.title} />
            </TabContent>
             <TabContent to="assignments" activePage={this.state.activePage}>
                <OtherWorkAssignments selectedRecord={this.props.selectedRecord} title={this.props.title} />
            </TabContent>
          </div>
        );
    }
}




export default OtherWorkSidebar;

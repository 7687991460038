import React from "react";

const HiChangeSet = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      viewBox="0 0 20 20"
    >
      <path
        d="M14.1667 1.66666L17.5 5M17.5 5L14.1667 8.33333M17.5 5H6.5C5.09987 5 4.3998 5 3.86502 5.27248C3.39462 5.51216 3.01217 5.89462 2.77248 6.36502C2.5 6.8998 2.5 7.59987 2.5 9V9.16666M2.5 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V10.8333M2.5 15L5.83333 18.3333M2.5 15L5.83333 11.6667"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiChangeSet;

import React, { useContext, useRef, useState } from "react";

import "./MainAvatar.scss";
import Avatar from "../../common/avatar";
import TooltipWithPage from "../../common/tooltipWithPage/tooltipWithPage";
import Auth from "../../auth/Auth";
import HubIcon from "../../common/hubIcon/HubIcon";
import HiUser from "../../common/icons/HiUser";
import HiLogout from "../../common/icons/HiLogout";
import NewModal from "../../common/newModal";
import ProfileSettings from "../ProfileSettings";
import CommonContext from "../../store/common-context";

const MainAvatar = () => {
  const [isShownModal, setIsShownModal] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const { userInfo } = useContext(CommonContext);

  const avatarRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };

  const handleMouseLeave = (event) => {
    if (
      !avatarRef.current.contains(event.relatedTarget) &&
      !tooltipRef.current.contains(event.relatedTarget)
    ) {
      setTooltipVisible(false);
    }
  };

  return (
    <>
      <div
        class="hub-navProfile "
        ref={avatarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          className={`hub-navProfile-wrapper ${
            isTooltipVisible ? "active" : ""
          }`}
        >
          <Avatar
            firstName={userInfo?.firstName}
            lastName={userInfo?.lastName}
            color={userInfo?.color}
            uid={userInfo?.uid}
            size="md"
            hasAvatar={
              userInfo?.profileImage?.cropImageHash &&
              userInfo?.profileImage?.profileImageHash
            }
            hash={userInfo?.profileImage?.cropImageHash}
          />
        </div>
        {isTooltipVisible && (
          <div ref={tooltipRef}>
            <TooltipWithPage
              style={{
                width: "190px",
                transform: "translate(10.5px,0px)",
                marginTop: "0",
                top: 4,
              }}
              arrowLocaiton={{
                position: "absolute",
                top: "25px",
                transform: "rotate(45deg)",
                left: -4,
                marginLeft: 0,
              }}
            >
              <div className="hub-user-info">
                <p className="user-name">{userInfo?.fullName}</p>
                <p className="user-role">{userInfo?.roleInfo?.name}</p>
              </div>
              <div className="hub-profile-options">
                <div
                  className="hub-profile-option"
                  onClick={() => setIsShownModal(true)}
                >
                  <HubIcon fontSize={14}>
                    <HiUser />
                  </HubIcon>
                  Profile
                </div>
                <div className="hub-profile-option" onClick={Auth.logout}>
                  <HubIcon fontSize={14}>
                    <HiLogout />
                  </HubIcon>
                  Log out
                </div>
              </div>
            </TooltipWithPage>
          </div>
        )}
      </div>
      {isShownModal && (
        <NewModal onClose={() => setIsShownModal(false)}>
          <ProfileSettings />
        </NewModal>
      )}
    </>
  );
};

export default MainAvatar;

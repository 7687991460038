import React from "react";

import "./Pagination.scss";
import MainButton from "../../buttons/MainButton";
import HiArrowShortLeft from "../../icons/HiArrowShortleft";
import HiArrowShortRight from "../../icons/HiArrowShortRight";

const Pagination = ({
  totalRows,
  page,
  startEntry,
  endEntry,
  totalPages,
  onClickNext,
  onClickPrev,
  isPrevButtonDisabled,
  isNextButtonDisabled,
}) => {
  return (
    <div className="hub-dt-pagination-container">
      <div className="action-section">
        <MainButton
          icon={<HiArrowShortLeft />}
          onClick={onClickPrev}
          disabled={isPrevButtonDisabled}
          className="tiny-icon"
          style={{ padding: "0" }}
        />
        <div className="page-box">
          <div className="page-text-box">
            <p style={{ margin: 0 }}>{` ${page + 1} `}</p>
          </div>
          <p>{`/ ${totalPages}`}</p>
        </div>

        <MainButton
          icon={<HiArrowShortRight />}
          onClick={onClickNext}
          disabled={isNextButtonDisabled}
          className="tiny-icon"
          style={{ padding: "0" }}
        />
      </div>
      <div className="label-section">
        <p className="entries-text">
          {`showing `}
          <span
            style={{ fontWeight: 600 }}
          >{`${startEntry} - ${endEntry}`}</span>
          {` of `}
          <span style={{ fontWeight: 600 }}>{`${totalRows}`}</span>
          {` entries`}
        </p>
      </div>
    </div>
  );
};

export default Pagination;

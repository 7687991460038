import React, { Component } from 'react';

import Auth from './../../auth/Auth';
import {Utils} from './../../common/Commons';

import {Navigate} from 'react-router-dom';

class MyTimesheet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: "",
            Loading: true
        }
    }

    componentDidMount() {

       this.getdata();

    }

    getdata=()=>
    {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        //console.log(Auth.checkResourceId(), Auth.getResourceObject())

        let resourceId=Auth.checkResourceId()

        fetch('/api/reportingperiod/resource/'+resourceId, requestOptions)
                    .then(response => response.json().then(data => ({status:response.status, body:data})))
                    .then(r => {
                        //console.log(r)
                        if(r.status===200) {
                            
                            let periods = r.body;
                            
                            let selectedPeriod = -1;
               
                            
                            if (periods.length == 0) {
                                this.setState ({Loading: false, redirectTo: ""})
                            } else {
                                
                                let today = new Date();
                                
                                let lock = 0;

                                periods.map( (p, i) => {
                                    
                                    if (lock === 0) {
                                        selectedPeriod = p.id;
                                    }
                                    

                                    // Eğer tarih bu dönem veya ilerisinde ise çık 

                                    let periodStart = new Date(p.startDate);
                                    let periodFinish = new Date(p.finishDate);

                                    if ((today >= periodStart && today < periodFinish ) || p.timesheetStatus.code === "open" ) {
                                        lock = 1;
                                    }

                                 });
                                 
                                this.setState ({Loading: false, redirectTo: "/timesheet/detail?resourceId="+resourceId+"&timeperiodId="+selectedPeriod})
                            }

                          
                        }
                        else if(r.status===401) {
                            window.location.replace("/login");
                        }
                        else if(r.status===403) {
                            //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                        }
                        else {
                            //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                        }
                    });

    }

    render() {
        return this.state.redirectTo!==""?<Navigate to={this.state.redirectTo} />:false

    }
}

export default MyTimesheet;
import React, { Component } from 'react';


import Tab from '../../../common/tabs/Tab';
import TabGroup from '../../../common/tabs/TabGroup';


import TabContent from '../../../common/tabs/TabContent';


import SideBarActivityDetails from '../../task/SideBarActivityDetails';
import SideBarActivityStatus from '../../task/SideBarActivityStatus';
import SideBarActivityAssignments from '../../task/SideBarActivityAssignments';

class ActivityBoardSidebarContainer extends Component {
      
  constructor(props) {
      super(props);
      this.state = {
          activePage: "activity"
      }
  }


  setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
  }


  componentDidMount()
  {
   // console.log("componentDidMount",this.props)
      this.setState({...this.state, activePage:(this.props.sideBarTab?this.props.sideBarTab:"activity")});
  }

  componentDidUpdate(prevProps) {
    
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      this.setActivePage((this.props.sideBarTab?this.props.sideBarTab:"activity"))   
    } 
     

}

       getTabs(){
          
                return  <div className="hub-tabGroup-wrapper" >
                          <TabGroup>
                 
                             
                              <Tab to="activity" onClick={this.setActivePage} label="Details" activePage={this.state.activePage} />
                              <Tab to="activityStatus" onClick={this.setActivePage} label="Status" activePage={this.state.activePage} />
                              <Tab to="activityAssignment" onClick={this.setActivePage} label="Assignments" activePage={this.state.activePage} />
                       
                          </TabGroup> 
                         
                          <TabContent to="activity" activePage={this.state.activePage}>
                              <SideBarActivityDetails selectedRecord={this.props.selectedRecord} project={this.props.project}  save={this.props.save}  handleDragEndCreator={this.props.handleDragEndCreator} pageType={"board"}/>
                          </TabContent>
                          <TabContent to="activityStatus" activePage={this.state.activePage} >
                              <SideBarActivityStatus selectedRecord={this.props.selectedRecord} title={this.props.title}/>
                          </TabContent>
                          <TabContent to="activityAssignment" activePage={this.state.activePage}  >
                            <SideBarActivityAssignments selectedRecord={this.props.selectedRecord} project={this.props.project} title={this.props.title}/>
                              
                          </TabContent>
                        </div>
            
                   

                 

                
            }



    render() {
     //  console.log("sidebarcontainer",this.props)
       //console.log(this.props)
        
        return (
          this.getTabs() 

        );
    }
}




export default ActivityBoardSidebarContainer;

import React, { Component } from "react";
import "./FilterSelector.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";

class FilterSelector extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isOpen: false,
      filterText: "",
    };
  }

  openSelector = (e) => {
    //console.log(0);
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, isOpen: true }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
      this.filterSearch.focus();
    });
  };

  closeSelector = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, isOpen: false, filterText: "" }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
      this.filterSearch.blur();
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.closeSelector(event);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClickOutside);
  }

  setFilterText = (e) => {
    this.setState({ ...this.state, filterText: e.target.value });
  };

  escFunction = (event) => {
    if (event.key === "Escape") {
      this.closeSelector(event);
    }
  };

  selectAttribute = (a) => {
    this.setState({ ...this.state, isOpen: false, filterText: "" }, () => {
      this.props.addFilter(a);
    });
  };

  render() {
    return (
      <div
        className={
          this.state.isOpen
            ? "hub-dataTable-filterSelector active"
            : "hub-dataTable-filterSelector"
        }
        ref={this.wrapperRef}
      >
        <div className="select">
          <input
            onKeyDown={this.escFunction}
            value={this.state.filterText}
            onChange={this.setFilterText}
            ref={(x) => (this.filterSearch = x)}
            placeholder={this.state.isOpen ? "Search..." : "Add Filter"}
            onFocus={this.openSelector}
          />
          <FontAwesomeIcon
            onClick={this.openSelector}
            icon={faAngleDown}
            width={12}
            style={{ cursor: "pointer" }}
          />
        </div>
        {this.state.isOpen ? (
          <div className="list">
            {this.props.columns.map((c) => {
              if (
                this.props.filterList.indexOf(c.id) == -1 &&
                (this.state.filterText === "" ||
                  new RegExp(this.state.filterText, "i").test(c.label))
              ) {
                return (
                  <div
                    key={"filterPicker-" + c.id}
                    className="attr"
                    onClick={() => {
                      this.selectAttribute(c);
                    }}
                  >
                    {" "}
                    {c.label}
                  </div>
                );
              } else {
                return false;
              }
            })}
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default FilterSelector;

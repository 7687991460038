import React, { Component } from 'react'
import PageHeader from '../../common/pageElements/pageHeader';
import DataTable from '../../common/dataTable';

import DemandCreate from './DemandCreate';
export default class DemandList extends Component {
  render() {


    let toolbarActionButtons=[]
    if(1)
    {
        toolbarActionButtons.push({
            code:"newDemand",
            label: "New Demand",
            onClick: 'showModal'
        })
    }
    const dtConfig = {
        endPoint: "/api/idea",
        columns: [
            {id:"code", dataKey:"code", label:"Code", type:"text"}
            ,{id:"name", dataKey:"name", label:"Name", type:"text", linkTo:"/demand/{uid}/details" }
            ,{id:"description", dataKey:"description", label:"Description", type:"text" }
            ,{id:"startDate", dataKey:"startDate", label:"Start Date", type:"date"}
            ,{id:"finishDate", dataKey:"finishDate", label:"Finish Date", type:"date"}
            ,{id:"size", dataKey:"size.name", label:"Size", type:"text"}
            ,{id:"type", dataKey:"type.name", label:"Type", type:"text"}

        ], 
        idAttribute: "uid",
        nameAttribute: "name",
        view: {
            visibleColumnList: ["code","name","description","startDate","finishDate","size","type"],
            hideSelectbox: true
        },
        modal: <DemandCreate />,
        modalOptions:{height:"80%",width:"70%"},
        
        toolbarActionButtons:toolbarActionButtons
        /*sideBar: <OtherWorkSidebar  />*/
    }

    return (
        <>
        <PageHeader
            title={"Demands"}
            /> 
        <div className="hub-page" >
            <DataTable config={dtConfig}  />
        </div>
        </>
    );
  }
}

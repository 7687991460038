import React, { Component } from 'react';

class SectionContainer extends Component {
    render() {
        return (
            <div style={{width:this.props.width?this.props.width:"50%", float:"left", ...this.props.style}}>
                {this.props.children}
            </div>
        );
    }
}

export default SectionContainer;
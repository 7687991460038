import React, { Component, useEffect } from "react";
import MainButton from "../../common/buttons/MainButton";

import "./TeamHub.css";

import TaskBoard from "./../taskBoard";

import TaskList from "./taskList";
import ActivityList from "./activityList";
import Timeline from "./timeline";
import Auth from "../../auth/Auth";

import ButtonSelect from "./../taskBoard/ButtonSelect";
import { DateUtils } from "../../common/Commons";

import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";
import TabContent from "../../common/tabs/TabContent";

import PageHeader from "../../common/pageElements/pageHeader";
import TooltipWithPage from "../../common/tooltipWithPage/tooltipWithPage";
import DatePicker from "./datePicker";
import { Utils } from "../../common/Commons";
import ActivityBoard from "../activityBoard/activityBoard";
import ActivityTimeline from "./timeline/activityTimeline";
import HubIcon from "../../common/hubIcon/HubIcon";
import HiDisplay from "../../common/icons/HiDisplay";
import OBSList from "./comps/teamPicker/OBSList";
import HiUsers from "../../common/icons/HiUsers";
import PickerButton from "../../common/buttons/PickerButton";
import { useLocation, useNavigate } from "react-router-dom";
class TeamHub extends Component {
  constructor(props) {
    super(props);
    this.dutils = new DateUtils();
    this.utils = new Utils();
    let current = new Date();
    this.state = {
      Loading: true,
      view: {
        mode: this.getModeFromPath(), // Initialize based on the path
      },
      obsList: [],
      selectedOBS: {},
      selectedBoardType: { id: 1, name: "Task" },
      boardTypes: [
        { id: 1, name: "Task" },
        { id: 2, name: "Activity" },
      ],
      counter: 0,
      showDatePanel: false,
      startDate: current.setDate(current.getDate() - 14),
      finishDate: current.setDate(current.getDate() + 28),
    };
  }

  getModeFromPath() {
    let location = this.props.location.pathname.split("/").pop();
    if (location === "teamHub") location = "board";
    return location; // Default to "board" if no path is provided
  }

  toggleShowDatePanel = () => {
    this.setState({ ...this.state, showDatePanel: !this.state.showDatePanel });
  };

  componentDidMount() {
    this.getData();
  }

  setMode = (mode) => {
    this.setState({ ...this.state, view: { ...this.state.view, mode: mode } });
    this.props.navigate(`/teamHub/${mode}`);
  };

  getData = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    //  console.log(obs)

    // !* kullanıcı önceden seçimli dateleri ve obs çeker
    let viewObject = await this.utils.getUserView("teamHub");
    let view = viewObject?.general;
    //console.log("view",view)

    fetch("/api/obs/teamboard", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status === 200) {
          //console.log("r",r,r.body.object[0])

          // console.log("view2",view)

          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            obsList: r.body.object,
            selectedOBS: view ? view.obs : r.body.object[0],
            startDate: view ? view.dates.startDate : this.state.startDate,
            finishDate: view ? view.dates.finishDate : this.state.finishDate,
          });

          //console.log(this.state.finishDate,this.dutils.Date2StrWithLine(this.state.startDate))
          // console.log(this.state.period.open)
        } else if (r.status === 401) {
          window.location.replace("/login");
        } else if (r.status === 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  setObs = (selected) => {
    //console.log(selected);
    this.setState({
      ...this.state,
      selectedOBS: selected,
      counter: this.state.counter + 1,
    });
    this.utils.updateUserView("teamHub", {
      dates: {
        startDate: this.state.startDate,
        finishDate: this.state.finishDate,
      },
      obs: selected,
    });
  };

  setBoardType = (selected) => {
    //console.log(selected);
    this.setState({
      ...this.state,
      selectedBoardType: selected,
      counter: this.state.counter + 1,
    });
  };

  setDates = (startDate, finishDate) => {
    //console.log("setDates",startDate,finishDate);
    this.setState({
      ...this.state,
      startDate: startDate,
      finishDate: finishDate,
      showDatePanel: false,
      counter: this.state.counter + 1,
    });

    this.utils.updateUserView("teamHub", {
      dates: { startDate: startDate, finishDate: finishDate },
      obs: this.state.selectedOBS,
    });
  };

  render() {
    const labelIcon = (
      <HubIcon>
        <HiDisplay />
      </HubIcon>
    );

    const teamIcon = (
      <HubIcon>
        <HiUsers />
      </HubIcon>
    );

    const teamTooltip = (isSelected, handleSelectOption) => {
      return (
        <TooltipWithPage
          style={{
            width: "336px",
            transform: "translate(0px, 16px)",
            right: "0px",
          }}
          arrowLocaiton={{ marginLeft: "250px" }}
        >
          <OBSList
            isSelected={isSelected}
            items={this.state.obsList}
            toggleSelectBar={handleSelectOption}
          />
        </TooltipWithPage>
      );
    };

    return !this.state.Loading ? (
      <>
        <PageHeader
          title={"Team Hub"}
          left={
            <TabGroup>
              <Tab
                onClick={() => {
                  this.setMode("board");
                }}
                label="Board"
                to={"board"}
                activePage={this.state.view.mode}
              />
              <Tab
                onClick={() => {
                  this.setMode("list");
                }}
                label="List"
                to={"list"}
                activePage={this.state.view.mode}
              />
              <Tab
                onClick={() => {
                  this.setMode("timeline");
                }}
                label="Timeline"
                to={"timeline"}
                activePage={this.state.view.mode}
              />
            </TabGroup>
          }
          right={
            <>
              <PickerButton
                label="Display"
                icon={labelIcon}
                selected={this.state.selectedBoardType}
                toggleSelected={this.setBoardType}
                style={{ marginRight: "10px" }}
                options={this.state.boardTypes}
                tooltipStyle={{
                  width: "153px",
                  transform: "translate(0px,16px)",
                  left:
                    this.state.selectedBoardType.name === "Activity"
                      ? "19px"
                      : "0px",
                }}
                arrowLocation={{ marginLeft: "120px" }}
              />
              <PickerButton
                label="Team"
                icon={teamIcon}
                selected={this.state.selectedOBS}
                toggleSelected={this.setObs}
                customTooltip={teamTooltip}
              />
            </>
          }
        />
        <div className="hub-page">
          <div className="hub-thub-body">
            {this.state.view.mode === "list" ? (
              this.state.selectedBoardType.name === "Task" ? (
                <TaskList
                  selectedOBS={this.state.selectedOBS}
                  view={this.state.view.mode}
                  counter={this.state.counter}
                  startDate={this.dutils.Date2StrWithLine(this.state.startDate)}
                  finishDate={this.dutils.Date2StrWithLine(
                    this.state.finishDate
                  )}
                />
              ) : (
                <ActivityList
                  selectedOBS={this.state.selectedOBS}
                  view={this.state.view.mode}
                  counter={this.state.counter}
                  startDate={this.dutils.Date2StrWithLine(this.state.startDate)}
                  finishDate={this.dutils.Date2StrWithLine(
                    this.state.finishDate
                  )}
                />
              )
            ) : (
              false
            )}
            {this.state.view.mode === "board" ? (
              this.state.selectedBoardType.name === "Task" ? (
                <TaskBoard
                  selectedOBS={this.state.selectedOBS}
                  view={this.state.view.mode}
                  startDate={this.dutils.Date2StrWithLine(this.state.startDate)}
                  finishDate={this.dutils.Date2StrWithLine(
                    this.state.finishDate
                  )}
                />
              ) : (
                <ActivityBoard
                  selectedOBS={this.state.selectedOBS}
                  view={this.state.view.mode}
                  startDate={this.dutils.Date2StrWithLine(this.state.startDate)}
                  finishDate={this.dutils.Date2StrWithLine(
                    this.state.finishDate
                  )}
                />
              )
            ) : (
              false
            )}
            {this.state.view.mode === "timeline" ? (
              this.state.selectedBoardType.name === "Task" ? (
                <Timeline
                  selectedOBS={this.state.selectedOBS}
                  view={this.state.view.mode}
                  startDate={this.dutils.Date2StrWithLine(this.state.startDate)}
                  finishDate={this.dutils.Date2StrWithLine(
                    this.state.finishDate
                  )}
                />
              ) : (
                <ActivityTimeline
                  selectedOBS={this.state.selectedOBS}
                  view={this.state.view.mode}
                  startDate={this.dutils.Date2StrWithLine(this.state.startDate)}
                  finishDate={this.dutils.Date2StrWithLine(
                    this.state.finishDate
                  )}
                />
              )
            ) : (
              false
            )}
          </div>
        </div>
      </>
    ) : (
      ""
    );
  }
}

const TeamHubWithRouter = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // If the current path is exactly /teamHub, redirect to /teamHub/board
    if (location.pathname === "/teamHub") {
      navigate("/teamHub/board");
    }
  }, [location.pathname, navigate]);

  return <TeamHub navigate={navigate} location={location} {...props} />;
};

export default TeamHubWithRouter;

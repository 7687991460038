import React, { Component } from "react";
import "./toDo.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPlus,
  faClipboardListCheck,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlusLarge } from "@fortawesome/pro-duotone-svg-icons";
import Auth from "../../../../auth/Auth";
import { Droppable } from "react-beautiful-dnd";
import ToDoList from "./ToDoList";

import ToDoNewInput from "./toDoNewInput";
import CardDetailSection from "./../comps/CardDetailSection";
import MainButton from "../../../../common/buttons/MainButton";
export default class ToDo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNewToDoInput: false,
      isToDoListOpen: false,
    };
  }

  toggleNewToDoInput = () => {
    this.setState({
      ...this.state,
      isNewToDoInput: !this.state.isNewToDoInput,
    });
  };

  toggleIsToDoListOpen = () => {
    this.setState({
      ...this.state,
      isToDoListOpen: !this.state.isToDoListOpen,
    });
  };

  newTodo = () => {
    //console.log("oktay")
    this.setState({
      ...this.state,
      isToDoListOpen: true,
      isNewToDoInput: false,
    });
  };

  crud = (method, value, attribute, todoId, todoIndex, object) => {
    console.log("crud", method);
    let array = object !== undefined ? object : {};

    array[attribute] = value;
    array.taskId = { uid: this.props.task.uid };

    this.props.toDoOperations(
      method,
      todoId,
      array,
      this.props.taskIndex,
      this.props.columnIndex,
      todoIndex
    );
    this.newTodo();
  };

  render() {
    // console.log("todo",this.props.task.toDo)

    let todoLength = this.props.task.toDo?.length;

    let completedArray = [];
    this.props.task?.toDo?.map((val) => {
      if (val.isCompleted) completedArray.push(val);
    });
    if (this.props.activeTab === "todo") {
      return (
        <CardDetailSection
          markerLeft={58 + this.props.markerLeft}
          key="cardToDoSection"
        >
          <div className="card-section-header">
            <div className="title">
              <span>
                <FontAwesomeIcon icon={faClipboardListCheck} />
              </span>
              <span>To Do List</span>
            </div>
            <div className="button-bar">
              {todoLength > 0 ? (
                <MainButton
                  label={this.state.isNewToDoInput ? "Cancel" : "Add New"}
                  className="new-plain-button"
                  onClick={this.toggleNewToDoInput}
                />
              ) : (
                false
              )}
            </div>
          </div>
          <div className="to-do">
            <div className="list">
              {todoLength === 0 ? (
                <ToDoNewInput
                  method={"POST"}
                  task={this.props.task}
                  taskIndex={this.props.taskIndex}
                  columnIndex={this.props.columnIndex}
                  crud={this.props.toDoOperations}
                  changeArea={"name"}
                  buttonName={"Create"}
                />
              ) : (
                false
              )}

              <ToDoList
                method="PATCH"
                task={this.props.task}
                taskIndex={this.props.taskIndex}
                columnIndex={this.props.columnIndex}
                // index={i}
                todoList={this.props.task.toDo}
                crud={this.props.toDoOperations}
              />

              {this.state.isNewToDoInput ? (
                <ToDoNewInput
                  method={"POST"}
                  task={this.props.task}
                  taskIndex={this.props.taskIndex}
                  columnIndex={this.props.columnIndex}
                  crud={this.props.toDoOperations}
                  changeArea={"name"}
                  buttonName={"Create"}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </CardDetailSection>
      );
    } else {
      return false;
    }
  }
}

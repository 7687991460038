import React, { Component } from "react";
import "./TextWidget.css";
import Widget from "./../Widget";
import TextWidgetRunner from "./TextWidgetRunner";

class TextWidget extends Component {
  /* 
        props  
            header: üst metin 
            value: string olarak değer
            subtext:  alttaki ifade
    */

  render() {
    return (
      <TextWidgetRunner
        config={this.props.config}
        selectedFilters={this.props.selectedFilters}
        counter={this.props.counter}
        multiplexingFilter={this.props.multiplexingFilter}
        multiplexingAttribute={this.props.multiplexingAttribute}
      />
    );
  }
}

export default TextWidget;

import React, { Component } from 'react';
import MainButton from  './../../../common/buttons/MainButton';

import './LayoutMenu.css';

import LayoutItem from './LayoutItem';
import LevelIcon from './LevelIcon';
import {faListTree, faTheta, faPen, faClone}  from '@fortawesome/pro-regular-svg-icons'
import {faArrowDownLong, faPlus, faPeopleGroup, faBriefcase, faUser, faFolderGrid, faNetworkWired, faBolt, faUserGroup}  from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailItem from './DetailItem';
import HiAdd from '../../../common/icons/HiAdd'
import HiProjects from '../../../common/icons/HiProjects'
import HiEdit2 from '../../../common/icons/HiEdit2';


class LayoutMenu extends Component {

    constructor(props) {
        super(props);
        
        this.levels = {
          obs: {code: "obs", name: "Department", icon:faPeopleGroup},
          resource: {code: "resource", name: "Resource", icon: faUser},
          role: {code: "role", name: "Role", icon: faBriefcase},
          project: {code: "project", name: "Project", icon: faFolderGrid},
          wbs: {code: "wbs", name: "WBS", icon: faNetworkWired},
          activity: {code: "activity", name: "Activity", icon: faBolt}
        }

        this.levelList = ['obs', 'resource', 'role', 'project', 'wbs', 'activity'];

        this.fields = {
          planned: {code: "planned", name: "Planned", icon:faTheta},
          actual: {code: "actual", name: "Actual", icon:faTheta},
          atCompletion: {code: "atCompletion", name: "At Completion", icon:faTheta},
          remaining: {code: "remaining", name: "Remaining", icon:faTheta},
          capacity: {code: "capacity", name: "Capacity", icon:faTheta},
        }

        this.fieldList = ['planned', 'actual', 'atCompletion', 'remaining', 'capacity'];

        this.wrapperRef = React.createRef();
        this.xbuttonRef = React.createRef();
        this.state = {loading:true, isOpen: false}
    }
    
    componentDidMount(){

      this.setState({...this.state, loading:false, selectedLayout:"", activeLayout:""})

    }
    togglePanel = () => {
        this.setState({...this.state, isOpen: !this.state.isOpen }, () => {
            setTimeout(() => {
              document.getElementsByClassName("hub-rmUtil-layoutMenu-wrapper")[0].style.opacity="1";
            })
            window.addEventListener("mouseup", this.handleClickOutside);
        })
    }

    handleClickOutside = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (this.wrapperRef && !this.wrapperRef.current?.contains(event.target)) {
            this.closeSelector(event);
      }
    }

    closeSelector = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({...this.state, isOpen: false, filterText:""}, () => {
            window.removeEventListener("mouseup", this.handleClickOutside);
        });
    }

    setActive = (l) => {
      this.setState({...this.state, activeLayout:l.code});
    }


    selectLayout = () => {
      this.setState({...this.state, selectedLayout:this.state.activeLayout, isOpen:false}, () => {
        this.props.selectLayout(this.state.activeLayout);
      });
    }

    editLayoutModal = () => {
        this.props.editLayoutModal(this.state.activeLayout);
    }

    render() {

      let activeItem = {};
      let activeLayoutInd = -1;
      if(!this.state.loading){
        activeLayoutInd = this.props.layout.layoutList.indexOf(this.state.activeLayout);
        
        if(activeLayoutInd!==undefined) {
          activeItem = this.props.layout.layouts[this.props.layout.layoutList.indexOf(this.state.activeLayout)];
        }  
      } 

      // Selected Layout name from prop
      let selectedLayoutName = "";
      if(this.props.layout.selectedLayout && this.props.layout.selectedLayout!==""){
        selectedLayoutName = this.props.layout.layouts[this.props.layout.layoutList.indexOf(this.props.layout.selectedLayout)].name
      }
            
      return (
        this.state.loading?<div>Loading</div>:<>
            <MainButton onClick={this.togglePanel} iconLineColor = '#1d5bbf' label={"Selected Layout: " + selectedLayoutName} icon={<HiProjects />} className="button-4" />
            {this.state.isOpen?<div ref={this.wrapperRef} className="hub-rmUtil-layoutMenu-wrapper">
              <div className="arrow"></div>
              <div className="hub-rmUtil-layoutMenu">
                <div className="header">
                  <div>Select Layout</div>
                  <div className="buttonBar">
                    <MainButton onClick={this.props.newLayoutModal} style={{height:32, border:"1px solid #DFE4ED"}} className="tiny-icon" icon={<HiAdd />} /> 
                    {activeItem?.isSystem===false?<MainButton onClick={this.editLayoutModal} style={{height:32, border:"1px solid #DFE4ED"}} className="tiny-icon" icon={<HiEdit2/>} />:false}
                    {/*<MainButton style={{height:32, border:"1px solid #DFE4ED"}} className="tiny-icon" icon={faClone} /> */}
                    {(activeLayoutInd > -1 && activeItem?.code !== this.state.selectedLayout)?<MainButton style={{height:32}} onClick={this.selectLayout} className="hub-button button-area-action" label="Select" />:false} 
                  </div>
                </div>
                <div className="body">
                  <div className="column">
                      <div className="layoutItemList">
                          {this.props.layout.layouts.map((l, i) => {
                              return <LayoutItem key={"LayoutMenuLayoutItem"+l.code} selected={l.code===this.props.layout.selectedLayout} ind={i} active={l.code===this.state.activeLayout} item={l} key={"listItem-"+l.uid?.toString()} onClick={this.setActive} name={l.name} />
                          })}
                      </div>
                  </div>
                  <div className="column bg">
                    {activeLayoutInd>-1?<div className="layoutItemDetails">
                      <div className="detailHeader">{activeItem.name}</div>
                      <div>{activeItem.levels.map( (x, i) => {
                          return <DetailItem key={"LayoutMenuGroup"+this.levels[x]?.code} level={i} icon={this.levels[x]?.icon} label= {this.levels[x]?.name} /> 
                      } )}</div>
                      <div className="detailHeader" style={{color:"#000000", marginTop:17}}>Fields</div>
                      <DetailItem key={"LayoutMenuPrimaryField"} icon={this.fields[activeItem.primaryField]?.icon} label={this.fields[activeItem.primaryField]?.name} /> 
                      <DetailItem key={"LayoutMenuSecondaryField"}  icon={this.fields[activeItem.secondaryField]?.icon} label={this.fields[activeItem.secondaryField]?.name} /> 
                    </div>:false}
                  </div>
                  <div className="cb"></div>
                </div>  
              </div>
            </div>:false}
          </>
      );
    }
}

export default LayoutMenu;
import React, { Component } from "react";
import ContainerCreator from "./containerCreator";
import WidgetCreator from "./widgetCreator";
export default class childCreator extends Component {
  render() {
    let c = this.props.childs;

    return c
      ? c.map((child) => {
          return child.type === "container" ? (
            <ContainerCreator
              container={child}
              selectedFilters={this.props.selectedFilters}
              counter={this.props.counter}
              multiplexingFilter={this.props.multiplexingFilter}
              multiplexingAttribute={this.props.multiplexingAttribute}
            />
          ) : (
            <WidgetCreator
              childs={c}
              code={child.code}
              style={child.style}
              selectedFilters={this.props.selectedFilters}
              counter={this.props.counter}
              multiplexingFilter={this.props.multiplexingFilter}
              multiplexingAttribute={this.props.multiplexingAttribute}
            />
          );
        })
      : false;
  }
}

import React from "react";

const HiFileXL = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M18.6666 3.026V8.53339C18.6666 9.28012 18.6666 9.65349 18.8119 9.93871C18.9397 10.1896 19.1437 10.3936 19.3946 10.5214C19.6798 10.6667 20.0532 10.6667 20.7999 10.6667H26.3073M26.6666 13.3176V22.9333C26.6666 25.1735 26.6666 26.2936 26.2306 27.1493C25.8471 27.9019 25.2352 28.5138 24.4825 28.8973C23.6269 29.3333 22.5068 29.3333 20.2666 29.3333H11.7333C9.49304 29.3333 8.37294 29.3333 7.51729 28.8973C6.76464 28.5138 6.15272 27.9019 5.76923 27.1493C5.33325 26.2936 5.33325 25.1735 5.33325 22.9333V9.06663C5.33325 6.82642 5.33325 5.70631 5.76923 4.85066C6.15272 4.09802 6.76464 3.48609 7.51729 3.1026C8.37294 2.66663 9.49304 2.66663 11.7333 2.66663H16.0156C16.994 2.66663 17.4832 2.66663 17.9435 2.77715C18.3517 2.87513 18.7418 3.03675 19.0997 3.25607C19.5034 3.50343 19.8493 3.84934 20.5411 4.54114L24.7921 8.79211C25.4839 9.48392 25.8298 9.82982 26.0771 10.2335C26.2965 10.5914 26.4581 10.9816 26.5561 11.3897C26.6666 11.85 26.6666 12.3392 26.6666 13.3176Z"
        stroke="#647795"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiFileXL;

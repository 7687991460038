import React, { Component } from 'react'
import MainButton from '../../../../common/buttons/MainButton'
import DynamicLookup from '../../../../common/inputControls/lookup/DynamicLookup';
import TextArea from '../../../../common/inputControls/textArea2/textArea';
import { Utils } from '../../../../common/Commons';

export default class blockCreate extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
        blockResolveDescription: "",
        blockDescription:"",
        blockOwner:"",
        blockReason:"",
    }
  }

  componentDidMount(){

      //console.log("componentDidMount TextField");
      //console.log(this.props);
      this.setState({...this.state, 
        blockDescription:this.props.task.isBlocked && this.props.task.blockDescription?this.props.task.blockDescription:"",
        blockReason:this.props.task.isBlocked && this.props.task.blockReason?this.props.task.blockReason:"",
        blockOwner:this.props.task.isBlocked && this.props.task.blockOwner?this.props.task.blockOwner:""
      }) 
    }


    
    onChangeBlockDescription=(formId,value)=>
    {
      //console.log(formId,value);
        this.setState({...this.state, blockDescription: value}); 
        //this.props.toggleBlock("blockDescription",e.target.value,this.props.task.uid,this.props.columnIndex,this.props.taskIndex )

    }


    
    onChangeBlockReason=(formId,value)=>
    {

       //console.log(formId,value);
       this.setState({...this.state, blockReason: value}); 
        //this.props.toggleBlock("blockDescription",e.target.value,this.props.task.uid,this.props.columnIndex,this.props.taskIndex )

    }


    
    
    onChangeBlockOwner=(formId,value)=>
    {

       //console.log(formId,value);
       this.setState({...this.state, blockOwner: value}); 
        //this.props.toggleBlock("blockDescription",e.target.value,this.props.task.uid,this.props.columnIndex,this.props.taskIndex )

    }



  saveBlock=(blockType)=>
  {
      // console.log("blockType",blockType,this.state)

        if(this.state.blockDescription==="" || this.state.blockOwner===""  || this.state.blockReason==="")
        {
            const u = new Utils();  
            u.addNotification("error", "All areas must be entered.")
        }

        else
        {

        
          let blockObject={};
        
          if(blockType===1)
          {
          //blockOwner.id=this.state.blockOwner.id
            blockObject.blockDescription=this.state.blockDescription
            blockObject.blockReason=this.state.blockReason
            blockObject.blockOwner=this.state.blockOwner
            blockObject.isBlocked=true
          }

      

        // console.log("blockObject",this.props.task.uid, blockObject, this.props.columnIndex, this.props.cardIndex,this.props.task.stage.code)

          this.props.blockTask(this.props.task.uid, blockObject, this.props.columnIndex, this.props.cardIndex,this.props.task.stage.code)
          this.props.closePanel();
        }
  }



    
  render() {
    return (
            <div style={{display:"flex",flexDirection:"column"}}>
              <div  style={{display:"flex",flexDirection:"row"}}>
                <DynamicLookup required={true} endpoint="/api/resource/blockowner" value={{value:this.state.blockOwner?.uid, label: this.state.blockOwner?.fullName }} onChange={this.onChangeBlockOwner} formId="blockOwner" searchAttribute="fullName" valueAttribute="uid" labelAttribute="fullName"  width="50%" label="Block Owner"/>
                <DynamicLookup required={true} endpoint="/api/valuelistoptions/parent/29" value={{value:this.state.blockReason?.uid, label: this.state.blockReason?.name }} 
                        onChange={this.onChangeBlockReason} formId="uid" searchAttribute="name" valueAttribute="uid" labelAttribute="name"  width="50%" label="Block Reason" />
              </div>

              <div  style={{height:"100px"}}>
                <TextArea required={true} width="98%" rows="2" label=" Description" defaultValue={this.state.blockDescription} value={this.state.blockDescription} onChange={this.onChangeBlockDescription} changeArea="blockDescription" />
          
              </div>
              <div  style={{display:"flex",justifyContent:"flex-end"}}>
                <MainButton onClick={()=>this.saveBlock(1)} className="button-1" label="Save" />
              </div>
            </div>
    )
  }
}

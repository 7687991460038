import http, { axiosFileUpload as httpFile } from "../http-common";

export const postFile = (file, handleUploadProgress) => {
  return httpFile.post("/api/upload", file, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      handleUploadProgress(percentCompleted);
    },
  });
};

export const downloadFile = (hash) => {
  return http.get(`/api/download/${hash}`, {
    responseType: "blob", // Blob olarak döneceğini belirtiyoruz
  });
};

import React, { Component } from 'react'
import './tooltip.css'
export default class tooltip extends Component {

    
 constructor(props) {
   super(props)
 
   this.state = {
      visible:false
   }
 }

        componentDidUpdate(prevProps){
            if(prevProps.close!==this.props.close)
            {
                this.toggleVisible();
            }
            
        }

    toggleVisible = ()=>{
        this.setState({...this.state,visible:!this.state.visible})
    }

  render() {
    

    return (
        this.state.visible?
         <div className={"tooltip "+(this.props.class!==undefined?this.props.class:"top")} style={{...this.props.style}}>
                <div className="tooltip-arrow"></div>
                <div className="tooltip-label" style={{backgroundColor:this.props.color!==undefined?this.props.color:"#fff" }}>
                  <div className="description" >{this.props.label}</div>
                 
                 
                  {this.props.milestoneType!==undefined?<div className="milestoneType" >{this.props.milestoneType}</div>:""}
                   {this.props.date!==undefined?<div className="date" >{this.props.date}</div>:""}
                </div>
        </div>:''
       
    )
  
  }
}

import React, { Component } from "react";
import Tooltip from "../tooltip/tooltip";
import HubIcon from "../hubIcon/HubIcon";
import HiHelpCircle from "../icons/HiHelpCircle";
export default class infoIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleTooltip: false,
      svgColor: "#D2DEF2",
    };
  }

  handleOpenTooltip = () => {
    this.setState({
      ...this.state,
      svgColor: "#1254C1",
      toggleTooltip: true,
    });
  };

  handleCloseTooltip = () => {
    this.setState({
      ...this.state,
      svgColor: "#D2DEF2",
      toggleTooltip: false,
    });
  };

  render() {
    return (
      <div
        className="info-icon"
        onMouseOver={this.handleOpenTooltip}
        onMouseOut={this.handleCloseTooltip}
      >
        <HubIcon lineColor={this.state.svgColor}>
          <HiHelpCircle />
        </HubIcon>

        {this.state.toggleTooltip && (
          <div className="hub-widget-info-tooltip-centered">{this.props.info}</div>
        )}
      </div>
    );
  }
}

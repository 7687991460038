import HubIcon from "../hubIcon/HubIcon";
import "./FileBadge.scss";
import HiClose from "../icons/HiClose";
import HiFile from "../icons/HiFile";
import HiImage from "../icons/HiImage";

const FileBadge = ({ fileName, onDeleteFile, editable, isImage, hash }) => {
  return (
    <div className="file-badge-container">
      <span className="icon" onClick={() => onDeleteFile(hash)}>
        {isImage ? (
          <HubIcon lineColor="#1769F1" fontSize={15}>
            <HiImage />
          </HubIcon>
        ) : (
          <HubIcon lineColor="#1769F1" fontSize={15}>
            <HiFile />
          </HubIcon>
        )}
      </span>
      <p>{fileName}</p>
      {editable && (
        <span className="icon" onClick={() => onDeleteFile(hash)}>
          <HubIcon lineColor="#1769F1" fontSize={14}>
            <HiClose />
          </HubIcon>
        </span>
      )}
    </div>
  );
};

export default FileBadge;

import React from "react";

const HiResourceUtilization = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      viewBox="0 0 20 20"
    >
      <path
        d="M3.33337 18.1812C3.83552 18.3333 4.51376 18.3333 5.66669 18.3333H14.3334C15.4863 18.3333 16.1645 18.3333 16.6667 18.1812M3.33337 18.1812C3.2257 18.1486 3.12613 18.109 3.03171 18.0608C2.56131 17.8212 2.17885 17.4387 1.93917 16.9683C1.66669 16.4335 1.66669 15.7335 1.66669 14.3333V5.66666C1.66669 4.26653 1.66669 3.56647 1.93917 3.03169C2.17885 2.56128 2.56131 2.17883 3.03171 1.93915C3.56649 1.66666 4.26656 1.66666 5.66669 1.66666H14.3334C15.7335 1.66666 16.4335 1.66666 16.9683 1.93915C17.4387 2.17883 17.8212 2.56128 18.0609 3.03169C18.3334 3.56647 18.3334 4.26653 18.3334 5.66666V14.3333C18.3334 15.7335 18.3334 16.4335 18.0609 16.9683C17.8212 17.4387 17.4387 17.8212 16.9683 18.0608C16.8739 18.109 16.7743 18.1486 16.6667 18.1812M3.33337 18.1812C3.33365 17.5067 3.33769 17.1499 3.3974 16.8497C3.66043 15.5274 4.69408 14.4937 6.01639 14.2307C6.33838 14.1667 6.72559 14.1667 7.50002 14.1667H12.5C13.2744 14.1667 13.6617 14.1667 13.9837 14.2307C15.306 14.4937 16.3396 15.5274 16.6026 16.8497C16.6623 17.1499 16.6664 17.5067 16.6667 18.1812M13.3334 7.91666C13.3334 9.75761 11.841 11.25 10 11.25C8.15907 11.25 6.66669 9.75761 6.66669 7.91666C6.66669 6.07571 8.15907 4.58333 10 4.58333C11.841 4.58333 13.3334 6.07571 13.3334 7.91666Z"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiResourceUtilization;

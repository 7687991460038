import { useState } from "react";
import Tooltip from "../../../../common/tooltip/tooltip.js";

import Avatar from "../../../../common/avatar/index.js";

const CardAvatar = ({
  size,
  isSelected,
  assignment,
  style,
  setAssignmentTab,
}) => {
  const [toggleTooltip, setToggleTooltip] = useState(false);

  const handleToggleTooltip = () => {
    setToggleTooltip((prevState) => !prevState);
  };

  return size === undefined ? (
    <div
      className={`avatar ${isSelected ? "active" : ""}`}
      style={{
        ...style,
      }}
      onClick={setAssignmentTab}
      onMouseOver={handleToggleTooltip}
      onMouseOut={handleToggleTooltip}
    >
      <Avatar
        firstName={assignment?.owner?.firstName}
        color={assignment?.owner?.color}
        uid={assignment?.owner?.uid}
        size="sm"
        hasAvatar={
          assignment?.owner?.profileImage?.cropImageHash &&
          assignment?.owner?.profileImage?.profileImageHash
        }
        letterCount={1}
      >
        <Tooltip
          label={assignment?.owner?.fullName}
          close={toggleTooltip}
          class="top-avatar"
        />
      </Avatar>
    </div>
  ) : (
    <div
      className="avatar"
      style={{
        backgroundColor: "#fff",
        color: "#42403C",
        border: "1px solid rgba(74, 124, 204, 0.20)",
        textAlign: "center",
        fontFamily: "Noto Sans",
        fontsize: 11,
        fontWight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        ...style,
      }}
    >
      +{size}
    </div>
  );
};

export default CardAvatar;

import React, { Component } from 'react';
import {DateUtils} from './../../../common/Commons'


class TD extends Component {
    render() {
        let util = new DateUtils();

        
        let label = "";
        if(this.props.value!==null) {
            switch(this.props.type) {
                case 'text':
                    label = this.props.value;
                    break;
                case 'date':
                    label = util.Date2Str(this.props.value);
                    break;
                case 'number':
                    label = (this.props.value!==undefined && this.props.value!==null) ?(this.props.value).toFixed(2):'';
                    break;
                case 'percentage':
                    label = this.props.value?(this.props.value).toFixed(0)+'%':'0%';
                    break;    
                case 'hour': 
                    label=this.props.value?(this.props.value).toFixed(0)+'h':'0h';
                    break;
                case 'day': 
                    label=this.props.value?(this.props.value).toFixed(0)+'d':'0d';
                    break;
                default:
                    return <td></td>
            } 
        }
        
        return (
            <td className={this.props.className?this.props.className:false}>
                <div style={{textAlign:(this.props.align?this.props.align:"left")}}>
                     {label}
                </div>
            </td>
        );
    }
}

export default TD;
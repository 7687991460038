import React, { Component } from 'react';
import PageNavigation from '../../../common/page-navigation';
import PageHeader from './../../../common/pageElements/pageHeader/'

import {
    Routes,
    Route,
  } from "react-router-dom";
import Details from './Details';
import ProgramDashboards from '../dashboard';    
import ProgramRoadmap from '../projectWBS';
import Auth from '../../../auth/Auth';
import { useParams } from 'react-router';
class ProgramDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                {to:"details", id:"programDetails", label:"Properties", icon: ""},
                {to:"roadmap", id:"programRoadmap", label:"Roadmap", icon: ""},
                {to:"dashboard", id:"programDashboard", label:"Dashboard", icon: ""}
              
            ],  
            program: {}
        }
    }

    getRecord = (e) => {
        //console.log("getRecord",e);
        //console.log(e);
         this.setState({...this.state, program:e});

         this.props.setSelectedRecord(e)
    }



    

componentDidMount() {
    this.getData();
  }
  
  
  
  getData = () =>{
     const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
  
    };
  
    fetch('/api/program/'+this.props.params.programid, requestOptions)
        .then(response => response.json().then(data => ({status:response.status, body:data})))
        .then(r => {
           // console.log('Status',r,r.body.object)
            if(r.body.status==200) {
                this.setState({...this.state, Loading:false, program:r.body.object});
  
               // this.props.selectedRecord(r.body.object[0])
            }
            else if(r.body.status==401) {
                window.location.replace("/login");
            }
            else if(r.body.status==403) {
                this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"You are not authorized to see this page. Please contact your system administrator."});
            }
            else {
                this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"An unexpected error occured. Please contact your system administrator."});
            }
        });
  }


    render() {

       //console.log("programDetails",this.state)
        return (
            <>
            <PageHeader
                title={(this.state.program?.name?this.state.program.name:"")}
                left={<PageNavigation links = {this.state.links} selectedRecord={this.state.program} setSelectedRecord={this.props.setSelectedRecord} defaultPage={1} type={"changeSet"}/>}
                /> 
            <div className="hub-page">
                
                <Routes>
                    <Route path="/details" element={<Details program={this.state.program}  selectedRecord={this.getRecord}/>} />
                    <Route path="/roadmap" element={<ProgramRoadmap program={this.state.program} selectedRecord={this.getRecord}/>} />
                    <Route path="/dashboard" element={<ProgramDashboards program={this.state.program} selectedRecord={this.getRecord}/>} />
                    
              
                </Routes>
            </div>
            </>
        );
    }
}


export default (props) => (
    <ProgramDetails 
        {...props}
        params={useParams()}
        />
); 
import React, { Component } from 'react';

class HeaderCol extends Component {
    render() {
        return (
            <div className="hub-rmUtil-headerCol" style={{width:this.props.width , ...this.props.style}}>
                {this.props.label}
            </div>
        );
    }
}

export default HeaderCol;
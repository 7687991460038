import React from "react";

const HiTodo = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      viewBox="0 0 20 20"
    >
      <path
        d="M12.933 3.82235C13.677 3.82235 14.049 3.82235 14.3542 3.90413C15.1824 4.12605 15.8293 4.77296 16.0512 5.60119C16.133 5.90639 16.133 6.27837 16.133 7.02235V14.3824C16.133 15.7265 16.133 16.3985 15.8714 16.9119C15.6413 17.3635 15.2742 17.7307 14.8226 17.9608C14.3092 18.2224 13.6371 18.2224 12.293 18.2224H7.17301C5.82888 18.2224 5.15682 18.2224 4.64343 17.9608C4.19184 17.7307 3.82469 17.3635 3.59459 16.9119C3.33301 16.3985 3.33301 15.7265 3.33301 14.3824V7.02235C3.33301 6.27837 3.33301 5.90639 3.41479 5.60119C3.63671 4.77296 4.28362 4.12605 5.11184 3.90413C5.41704 3.82235 5.78903 3.82235 6.53301 3.82235M6.6202 9.50006H12.4962M6.6202 13.5001H11M7.81301 5.42235H11.653C12.101 5.42235 12.3251 5.42235 12.4962 5.33516C12.6467 5.25846 12.7691 5.13607 12.8458 4.98554C12.933 4.81441 12.933 4.59039 12.933 4.14235V3.50235C12.933 3.05431 12.933 2.83029 12.8458 2.65916C12.7691 2.50863 12.6467 2.38624 12.4962 2.30955C12.3251 2.22235 12.1011 2.22235 11.653 2.22235H7.81301C7.36497 2.22235 7.14094 2.22235 6.96982 2.30955C6.81929 2.38624 6.6969 2.50863 6.6202 2.65916C6.53301 2.83029 6.53301 3.05431 6.53301 3.50235V4.14235C6.53301 4.59039 6.53301 4.81441 6.6202 4.98554C6.6969 5.13607 6.81929 5.25846 6.96982 5.33516C7.14094 5.42235 7.36497 5.42235 7.81301 5.42235Z"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiTodo;

import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";

import { Utils } from "./../../common/Commons";

import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import NumberField from "../../common/inputControls/numberField";

import TextArea from "../../common/inputControls/textarea/TextArea";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import HiSave from "../../common/icons/HiSave.js";
class NewAssignment extends Component {
  /**/
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Load",
      ErrorMessage: "",
      roleId: 0,
      roleInfo: "",
      formData: {
        taskId: "",
        status: {},
        etc: 0,
      },

      errorMessages: {
        owner: { message: "" },
        finishDate: { message: "" },
        name: { message: "", error: false },
      },

      requiredFields: [{ id: "owner", label: "Resource" }],
    };
  }

  handleChanges = (e, val) => {
    let items = { ...this.state.formData };

    items[e] = val;
    if (e === "owner") {
      let errors = { ...this.state.errorMessages };
      errors.owner.message = "";

      this.setState({
        ...this.state,
        formData: items,
        errorMessages: errors,
        roleId: items.owner?.roleId,
        roleInfo: items.owner.roleInfo?.name,
      });
    } else if (e === "remainingUnit") {
      let errors = { ...this.state.errorMessages };
      errors.remaining.message = "";
      this.setState({ ...this.state, formData: items, errorMessages: errors });
    } else if (e === "role") {
      this.setState({
        ...this.state,
        formData: items,
        roleId: val.uid,
        roleInfo: val.name,
      });
    } else {
      this.setState({ ...this.state, formData: items });
    }

    //console.log(items);
  };

  handleChangesLookup = (e, val) => {
    //console.log(e)
    //console.log(val)

    let items = { ...this.state.formData };

    items[e] = val.id;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  componentDidMount() {
    //console.log("newAssignment")
    //console.log(this.props)
    // this.GetDatas();
    let data = {
      taskId: this.props.parentRecord.uid,
      status: {
        uid: 31,
        code: "not_started",
        name: "Not Started",
        color: null,
      },
      etc: 0,
      startDate: this.props.parentRecord.startDate,
      finishDate: this.props.parentRecord.finishDate,
    };

    this.setState({
      ...this.state,
      formData: data,
    });
  }

  componentDidUpdate(prevProps) {
    //console.log("newSidebar!!");

    // console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //  console.log(this.props);

      this.setState({ Loading: true, Status: "Loading" });

      //  this.GetDatas();
    }
  }

  Create = () => {
    // console.log(this.state.formData)
    //console.log(JSON.stringify(this.state.formData))

    //  console.log(this.state.formData.owner)
    if (this.state.formData.owner === undefined) {
      //let errors = {...this.state.errorMessages};
      const u = new Utils();
      u.addNotification("error", "Resource Must Selected.");
      let errors = { ...this.state.errorMessages };
      errors.owner.message = "Resource Must Selected.";
      this.setState({ ...this.state, errorMessages: errors });
    } else {
      this.props.save("post", "/api/assignment", this.state.formData);
    }
    //
  };

  render() {
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          {/*<TextField width="45%" onChange= {this.handleChanges} changeArea="code" label="code" required={true}/>*/}

          <DynamicLookup
            endpoint="/api/resource/assignment"
            onChange={this.handleChanges}
            formId="owner"
            searchAttribute="fullName"
            valueAttribute="id"
            labelAttribute="fullName"
            width="45%"
            label="Resource"
            required={true}
            errorMessage={this.state.errorMessages["owner"].message}
            error={this.state?.errorMessages["owner"]?.error}
          />
          <NumberField
            width="45%"
            onChange={this.handleChanges}
            changeArea="remainingUnit"
            value="0"
            label="Remaining Units (h)"
            required={true}
          />
          {/*<TextField width="45%"  type="date" onChange = {this.handleChanges} changeArea="startDate" value={this.props.parentRecord.startDate} label="startDate"  />
                    <TextField width="45%"  type="date" onChange = {this.handleChanges} changeArea="finishDate" value={this.props.parentRecord.finishDate} label="finishDate"  />*/}
          {/*     <TextField width="45%" onChange = {this.handleChanges} changeArea="role" value={this.state.formData.owner?.roleInfo?.name} label="Role"   />
                  <DynamicLookup endpoint="/api/valuelistoptions/parent/13"  onChange={this.handleChanges} formId="status" searchAttribute="name" valueAttribute="uid" labelAttribute="name"  width="45%" label="Status"/>*/}
          <DynamicLookup
            endpoint="/api/resourcerole"
            onChange={this.handleChanges}
            formId="role"
            searchAttribute="name"
            valueAttribute="id"
            labelAttribute="name"
            width="45%"
            label="Assignment Role"
            value={{ value: this.state?.roleId, label: this.state?.roleInfo }}
          />
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.Create}
            key="newTaskButton"
            className="button-area-action"
            iconLineColor="#fff"
            icon={<HiSave />}
          />
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default NewAssignment;

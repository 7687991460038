import "./THBasic.scss";

/**
 * THBasic is a simple container component that renders its children within a styled div.
 * It is typically used for table headers or other similar UI elements where basic styling is required.
 *
 * @param {JSX.Element | JSX.Element[]} children The content to be rendered inside the component.
 * @param {object} [style] Additional inline styles to apply to the component.
 * @returns {JSX.Element} The rendered THBasic component.
 *
 * @example
 * // Render a THBasic component with custom content and style
 * <THBasic style={{ backgroundColor: '#f0f0f0', padding: '10px' }}>
 *   <span>Header Title</span>
 * </THBasic>
 */
const THBasic = ({ children, style }) => {
  return (
    <div className="hub-dt-th-basic" style={{ ...style }}>
      {children}
    </div>
  );
};

export default THBasic;

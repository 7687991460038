import React, { Component } from "react";
import Tag from "./tag";
import "./tag.css";
import TooltipWithPage from "../../../../common/tooltipWithPage/tooltipWithPage";

export default class tagGroup extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      infoOpen: false,
    };
  }

  toggleMenu = () => {
    this.setState({ ...this.state, isMenuOpen: !this.state.isMenuOpen }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, isMenuOpen: false, infoOpen: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event?.target)) {
      this.closeMenu();
    }
  };

  render() {
    //console.log("tag",this.props.tags)
    return this.props.tags?.length > 0 ? (
      <div
        className="hub-card-tag-container"
        onClick={this.toggleMenu}
        style={{ ...this.props.style }}
      >
        {this.props.tags.map((t, i) => {
          //console.log(t)
          return (
            <Tag
              tagName={t.tag_name}
              tagColor={t.tag_color}
              collapsed={i > 1}
            />
          );
        })}

        <div className="hub-card-tag-tooltip-wrapper">
          <TooltipWithPage
            title={"Tags"}
            closePanel={this.toggleTooltip}
            style={{ width: "220px" /**/, top: "29px" }}
            arrowLocaiton={{ display: "none" }}
            noCloseIcon
          >
            <div
              className="hub-card-tag-container"
              style={{ rowGap: "5px", flexWrap: "wrap" }}
            >
              {this.props.tags.map((t, i) => {
                //console.log(t)
                return <Tag tagName={t.tag_name} tagColor={t.tag_color} />;
              })}
            </div>
          </TooltipWithPage>
        </div>
      </div>
    ) : (
      false
    );
  }
}

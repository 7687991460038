import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHexagonExclamation}  from '@fortawesome/pro-duotone-svg-icons'
export default class activitiesTableHexaIcon extends Component {

    constructor(props) {
        super(props);
        this.state={ showChanges: false}
    }

   getWarnClass = (impact) => {
        switch (impact) {
            case "Low":
                return "hub-changeSet-activityTable-warnGreen"
                break;
            case "Medium":
                return "hub-changeSet-activityTable-warnYellow"
                break;
            case "High":
                return "hub-changeSet-activityTable-warnRed"
                break;
            default:
                return "";
                break;
        }
    }

    getColor = (impact) => {
        switch (impact) {
            case "Low":
                return "Green"
                break;
            case "Medium":
                return "#f7b440"
                break;
            case "High":
                return "Red"
                break;
            default:
                return "";
                break;
        }
    }

    
    handleMouseOver = (e) => {
        this.setState({...this.state, showChanges: true})
    }
    handleMouseLeave = (e) => {
        this.setState({...this.state,  showChanges: false})
    }



  render() {
    return (
      <div style={{width: "24px", paddingLeft: "8px", textAlign:"center"}}>
            <FontAwesomeIcon onMouseMove={this.handleMouseOver} onMouseLeave={this.handleMouseLeave} className={this.getWarnClass(this.props.activity.impact)} icon={faHexagonExclamation} reverse />

           {this.state.showChanges? 
           <div className={"hub-changeSet-changes"}>
            <span style={{fontSize:"16px" ,color:this.getColor(this.props.activity.impact)}}>{this.props.activity.impact}</span><br />

              <div className={"change-info-wrapper"}>
                
                {this.props.activity.changeAreas.map((cng)=>{

                  return <div>{cng}</div>

                })}
            </div>
           </div>:false}
      </div>
    )
  }
}

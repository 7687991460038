import React from "react";

const HiProgram = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
    >
      <path
        d="M7.50002 1.66666V3.33333M12.5 1.66666V3.33333M7.50002 16.6667V18.3333M12.5 16.6667V18.3333M16.6667 7.5H18.3334M16.6667 11.6667H18.3334M1.66669 7.5H3.33335M1.66669 11.6667H3.33335M7.33335 16.6667H12.6667C14.0668 16.6667 14.7669 16.6667 15.3017 16.3942C15.7721 16.1545 16.1545 15.772 16.3942 15.3016C16.6667 14.7669 16.6667 14.0668 16.6667 12.6667V7.33333C16.6667 5.9332 16.6667 5.23313 16.3942 4.69835C16.1545 4.22795 15.7721 3.8455 15.3017 3.60581C14.7669 3.33333 14.0668 3.33333 12.6667 3.33333H7.33335C5.93322 3.33333 5.23316 3.33333 4.69838 3.60581C4.22797 3.8455 3.84552 4.22795 3.60584 4.69835C3.33335 5.23313 3.33335 5.9332 3.33335 7.33333V12.6667C3.33335 14.0668 3.33335 14.7669 3.60584 15.3016C3.84552 15.772 4.22797 16.1545 4.69838 16.3942C5.23316 16.6667 5.93322 16.6667 7.33335 16.6667ZM8.83335 12.5H11.1667C11.6334 12.5 11.8668 12.5 12.045 12.4092C12.2018 12.3293 12.3293 12.2018 12.4092 12.045C12.5 11.8667 12.5 11.6334 12.5 11.1667V8.83333C12.5 8.36662 12.5 8.13326 12.4092 7.955C12.3293 7.7982 12.2018 7.67072 12.045 7.59082C11.8668 7.5 11.6334 7.5 11.1667 7.5H8.83335C8.36664 7.5 8.13329 7.5 7.95503 7.59082C7.79823 7.67072 7.67074 7.7982 7.59085 7.955C7.50002 8.13326 7.50002 8.36662 7.50002 8.83333V11.1667C7.50002 11.6334 7.50002 11.8667 7.59085 12.045C7.67074 12.2018 7.79823 12.3293 7.95503 12.4092C8.13329 12.5 8.36664 12.5 8.83335 12.5Z"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiProgram;

import React, { Component } from 'react';

class TimesheetProgressBar extends Component {
    
    render() {
        let pct = 0;
        
        let lineColor = "#2a854e";
        if(this.props.actual && this.props.availability) {
            pct = (100*this.props.actual / this.props.availability).toFixed(2);
            //console.log('pct',pct)
            if (pct <100){lineColor =  "#8182ff";}
            else if(pct ==100){  lineColor =  "#2a854e";}
            else if (pct >100) {lineColor =  "#f41138"; pct=100.5;}
        } 
        return (
            <div style={this.props.style} className="hub-ts-progressBar">
                <div className="units" style={{textTransform: "uppercase", marginBottom: "5px"}}>HOURS</div>
                <div className="usage"><span style={{ fontSize: "calc(1rem + 1vw)", fontWeight: 400}}>{this.props.actual?this.props.actual.toFixed(2):0.00}</span>/{this.props.availability?this.props.availability.toFixed(2):0.00}</div>
                <div className="hub-ts-progressBar-graph">
                    <div className="graph-inner">
                        <div className="graph-line" style={{width: pct.toString() + "%", backgroundColor: lineColor}}>  </div>
                        <div className="graph-line-border">  </div>
                    </div>
                </div>
            </div>
        );
    }
    
}

export default TimesheetProgressBar;
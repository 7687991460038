import React, { Component } from 'react';
import ProjectTile from './ProjectTile';
import './ProjectTile.css';
import Auth from './../../auth/Auth';
import ProjectTileFilterArea from './ProjectTileFilterArea';

class ProjectTiles extends Component {

    constructor(props) {
        super(props);
        this.state = {
            LoadingText: "Loading Projects",
            Loading:true,
            LoadingError:false,
            LoadingErrorText:"",
            projects:[]
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch('/api/projects', requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                if(r.status==200) {
                    this.setState({...this.state, Loading:false, projects:r.body.object});
                }
                else if(r.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    }

    handleFilterChange(e) {
        this.setState({filterText: e});
    }

    render() {
        return (
            <div className="hub-page"> 
               {this.state.Loading?<div>{this.state.LoadingText}</div>:
               <div>
                <ProjectTileFilterArea handleChange={this.handleFilterChange} filterText={this.state.filterText} />
                    <div className="hub-projectTile-tiles">
                        {this.state.projects.map(i => {
                            return <ProjectTile key={i.uid} project={i} />
                        })}
                    </div>
                </div>}
            </div>
        );
    }

}

export default ProjectTiles;
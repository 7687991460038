import React, { Component } from 'react';

import InputControl from '../InputControl';

class booleanArea extends Component {

    constructor(props) {
      super(props);
      this.state={v: '', focus:false, error:false, errorMessage: ''}
      this.handleFocus = this.handleFocus.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    handleFocus(t) {
      let errorMessage = t===false&&this.props.required&&this.state.v.length==0?'This field is required.':'';
      let error = t===false&&this.props.required&&this.state.v.length==0?true:false;
      this.setState({...this.state, focus:t, error:error, errorMessage: errorMessage}) 
    }


    componentDidMount(){

      //console.log("componentDidMount booleanArea");
      //console.log(this.props);
      this.setState({...this.state, v:this.props.value?this.props.value:''}) 
    }

    componentDidUpdate(prevProps)
    {
      if(prevProps.value!==this.props.value)
      {
        this.setState({...this.state, v:this.props.value?this.props.value:''})
      }
    }


    onChange(e){
        //console.log(e.target.value)
         //console.log(this.state)
        this.setState({...this.state, v: !this.state.v}); this.props.onChange(this.props.changeArea, !this.state.v)
    
    }

    render() {
        return (
            <InputControl inputValue={this.state.v} type="bool" error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props}>
              <input onClick={this.onChange}  disabled={this.props.disabled}  type="checkbox" checked={this.state.v} onFocus={()=>this.handleFocus(true)} onBlur={()=>this.handleFocus(false)}/>
            </InputControl>
        );
    }

}

export default booleanArea;
import React, { Component } from "react";

import AsyncSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import Auth from "../../../auth/Auth";
import InputControl from "../InputControl";
import TooltipWithPage from "../../tooltipWithPage/tooltipWithPage";
import MainButton from "../../buttons/MainButton";
//import "react-select/dist/react-select.css";
import { tagColors } from "../../colors/colors";
import HubIcon from "../../hubIcon/HubIcon";
import HiAddCirle from "../../icons/HiAddCircle";
import HiClose from "../../icons/HiClose";
export default class MultiSelect extends Component {
  constructor() {
    super();
    this.state = {
      multiValue: [],
      filterOptions: [
        { value: "foo", label: "Foo", color: "red" },
        { value: "bar", label: "Bar", color: "blue" },
        { value: "bat", label: "Bat", color: "pink" },
      ],

      values: [],
      showTooltip: false,
    };
  }

  componentDidMount() {
    this.getData("", (x) => {
      this.setState({ ...this.state, values: x });
    });
  }

  // counter dışında tetikleyemedim. Farklı bir yol bulunabilir.
  componentDidUpdate(prevProps, prevState) {
    if (this.props.counter !== prevProps.counter) {
      this.getData("", (x) => {
        this.setState({ ...this.state, values: x });
      });
    }
  }

  getData = async (inputText, callback) => {
    // console.log("endpoint",this.props.endpoint)
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    const response = await fetch(
      `${this.props.endpoint}?size=1000000&search=${this.props.searchAttribute}:${inputText}`,
      requestOptions
    );
    const json = await response.json();
    let result = json.object;
    if (this.props.extraObject) {
      result = json.object[this.props.extraObject];
    }
    // console.log("json",result)
    callback(
      result?.map((i) => ({
        label: i[this.props.labelAttribute],
        value: i[this.props.valueAttribute],
        color: i[this.props.backgroundColorAttribute].code /*object:i*/,
      }))
    );

    // console.log("dynamic Response",response)
  };

  handleMultiChange = (option) => {
    //console.log("option",option)
    //this.setState({multiValue: option} )
    this.props.onChange(this.props.changeArea, option);
  };

  //Kullanılmıyor başka methodlarda kullanılabilir.
  handleCreate = (inputValue) => {
    //console.log("handleCreate",inputValue)

    this.props.onCreate(this.props.changeArea, inputValue);
  };

  toggleTooltip = (inputValue) => {
    //this.setState({...this.state, showTooltip: !this.state.showTooltip});

    this.props.toggleTooltip(inputValue);
  };

  render() {
    let colourStyles = {
      control: (styles, { data }) => ({
        ...styles,
        borderRadius: "6px",
        display: "flex",
        justifyContent: "space-between",
      }),

      input: (styles, { data }) => ({
        ...styles,
        height: "20px",
        color: "#051530",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "18px" /* 128.571% */,
      }),

      multiValue: (styles, { data }) => {
        //console.log("data",data.color,tagColors,tagColors.find(x=>x.name===data.color))

        const color = tagColors.find(
          (x) => x.name === data.color
        )?.backgroundColor; //data.color//colors[data.color].backgroundColor;
        return {
          ...styles,
          backgroundColor: color,
          borderRadius: "4px",
          height: "20px",
        };
      },

      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
        fontSize: "10px",
      }),

      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ":hover": {
          background: "rgb(0 0 0 / 20%)",
        },
        backgroundColor: data.color,
        color: "white",
        borderRadius: "6px",
        background: "rgb(0 0 0 / 10%)",
      }),

      indicatorSeparator: (base) => ({
        ...base,
        display: "none !important",
      }),
      indicatorsContainer: (base) => ({
        ...base,
        paddingRight: "10px !important",
      }),
      placeholder: (base, state) => ({
        ...base,
        color: "#051530",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "18px" /* 128.571% */,
        opacity: "0.15",
      }),
      menu: (provided, state) => ({
        ...provided,
        width: "calc(100% + 2px)",
        left: "-2px !important",
        borderRadius: "6px",
        border: "1px solid var(--hub100, #D2DEF2)",
        background: "#FFF",
        boxShadow: "0px 20px 30px 0px rgba(18, 84, 193, 0.25)",
        padding: "5px",
        overflowY: "auto",
        scrollbarWidth: "3px !important",
        scrollbarColor: "#779dd9 white",
        "&::-webkit-scrollbar": {
          width: "3px !important",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#779dd9",
          borderRadius: "3px",
          width: "3px !important",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#779dd9",
          borderRadius: "3px",
          width: "3px !important",
        },
        "& > div:last-child": {
          borderBottom: "none", // Remove bottom border from last option
        },
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderBottom: "1px solid #E8EFF9",
        padding: "10px",
        height: "auto",
        color: state.isSelected ? "#fff" : "var(--hub900, #051530)",
        background: state.isSelected ? "var(--hub500, #1769F1)" : "#fff",
        fontFamily: "Noto Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        borderRadius: "3px",

        "&:last-child": {
          borderBottom: "none", // Remove bottom border from last option
        },

        "&:hover": {
          background: "var(--hub10, #F4F7FC)",
          color: "#051530",
        },
      }),
    };

    const DropdownIndicator = (props) => {
      return (
        <div {...props}>
          <HubIcon>
            <HiAddCirle />
          </HubIcon>
        </div>
      );
    };

    return (
      <>
        <InputControl
          inputValue={this.state.v}
          error={this.state.error}
          errorMessage={this.state.errorMessage}
          focus={this.state.focus}
          {...this.props}
          type={"multi"}
        >
          {this.props.isCreatable ? (
            <CreatableSelect
              isClearable
              name={this.props.label}
              placeholder={this.props.label}
              value={this.props.value}
              options={this.state.values}
              onChange={this.handleMultiChange}
              isMulti
              styles={colourStyles}
              isDisabled={this.props.disabled}
              onCreateOption={this.toggleTooltip}
              components={{ DropdownIndicator, ClearIndicator: null }}
              maxMenuHeight={190}
            />
          ) : (
            <AsyncSelect
              name={this.props.label}
              placeholder={this.props.label}
              value={this.props.value}
              options={this.state.values}
              onChange={this.handleMultiChange}
              isMulti
              styles={colourStyles}
              isDisabled={this.props.disabled}
              components={{ DropdownIndicator, ClearIndicator: null }}
            />
          )}

          {/* Create etmek için kullanılır*/}
          {this.props.showTooltip ? this.props.children : false}
        </InputControl>
      </>
    );
  }
}

import React, { Component } from 'react'
import DateArea from '../../common/inputControls/dateArea'
import MainButton from '../../common/buttons/MainButton';
import PageArea from '../../common/sideBarPageContent/pageArea/pageArea';
import ButtonArea from '../../common/sideBarPageContent/buttonArea/buttonArea';
import BodyArea from '../../common/sideBarPageContent/bodyArea/bodyArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion } from '@fortawesome/pro-duotone-svg-icons'
export default class datePicker extends Component {
constructor() {
    super();
    
    this.state = {
        
           
              startDate:"",
              finishDate:""
            
        };
  }
  
  componentDidMount()
  {
      this.setState({ ...this.state, startDate:this.props.startDate,  finishDate:this.props.finishDate })
  }
  
    
    handleChangesStartDate = (e,val) =>{
       // console.log(e,val)

         this.setState({ ...this.state, startDate:val })
        
    }

      handleChangesFinishDate = (e,val) =>{
        //console.log(e,val)

         this.setState({ ...this.state, finishDate:val })
        
    }

    clear=()=>
    {
        this.setState({ ...this.state, finishDate:"", startDate :""})
    }





    
  render() {

    //console.log(this.props)
    return (
        <div  style={{display:"flex",flexDirection:"column"}}>
            <div className='hubTeam-date-info'>
                <FontAwesomeIcon icon={faCircleQuestion} color={"#ACBDE4"}   size={"1x"} style={{marginRight:"5px",marginTop:"2px"}}/>
                <span>Tasks with a start date greater than the selected start date and a finish date less than the selected finish date are shown.</span>
            </div>

            <div className='hubTeam-line'></div>
            
            <div>
                 <DateArea info="The start date of the tasks is greater than the selected date." onChange = {this.handleChangesStartDate} changeArea="startDate"  width="45%"  type="date"  value={this.state.startDate} label="Start Date >"   />
                 <DateArea info={"The completion date of the tasks is less than the selected date."} onChange= {this.handleChangesFinishDate} changeArea="finishDate" width="45%"  type="date" value={this.state.finishDate} label="Finish Date <"   />
            </div>
            
           
            <div style={{display:"flex",flexDirection:"row",alignSelf:"flex-end",marginTop:"15px",marginRight:"11px"}}>
             
                <MainButton onClick={this.clear} key="newTaskButton" className="button-2" icon={false} label="Clear" />         
                <MainButton onClick={()=>this.props.setDates(this.state.startDate,this.state.finishDate)} key="newCommitButton" className="button-1" icon={false} label="Apply" /> 
            </div>
      </div>
    )
  }
}

const  sampleData =  {
    selectedPeriod: 0,
    initialPeriodOrder:0, 
    reportingPeriods: [
      {
        uid: 0,
        name: "Jan 3 - Jan 19",
        start_date: new Date("2022-01-03T00:00:00.000Z"),
        finish_date: new Date("2022-01-09T23:59:59.999Z"),
        status: {
          code: "OPEN",
          label: "Open"
        },
        days:[
          {
            day: new Date("2022-01-03T00:00:00.000Z"),
            exception: 0, 
              workhours: 8.5
          }, {
            day: new Date("2022-01-04T00:00:00.000Z"),
            exception: 0,
            workhours: 8.5
          }, {
            day: new Date("2022-01-05T00:00:00.000Z"),
            exception: 0,
            workhours: 8.5
          }, {
            day: new Date("2022-01-06T00:00:00.000Z"),
            exception: 0,
            workhours: 8.5
          }, {
            day: new Date("2022-01-07T00:00:00.000Z"),
            exception: 0,
            workhours: 8.5
          }, {
            day: new Date("2022-01-08T00:00:00.000Z"),
            exception: 1,
            workhours: 0
          }, {
            day: new Date("2022-01-09T00:00:00.000Z"),
            exception: 1,
            workhours: 0
          }
      ]
      },
      {
        uid: 1,
        name: "Jan 10 - Jan 16",
        start_date: new Date("2022-01-10T00:00:00.000Z"),
        finish_date: new Date("2022-01-16T23:59:59.999Z"),
        status: {
            code: "OPEN",
            label: "Open"
        }
      },
      {
        uid: 2,
        name: "Jan 17 - Jan 23",
        start_date: new Date("2022-01-17T00:00:00.000Z"),
        finish_date: new Date("2022-01-23T23:59:59.999Z"),
        status: {
          code: "OPEN",
          label: "Open"
        }
      },
      {
        uid: 3,
        name: "Jan 24 - Jan 30",
        start_date: new Date("2022-01-24T00:00:00.000Z"),
        finish_date: new Date("2022-01-30T23:59:59.999Z"),
        status: {
          code: "OPEN",
          label: "Open"
        }
      },
      {
        uid: 4,
        name: "Jan 31 - Feb 6",
        start_date: new Date("2022-01-24T00:00:00.000Z"),
        finish_date: new Date("2022-01-30T23:59:59.999Z"),
        status: {
          code: "OPEN",
          label: "Open"
        }
      },
    ],
    timesheet: {
        availability: 0,
        actual: 0,
        totals:[8, 0, 0, 0, 8, 2.4, 0],
        remaining: 90.0,
        status: {},
        stage:{},
        timeReportLines: [
          {
            uid: 1,
            assignment_uid: 1, 
            task_uid: 1, 
            task_name: "Task 1",
            activity_uid: 1, 
            activity_name: "Activity 1",
            wbs_uid: 1,
            wbs_name: "WBS 1", 
            project_uid: 1,
            project_name: "Project 1",
            worklogs: [8, null, null, null, 8, 2.4, null],
            actual: 18.4,
            remaining: 32.6
          }
        ]
    }
};

export default sampleData;
import { useState } from "react";
import parse from "html-react-parser";

import HubIcon from "../../hubIcon/HubIcon";
import HiArrowShortDown from "../../icons/HiArrowShortDown";
import HiArrowShortUp from "../../icons/HiArrowShortUp";

const maxLength = 250;

const NoteDescription = ({ dataSet }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!dataSet?.message) {
    return <div>There is no message!</div>;
  }

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const renderButton = () => (
    <div onClick={toggleExpand} className="expand-note">
      {isExpanded ? "Show less" : "Show more"}
      <HubIcon lineColor="#647795">
        {isExpanded ? <HiArrowShortUp /> : <HiArrowShortDown />}
      </HubIcon>
    </div>
  );

  const truncateHtml = (html, maxLength) => {
    if (html?.length <= maxLength) {
      return html;
    }
    return `${html.slice(0, maxLength)}...`;
  };

  const displayedHtml =
    isExpanded && dataSet?.message
      ? dataSet?.message
      : truncateHtml(dataSet?.message, maxLength);

  return (
    <div className="note-description">
      {parse(displayedHtml)}
      {dataSet.message?.length > maxLength && renderButton()}
    </div>
  );
};

export default NoteDescription;

import React from "react";

import "./CheckBox.scss";

const CheckBox = ({ id, isDisabled, isChecked, onChange }) => {
  return (
    <>
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        className="custom-checkbox"
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
      />
      <label htmlFor={`checkbox-${id}`} className="checkbox-label" />
    </>
  );
};

export default CheckBox;

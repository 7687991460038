import React, { Component } from 'react';
import './Tabs.css';

class TabGroup extends Component {
    render() {
        return (
            <div className="hub-tabGroup">
                { this.props.children }
            </div>
        );
    }
}

export default TabGroup;
import React, { Component } from 'react'
import Auth from '../../../auth/Auth';
import PageFilter from '../../../widgets/pageFilter';
import Container from '../container/Container';
import ContainerCreator from './containerCreator';
import './dashboard.css'
import DashboardHeader from './DashboardHeader';
import LoadingScreen from '../../statusScreen/loadingScreen';
export default class PageLayout extends Component {

    // * Bu sayfa dashboard configine göre layout ve widgetleri oluşturur.

    constructor(props) {
        super(props);
        this.state={
            data: {},
            filterList:[],
            filters:[],
            selectedFilters:[],
            counter:0,
            affectAll:[],
            multiplexing:null,
            loading:true,
            noContent:false
             }
             
    }

    componentDidMount() 
    { 
        this.getData() 
    }

    componentDidUpdate(prevProps,prevState)
    {
        if(prevProps.code!==this.props.code)
        {
            this.getData()
        }
    }


    getQuery = (queryCode) => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };
      //  console.log(obs)
        let result=[]
     
          fetch('/api/query/code/'+queryCode.query, requestOptions)

        
        
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
            // console.log("r",queryCode,r)
                
               if(r.status===200) {

                result=r.body.object
                  this.setState({...this.state, multiplexing:r.body.object, multiplexingAttribute:queryCode.attribute, loading:false })
               
            }
               
            });

            return result;
    }


    getData =  () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
        };
         fetch('/api/page/code/'+this.props.code, requestOptions)
        .then(response => response.json().then(data => ({status:response.status, body:data})))
        .then(r => {
            //console.log("getdata pageLayout",r)
            if (r.status===200 && r?.body?.status!==204) {
              //  console.log("r.body.object.filters",JSON.parse(r.body.object.filters))
              
                let filters=JSON.parse(r.body.object.filters===""?null:r.body.object.filters)

                let selectedFilters=[]
                let newAffectAll=[]
                filters?.map((f)=>
                {
                    

                    if(f.isVisible===true || f.isVisible===undefined)
                    {

              

                        f.selectedFilter=null
                        selectedFilters.push({id:f.id,dataKey:f.dataKey, value:"null"})
                       
                    }
                    else
                    {

                     
                        selectedFilters.push({id:f.id,dataKey:f.dataKey, value:this.props[f.propsObjectCode][f.propsObjectValueAttribute]!==undefined?this.props[f.propsObjectCode][f.propsObjectValueAttribute]:"null"})
                    }
                    

                    if(f?.affect)
                    {
                        newAffectAll.push({id:f.id,dataKey:f.dataKey, value:"null"})
                    }
                })


                this.setState({...this.state, data:r.body.object,layout:JSON.parse(r.body.object.layout) ,
                    filters:filters!==null?filters:[],
                    selectedFilters:selectedFilters,
                    affectAll:newAffectAll,
                    loading: r.body.object?.multiplexing?true:false }
                ,()=>{
                    if(r.body.object.multiplexing)
                    {
                    
                        this.getQuery(JSON.parse(r.body.object.multiplexing))}
                    }
                );
            } else if (r.status===401) {
                window.location.replace("/login");
            } else if (r.status===403) {
                this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
            } else if (r.status===200 && r?.body?.status===204) {
                this.setState({...this.state, noContent: true});

            
            } else {
                this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
            }
        });
    }

 

    setFilter = (selectedFilters ) => { 
        //console.log("setFilter", selectedFilters)
    
        /*let affectAll=null
        if(this.state.filters.find(x=>x.affect)!==undefined)
        {
            let id=this.state.filters.find(x=>x.affect).id
            //console.log("affectAll",id )

            affectAll=selectedFilters.find(x=>x.id===id)
        }
        */

        this.setState({...this.state,selectedFilters:selectedFilters,counter:this.state.counter+1/*,affectAll:affectAll */})
      
    }

    setSelectedFilter=(value, index,all,affectAll)=>{
        //console.log("setSelectedFilter", value, index,all,affectAll)

        let filters=this.state.filters
        if(all===undefined)
        {
            filters[index].selectedFilter=value
        }
        else
        {
            filters.map((f)=>
            {
                f.selectedFilter=null
            })
        }


        let newAffectAll=this.state.affectAll
        let affectIndex=newAffectAll.findIndex(x=>x?.id===affectAll?.id)
        if(affectIndex===-1)
        {
            newAffectAll.push(affectAll)
        }
        else
        {
            newAffectAll[affectIndex]=affectAll
        }


       this.setState({...this.state,affectAll:newAffectAll,filters:filters })
        

       

    }
    


  render() {

   // console.log("pageLayout selectedFilters",this.state.selectedFilters)
    return (

        !this.state.loading?
            <div className="hub-page" style={{padding:0, background:"#F5F7FA"}}>
                {this.state.filters.length>0?
                <PageFilter filters = {this.state.filters} setSelectedFilter={this.setSelectedFilter} selectedFilters={this.state.selectedFilters} setFilter = {this.setFilter} affectAll={this.state.affectAll}  />
                :false
                }

                {this.state.multiplexing?
                
                    (this.state.multiplexing.map((m)=>
                    {
                        return  <div className='hub-dashboard-page-container'>

                                    <DashboardHeader title={m.name}/>
                                    <Container id="1" direction="column" stretch>
                                        {
                                            this.state.layout?.map((c)=>{
                                                return <ContainerCreator container={c} selectedFilters={this.state.selectedFilters} multiplexingFilter={m.id} multiplexingAttribute={this.state.multiplexingAttribute} counter={this.state.counter}/>
                                            })       
                                        }   
                                    </Container>
                                </div>
                    }))
            
                :

                    <div className='hub-dashboard-page-container'>             
                        <Container id="1" direction="column" stretch>
                            {
                                this.state.layout?.map((c)=>{
                                    return <ContainerCreator container={c} selectedFilters={this.state.selectedFilters} counter={this.state.counter}/>
                                })       
                            }   
                        </Container>
                    </div>
                }

            </div>
        :
        <LoadingScreen noContent={this.state.noContent}/>
     
    )
  }
}

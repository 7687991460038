import React, { Component } from 'react'
//import "./../Inputs.css"
import InputControl from '../InputControl';
import Auth from '../../../auth/Auth';
import MainButton from '../../../common/buttons/MainButton';


  const selectConfig =  {id:"code", value:"name", empty:true, emptyDescription:"Seçiniz."}
  class index extends Component {

/**/
    constructor(props) {
        super(props);
        this.state= {
           Value: this.props.value,
           Loading:true,
           data:[],
           config: {}

        }
    }


    
 handleInputChange = (event) =>{

        this.setState({
            Value: event.target.value
        });
       
        this.props.parentCallback(event.target.value,this.props.changeArea);
           
      
}

/*
componentDidUpdate(prevProps) {
          //
        if (prevProps !== this.props) {
        //console.log("Loading Input");
        console.log("componentDidMount select");
      console.log(this.props.url);
        this.props.url!==undefined?this.GetDatas(this.props.url.url,this.props.url.id):console.log("non")

        console.log(this.state);
    }
  }
*/


componentDidMount(){
  this.setState({
  ...this.state,  config:this.props.config!==undefined?this.props.config:selectConfig
});

  this.props.data!==undefined?this.GetDatas(this.props.data.url,this.props.data.id):console.log("non")

  //console.log(this.state)
}

     GetDatas = (url,id) => {
           
            const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };
        const lastUrl= id==false?url:url+id

        fetch('/api/'+lastUrl, requestOptions)
        
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
              // console.log(r);
               // console.log(requestOptions);
               if(r.status==200) {
                this.setState({...this.state, Loading:false, data:r.body.object});

               // console.log(this.state.formData.startDate)
               // console.log(this.state.period.open)
            }
                else if(r.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });


    
    }

    handleFocus(t) {
      let errorMessage = t===false&&this.props.required&&this.state.v.length==0?'This field is required.':'';
      let error = t===false&&this.props.required&&this.state.v.length==0?true:false;
      this.setState({...this.state, focus:t, error:error, errorMessage: errorMessage}) 
    }


  render() {


    return (
       
       <InputControl inputValue={this.state.v} type="select" error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props}>
           
         <select type="select" defaultValue={this.props.value} disabled={this.props.disabled} onChange={this.handleInputChange} style={{width:this.props.cols  }}>
           {this.state.config.empty? 
           <option value="">{this.state.config.emptyDescription}</option>
           :''}
         
             {
                this.state.data.map( (c) => {
                    return  <option  selected={this.props.value===c[this.state.config.id]?true:false}  value={c[this.state.config.id]}>{c[this.state.config.value] }</option>
                 })
             }
         </select>
         
      </InputControl>
    )
  }
}


export default index;

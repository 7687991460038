import React, { useState, useCallback, useEffect, useContext } from "react";
import Cropper from "react-easy-crop";

import "./ImageCropper.scss";
import MainButton from "../buttons/MainButton";
import HiUpload from "../icons/HiUpload";
import HiTrash from "../icons/HiTrash";
import {
  createProfileImage,
  deleteProfileImage,
  updateProfileImage,
} from "../../api/profile";
import Auth, { resourceObject } from "../../auth/Auth";
import SkeletonLoader from "../skeletonLoader";
import CommonContext from "../../store/common-context";
import { Utils } from "../Commons";

const image64ToImage = (base64) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = base64;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(img);
    };
  });
};

const cropImage = (image, x, y, newWidth, newHeight) => {
  const canvas = document.createElement("canvas");
  canvas.width = newWidth;
  canvas.height = newHeight;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(image, x, y, newWidth, newHeight, 0, 0, newWidth, newHeight);
  return canvas.toDataURL("image/jpeg");
};

const cropImage64 = async (base64, x, y, newWidth, newHeight) => {
  const img = await image64ToImage(base64);
  return cropImage(img, x, y, newWidth, newHeight);
};

const base64ToFile = (base64String, fileName) => {
  const byteString = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];

  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], fileName, { type: mimeString });
  return file;
};

const ImageCropper = ({
  image,
  mainFile,
  onSelectFile,
  onCloseCropper,
  mode,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.3);
  const [coord, setCoord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounting, setIsMounting] = useState(true);

  const { userInfo, setUserInfo } = useContext(CommonContext);

  useEffect(() => {
    setTimeout(() => {
      setIsMounting(false);
    }, 500);
  }, []);

  const utils = new Utils();

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCoord(croppedAreaPixels);
  }, []);

  const cutImage = async () => {
    setIsLoading(true);
    cropImage64(image, coord.x, coord.y, coord.width, coord.height).then(
      (croppedImage) => {
        saveImage(croppedImage);
      }
    );
  };

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const getBackgroundSize = (value) => {
    const percentage = ((value - 1) / 2) * 100; // Convert value range (1-3) to percentage (0-100)
    return `${percentage}% 100%`;
  };

  const saveImage = async (croppedImage) => {
    const croppedFile = base64ToFile(croppedImage, "cropped" + mainFile?.name);

    const formData = new FormData();
    formData.append("resourceUid", userInfo?.uid);
    formData.append("profileImage", mainFile);
    formData.append("cropImage", croppedFile);

    const hasAvatar =
      userInfo?.profileImage?.cropImageHash &&
      userInfo?.profileImage?.profileImageHash;

    const sendImages =
      mode === "new" && !hasAvatar
        ? createProfileImage(formData)
        : updateProfileImage(userInfo?.uid, formData);

    try {
      const { data } = await sendImages;

      if (data?.status === 200) {
        const resourceInfo = JSON.parse(localStorage.getItem(resourceObject));
        resourceInfo.profileImage = data.object;
        localStorage.setItem(resourceObject, JSON.stringify(resourceInfo));
        setUserInfo({ ...resourceInfo });
        onCloseCropper(true);
        setIsLoading(false);
      }
    } catch (err) {
      utils.addNotification("error", err?.response?.data?.message);
    }
  };

  const deleteImage = async () => {
    if (mode === "edit") {
      try {
        const { data } = await deleteProfileImage(userInfo?.uid);
        if (data?.status === 200) {
          const resourceInfo = JSON.parse(localStorage.getItem(resourceObject));
          resourceInfo.profileImage = null;
          localStorage.setItem(resourceObject, JSON.stringify(resourceInfo));
          setUserInfo({ ...resourceInfo });
          onCloseCropper();
        }
      } catch (err) {
        utils.addNotification("error", err?.response?.data?.message);
      }
    } else {
      onCloseCropper();
    }
  };

  return (
    <>
      {isMounting && (
        <div className="crop-container">
          <SkeletonLoader width={477} height={157} borderRadius={6} />
        </div>
      )}
      <div
        className="crop-container"
        style={{
          position: !isMounting ? "relative" : "absolute",
          opacity: !isMounting ? "1" : "0",
        }}
      >
        <div
          className="crop-wrapper"
          style={{
            pointerEvents: isLoading ? "none" : "all",
          }}
        >
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </div>
        <div className="range-wrapper">
          <div class="zoom-range-slider">
            <input
              type="range"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(e) => onZoomChange(e.target.value)}
              style={{ backgroundSize: getBackgroundSize(zoom) }}
            />
          </div>
        </div>
        <div className="action-section">
          <MainButton
            disabled={isLoading}
            onClick={deleteImage}
            className="secondary"
            icon={<HiTrash />}
            style={{
              width: 30,
              height: 34,
            }}
          />
          <MainButton
            disabled={isLoading}
            onClick={onSelectFile}
            className="secondary"
            icon={<HiUpload />}
            style={{
              width: 30,
              height: 34,
            }}
          />
          <MainButton
            isLoading={isLoading}
            onClick={cutImage}
            className="primary"
            label="Save"
            style={{
              width: 57,
              height: 34,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ImageCropper;

import React from "react";

const HiImageXL = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M21.6 28H9.24183C8.43407 28 8.0302 28 7.84318 27.8403C7.6809 27.7017 7.59479 27.4938 7.61153 27.281C7.63083 27.0358 7.91641 26.7503 8.48758 26.1791L19.8248 14.8418C20.3529 14.3138 20.6169 14.0498 20.9213 13.9509C21.1891 13.8639 21.4776 13.8639 21.7454 13.9509C22.0498 14.0498 22.3138 14.3138 22.8418 14.8418L28 20V21.6M21.6 28C23.8402 28 24.9603 28 25.816 27.564C26.5686 27.1805 27.1805 26.5686 27.564 25.816C28 24.9603 28 23.8402 28 21.6M21.6 28H10.4C8.15979 28 7.03969 28 6.18404 27.564C5.43139 27.1805 4.81947 26.5686 4.43597 25.816C4 24.9603 4 23.8402 4 21.6V10.4C4 8.15979 4 7.03969 4.43597 6.18404C4.81947 5.43139 5.43139 4.81947 6.18404 4.43597C7.03969 4 8.15979 4 10.4 4H21.6C23.8402 4 24.9603 4 25.816 4.43597C26.5686 4.81947 27.1805 5.43139 27.564 6.18404C28 7.03969 28 8.15979 28 10.4V21.6M14 11.3333C14 12.8061 12.8061 14 11.3333 14C9.86057 14 8.66667 12.8061 8.66667 11.3333C8.66667 9.86057 9.86057 8.66667 11.3333 8.66667C12.8061 8.66667 14 9.86057 14 11.3333Z"
        stroke="#647795"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiImageXL;

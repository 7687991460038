import Auth from "../auth/Auth";
import {
  tokenVar,
  resourceId,
  resourceFullName,
  resourceObject,
} from "./../auth/Auth.js";
class LoginAPI {
  login(
    body,
    callback = function (e) {
      void 0;
    }
  ) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    };

    fetch("/api/auth/session", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => callback(obj));
  }

  getUserInfo(
    callback = function (e) {
      void 0;
    }
  ) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/auth/session", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status == 200) {
          localStorage.setItem(resourceId, r.body.object.resource.uid);
          localStorage.setItem(
            resourceFullName,
            r.body.object.resource.fullName
          );
          localStorage.setItem(
            resourceObject,
            JSON.stringify({
              ...r.body.object.resource,
            })
          );

          callback();
          //console.log(this.state)
          // console.log(this.state.period.open)
        }
      });
  }
}

export default new LoginAPI();

import React, { Component } from 'react';
import './Container.css';

class Container extends Component {
    /*
        Genel mantığı 
        <Container
            width ve height  container'a verilecek uzunluk css e yazılacak değer "33%" veya "400px" gibi
            stretch : flex 1 yapar
            direction: flex direction
        >
        Her şeyi container içine container olarak koyulmayacak. 
        Containerın altında 3 tane portlet varsa her birinin container olması gerekmiyor.
    */
    render() {
        return (
            <div style={{width:(this.props.width?this.props.width:"auto"), height:(this.props.height?this.props.height:"auto"), ...this.props.style}} className={"hub-wb-container" + (this.props.direction==="row"?" row":" col") + (this.props.stretch?" stretch":"")}>
                {this.props.children}
            </div>
        );
    }
}

export default Container;
const HiItalic = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill={bgColor ?? "none"}
    >
      <path
        d="M15.8333 3.3335H8.33329M11.6666 16.6668H4.16663M12.5 3.3335L7.49996 16.6668"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiItalic;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'


class ProjectTileFilterArea extends Component {

    constructor(props) {
        super(props);    
        this.onChange = this.onChange.bind(this);
    }
    
    onChange(event) {
        this.props.handleChange(event.target.value);
    }

    render() {
        return (
            <div className="hub-projectTile-filterArea">
                <div className="hub-projectTile-filterContainer"> 
                    <div className="icon"><FontAwesomeIcon size="lg" icon={faMagnifyingGlass} color="#666666" /></div>
                    <div className="filter"><input type="text" value={this.props.filterText} onChange={this.onChange} placeholder="Search or filter..." /></div>
                </div>
            </div>
        );
    }
}

export default ProjectTileFilterArea;
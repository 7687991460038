import React from "react";

import Auth from "../../auth/Auth";
import TextWidget from "./TextWidget";
import LoadingScreen from "../../common/statusScreen/loadingScreen";

export default class TextWidgetRunner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }

  render() {
    //console.log("metric",this.state)
    return <TextWidget config={this.props.config} />;
  }
} 

import React from "react";

const HiFilter = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M4 8H12M2 4H14M6 12H10"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiFilter;

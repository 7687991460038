import { useState } from "react";
import "./Avatar.scss";
import SkeletonLoader from "../skeletonLoader";
import { skeletonSet } from "../../static/config";

/**
 * Avatar is a component that displays a user's avatar. It can either show an image or a default
 * avatar with initials based on the user's first and last names. The avatar can be customized
 * with different sizes, colors, and styles.
 *
 * @param {object} props The component props.
 * @param {string} props.firstName The user's first name.
 * @param {string} [props.lastName=""] The user's last name.
 * @param {string} [props.color] The background color for the avatar when no image is available.
 * @param {string} props.uid The user's unique identifier, used to generate the profile image URL.
 * @param {"sm"|"md"|"lg"} [props.size="md"] The size of the avatar (small, medium, or large).
 * @param {object} [props.style] Additional inline styles to apply to the avatar container.
 * @param {React.ReactNode} [props.children] Optional children elements to render inside the avatar.
 * @param {boolean} [props.hasAvatar=false] Whether the user has an avatar image available.
 * @param {number} [props.letterCount=2] The number of letters to show in the default avatar.
 *
 * @returns {JSX.Element} The rendered Avatar component.
 *
 * @example
 * // Render an avatar with a profile image
 * <Avatar
 *   firstName="John"
 *   lastName="Doe"
 *   uid="12345"
 *   hasAvatar={true}
 *   size="md"
 *   style={{ width: 50, height: 50 }}
 * />
 *
 * @example
 * // Render a default avatar with initials
 * <Avatar
 *   firstName="John"
 *   lastName="Doe"
 *   color="#FF5733"
 *   size="lg"
 *   letterCount={1}
 * />
 */
const Avatar = ({
  firstName,
  lastName = "",
  color,
  uid,
  size = "md",
  style,
  children,
  hasAvatar,
  letterCount = 2,
  hash,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  const imageUrl = `${window.location.origin}/api/profileImage/${uid}/resourceImageType?imageType=crop`;

  const handleError = () => {
    setHasError(true);
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const defaultAvatar =
    letterCount === 2
      ? firstName?.split("")[0] + lastName?.split("")[0]
      : firstName?.split("")[0];

  const renderAvatar = () => {
    if (hasAvatar) {
      return (
        <img
          key={hash ?? uid}
          src={imageUrl}
          alt="Profile"
          onError={handleError}
          onLoad={handleImageLoad}
          style={{ display: !isLoaded ? "none" : "block" }}
        />
      );
    } else {
      return defaultAvatar;
    }
  };

  return (
    <div
      className={`hub-new-avatar-container ${size}`}
      style={{
        ...style,
        backgroundColor: hasAvatar ? "transparent" : color,
      }}
    >
      <div className="hub-new-avatar">
        {!isLoaded && hasAvatar && (
          <SkeletonLoader
            width={style?.width ?? skeletonSet?.avatar[size]?.width}
            height={style?.height ?? skeletonSet?.avatar[size]?.height}
            borderRadius="50%"
          />
        )}
        {hasError ? defaultAvatar : renderAvatar()}
        {children}
      </div>
    </div>
  );
};

export default Avatar;

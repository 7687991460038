import React, { Component } from "react";
import "./PrebuiltFilter.css";
import HiLine from "../../icons/HiLine";
import HiClose from "../../icons/HiClose";
import HubIcon from "../../hubIcon/HubIcon";

/*
    {
        code:"emptyRole",
        label: "Missing Role",
        filter:{
            option:{ value: 'null', label: 'Is Empty', indicator:"Is Empty"},
            value:[],
            valueIds:[]
        }
    }
*/
class PrebuiltFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelected: !!this.props.filter.defaultSelected,
    };
  }

  render() {
    let color = "#000000";

    if (this.props.filter.color && this.props.filter.color !== "") {
      color = this.props.filter.color;
    }

    return (
      <div
        onClick={() => {
          !this.state.isSelected &&
            this.props.applyPrebuiltFilter(this.props.filter);
          this.setState({ ...this.state, isSelected: !this.state.isSelected });
        }}
        className={`hub-dataTable-prebuiltFilter ${
          this.state.isSelected ? "selected-filter" : ""
        }`}
      >
        <span
          className="icon"
          style={{
            backgroundColor: color,
            padding: this.state.isSelected ? "2.5px" : "0px",
          }}
          /*  onClick={() => {
            this.props.onRemove(this.props.filter.code);
          }} */
        >
          {this.state.isSelected ? (
            <HubIcon lineColor="#fff" fontSize={13}>
              <HiClose />
            </HubIcon>
          ) : (
            <HiLine lineColor={color} />
          )}
        </span>
        <span className="label">{this.props.filter.label}</span>
      </div>
    );
  }
}

export default PrebuiltFilter;

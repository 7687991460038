import React from "react";

const HiCollapse = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M2.66667 9.33333H6.66667M6.66667 9.33333V13.3333M6.66667 9.33333L2 14M13.3333 6.66667H9.33333M9.33333 6.66667V2.66667M9.33333 6.66667L14 2"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiCollapse;

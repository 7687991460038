import React, { Component } from 'react'
import PageLayout from '../../common/pageElements/pageLayout/pageLayout'
export default class dashboard extends Component {
  render() {
   // console.log("dashboard", this.props)

    return (
        < PageLayout code={ "projectDashboard" }  {...this.props}/>
    )
  }
}

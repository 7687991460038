import NoteFileItem from "./NoteFileItem";

const NoteFiles = ({ dataSet }) => {
  const fileList = dataSet?.conversationFiles?.map((file) => (
    <NoteFileItem key={file.hash} fileData={file} />
  ));
  return <div className="files-wrapper">{fileList}</div>;
};

export default NoteFiles;

import React, { Component } from "react";
import Navigation from "./../navigation/Navigation";
import "./../App.css";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "helloWorld",
      isNavCollapsed: 1,
    };
    this.onNavCollapse = this.onNavCollapse.bind(this);
  }

  onNavCollapse() {
    let newState = this.state;
    this.setState({
      ...newState,
      isNavCollapsed: this.state.isNavCollapsed === 0 ? 1 : 0,
    });
  }

  render() {

        //console.log("Layout",this.props, this.props.history)
    return (
      <div id="hub-root" style={{ paddingLeft: "70px" }}>
        <Navigation
          activeNavId={this.props.navId}
          collapsed={this.state.isNavCollapsed}
          onCollapse={this.onNavCollapse}
        />
        <div id="hub-mainPageArea">
          {/*<MainHeader pageTitle={this.props.selectedRecord?.name?this.props.selectedRecord?.name:this.props.pageTitle} />*/}
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Layout;

import React, { Component } from 'react';
import {faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class YearlyPicker extends Component {

    /* Dönemler başlangıç tarihinin metni ile olabilir. */
   
    constructor(props){ 
       super(props);
       const now = new Date();

       this.periodsLeft = [
           {periodId: 2021, periodIdStr:"2021", periodLabel:"2021", periodName:"2021"},
           {periodId: 2023, periodIdStr:"2023", periodLabel:"2023", periodName:"2023"},
           {periodId: 2025, periodIdStr:"2025", periodLabel:"2025", periodName:"2025"},
           {periodId: 2027, periodIdStr:"2027", periodLabel:"2027", periodName:"2027"}
           
         ];

         this.periodsRight = [
            {periodId: 2022, periodIdStr:"2022", periodLabel:"2022", periodName:"2022"},
            {periodId: 2024, periodIdStr:"2024", periodLabel:"2024", periodName:"2024"},
            {periodId: 2026, periodIdStr:"2026", periodLabel:"2026", periodName:"2026"},
            {periodId: 2028, periodIdStr:"2028", periodLabel:"2028", periodName:"2028"}
          ];

       

       this.aligns = ["left", "center", "right"];

       this.state={
           startPeriod: now.getFullYear(),
           currentPeriod: now.getFullYear().toString()+"-"+(now.getMonth() + 1).toString().padStart(2, "0")+"-01"
       }
   }
   nextPeriod = () => {
       this.setState({...this.state, startPeriod:this.state.startPeriod+1})
   }
   prevPeriod = () => {
       this.setState({...this.state, startPeriod:this.state.startPeriod-1})
   }
   
   render() {
       let firstPeriod = this.state.startPeriod.toString();
       let secondPeriod = (this.state.startPeriod+1).toString();

       return (
           <>
           <div className="pickerWrapper">
               <div className="pickerColumn">
                     {
                           /*
                   <div className="periodSelector">
                      
                       <div className="icon" onClick={this.prevPeriod} ><FontAwesomeIcon icon={faChevronLeft} size="sm"/> </div>
                       <div className="label">{firstPeriod}</div> 
                         
                   </div>   
                    */
                } 
                   <div className="periodBody">
                    {this.periodsLeft.map((j, ind)=>{
                               return (<div className="monthItem" style={{width:"100%"}}>
                                   <div style={{width:"100%"}} className={"cellItem"+ ((this.state.currentPeriod===firstPeriod.toString()+"-"+j.periodIdStr+"-"+"01")?" today":"")}>{j.periodLabel}</div>
                                   </div>)})}
                   </div>
               </div>
               {/** SECOND COLUMN */}
               <div className="pickerColumn">
                    {    
                           /*
                   <div className="periodSelector">
                       <div className="label">{secondPeriod}</div> 
                       <div className="icon" onClick={this.nextPeriod} ><FontAwesomeIcon icon={faChevronRight} size="sm"/> </div>
                   </div>   
                    */
                    } 
                   <div className="periodBody">
                       {this.periodsRight.map((j, ind)=>{
                           return (<div className="monthItem" style={{width:"100%"}}>
                               <div style={{width:"100%"}} className={"cellItem"+ ((this.state.currentPeriod===secondPeriod.toString()+"-"+j.periodIdStr+"-"+"01")?" today":"")}>{j.periodLabel}</div>
                       </div>)})}
                   </div>
               </div>
           </div>
           <div className="footNote">You can choose up to 48 quarters</div>
           </>
       );
   }
}

export default YearlyPicker;
import React, { Component, useCallback } from "react";
import "./DataTable.css";
import Auth from "./../../auth/Auth";
import TH from "./TH";
import TD from "./TD";
import FilterBar from "./filters/FilterBar";
import Toolbar from "./Toolbar";
import ActionBar from "./ActionBar";
import Pagination from "./Pagination";
import SideBar from "./SideBar";
import GanttTH from "./GanttTH";
import MainButton from "../../common/buttons/MainButton";
import Gantt from "./Gantt";
import { Utils, extractColumnWidth } from "./../../common/Commons";
import { DateUtils } from "./../../common/Commons";
import "../spinner.css";

import LoadingScreen from "../statusScreen/loadingScreen";

import ContextMenu from "../contextMenu/contextMenu";
import ContextItem from "../contextMenu/contextItem";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faEmptySet,
  faSpinner,
  faCopy,
  faClone,
  faTrash,
  faCircleInfo,
} from "@fortawesome/pro-duotone-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "../modal";
import HiExpand from "../icons/HiExpand";
// bir objenin içindeki nested değerleri bulmaya yarıyor
// resolvePath(window,'document.body.xyz') => undefined
// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

const findGetParameter = (parameterName) => {
  var result = null,
    tmp = [];
  var items = window.location.search.substr(1).split("&");
  //console.log("items",items)
  for (var index = 0; index < items.length; index++) {
    tmp = items[index].split("=");
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

function pad2(n) {
  return n < 10 ? "0" + n : n;
}

class DataTable extends Component {
  /*
        Props:
            dataSet:  Data dizisi  Array olacak ve  beri eleman obje olarak kaydın detayını içerecek
            config: Tablonun konfigürasyonu
                config.columns: tablo kolonları
    */

  constructor(props) {
    super(props);

    this.isScrolling = {}; //globalTimer

    this.justOnce = true; //globalTimer
    this.utils = new Utils();
    this.dutils = new DateUtils();

    // Visible Columns isminde bir AoO oluşturuyoruz. Orada bütün kolonlar var. Bu kolonlara width değişkenini türüne bağlı olarak ekliyoruz.
    // Yapılan değişikliklerde ApplyVisibleColumns'u değiştirmeyi unutmayalım
    let newConfig = this.props.config;
    //console.log(newConfig);
    newConfig.view.visibleColumns = [];
    this.props.config.columns.map((c) => {
      if (this.props.config.view.visibleColumnList.indexOf(c.id) > -1) {
        newConfig.view.visibleColumns.push({
          ...c,
          width: extractColumnWidth(c),
          /* c.type === "text" || c.type === "hour" || c.type === "day"
              ? 240
              : 150, */
        });
      }
    });
    // newConfig' boş filter dizileri de ekleniyor. List id'leri tutmak için
    newConfig.view.filterList = [];
    newConfig.view.filters = [];
    // default view mode:
    newConfig.view.mode = newConfig.view.mode ? newConfig.view.mode : "list";

    newConfig.view.showSelect =
      newConfig.view.showSelect !== undefined
        ? newConfig.view.showSelect
        : true;

    this.state = {
      isFilterOpen: false,
      orderBy: "",
      orderType: "ASC",
      selectedRows: [],
      selectedRowsBefore: [],
      perPage: 25,
      showContextMenu: false,
      anchorPoint: { x: 0, y: 0 }, // Sağ tık için tıklanan pozisyonu tutacak.
      wPerMonth: 120, //Gantt bölümünde bir ayın genişliği
      page: 0,
      isSideBarOpen: false,
      config: newConfig,
      ganttRenderBy: 0,
      ganttRenderCount: 100,
      ganttRenderOverwrap: 50,
      selectedRecord: {},
      hideGanttbar: true,
      hideColor: false,
      selectedRecordRowNum: -1,
      data: [], // filtrelenmiş veri tablolara bu giderecek.
      rawData: [], // çekilen ham data
      sortString: "",
      sortColumn: "",
      showModal: false,
      newCreate: false,
      selecteds: [],
      Loading: true,
      Thinking: false,
      collapseAll: false,
      collapseStatus: false,
      expandArray: [],
      expandMode: "expand",
      sidebarWidth: 580,
      sidebarType: "",
      createType: "",
    };

    this.contextMenuRef = React.createRef();
  }

  /* Filter Methodları */
  toggleFilter = () => {
    this.setState({ ...this.state, isFilterOpen: !this.state.isFilterOpen });
  };

  addFilter = (c) => {
    // console.log("addFilter",c)
    // Seçili filtre listesine filtre ekler.
    // param olarak kolon tanımını alıyor. Hem filters hem filterList'i güncelliyor. Eğer kayıt varsa bir şey yapmıyor. Yoksa ekliyor.
    if (this.state.config.view.filterList.indexOf(c.id) < 0) {
      let newConfig = { ...this.state.config };

      newConfig.view.filterList.push(c.id);
      newConfig.view.filters.push(c);

      this.setState({ ...this.state, config: newConfig });
    }
  };

  removeFilter = (c) => {
    // param olarak kolon tanımını alıyor. Hem filters hem filterList'i güncelliyor.
    if (this.state.config.view.filterList.indexOf(c.id) > -1) {
      const pos = this.state.config.view.filterList.indexOf(c.id);

      let newConfig = { ...this.state.config };

      newConfig.view.filterList.splice(pos, 1);
      newConfig.view.filters.splice(pos, 1);

      let filteredData = this.prepareFilteredData(
        newConfig.view.filters,
        this.state.rawData
      );

      this.setState({ ...this.state, config: newConfig, data: filteredData });

      // User View filtre ekleme
      if (this.state.config.pageId) {
        this.utils.updateUserView(this.state.config.pageId, {
          visibleColumnList: this.state.config.view.visibleColumnList,
          filter: {
            filterList: newConfig.view.filterList,
            filters: newConfig.view.filters,
          },
        });
      }
    }
  };

  setFilter = (columnId, filter) => {
    // console.log("setFilter",columnId, filter)
    if (this.state.config.view.filterList.indexOf(columnId) > -1) {
      const pos = this.state.config.view.filterList.indexOf(columnId);
      let newConfig = { ...this.state.config };
      newConfig.view.filters[pos].filter = filter;
      let filteredData = this.prepareFilteredData(
        newConfig.view.filters,
        this.state.rawData
      );
      this.setState({
        ...this.state,
        config: newConfig,
        data: filteredData,
        ganttRenderBy: 0,
      });

      // User View filtre ekleme
      if (this.state.config.pageId) {
        this.utils.updateUserView(this.state.config.pageId, {
          visibleColumnList: this.state.config.view.visibleColumnList,
          filter: {
            filterList: newConfig.view.filterList,
            filters: newConfig.view.filters,
          },
        });
      }

      //  this.ganttContainerScrollHandler(0,0);
    }
  };

  applyPrebuiltFilter = (prebuiltFilter) => {
    // console.log("applyPrebuiltFilter",prebuiltFilter,this.state.config.prebuiltFilters)
    let newConfig = { ...this.state.config };
    prebuiltFilter.filterSet.map((f) => {
      if (this.state.config.view.filterList.indexOf(f.id) > -1) {
        const pos = this.state.config.view.filterList.indexOf(f.id);
        newConfig.view.filters[pos].filter = f.filter;
      } else {
        newConfig.view.filterList.push(f.id);
        newConfig.view.filters.push(f);
      }
    });

    let filteredData = this.prepareFilteredData(
      newConfig.view.filters,
      this.state.rawData
    );

    this.setState({ ...this.state, config: newConfig, data: filteredData });
  };

  validateFilter = (filterCol, dataRow) => {
    let v = resolvePath(dataRow, filterCol.dataKey);

    if (filterCol.type === "text") {
      switch (filterCol.filter.option.value) {
        case "null":
          return v === null || v === "";
          break;
        case "notnull":
          return v !== null && v !== "";
          break;
        case "contains":
          return new RegExp(filterCol.filter.value, "i").test(v);
          break;
        case "notcontains":
          return !new RegExp(filterCol.filter.value, "i").test(v);
          break;
        case "equal":
          return v === filterCol.filter.value;
          break;
        case "notequal":
          return v !== filterCol.filter.value;
          break;
        default:
          return true;
      }
    } else if (filterCol.type === "date") {
      switch (filterCol.filter.option.value) {
        case "null":
          return v === null || v === "";
          break;
        case "notnull":
          return v !== null && v !== "";
          break;
        case "dateIs":
          return v === filterCol.filter.value[0];
          break;
        case "dateIsNot":
          return v !== filterCol.filter.value[0];
          break;
        case "dateBefore":
          return new Date(v) < new Date(filterCol.filter.value[0]);
          break;
        case "dateAfter":
          return new Date(v) > new Date(filterCol.filter.value[0]);
          break;
        case "dateBetween":
          return (
            new Date(v) >= new Date(filterCol.filter.value[0]) &&
            new Date(v) <= new Date(filterCol.filter.value[1])
          );
          break;
        case "dateNotBetween":
          return !(
            new Date(v) >= new Date(filterCol.filter.value[0]) &&
            new Date(v) <= new Date(filterCol.filter.value[1])
          );
          break;
        default:
          return true;
      }
    } else if (filterCol.type === "number") {
      switch (filterCol.filter.option.value) {
        case "null":
          return v === null || v === "";
          break;
        case "notnull":
          return v !== null && v !== "";
          break;
        case "equal":
          return v === parseFloat(filterCol.filter.value[0]);
          break;
        case "notequal":
          return v !== parseFloat(filterCol.filter.value[0]);
          break;
        case "numberGt":
          return v > parseFloat(filterCol.filter.value[0]);
          break;
        case "numberLt":
          return v < parseFloat(filterCol.filter.value[0]);
          break;
        case "numberBetween":
          return (
            v >= parseFloat(filterCol.filter.value[0]) &&
            v <= parseFloat(filterCol.filter.value[1])
          );
          break;
        case "numberNotBetween":
          return !(
            v >= parseFloat(filterCol.filter.value[0]) &&
            v <= parseFloat(filterCol.filter.value[1])
          );
          break;
        default:
          return true;
      }
    } else if (filterCol.type === "percentage") {
      //  console.log("percentage",filterCol)

      switch (filterCol.filter.option.value) {
        case "null":
          return v === null || v === "";
          break;
        case "notnull":
          return v !== null && v !== "";
          break;
        case "equal":
          return v === parseFloat(filterCol.filter.value[0]);
          break;
        case "notequal":
          return v !== parseFloat(filterCol.filter.value[0]);
          break;
        case "numberGt":
          return v > parseFloat(filterCol.filter.value[0]);
          break;
        case "numberLt":
          return v < parseFloat(filterCol.filter.value[0]);
          break;
        case "numberBetween":
          return (
            v >= parseFloat(filterCol.filter.value[0]) &&
            v <= parseFloat(filterCol.filter.value[1])
          );
          break;
        case "numberNotBetween":
          return !(
            v >= parseFloat(filterCol.filter.value[0]) &&
            v <= parseFloat(filterCol.filter.value[1])
          );
          break;
        default:
          return true;
      }
    } else if (filterCol.type === "dynamicLookup") {
      switch (filterCol.filter.option.value) {
        case "null":
          return v === null || v === "";
          break;
        case "notnull":
          return v !== null && v !== "";
          break;
        case "isOneOf":
          return (
            v !== null &&
            filterCol.filter.valueIds.indexOf(v[filterCol.valueAttribute]) > -1
          );
          break;
        case "isNotOneOf":
          return (
            v === null ||
            !(
              filterCol.filter.valueIds.indexOf(v[filterCol.valueAttribute]) >
              -1
            )
          );
          break;
        default:
          return true;
      }
    } else if (filterCol.type === "staticLookup") {
      switch (filterCol.filter.option.value) {
        case "null":
          return v === null || v === "";
          break;
        case "notnull":
          return v !== null && v !== "";
          break;
        case "isOneOf":
          return v !== null && filterCol.filter.valueIds.indexOf(v.uid) > -1;
          break;
        case "isNotOneOf":
          return v === null || !(filterCol.filter.valueIds.indexOf(v.uid) > -1);
          break;
        default:
          return true;
      }
    } else if (filterCol.type === "boolean") {
      switch (filterCol.filter.option.value) {
        case "is":
          return v === true;
          break;
        case "isNot":
          return v !== true;
          break;
        default:
          return true;
      }
    } else {
      return true;
    }
  };

  clearFilters = () => {
    let newConfig = { ...this.state.config };

    newConfig.view.filterList = [];
    newConfig.view.filters = [];

    /*      const updatedFilterlist =  newConfig.view.filterList.filter((filterItem) => filterItem !== filterCode);
          const updatedFilters = newConfig.view.filters.filter((filterItem) =>  filterItem.id !== filterCode); 

           newConfig.view.filterList = updatedFilterlist;
           newConfig.view.filters = updatedFilters;  */

    let filteredData = this.prepareFilteredData(
      newConfig.view.filters,
      this.state.rawData
    );

    this.setState({ ...this.state, config: newConfig, data: filteredData });
  };

  prepareFilteredData = (filterSet, rawData) => {
    // State'i alır,
    // state'deki datayı döndürerek filtrelere uyuyorsa valid olarak işaretler ve filteredData'ya yazar.
    // filteredData'yı döner

    // Sortlama da yapar
    // rawData sortlanıyor.

    //console.log("prepareFilteredData",filterSet)

    const newRawData = [...rawData];

    if (this.state.sortColumn !== "" && this.props.feSorting) {
      const found = this.state.config.columns.find(
        (e) => e?.dataKey === this.state.sortColumn
      );

      //console.log("found",found)

      newRawData.sort((a, b) => {
        let aStr = resolvePath(a, this.state.sortColumn);
        let bStr = resolvePath(b, this.state.sortColumn);
        let result = 0;

        if (aStr === null || bStr === null) {
          return this.state.sortType === "asc" ? 1 : -1;
        }

        if (found.type === "text") {
          result = aStr?.localeCompare(bStr, "tr", { sensitivity: "base" });
        } else if (found.type === "staticLookup") {
          result = aStr?.name.localeCompare(bStr?.name, "tr", {
            sensitivity: "base",
          });
        } else if (found.type === "dynamicLookup") {
          if (aStr[found.labelAttribute] && bStr[found.labelAttribute]) {
            result = aStr[found.labelAttribute]?.localeCompare(
              bStr[found.labelAttribute],
              "tr",
              { sensitivity: "base" }
            );
          }
        } else if (
          found.type === "number" ||
          found.type === "percent" ||
          found.type === "hour"
        ) {
          result = aStr > bStr ? 1 : -1;
        } else {
          result = aStr > bStr ? 1 : -1;
        }

        if (this.state.sortType === "asc") {
          result = result * -1;
        }

        return result;
      });
    }

    let filteredData = [];
    let filteredDataKeys = []; // Parent'ları getirirken mükerrerliği önlemek için bir diziye key listesini atıyoruz. task-1231 şeklinde objectType + uid

    newRawData?.map((d) => {
      let valid = 1;

      filterSet.map((f) => {
        if (f.filter && f.filter !== null) {
          let v = this.validateFilter(f, d);
          if (!v) {
            valid = valid - 1;
          }
        }
      });

      if (valid === 1) {
        filteredData.push(d);
        filteredDataKeys.push(d.objectType + d?.uid?.toString());
      }
    });

    // Eğer ekran Gantt View'da ise;
    if (this.state.config.view.mode === "gantt") {
      // Filtrelenenleri döngüye sokup parent'ları listede mi diye bakıyoruz, eğer değilse listeye ekliyoruz.

      let x = 0;
      while (filteredData[x]) {
        if (filteredData[x].parentInd) {
          let d = newRawData[filteredData[x].parentInd];
          if (
            filteredDataKeys.indexOf(d?.objectType + d?.uid?.toString()) === -1
          ) {
            filteredData.push(d);
            filteredDataKeys.push(d.objectType + d.uid.toString());
          }
        }
        x = x + 1;
      }

      // Filtrelenen kayıtlar ve parentlarını WbsSort'a göre sıralıyoruz.
      filteredData.sort((a, b) => {
        return a.wbsSort - b.wbsSort;
      });
    }

    return filteredData;
  };

  /* Filter Methodları Sonu */

  toggleSidebar = () => {
    this.setState(
      {
        ...this.state,
        isSideBarOpen: !this.state.isSideBarOpen,
        newCreate: false,
      },
      () => {
        this.updateUserView("isSideBarOpen", this.state.isSideBarOpen);
      }
    );
  };

  setSidebarWidth = (width) => {
    //console.log("isSideBarOpen",width)
    this.setState({ ...this.state, sidebarWidth: width }, () => {
      this.updateUserView("sidebarWidth", width);
    });
  };

  // * Bu method user Viewi günceller.
  // * Burdaki verilere göre initializeExpandStatus metodu gerekli alanları setler
  updateUserView = (attributeName, attribute, attribute2) => {
    if (this.state.config.pageId) {
      let expandMode = this.state.expandMode;
      let expandArray = this.state.expandArray;
      let selectedRecord = {};
      //console.log("selectedRecord",this.state.selectedRecord)
      if (this.state.selectedRecord) {
        selectedRecord = {
          uid: this.state.selectedRecord?.uid,
          objectType: this.state.selectedRecord?.objectType,
        };
      }
      let isSideBarOpen = this.state.isSideBarOpen;
      let hideGanttbar = this.state.hideGanttbar;
      let hideColor = this.state.hideColor;

      let scroll = this.state.scroll;
      let sidebarWidth = this.state.sidebarWidth;

      switch (attributeName) {
        case "expandMode":
          expandMode = attribute;
          expandArray = attribute2;
          break;
        case "expandArray":
          expandArray = attribute;
          break;
        case "selectedRecord":
          selectedRecord = attribute;
          break;
        case "isSideBarOpen":
          isSideBarOpen = attribute;
          break;
        case "hideGanttbar":
          hideGanttbar = attribute;
          break;
        case "hideColor":
          hideColor = attribute;
          break;
        case "scroll":
          scroll = attribute;
          break;
        case "sidebarWidth":
          sidebarWidth = attribute;
          break;
        default:

        // code block
      }

      this.utils.updateUserView(
        this.state.config.pageId + "-" + this.state.config.projectId,
        {
          expandMode: expandMode,
          expandArray: expandArray,
          selectedRecord: selectedRecord,
          isSideBarOpen: isSideBarOpen,
          hideGanttbar: hideGanttbar,
          hideColor: hideColor,
          scroll: scroll,
          sidebarWidth: sidebarWidth,
        }
      );
    }
  };

  toggleGanttbar = () => {
    //console.log("toggleGanttbar",this.state.hideGanttbar)
    this.setState(
      { ...this.state, hideGanttbar: !this.state.hideGanttbar },
      () => {
        this.updateUserView("hideGanttbar", this.state.hideGanttbar);
      }
    );
  };

  toggleColor = () => {
    this.setState({ ...this.state, hideColor: !this.state.hideColor }, () => {
      this.updateUserView("hideColor", this.state.hideColor);
    });
  };

  openSidebar = () => {
    this.setState(
      { ...this.state, isSideBarOpen: true, newCreate: false },
      () => {
        this.updateUserView("isSideBarOpen", this.state.isSideBarOpen);
      }
    );
  };

  openSidebarNewTask = () => {
    this.setState({ ...this.state, isSideBarOpen: true, newCreate: true });
  };

  openNewSideBar = (objectType, createType) => {
    console.log("openNewSideBar", objectType, createType);
    this.setState(
      {
        ...this.state,
        showContextMenu: false,
        sidebarType: objectType,
        isSideBarOpen: true,
        createType: createType !== undefined ? createType : "",
      } /*,this.openSidebarNewTask*/
    );
  };

  hideModal = () => {
    this.setState({ ...this.state, showModal: false });
  };

  showModal = () => {
    this.setState({ ...this.state, showModal: true });
  };

  selectRow = (e, i, d) => {
    // console.log("selectRow", i,this.state.ganttRenderBy)
    e.stopPropagation();
    // Buranın daha verimli çalışması gerekebilir.
    let newSelectedRows = [...this.state.selectedRows];
    let index = newSelectedRows.indexOf(i);
    if (index >= 0) {
      newSelectedRows.splice(index, 1);
    } else {
      newSelectedRows.push(i);
    }
    // console.log(this.state.selectedRows)
    this.setState(
      { ...this.state, selectedRows: newSelectedRows },
      this.selecteds(i, d)
    );
  };

  selecteds = (i, d) => {
    //console.log("selecteds",i,d)

    let array = this.state.selecteds;

    if (array.includes(d.uid)) {
      const index = array.indexOf(d.uid);
      array.splice(index, 1);
    } else {
      array.push(d.uid);
    }

    this.setState({ ...this.state, selecteds: array });
  };

  deselectAll = () => {
    this.setState({ ...this.state, selectedRows: [], selecteds: [] });
  };

  selectAll = () => {
    let rows = [...Array(this.state.data.length).keys()];
    let selecteds = this.state.data.map((a) => a.uid);

    if (this.state.config.view.selectBoxCondition) {
      rows = [];
      selecteds = [];
      // selecteds = this.state.data.filter(x=>x[this.state.config.view.selectBoxCondition.key]===this.state.config.view.selectBoxCondition.value).map(a => a.uid);
      this.state.data.map((a, i) => {
        if (
          a[this.state.config.view.selectBoxCondition.key] ===
          this.state.config.view.selectBoxCondition.value
        ) {
          selecteds.push(a.uid);
          rows.push(i);
        }
      });
    }
    //console.log(selecteds,rows)
    this.setState({ ...this.state, selectedRows: rows, selecteds: selecteds });
  };

  setPage = (page) => {
    this.setState({ ...this.state, page: page, selectedRows: [] });
  };

  increaseGanttWrapper = () => {
    // GANTT'ta görününen bölümün aşağı kaydırılmasında çalışacak.
    const max = 300; // Maximum görünür satır.
  };

  decreaseGanttWrapper = () => {
    // GANTT'ta görününen bölümün yukarı kaydırılmasında çalışacak.
  };

  selectRecord = (
    c,
    i,
    cb = (e) => {
      void 0;
    }
  ) => {
    this.updateUserView("selectedRecord", {
      uid: c.uid,
      objectType: c.objectType,
    });

    //console.log("selectRecord",c)
    this.setState(
      {
        ...this.state,
        sidebarType: "",
        selectedRecord: c,
        selectedRecordRowNum: i + this.state.ganttRenderBy,
      },
      () => {
        if (
          this.state.config.view.mode == "gantt" &&
          !this.state.hideGanttbar
        ) {
          /*
                // Gantt barda ilgili row'u selected yapıyor.
                if(document.getElementsByClassName("hub-gantt-row active").length>0){
                    document.getElementsByClassName("hub-gantt-row active")[0].className="hub-gantt-row";
                }
                document.getElementById("hub-gantt-row-"+i.toString()).className="hub-gantt-row active";
                */

          // Eğer gantt seçiliyse seçilen gantt bara scrolluyor

          // Seçilen satırın ganttRender'ı ile gerçek yeri alınıyor.
          const sr = i + this.state.ganttRenderBy;

          if (this.state.data[sr].ganttLeft) {
            if (this.state.data[sr].ganttLeft > this.state.wPerMonth) {
              document.getElementsByClassName("hub-gantt")[0].scroll({
                left: this.state.data[sr].ganttLeft - this.state.wPerMonth,
                behavior: "smooth",
              });
            } else {
              document
                .getElementsByClassName("hub-gantt")[0]
                .scroll({ left: 0 });
            }
          }
        }
        cb();
      }
    );
  };

  applyVisibleColumns = (cl, userView) => {
    //console.log("applyVisibleColumns",cl)
    // Başlangıç değerleri constructor'da oluşuyor. Değişiklik yaparken ilgili bölüme dikkat etmek gerekiyor.
    // cl = Seçilen kolon id'lerinin dizisi

    let oldVisibileColumnList = [];
    oldVisibileColumnList = [...this.state.config.view.visibleColumnList];
    let oldVisibileColumns = [];
    oldVisibileColumns = [...this.state.config.view.visibleColumns];

    let newState = { ...this.state };
    newState.config.view.visibleColumns = [];

    newState.config.columns.map((c) => {
      if (cl.indexOf(c.id) > -1) {
        newState.config.view.visibleColumns.push({
          ...c,
          // Aşağıdaki karmaşanın sebebi eğer kayıt daha önce seçili ise ve bir değişiklik yapılmışsa, (width vs. kaybetmemek)
          width:
            oldVisibileColumnList.indexOf(c.id) > -1
              ? oldVisibileColumns[oldVisibileColumnList.indexOf(c.id)]?.width
              : c.type == "text" || c.type == "hour" || c.type == "day"
              ? 240
              : 150,
        });
      }
    });
    newState.config.view.visibleColumnList = cl;

    //console.log("applyVisibleColumns userView",userView?.filter)
    if (userView?.filter !== undefined) {
      newState.config.view.filters = userView.filter?.filters
        ? userView.filter?.filters
        : newState.config.view.filters;
      newState.config.view.filterList = userView.filter?.filterList
        ? userView.filter?.filterList
        : newState.config.view.filterList;
      newState.isFilterOpen =
        userView.filter?.filters.length > 0 ? true : false;
    }

    // !* Burası user view için güncelleme yapar.
    // ! ilk seferde girmemesi için bir şey yapmak lazım ama ne??
    if (this.state.config.pageId) {
      this.utils.updateUserView(this.state.config.pageId, {
        visibleColumnList: cl,
        filter: {
          filterList: userView?.filter?.filterList
            ? userView.filter?.filterList
            : newState.config.view.filterList,
          filters: userView?.filter?.filters
            ? userView.filter?.filters
            : newState.config.view.filters,
        },
      });
    }

    this.setState(newState);
  };

  componentDidMount() {
    // console.log("componentDidMount DataTable");
    this.getData();
  }

  updateSingleRow = (ind, v) => {
    // ind sırasındaki veriyi rawdata'da v ile günceller,
    // sonrasında tekrar filteredData'yı çağırır ve setState ile veriyi günceller.
    // Veri hem rawData'da hem
    /*
            let newData = [...this.state.data]; // rawData kopyalanır
            newData.splice(ind,1,v);  // ilgili satır güncellenir.
            this.setState({...this.state, data:newData});
        */
  };

  componentDidUpdate(prevProps, prevStates) {
    if (
      prevStates.selectedRecord !== this.state.selectedRecord &&
      this.state.newCreate === true
    ) {
      this.setState({ ...this.state, newCreate: false });
    }

    if (
      this.props.refresh !== undefined &&
      prevProps.refresh !== this.props.refresh
    ) {
      this.getData();
    }

    if (
      this.props.counter !== undefined &&
      prevProps.counter !== this.props.counter
    ) {
      this.getData();
    }
  }

  getData = async () => {
    let filteredData = [];
    //console.log("getData",this.state.selectedRecord)
    if (this.state.Loading === false) {
      this.setState({ ...this.state, Thinking: true });
    }
    let newConfig = { ...this.state.config };
    this.state.config.prebuiltFilters
      ?.filter((x) => x.defaultSelected)
      .map((pf) => {
        pf.filterSet.map((f) => {
          //console.log(f,newConfig.view.filterList)
          if (this.state.config.view.filterList.indexOf(f.id) > -1) {
            const pos = this.state.config.view.filterList.indexOf(f.id);
            newConfig.view.filters[pos].filter = f.filter;
          } else {
            newConfig.view.filterList.push(f.id);
            newConfig.view.filters.push(f);
          }
        });
      });

    let viewObject = await this.utils.getUserView(
      this.state.config.pageId,
      this.state.config.projectId
    );
    let view = {};
    let projectView = {};

    if (viewObject) {
      view = viewObject.general;
      projectView = viewObject.projectView;

      /*
            if(view?.visibleColumnList)
                {   

                    //console.log("ife girdik",view)
                    this.applyVisibleColumns(view.visibleColumnList,view)
                }
*/

      // console.log("getData",view,projectView)
      //config.view.visibleColumnList=view.visibleColumnList
    }

    if (this.props.sampleData) {
      filteredData = this.prepareFilteredData(
        this.state.config.view.filters,
        this.props.sampleData
      );
      this.setState({
        ...this.state,
        Loading: false,
        data: filteredData,
        rawData: this.props.sampleData,
      });
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.check(),
        },
      };

      //console.log("this.state.sortString",this.state.sortString)
      let searchString = "";

      if (this.state.sortString !== "") {
        searchString = "?" + this.state.sortString;
      } else {
        searchString = "";
      }

      fetch(this.props.config.endPoint + searchString, requestOptions)
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((r) => {
          if (r.status == 200) {
            let dutils = new DateUtils();
            //console.log(r)
            if (
              this.state.config.childAttribute &&
              r.body.object !== null &&
              r.body.object.length > 0
            ) {
              //console.log(this.state.config)
              let ds = this.unHierarchyData(r.body.object);

              let ganttStart = new Date(
                ds.ganttStart.getFullYear(),
                ds.ganttStart.getMonth() - 1,
                1
              );
              let ganttFinish = new Date(
                ds.ganttFinish.getFullYear(),
                ds.ganttFinish.getMonth() + 4,
                0
              );
              //console.log(r.body.object)
              //console.log(ds.data)
              let list = ds.data.map((d) => {
                let newRow = { ...d };

                if (d.startDate !== null && d.finishDate !== null) {
                  newRow = {
                    ...newRow,
                    ganttWidth: this.getWidth(d.startDateObj, d.finishDateObj),
                    ganttLeft: this.getWidth(ganttStart, d.startDateObj),
                  };
                }

                if (
                  d.objectTypeInfo.code === "wbs" ||
                  d.objectTypeInfo.code === "activity"
                ) {
                  if (
                    d.p6Plan?.baselineStart !== null &&
                    d.p6Plan?.baselineFinish !== null
                  ) {
                    let s = new Date(d.p6Plan?.baselineStart);
                    let f = new Date(d.p6Plan?.baselineFinish);
                    newRow = {
                      ...newRow,
                      ganttBlWidth: this.getWidth(s, f),
                      ganttBlLeft: this.getWidth(ganttStart, s),
                    };
                  }
                }
                if (d.objectTypeInfo.code === "task") {
                  if (d.plannedStart !== null && d.plannedFinish !== null) {
                    let s = new Date(d.plannedStart);
                    let f = new Date(d.plannedFinish);
                    newRow = {
                      ...newRow,
                      ganttBlWidth: this.getWidth(s, f),
                      ganttBlLeft: this.getWidth(ganttStart, s),
                    };
                  }
                }

                return newRow;
              });

              if (list.length > 0) {
                filteredData = this.prepareFilteredData(
                  this.state.config.view.filters,
                  list
                );
              }

              //console.log("getdata",filteredData)
              // this.setState({...this.state, Loading:false, data:filteredData, rawData:list, ganttStart: ganttStart, ganttFinish: ganttFinish, ganttMonths: dutils.getMonths(new Date(ganttStart), new Date(ganttFinish))});
              this.setState(
                {
                  ...this.state,
                  Loading: false,
                  Thinking: false,
                  data: filteredData,
                  rawData: list,
                  ganttStart: ganttStart,
                  ganttFinish: ganttFinish,
                  ganttMonths: dutils.getMonths(
                    new Date(ganttStart),
                    new Date(ganttFinish)
                  ),
                  selectedRecordRowNum:
                    ds.gotoTaskIndex > -1
                      ? ds.gotoTaskIndex
                      : this.state.selectedRecordRowNum,
                  selectedRecord:
                    ds.gotoTaskIndex > -1
                      ? list[ds.gotoTaskIndex]
                      : this.state.selectedRecord,
                  selectedRows: [],
                  selecteds: [],
                },
                () => {
                  this.initializeExpandStatus(projectView, view);
                }
              );
            } else {
              if (r.body.object !== null && r.body.object.length > 0) {
                filteredData = this.prepareFilteredData(
                  this.state.config.view.filters,
                  r.body.object
                );
              }

              this.setState({
                ...this.state,
                Loading: false,
                Thinking: false,
                data: filteredData,
                rawData: r.body.object,
                selectedRows: [],
                selecteds: [],
              });
            }
          } else if (r.status == 401) {
            window.location.replace("/login");
          } else if (r.status == 403) {
            this.setState({
              ...this.state,
              LoadingError: true,
              Thinking: false,
              LoadingErrorText:
                "You are not authorized to see this page. Please contact your system administrator.",
            });
          } else {
            this.setState({
              ...this.state,
              LoadingError: true,
              Thinking: false,
              LoadingErrorText:
                "An unexpected error occured. Please contact your system administrator.",
            });
          }
        });
    }
  };

  unHierarchyData = (ds) => {
    // children attribute üzerinden liste oluşturuyor.
    // attribute children olarak fonksiyona yazılmış durumda ve henüz parametrik değil.
    //

    let list = [];
    let attr = [...ds];
    let j = 0;
    let ganttStart = null;
    let ganttFinish = null;
    let itemIndexList = []; // hangi ID'li kaydın hangi sırada olduğunu tutan index.

    /* eğer Gantt linki tıklandıysa o kaydın açılma özelliği */
    let gotoTask = findGetParameter("task");
    let searchArea = "code";
    if (gotoTask === null) {
      gotoTask = findGetParameter("taskId");
      searchArea = "uid";
    }

    //console.log("gotoTask",gotoTask,gotoTask?.includes("*id*"))
    let gotoTaskIndex = -1;
    /* eğer Gantt linki tıklandıysa o kaydın açılma özelliği */

    /*
        if(gotoTask?.includes("*id*"))
        {


            searchArea="uid"
            gotoTask=gotoTask.replace("*id*","")
            //console.log("gotoTask",gotoTask)
        }
        */

    for (let i = 0; i < attr.length; i++) {
      let d = attr[i];
      let level = 1;

      if (
        d.objectTypeInfo.code === "task" &&
        d[searchArea].toString() === gotoTask
      ) {
        gotoTaskIndex = i;
        // console.log("Kayıt Bulundu",searchArea,d[searchArea], gotoTask);
        // console.log("Sıra No", gotoTaskIndex);
      }

      itemIndexList.push(d.objectTypeInfo.code + d.uid.toString());

      if (d.objectTypeInfo.code === "task" && !d.isTask) {
        d.objectTypeInfo.name = "Summary Task";
      }
      //Yeri değişitirldi. Sıralama yapılınca çocuklardan başladığı için liste dolmuyor.

      if (d.parentInd !== undefined) {
        level = list[d.parentInd].hierLevel + 1;
      } else {
        level = 1;
      }

      if (d.startDate !== null && d.finishDate !== null) {
        // Bir süre start Date ve finish Date boş olabilir entegrasyon bitene kadar
        list.push({
          ...d,
          wbsSort: j,
          isExpanded: true,
          hierLevel: level,
          startDateObj: new Date(d.startDate),
          finishDateObj: new Date(d.finishDate),
        });
      } else {
        list.push({ ...d, wbsSort: j, isExpanded: true, hierLevel: level });
      }

      //console.log(list)

      // WBS İçin Parent Ekleme
      // Eğer Parent WBS alanı boş değilse parent'a childern olarak eklenmeli ve ilgili WBS'e Parent ind eklenmeli.

      if (d.objectTypeInfo.code === "wbs" && d.parentWbs !== null) {
        //console.log(d.parentWbsInfo.objectTypeInfo.code + d.parentWbsInfo.uid.toString());

        //console.log(itemIndexList);
        let newParentInd = itemIndexList.indexOf(
          d.parentWbsInfo.objectTypeInfo.code + d.parentWbsInfo.uid.toString()
        );
        d.parentInd = newParentInd;

        if (d.parentInd !== undefined) {
          level =
            list[d.parentInd]?.hierLevel + 1
              ? list[d.parentInd]?.hierLevel + 1
              : 1;
        } else {
          level = 1;
        }

        newParentInd !== -1
          ? Object.assign(list[list.length - 1], {
              hierLevel: level,
              parentInd: d.parentInd,
            })
          : Object.assign(list[list.length - 1], { hierLevel: level });

        //Sıralamadaki hata için eklendi
        if (list[newParentInd]) {
          list[newParentInd].children.push(d);
        }
      }

      if (d.objectTypeInfo.code === "task" && d.parent !== null) {
        //console.log(d.parentWbsInfo.objectTypeInfo.code + d.parentWbsInfo.uid.toString());

        //console.log(itemIndexList);
        let newParentInd = itemIndexList.indexOf(
          d.parent.objectTypeInfo?.code + d.parent.uid.toString()
        );
        d.parentInd = newParentInd;

        if (d.parentInd !== undefined) {
          level =
            list[d.parentInd]?.hierLevel + 1
              ? list[d.parentInd]?.hierLevel + 1
              : 1;
        } else {
          level = 1;
        }
        newParentInd !== -1
          ? Object.assign(list[list.length - 1], {
              hierLevel: level,
              parentInd: d.parentInd,
            })
          : Object.assign(list[list.length - 1], { hierLevel: level });

        //Sıralamadaki hata için eklendi
        if (list[newParentInd]) {
          //console.log(list[newParentInd])

          if (list[newParentInd].children === undefined) {
            Object.assign(list[newParentInd], { children: [] });
          }

          list[newParentInd].children.push(d);
        }
      }

      if (d.startDate !== null && d.finishDate !== null) {
        if (ganttStart === null && ganttFinish === null) {
          ganttStart = new Date(d.startDate);
          ganttFinish = new Date(d.finishDate);
        } else {
          const xs = new Date(d.startDate);
          if (ganttStart > xs) {
            ganttStart = xs;
          }
          const xf = new Date(d.finishDate);
          if (ganttFinish < xf) {
            ganttFinish = xf;
          }
        }
      }

      if (d.p6Plan) {
        if (
          d.p6Plan?.baselineStart !== null &&
          d.p6Plan?.baselineFinish !== null
        ) {
          const xs = new Date(d.p6Plan.baselineStart);
          if (ganttStart > xs) {
            ganttStart = xs;
          }
          const xf = new Date(d.p6Plan.baselineFinish);
          if (ganttFinish < xf) {
            ganttFinish = xf;
          }
        }
      }

      if (d.children) {
        let x = d.children.map((x) => {
          return { ...x, parentInd: i };
        });
        attr.splice(i + 1, 0, ...x);
      }

      j++;
    }

    let newList = list.map((lst, i) => {
      if (lst.children) {
        lst.childrenCount = lst.children.length;
      } else {
        lst.childrenCount = 0;
      }

      return lst;
    });

    //return {data: list, ganttStart: ganttStart, ganttFinish: ganttFinish};

    return {
      data: newList,
      ganttStart: ganttStart,
      ganttFinish: ganttFinish,
      gotoTaskIndex: gotoTaskIndex,
    };
  };

  parseLink = (link, record) => {
    const reps = link.match(/{(.|)*?}/gi);
    let newLink = link;

    reps.map((r) => {
      var value = resolvePath(record, r.substring(1, r.length - 1));
      newLink = newLink.replace(r, value);
    });

    return newLink;
  };

  makeTableRow = (d, i) => {
    return (
      <tr
        key={"tr-" + i.toString()}
        onDoubleClick={this.openSidebar}
        onClick={() => {
          this.selectRecord(d, i);
        }}
        className={this.state.selectedRecordRowNum == i ? "active" : ""}
      >
        {!this.state.config.view.hideSelectbox ? (
          <td align="center" className="hub-dataTable-picker">
            <input
              type="checkbox"
              disabled={
                this.state.config.view.selectBoxCondition
                  ? d[this.state.config.view.selectBoxCondition.key] ===
                    this.state.config.view.selectBoxCondition.value
                    ? false
                    : true
                  : false
              }
              checked={this.state.selectedRows.indexOf(i) > -1}
              onChange={(e) => {
                this.selectRow(e, i, d);
              }}
            />
          </td>
        ) : (
          false
        )}
        {/*   <td align="center" className="hub-dataTable-picker">
          {!this.state.config.view.hideSelectbox ? (
            <input
              type="checkbox"
              disabled={
                this.state.config.view.selectBoxCondition
                  ? d[this.state.config.view.selectBoxCondition.key] ===
                    this.state.config.view.selectBoxCondition.value
                    ? false
                    : true
                  : false
              }
              checked={this.state.selectedRows.indexOf(i) > -1}
              onChange={(e) => {
                this.selectRow(e, i, d);
              }}
            />
          ) : (
            false
          )}
        </td> */}
        {this.state.config.view.visibleColumns.map((c, ix) => {
          let link = c.linkTo ? this.parseLink(c.linkTo, d) : undefined;

          return (
            <TD
              key={"cell-" + i.toString() + "-" + c.id}
              width={c.width - 12}
              hasColor={!!c.color}
              type={c.type}
              linkTo={link}
              value={resolvePath(d, c.dataKey)}
              sideBar={this.openNewSideBar}
              objectType={d.objectType}
            />
          );
        })}
      </tr>
    );
  };

  makeGanttTableRow = (d, i) => {
    let colorClass = "";
    if (!this.state.hideColor) {
      switch (d.objectType) {
        case "wbs":
          colorClass = "purple";
          break;
        case "activity":
          colorClass = "lightyellow";
          break;
        case "task":
          colorClass =
            d.children && d.children.length > 0 ? "lightteal" : "false";
          break;
        default:
          colorClass = "";
      }
    }

    return (
      <tr
        key={"tr-" + i.toString()}
        onDoubleClick={this.openSidebar}
        onClick={() => {
          this.selectRecord(d, i);
        }}
        className={
          colorClass +
          " " +
          (this.state.selectedRecordRowNum == i + this.state.ganttRenderBy
            ? "active"
            : "")
        }
      >
        <td align="center" className="hub-dataTable-picker">
          {!this.state.config.view.hideSelectbox ? (
            <input
              type="checkbox"
              disabled={
                this.state.config.view.selectBoxCondition
                  ? d[this.state.config.view.selectBoxCondition.key] ===
                    this.state.config.view.selectBoxCondition.value
                    ? false
                    : true
                  : false
              }
              checked={this.state.selectedRows.indexOf(i) > -1}
              onChange={(e) => {
                this.selectRow(e, i, d);
              }}
            />
          ) : (
            false
          )}
        </td>
        {this.state.config.view.visibleColumns.map((c, ix) => {
          if (c.id !== "code") {
            let link = c.linkTo ? this.parseLink(c.linkTo, d) : undefined;
            return (
              <TD
                hasColor={!!c.color}
                handleRightClick={(e) => {
                  this.handleRightClick(e, d, i);
                }}
                key={"cell-" + i.toString() + "-" + c.id}
                hasChild={d.children && d.children.length > 0 ? true : false}
                isExpanded={d.isExpanded}
                width={c.width - (ix > 0 ? 8 : 32)}
                type={c.type}
                linkTo={link}
                value={resolvePath(d, c.dataKey)}
                sideBar={this.openNewSideBar}
                objectType={d.objectType}
              />
            );
          } else {
            let link = c.linkTo ? this.parseLink(c.linkTo, d) : undefined;
            return (
              <TD
                hasColor={!!c.color}
                deleted={
                  d.objectType === "activity" && !d.isMatched ? true : false
                }
                handleRightClick={(e) => {
                  this.handleRightClick(e, d, i);
                }}
                ind={d.wbsSort}
                rowNum={i + this.state.ganttRenderBy}
                hierLevel={d.hierLevel}
                toggleIsExpanded={this.toggleIsExpanded}
                hasChild={d.children && d.children.length > 0 ? true : false}
                expandable
                key={"cell-" + i.toString() + "-" + c.id}
                objectType={d.objectType}
                isExpanded={d.isExpanded}
                width={c.width - 12}
                type={c.type}
                linkTo={link}
                value={resolvePath(d, c.dataKey)}
                sideBar={this.openNewSideBar}
              />
            );
          }
        })}
      </tr>
    );
  };

  handleResizeColumn = (i, w) => {
    // Resize durduğunda  view'a kolonun width'ini ekleyen method.
    // i->kolon objesinin dizideki indexi
    // w yeni Deltawidth.   bu değer mevcut width'e + olarak eklenir.

    let newState = { ...this.state };
    newState.config.view.visibleColumns[i].width =
      this.state.config.view.visibleColumns[i].width + w;
    this.setState(newState);
  };

  handleSort = (columnName, sortType) => {
    //console.log(columnName+" "+sortType)
    //this.setState({...this.state,  sortString:'&sort='+columnName+','+sortType, sortColumn:columnName}, () => { this.getData()});

    this.setState(
      {
        ...this.state,
        sortString: "&sort=" + columnName + "," + sortType,
        sortType: sortType,
        sortColumn: columnName,
      },
      () => {
        if (this.props.feSorting) {
          this.handleFESorting();
        } else {
          this.getData();
        }
      }
    );
  };

  // handleFESort sıralamayı frontendde yapar
  handleFESorting = () => {
    let filteredData = this.prepareFilteredData(
      this.state.config.view.filters,
      this.state.rawData
    );
    this.setState({ ...this.state, Loading: false, data: filteredData });
  };

  setViewMode = (mode) => {
    let newState = { ...this.state };
    newState.config.view.mode = mode;
    this.setState(newState);
  };

  //Button Areadaki tuşlar için. Yukarıdan aşağıya method gönderemedim
  buttonClick = (type, endpoint) => {
    // console.log("buttonClick");
    //console.log(type);
    if (type === "new") {
      //console.log(type);
      !this.state.isSideBarOpen
        ? this.setState({
            ...this.state,
            newCreate: true,
            isSideBarOpen: !this.state.isSideBarOpen,
          })
        : this.setState({ ...this.state, newCreate: true });
    }
    if (type === "delete") {
      this.deleteRecord(endpoint, true);
    }
  };

  //arrayden indexe göre veri çıkarır. Çalışmadı
  arrayPop = (array) => {
    let data = this.state.data;
    //let index = newSelectedRows.indexOf(i);

    //console.log(array.length);
    //console.log(data);

    let newArray = array.sort((a, b) => b - a);

    console.log("arrayPop", array, newArray);

    for (var i = 0; i < newArray.length; i++) {
      // let index = data.indexOf(array[i]);
      //console.log(newArray[i]);
      // console.log(index);
      //console.log(array[i],1);
      data.splice(newArray[i], 1);
    }
    // console.log(data);
    return data;
  };

  deleteRecord = (endpoint, condition) => {
    console.log("deleteRecord", endpoint, condition);
    let cond = condition;
    let error = false;
    let task = {};
    if (cond !== undefined || cond === true) {
      if (endpoint === "/api/task") {
        //console.log(this.state.selectedRows)
        this.state.selecteds.map((i) => {
          task = this.state.rawData.find(
            (x) => x.uid === i && x.objectType === "task"
          );

          if (task === undefined) {
            task = this.state.data.find(
              (x) => x.uid === i && x.objectType === "task"
            );
          }
          console.log(i, task);
          //console.log(this.state.data[r])
          if (task.actualUnit > 0) {
            this.notify(
              "error",
              task.name +
                " (" +
                task.code +
                ") have actuals. Cannot be deleted."
            );
            error = true;
          }
        });
      }

      if (endpoint === "/api/assignment") {
        console.log(this.state.rawData);
        this.state.selecteds.map((i) => {
          task = this.state.rawData.find((x) => x.uid === i);

          if (task === undefined) {
            task = this.state.data.find((x) => x.uid === i);
          }
          console.log(i, task);
          //console.log(this.state.data[r])
          if (task?.actualUnit > 0) {
            this.notify(
              "error",
              task?.owner?.firstName +
                " " +
                task?.owner?.lastName +
                " have actuals. Cannot be deleted."
            );
            error = true;
          }
        });
      }

      if (!error) {
        cond = false;
      }

      //console.log("error",error,cond)
    }

    if (cond === undefined || cond === false) {
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.check(),
        },
        body: JSON.stringify({
          ids: this.state.selecteds,
        }),
      };

      fetch(endpoint, requestOptions)
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((r) => {
          console.log(r);
          if (r.body.status == 200) {
            //this.setState({ Loading:true});

            //  console.log(this.state.selectedRows)

            this.arrayPop(this.state.selectedRows);

            this.setState({ ...this.state, selecteds: [], selectedRows: [] });

            //this.getData();
            this.notify("success", "Deleted");
          } else if (r.body.status == 400) {
            this.notify("error", r.body.message);
          } else if (r.body.status == 401) {
            window.location.replace("/login");
          } else if (r.body.status == 403) {
            this.notify("error", r.body.message);
            this.setState({
              ...this.state,
              LoadingError: true,
              LoadingErrorText:
                "You are not authorized to see this page. Please contact your system administrator.",
            });
          } else {
            this.notify("error", "Record has actuals cannot be deleted");
            this.setState({
              ...this.state,
              LoadingError: true,
              LoadingErrorText:
                "An unexpected error occured. Please contact your system administrator.",
            });
          }
        });
    }
  };

  deleteSelectedRecord = (endpoint) => {
    if (this.state.Loading === false) {
      this.setState({ ...this.state, Thinking: true });
    }
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify({
        ids: [this.state.selectedRecord.uid],
      }),
    };

    fetch(endpoint, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log('DELETE',r);
        if (r.body.status == 200) {
          this.notify("info", "Success");
          this.setState(
            {
              ...this.state,
              selecteds: [],
              showContextMenu: false,
              selectedRows: [],
              selectedRecord: {},
              selectedRecordRowNum: -1,
              isSideBarOpen: false,
              Thinking: false,
            },
            () => {
              this.getData();
            }
          );
        } else if (r.body.status == 401) {
          window.location.replace("/login");
        } else if (r.body.status == 403) {
          this.notify("error", r.body.message);
          this.setState({
            ...this.state,
            LoadingError: true,
            Thinking: false,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.notify("error", "Record can not be deleted.");
          this.setState({
            ...this.state,
            LoadingError: true,
            Thinking: false,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  //Yeni eklenen gantta ilgili alana iter
  arrayInsert = (data, ind) => {
    var index = this.state.data.findIndex(
      (x) => x.uid === this.state.selectedRecord.uid
    );
    let selectedRecordArray = this.state.selectedRecord;
    if (ind !== undefined && ind !== null) {
      index = ind;
      selectedRecordArray = this.state.data[ind];
    }

    if (selectedRecordArray?.children === undefined) {
      Object.assign(selectedRecordArray, { children: [] });
    }
    selectedRecordArray.children.splice(0, 0, data);
    //console.log("selectedRecordArray");
    //console.log(selectedRecordArray);
    let array = [];

    let insertedArray = data;
    Object.assign(insertedArray, {
      startDateObj: new Date(insertedArray.startDate),
      finishDateObj: new Date(insertedArray.finishDate),
      isExpanded: true,
      parentInd: selectedRecordArray.parentInd + 1,
      hierLevel: selectedRecordArray.hierLevel + 1,
    });
    // console.log("insertedArray");
    // console.log(insertedArray);

    //console.log(new Date(this.state.ganttStart), insertedArray.startDateObj, this.getWidth(new Date(this.state.ganttStart), insertedArray.startDateObj))

    insertedArray = {
      ...insertedArray,
      ganttWidth: this.getWidth(
        insertedArray.startDateObj,
        insertedArray.finishDateObj
      ),
      ganttLeft: this.getWidth(
        new Date(this.state.ganttStart),
        insertedArray.startDateObj
      ),
    };

    this.state.data !== null ? (array = this.state.data) : (array = []);

    //console.log(array);
    array.splice(index + 1, 0, insertedArray);
    array.splice(index, 1, selectedRecordArray);
    //console.log(array);

    return array;
  };

  createRecord = (
    endpoint,
    data,
    insertLocation,
    dataType,
    objectType,
    insertToSelectedIndex
  ) => {
    console.log(
      "createRecord",
      endpoint,
      data,
      insertLocation,
      dataType,
      objectType,
      insertToSelectedIndex
    );
    //console.log(data)
    //console.log("staste",this.state)
    //console.log(JSON.stringify(this.state.formData))

    //console.log(this.props)
    let parent = {};

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(data),
    };

    fetch(endpoint, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log("createRecord return",r)
        if (r.status === 201 || r.status === 200) {
          if (insertLocation === "middle") {
            let array = {};
            // console.log("dataType",dataType)
            if (dataType === undefined) {
              if (endpoint === "/api/activity" || endpoint === "/api/wbs") {
                // console.log("activity")
                let sa = "wbsId";
                if (endpoint === "/api/wbs") {
                  sa = "parentWbs";
                }
                //array=this.state.data
                let ind = this.state.data.findIndex(
                  (x) => x.uid === data[sa] && x.objectType === "wbs"
                );
                array = this.arrayInsert(r.body.object, ind);
              } else {
                // yoktan oluşturma selected recorda bakmaz actionsdan
                if (insertToSelectedIndex) {
                  console.log("insertToSelectedIndex", r.body.object);
                  let ind = this.state.data.findIndex(
                    (x) =>
                      x.uid === data.activityId && x.objectType === "activity"
                  );
                  if (data.parent?.uid) {
                    ind = this.state.data.findIndex(
                      (x) =>
                        x.uid === data.parent.uid && x.objectType === "task"
                    );
                  }
                  array = this.arrayInsert(r.body.object, ind);
                } else {
                  array = this.arrayInsert(r.body.object);
                }
              }

              // console.log("array",array);
              //console.log("r.body.object",r.body.object);
              if (endpoint === "/api/task" && r.body.object.parent !== null) {
                let parent = array.find(
                  (x) =>
                    x.uid === r.body.object.parent.uid &&
                    x.objectType === "task"
                );
                // console.log("parent",parent);
                parent.isTask = false;
                parent.objectTypeInfo.name = "Summary Task";
              }
            }

            if (dataType === "array") {
              let createdTasks = r.body.object.reverse();

              console.log("dataType", "array");
              createdTasks.map((data) => {
                array = this.arrayInsert(data);
              });

              parent = array.find(
                (x) =>
                  x.uid === r.body.object[0].parent.uid &&
                  x.objectType === "task"
              );

              parent.isTask = false;
              parent.objectTypeInfo.name = "Summary Task";
            }
            this.setState({
              ...this.state,
              Status: "Update",
              ErrorMessage: "Updated Succesfully",
              newCreate: false,
              selectedRecord: dataType === undefined ? r.body.object : parent,
              data: array,
            });
          } else if (
            insertLocation !== "middle" &&
            objectType !== "timesheet"
          ) {
            let array = [];

            this.state.data !== null ? (array = this.state.data) : (array = []);
            array.unshift(r.body.object);
            this.setState({
              ...this.state,
              Status: "Update",
              ErrorMessage: "Updated Succesfully",
              newCreate: false,
              selectedRecord: r.body.object,
              data: array,
            });
          } else if (objectType === "timesheet") {
            console.log("timesheet", this.state.selectedRows);
            let newData = this.state.data;

            data.map((i) => {
              newData.find(
                (x) => x.uid === i.timesheetID
              ).waitingMyApproval = false;
            });
            this.setState({
              ...this.state,
              Status: "Update",
              ErrorMessage: "Updated Succesfully",
              data: newData,
              selecteds: [],
              selectedRows: [],
            });
          }

          this.notify("success", r.body.message);
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
          this.notify("error", r.body.message);
        } else {
          //console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
          this.notify("error", r.body.message);
        }
      });
  };

  updateRecord = async (endpoint, data, objectType, refresh) => {
    //  console.log("updateRecord",endpoint,data,objectType,refresh)
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(data),
    };

    await fetch(endpoint, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(JSON.stringify(data));
        //console.log(r);
        if (r.status === 200) {
          this.notify("success", "Updated");
          //  console.log(r);
          // this.removeItem();
          let newData = this.state.data;
          if (
            objectType === "task" ||
            objectType === "activity" ||
            objectType === "wbs"
          ) {
            let index = newData.findIndex(
              (x) => x.objectType === objectType && x.uid === data.uid
            );
            newData[index] = data;
            //console.log("newObject",newObject)

            if (!refresh) {
              this.setState({ ...this.state, data: newData });
            } else {
              this.setState(
                {
                  ...this.state,
                  Status: "Update",
                  ErrorMessage: "Updated Succesfully",
                },
                () => {
                  this.getData();
                }
              );
            }
          } else {
            let index = newData.findIndex((x) => x.uid === data.uid);
            newData[index] = data;
            //console.log("newObject",newObject)

            this.setState({ ...this.state, data: newData });
          }

          //!! toDo Burası değişmeli update kodu gelmeli
          /*
                    if(willRefresh===undefined || willRefresh===true)
                    {
                        this.setState({...this.state, Status: "Update" ,ErrorMessage:"Updated Succesfully"}, () => {    this.getData();})
                    }   
                */
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });

          this.notify("error", r.body.message);
        } else {
          //console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };

  save = (
    type,
    endpoint,
    dataForm,
    insertLocation,
    dataType,
    insertToSelectedIndex,
    objectType,
    refresh
  ) => {
    //console.log("save", type,endpoint,dataForm,insertLocation,dataType, insertToSelectedIndex)

    switch (type) {
      case "post":
        this.createRecord(
          endpoint,
          dataForm,
          insertLocation,
          dataType,
          undefined,
          insertToSelectedIndex
        );
        break;
      case "patch":
        this.updateRecord(endpoint, dataForm, objectType, refresh);
        break;
    }
  };

  updateTask = (type, data, parent) => {
    if (type === "assignment") {
      let newData = this.state.data;

      //console.log("newData",newData)
      let task = newData.find(
        (x) => x.objectType === "task" && x.uid === parent.uid
      );
      task.atCompletionUnit =
        data.remainingUnit +
        (task.atCompletionUnit ? task.atCompletionUnit : 0);

      //console.log("task", task)
    }
    //console.log("updateTask",type,data,parent)
  };

  collapseExpandAll = () => {
    console.log(
      "collapseExpandAll",
      this.state.data.filter((x) => x.parentInd !== undefined)
    );
    let newData = this.state.data;
    console.log("collapseExpandAll", newData);
    newData.map((data) => {
      data.isExpanded = !this.state.collapseAll;
    });
    newData.splice(1, newData.length - 1);
    this.setState({
      ...this.state,
      data: newData,
      collapseAll: !this.state.collapseAll,
    });

    /**/

    this.setState({ ...this.state, collapseAll: !this.state.collapseAll });
  };

  toggleCollapseStatus = () => {
    //console.log("toggleCollapseStatus")

    let newData = this.state.data;
    //let removeCount = 0;
    if (!this.state.collapseAll) {
      newData.map((data) => {
        data.isExpanded = false;
      });
      newData.splice(1, newData.length - 1);
    } else {
      newData = [...this.state.rawData];
      newData.map((data) => {
        data.isExpanded = true;
      });
    }

    this.setState({
      ...this.state,
      data: newData,
      collapseStatus: !this.state.collapseStatus,
      collapseAll: !this.state.collapseAll,
    });
  };

  collapseAll = () => {
    // rawData'nın tamamı kapatılır
    // sadece 1. seviye kayıtlar gösterilir.

    let newRawData = [];
    let newData = [];

    this.state.rawData.map((x) => {
      newRawData.push({ ...x, isExpanded: false });
      if (x.hierLevel === 1) {
        newData.push({ ...x, isExpanded: false });
      }
    });

    this.setState(
      {
        ...this.state,
        data: newData,
        rawData: newRawData,
        expandMode: "collapse",
      },
      () => {
        this.updateUserView("expandMode", this.state.expandMode, []);
      }
    );

    document.getElementById("hub-gantt-table-body").scrollTop = 0;
  };

  expandAll = () => {
    //console.log("expandAll",this.state)

    // rawData'nın tamamı açılır
    // tüm kayıtlar gösterilir.

    let newData = this.state.rawData.map((x) => {
      return { ...x, isExpanded: true };
    });

    this.setState(
      { ...this.state, data: newData, rawData: newData, expandMode: "expand" },
      () => {
        this.updateUserView("expandMode", this.state.expandMode, []);
      }
    );
  };

  //İlk açılışta expand collapse durumunu oluşturur.
  initializeExpandStatus = (projectView, view) => {
    //console.log("initializeExpandStatus",projectView,view,this.state)
    let newRawData = [];
    let newData = [];

    let selectedRecord = {};

    let selectedRecordRowNum = -1;
    let filters = this.state.config.view.filters;

    if (view?.filter) {
      filters = view.filter?.filters;
    }

    let rawData = this.prepareFilteredData(filters, this.state.rawData);

    let gotoTask = findGetParameter("task");
    let searchArea = "code";
    if (gotoTask === null) {
      gotoTask = findGetParameter("taskId");
      searchArea = "uid";
    }

    //console.log("gotoTask",gotoTask)

    //console.log("initializeExpandStatus collapse",view)
    if (
      projectView === undefined ||
      Object.keys(projectView).length === 0 ||
      gotoTask !== null
    ) {
      newData = [...this.state.data];
      newRawData = [...this.state.rawData];
    }

    if (projectView?.expandMode === "collapse" && gotoTask === null) {
      //console.log("initializeExpandStatus collapse")

      this.state.rawData.map((x) => {
        newRawData.push({ ...x, isExpanded: false });
        if (x.hierLevel === 1) {
          newData.push({ ...x, isExpanded: false });
        }
      });

      //console.log("initializeExpandStatus",projectView.expandArray)

      let appendTasks = [];
      let checkPoint = null; // checkPoint değişkeni açılırken aradaki kapalıları kapalı tutmak için.

      let xInd = 0;
      let xInd2 = 0;

      let i = 0;

      projectView?.expandArray
        ?.sort((a, b) => (a.wbsSort > b.wbsSort ? 1 : -1))
        .map((pp) => {
          checkPoint = null;
          appendTasks = [];

          xInd = newData.find(
            (x) => x.uid === pp.uid && x.objectType === pp.objectType
          )?.wbsSort;
          xInd2 = newData.findIndex(
            (x) => x.uid === pp.uid && x.objectType === pp.objectType
          );
          // console.log(xInd,pp,newData,newRawData.length)

          for (i = xInd; i < newRawData.length; i++) {
            if (xInd > 0) {
              //console.log(i,xInd, newRawData[xInd], newRawData[i])
            }

            if (
              i > xInd &&
              newRawData[xInd].hierLevel >= newRawData[i].hierLevel
            ) {
              //  console.log("breaaak")
              break;
            }
            if (i > xInd) {
              // Eğer açılan satırın altında kapalı bir satır varsa
              // kapalı satırın indexi checkpointe yazılır.
              // eğer checkPoint null değilse döngü o sırada kapalı bir satırın alt verisinin içindedir ve continue ile loop atlatılır.
              // eğer aynı veya daha büyük bir hierLevel'a denk gelirse, kapalı bloktan çıkılmıştır. checkPoint değişkeni null olarak set edilir.
              if (checkPoint !== null) {
                if (
                  newRawData[checkPoint].hierLevel < newRawData[i].hierLevel
                ) {
                  continue;
                } else {
                  checkPoint = null;
                }
              }

              if (xInd > 0) {
                // console.log("basılacak task",newRawData[i])
              }

              appendTasks.push(newRawData[i]);

              if (newRawData[i].isExpanded === false) {
                checkPoint = i;
              }
            }
          }

          newData.splice(xInd2 + 1, 0, ...appendTasks);

          //console.log("en aşağı",xInd,newData, newData[xInd])
          if (newData[xInd2]?.isExpanded !== undefined) {
            newData[xInd2].isExpanded = !newData[xInd2]?.isExpanded;
          }
        });
    }

    if (projectView?.expandMode === "expand" && gotoTask === null) {
      //console.log("initializeExpandStatus expand")
      newRawData = [...this.state.rawData];
      newData = rawData.map((x) => {
        return { ...x, isExpanded: true };
      });

      let i = 0;
      let ind = 0;

      let removeCount = 0;
      projectView?.expandArray
        ?.sort((a, b) => (a.wbsSort < b.wbsSort ? 1 : -1))
        .map((pp) => {
          // console.log("pp",pp,newData.find(x=>x.uid===pp.uid && x.objectType===pp.objectType) )
          removeCount = 0;
          ind = newData.find(
            (x) => x.uid === pp.uid && x.objectType === pp.objectType
          )?.wbsSort;
          for (i = ind; i < newData.length; i++) {
            if (i > ind && newData[ind].hierLevel >= newData[i].hierLevel) {
              break;
            }
            removeCount = removeCount + 1;
          }

          newData.splice(ind + 1, removeCount - 1);
          if (newData[ind]?.isExpanded !== undefined) {
            newData[ind].isExpanded = !newData[ind].isExpanded;
          }
        });
    }

    if (projectView?.selectedRecord) {
      selectedRecord = newData.find(
        (x) =>
          x.uid === projectView?.selectedRecord?.uid &&
          x.objectType === projectView?.selectedRecord?.objectType
      );
      selectedRecordRowNum = newData.findIndex(
        (x) =>
          x.uid === projectView?.selectedRecord?.uid &&
          x.objectType === projectView?.selectedRecord?.objectType
      );

      //console.log("selectedRecord",selectedRecord,projectView)
    }

    /* eğer Gantt linki tıklandıysa o kaydın açılma özelliği */

    //console.log("gotoTask",gotoTask)
    let gotoTaskIndex = -1;

    if (gotoTask !== null) {
      gotoTaskIndex = newData.findIndex(
        (x) =>
          x.objectType === "task" &&
          x[searchArea] ===
            (searchArea === "uid" ? parseInt(gotoTask) : gotoTask)
      );
      selectedRecordRowNum = gotoTaskIndex;
      selectedRecord = newData.find(
        (x) =>
          x.objectType === "task" &&
          x[searchArea] ===
            (searchArea === "uid" ? parseInt(gotoTask) : gotoTask)
      );

      //console.log("gotoTaskIndex",gotoTaskIndex,gotoTask,newData.find(x=>x.objectType==="task"&& x[searchArea]===gotoTask))
    }

    if (gotoTaskIndex > 3) {
      document.getElementById("hub-gantt-table-body").scrollTop =
        (gotoTaskIndex - 2) * 27;
    } else if (projectView?.scroll > 0 && gotoTaskIndex === -1) {
      document.getElementById("hub-gantt-table-body").scrollTop =
        projectView?.scroll;
    }

    /* eğer Gantt linki tıklandıysa o kaydın açılma özelliği */

    this.setState(
      {
        ...this.state,
        data: newData,
        rawData: newRawData,
        expandMode: projectView?.expandMode
          ? projectView?.expandMode
          : this.state.expandMode,
        expandArray: projectView?.expandArray
          ? projectView?.expandArray
          : this.state.expandArray,
        selectedRecord: selectedRecord ? selectedRecord : {},
        selectedRecordRowNum: selectedRecordRowNum,
        isSideBarOpen: projectView?.isSideBarOpen
          ? projectView?.isSideBarOpen
          : this.state.isSideBarOpen,
        hideGanttbar:
          projectView?.hideGanttbar !== undefined
            ? projectView?.hideGanttbar
            : this.state.hideGanttbar,
        hideColor: projectView?.hideColor
          ? projectView?.hideColor
          : this.state.hideColor,
        sidebarWidth: projectView?.sidebarWidth
          ? projectView?.sidebarWidth
          : this.state.sidebarWidth,
      },
      /**/ () => {
        if (view?.visibleColumnList) {
          //console.log("ife girdik")
          this.applyVisibleColumns(view.visibleColumnList, view);
        }
      }
    );
  };

  configureExpandArray = (ind) => {
    // * ExpandArray açılacak veya kapanacak kayıtları tutar.
    // Collapse mod ise sadece açıkları tutar
    // Expand mode ise sadece kapalıları tutar.
    // ilk 2 if collapse mode için, son iki if expande mode için

    let expandArray = this.state.expandArray;

    //console.log("configureExpandArray", expandArray)

    if (
      this.state.expandMode === "collapse" &&
      !this.state.data[ind].isExpanded
    ) {
      expandArray.push({
        uid: this.state.data[ind].uid,
        objectType: this.state.data[ind].objectType,
        wbsSort: this.state.data[ind].wbsSort,
      });
    } else if (
      this.state.expandMode === "collapse" &&
      this.state.data[ind].isExpanded &&
      expandArray.findIndex(
        (x) =>
          x.uid === this.state.data[ind].uid &&
          x.objectType === this.state.data[ind].objectType
      ) !== -1
    ) {
      expandArray.splice(
        expandArray.findIndex(
          (x) =>
            x.uid === this.state.data[ind].uid &&
            x.objectType === this.state.data[ind].objectType
        ),
        1
      );
    }

    // * Expand mode için tutulan kayıtlar
    if (this.state.expandMode === "expand" && this.state.data[ind].isExpanded) {
      expandArray.push({
        uid: this.state.data[ind].uid,
        objectType: this.state.data[ind].objectType,
        wbsSort: this.state.data[ind].wbsSort,
      });
    } else if (
      this.state.expandMode === "expand" &&
      !this.state.data[ind].isExpanded &&
      expandArray.findIndex(
        (x) =>
          x.uid === this.state.data[ind].uid &&
          x.objectType === this.state.data[ind].objectType
      ) !== -1
    ) {
      expandArray.splice(
        expandArray.findIndex(
          (x) =>
            x.uid === this.state.data[ind].uid &&
            x.objectType === this.state.data[ind].objectType
        ),
        1
      );
    }

    this.updateUserView("expandMode", this.state.expandMode, expandArray);

    //console.log("configureExpandArray",expandArray)

    return expandArray;
  };

  toggleIsExpanded = (e, ind) => {
    // Bunu muhtemelen smart render'in için bu şekilde
    // diziye eleman ekleyip çıkarma olarak
    // geliştirildi.
    //console.log("toggleIsExpanded", ind,this.state.data[ind].isExpanded)

    // * Bu kısım sayfa açılınca collapse-expand durumunu tutmak için kullanılır.
    // * en altta setState fonksiyonunda var

    let expandArray = this.configureExpandArray(ind);

    e.preventDefault();
    e.stopPropagation();

    let newData = [];

    const xInd = this.state.data[ind].wbsSort;

    let removeCount = 0;
    if (this.state.data[ind].isExpanded) {
      for (let i = ind; i < this.state.data.length; i++) {
        if (
          i > ind &&
          this.state.data[ind].hierLevel >= this.state.data[i].hierLevel
        ) {
          break;
        }
        removeCount = removeCount + 1;
      }

      newData = [...this.state.data];
      newData.splice(ind + 1, removeCount - 1);
    } else {
      // Açılınca eklenecek satırlar döngüyle buraya girer.
      let appendTasks = [];
      let checkPoint = null; // checkPoint değişkeni açılırken aradaki kapalıları kapalı tutmak için.

      for (let i = xInd; i < this.state.rawData.length; i++) {
        //console.log(i,xInd,this.state.rawData[xInd].hierLevel, this.state.rawData[i].hierLevel)
        if (
          i > xInd &&
          this.state.rawData[xInd].hierLevel >= this.state.rawData[i].hierLevel
        ) {
          break;
        }
        if (i > xInd) {
          // Eğer açılan satırın altında kapalı bir satır varsa
          // kapalı satırın indexi checkpointe yazılır.
          // eğer checkPoint null değilse döngü o sırada kapalı bir satırın alt verisinin içindedir ve continue ile loop atlatılır.
          // eğer aynı veya daha büyük bir hierLevel'a denk gelirse, kapalı bloktan çıkılmıştır. checkPoint değişkeni null olarak set edilir.
          if (checkPoint !== null) {
            if (
              this.state.rawData[checkPoint].hierLevel <
              this.state.rawData[i].hierLevel
            ) {
              continue;
            } else {
              checkPoint = null;
            }
          }

          appendTasks.push(this.state.rawData[i]);

          if (this.state.rawData[i].isExpanded === false) {
            checkPoint = i;
          }
        }
        removeCount = removeCount + 1;
      }

      newData = [...this.state.data];

      const x = [...this.state.rawData];
      newData.splice(ind + 1, 0, ...appendTasks);
    }

    newData[ind].isExpanded = !newData[ind].isExpanded;

    // satırın Expand Collapse durumunun üst seviye kapanıp açıldığında da saklanması için raw data'ya isExpanded bilgisi taşınır.
    let newRawData = [...this.state.rawData];
    newRawData[xInd].isExpanded = newData[ind].isExpanded;
    let newSelectedRows = [...this.state.selectedRows];

    //console.log("isExpanded", ind,this.state.data[ind].isExpanded)
    let deleteds = [];
    if (!this.state.data[ind].isExpanded) {
      for (let i = newSelectedRows.length - 1; i >= 0; i--) {
        //console.log("if girdik")
        //console.log(i,newSelectedRows[i],newSelectedRows)
        if (
          newSelectedRows[i] > ind &&
          newSelectedRows[i] < ind + removeCount - 1
        ) {
          //console.log("if girdik")
          newSelectedRows.splice(i, 1);
          deleteds.push(newSelectedRows[i]);
          //i++
        } else {
          newSelectedRows[i] = newSelectedRows[i] - (removeCount - 1);
        }
      }
    } else {
      //console.log("else girdik",removeCount,ind,newSelectedRows,this.state.selectedRowsBefore)
      for (let i = newSelectedRows.length - 1; i >= 0; i--) {
        //console.log("if girdik")
        //console.log(i,newSelectedRows[i],newSelectedRows)
        if (
          newSelectedRows[i] > ind /*&& newSelectedRows[i]<(ind+removeCount-1)*/
        ) {
          //console.log("if girdik")
          //newSelectedRows.splice(i, 1 )
          newSelectedRows[i] = newSelectedRows[i] + (removeCount - 1);
          //i++
        } else {
        }
      }

      newSelectedRows.splice(
        newSelectedRows.length,
        0,
        ...this.state.selectedRowsBefore
      );
    }

    this.setState({
      ...this.state,
      data: newData,
      rawData: newRawData,
      selectedRows: newSelectedRows,
      selectedRowsBefore: deleteds,
      expandArray: expandArray,
    });
  };

  dateDiff = (startingDate, endingDate) => {
    var startDate = startingDate;
    var endDate = endingDate;

    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return (
      yearDiff * 12.0 +
      +monthDiff * 1.0 +
      dayDiff / daysInMonth[endDate.getMonth()]
    );
  };

  getWidth = (
    startDate,
    finishDate,
    roadmapLeft = null,
    roadmapRight = null
  ) => {
    if (startDate === null || finishDate === null) {
      return 0;
    }
    if (roadmapLeft !== null && roadmapLeft > startDate) {
      startDate = roadmapLeft;
    }
    return finishDate > startDate
      ? Math.round(this.dateDiff(startDate, finishDate) * this.state.wPerMonth)
      : 0;
  };

  ganttContainerScrollHandler = (e, customScroll) => {
    // console.log("ganttContainerScrollHandler",e.target.scrollTop,this.state.selectedRows,this.state.data)

    if (window._preventEvent) {
      window._preventEvent = false;
      return;
    }

    if (this.justOnce) {
      e.stopPropagation();
      clearTimeout(this.isScrolling);
      this.isScrolling = setTimeout(() => {
        let scrollTop = customScroll ? customScroll : e.target.scrollTop;

        let newRenderBy = Math.floor(e.target.scrollTop / 27);

        if (this.state.ganttRenderBy !== newRenderBy) {
          this.setState(
            {
              ...this.state,
              scroll: scrollTop,
              ganttRenderByLast: this.state.ganttRenderBy,
              ganttRenderBy:
                newRenderBy > this.state.ganttRenderOverwrap
                  ? newRenderBy - this.state.ganttRenderOverwrap
                  : 0,
            },
            () => {
              window._preventEvent = true;
              document.getElementById("hub-gantt-table-body").scrollTop =
                scrollTop;

              if (!this.state.hideGanttbar) {
                document.getElementById("hub-gantt-body").scrollTop = scrollTop;
              }
              //console.log("ganttContainerScrollHandler",e.target.scrollTop)

              this.updateUserView("scroll", e.target.scrollTop);
            }
          );
        }
      }, 150);

      document.getElementById("hub-gantt-table-header").scrollLeft =
        e.target.scrollLeft;

      if (!this.state.hideGanttbar) {
        window._preventEvent = true;
        document.getElementById("hub-gantt-body").scrollTop =
          e.target.scrollTop;
      }
    }
  };

  /* Context Menu methods  */

  handleRightClick = (e, d, i) => {
    e.preventDefault();
    this.selectRecord(d, i, () => {
      this.setState(
        {
          ...this.state,
          anchorPoint: { x: e.pageX, y: e.pageY },
          showContextMenu: true,
        },
        () => {
          window.addEventListener("mousedown", this.handleClickOutside);
        }
      );
    });
  };

  closeContextMenu = () => {
    this.setState({ ...this.state, showContextMenu: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    if (
      this.contextMenuRef &&
      !this.contextMenuRef.current?.contains(event.target)
    ) {
      this.closeContextMenu();
    }
  };

  timesheetApprove = (endpoint, action) => {
    let timesheetObjectArray = [];

    this.state.selecteds.map((id) => {
      timesheetObjectArray.push({ actionCode: "approve", timesheetID: id });
    });

    console.log(
      "timesheetApprove",
      endpoint,
      action,
      this.state.selecteds,
      timesheetObjectArray
    );

    this.createRecord(endpoint, timesheetObjectArray, null, null, "timesheet");
  };

  copyTask = () => {
    //console.log(this.state.selecteds,this.state.rawData, this.state.data )

    let copyObject = [];
    let task = {};
    this.state.selecteds.map((i) => {
      task = this.state.rawData.find(
        (x) => x.uid === i && x.objectType === "task"
      );
      //console.log(i, task )
      copyObject.push({ code: task.code, name: task.name, uid: task.uid });
    });

    /*
        this.state.selectedRows.map((i)=>
        {
            copyObject.push({code:this.state.data[i].code, name:this.state.data[i].name, uid:this.state.data[i].uid})
            
        })

        */

    this.utils.copyTask(this.state.selecteds);
    this.utils.copyTaskObject(copyObject);

    this.deselectAll();
  };

  copyControl = (type) => {
    // console.log("copyControl",type)

    // this.showModal();

    this.setState({ ...this.state, showModal: true, pasteType: type });
  };

  //1 child, 2 sibling
  pasteTask = (type) => {
    //console.log("pasteTask",type,  this.utils.getCopyTaskList())

    let object = {};
    let target = {};
    let index = null;

    if (type === 1) {
      target.id = this.state.selectedRecord.uid;
      target.objectType =
        this.state.selectedRecord.objectTypeInfo?.code.toString();
      index = this.state.selectedRecord.wbsSort;
    }

    //sibling
    if (type === 2) {
      // console.log(this.state.data[this.state.selectedRecord.parentInd])
      target.id = this.state.data[this.state.selectedRecord.parentInd].uid;
      target.objectType =
        this.state.data[
          this.state.selectedRecord.parentInd
        ].objectTypeInfo?.code.toString();
      index = this.state.data[this.state.selectedRecord.parentInd].wbsSort;
    }

    object.target = target;
    object.tasks = this.utils.getCopyTaskList();

    //console.log("pasteTask",type,  object)

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(object),
    };

    fetch("/api/task/copy", requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(JSON.stringify(data));
        console.log(r);
        if (
          r.body.isSuccess &&
          (r.status === 200 || r.body.object.length > 0)
        ) {
          let array = [];

          r.body.object.map((data) => {
            array = this.arrayInsert(data, index);
          });

          if (target.objectType === "task") {
            let parent = array.find(
              (x) => x.uid === target.id && x.objectType === "task"
            );
            // console.log("parent",parent);
            parent.isTask = false;
            parent.objectTypeInfo.name = "Summary Task";
          }

          //console.log("array",array)
          this.notify("success", "Tasks Copied");
          //this.utils.removeCopyTaskList();
          this.setState({ ...this.state, data: array });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });

          this.notify("error", r.body.message);
        } else {
          //console.log(r.body.message);
          this.notify("error", r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  copyClear = () => {
    this.utils.removeCopyTaskList();
    this.utils.removeCopyTaskListObject();
    this.setState({ ...this.state });
  };

  exportToCSV = () => {
    // Prepare Data Array
    let rows = [];

    // Push Column Headers
    let row = this.state.config.view.visibleColumns.map((c) => {
      return c.label;
    });
    rows.push(row);

    // Push Values if data exists
    if (this.state.data) {
      this.state.data.map((d) => {
        let row = this.state.config.view.visibleColumns.map((c, ix) => {
          let label = "";
          let value = resolvePath(d, c.dataKey);
          if (value !== null && value !== undefined) {
            switch (c.type) {
              case "text":
                label = value.replace(/#/g, "-");
                break;
              case "date":
                label = this.dutils.Date2Str(value);
                break;
              case "number":
                label = value;
                break;
              case "staticLookup":
                label = value?.name ? value.name : "";
                break;
              case "dynamicLookup":
                label = value?.name ? value.name : "";
                break;
              case "lookup":
                label = value?.name;
                break;
              case "percentage":
                label = value ? value.toFixed(0) + "%" : "0%";
                break;
              case "boolean":
                label = value ? "Yes" : "No";
                break;
              case "hour":
                label = value ? value.toFixed(0) + "h" : "0h";
                break;
              case "day":
                label = value ? value.toFixed(0) + "d" : "0d";
                break;
            }
          }
          return '"' + label + '"';
        });
        rows.push(row);
      });
    }

    //c.type

    // Prepare Content
    // Türkçe karakter için dosya başına ekleniyor. universalBOM
    var universalBOM = "\uFEFF";
    let csvContent =
      "data:text/csv;charset=utf-8," +
      universalBOM +
      rows.map((e) => e.join(";")).join("\n");

    // rows.map(e => console.log(e))

    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);

    // File Name
    var date = new Date();
    let dateSuffix =
      date.getFullYear().toString() +
      pad2(date.getMonth() + 1) +
      pad2(date.getDate()) +
      pad2(date.getHours()) +
      pad2(date.getMinutes()) +
      pad2(date.getSeconds());

    let fileName =
      (this.state.config.fileName ? this.state.config.fileName : "export") +
      "_" +
      dateSuffix;
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    document.body.removeChild(link);
  };

  render() {
    let pagedData = this.state.data
      ? this.state.data.slice(
          this.state.page * this.state.perPage,
          (this.state.page + 1) * this.state.perPage
        )
      : "";

    // console.log("selectedRecord",this.state.selectedRecord, pagedData,pagedData?.find(x=>x.uid===this.state.selectedRecord.activityId && x.objectType==="activity"))
    // console.log("pagedData",pagedData)
    // Gantt Smart Render'a dahil olacak veri.
    let renderSlicedData = [];
    let renderSlicedDataRender = [];
    let projectSource = this.props.config?.project?.source?.code;

    if (
      this.state.config.view.mode === "gantt" &&
      this.state.Loading === false
    ) {
      let startRow = this.state.ganttRenderBy;

      renderSlicedDataRender = [];
      renderSlicedData = this.state.data.slice(
        startRow,
        startRow + this.state.ganttRenderCount
      );

      //console.log("startRow",startRow,this.state.ganttRenderCount )
    }

    //console.log(this.state.showModal)

    return (
      <div className="hub-dataTable-wrapper">
        {/*1 > this.state.data.length && !this.state.Loading && (
          <>
            <LoadingScreen icon={faEmptySet} message="No Records" noSpin />{" "}
           
          </>
        )*/}

        {!this.state.config.view.hideToolbar ? (
          <Toolbar
            clearFilters={this.clearFilters}
            applyPrebuiltFilter={this.applyPrebuiltFilter}
            toolbarActionButtons={this.state.config.toolbarActionButtons}
            showModal={this.showModal}
            exportToCSV={this.exportToCSV}
            toggleCollapseStatus={this.toggleCollapseStatus}
            collapseAll={this.state.collapseAll}
            collapseStatus={this.state.collapseStatus}
            showCollapseButton={this.state.config.view.showCollapse}
            hideColor={this.state.hideColor}
            toggleColor={this.toggleColor}
            thinking={this.state.Thinking}
            hideGanttbar={this.state.hideGanttbar}
            toggleGanttbar={this.toggleGanttbar}
            setViewMode={this.setViewMode}
            refresh={this.getData}
            isSideBarOpen={this.state.isSideBarOpen}
            toggleSidebar={this.toggleSidebar}
            applyVisibleColumns={this.applyVisibleColumns}
            isFilterOpen={this.state.isFilterOpen}
            config={this.state.config}
            toggleFilter={this.toggleFilter}
          />
        ) : (
          false
        )}
        {
          <FilterBar
            clearFilters={this.clearFilters}
            applyPrebuiltFilter={this.applyPrebuiltFilter}
            preBuiltFilters={this.state.config.prebuiltFilters}
            isFilterOpened={this.state.isFilterOpen}
            setFilter={this.setFilter}
            addFilter={this.addFilter}
            removeFilter={this.removeFilter}
            filters={this.state.config.view.filters}
            filterList={this.state.config.view.filterList}
            columns={this.state.config.columns}
          />
        }
        {this.state.Loading && (
          <LoadingScreen isSidebarLoader={this.props.isSidebarTable} />
        )}

        <div
          className="hub-dataTable-tableArea"
          style={{
            paddingRight: this.state.isSideBarOpen ? "0" : false,
            padding: this.state.config.view?.mode === "util" ? "0" : false,
            /*opacity: this.state.data && pagedData.length > 0 ? "1" : "0",*/
          }}
        >
          {this.state.config.view.mode === "list" ? ( // Liste Görünümü
            <>
              {this.state.config.button ? (
                <div className="hub-dataTable-tableArea-buttonArea">
                  {this.state.config.button.map((c, i) => {
                    return (
                      <MainButton
                        onClick={() => this.buttonClick(c.type, c.endpoint)}
                        key={"newButton-" + i.toString()}
                        className="button-2"
                        icon={false}
                        label={c.label}
                        disabled={
                          c.type === "delete" &&
                          this.state.selecteds.length === 0
                            ? true
                            : false
                        }
                      />
                    );
                  })}
                </div>
              ) : (
                ""
              )}
              {!this.state.config.view.hideActionbar ? (
                <ActionBar
                  projectSource={this.props.config?.project?.source?.code}
                  actionButtons={this.state.config.actionButtons}
                  hideSelectbox={this.state.config.view.hideSelectbox}
                  clearFilters={this.clearFilters}
                  applyPrebuiltFilter={this.applyPrebuiltFilter}
                  preBuiltFilters={this.state.config.prebuiltFilters}
                  selectAll={this.selectAll}
                  deselectAll={this.deselectAll}
                  selectedRows={this.state.selectedRows}
                  deleteAvailable={this.state.config.view.deleteAvailable}
                  deleteRecord={this.deleteRecord}
                  timesheetApproveAvailable={
                    this.state.config.view.timesheetApproveAvailable
                  }
                  timesheetApprove={this.timesheetApprove}
                />
              ) : (
                false
              )}

              <div className="hub-dataTable-tableArea-container">
                <div className="hub-dataTable">
                  {this.state.data && pagedData.length > 0 ? (
                    <>
                      <div className="hub-dataTable-headerBg"></div>

                      <table cellPadding="0" border="0" cellSpacing="0">
                        <thead>
                          <tr>
                            {this.state.config.view.visibleColumns.map(
                              (c, i) => {
                                return (
                                  <TH
                                    key={"th-" + c.id}
                                    isHideSelectbox={
                                      this.props.config.view.hideSelectbox
                                    }
                                    handleResizeColumn={this.handleResizeColumn}
                                    column={c}
                                    ind={i}
                                    handleSort={this.handleSort}
                                    sortColumn={this.state.sortColumn}
                                  />
                                );
                              }
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          {pagedData.map((d, i) => {
                            return this.makeTableRow(d, i);
                          })}
                        </tbody>
                      </table>
                    </>
                  ) : !this.state.Loading ? (
                    <div style={{ height: "100px", position: "relative" }}>
                      <LoadingScreen
                        icon={faEmptySet}
                        message="No Record"
                        noSpin
                      />
                    </div>
                  ) : (
                    false
                  )}
                </div>
                {this.state.config.sideBar ? (
                  <SideBar
                    parentRecord={this.state.config.parentRecord}
                    selectedRecord={this.state.selectedRecord}
                    idAttribute={this.state.config.idAttribute}
                    nameAttribute={this.state.config.nameAttribute}
                    toggleSidebar={this.toggleSidebar}
                    setSidebarWidth={this.setSidebarWidth}
                    isOpen={this.state.isSideBarOpen}
                  >
                    {React.cloneElement(this.state.config.sideBar, {
                      selectedRecord: this.state.selectedRecord,
                      newCreate: this.state.newCreate,
                      parentRecord: this.state.config.parentRecord,
                      sidebarType: this.state.sidebarType,
                      createType: this.state.createType,
                      save: this.save,
                      updateSingleRow: this.updateSingleRow,
                    })}
                  </SideBar>
                ) : (
                  false
                )}
                {this.state.showModal ? (
                  <Modal
                    key="OWAssignmentTable-modal"
                    title={this.state.selectedRecord?.name}
                    show={this.state.showModal}
                    onClose={this.hideModal}
                    height={
                      this.state.config.modalOptions?.height
                        ? this.state.config.modalOptions.height
                        : "80%"
                    }
                    width={
                      this.state.config.modalOptions?.width
                        ? this.state.config.modalOptions.width
                        : "90%"
                    }
                  >
                    {React.cloneElement(this.state.config.modal, {
                      selectedRecord: this.state.selectedRecord,
                      data: this.state.data,
                      selecteds: this.state.selecteds,
                      copyList: this.utils?.getCopyTaskList(),
                      pasteTask: this.pasteTask,
                      pasteType: this.state.pasteType,
                      onClose: this.hideModal,
                    })}
                  </Modal>
                ) : (
                  false
                )}
              </div>
              <Pagination
                onExpand={this.props.onExpand}
                isSmallTable={this.props.config.view.isSmallTable}
                setPage={this.setPage}
                totalRows={this.state.data ? this.state.data.length : 0}
                perPage={this.state.perPage}
                page={this.state.page}
              />
            </>
          ) : (
            false
          )}
          {this.state.config.view.mode == "tile" ? ( // Tile Görünümü
            <>
              <div className="hub-dataTable-tileContainer">
                {this.state.data.map((i) => {
                  return React.cloneElement(this.state.config.tile, {
                    key: i.uid,
                    project: i,
                  });
                })}
              </div>
              {this.state.showModal ? (
                <Modal
                  key="OWAssignmentTable-modal"
                  title={/*this.state.selectedRecord?.name*/ "Create Project"}
                  show={this.state.showModal}
                  onClose={this.hideModal}
                  height={
                    this.state.config.modalOptions?.height
                      ? this.state.config.modalOptions.height
                      : "80%"
                  }
                  width={
                    this.state.config.modalOptions?.width
                      ? this.state.config.modalOptions.width
                      : "90%"
                  }
                >
                  {React.cloneElement(this.state.config.modal, {
                    selectedRecord: this.state.selectedRecord,
                    data: this.state.data,
                    selecteds: this.state.selecteds,
                    copyList: this.utils?.getCopyTaskList(),
                    pasteTask: this.pasteTask,
                    pasteType: this.state.pasteType,
                    onClose: this.hideModal,
                  })}
                </Modal>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}
          {this.state.config.view.mode == "board" // Tile Görünümü
            ? React.cloneElement(this.state.config.board, {
                /* key: i.uid, project:i*/
              })
            : false}
          {this.state.config.view.mode == "gantt" &&
          this.state.Loading === false ? ( // Tile Görünümü
            <>
              {!this.state.config.view.hideActionbar ? (
                <ActionBar
                  openNewSideBar={this.openNewSideBar}
                  projectSource={this.props.config?.project?.source?.code}
                  isExpandable
                  handleCollapseAll={this.collapseAll}
                  handleExpandAll={this.expandAll}
                  collapseAll={this.collapseAll}
                  actionButtons={this.state.config.actionButtons}
                  hideSelectbox={this.state.config.view.hideSelectbox}
                  clearFilters={this.clearFilters}
                  applyPrebuiltFilter={this.applyPrebuiltFilter}
                  preBuiltFilters={this.state.config.prebuiltFilters}
                  selectAll={this.selectAll}
                  deselectAll={this.deselectAll}
                  selectedRows={this.state.selectedRows}
                  deleteAvailable={this.state.config.view.deleteAvailable}
                  deleteRecord={this.deleteRecord}
                  copyAvailable={this.state.config.view.copyAvailable}
                  copyTask={this.copyTask}
                  pasteTask={this.pasteTask}
                  copyControl={this.copyControl}
                  copyTaskListCount={this.utils?.getCopyTaskList()?.length}
                  selectedRecordType={
                    this.state.selectedRecord.objectTypeInfo?.code
                  }
                  copyClear={this.copyClear}
                />
              ) : (
                false
              )}
              <div className="hub-dataTable-ganttContainer">
                <div className="hub-gantt-table-wrapper">
                  <div className="hub-gantt-table">
                    <div
                      id="hub-gantt-table-header"
                      className="hub-gantt-table-header"
                    >
                      <table
                        cellPadding="0"
                        border="0"
                        cellSpacing="0"
                        style={{ position: "relative", top: "0" }}
                      >
                        <thead>
                          <tr>
                            {this.state.config.view.visibleColumns.map(
                              (c, i) => {
                                //console.log("oktay col",c)
                                return (
                                  <GanttTH
                                    key={"th-" + c.id}
                                    handleResizeColumn={this.handleResizeColumn}
                                    column={c}
                                    ind={i}
                                    handleSort={this.handleSort}
                                    sortColumn={this.state.sortColumn}
                                  />
                                );
                              }
                            )}
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div
                      className="hub-gantt-table-body"
                      id="hub-gantt-table-body"
                      onScroll={this.ganttContainerScrollHandler}
                    >
                      <div style={{ height: this.state.data.length * 27 }}>
                        <div
                          style={{ height: this.state.ganttRenderBy * 27 }}
                        ></div>
                        {pagedData.length > 0 ? (
                          <table cellPadding="0" border="0" cellSpacing="0">
                            <tbody>
                              {renderSlicedData.map((d, i) => {
                                return this.makeGanttTableRow(d, i);
                              })}
                            </tbody>
                          </table>
                        ) : (
                          <LoadingScreen
                            icon={faEmptySet}
                            message="No Record"
                            noSpin
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {this.state.hideGanttbar ? (
                    false
                  ) : (
                    <Gantt
                      wPerMonth={this.state.wPerMonth}
                      selectRecord={this.selectRecord}
                      selectedRecordRowNum={this.state.selectedRecordRowNum}
                      ganttStart={this.state.ganttStart}
                      ganttMonths={this.state.ganttMonths}
                      ganttFinish={this.state.ganttFinish}
                      ganttRenderCount={this.state.ganttRenderCount}
                      ganttRenderBy={this.state.ganttRenderBy}
                      ganttRenderByLast={this.state.ganttRenderByLast}
                      data={renderSlicedData}
                      dataLen={this.state.data.length}
                    />
                  )}
                </div>
                {this.state.config.sideBar && this.state.isSideBarOpen ? (
                  <SideBar
                    parentRecord={this.state.config.parentRecord}
                    selectedRecord={this.state.selectedRecord}
                    idAttribute={this.state.config.idAttribute}
                    nameAttribute={this.state.config.nameAttribute}
                    title={
                      this.state.sidebarType === "wbsCreate"
                        ? "Create WBS"
                        : this.state.sidebarType === "activityCreate"
                        ? "Create Activity"
                        : this.state.sidebarType === "taskCreateScratch"
                        ? "Create Task"
                        : ""
                    }
                    toggleSidebar={this.toggleSidebar}
                    isOpen={this.state.isSideBarOpen}
                    setSidebarWidth={this.setSidebarWidth}
                    sidebarWidth={this.state.sidebarWidth}
                    sidebarType={this.state?.sidebarType}
                  >
                    {React.cloneElement(this.state.config.sideBar, {
                      selectedRecord: this.state.selectedRecord,
                      newCreate: this.state.newCreate,
                      parentRecord: this.state.config.parentRecord,
                      sidebarType: this.state.sidebarType,
                      createType: this.state.createType,
                      save: this.save,
                      updateSingleRow: this.updateSingleRow,
                      updateTask: this.updateTask,
                    })}
                  </SideBar>
                ) : (
                  false
                )}
                {this.state.showModal ? (
                  <Modal
                    key="OWAssignmentTable-modal"
                    title={this.state.selectedRecord?.name}
                    show={this.state.showModal}
                    onClose={this.hideModal}
                    height={
                      this.state.config.modalOptions.height
                        ? this.state.config.modalOptions.height
                        : "80%"
                    }
                    width={
                      this.state.config.modalOptions.width
                        ? this.state.config.modalOptions.width
                        : "90%"
                    }
                  >
                    {React.cloneElement(this.state.config.modal, {
                      selectedRecord: this.state.selectedRecord,
                      data: this.state.data,
                      selecteds: this.state.selecteds,
                      copyList: this.utils?.getCopyTaskList(),
                      pasteTask: this.pasteTask,
                      pasteType: this.state.pasteType,
                      onClose: this.hideModal,
                    })}
                  </Modal>
                ) : (
                  false
                )}
              </div>
            </>
          ) : (
            false
          )}
        </div>
        {this.state.showContextMenu && !this.props.roadmap ? (
          <div
            ref={this.contextMenuRef}
            style={{
              top: this.state.anchorPoint?.y - 110,
              left: this.state.anchorPoint?.x - 50,
              position: "absolute",
            }}
          >
            <ContextMenu>
              {this.state.selectedRecord.objectTypeInfo?.code === "activity" &&
              this.state.selectedRecord?.extType !== "Start Milestone" &&
              this.state.selectedRecord?.extType !== "Finish Milestone" &&
              this.state.selectedRecord?.p6Plan?.status?.code !== "completed" &&
              this.state.selectedRecord?.status?.code !== "completed" ? (
                Auth.getResourceObject()?.securityProfile?.name === "MUH" ? (
                  <ContextItem
                    label={
                      "Engineers can only create sub-tasks on existing tasks."
                    }
                    icon={faCircleInfo}
                    iconColor={"#ACBDE3"}
                    className={"info"}
                  />
                ) : (
                  <>
                    <ContextItem
                      label={"Insert Task"}
                      onClick={() => this.openNewSideBar("taskCreate")}
                      icon={faPlus}
                      iconColor={"#2F5AB9"}
                    />
                    {this.utils?.getCopyTaskList()?.length > 0 ? (
                      <ContextItem
                        label={"Paste as Child"}
                        onClick={() => this.copyControl(1)}
                        icon={faCopy}
                        iconColor={"#2F5AB9"}
                      />
                    ) : (
                      false
                    )}
                  </>
                )
              ) : (
                false
              )}

              {this.state.selectedRecord.objectTypeInfo?.code === "task" ? (
                <>
                  {pagedData?.find(
                    (x) =>
                      x.uid === this.state.selectedRecord.activityId &&
                      x.objectType === "activity"
                  )?.status?.code === "completed" ? (
                    <ContextItem
                      label={
                        "You can not create Tasks under Completed Activity."
                      }
                      icon={faCircleInfo}
                      iconColor={"#ACBDE3"}
                      className={"info"}
                    />
                  ) : !this.state.selectedRecord?.isBlocked ? (
                    <>
                      {this.state.selectedRecord.objectTypeInfo.name ===
                        "Summary Task" ||
                      this.state.selectedRecord.atCompletionUnit === 0 ||
                      this.state.selectedRecord.atCompletionUnit ===
                        undefined ? (
                        <>
                          <ContextItem
                            label={"Insert Subtask"}
                            onClick={() => this.openNewSideBar("taskCreate")}
                            icon={faPlus}
                            iconColor={"#2F5AB9"}
                          />
                          {this.utils?.getCopyTaskList()?.length > 0 ? (
                            <>
                              <ContextItem
                                label={"Paste as Child"}
                                onClick={() => this.copyControl(1)}
                                icon={faCopy}
                                iconColor={"#2F5AB9"}
                              />
                              <ContextItem
                                label={"Paste as Sibling"}
                                onClick={() => this.copyControl(2)}
                                icon={faCopy}
                                iconColor={"#2F5AB9"}
                              />
                            </>
                          ) : (
                            false
                          )}
                        </>
                      ) : (
                        <>
                          {this.utils?.getCopyTaskList()?.length > 0 ? (
                            <>
                              <ContextItem
                                label={"Paste as Sibling"}
                                onClick={() => this.copyControl(2)}
                                icon={faCopy}
                                iconColor={"#2F5AB9"}
                              />
                            </>
                          ) : (
                            false
                          )}
                          <ContextItem
                            label={
                              "Tasks with At Completion Unit can not have subtasks."
                            }
                            icon={faCircleInfo}
                            iconColor={"#ACBDE3"}
                            className={"info"}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <ContextItem
                      label={"Blocked Tasks can not have subtasks."}
                      icon={faCircleInfo}
                      iconColor={"#ACBDE3"}
                      className={"info"}
                    />
                  )}
                  {this.state.selectedRecord.children?.length > 0 ? (
                    <ContextItem
                      label={"Summary Tasks can not be deleted."}
                      icon={faCircleInfo}
                      iconColor={"#ACBDE3"}
                      className={"info"}
                    />
                  ) : this.state.selectedRecord.actualUnit === 0 ||
                    this.state.selectedRecord.actualUnit === undefined ||
                    this.state.selectedRecord.actualUnit === null ? (
                    <ContextItem
                      label={"delete task"}
                      icon={faTrash}
                      onClick={() => this.deleteSelectedRecord("/api/task")}
                      iconColor={"red"}
                      className={"red"}
                      line={true}
                    />
                  ) : (
                    <ContextItem
                      label={"Tasks with actuals can not be deleted."}
                      icon={faCircleInfo}
                      iconColor={"#ACBDE3"}
                      className={"info"}
                    />
                  )}
                </>
              ) : (
                false
              )}
              {this.state.selectedRecord.objectTypeInfo?.code === "wbs" ? (
                projectSource !== "hub" ? (
                  <ContextItem
                    label={"No action available for WBSs."}
                    icon={faCircleInfo}
                    iconColor={"#ACBDE3"}
                    className={"info"}
                  />
                ) : (
                  <>
                    <ContextItem
                      label={"add Child WBS"}
                      onClick={() => this.openNewSideBar("wbsCreate", "child")}
                      icon={faPlus}
                      iconColor={"#2F5AB9"}
                    />
                    {this.state.selectedRecord.level === 1 ? (
                      false
                    ) : (
                      <ContextItem
                        label={"add Sibling WBS"}
                        onClick={() =>
                          this.openNewSideBar("wbsCreate", "sibling")
                        }
                        icon={faPlus}
                        iconColor={"#2F5AB9"}
                      />
                    )}
                    <ContextItem
                      label={"add Activity"}
                      onClick={() =>
                        this.openNewSideBar("activityCreate", "child")
                      }
                      icon={faPlus}
                      iconColor={"#2F5AB9"}
                    />
                  </>
                )
              ) : (
                false
              )}
              {this.state.selectedRecord.objectTypeInfo?.code === "activity" ? (
                !(
                  this.state.selectedRecord?.extType !== "Start Milestone" &&
                  this.state.selectedRecord?.extType !== "Finish Milestone"
                ) ? (
                  <ContextItem
                    label={"No action available for milestones."}
                    icon={faCircleInfo}
                    iconColor={"#ACBDE3"}
                    className={"info"}
                  />
                ) : this.state.selectedRecord?.p6Plan?.status?.code ===
                    "completed" ||
                  this.state.selectedRecord?.status?.code === "completed" ? (
                  <ContextItem
                    label={"You can not create Tasks under Completed Activity"}
                    icon={faCircleInfo}
                    iconColor={"#ACBDE3"}
                    className={"info"}
                  />
                ) : (
                  false
                )
              ) : (
                false
              )}
            </ContextMenu>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default DataTable;

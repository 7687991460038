import React from "react";

import "./TD.scss";
import ColorfullContent from "../CellContents/ColorfullContent";
import TextContent from "../CellContents/TextContent";
import NumberContent from "../CellContents/NumberContent";
import BooleanContent from "../CellContents/BooleanContent";
import DateContent from "../CellContents/DateContent";
import DayContent from "../CellContents/DayContent";
import HourContent from "../CellContents/HourContent";
import StaticLookupContent from "../CellContents/StaticLookupContent";
import DynamicLookupContent from "../CellContents/DynamicLookupContent";
import LookupContent from "../CellContents/LookupContent";
import ProgressCircle from "../../progressCircle/ProgressCircle";
import {
  extractStatusColor,
  date2Str,
  extractProgressColor,
  resolvePath,
  parseLink,
} from "../../Commons";
import TagGroup from "../../tags/TagGroup";

/**
 * TD is a versatile table cell component that dynamically renders different types of content
 * based on the provided column type and data.
 * It supports various content types including text, boolean, percentage, date, lookup, tag, and more.
 *
 * @param {object} column Configuration object for the column, defining the type of content, dataKey, and other properties.
 * @param {object} row The row data associated with the current cell.
 * @param {number|string} width The width of the table cell.
 * @param {object} [style] Additional inline styles to apply to the component.
 * @returns {JSX.Element} The rendered TD component.
 *
 * @example
 * // Render a text cell with a dynamic link
 * <TD
 *   column={{ type: "text", dataKey: "name", linkTo: "/profile" }}
 *   row={{ name: "John Doe" }}
 *   width={200}
 * />
 *
 * @example
 * // Render a progress circle for a percentage column
 * <TD
 *   column={{ type: "percentage", dataKey: "progress" }}
 *   row={{ progress: 75 }}
 *   width={100}
 * />
 */
const TD = ({ column, row, width, style }) => {
  const renderCellContent = (column, row) => {
    let content;

    let link = !!column?.linkTo && parseLink(column?.linkTo, row);

    let value = resolvePath(row, column?.dataKey);

    if (!!column?.type) {
      switch (column?.type) {
        case "text":
          if (column?.color) {
            const { bgColor, textColor } = extractStatusColor(value);
            content = (
              <ColorfullContent
                bgColor={bgColor}
                textColor={textColor}
                text={value}
              />
            );
          } else {
            content = <TextContent value={value} linkTo={link} />;
          }
          break;

        case "boolean":
          content = <BooleanContent isChecked={value} />;
          break;

        case "percentage":
          let percentColor = "#4A7CCC";
          let trackColor = "#D2DEF2";
          if (row?.objectType) {
            const colors = extractProgressColor(row?.objectType);
            percentColor = colors?.percentColor;
            trackColor = colors?.trackColor;
          } else if (row?.colorCode === "yellow") {
            percentColor = "#4A7CCC";
            trackColor = "#D2DEF2";
          } else if (row?.colorCode === "green") {
            percentColor = "#6DBA88";
            trackColor = "#35D69C33";
          } else if (row?.colorCode) {
            percentColor = row?.colorCode;
            trackColor = "#D2DEF2";
          }

          content = (
            <ProgressCircle
              percentColor={percentColor}
              trackColor={trackColor}
              value={value?.toFixed(0) ?? 0}
            />
          );
          break;

        case "date":
          content = <DateContent value={date2Str(value)} />;
          break;

        case "number":
          content = <NumberContent value={value} />;
          break;

        case "staticLookup":
          content = <StaticLookupContent value={value?.name} />;
          break;

        case "dynamicLookup":
          content = <DynamicLookupContent value={value?.name} />;
          break;

        case "lookup":
          content = <LookupContent value={value?.name} />;
          break;

        case "hour":
          content = (
            <HourContent value={value ? value.toFixed(0) + "h" : "0h"} />
          );
          break;

        case "day":
          content = (
            <DayContent value={value ? value.toFixed(0) + "d" : "0d"} />
          );
          break;

        case "tag":
          content = (
            <TagGroup
              tags={value}
              collapsedCount={1}
              style={{ marginBottom: 0, marginTop: 0 }}
            />
          );
          break;

        default:
          content = <TD width={width} />;
      }
    }

    return content;
  };

  return (
    <div
      className="hub-dt-td"
      style={{
        ...style,
        width: width,
        overflow: column?.type !== "tag" ? "overflow" : "unset",
      }}
    >
      {renderCellContent(column, row)}
    </div>
  );
};

export default TD;

import React, { Component } from 'react';
import ReportPeriod from './ReportPeriod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons'


class ReportPeriodPicker extends Component {
    constructor(props) {
        super(props);  
        this.state = {initialPeriodOrder: 0}
    }

    componentDidMount() {
        const order = this.getPeriodOrder(this.props.initialPeriodOrder);
        this.setState({...this.state, initialPeriodOrder:order});
    }

    getPeriodOrder(i) {
        const order = this.state.initialPeriodOrder+i < 0 ? 0 : (i + 3 >= this.props.reportingPeriods.length? this.props.reportingPeriods.length - 3:i);
        
        //console.log(i,this.state.initialPeriodOrder,this.state.initialPeriodOrder + 1 + 2 ,this.props.reportingPeriods.length,order)
        return order;
    }

    movePeriod = (i) => {this.setState({...this.state, initialPeriodOrder: this.getPeriodOrder(this.state.initialPeriodOrder + i)})}
    
    selectPeriod = (period) => {
        this.props.selectPeriod(period, this.state.initialPeriodOrder)
    }

    render() {

        //console.log(this.state)

        let listPeriods =this.props.reportingPeriods.slice(this.state.initialPeriodOrder, this.state.initialPeriodOrder+4 )

        return (
            <div className="hub-ts-ReportPeriodPicker">
                <div style={{minWidth: "422px"}}>
                    <FontAwesomeIcon icon={faAngleLeft} onClick={() => {this.movePeriod(-1)}} />
                    {listPeriods.map( (x,i) => {
                        return <ReportPeriod selectPeriod={this.selectPeriod} key={"ReportPeriod-"+x.id.toString()} ReportPeriod={x} isSelected = {i === this.props.selectedPeriod-this.state.initialPeriodOrder}/>
                    })}
                    <FontAwesomeIcon icon={faAngleRight} onClick={() => {this.movePeriod(1)}}  />
                </div>
                <div style={{clear:"both"}}></div>
            </div>
        );
    }
}

export default ReportPeriodPicker;
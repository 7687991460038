import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";

import "../../taskBoard/card/cards.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faStar,
  faMessageLines,
  faBan,
  faCircleExclamation,
} from "@fortawesome/pro-regular-svg-icons";

import ProgressBar from "../../taskBoard/card/progressBar.js/progressBar.js";

import CardToolbarIcon from "../../taskBoard/card/CardToolbarIcon.js";

import { DateUtils } from "../../../common/Commons";
import TagGroup from "../../taskBoard/card/tags/tagGroup";
import ActivityBlock from "./activityBlock.js";
import ActivityTasks from "./activityTasks/activityTasks.js";
import HubIcon from "../../../common/hubIcon/HubIcon.js";
import HiBlock from "../../../common/icons/HiBlock.js";
import HiTodo from "../../../common/icons/HiTodo.js";

export default class ActivityCard extends Component {
  constructor(props) {
    super(props);
    this.du = new DateUtils();
    this.state = {
      activeTab: this.props.task.isBlocked ? "block" : "",
      assignmentType: "",
      assignment: {},
      showToDo: false,
      showBlock: false,
      showAssignments: false,
      isAssignmentPanelOpen: false, // Assignment panelini açıksa assigment bölümü kapanmayacak.
    };
  }

  setActiveTab = (tab) => {
    this.setState({
      ...this.state,
      activeTab: tab === this.state.activeTab ? "" : tab,
    });
  };
  hideAssignments = () => {
    this.setState({ ...this.state, showAssignments: false });
  };
  showAssignments = () => {
    this.setState({ ...this.state, showAssignments: true });
  };
  openAssignmentPanel = () => {
    this.setState({ ...this.state, isAssignmentPanelOpen: true });
  };
  closeAssignmentPanel = () => {
    this.setState({
      ...this.state,
      showAssignments: false,
      isAssignmentPanelOpen: false,
    });
  };

  setAssignmentTab = (tab, assignmentType, assignment) => {
    let newTab = tab;

    if (
      tab === this.state.activeTab &&
      assignmentType === "new" &&
      this.state.assignmentType === "new"
    ) {
      newTab = "";
    }
    this.setState({
      ...this.state,
      activeTab: newTab,
      assignmentType: assignmentType,
      assignment: assignment,
    });
  };

  setFavorite = () => {
    this.props.setFavorite(
      { objectUid: this.props.task.uid, objectType: { code: "task", uid: 52 } },
      !this.props.task.isFavorite,
      this.props.columnIndex,
      this.props.index
    );
  };

  render() {
    let assignmentArray = this.props.taskResource?.find(
      (x) => x.taskId === this.props.task.uid
    )?.assignmentList;
    // console.log("this props",this.props.task.name, this.props.task,assignmentArray)

    //console.log("datediff",this.props.task.finishDate,this.du.getDifferenceToday(this.props.task.finishDate))
    let taskLength = this.props.task?.tasks?.length;
    return (
      <Draggable
        isDragDisabled={
          this.props.dragDisabled ? this.props.dragDisabled : false
        }
        key={this.props.task.uid}
        draggableId={this.props.task.uid.toString()}
        index={this.props.index}
      >
        {(draggableProvided, draggableSnapshot) => (
          <>
            <div
              className="card-drag-area"
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
            >
              {draggableProvided.placeholder}
              <div
                className={
                  "hub-teamHub-card " +
                  (this.props.selectedCard?.uid === this.props.task.uid
                    ? " selected"
                    : "")
                }
                style={{
                  borderColor: this.props.task.isBlocked ? "#f30000" : "",
                }}
                onClick={() =>
                  this.props.selectRecord(
                    this.props.task,
                    this.props.columnIndex,
                    this.props.index
                  )
                }
              >
                <div
                  className="body"
                  onDoubleClick={() => this.props.openSidebar("detail")}
                >
                  <div style={{ position: "relative" }}>
                    <TagGroup tags={this.props.task?.tag} />
                  </div>
                  <div className="title" {...draggableProvided.dragHandleProps}>
                    {this.props.task.name}
                  </div>
                  <div className="project-title">
                    {this.props.task.project?.code} -{" "}
                    {this.props.task.project?.name}
                  </div>
                  <div className="details">
                    <div
                      title="Go to Project"
                      className={
                        "tag fl " + this.props.task.color?.code.toString()
                      }
                    >
                      <a
                        href={
                          "/project/" +
                          this.props.task?.projectId.toString() +
                          "/wbs?activity=" +
                          this.props.task.code
                        }
                        className={this.props.task.color?.code.toString()}
                      >
                        {this.props.task.code}
                      </a>
                    </div>
                    <ProgressBar
                      remaining={this.props.task.remainingUnit}
                      actual={this.props.task.actualUnit}
                      color={this.props.task.color?.code.toString()}
                    />

                    {/*<div style={{marginLeft:5}} className={"tag fl" + this.props.task.color?.code.toString()}><a className={"gray"}>%15</a></div>*/}
                    <div
                      className={"tag fr"}
                      title={this.props.task.finishDate}
                    >
                      <a
                        className={
                          this.du.getDifferenceToday(
                            this.props.task.finishDate
                          ) <= 0
                            ? "lightblue"
                            : "red"
                        }
                      >
                        {this.du.getDifferenceToday(
                          this.props.task.finishDate
                        ) > 0 ? (
                          <FontAwesomeIcon
                            style={{ cursor: "pointer", marginRight: "4px" }}
                            color="#ffffff"
                            icon={faCircleExclamation}
                          />
                        ) : (
                          false
                        )}
                        {this.du.Date2MonthDay(this.props.task.finishDate)}
                      </a>
                    </div>

                    <div className="cb"></div>
                  </div>

                  <div className="toolbar">
                    <div
                      className="left"
                      style={{
                        width:
                          (this.state.showAssignments ||
                            this.state.isAssignmentPanelOpen) &&
                          assignmentArray.length > 3
                            ? "0px"
                            : false,
                        opacity:
                          (this.state.showAssignments ||
                            this.state.isAssignmentPanelOpen) &&
                          assignmentArray.length > 3
                            ? 0
                            : 1,
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      <CardToolbarIcon
                        className="todo"
                        selected={this.state.activeTab === "todo"}
                        key="todoIcon"
                        active={
                          taskLength > 0 && this.state.activeTab !== "todo"
                            ? true
                            : false
                        }
                        label={taskLength > 0 ? taskLength : ""}
                        onClick={() => this.setActiveTab("todo")}
                        icon={
                          <HubIcon
                            fontSize={20}
                            lineColor={
                              this.state.activeTab === "todo"
                                ? "#fff"
                                : taskLength > 0 &&
                                  this.state.activeTab !== "todo"
                                ? "#174999"
                                : "#DAE3F2"
                            }
                          >
                            <HiTodo />
                          </HubIcon>
                        }
                      />
                      <CardToolbarIcon
                        className={
                          "blockIcon" +
                          (this.props.task.isBlocked
                            ? " blocked"
                            : this.props.task.blockResolveDescription
                            ? " resolved"
                            : "")
                        }
                        selected={this.state.activeTab === "block"}
                        active={
                          this.props.task.isBlocked
                            ? true
                            : this.props.task.blockResolveDescription
                            ? true
                            : false
                        }
                        key="banIcon"
                        onClick={() => this.setActiveTab("block")}
                        icon={
                          <HubIcon
                            fontSize={20}
                            lineColor={
                              this.state.activeTab === "block" ||
                              this.props.task.isBlocked
                                ? "#fff"
                                : "#DAE3F2"
                            }
                          >
                            <HiBlock />
                          </HubIcon>
                        }
                      />
                    </div>
                  </div>

                  {/*<CardInfo task={this.props.task}/>*/}
                </div>

                <ActivityTasks
                  activeTab={this.state.activeTab}
                  task={this.props.task}
                  toDoOperations={this.props.toDoOperations}
                  taskIndex={this.props.index}
                  columnIndex={this.props.columnIndex}
                  markerLeft={taskLength > 0 ? 10 : 0}
                />
                <ActivityBlock
                  activeTab={this.state.activeTab}
                  blockTask={this.props.blockTask}
                  task={this.props.task}
                  toggleBlock={this.props.toggleBlock}
                  closeBlock={() => this.setActiveTab("")}
                  taskIndex={this.props.index}
                  columnIndex={this.props.columnIndex}
                  markerLeft={taskLength > 9 ? 24 : taskLength > 0 ? 15 : 0}
                />
              </div>
            </div>
          </>
        )}
      </Draggable>
    );
  }
}

import React from "react";

import WidgetCreator from "../../common/pageElements/pageLayout/widgetCreator";
import ContainerCreator from "../../common/pageElements/pageLayout/containerCreator";
import "./WidgetGroup.scss";

const WidgetGroup = ({
  config,
  childs,
  selectedFilters,
  counter,
  multiplexingAttribute,
  multiplexingFilter,
}) => {
  const { title, configuration } = config;

  return (
    <div className="widget-group" style={configuration?.style}>
      <p className="widget-title"> {title ?? ""}</p>
      <div className="child-list">
        {childs[0].childs.map((child) => {
          return child.type === "container" ? (
            <ContainerCreator
              container={child}
              selectedFilters={selectedFilters}
              counter={counter}
              multiplexingFilter={multiplexingFilter}
              multiplexingAttribute={multiplexingAttribute}
            />
          ) : (
            <WidgetCreator
              childs={childs[0].childs}
              code={child.code}
              style={child.style}
              selectedFilters={selectedFilters}
              counter={counter}
              multiplexingFilter={multiplexingFilter}
              multiplexingAttribute={multiplexingAttribute}
            />
          );
        })}
      </div>
    </div>
  );
};

export default WidgetGroup;

import React, { Component } from "react";
import MainButton from "./../buttons/MainButton";
import ColumnPicker from "./ColumnPicker";
import "../spinner.css";
import HiFilter from "../icons/HiFilter";
import HiRefresh from "../icons/HiRefresh";
import HiDownload from "../icons/HiDownload";
import HiTableView from "../icons/HiTableView";
import HiGanttLine from "../icons/HiGanttLine";
import HiColors from "../icons/HiColors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEmptySet, faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import HiLayoutRight from "../icons/HiLayoutRight";
import HiGanttBar from "../icons/HiGanttBar";

class Toolbar extends Component {
  render() {
    // console.log("Toolbar",this.props.toolbarActionButtons)
    let tab = this.props.toolbarActionButtons;

    const borderStyle = this.props.isFilterOpen ? {} : { borderBottom: "none" };

    return (
      <div className="hub-dataTable-toolBar" style={borderStyle}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {tab?.map((t) => {
            return (
              <MainButton label={t.label} onClick={this.props[t.onClick]} />
            );
          })}
          <MainButton
            label={"Filters"}
            className={"toolbar-filters-button"}
            onClick={this.props.toggleFilter}
            icon={<HiFilter />}
            superscript={
              this.props.config.view.filterList.length > 0
                ? this.props.config.view.filterList.length
                : false
            }
          />
          <ColumnPicker
            applyVisibleColumns={this.props.applyVisibleColumns}
            columns={this.props.config.columns}
            visibleColumnList={this.props.config.view.visibleColumnList}
          />
          {this.props.config.view.mode !== "list" && (
            <MainButton
              className={
                this.props.config.view.mode == "list"
                  ? "new-icon-button active"
                  : "new-icon-button"
              }
              onClick={() => this.props.setViewMode("list")}
              tooltip={"List View"}
              icon={<HiTableView />}
            />
          )}

          {this.props.config.view.mode !== "gantt" &&
          this.props.config.view.gantt ? (
            <MainButton
              className={
                this.props.config.view.mode == "gantt"
                  ? "new-icon-button active"
                  : "new-icon-button"
              }
              onClick={() => this.props.setViewMode("gantt")}
              tooltip={"Gantt View"}
              icon={<HiGanttLine />}
            />
          ) : (
            false
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {this.props.config.view.mode === "gantt" ? (
            <MainButton
              className={
                this.props.hideGanttbar
                  ? "new-icon-button"
                  : "new-icon-button active"
              }
              onClick={this.props.toggleGanttbar}
              tooltip={"Toggle Gantt"}
              icon={<HiGanttBar />}
            />
          ) : (
            false
          )}
          {this.props.config.view.mode === "gantt" ? (
            <MainButton
              className={
                this.props.hideColor
                  ? "new-icon-button colored"
                  : "new-icon-button colored active"
              }
              onClick={this.props.toggleColor}
              tooltip={"Toggle Gantt Colors"}
              icon={<HiColors />}
            />
          ) : (
            false
          )}
          {this.props.config.sideBar ? (
            <MainButton
              className={
                this.props.isSideBarOpen
                  ? "new-icon-button active"
                  : "new-icon-button"
              }
              onClick={this.props.toggleSidebar}
              tooltip={"Sidebar"}
              icon={<HiLayoutRight />}
            />
          ) : (
            false
          )}
          {this.props.config.tile ? (
            <MainButton
              className={
                this.props.config.view.mode == "tile"
                  ? "new-icon-button active"
                  : "new-icon-button"
              }
              onClick={() => this.props.setViewMode("tile")}
              tooltip={"Sidebar"}
              icon={<HiLayoutRight />}
            />
          ) : (
            false
          )}
          {this.props.config.board ? (
            <MainButton
              className={
                this.props.config.view.mode == "board"
                  ? "new-icon-button active"
                  : "new-icon-button"
              }
              onClick={() => this.props.setViewMode("board")}
              tooltip={"Sidebar"}
              icon={<HiLayoutRight />}
            />
          ) : (
            false
          )}

          <MainButton
            className="new-icon-button"
            icon={<HiDownload />}
            onClick={this.props.exportToCSV}
            tooltip={"Export to CSV"}
          />
          <MainButton
            className="new-icon-button"
            icon={<HiRefresh />}
            onClick={this.props.refresh}
            tooltip={"Refresh"}
            spinClass={this.props.thinking ? "spinner2 fast" : ""}
          />
        </div>
      </div>
    );
  }
}

export default Toolbar;

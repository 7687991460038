import React, { Component } from "react";
import MainButton from "../../common/buttons/MainButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./statusScreen.css";
import InputGroups from "../skeletonPatterns/InputGroups";
import Conversations from "../skeletonPatterns/Conversations";
import Table from "../skeletonPatterns/Table";

export default class statusScreen extends Component {
  getLogo() {
    switch (this.props.label) {
      case "Error":
        return <FontAwesomeIcon icon={faCircleXmark} color="red" size="3x" />;

      case "Loading":
        if (this.props.loadingType === "inputs") {
          return <InputGroups />;
        } else if (this.props.loadingType === "conversations") {
          return <Conversations />;
        } else if (this.props.loadingType === "table") {
          return <Table />;
        } else {
          return (
            <FontAwesomeIcon
              icon={faSpinner}
              color="#D7EDFF"
              size="3x"
              spin={true}
            />
          );
        }

      case "Update":
        return <FontAwesomeIcon icon={faCircleCheck} color="green" size="3x" />;
    }
  }

  getMessage() {
    switch (this.props.label) {
      case "Error":
        return (
          <div className="message" style={{ color: "red" }}>
            {this.props.message}
          </div>
        );

      case "Loading":
        return <br></br>;

      case "Update":
        return (
          <div className="message" style={{ color: "green" }}>
            {this.props.message}
          </div>
        );
    }
  }

  getButton() {
    switch (this.props.label) {
      case "Error":
        return (
          <div className="button">
            {" "}
            <MainButton
              onClick={this.props.onClick}
              key="okButton"
              className="button-2"
              icon={false}
              label="OK"
            />
          </div>
        );

      case "Loading":
        return null;

      case "Update":
        return (
          <div className="button">
            {" "}
            <MainButton
              onClick={this.props.onClick}
              key="okButton"
              className="button-2"
              icon={false}
              label="OK"
            />
          </div>
        );
    }
  }

  render() {
    return (
      <div className="hub-statusScreen">
        {!this.props.loadingType && (
          <div className="label">{this.props.label}</div>
        )}

        <div className="icon">{this.getLogo()}</div>
        {this.getMessage()}
        {this.getButton()}
      </div>
    );
  }
}

import "../note.css";
import Avatar from "../../avatar/index.js";

const NoteIcon = ({ dataSet }) => {
  return (
    <Avatar
      firstName={dataSet?.createdBy?.firstName}
      lastName={dataSet?.createdBy?.firstName}
      color={dataSet?.createdBy?.color}
      uid={dataSet?.createdBy?.uid}
      size="md"
      hasAvatar={
        dataSet?.createdBy?.profileImage?.cropImageHash &&
        dataSet?.createdBy?.profileImage?.profileImageHash
      }
    />
  );
};

export default NoteIcon;

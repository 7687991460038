import React from "react";

const GraphLegendSymbol = ({ fill }) => {
  return (
    <svg
      width="12px"
      height="16px"
      viewBox="0 0 12 4"
      xmlns="http://www.w3.org/2000/svg"
      style={{ paddingTop: "12px" }}
    >
      <rect width="12" height="4" rx={2} fill={fill} />
    </svg>
  );
};

export default GraphLegendSymbol;

import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";

import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import HiSave from "../../common/icons/HiSave.js";

class ResourceDetails extends Component {
  /**/
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
      },
      lookup: {
        uid: 3,
        code: "open",
        name: "open",
        parent: 2,
      },
    };
  }

  handleChanges = (formId, value) => {
    let items = { ...this.state.formData };
    items[formId] = value;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  handleChangesRole = (formId, value) => {
    //console.log(formId,value)
    let items = { ...this.state.formData };
    items[formId] = value.uid;

    items.roleInfo = value;
    this.setState({
      ...this.state,
      formData: items,
    });

    //console.log(items)
  };

  componentDidMount() {
    //console.log("componentDidMount")
    //  console.log(this.props)
    this.GetDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });

      this.GetDatas();
    }
  }

  GetDatas = () => {
    //console.log("getdata")
    //console.log(Auth.getResourceObject())

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/resource/" + this.props.selectedRecord.uid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log(r);
        if (r.status == 200) {
          let secProfile = Auth.getResourceObject().securityProfile?.name;
          let secRole = true;
          switch (secProfile) {
            case "USER":
              secRole = false;
              break;
            case "YABANCI":
              secRole = false;
              break;
            case "MUH":
              secRole = false;
              break;
          }

          //console.log(secRole.toString())
          //console.log(secProfile)
          let isManager = secRole;
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object,
            isManager: isManager,
          });

          //console.log(this.state)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  Update = () => {
    //console.log(this.state)

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify({
        roleId: this.state.formData.roleInfo.uid,
        subContractor: this.state.formData?.subContractor?.uid,
      }),
    };

    //console.log(requestOptions.body)

    fetch("/api/resource/" + this.state.formData.uid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(requestOptions)
        // this.props.updateSingleRow(1,r.object);
        if (r.status === 200) {
          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  updateRecord = () => {
    this.props.save("patch", "/api/resource/" + this.state.formData.uid, {
      roleId: this.state.formData.roleInfo.uid,
      subContractor: this.state.formData?.subContractor?.uid,
    });
  };

  handleChangesTest = (e) => {
    //console.log(e)
    //items = {...this.state.lookup};

    let items = e;
    this.setState({
      ...this.state,
      lookup: items,
    });
    //console.log(this.state)
  };

  render() {
    let secProfile = Auth.getResourceObject().securityProfile?.name;

    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="input-grid">
            {" "}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="firstName"
              label="Name"
              value={this.state.formData.firstName}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="lastName"
              label="Last Name"
              value={this.state.formData.lastName}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="uniqueName"
              label="Unique Name"
              value={this.state.formData.uniqueName}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="email"
              label="Email"
              value={this.state.formData.email}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="manager"
              label="Manager"
              value={this.state.formData.Manager}
              disabled={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="obsInfo"
              label="OBS"
              value={this.state.formData.obsInfo.name}
              disabled={true}
            />{" "}
            {this.state.isManager || secProfile === "ADMIN" ? (
              <>
                <DynamicLookup
                  endpoint="/api/resourcerole"
                  value={{
                    value: this.state.formData?.roleInfo?.uid,
                    label: this.state.formData?.roleInfo?.name,
                  }}
                  onChange={this.handleChangesRole}
                  formId="roleId"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Role"
                />

                <DynamicLookup
                  endpoint="/api/valuelistoptions/parent/30"
                  value={{
                    value: this.state.formData?.subContractor?.uid,
                    label: this.state.formData?.subContractor?.name,
                  }}
                  onChange={this.handleChanges}
                  formId="subContractor"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Sub Contractor"
                />
              </>
            ) : (
              <TextField
                width="100%"
                label="Role"
                value={this.state.formData.roleInfo?.name}
                required={true}
                disabled={true}
              />
            )}{" "}
          </div>
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.updateRecord}
            key="newTaskButton"
            className="button-area-action"
            iconLineColor="#fff"
            icon={<HiSave />}
            label="Save"
          />
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default ResourceDetails;

import React, { Component } from "react";
import "../../projects/details/ProjectDetails.css";
import Auth from "./../../auth/Auth";
import TextField from "../../projects/details/TextField";

import TextArea from "../../common/inputControls/textarea/TextArea";

import ButtonArea from "./../../common/pageElements/buttonArea";
import Section from "./../../common/pageElements/section";
import SectionContainer from "./../../common/pageElements/sectionContainer";
import MainButton from "./../../common/buttons/MainButton";
import { faFloppyDisk } from "@fortawesome/pro-light-svg-icons";

import { useParams } from "react-router";

import { DateUtils, Utils } from "./../../common/Commons";

import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";

import LoadingScreen from "../../common/statusScreen/loadingScreen";

import DateArea from "../../common/inputControls/dateArea";

class DemandDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LoadingText: "Loading Demand Details",
      Loading: true,
      LoadingError: false,
      LoadingErrorText: "",
      demand: this.props.demand,

      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
      },

      requiredFields: [{ id: "name", label: "Name" }],
    };
  }

  handleChanges = (area, val) => {
    //console.log("handleChanges",area,val)
    //console.log(val)
    let items = { ...this.state.demand };

    items[area] = val;
    this.setState({
      ...this.state,
      demand: items,
    });

    // console.log(items)
  };

  handleChangesLookup = (e, val) => {
    // console.log(e)
    //console.log(val)

    let items = { ...this.state.demand };

    items[e] = val.uid;
    items.programManagerInfo = { uid: val.uid, fullName: val.fullName };
    this.setState({
      ...this.state,
      demand: items,
    });

    //console.log(items)
  };

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.demand };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] > this.state.demand.finishDate) {
      errors[e].message = "Start Date can not be later than Finish Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;
      errors.finishDate.message = "";
      errors.finishDate.error = false;
    }

    this.setState({ ...this.state, demand: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.demand };
    items[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items[e] < this.state.demand.startDate) {
      errors[e].message = "Finish Date can not be earlier than Start Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.startDate.message = "";
      errors.startDate.error = false;
    }

    this.setState({ ...this.state, demand: items, errorMessages: errors });
  };

  componentDidMount() {
    //this.getData();
    this.setState({ ...this.state, Loading: false, demand: this.props.demand });
  }

  getData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/demands/" + this.props.params.demandid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log('Status',r.body.status)
        if (r.body.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            demand: r.body.object,
          });

          this.props.selectedRecord(r.body.object[0]);
        } else if (r.body.status == 401) {
          window.location.replace("/login");
        } else if (r.body.status == 403) {
          this.setState({
            ...this.state,
            Loading: false,
            LoadingError: true,
            LoadingText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Loading: false,
            LoadingError: true,
            LoadingText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  update = () => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(this.state.demand),
    };

    //console.log("update",this.state.demand)

    fetch("/api/idea/" + this.props.params.demandid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        const u = new Utils();

        if (r.status === 200) {
          this.setState(
            {
              ...this.state,
              Status: "Update",
              ErrorMessage: "Updated Succesfully",
            },
            this.props.selectedRecord(r.body.object)
          );
          this.notify();
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          u.addNotification(
            "error",
            "You are not authorized to take this action. Please contact your system administrator.."
          );
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          u.addNotification("error", r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            ErrorMessage: r.body.message,
          });
        } else {
          //console.log(r.body.message);
          u.addNotification("error", r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  updateCheck = () => {
    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      this.update();
    }
  };

  notify = () => {
    const u = new Utils();
    u.addNotification("success", "Demand saved successfully.");
  };

  render() {
    //const du  = new DateUtils();
    // console.log("demand details",this.state, " props ", this.props)

    return this.state.Loading ? (
      <LoadingScreen />
    ) : (
      <div className="pageDetails">
        <ButtonArea>
          <MainButton
            onClick={this.updateCheck}
            label="Save Demand"
            className="button-2"
          />
        </ButtonArea>
        <Section title="DEMAND DETAILS">
          <SectionContainer width="80%">
            <TextField
              disabled
              pct="20"
              label="Demand ID"
              value={this.state.demand.code}
            />
            <TextField
              pct="60"
              label="Demand Name"
              value={this.state.demand.name}
              onChange={this.handleChanges}
              changeArea="name"
            />
          </SectionContainer>

          <SectionContainer width="100%">
            <TextArea
              width="95%"
              rows="8"
              pct="80"
              label="Description"
              value={this.state.demand.description}
              onChange={this.handleChanges}
              changeArea="description"
            />
          </SectionContainer>
        </Section>
        <Section title="DEMAND SCHEDULE">
          <SectionContainer width="100%">
            <DateArea
              width="30%"
              onChange={this.handleChangesStartDate}
              changeArea="startDate"
              label="Start Date"
              value={this.state.demand.startDate}
              required={true}
              errorMessage={this.state.errorMessages["startDate"].message}
              error={this.state?.errorMessages["startDate"]?.error}
              warning={this.state?.errorMessages["startDate"]?.warning}
            />

            <DateArea
              width="30%"
              onChange={this.handleChangesFinishDate}
              changeArea="finishDate"
              label="Finish Date"
              value={this.state.demand.finishDate}
              required={true}
              errorMessage={this.state?.errorMessages["finishDate"]?.message}
              error={this.state?.errorMessages["finishDate"]?.error}
              warning={this.state?.errorMessages["finishDate"]?.warning}
            />
          </SectionContainer>
        </Section>
        <Section title="PROPERTIES">
          <SectionContainer width="20%">
            <DynamicLookup
              endpoint={"/api/valuelist/code/idea_size"}
              required={true}
              onChange={this.handleChanges}
              extraObject={"valueListOptions"}
              value={{
                value: this.state.demand?.size?.uid,
                label: this.state.demand?.size?.name,
              }}
              formId="size"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="93%"
              label="Size"
              disabled={false}
            />
          </SectionContainer>

          <SectionContainer width="20%">
            <DynamicLookup
              endpoint={"/api/valuelist/code/idea_type"}
              required={true}
              onChange={this.handleChanges}
              extraObject={"valueListOptions"}
              value={{
                value: this.state.demand?.type?.uid,
                label: this.state.demand?.type?.name,
              }}
              formId="type"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="93%"
              label="Type"
              disabled={false}
            />
          </SectionContainer>

          <SectionContainer width="20%">
            <DynamicLookup
              endpoint={"/api/valuelist/code/idea_priority"}
              required={true}
              onChange={this.handleChanges}
              extraObject={"valueListOptions"}
              value={{
                value: this.state.demand?.priority?.uid,
                label: this.state.demand?.priority?.name,
              }}
              formId="priority"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="93%"
              label="Priority"
              disabled={false}
            />
          </SectionContainer>
        </Section>
        <Section title="STAKEHOLDERS">
          <SectionContainer width="35%">
            {/* <DynamicLookup endpoint="/api/resource" value={{value:this.state.demand.iptResponsible?.id, label: this.state.demand.iptResponsible?.fullName }} onChange={this.handleChanges} formId="iptResponsible" searchAttribute="fullName" valueAttribute="id" labelAttribute="fullName"  width="90%" label="IPT Responsible"/>*/}

            <DynamicLookup
              endpoint="/api/obs"
              value={{
                value: this.state.demand.responsibleUnit?.uid,
                label: this.state.demand.responsibleUnit?.name,
              }}
              onChange={this.handleChanges}
              formId="responsibleUnit"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="90%"
              label="Responsible Unit"
            />
          </SectionContainer>
          <SectionContainer width="35%">
            <DynamicLookup
              endpoint="/api/resource"
              value={{
                value: this.state.demand.responsibleManager?.uid,
                label: this.state.demand.responsibleManager?.fullName,
              }}
              onChange={this.handleChangesLookup}
              formId="responsibleManager"
              searchAttribute="fullName"
              valueAttribute="uid"
              labelAttribute="fullName"
              width="90%"
              label="Responsible Manager"
            />
          </SectionContainer>
        </Section>
      </div>
    );
  }
}

export default (props) => <DemandDetails {...props} params={useParams()} />;

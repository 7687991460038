import http, { axiosFileUpload as httpFile } from "../http-common";

const basePath = (id = "") => `/api/profileImage${id ? "/" : ""}${id}`;

export const getProfileImage = (resourceId) => {
  return http.get(basePath(resourceId));
};

export const deleteProfileImage = (resourceId) => {
  return http.delete(basePath(resourceId));
};

export const createProfileImage = (files) => {
  return httpFile.post(basePath(), files);
};

export const updateProfileImage = (resourceId, files) => {
  return httpFile.patch(basePath(resourceId), files);
};

import { useState } from "react";
import Select, { components } from "react-select";

import Avatar from "../../avatar";
import HubIcon from "../../hubIcon/HubIcon";
import HiArrowShortDown from "../../icons/HiArrowShortDown";
import HiSearch from "../../icons/HiSearch";
import InputControl from "../InputControl";

const SearchSelect = ({
  value,
  onChange,
  placeholder,
  defaultOptions,
  options,
  withAvatar,
}) => {
  const colourStyles = {
    control: (base) => ({
      ...base,
      border: "0",
      outline: "none",
      padding: "0",
      minHeight: "31px",
      boxShadow: "none",
      "&:hover": {
        border: "0",
        outline: "none",
        boxShadow: "none",
      },
    }),
    container: (base) => ({
      ...base,
      width: "100%",
      border: "0",
      outline: "none",
      padding: "0",
      boxShadow: "none",
      "&:hover": {
        border: "0",
        outline: "none",
        boxShadow: "none",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0",
      lineHeight: "18px" /* 128.571% */,
    }),
    input: (base) => ({
      ...base,
      padding: "0",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none !important",
    }),
    dropdownIndicator: (base) => ({
      ...base,
    }),
    placeholder: (base, state) => ({
      ...base,
      color: "#051530",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "18px" /* 128.571% */,
      opacity: "0.15",
      padding: "0",
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: "1000",
      width: "calc(100% + 45px)",
      left: "-34px !important",
      borderRadius: "6px",
      border: "1px solid var(--hub100, #D2DEF2)",
      background: "#FFF",
      boxShadow: "0px 20px 30px 0px rgba(18, 84, 193, 0.25)",
      padding: "5px",
      overflowY: "auto",
      scrollbarWidth: "7px !important",
      scrollbarColor: "#E3E3E3 white",
      "&::-webkit-scrollbar": {
        backgroundColor: "#E3E3E3;",
        width: "7px !important",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E3E3E3;",
        borderRadius: "1px",
        width: "7px !important",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
        borderRadius: "1px",
        width: "7px !important",
      },
      "& > div:last-child": {
        borderBottom: "none", // Remove bottom border from last option
      },
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      borderBottom: "1px solid #E8EFF9",
      padding: "10px",
      height: "auto",
      color: state.isSelected ? "#fff" : "var(--hub900, #051530)",
      background: state.isSelected ? "var(--hub500, #1769F1)" : "#fff",
      fontFamily: "Noto Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      borderRadius: "3px",

      "&:last-child": {
        borderBottom: "none", // Remove bottom border from last option
      },

      "&:hover": {
        background: "var(--hub10, #F4F7FC)",
        color: "#051530",
      },
    }),
  };

  const { Option } = components;
  const IconOption = (props) => {
    return (
      <Option {...props}>
        <div
          className="lookup-dropdown"
          style={{ display: "flex", alignItems: "center" }}
        >
          {withAvatar && (
            <Avatar
              firstName={props?.data?.firstName}
              lastName={props?.data?.lastName}
              uid={props?.data?.uid}
              color={props?.data?.color}
              style={{
                width: 22,
                height: 22,
              }}
              size="sm"
              letterCount={1}
              hasAvatar={props?.data?.hasAvatar}
            />
          )}

          {props.data.label}
        </div>
      </Option>
    );
  };

  const DropdownIndicator = (props) => {
    return (
      <div {...props}>
        <HubIcon>
          <HiArrowShortDown />
        </HubIcon>
      </div>
    );
  };
  return (
    <InputControl hideMessageBox inputWrapperStyle={{ height: 36, gap: "5px" }}>
      <HubIcon>
        <HiSearch />
      </HubIcon>
      <Select
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultOptions={defaultOptions}
        options={options}
        styles={colourStyles}
        components={{ Option: IconOption, DropdownIndicator }}
      />
    </InputControl>
  );
};

export default SearchSelect;

const HiMention = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill={bgColor ?? "none"}
    >
      <path
        d="M10 8C10 6.61929 8.88071 5.5 7.5 5.5C6.11929 5.5 5 6.61929 5 8C5 9.38071 6.11929 10.5 7.5 10.5C8.88071 10.5 10 9.38071 10 8ZM10 8V8.9375C10 9.80045 10.6996 10.5 11.5625 10.5C12.4254 10.5 13.125 9.80045 13.125 8.9375V8C13.125 4.8934 10.6066 2.375 7.5 2.375C4.3934 2.375 1.875 4.8934 1.875 8C1.875 11.1066 4.3934 13.625 7.5 13.625M10.3125 12.8714C9.42503 13.3838 8.45598 13.6268 7.49998 13.6259"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiMention;

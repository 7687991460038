import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import StatusScreen from "../../common/statusScreen/statusScreen.js";
import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import { Utils } from "./../../common/Commons.js";
import { DateUtils } from "./../../common/Commons.js";
import TextField from "../../common/inputControls/textField";
import DateArea from "../../common/inputControls/dateArea";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import TextArea2 from "../../common/inputControls/textArea2/textArea";
import Section from "../../common/pageElements/section";
import DynamicLookupSync from "../../common/inputControls/lookup/DynamicLookupSync";
import HiSave from "../../common/icons/HiSave.js";
class NewTask extends Component {
  /**/
  constructor() {
    super();
    this.util = new DateUtils();
    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        taskId: "",
        startDate: "",
        finishDate: "",
        status: {
          uid: 31,
          code: "not_started",
          name: "Not Started",
          color: null,
        },

        progressCalculation: { uid: 151, name: "Unit", code: "unit" },
        remainingUnit: 0,
      },
      errorMessages: {
        startDate: { message: "" },
        finishDate: { message: "" },
        name: { message: "", error: false },
        progressCalculation: { message: "", error: false, warning: false },
      },

      requiredFields: [
        { id: "name", label: "Name" },
        { id: "progressCalculation", label: "progress Calculation" },
      ],
    };
  }

  handleChangesStartDate = (e,val) =>{

    //  console.log(this.state)
     //console.log(val)
  
  
         let errors = {...this.state.errorMessages};
         let items = {...this.state.formData}; 
         items[e]=val;
         const dateFormat = new Date(val).toLocaleDateString('tr-tr', { year:"numeric", month:"numeric", day:"numeric"});
         const dateYear = new Date(val).getFullYear();
         if(items[e]>this.state.formData.finishDate)
         {
           errors[e].message="Start Date can not be later than Finish Date";
           errors[e].error=true;      
           errors[e].warning=false;         
         }
         else if(dateFormat=="Invalid Date" || dateYear>2099 || dateYear<1900){
             errors[e].message="Invalid Date Format";
             errors[e].error=true;
             errors[e].warning=false;      
         } 
         else
         {
            errors[e].message="";
            errors[e].error=false;
            this.state.errorMessages.finishDate.error = false;
            this.state.errorMessages.finishDate.message= "";
  
            if(items[e]<(this.state.activity?.p6Plan?this.state.activity?.p6Plan?.startDate:this.state.activity?.startDate))
            {
              errors[e].message="Start Date is earlier than Activity's Start Date";
              errors[e].error=false;
              errors[e].warning=true;  
            }
         }
  
          this.setState({ ...this.state, formData:items,  errorMessages:errors })
         
     }
  
  
  
       handleChangesFinishDate = (e,val) =>{
        
         let errors = {...this.state.errorMessages};
         let items = {...this.state.formData};
         items[e]=val;
         const dateFormat = new Date(val).toLocaleDateString('tr-tr', { year:"numeric", month:"numeric", day:"numeric"});
         const dateYear = new Date(val).getFullYear();
         if(items[e]<this.state.formData.startDate)
         {
             errors[e].message="Finish Date can not be earlier than Start Date";
             errors[e].error=true; 
             errors[e].warning=false;      
         }
         else if(dateFormat=="Invalid Date" || dateYear>2099 || dateYear<1900){
             errors[e].message="Invalid Date Format";
             errors[e].error=true;
             errors[e].warning=false;
         }  
         else
         {
            errors[e].message="";
            errors[e].error=false;
            this.state.errorMessages.startDate.error = false;
            this.state.errorMessages.startDate.message= "";
            
            if(items[e]>(this.state.activity?.p6Plan?this.state.activity?.p6Plan?.finishDate:this.state.activity?.finishDate))
            {
             errors[e].message="Finish Date is later than Activity's Finish Date";
             errors[e].error=false;
             errors[e].warning=true; 
            }
         }
        
          this.setState({ ...this.state, formData:items,  errorMessages:errors })
         
     }

  handleChanges = (e, val) => {
    //console.log(e)

    //console.log(val)

    let items = { ...this.state.formData };

    items[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  handleChangesLookup = (e, val) => {
    //console.log(e)
    //console.log(val.id)

    let items = { ...this.state.formData };

    items[e] = val.id;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  handleChangesProject = (e, val) => {
    //console.log(e)
    //console.log(val.id)

    let items = { ...this.state.formData };

    items[e] = val.uid;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  handleChangesActivity = (e, val) => {
    //console.log("handleChangesActivity",e,val,this.state.formData.startDate)

    //console.log(val)

    let items = { ...this.state.activity };
    let taskItems = { ...this.state.formData };
    //  const items = Object.create(this.state.formData);

    items[e] = val.uid;
    items.wbsId = val.wbsId;
    items.code = val.code;
    items.name = val.name;
    items.finishDate = val.finishDate;
    items.startDate = val.startDate;
    //this.setState({ formData:items },this.chechkRequiredFields(e,val))
    taskItems.activityId = val.uid;

    taskItems.parent = null;
    if (
      this.state.formData.startDate === "" ||
      this.state.formData.startDate === undefined
    ) {
      // console.log("ife girdik",val.startDate )
      taskItems.startDate = val.startDate;
    }
    if (
      this.state.formData.finishDate === "" ||
      this.state.formData.finishDate === undefined
    ) {
      taskItems.finishDate = val.finishDate;
    }

    this.setState({ ...this.state, activity: items, formData: taskItems });
  };

  handleChangesTask = (e, val) => {
    //console.log("handleChangesTask",e, val)
    //console.log(val.id)

    /*  */
    let items = { ...this.state.formData };

    items[e] = val;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  componentDidMount() {
    //console.log("componentDidMount",Auth.getResourceObject())
    //console.log(this.props)
    // this.GetDatas();

    //console.log(this.props.parentRecord.children)
    //console.log(Object.keys(this.props.parentRecord.children).length)
    let secProfile = Auth.getResourceObject().securityProfile?.name;
    let owner = "";
    //console.log('TESTT',secProfile)
    switch (secProfile) {
      case "USER":
        owner = Auth.getResourceObject().managerInfo;
        break;
      case "MUH":
        owner = Auth.getResourceObject().managerInfo;
        break;
      default:
        owner = Auth.getResourceObject();
    }

    let errors = { ...this.state.errorMessages };

    //console.log("this.props.parentRecord?.children",this.props.parentRecord.children)
    //console.log("this.props.parentRecord?.children",this.props.parentRecord.children.filter(child => child.level === 1).length);

    let childLength =
      this.props.parentRecord.children === undefined
        ? 1
        : this.props.objectType === "task"
        ? this.props.parentRecord?.children?.length + 1
        : this.props.parentRecord.children.filter((child) => child.level === 1)
            .length + 1;

    let data = {
      code: this.props.parentRecord.code + "-" + (childLength * 10).toString(),
      //code:"asdsad",

      parent:
        this.props.objectType === "task"
          ? {
              uid: this.props.parentRecord.uid,
              code: this.props.parentRecord.code,
            }
          : null,
      activityId:
        this.props.objectType === "task"
          ? this.props.parentRecord.activityId
          : this.props.parentRecord.uid,

      status: {
        uid: 31,
        code: "not_started",
        name: "Not Started",
        color: null,
      },
      stage: {
        uid: 1,
      },
      progressCalculation: { uid: 151, name: "Unit", code: "unit" },
      name: "new task",
      remainingUnit: 0,
      startDate: this.props.parentRecord.startDate,
      finishDate: this.props.parentRecord.finishDate,
      owner: owner,
    };
    /*   if(this.util.getDifferenceToday(this.props.parentRecord.startDate)>14)
            {
              this.state.errorMessages["startDate"].message="Task can not be earlier than 2 weeks";
              this.state.errorMessages["startDate"].error=true;
             
            }
            console.log(errors)
          */
    this.setState(
      { ...this.state, formData: data, Status: "Load", secProfile: secProfile },
      () => {
        if (!this.props.fromActions) {
          this.getActivity();
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    //console.log("newSidebar!!");

    // console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //  console.log(this.props);
      let childLength =
        this.props.parentRecord.children === undefined
          ? 1
          : this.props.objectType === "task"
          ? this.props.parentRecord?.children?.length + 1
          : this.props.parentRecord.children.filter(
              (child) => child.level === 1
            ).length + 1;
      let data = {
        code: this.props.parentRecord.code + " " + childLength.toString(),
        //code:"asdsad",
        activityId: this.props.parentRecord.uid,
        parent: { code: this.props.parentRecord.code },
        status: {
          uid: 31,
          code: "not_started",
          name: "Not Started",
          color: null,
        },
        stage: {
          uid: 1,
        },
        name: "new task2222",
        remainingUnit: 0,
        startDate: this.props.parentRecord.startDate,
        finishDate: this.props.parentRecord.finishDate,
        owner: Auth.getResourceObject(),
      };

      this.setState(
        { Loading: true, Status: "Loading", formData: data },
        this.getActivity()
      );

      //  this.GetDatas();
    }
  }

  getActivity = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        (this.props.selectedRecord.activityId
          ? this.props.selectedRecord.activityId
          : this.props.selectedRecord.uid),
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log(r);
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            activity: r.body.object[0],
          });

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  Create = () => {
    // console.log(this.state.formData)
    //console.log(JSON.stringify(this.state.formData))

    //console.log(this.props)

    // console.log(this.checkErrors());

    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      //console.log("created");
      this.props.save(
        "post",
        "/api/task",
        this.state.formData,
        "middle",
        undefined,
        this.props.fromActions !== undefined ? true : undefined
      );
    }
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //  console.log(arrayErrors[i][1].message)

      if (arrayErrors[i][1].error) {
        //console.log(item)
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  render() {
    //console.log("oktayBey", this.state)
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          {/*  <TextField width="100%" onChange= {this.handleChanges} changeArea="code" label="ID" value={this.state.formData.code} required={true} disabled={true}/>*/}
          <div className="input-grid">
            {" "}
            {this.props.fromActions !== undefined ? (
              <div style={{ height: "85px" }}>
                <DynamicLookupSync
                  endpoint={
                    "/api/projects/" + this.props.project?.uid + "/activity"
                  }
                  required={true}
                  searchAppender={"extType:notLike:Milestone"}
                  onChange={this.handleChangesActivity}
                  //value={{value:this.state.formData.owner?.id, label: this.state.formData.owner?.fullName }}
                  formId="activityId"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Activity"
                />

                <DynamicLookupSync
                  endpoint={
                    "/api/projects/" +
                    this.props.project?.uid +
                    "/wbs/" +
                    this.state.activity?.wbsId +
                    "/activity/" +
                    this.state.formData?.activityId +
                    "/task"
                  }
                  searchAppender={"extType:notLike:Milestone"}
                  onChange={this.handleChangesTask}
                  //value={{value:this.state.formData.owner?.id, label: this.state.formData.owner?.fullName }}
                  formId="parent"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Parent Task"
                />
              </div>
            ) : (
              false
            )}
            {this.props.pageType === "board" ? (
              <>
                <DynamicLookup
                  endpoint="/api/projects"
                  required={true}
                  onChange={this.handleChangesProject}
                  //value={{value:this.state.formData.owner?.id, label: this.state.formData.owner?.fullName }}
                  formId="projectId"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Project"
                />

                <DynamicLookupSync
                  endpoint={
                    "/api/projects/" +
                    this.state.formData.projectId +
                    "/activity"
                  }
                  required={true}
                  searchAppender={"extType:notLike:Milestone"}
                  onChange={this.handleChangesActivity}
                  //value={{value:this.state.formData.owner?.id, label: this.state.formData.owner?.fullName }}
                  formId="activityId"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Activity"
                />
              </>
            ) : (
              false
            )}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="name"
              label="Name"
              value={this.state.formData.name}
              required={true}
            />
            {this.state.secProfile !== "USER" &&
            this.state.secProfile !== "MUH" ? (
              <DynamicLookup
                endpoint="/api/resource/taskresponsible"
                required={true}
                onChange={this.handleChanges}
                value={{
                  value: this.state.formData.owner?.uid,
                  label: this.state.formData.owner?.fullName,
                }}
                formId="owner"
                searchAttribute="fullName"
                valueAttribute="uid"
                labelAttribute="fullName"
                width="100%"
                label="Responsible"
              />
            ) : (
              <TextField
                width="100%"
                label="Responsible"
                value={this.state.formData.owner?.fullName}
                required={true}
                disabled={true}
              />
            )}
            <DateArea
              width="100%"
              type="date"
              onChange={this.handleChangesStartDate}
              changeArea="startDate"
              value={this.state.formData?.startDate}
              label="Start Date"
              required={true}
              errorMessage={this.state.errorMessages["startDate"].message}
              warning={this.state?.errorMessages["startDate"]?.warning}
              error={this.state?.errorMessages["startDate"]?.error}
            />
            <DateArea
              width="100%"
              type="date"
              onChange={this.handleChangesFinishDate}
              changeArea="finishDate"
              value={this.state.formData?.finishDate}
              label="Finish Date"
              required={true}
              errorMessage={this.state.errorMessages["finishDate"]?.message}
              error={this.state?.errorMessages["finishDate"]?.error}
              warning={this.state?.errorMessages["finishDate"]?.warning}
            />
            <DynamicLookup
              endpoint={"/api/valuelist/code/hub_task_progress_calculation"}
              required={true}
              onChange={this.handleChanges}
              extraObject={"valueListOptions"}
              value={{
                value: this.state.formData?.progressCalculation?.uid,
                label: this.state.formData?.progressCalculation?.name,
              }}
              formId="progressCalculation"
              searchAttribute="name"
              valueAttribute="uid"
              labelAttribute="name"
              width="100%"
              label="Progress Calculation Method"
              disabled={
                this.state.secProfile == "USER" ||
                this.state.secProfile == "MUH" ||
                this.state.secProfile == "BMUH"
              }
            />
          </div>

          <TextArea2
            width="100%"
            rows="5"
            label="Description"
            value={this.state.formData.description}
            onChange={this.handleChanges}
            changeArea="description"
          />

          {/* <TextField width="100%" onChange = {this.handleChanges} changeArea="etc" value="0" label="Etc"/>
                    <SelectArea width="47%" cols="100%" parentCallback = {this.handleChanges} changeArea="owner" label="owner"  data={selectConfig.data[1]} config={selectConfig.config[1]}/>
                 <DynamicLookup endpoint="/api/resource"  onChange={this.handleChangesLookup} formId="owner" searchAttribute="name" valueAttribute="id" labelAttribute="fullName"  width="100%" label="Owner"/>*/}

          <Section title="Activity Details">
            <div className="input-grid">
              <TextField
                disabled={true}
                width="100%"
                label="Activity Code"
                value={this.state.activity?.code}
              />
              <TextField
                disabled={true}
                width="100%"
                label="Activity Name"
                value={this.state.activity?.name}
              />
              <DateArea
                disabled={true}
                width="100%"
                type="date"
                value={
                  !this.props?.fromActions
                    ? this.state.activity?.startDate
                    : this.state.activity?.p6Plan?.startDate
                }
                label="Activity Start Date"
              />
              <DateArea
                disabled={true}
                width="100%"
                type="date"
                value={
                  !this.props?.fromActions
                    ? this.state.activity?.finishDate
                    : this.state.activity?.p6Plan?.finishDate
                }
                label="Activity Finish Date"
              />
            </div>
          </Section>
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.Create}
            key="newTaskButton"
            className="button-area-action"
            iconLineColor="#fff"
            icon={<HiSave />}
            label="Save"
          />
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default NewTask;

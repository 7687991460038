import React, { Component } from 'react';

import InputControl from '../InputControl';
import DatePicker, { registerLocale } from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import "./DateArea.css";
import { DateUtils, Utils } from '../../Commons';
import { enAU } from 'date-fns/locale';


registerLocale("enUS", enAU);

class dateArea extends Component {

    constructor(props) {
      super(props);
      this.state={v: '', focus:false, error:false, errorMessage: ''}
      this.handleFocus = this.handleFocus.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    handleFocus(t) {
      let errorMessage = t===false&&this.props.required&&this.state.v.length==0?'This field is required.':'';
      let error = t===false&&this.props.required&&this.state.v.length==0?true:false;
      this.setState({...this.state, focus:t, error:error, errorMessage: errorMessage}) 
    }

    componentDidMount(){

      //console.log("componentDidMount TextField");
      //console.log(this.props);
      this.setState({...this.state, v:this.props.value?this.props.value:''}) 
    }

      componentDidUpdate(prevProps)
    {
      if(prevProps.value!==this.props.value)
      {
         this.setState({...this.state, v:this.props.value?this.props.value:''}) 
      }
    }

    onChange(x){ 

      let e=""
      if(x!==null)
      {

        let a=new Date(x);
        a.setHours(3);

        e = a.toISOString().slice(0,10);


      }
       
      
      
      this.setState({...this.state, v: e, focus:false}); this.props.onChange(this.props.changeArea,e )}

    onKeyDown (e){
      if(this.props.onKeyDown!==undefined)
      {

         this.props.onKeyDown(e);
      }
     
    }

    render() {

      let du = new DateUtils();

      var timestamp = Date.parse(this.state.v); // invalid date handling

      if (isNaN(timestamp) == false) {
        var d = new Date(timestamp);
      } else {
        var d = null
      }
      
      //console.log("this.state.v", this.state.v)
      //console.log("timestamp", timestamp)
      //console.log("d", d)


        return (
            <InputControl inputValue={this.state.v} error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props}>
              { 
                //<input type="date"  onKeyDown={(e)=>this.onKeyDown(e)} onChange={this.onChange} disabled={this.props.disabled}  value={this.state.v} onFocus={()=>this.handleFocus(true)} onBlur={()=>this.handleFocus(false)}/>
              }

              {(this.props.disabled)?<input disabled  value={du.Date2Str(d)} />:<DatePicker
              selected={ d }
              className="input"
              onChange={ this.onChange }
              onFocus={()=>this.handleFocus(true)} 
              onBlur={()=>this.handleFocus(false)}
              dateFormat="dd.MM.yyyy"
              locale="enUS"
              todayButton="Today"
              />}
             
              {
                /*  Tasklar
                    Girdi olarak daha önce kullandığımız YYYY-MM-DD formatını almalı ve state'e onu dönmeli.
                    Styling
                    Read Only - Disabled propu implementasyonu
                     
                    <DatePicker
                    selected={ d }
                    onChange={ this.onChange }
                    dateFormat="dd.mm.yyyy"
                    locale="tr"
                    />

                */

              }


            </InputControl>
        );
    }

}

export default dateArea;
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import {
  Editable,
  withReact,
  useSlate,
  Slate,
  useFocused,
  useSelected,
  ReactEditor,
} from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  Text,
  Range,
  Node,
} from "slate";
import { withHistory } from "slate-history";
import escapeHtml from "escape-html";

import { Button, Icon, Portal, Toolbar } from "./EditorComponents";
import HiSend from "../icons/HiSend";
import MainButton from "../buttons/MainButton";
import TooltipWithPage from "../tooltipWithPage/tooltipWithPage";
import { getMentionCharacters } from "../../api/task";
import { postFile } from "../../api/file";
import UploadButton from "../fileUpload/UploadButton";
import FileBadge from "../fileUpload/FileBadge";
import HubIcon from "../hubIcon/HubIcon";
import HiBold from "../icons/HiBold";
import HiItalic from "../icons/HiItalic";
import HiUnderline from "../icons/HiUnderline";
import HiBulletList from "../icons/HiBulletList";
import HiNumberList from "../icons/HiNumberList";
import HiMention from "../icons/HiMention";
import loadingImage from "../../assets/img/loading-white.png";
import {
  detectOperatingSystem,
  imageDetector,
  sliceFileName,
} from "../Commons";
import enterMac from "../../assets/img/mac-enter.svg";
import enterWindows from "../../assets/img/windows-enter.svg";
import HiEdit1 from "../icons/HiEdit1";
import HiClose from "../icons/HiClose";
import HiMore from "../icons/HiMore";
import HiTrash from "../icons/HiTrash";
import HiImage from "../icons/HiImage";
import HiFile from "../icons/HiFile";

const Loading = () => {
  return <img src={loadingImage} className="spinner-reversed" />;
};

// Function to extract mentions from the editor's value
const extractMentions = (nodes) => {
  const mentions = [];

  const traverseNodes = (nodes) => {
    for (const node of nodes) {
      if (node.type === "mention") {
        mentions.push(node.character);
      }
      if (node.children) {
        traverseNodes(node.children);
      }
    }
  };

  traverseNodes(nodes);
  return mentions;
};

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }

  const children = node.children?.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "mention":
      return `<span data-mention="${node.character.fullName}" style="color: #1769F1; font-weight: 600;">@${node.character.fullName}</span>`;
    case "quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "list-item":
      return `<li>${children}</li>`;
    default:
      return children;
  }
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

let retrievedMentions = [];

let mentionRetrieved = false;

const RichTextEditor = ({
  initialValue,
  onClickAction,
  uid,
  shrinkable,
  isEditMode,
  isLoading,
  onCancel,
  readOnly,
  existingFiles,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );
  const [value, setValue] = useState(initialValue);
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [hasContent, setHasContent] = useState(false);
  const [characters, setCharacters] = useState(retrievedMentions);
  const [fileList, setFileList] = useState(existingFiles);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isFocused, setIsFocused] = useState(!!readOnly || isEditMode);
  const [isShownEditing, setIsShownEditing] = useState(isEditMode);
  const ref = useRef();
  const wrapperRef = useRef();
  const editorRef = useRef();

  useEffect(() => {
    editorRef.current = ReactEditor.toDOMNode(editor, editor);

    const handleScroll = () => {
      if (editorRef.current.scrollTop === 0) {
        setIsShownEditing(true);
      } else {
        setIsShownEditing(false);
      }
    };

    const scrollContainer = editorRef.current;
    scrollContainer.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!mentionRetrieved) {
      mentionRetrieved = true;
      getCharacterList();
    }
  }, [search]);

  const isShrinked = shrinkable && !isFocused;

  const getCharacterList = async () => {
    const response = await getMentionCharacters(uid);

    if (response.status === 200) {
      retrievedMentions = response.data.object;
      setCharacters(retrievedMentions);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await postFile(formData, handleUploadProgress);

    if (response.status === 200) {
      setFileList((prevState) => [...prevState, response.data?.object]);
    }
  };

  const removeFile = (hash) => {
    setFileList((prevState) => [
      ...prevState.filter((file) => file.hash !== hash),
    ]);
  };

  const handleUploadProgress = (progress) => {
    setUploadProgress(progress);
  };

  const chars = characters
    .filter((c) => c?.fullName.toLowerCase().startsWith(search.toLowerCase()))
    .slice(0, 10);

  const onKeyDown = useCallback(
    (event) => {
      const isMac = detectOperatingSystem() === "Mac";
      const isCtrlEnter = event.ctrlKey && event.key === "Enter";
      const isCmdEnter = event.metaKey && event.key === "Enter";

      if ((isMac && isCmdEnter) || (!isMac && isCtrlEnter)) {
        event.preventDefault();
        postMessage();
        return;
      }
      if (target && chars.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, chars[index]);
            setTarget(null);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
    },
    [chars, editor, index, target]
  );

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  const handleExtractMentions = () => {
    const mentions = extractMentions(value);
    const idList = mentions.map((mention) => mention?.uid);
    return idList;
  };

  const handleChange = (newValue) => {
    setValue(newValue);

    const content = newValue
      .map((n) => Node.string(n))
      .join("")
      .trim();
    setHasContent(content.length > 0 || !!fileList.length);

    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: "word" });
      const before = wordBefore && Editor.before(editor, wordBefore);
      const beforeRange = before && Editor.range(editor, before, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = afterText.match(/^(\s|$)/);

      if (beforeMatch && afterMatch) {
        setTarget(beforeRange);
        setSearch(beforeMatch[1]);
        setIndex(0);
        return;
      }
    }

    setTarget(null);
  };

  const renderShortCut = () => {
    const operatingSystem = detectOperatingSystem();
    if (operatingSystem === "Mac") {
      return (
        <>
          <span className="enter-shortcut">Send to</span> <img src={enterMac} />
        </>
      );
    } else {
    }
    return (
      <>
        Send to <img src={enterWindows} />
      </>
    );
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const postMessage = () => {
    const mentions = handleExtractMentions();
    const messageHTML = value.map((node) => serialize(node)).join("");
    const hashList = fileList.map((file) => file?.hash);
    onClickAction(messageHTML, mentions, hashList, value);
  };

  const renderActions = () => {
    if (!isEditMode) {
      return (
        <div className="hub-rte-action">
          <MainButton
            onClick={postMessage}
            className="primary"
            style={{
              minWidth: "72.5px",
            }}
            icon={isLoading ? <Loading /> : <HiSend />}
            label={isLoading ? "" : "Send"}
            disabled={!hasContent && !readOnly}
          />
        </div>
      );
    } else {
      return (
        <div className="hub-rte-action">
          <MainButton
            onClick={() => onCancel(value)}
            className="secondary"
            style={{
              minWidth: "72.5px",
            }}
            icon={<HiClose />}
            label="Cancel"
          />
          <MainButton
            onClick={postMessage}
            className="primary"
            style={{
              minWidth: "72.5px",
            }}
            icon={isLoading ? <Loading /> : <HiSend />}
            label={isLoading ? "" : "Update"}
            disabled={!hasContent && !readOnly}
          />
        </div>
      );
    }
  };

  const renderShrinkedActions = () => {
    if (isShrinked && !isEditMode) {
      return (
        <div
          className="hub-rte-action"
          style={{
            right: "15px",
            transform: "translate(0, -50%)",
            top: "50%",
            zIndex: 10,
          }}
        >
          <MainButton
            onClick={postMessage}
            className="primary"
            style={{
              minWidth: "72.5px",
            }}
            icon={isLoading ? <Loading /> : <HiSend />}
            label={isLoading ? "" : "Send"}
            disabled={!hasContent && !readOnly}
          />
        </div>
      );
    } else if (isShrinked && isEditMode) {
      return (
        <div
          className="hub-rte-action"
          style={{
            right: "15px",
            transform: "translate(0, -50%)",
            top: "50%",
            zIndex: 10,
          }}
        >
          <MainButton
            onClick={postMessage}
            className="primary"
            iconLineColor="#fff"
            style={{
              minWidth: "72.5px",
            }}
            icon={isLoading ? <Loading /> : <HiSend />}
            label={isLoading ? "" : "Update"}
            disabled={!hasContent && !readOnly}
          />
        </div>
      );
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsFocused(false);
    }
  };

  return (
    <div
      onFocus={handleFocus}
      className={`hub-rte-wrapper ${isFocused ? "focused" : ""}`}
      style={{
        height: isShrinked ? "70px" : "auto",
        pointerEvents: readOnly ? "none" : "all",
      }}
      ref={wrapperRef}
    >
      <Slate
        editor={editor}
        initialValue={initialValue}
        value={value}
        onChange={handleChange}
      >
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Write an update to share..."
          renderPlaceholder={({ children, attributes }) => {
            attributes.style.opacity = 1;
            return (
              <div {...attributes}>
                <p
                  className={`hub-rte-placeholder ${
                    isFocused ? "focused" : ""
                  }`}
                  style={{ margin: isEditMode ? "25px 0 0 0" : "10px 0 0 0" }}
                >
                  {children} {!isFocused && renderShortCut()}
                </p>
              </div>
            );
          }}
          spellCheck
          autoFocus={isEditMode}
          onKeyDown={onKeyDown}
          className={`hub-rte-editable ${isShrinked ? "shrinked" : ""}`}
          style={{
            padding: isEditMode && isFocused ? "30px 15px 15px 15px" : "15px",
            color: readOnly ? "#DADCE0" : "#051530",
          }}
        />
        {target && chars.length > 0 && (
          <Portal>
            <div
              ref={ref}
              style={{
                top: "-9999px",
                left: "-9999px",
                position: "absolute",
                zIndex: 20,
              }}
              data-cy="mentions-portal"
            >
              <TooltipWithPage
                style={{
                  width: "233px",
                  transform: "translate(-10px,-10px)",
                }}
                arrowLocaiton={{ marginLeft: "10px" }}
              >
                <div className="hub-rte-mention-options">
                  {chars.map((char, i) => (
                    <div
                      key={char?.uid}
                      onClick={() => {
                        Transforms.select(editor, target);
                        insertMention(editor, char);
                        setTarget(null);
                      }}
                      className={`hub-rte-mention-item ${
                        i === index ? "selected" : ""
                      }`}
                      style={{
                        borderBottom:
                          i === chars.length - 1
                            ? "none"
                            : "1px solid var(--hub50, #E8EFF9)",
                      }}
                    >
                      {char?.fullName}
                    </div>
                  ))}
                </div>
              </TooltipWithPage>
            </div>
          </Portal>
        )}

        {!isShrinked && (
          <>
            {!!fileList?.length && (
              <FileList files={fileList} removeFile={removeFile} />
            )}
            <Toolbar className="hub-rte-toolbar">
              <MarkButton
                format="bold"
                icon={
                  <HubIcon fontSize={20} lineColor="#647795">
                    <HiBold />
                  </HubIcon>
                }
              />
              <MarkButton
                format="italic"
                icon={
                  <HubIcon fontSize={20} lineColor="#647795">
                    <HiItalic />
                  </HubIcon>
                }
              />
              <MarkButton
                format="underline"
                icon={
                  <HubIcon fontSize={20} lineColor="#647795">
                    <HiUnderline />
                  </HubIcon>
                }
              />

              <BlockButton
                format="bulleted-list"
                icon={
                  <HubIcon fontSize={20} lineColor="#647795">
                    <HiBulletList />
                  </HubIcon>
                }
              />
              <BlockButton
                format="numbered-list"
                icon={
                  <HubIcon fontSize={20} lineColor="#647795">
                    <HiNumberList />
                  </HubIcon>
                }
              />

              <UploadButton
                uploadProgress={uploadProgress}
                uploadFile={uploadFile}
                readOnly={readOnly}
              />
              {renderActions()}
            </Toolbar>
          </>
        )}
        {renderShrinkedActions()}
      </Slate>

      {isEditMode && isFocused && isShownEditing && (
        <div className="edit-label">
          <HubIcon fontSize={16} lineColor="#647795">
            <HiEdit1 />
          </HubIcon>
          <p>Editing</p>
        </div>
      )}
    </div>
  );
};

const withMentions = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === "mention" || markableVoid(element);
  };

  return editor;
};

const insertMention = (editor, character) => {
  const mention = {
    type: "mention",
    character,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };

  switch (element.type) {
    case "mention":
      return (
        <Mention
          attributes={attributes}
          children={children}
          element={element}
        />
      );
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      className="hub-rte-button"
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      className="hub-rte-button"
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const style = {
    outline: selected && focused ? "1px solid #176af12a" : "none",
  };
  // See if our empty text child has any styling marks applied and apply those
  if (element.children[0].bold) {
    style.fontWeight = "bold";
  }
  if (element.children[0].italic) {
    style.fontStyle = "italic";
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character?.fullName.replace(" ", "-")}`}
      style={style}
      className="hub-rte-mention-badge"
    >
      <span className="mention-icon">
        <HubIcon fontSize={15} lineColor="#1769F1">
          <HiMention />
        </HubIcon>
      </span>

      {element.character?.fullName}
      {children}
    </span>
  );
};

const FileList = ({ files, removeFile }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShownMore, setIsShownMore] = useState(false);

  const menuRef = useRef();

  useEffect(() => {
    if (files?.length > 5) {
      setIsShownMore(true);
    } else {
      setIsShownMore(false);
    }
  }, [files]);

  const fileList = files?.map((file, index) => {
    return (
      <FileBadge
        key={file.fileName + index}
        isImage={imageDetector(file)}
        fileName={`${sliceFileName(file?.fileName, 11)}${file.extension}`}
        hash={file.hash}
        onDeleteFile={removeFile}
        editable
      />
    );
  });

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    window.addEventListener("mousedown", handleClickOutside);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    window.removeEventListener("mousedown", handleClickOutside);
  };

  const handleClickOutside = (event) => {
    if (menuRef && !menuRef.current?.contains(event?.target)) {
      closeMenu();
    }
  };

  const More = () => {
    return (
      <div className="file-more-wrapper">
        <MainButton
          iconLineColor="#A5BDE5"
          iconSize={14}
          className="file-settings"
          icon={<HiMore />}
          onClick={toggleMenu}
        />

        {isMenuOpen && (
          <div className="file-options-menu" ref={menuRef}>
            {files.map((file, index) => (
              <>
                <div key={file?.hash} className="file-options-menu-item">
                  {imageDetector(file) ? (
                    <HubIcon lineColor="#1769F1" fontSize={15}>
                      <HiImage />
                    </HubIcon>
                  ) : (
                    <HubIcon lineColor="#1769F1" fontSize={15}>
                      <HiFile />
                    </HubIcon>
                  )}
                  <span>
                    {sliceFileName(file?.fileName, 15)}
                    {file.extension}
                  </span>
                  <span
                    className="delete-icon"
                    onClick={() => removeFile(file.hash)}
                  >
                    <HubIcon fontSize={14} lineColor="#0E3F91">
                      <HiTrash />
                    </HubIcon>
                  </span>
                </div>
                {files.length !== index + 1 && (
                  <div className="file-menu-divider" />
                )}
              </>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="hub-rte-file-list">
      <div className="file-list-wrapper">
        {isShownMore ? (
          <>
            {fileList.slice(0, 6)}
            <More />
          </>
        ) : (
          fileList
        )}
      </div>
    </div>
  );
};

export default RichTextEditor;

import React, { Component } from 'react';
import GaugeChart from "./GaugeChart";
import Auth from '../../auth/Auth';
import LoadingScreen from '../../common/statusScreen/loadingScreen';
class GaugeChartRunner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            noContent:false,
            data:[],
           

        }
       
    }

    componentDidMount() {
        this.generateData();
    }
    
        
    componentDidUpdate(prevProps, prevState) 
    { 
   
        if(prevProps.counter!==this.props.counter)
        {
          
            this.generateData();
        }

    } 
        
         


    generateData = () => {

        if (this.props.sampleData) {
            this.setState({...this.state, loading:false, data:this.props.sampleData, rawData:this.props.sampleData});
        } else {

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
            };


            let filterString=""
            //console.log("generateData",this.props.selectedFilters)
            if(this.props.selectedFilters.length>0 || this.props.multiplexingFilter)
            {
                filterString+="?"

                this.props.selectedFilters.map((f,i)=>
                {
                   
                    filterString+=(i!==0?"&":"")+f.dataKey+"="+f.value.toString()
                })

                
                if(this.props.multiplexingFilter)
                {
                    filterString+= (this.props.selectedFilters.length>0?"&":"")+this.props.multiplexingAttribute+"="+this.props.multiplexingFilter.toString()

                }
            }
    
            fetch("/api/query/code/"+this.props.config.query+filterString, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
             
                .then(r => {
                    //console.log("gauge",r)
                    if(r.status===200 ) {   
                        this.setState({...this.state, loading:r.body.object!==null?false:true, noContent:r.body.object===null?true:false, data:r.body.object!==null?r.body?.object[0]:[]});
                    }
                    else if(r.status===401) {
                        window.location.replace("/login");
                    }
                    else if(r.status===403) {
                        this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else {
                        this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });
        }
    }


    render() {
        return <>
        {(this.state.loading?<div style={{position:"relative", height:"100px"}}><LoadingScreen noContent={this.state.noContent}/></div>:<GaugeChart min={0} max={200} mid={100} value={40} data={this.state.data} config={this.props.config} />)} 
      </>
    }
}

export default GaugeChartRunner;
import React, { Component } from 'react'
import { DragDropContext} from 'react-beautiful-dnd';
import Auth from '../../auth/Auth';
import Column from './column.js'
import Toolbar from './Toolbar.js'
import './taskBoard.css'
import SideBar from './../../common/dataTable/SideBar';
import {Utils} from './../../common/Commons';
import TaskBoardSidebarContainer from './sideBar/TaskBoardSidebarContainer'
import ColumnHeader from './ColumnHeader'

import '../../common/spinner.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEmptySet,faSpinner} from '@fortawesome/pro-duotone-svg-icons'
export default class index extends Component {
constructor(props) {
  super(props)

  this.state = {
    Loading:true,
    LoadingError:false,
    LoadingErrorText:"",
    isSideBarOpen: false,
    columns:[],
    rawColumns:[],
    filteredColumns:[],
    columnOrder:[],
     task:{
       stage:{}
     },
      task2:{
      
     },
     searchString: "",
     selectedRecord:{},
     selectedOBS:{},
     OBSList:[],
     selectedCard:{},
     collapse:false,
     description:false,
     isNewTask:false,

     selectedFilter:[],
     sideBarType:"detail",
     boardType:"Task"
  }
}

    toggleSidebar = () => {
      this.setState({...this.state, isSideBarOpen: !this.state.isSideBarOpen});
    }

    openSidebar = (type, tab=null) => {
      this.setState({...this.state, isSideBarOpen: true, sideBarTab:tab,  isNewTask:type==="new"?true:false, sideBarType:type}, () => { this.balanceBoardWrapperScollbar() });
    }

    balanceBoardWrapperScollbar = () => {
      if(this.state.selectedRecord?.columnIndex && this.state.isSideBarOpen) {
        let sleft = document.getElementById("hub-board-wrapper").scrollLeft;
        let swidth = document.getElementById("hub-board-wrapper").offsetWidth;

        if (((this.state.selectedRecord?.columnIndex+1)*340 + sleft) > swidth) {
          document.getElementById("hub-board-wrapper").scrollLeft = (this.state.selectedRecord?.columnIndex+1)*340 - swidth;
        }
      }
    }


    toggleCollapse = () =>{
      this.setState({...this.state, collapse: !this.state.collapse});
    }

     toggleDescription = () =>{
      this.setState({...this.state, description: !this.state.description});
    }

    selectedRecord=(record,columnIndex,cardIndex)=>{     
      Object.assign(record,{columnIndex:columnIndex,cardIndex:cardIndex})
      this.setState({...this.state, sideBarTab:"task", selectedRecord: record, selectedCard:record}, () => { this.balanceBoardWrapperScollbar() });
    }


    toggleBlock = (attr,value,id,columnIndex,TaskIndex) =>{

        let newColumns=this.state.columns;
        let task=newColumns[columnIndex].task[TaskIndex]
        task[attr]=value;
        newColumns[columnIndex].task[TaskIndex][attr]=value;
        
        this.setState({...this.state,columns:newColumns})

    }

    componentDidMount() {  
      this.getData(this.props.selectedOBS,this.props.startDate,this.props.finishDate);
    }

    componentDidUpdate(prevProps) {
      if(prevProps.selectedOBS.uid!==this.props.selectedOBS.uid || prevProps.view!==this.props.view || prevProps.startDate!==this.props.startDate  || prevProps.finishDate!==this.props.finishDate)
      {
        this.getData(this.props.selectedOBS,this.props.startDate,this.props.finishDate);
      }
    }


    getData = (obs, startDate, finishDate) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };
        this.setState({...this.state, Loading:true});
        let serchString=""

        if(obs!==undefined || (startDate!==undefined && startDate!=="") || (finishDate!==undefined && finishDate!==""))
        {
          serchString+="?"
        }

        if(obs!==undefined )
        {
          //serchString.concat('obsId='+obs.uid.toString());
           serchString+='obsId='+obs.uid?.toString();
         // serchString='?obsId='+obs.uid;
        }
        /**/
         if(startDate!==undefined && startDate!=="")
        {
         // serchString+='&startDate='+startDate;
         // serchString='?obsId='+obs.uid;
        }
         if(finishDate!==undefined && finishDate!=="")
        {
         // serchString+='&finishDate='+finishDate;
         // serchString='?obsId='+obs.uid;
        }
        
     
        fetch('/api/task/stage'+serchString, requestOptions)
        
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
             
              if(r.body.status===200) {
                  let columnOrder=[];
                 // console.log("r.body.object",r.body.object)
                  r.body.object.map((val)=>{columnOrder.push(val.code)});
                  this.setState({...this.state, Loading:false, LoadingError:false,columns:r.body.object,/**/rawColumns:Object.create(r.body.object),columnOrder:columnOrder,selectedOBS:r.body.object[0].resourceOBS ,OBSList:r.body.object[0].obsList});
              } else if(r.body.status==401) {
                    window.location.replace("/login");
              } else if(r.status==403) {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
              } else {
                    this.setState({...this.state, Loading:false, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
              }

            });

      }

      updateStage = (taskId,Stage) => {

        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
            body: JSON.stringify(Stage)
        };

        fetch('/api/task/'+taskId, requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                if(r.body.status===200) {
                
                }
                else if(r.body.status==401) {
                    window.location.replace("/login");
                }
                else if(r.body.status==403) {
                    this.setState({...this.state,  Loading:false, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else if(r.body.status==400) {
                    this.setState({...this.state,  Loading:false, LoadingError: true, ErrorMessage:r.body.message});
                }
                else {
                    console.log(r.body.message);
                    this.setState({...this.state,  Loading:false, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });

      }

     handleDragEndCreator = (taskId,destination) => {
        let newDragObject={}
        
        newDragObject.draggableId=taskId
        newDragObject.combine=null
        newDragObject.mode="FLUID"
        newDragObject.reason="DROP"
        newDragObject.type="DEFAULT"
        newDragObject.destination=destination

        let columns=this.state.columns

        for(let i=0; i< columns.length; i++)
        {
          if(columns[i].task.findIndex(x=>x.uid===taskId)!==-1)
          {
            newDragObject.source={index:columns[i].task.findIndex(x=>x.uid===taskId),droppableId:columns[i].code}
            break;
          }
        }


        //console.log("handleDragEndCreator",taskId,newDragObject)
        this.handleDragEnd(newDragObject);

       }


    handleDragEnd=(result)=>
    {

      //console.log("handleDragEnd",result)
      if(result.type==="card")
      {
        
        if (!result.destination) return;
          
        
         let newColumns=this.state.columns;


         // if: Aynı kolondaki sıralama. Else:diğer kolon. Aynı kolon sıralamaya gerek olmayabilir. Sıralama için task objesinde alan gerekecek
        if(result.destination.droppableId===result.source.droppableId)
        {
          
          //  console.log(this.state.columnOrder.indexOf(result.destination.droppableId));
            let columnIndex=this.state.columnOrder.indexOf(result.destination.droppableId)

            let [removed] = newColumns[columnIndex].task.splice(result.source.index, 1);
            newColumns[columnIndex].task.splice(result.destination.index, 0, removed);
        
         }
         else
         {
            let destColumnIndex=this.state.columnOrder.indexOf(result.destination.droppableId)
            let sourceColumnIndex=this.state.columnOrder.indexOf(result.source.droppableId)
             
            let [removed] = newColumns[sourceColumnIndex].task.splice(result.source.index, 1);
            let removedTaskResource=newColumns[sourceColumnIndex].taskResource?.find(x => x.taskId === parseInt(result.draggableId));
             
            let deletedTaskResourceIndex=newColumns[sourceColumnIndex].taskResource.findIndex(object => {
                return object.taskId === removedTaskResource.taskId;
              });

            newColumns[sourceColumnIndex].taskResource.splice(deletedTaskResourceIndex,1)
            newColumns[destColumnIndex].task.splice(result.destination.index, 0, removed);

            newColumns[destColumnIndex].taskResource.push(removedTaskResource);

            let taskId=parseInt(result.draggableId);

            let task={};
            task.stage=newColumns[destColumnIndex].boardColumns;
            
            this.updateStage(taskId,task);

         }
        
        
        //console.log(newColumns[0].tasks)

        this.setState({...this.state,columns:newColumns})
      }

      if(result.type==="todo" && result.destination.droppableId===result.source.droppableId)
      {

        let cardIndex=parseInt(result.destination.droppableId.slice(result.destination.droppableId.indexOf('-') + 1))
        let colIndex=parseInt(result.destination.droppableId.substring(0,result.destination.droppableId.indexOf('-') ))

        let newColumns=this.state.columns;

        let todoArray=newColumns[colIndex].task[cardIndex].toDo.slice()
        

       // todoArray.splice(result.destination.index, 0, todoArray[result.source.index])

       this.swapPositions(todoArray, result.source.index, result.destination.index)
        //console.log("todoArray",todoArray, " newtodoArray ")
        todoArray.map((t,i)=>{
          return t.orderNumber=i+1
        })
        let todo=todoArray[result.destination.index]
        newColumns[colIndex].task[cardIndex].toDo=todoArray
        
        this.setState({...this.state,columns:newColumns},this.toDoOperations ("PATCH",todo.uid,todo,colIndex,cardIndex, result.destination.index) )
        

      }
    }

    swapPositions = (array, from ,to) => 
    {
      array.splice(to, 0, array.splice(from, 1)[0]);
      return array;
    }


    
    


    toggleSelected=(selected)=>
    {
      //console.log(selected);
      this.setState({...this.state,selectedOBS:selected, Loading:true},this.getData(selected));
      
    }


    toDoOperations = (method,Id,object,ColumnIndex,taskIndex, todoIndex) => {
            //console.log("toDoOperations",method,Id,object,taskIndex,ColumnIndex, todoIndex)
            //console.log(JSON.stringify(this.state.formData))
            let baseUrl="";
            switch(method) {
                case "POST":
                  baseUrl='/api/todo'
                  break;
                case "PATCH":
                  baseUrl='/api/todo/'+Id
                  break;
                  case "DELETE":
                  baseUrl='/api/todo'
                  
                  break;
               
              }


              if(method==="DELETE")
              {
                    object={
                    ids: [Id]
                  }
              }
              // console.log(baseUrl)

            const requestOptions = {
                method: method,
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
                body: JSON.stringify(object)
            };

            fetch(baseUrl, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {


                     //console.log(requestOptions);
                      //console.log(r);
                    if(r.status===201 && method==="POST") {
                     // console.log("oluşturuldu");
                  

                      let newColumns=this.state.columns;

                      newColumns[ColumnIndex].task[taskIndex].toDo.push(r.body.object);

                     // console.log("POST",newColumns[ColumnIndex].task[taskIndex] )

                      this.setState({...this.state,columns:newColumns})

                    
                
                    }
                     else if(r.status===200 && method==="PATCH" ) {
                        
                        let newColumns=this.state.columns;

                       // console.log(todoIndex)
                        //console.log(object)

                        newColumns[ColumnIndex].task[taskIndex].toDo[todoIndex]=object;
                         this.setState({...this.state,columns:newColumns})
                    }

                     else if(r.status==200 && method==="DELETE" ) {
                        
                        let newColumns=this.state.columns;

                        //console.log(todoIndex)
                        //console.log(object)

                        newColumns[ColumnIndex].task[taskIndex].toDo.splice(todoIndex, 1);
                         this.setState({...this.state,columns:newColumns})
                    }
                    else if(r.status==401) {
                        window.location.replace("/login");
                    }
                    else if(r.status==403) {
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else if(r.status==400) {
                      //console.log("hata");
                        //this.setState({...this.state,Status: "Error", Error: true, ErrorMessage:r.body.message});
                    }
                    else {
                        //console.log(r.body.message);
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });

    }





    
    assignmentOperations = (method,columnIndex,taskId,assignmentId,assignmentIndex,object) => {
          // console.log(method,columnIndex,taskId,assignmentId,assignmentIndex,object)


            let baseUrl="";
            switch(method) {
                case "POST":
                  baseUrl='/api/assignment'
                  break;
                case "PATCH":
                  baseUrl='/api/assignment/'+assignmentId
                  break;
                  case "DELETE":
                  baseUrl='/api/assignment'
                  
                  break;
               
              }


              if(method==="DELETE")
              {
                    object={
                    ids: [assignmentId]
                  }
              }


              const requestOptions = {
                method: method,
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
                body: JSON.stringify(object)
            };

            fetch(baseUrl, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {
                    if(r.status===201 && method==="POST") {
                      //console.log("oluşturuldu",r.body.object);
                  

                      let newColumns=this.state.columns;

                      
         
                        newColumns[columnIndex].taskResource.find(x => x.taskId === taskId).assignmentList.push(r.body.object);


                      this.setState({...this.state,columns:newColumns})

                    
                
                    }
                     else if(r.status==200 && method==="PATCH" ) {
                        
                        let newColumns=this.state.columns;

                       // console.log(todoIndex)
                        //console.log(object)
                      let assignment=newColumns[columnIndex].taskResource.find(x => x.taskId === taskId).assignmentList.find(y=>y.uid===assignmentId)
                        assignment.remainingUnit=object.remainingUnit;
                        assignment.role=object.role;
                        //console.log("patch",object,newColumns[columnIndex].taskResource.find(x => x.taskId === taskId).assignmentList[assignmentIndex])

                         this.setState({...this.state,columns:newColumns})
                    }

                     else if(r.status==200 && method==="DELETE" ) {
                        
                        let newColumns=this.state.columns;

                        //console.log(todoIndex)
                        //console.log(object)

                        
                        newColumns[columnIndex].taskResource.find(x => x.taskId === taskId).assignmentList.splice(assignmentIndex, 1);



                         this.setState({...this.state,columns:newColumns})
                    }
                    else if(r.status==401) {
                        window.location.replace("/login");
                    }
                    else if(r.status==403) {
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else if(r.status==400) {
                      console.log("hata");
                        //this.setState({...this.state,Status: "Error", Error: true, ErrorMessage:r.body.message});
                    }
                    else {
                        console.log(r.body.message);
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });

          
           
           // console.log(this.state.columns[columnIndex].taskResource.find(x => x.taskId === taskId).assignmentList[assignmentIndex]);
           //this.setState({...this.state,columns:newColumns})
    }


    updateRecord = async (method,endpoint,data) => {
          //console.log("data")
            //console.log(JSON.stringify(this.state.formData))

          //  this.handleDragEndCreator(1025,{stage:{uid:2}});
            const requestOptions = {
                method: method,
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
                body: JSON.stringify(data)
            };

            await fetch(endpoint, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {

                    if(r.status===200) {
                 
                      let newColumns=this.state.columns;
                      newColumns[data.columnIndex].task[data.cardIndex]=data;
                      this.setState({...this.state, columns: newColumns});
                      this.notify("success","Updated")
                
                    } else if(r.status===201) {

                      let newColumns=this.state.columns;

                      let task=r.body.object;



                      Object.assign(task,{toDo:[]})

                      let taskResource={taskId:task.uid,resourceList:null,assignmentList:[]}

                      newColumns[0].task.unshift(r.body.object);
                      newColumns[0].taskResource.push(taskResource);
                      //console.log("taskId",task.uid)
                    //  console.log("newcolumns",newColumns)

                      this.setState({...this.state, columns: newColumns});
                      this.toggleSidebar()
                      this.notify("success","Created")
                     }
                    else if(r.status==401) {
                        window.location.replace("/login");
                    }
                    else if(r.status==403) {
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else if(r.status==400) {
                        this.setState({...this.state,Status: "Error", Error: true, ErrorMessage:r.body.message});

                        this.notify("error",r.body.message)
                    }
                    else {
                        console.log(r.body.message);
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });


    }

  notify = (type,message) => { const u = new Utils();  u.addNotification(type, message) };

  setSearchString = (v) => {this.setState({...this.state, searchString:v})}


  blockTask = (uid,data,columnIndex,cardIndex,stage) => {
          //console.log("blockTask",uid,data)
            //console.log(JSON.stringify(this.state.formData))

            const requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
                body: JSON.stringify(data)
            };

            fetch('api/task/'+uid+'/block', requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {

                    if(r.status===200) {
                 

                     let newColumns=this.state.columns;

                     if(!data.isBlocked)
                     {

                       // console.log("data",data,stage,newColumns,newColumns.find(x=>x.code===stage))
                        let task=newColumns.find(x=>x.code===stage).task.find(y=>y.uid===uid)

                       //console.log("task",task)
                        task.isBlocked=false
                        task.blockResolveDescription=data.blockResolveDescription
                        task.blockResolveBy=Auth.getResourceObject()
                        task.blockResolveDate=Date.now();
                        this.setState({...this.state, columns: newColumns});

                         this.notify("success","Block Resolved")
                     }


                      if(data.isBlocked)
                     {

                       // console.log("data",data,stage,newColumns,newColumns.find(x=>x.code===stage))
                        let task=newColumns.find(x=>x.code===stage).task.find(y=>y.uid===uid)

                       //console.log("task",task)
                        task.isBlocked=true
                        task.blockDescription=data.blockDescription
                        task.blockReason=data.blockReason
                        task.blockOwner=data.blockOwner
                        task.blockCreatedDate=Date.now();
                        task.blockResolveDescription=null
                        this.setState({...this.state, columns: newColumns});

                        this.notify("success","Blocked")
                     }

                      //let task=r.body.object;
                      //console.log(data.columnIndex,data.cardIndex);
                      //newColumns[data.columnIndex].task[data.cardIndex]=data;
                    }
                   
                    else if(r.status==401) {
                        window.location.replace("/login");
                    }
                    else if(r.status==403) {
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else if(r.status==400) {
                        this.setState({...this.state,Status: "Error", Error: true, ErrorMessage:r.body.message});

                        this.notify("error",r.body.message)
                    }
                    else {
                        console.log(r.body.message);
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });

    }


    setFavorite=(taskObject,favoriteStatus,columnIndex,taskIndex)=>
    {
      //console.log("setFavorite",taskObject,favoriteStatus,columnIndex,taskIndex)

      const requestOptions = {
        method: favoriteStatus?"POST":"DELETE",
        headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
        body: JSON.stringify(taskObject)
      };

      let delString=""

      if(!favoriteStatus)
      {
        delString="/"+taskObject.objectUid
      }

      fetch('api/favorites'+delString, requestOptions)
          .then(response => response.json().then(data => ({status:response.status, body:data})))
          .then(r => {
            console.log(r)

              if(r.status===201 || r.status===200) {
          
              
                  let newColumns=this.state.columns;
                  newColumns[columnIndex].task[taskIndex].isFavorite=favoriteStatus
                  this.setState({...this.state, columns: newColumns});
                            
              }
            
              else if(r.status===401) {
                  window.location.replace("/login");
              }
              else if(r.status===403) {
                  this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
              }
              else if(r.status===400) {
                  this.setState({...this.state,Status: "Error", Error: true, ErrorMessage:r.body.message});

                  this.notify("error",r.body.message)
              }
              else {
                  console.log(r.body.message);
                  this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
              }
          });
    }

  setCollapsed=(index)=>{ 

      let newColumns=this.state.columns;

      
      newColumns[index].boardColumns.isCollapsed=!(newColumns[index].boardColumns.isCollapsed);


      
      this.setState({...this.state, columns:newColumns})


  }


  setFilter =(type)=>
  {
    //console.log("type",type)
   // console.log("rawColumns",this.state)
   // let newColumns=[...this.state.rawColumns];


   //Bunlar reference oluyor state de değişiyor
    //const newColumnsA=[...this.state.rawColumns];
    //let newColumnsA=[...this.state.rawColumns];
    
    //Bu doğru çalışıyor
   // console.log("length",this.state.rawColumns.length)
    const newColumnsA = this.state.rawColumns.length!==0?this.state.rawColumns.map(item => ({...item})):this.state.columns.map(item => ({...item}))

    //[].concat(arr);
    //console.log("newColumns",newColumnsA)
    let array=[];
    let task={};
    let selectedFilter=this.state.selectedFilter;

    //console.log("findIndex",selectedFilter.findIndex(x=>x.id===type))

    if(selectedFilter.findIndex(x=>x.id===type)===-1)
    {
      selectedFilter.push({id:type})
    }
    else
    {
      selectedFilter.splice(selectedFilter.findIndex(x=>x.id===type),1)
    }

    //console.log("selectedFilter",selectedFilter)
    //Object.assign(newColumns,{boardColumns:[]})
   // console.log("newColumns",newColumns)
    selectedFilter.map((filter)=>
    {

   
            newColumnsA.map((col,i)=>{

              array=[];

              if(filter.id===1)
              {
                col.taskResource.map((data)=>
                {

                  //console.log("data",data)
                  //console.log("assignmentList",data.assignmentList.length)
                    for(let i=0; i<data.assignmentList.length; i++)
                    {
                      if(parseInt(Auth.checkResourceId())===parseInt(data.assignmentList[i]?.owner?.uid))
                      {
                        //console.log("task",(col.task.find(x => x.uid === (data.taskId))))
                        //console.log("data.taskId",data.taskId)
                        task=(col.task.find(x => x.uid === (data.taskId)));

                        if(task!==undefined)
                        {
                          array.push(task);
                        }
                        
                        break;
                      }
                    
                    }

                  

                })

              }

              if(filter.id===2)
              {
                col.task.map((data)=>
                {

                  //console.log("data",data)
                  //console.log("assignmentList",data.assignmentList.length)
                  
                      if(data.isBlocked)
                      {
                        //console.log("task",data)
                        //console.log("data.taskId",data.taskId)
                        array.push(data);
                      
                      }
                    
                })

              }
              if(filter.id===3)
              {
                col.task.map((data)=>
                {

                  //console.log("data",data)
                  //console.log("assignmentList",data.assignmentList.length)
                  
                      if(data.isFavorite)
                      {
                        //console.log("task",data)
                        //console.log("data.taskId",data.taskId)
                        array.push(data);
                      
                      }
                    
                })

              }
              newColumnsA[i].task=array;
               // Object.assign(newColumns[i],{task:array})
            })
    })
      //console.log("newColumns son",newColumnsA)

       //console.log("this.state son",this.state)
  this.setState({ 
    ...this.state,
    columns:newColumnsA,
    rawColumns:this.state.rawColumns.length===0?this.state.columns:this.state.rawColumns,
    selectedFilter:selectedFilter
  
  })
  }

  render() {

    //console.log("state",this.state)
      if(this.state.Loading)
       {
         return(
        <div style={{display:"flex",flexDirection:"column", position:"absolute",left:"50%",top:"50%",fontWeight:"bold",transform:"translate(-50%,-50%)"}}>
                                                
        <FontAwesomeIcon icon={faSpinner} color={"#D7EDFF"}   size={"4x"} className="spinner2" />
        <br/>Loading...
       </div>
       )
       }
       else if(this.state.LoadingError)
       {
        return  <div>{this.state.LoadingText}</div>
       }
       else{
         return (
           <>
            <div className='board-page' >
              <div className='board-toolbar'>
                  <Toolbar 
                    hideGanttbar = {this.state.hideGanttbar} 
                    toggleGanttbar = {this.toggleGanttbar} 
                    setViewMode={this.setViewMode} 
                    refresh={()=>this.getData(this.props.selectedOBS,this.props.startDate,this.props.finishDate)} 
                    isSideBarOpen = {this.state.isSideBarOpen}
                    sideBarType={this.state.sideBarType}
                    openSidebar = {this.openSidebar}  
                    toggleSidebar = {this.toggleSidebar} 
                    applyVisibleColumns={this.applyVisibleColumns} 
                    isFilterOpen={this.state.isFilterOpen} 
                    config={this.state.config} 
                    toggleFilter ={this.toggleFilter}
                    selected={this.state.selectedOBS}
                    options={this.state.OBSList} 
                    searchString={this.state.searchString}
                    setSearchString={this.setSearchString}
                    toggleSelected={this.toggleSelected} 
                    toggleCollapse={this.toggleCollapse} 
                    collapse={this.state.collapse}
                    toggleDescription={this.toggleDescription}
                    description={this.state.description}
                    setFilter={this.setFilter}
                    selectedFilter={this.state.selectedFilter}
                    boardType={this.state.boardType}
                    />
              </div>
              <div className='board-area'>
                <div className="hub-board-wrapper" id="hub-board-wrapper">
                    <div className="columns" style={{minWidth: ((this.state.columns?.length*345)+25), flex:1, overflow:"hidden"}}>                
                      <div className='board' style={{width: (this.state.columns?.length*345) }}>
                        <DragDropContext onDragEnd={this.handleDragEnd}>
                          { this.state.columns?.map((val,i)=>{
                            return <Column 
                            column = {val} 
                            searchString = {this.state.searchString}
                            columnIndex = {i} 
                            key = {val.code}
                            collapsed = {this.state.collapse} 
                            description = {this.state.description} 
                            selectRecord = {this.selectedRecord} 
                            openSidebar = {this.openSidebar} 
                            selectedCard = {this.state.selectedCard}  
                            toggleBlock = {this.toggleBlock}
                            toDoOperations = {this.toDoOperations} 
                            assignmentOperations={this.assignmentOperations}
                            blockTask={this.blockTask}
                            setFavorite={this.setFavorite}
                            />
                            })
                          }
                        </DragDropContext>
                      </div> 
                    </div>
                </div>
                    <SideBar title={this.state.sideBarType==="new"?"Create New Task":this.state.sideBarType==="block"?"Blocked Task List":""} parentRecord={this.state.selectedRecord} selectedRecord={this.state.selectedRecord} idAttribute={"uid"} nameAttribute={"name"} toggleSidebar = {this.toggleSidebar} isOpen = {this.state.isSideBarOpen} >
                        <TaskBoardSidebarContainer blockTask={this.blockTask} sideBarType={this.state.sideBarType}selectedRecord={this.state.selectedRecord}  sideBarTab={this.state.sideBarTab} save={this.updateRecord} isNewTask={this.state.isNewTask} updateStage={this.updateStage} handleDragEndCreator={this.handleDragEndCreator}/>  
                    </SideBar>
                </div>
            </div>
      </>
      )
      }
    
  }
}

import React, { Component } from "react";
import "./TsDeadline.css";
import calendarIcon from "./calendar.svg";

class TsDeadline extends Component {
  render() {
    return (
      <div className="hub-ts-deadline">
        {/*  <div className="hub-ts-deadline-icon"><img src={calendarIcon} /></div>
                <div className="hub-ts-deadline-label">30 May</div> */}
      </div>
    );
  }
}

export default TsDeadline;

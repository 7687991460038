import React, { Component } from 'react';

class ProjectTimeMetricMilestone extends Component {
    render() {
        return (
            <div className="container">
                <div className="hub-projectTile-milestone">
                    <div className="inner">
                        <div className="month" style={{color:this.props.color}}>{this.props.month}</div>
                        <div className="day" style={{color:this.props.color}}>{this.props.day}</div>
                    </div>
                </div>
                <div className="hub-projectTile-metricLabel" style={{marginLeft:'-8px', marginTop:'16px'}}>{this.props.label}</div>
            </div>
              
        
        );
    }
}

export default ProjectTimeMetricMilestone;
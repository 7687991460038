import React, { Component } from 'react'
import './block.css'
import Pagination from './Pagination';

export default class blockList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            searchString:"",
            filter:"blocked",
            page: 0,
            perPage: 20,
        }
    }
    setSearchString = (v) => { this.setState({...this.state, searchString:v}); }
    
    setFilter = (v) => { this.setState({...this.state, filter:v, page:0}); }

    setPage = (page) => { this.setState({...this.state, page:page}); }
        
    render() {

        let filteredData=this.props.data

        const blockedCount= filteredData.filter(x=>x.isBlocked===true).length;

        if(this.state.filter!=="" && this.state.filter!==null) {
            filteredData= filteredData.filter(x=>x.isBlocked===(this.state.filter==="blocked"))
        }

        let pagedData = filteredData?filteredData.slice(this.state.page*this.state.perPage, (this.state.page+1)*this.state.perPage):{};
                
        return (
            <div className='block-list-page'>
                <div className='summary'>
                    <div className='block-count-area'>
                        <div onDoubleClick={this.props.refresh} onClick={() => {this.setFilter("")}} 
                        className={"hub-dataTable-prebuiltFilter blue"} 
                        style={{whiteSpace: "nowrap", alignSelf:"center", border:(this.state.filter===""?"2px solid":"none")}}>
                            All ({this.props.data.length})
                        </div>
                        <div onClick={() => {this.setFilter("resolved")}} 
                        className={"hub-dataTable-prebuiltFilter green"} 
                        style={{whiteSpace: "nowrap", alignSelf:"center", border:(this.state.filter==="resolved"?"2px solid":"none")}}>
                            Resolved ({this.props.data.length - blockedCount})
                        </div>
                        <div onClick={() => {this.setFilter("blocked")}} 
                        className={"hub-dataTable-prebuiltFilter red"} 
                        style={{ whiteSpace: "nowrap", alignSelf:"center", border:(this.state.filter==="blocked"?"2px solid":"none")}}>
                            Blocked ({blockedCount})
                        </div>
                        {
                            /*
                            <div style={{marginLeft: "32px", justifySelf:"flex-end"}}>
                                <SearchArea onChange={this.setSearchString} value={this.state.searchString} />
                            </div>
                            */
                        }
                    </div>
                </div>

                <div className='hub-gantt-table-wrapper' style={{flexDirection:"column"}}>
                    <div>
                        <table width="95%">
                            <thead>
                                <tr>
                                    <th style={{width:"60%"}}>Name</th>
                                    <th style={{width:"20%"}}>Block Reason</th>
                                    <th style={{width:"20%"}}>Block Status</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div style={{flex: 1, overflow:"auto"}}>
                        <table width="95%">
                            <tbody>
                                {pagedData.sort((a, b) => !a.isBlocked  ? 1 : -1).map((t)=>
                                {
                                    return (((new RegExp(this.state.searchString, "i")).test(t.name))? <tr>
                                        <td style={{width:"60%",cursor:"pointer" , whiteSpace:"pre-line"}} onClick={()=>this.props.toggleShowDetails(t)}><a> {t.name} ({t.code}) </a> </td>
                                        <td style={{width:"20%",whiteSpace:"pre-line"}}>{t.blockReason?.name}</td>
                                        <td style={{width:"20%",whiteSpace:"pre-line"}}>{t.isBlocked?'Blocked':'Resolved'} </td>
                                    </tr>:false)
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div style={{paddingLeft: 12}}>
                    <Pagination setPage={this.setPage} totalRows={filteredData?filteredData.length:0} perPage={this.state.perPage} page={this.state.page}/>     
                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import PageNavigation from './../../../common/page-navigation';
import PageHeader from './../../../common/pageElements/pageHeader/'

import {
    Routes,
    Route,
  } from "react-router-dom";

import ChangeSetDetails from './details';
import ChangeSetActivities from './activities';
import Comments from './comments'

class ChangeSet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                {to:"details", id:"changeSetDetailsDetails", label:"Properties", icon: ""},
                {to:"activities", id:"changeSetDetailsActivities", label:"Activities", icon: ""},
                {to:"comments", id:"changeSetDetailsComments", label:"Comments", icon: ""},
            ],  
            changeSet: {},
            activePage:"details"
        }
    }
    render() {
        return (
            <>
            <PageHeader
                title={(this.state.changeSet?.name?this.state.changeSet.name:"")}
                left={<PageNavigation links = {this.state.links} selectedRecord={this.state.program} setSelectedRecord={this.props.setSelectedRecord} defaultPage={0} type={"changeSet"}/>}
                /> 
            <div className="hub-page" >
                <Routes>
                    <Route path="/details" element={<ChangeSetDetails  selectedRecord={this.getRecord}/>} />
                    <Route path="/activities" element={<ChangeSetActivities   selectedRecord={this.getRecord}/>} />
                    <Route path="/comments" element={<Comments   selectedRecord={this.getRecord}/>} />
                </Routes>
            </div>
            </>
        );
    }
}

export default ChangeSet;
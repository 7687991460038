export const skeletonSet = {
  label: { width: 80, height: 15, borderRadius: 6 },
  input: { width: "100%", height: 38, borderRadius: 6 },
  textArea: { width: "100%", height: 70, borderRadius: 6 },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    sm: {
      width: 28,
      height: 28,
    },
    md: {
      width: 40,
      height: 40,
    },
    lg: {
      width: 64,
      height: 64,
    },
  },
};

export const perPage = 25;

export const configCodes = {
  fileSize: "conversation_file_size",
};

import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

import Tab from '../../../common/tabs/Tab';
import TabGroup from '../../../common/tabs/TabGroup';

import TabContentGroup from '../../../common/tabs/TabContentGroup';
import TabContent from '../../../common/tabs/TabContent';
import TimesheetSideNotes from './TimesheetSideNotes'

class TimesheetNotesSidebar extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            activePage: "note"
        }
    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }


    render() {
       
        
        return (
          <div className="hub-tabGroup-wrapper" >
            <TabGroup>
               <Tab to="note" onClick={this.setActivePage} label="Conversation" activePage={this.state.activePage} />
            </TabGroup> 
            <TabContent to="note" activePage={this.state.activePage}>
              < TimesheetSideNotes selectedRecord={this.props.selectedRecord}/>
            </TabContent>
          </div>
        );
    }
}




export default TimesheetNotesSidebar;

import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default class contextItem extends Component {
  render() {
    return (
        <>
            {this.props.line?<div className='hub-context-item-line'></div>:false}
            <div className={"hub-context-item" + (this.props.className?(" "+this.props.className):"")} onClick={this.props.onClick}>
                {this.props.icon?
                    <div className={"hub-context-item-icon" }>
                            <FontAwesomeIcon  icon={this.props.icon} color={this.props.iconColor?this.props.iconColor:"#2F5AB9"} style={{ cursor:'pointer',...this.props}}/>
                    </div>:false
                }
                <span >
                    {this.props.label}
                </span>
            </div>
        </>
    )
  }
}

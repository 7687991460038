import React from "react";

import "./ColorfullContent.scss";

const ColorfullContent = ({ text, bgColor, textColor }) => {
  return (
    <div
      className="hub-dt-colorfull-content"
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <p className="text">{text}</p>
    </div>
  );
};

export default ColorfullContent;

import React, { Component } from "react";
import MainButton from "./../buttons/MainButton";

import HiArrowShortRight from "../icons/HiArrowShortRight";
import HiArrowShortleft from "../icons/HiArrowShortleft";
import HiArrowDoubleLeft from "../icons/HiArrowDoubleLeft";
import HiExpand from "../icons/HiExpand";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customPage: 0,
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.setState({ customPage: this.props.page + 1 });
    }
  }

  render() {
    const { page, perPage, totalRows, setPage } = this.props;
    const startEntry = page * perPage + 1;
    const endEntry = Math.min((page + 1) * perPage, totalRows);

    const isPrevButtonDisabled = page === 0;
    const isNextButtonDisabled = page === Math.floor((totalRows - 1) / perPage);

    const totalPages = Math.ceil(totalRows / perPage);

    return (
      <div
        className="hub-dataTable-pagination-new"
        style={{
          marginTop: this.props.isSmallTable ? "40px" : "0px",
          borderTop: this.props.isSmallTable ? "1px solid #D2DEF2" : "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: this.props.isSmallTable ? "0px" : "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "7px",
            }}
          >
            {/*    <MainButton
              icon={<HiArrowDoubleLeft />}
              onClick={() => setPage(0)}
              disabled={isPrevButtonDisabled}
              className="tiny-icon"
            /> */}
            <MainButton
              icon={<HiArrowShortleft />}
              onClick={() => setPage(isPrevButtonDisabled ? page : page - 1)}
              disabled={isPrevButtonDisabled}
              className="tiny-icon"
              style={{ padding: "0" }}
            />
            <div className="pagination-page-box">
              <div className="pagination-page-text-box">
                <p style={{ margin: 0 }}>{` ${page + 1} `}</p>
              </div>
              <p>{`/ ${totalPages}`}</p>
            </div>
            <div style={{ display: "flex" }}>
              <MainButton
                icon={<HiArrowShortRight />}
                onClick={() => setPage(isNextButtonDisabled ? page : page + 1)}
                disabled={isNextButtonDisabled}
                className="tiny-icon"
                style={{ padding: "0" }}
              />
              {/*     <MainButton
                icon={faForwardStep}
                onClick={() => setPage(totalPages - 1)}
                disabled={isNextButtonDisabled}
                className="tiny-icon"
              /> */}
            </div>
          </div>
        </div>
        <div className="pagination-entries">
          <p className="pagination-entries-text">
            {`showing `}
            <span
              style={{ fontWeight: 600 }}
            >{`${startEntry} - ${endEntry}`}</span>
            {` of `}
            <span style={{ fontWeight: 600 }}>{`${totalRows}`}</span>
            {` entries`}
          </p>
          <div style={{ flex: 1 }}> </div>
        </div>
        {this.props.isSmallTable ? (
          <MainButton
            onClick={this.props.onExpand}
            iconSize={12}
            style={{
              height: "26px",
              padding: "2px 5px",
              fontSize: "11px",
              border: "1px solid #d2def2",
            }}
            label="Expand"
            icon={<HiExpand />}
            iconLineColor="#1d5bbf"
            className="tiny-text"
          />
        ) : (
          <div className="empty-area" style={{ width: "50px" }}></div>
        )}
      </div>
    );
  }
}

export default Pagination;

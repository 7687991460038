import { useEffect, useState, useRef } from "react";

import "./PickerButton.scss";
import TooltipWithPage from "../../tooltipWithPage/tooltipWithPage";
import HubIcon from "../../hubIcon/HubIcon";
import HiArrowShortDown from "../../icons/HiArrowShortDown";
import HiArrowShortUp from "../../icons/HiArrowShortUp";

/**
 * PickerButton component allows users to select an option from a dropdown menu.
 * It supports custom tooltips, icons, and can handle click events outside the component to close the dropdown.
 *
 * @param {object} selected The currently selected option.
 * @param {function} toggleSelected Callback function to handle option selection, receiving the selected option as an argument.
 * @param {JSX.Element} icon An optional icon to display next to the label.
 * @param {string} label The text label displayed next to the icon.
 * @param {Array<{ name: string }>} options The list of options available for selection.
 * @param {function} [customTooltip] A custom tooltip function that can render a custom tooltip component.
 * @param {object} [style] Additional inline styles to apply to the picker container.
 * @param {object} [tooltipStyle] Additional inline styles to apply to the tooltip.
 * @param {string} [arrowLocation] The location of the arrow in the tooltip.
 * @returns {JSX.Element} The rendered PickerButton component.
 *
 * @example
 * // Render a PickerButton with options and custom tooltip
 * //Custom Example
 * const teamTooltip = (isSelected, handleSelectOption) => {
      return (
        <TooltipWithPage
          style={{
            width: "336px",
            transform: "translate(0px, 16px)",
            right: "0px",
          }}
          arrowLocaiton={{ marginLeft: "250px" }}
        >
          <OBSList
            isSelected={isSelected}
            items={this.state.obsList}
            toggleSelectBar={handleSelectOption}
          />
        </TooltipWithPage>
      );
    };

     <PickerButton
                label="Team"
                icon={labelIcon}
                selected={this.state.selectedOBS}
                toggleSelected={this.setObs}
                customTooltip={teamTooltip}
              />


               * //Default Example
 *  <PickerButton
                label="Display"
                icon={labelIcon}
                selected={this.state.selectedBoardType}
                toggleSelected={this.setBoardType}
                style={{ marginRight: "10px" }}
                options={this.state.boardTypes}
                tooltipStyle={{
                  width: "153px",
                  transform: "translate(0px,16px)",
                  left:
                    this.state.selectedBoardType.name === "Activity"
                      ? "19px"
                      : "0px",
                }}
                arrowLocation={{ marginLeft: "120px" }}
              />
 */
const PickerButton = ({
  selected,
  toggleSelected,
  icon,
  label,
  options,
  customTooltip,
  style,
  tooltipStyle,
  arrowLocation,
}) => {
  const [isSelected, setIsSelected] = useState({});
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const tooltipRef = useRef(null);
  const elementRef = useRef(null);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleElementClick = () => {
    setTooltipOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      !elementRef.current.contains(event.target)
    ) {
      setTooltipOpen(false);
    }
  };

  useEffect(() => {
    if (isTooltipOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isTooltipOpen]);

  const handleSelectOption = (option) => {
    setIsSelected(option);
    toggleSelected(option);
  };

  return (
    <>
      <div className="hub-teamHub-picker" style={{ ...style }}>
        <div className="label">
          {icon}
          {label}
        </div>
        <div className="menu" ref={elementRef} onClick={handleElementClick}>
          {selected?.name}
          <HubIcon fontSize={14} lineColor="#1769F1">
            {isTooltipOpen ? <HiArrowShortUp /> : <HiArrowShortDown />}
          </HubIcon>
        </div>
      </div>
      {isTooltipOpen && (
        <div ref={tooltipRef}>
          {customTooltip ? (
            customTooltip(isSelected, handleSelectOption)
          ) : (
            <TooltipWithPage
              style={{ ...tooltipStyle }}
              arrowLocation={arrowLocation}
            >
              <div className="picker-options">
                {options.map((o) => (
                  <div
                    key={o.name}
                    className={`option-item ${
                      o.name === isSelected.name ? "selected" : ""
                    }`}
                    onClick={() => handleSelectOption(o)}
                  >
                    {o.name}
                  </div>
                ))}
              </div>
            </TooltipWithPage>
          )}
        </div>
      )}
    </>
  );
};

export default PickerButton;

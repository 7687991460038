import React, { Component } from "react";
import "./InputControl.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEmptySet,
  faCircleQuestion,
} from "@fortawesome/pro-duotone-svg-icons";
import Tooltip from "../tooltip/tooltip";

class InputControl extends Component {
  constructor(props) {
    super(props);
    this.state = { focus: false, className: "", toggleTooltip: false };
    //+ " "+this.props.className?this.props.className:""
    this.handleFocus = this.handleFocus.bind(this);
  }

  handleFocus(t) {
    this.setState({ ...this.state, focus: t });
  }

  handleTooltip = () => {
    // console.log("changeColor")
    this.setState({ ...this.state, toggleTooltip: !this.state.toggleTooltip });

    // this.setState({...this.state,  toggleTooltip:!this.state.toggleTooltip})
  };

  getInput() {
    switch (this.props.type) {
      case "bool":
        return (
          <div
            style={{ ...this.props.inputWrapperStyle }}
            className={this.props.disabled ? "input-disable" : ""}
          >
            {this.props.children}
          </div>
        );

      case "textarea":
        return (
          <div
            style={{ ...this.props.inputWrapperStyle }}
            className={this.props.disabled ? "input-disabled" : "input"}
          >
            {this.props.children}
          </div>
        );

      case "select":
        return (
          <div
            style={{ ...this.props.inputWrapperStyle }}
            className={this.props.disabled ? "input-disabled" : "input"}
          >
            {this.props.children}
          </div>
        );
      case "multi":
        return (
          <div
            style={{ ...this.props.inputWrapperStyle }}
            className={this.props.disabled ? "multi-disabled" : "multi"}
          >
            {this.props.children}
          </div>
        );
      default:
        return (
          <div
            style={{ ...this.props.inputWrapperStyle }}
            className={this.props.disabled ? "input-disabled" : "input"}
          >
            {this.props.children}
          </div>
        );
    }
  }

  render() {
    //console.log(this.props.value)

    let icStyle = this.props.error
      ? this.props.warning
        ? "warning"
        : "error"
      : this.props.focus
      ? "active"
      : "";

    //  console.log("hub-inputControl "+icStyle + " " + (this.state.className?this.state.className:"sss"))
    return (
      <div
        className={
          "hub-inputControl " +
          icStyle +
          " " +
          (this.props.className ? this.props.className : "")
        }
        style={{
          ...this.props.style,
          width: this.props.width ? this.props.width : this.props.cols + "%",
        }}
      >
        {this.props.label ? (
          <div className="label">
            <div className="label-typo"> {this.props.label} </div>
            <div className="required-symbol">
              {this.props.required ? " *" : ""}
            </div>

            {this.props.info ? (
              <div
                className="label-info"
                onMouseOver={() => this.handleTooltip()}
                onMouseOut={() => this.handleTooltip()}
              >
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  color={"#ACBDE4"}
                  size={"1x"}
                />
                <Tooltip
                  label={this.props.info}
                  close={this.state.toggleTooltip}
                  class={"right"}
                  style={{ left: "unset", marginLeft: "25px" }}
                />
              </div>
            ) : (
              false
            )}
          </div>
        ) : (
          ""
        )}
        {this.getInput()}
        {!this.props.hideMessageBox && (
          <div className={"message" + (this.props.warning ? " yellow" : "")}>
            {this.props.errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default InputControl;

import React, { Component } from 'react';
import PageNavigation from '../../common/page-navigation';
import PageHeader from '../../common/pageElements/pageHeader/'

import {
    Routes,
    Route,
  } from "react-router-dom";



import { useParams } from 'react-router';
//import TextField from  './TextField';
import Auth from '../../auth/Auth';

import DemandDetails from './DemandDetails';

class DemandTabController extends Component {
    constructor(props) {
        super(props);
        this.state = {
            links: [
                {to:"details", id:"projectDetailsDetails", label:"Properties", icon: ""}
               // {to:"wbs", id:"projectDetailsWBS", label:"Tasks", icon: ""},
                //{to:"dashboard", id:"projectDashboard", label:"Dashboard", icon: ""}
                
            ],  
            demand: {},
            defaultPage:1,
            loading:true
        }
    }

    getRecord = (e) => {
        //console.log("getRecord",e);
        //console.log(e);
         this.setState({...this.state, demand:e});

         this.props.setSelectedRecord(e)
    }

    componentDidMount() { 
        //console.log("componentDidMount",this.props.params['*'])
       // this.setState({...this.state, loading:false,defaultPage:this.props.params['*']==="details"?0:1});

        this.getData()
     }

     
    getData = () =>{
         const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch('/api/idea/'+this.props.params.demandid        , requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                //console.log('Status',r.body.object)
                if(r.body.status==200) {
                    this.setState({...this.state, loading:false, defaultPage:this.props.params['*']==="details"?0:1, demand:r.body.object});

                   // this.props.selectedRecord(r.body.object[0])
                }
                else if(r.body.status==401) {
                    window.location.replace("/login");
                }
                else if(r.body.status==403) {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    }

    render() {

       // console.log("demandtab",this.props)

        return (
            !this.state.loading?
            <>
            <PageHeader
                title={(this.state.demand?.name?this.state.demand.name:"")}
                left={<PageNavigation links = {this.state.links}  setSelectedRecord={this.props.setSelectedRecord} defaultPage={0} type={"changeSet"}/>} linkto={"/demand"}/> 
            <div className="hub-page">
                <Routes>
                    <Route path="/details" element={<DemandDetails  selectedRecord={this.getRecord} demand={this.state.demand}/>} />
                  


                </Routes>
            </div>
            </>
            :false
        );
    }
}

export default (props) => (
    <DemandTabController 
        {...props}
        params={useParams()}
        />
);
import React, { Component } from 'react';
import './FilterItemForm.css';
import Select from 'react-select';


class FilterItemFormText extends Component {
    constructor(props) {
        super(props);
        this.state = {options:[], filter:{ option: null, value: null}}

    }

    componentDidMount() {

        const options = [
            { value: 'null', label: 'Is Empty', indicator:"Is Empty", },
            ,{ value: 'notnull', label: 'Is Not Empty', indicator:"Is Not Empty" }
            ,{ value: 'contains', label: 'Contains', indicator:"*=*" }
            ,{ value: 'notcontains', label: 'Not Contains', indicator:"*!=*" }
            ,{ value: 'equal', label: 'Is Equal', indicator:"=" }
            ,{ value: 'notequal', label: 'Is Not Equal', indicator:'\u2260' }
          ];
        

        if(this.props.column.filter){
            this.setState({options: options, filter: this.props.column.filter});
        } else {
            this.setState({options: options, filter: {option: { value: 'contains', label: 'Contains', indicator:"*=*" }, value: null}});
        }

    }

    setFilter = () => {
        // filter olarak filter:{option:"", value:{}} değerini döner. option seçili koşulun codu, value ise türe göre çeşitli değerler
        if(this.state.filter){
            if(this.state.filter.option.value==="null" || this.state.filter.option.value==="notnull") {
                this.props.setFilter({option: this.state.filter.option, value:null});
            } else {
                if (this.state.filter.value===null){
                    this.props.setFilter(null);
                } else {
                    this.props.setFilter(this.state.filter);
                }
            }
        } else {
            this.props.setFilter(null);
        }
    }

    handleChange = (attr, value) => {
        let newFilter = {...this.state.filter};
        newFilter[attr] = value;
        this.setState({...this.state, filter: newFilter}, 
            () => {
                this.setFilter();
            })
    }

 
    setValue = (e) => {
        let newFilter = {...this.state.filter};
        newFilter.value = e.target.value!==""?e.target.value:null;
        this.setState({...this.state, filter: newFilter})
    }

    handleKeyPress(e){
        if(e.key === "Enter"){
            e.target.blur(); 
          }
    }
     

 
    render() {
        return (
            <div className="hub-dataTable-filterItem-form-wrapper">
                <div className="hub-dataTable-filterItem-form-row">
                    <Select
                        onChange = {(v) => {this.handleChange("option", v) }}
                        options={this.state.options}
                        value={this.state.filter.option}
                        isClearable = {false}
                        styles = {{
                            container: base => ({
                                ...base,
                                flex: "1",
                              }),
                        }}
                    />
                </div>
                {(this.state.filter.option && this.state.filter.option.value !== "null" && this.state.filter.option.value !== "notnull")||this.state.filter.option===null?
                  <div className="hub-dataTable-filterItem-form-row">
                    <input onChange={this.setValue} onKeyPress={this.handleKeyPress}  onBlur={this.setFilter} value={this.state.filter.value} className="hub-dataTable-filterItem-form-input" />
                  </div>:false
                }
              
            </div>
        );
    }
}

export default FilterItemFormText;
import React, { useState, useEffect } from "react";
/* import { Draggable } from "react-beautiful-dnd"; */

import "./Thead.scss";
import CheckBox from "../../checkbox";

/**
 * Thead is a component that represents the header row of a table. It can optionally include a
 * checkbox for selecting all rows and allows for additional child elements to be rendered.
 *
 * @param {object} props The component props.
 * @param {boolean} props.hasSelectBox Determines if a checkbox for selecting all rows should be displayed.
 * @param {function} [props.onCheckAllRows] Callback function triggered when the "Select All" checkbox is clicked.
 * @param {object} [props.style] Additional inline styles to apply to the header row.
 * @param {React.ReactNode} props.children The content to be rendered inside the header row.
 *
 * @returns {JSX.Element} The rendered Thead component.
 *
 * @example
 * // Render a table header with a select all checkbox
 * <Thead
 *   hasSelectBox={true}
 *   onCheckAllRows={(isChecked) => console.log(isChecked)}
 *   style={{ backgroundColor: '#f4f4f4' }}
 * >
 *   <TH>Column 1</TH>
 *   <TH>Column 2</TH>
 * </Thead>
 *
 * @example
 * // Render a table header without a select all checkbox
 * <Thead style={{ backgroundColor: '#f4f4f4' }}>
 *   <TH>Column 1</TH>
 *   <TH>Column 2</TH>
 * </Thead>
 */
const Thead = ({
  hasSelectBox,
  onCheckAllRows,
  style,
  /*   provided, */
  children,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  /*   const [sortedColumn, setSortedColumn] = useState(null); */

  useEffect(() => {
    onCheckAllRows && onCheckAllRows(isChecked);
  }, [isChecked]);

  const handleClickCheck = () => {
    setIsChecked((prevState) => !prevState);
  };

  return (
    <div
      className="hud-dt-th-row"
      style={{ ...style }}
      /*   {...provided?.droppableProps}
      ref={provided?.innerRef} */
    >
      {hasSelectBox && (
        <div className="check-wrapper">
          <CheckBox
            id="checkbox-header"
            isChecked={isChecked}
            onChange={handleClickCheck}
          />
        </div>
      )}
      {children}
      {/*   {provided?.placeholder} */}
    </div>
  );
};

export default Thead;

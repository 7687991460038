import React, { Component } from "react";
import Card from "./card/card.js";
import { Droppable } from "react-beautiful-dnd";
import MainButton from "../../common/buttons/MainButton.js";
import Auth from "../../auth/Auth.js";
import ColumnHeader from "./ColumnHeader";
import HiAdd from "../../common/icons/HiAdd.js";
export default class column extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      description: false,
    };
  }

  setCollapsed = () => {
    this.setState({ ...this.state, collapsed: !this.state.collapsed });
  };

  render() {
    return (
      <div
        className={
          "column" +
          (this.props.column.boardColumns.isCollapsed ? " collapsed" : "")
        }
      >
        <ColumnHeader
          column={this.props.column}
          name={this.props.column.name}
          code={this.props.column.code}
          index={this.props.columnIndex}
          setCollapsed={this.setCollapsed}
        />
        {this.props.columnIndex === 0 &&
        !this.props.column.boardColumns.isCollapsed ? (
          <div className="column-button-bar">
            <MainButton
              icon={<HiAdd />}
              className={"new-board-add-button"}
              style={{ width: "100%" }}
              iconLineColor="#FFFFFF"
              label="Add a New Task"
              disabled={
                Auth.getResourceObject()?.securityProfile?.name === "MUH"
                  ? true
                  : false
              }
              onClick={() => this.props.openSidebar("new")}
            />
          </div>
        ) : (
          false
        )}
        <Droppable
          type="card"
          droppableId={this.props.column.code}
          isDropDisabled={this.props.column.code === "planning" ? true : false}
        >
          {(droppableProvided) => (
            <div
              className="card-deck"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {this.props.column.task.map((val, i) => {
                return new RegExp(this.props.searchString, "i").test(
                  val.name
                ) ? (
                  <Card
                    task={val}
                    dragDisabled={
                      this.props.column.code === "planning" || val.isBlocked
                        ? true
                        : false
                    }
                    taskResource={this.props.column.taskResource}
                    index={i}
                    key={"task-" + val.uid.toString()}
                    columnIndex={this.props.columnIndex}
                    selectRecord={this.props.selectRecord}
                    openSidebar={this.props.openSidebar}
                    toggleBlock={this.props.toggleBlock}
                    selectedCard={this.props.selectedCard}
                    toDoOperations={this.props.toDoOperations}
                    assignmentOperations={this.props.assignmentOperations}
                    blockTask={this.props.blockTask}
                    setFavorite={this.props.setFavorite}
                  />
                ) : (
                  false
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./PageNavigation.css";
import { useParams } from "react-router";
import Auth from "../../auth/Auth";
class PageNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: this.props.params["*"],
      project: null,
    };
  }

  setActivePage = (e) => {
    // console.log(e)
    this.setState({ ...this.state, activePage: e });
  };

  componentDidMount() {
    if (this.props.type !== undefined && this.props.type === "changeSet") {
      this.setState({ ...this.state, Loading: false });
    } else {
      this.getData();
    }
  }

  getData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch("/api/projects/" + this.props.params.projectid, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.body.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            project: r.body.object[0],
          });

          //this.props.selectedRecord(r.body.object[0])

          this.props.setSelectedRecord(r.body.object[0]);
        } else if (r.body.status == 401) {
          window.location.replace("/login");
        } else if (r.body.status == 403) {
          this.setState({
            ...this.state,
            Loading: false,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Loading: false,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    if (this.state.LoadingError) {
      return null;
    } else {
      return (
        <div className={"hub-pageNavigation"}>
          {this.props.links.map((e) => {
            /* console.log(e.to, this.state.activePage); */
            return (
              <div
                className={
                  this.state.activePage === e.to ? "link active" : "link"
                }
              >
                <div className="body">
                  <Link
                    key={e.id}
                    to={e.to}
                    onClick={() => this.setActivePage(e.to)}
                  >
                    {e.label}
                  </Link>
                </div>
                <div className="border"></div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}

export default (props) => <PageNavigation {...props} params={useParams()} />;

import React from "react";

const HiFile = ({ lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M8.16659 1.8239V4.23338C8.16659 4.56008 8.16659 4.72343 8.23017 4.84821C8.28609 4.95797 8.37533 5.04721 8.48509 5.10313C8.60987 5.16671 8.77322 5.16671 9.09992 5.16671H11.5094M11.6666 6.32647V10.5333C11.6666 11.5134 11.6666 12.0035 11.4758 12.3778C11.3081 12.7071 11.0404 12.9748 10.7111 13.1426C10.3367 13.3333 9.84668 13.3333 8.86659 13.3333H5.13325C4.15316 13.3333 3.66311 13.3333 3.28877 13.1426C2.95949 12.9748 2.69177 12.7071 2.52399 12.3778C2.33325 12.0035 2.33325 11.5134 2.33325 10.5333V4.46667C2.33325 3.48658 2.33325 2.99653 2.52399 2.62219C2.69177 2.2929 2.95949 2.02519 3.28877 1.85741C3.66311 1.66667 4.15316 1.66667 5.13325 1.66667H7.00679C7.43482 1.66667 7.64884 1.66667 7.85024 1.71502C8.0288 1.75789 8.19951 1.8286 8.35608 1.92455C8.53269 2.03277 8.68402 2.18411 8.98669 2.48677L10.8465 4.34657C11.1492 4.64924 11.3005 4.80057 11.4087 4.97717C11.5047 5.13375 11.5754 5.30445 11.6182 5.48302C11.6666 5.68442 11.6666 5.89844 11.6666 6.32647Z"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiFile;

import React from "react";

/**
 * Common icon wrapper component.
 *
 * @param {JSX.Element} children Icon to use(required).
 * @param {string} [bgColor = "none"] The background color of SVG.
 * @param {string} [lineColor = "#051530"] The line color of SVG.
 * @param {number} [fontSize = 16] Width and height of the icon in pixels.
 * @returns {JSX.Element} The rendered icon component.
 *
 * @example 
 * //Render a convenient icon with given SVG component.
 * <HubIcon fontSize={24}  bgColor="#ffffff" lineColor="#051530">
      <HiBlock />
 * </HubIcon> 
 */

const HubIcon = ({
  children,
  bgColor = "none",
  lineColor = "#051530",
  fontSize = 16,
}) => {
  return (
    <span
      style={{
        fontSize: `${fontSize}px`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!!children &&
        React.cloneElement(children, {
          bgColor: bgColor,
          lineColor: lineColor,
        })}
    </span>
  );
};

export default HubIcon;

import React, { Component } from "react";
import MainButton from "./../../common/buttons/MainButton";
import {
  faAngleRight,
  faAngleLeft,
  faBackwardStep,
  faForwardStep,
} from "@fortawesome/pro-light-svg-icons";

import HiArrowShortRight from "./../../common/icons/HiArrowShortRight";
import HiArrowShortleft from "./../../common/icons/HiArrowShortleft";

class Pagination extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (v) => {
    this.props.setPerPage(v.target.value);
  };

  render() {
    let startEntry = this.props.page * this.props.perPage + 1;
    let endEntry =
      (this.props.page + 1) * this.props.perPage > this.props.totalRows
        ? this.props.totalRows
        : (this.props.page + 1) * this.props.perPage;

    const totalPages = Math.ceil(this.props.totalRows / this.props.perPage);
    return (
      <div className="hub-widget-grid-pagination">
        {/*  {this.props.hideTotals ? (
          false
        ) : (
          <span style={{ paddingRight: "16px" }}>
            Total: <strong>{this.props.totalRows}</strong> records
          </span>
        )} */}
        <div className="hub-widget-grid-pagination-paging">
          {/*     {this.props.hidePerPage ? (
            false
          ) : (
            <>
              Rows per page:
              <div className="per-page">
                <select value={this.props.perPage} onChange={this.handleChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </>
          )} */}

          
          <div className="left-section">
            {/*   <MainButton
            style={{ background: "transparent" }}
            icon={faBackwardStep}
            onClick={() => {
              this.props.setPage(0);
            }}
            disabled={this.props.page === 0 ? true : false}
            className="tiny-icon"
          /> */}
            <MainButton
              icon={<HiArrowShortleft />}
              onClick={() => {
                this.props.setPage(this.props.page - 1);
              }}
              disabled={this.props.page === 0 ? true : false}
              className="tiny-icon"
              style={{ padding: "0" }}
            />
            <div className="pagination-page-box">
              <div className="pagination-page-text-box">
                <p style={{ margin: 0 }}>{` ${this.props.page + 1} `}</p>
              </div>
              <p>{`/ ${totalPages}`}</p>
            </div>
            <MainButton
              icon={<HiArrowShortRight />}
              onClick={() => {
                this.props.setPage(this.props.page + 1);
              }}
              disabled={
                this.props.page ===
                Math.floor((this.props.totalRows - 1) / this.props.perPage)
                  ? true
                  : false
              }
              className="tiny-icon"
              style={{ padding: "0" }}
            />
            {/*     <MainButton
            style={{ background: "transparent" }}
            icon={faForwardStep}
            onClick={() => {
              this.props.setPage(
                Math.floor(this.props.totalRows / this.props.perPage)
              );
            }}
            disabled={
              this.props.page ===
              Math.floor((this.props.totalRows - 1) / this.props.perPage)
                ? true
                : false
            }
            className="tiny-icon"
          /> */}
          </div>

          <div className="pagination-entries">
            <p className="pagination-entries-text">
              {`showing `}
              <span
                style={{ fontWeight: 600 }}
              >{`${startEntry} - ${endEntry}`}</span>
              {` of `}
              <span
                style={{ fontWeight: 600 }}
              >{`${this.props.totalRows}`}</span>
              {` entries`}
            </p>
            <div style={{ flex: 1 }}> </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pagination;

import React, { useState } from "react";
import "./ToggleButtonGroup.scss";

/**
 * ToggleButtonGroup component allows users to select one option from a group of toggle buttons.
 *
 * @param {Array<{ label: string, value: string }>} options The list of options to display as toggle buttons.
 * @param {string} initialSelection The value of the initially selected option.
 * @param {function} onChange Callback function triggered when a selection is made, receiving the selected value as an argument.
 * @param {object} [style] Additional styles to apply to the toggle button group container.
 * @returns {JSX.Element} The rendered ToggleButtonGroup component.
 *
 * @example
 * // Render a ToggleButtonGroup with three options
 * <ToggleButtonGroup
 *   options={[
 *     { label: "Option 1", value: "option1" },
 *     { label: "Option 2", value: "option2" },
 *     { label: "Option 3", value: "option3" }
 *   ]}
 *   initialSelection="option2"
 *   onChange={(value) => console.log(value)}
 * />
 */
const ToggleButtonGroup = ({ options, initialSelection, onChange, style }) => {
  const [selected, setSelected] = useState(initialSelection);

  const handleToggle = (option) => {
    setSelected(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <div className="toggle-button-group" style={{ ...style }}>
      {options.map((option) => (
        <button
          key={option.value}
          className={`toggle-button ${
            selected === option.value ? "active" : ""
          }`}
          onClick={() => handleToggle(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleButtonGroup;

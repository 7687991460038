import React, { Component } from 'react';
import BarChartRunner from './barChartRunner';
import Widget from '../Widget';


class BarChart extends Component {
    render() {
        return (
            <Widget title={this.props.config?.title}  link={this.props.config?.link} info={this.props.config?.info} icon={this.props.config?.icon}>
                <BarChartRunner config={this.props.config} dataSet = {this.props.dataSet} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
            </Widget>
        );
    }
}

export default BarChart;
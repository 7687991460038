import React, { Component } from "react";
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import StatusScreen from "../../common/statusScreen/statusScreen.js";
import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";
import DateArea from "../../common/inputControls/dateArea";
import { Utils } from "./../../common/Commons";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import TextField from "../../common/inputControls/textField";
import TextArea2 from "../../common/inputControls/textArea2/textArea";
import Section from "../../common/pageElements/section";
import MultiSelect from "../../common/inputControls/multiSelect/multiSelect";
import TooltipWithPage from "../../common/tooltipWithPage/tooltipWithPage";
import ColorPicker from "../../common/inputControls/lookup/colorPicker/ColorPicker";
import { faCheck, faCancel } from "@fortawesome/pro-light-svg-icons";
import HiSave from "../../common/icons/HiSave";
class SideBarTaskDetails extends Component {
  /**/
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
        actuals: "5",

        progressCalculation: { uid: 151, name: "Unit" },
      },
      errorMessages: {
        startDate: { message: "", warning: false },
        finishDate: { message: "", warning: false },
        name: { message: "", error: false, warning: false },
        progressCalculation: { message: "", error: false, warning: false },
      },

      requiredFields: [
        { id: "name", label: "Name" },
        { id: "progressCalculation", label: "progress Calculation" },
      ],
      showTooltip: false,
      tag: {
        name: "",
        color: {},
      },
      counter: 0,
    };
  }

  handleChanges = (e, val) => {
    // console.log("handleChanges",e,val)

    let items = { ...this.state.formData };

    items[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesTag = (e, val) => {
    //console.log("handleChangesTag",e,val)

    let items = { ...this.state.formData };

    items[e] = val;

    this.setState({
      ...this.state,
      formData: items,
    });
  };

  handleChangesCreateTag = (e, val) => {
    //console.log("handleChangesTag",e,val)

    let items = { ...this.state.tag };

    items[e] = val;

    this.setState({
      ...this.state,
      tag: items,
    });
  };

  onCreateTag = (e, val) => {
    //console.log("onCreateTag",e,val)
    let items = { ...this.state.formData };
    items[e].push({ value: 85, label: val, color: "yellow" });

    this.setState({ ...this.state, formData: items });
  };

  toggleTooltip = (inputValue) => {
    //console.log("toggleTooltip",inputValue)
    let tag = { ...this.state.tag };
    tag.name = inputValue;
    this.setState({
      ...this.state,
      showTooltip: !this.state.showTooltip,
      tag: tag,
    });
  };

  chechkRequiredFields(fieldName, fieldValue) {
    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  handleChangesStartDate = (e,val) =>{

    // console.log(this.props)
     //console.log(val)
  
 
         let errors = {...this.state.errorMessages};
         let items = {...this.state.formData}; 
         items[e]=val;
         const dateFormat = new Date(val).toLocaleDateString('tr-tr', { year:"numeric", month:"numeric", day:"numeric"});
         const dateYear = new Date(val).getFullYear();
           if(items[e]>this.state.formData.finishDate)
              {
               errors[e].message="Start Date can not be later than Finish Date";
               errors[e].error=true;      
               errors[e].warning=false;         
              }
              else if(dateFormat=="Invalid Date" || dateYear>2099 || dateYear<1900){
               errors[e].message="Invalid Date Format";
               errors[e].error=true;
               errors[e].warning=false;      
             } 
              else
             {
               errors[e].message="";
               errors[e].error=false;
               this.state.errorMessages.finishDate.error = false;
               this.state.errorMessages.finishDate.message= "";
               
               if(items[e]<this.state.parentRecord?.p6Plan?.startDate)
                {
                     errors[e].message="Start Date is earlier than Activity's Start Date";
                     errors[e].error=false;
                     errors[e].warning=true;  
                }
             }
          
 
          this.setState({ ...this.state, formData:items,  errorMessages:errors })
         
     }



     handleChangesFinishDate = (e,val) =>{
        let errors = {...this.state.errorMessages};
        let items = {...this.state.formData};
        items[e]=val;
        const dateFormat = new Date(val).toLocaleDateString('tr-tr', { year:"numeric", month:"numeric", day:"numeric"});
        const dateYear = new Date(val).getFullYear();
            if(items[e]<this.state.formData.startDate)
            {
                errors[e].message="Finish Date can not be earlier than Start Date";
                errors[e].error=true; 
                errors[e].warning=false;      
            }
            else if(dateFormat=="Invalid Date" || dateYear>2099 || dateYear<1900){
                 errors[e].message="Invalid Date Format";
                 errors[e].error=true;
                 errors[e].warning=false;
            } 
             
            else
             {
                errors[e].message="";
                errors[e].error=false;
                this.state.errorMessages.startDate.error = false;
                this.state.errorMessages.startDate.message= "";

                if(items[e]>this.state.parentRecord?.p6Plan?.finishDate)
                {
                    errors[e].message="Finish Date is later than Activity's Finish Date";
                    errors[e].error=false;
                    errors[e].warning=true; 
                }
                //errors.startDate.message="";
                //errors.startDate.error=false;
              }

         this.setState({ ...this.state, formData:items,  errorMessages:errors })
        
    };

  componentDidMount() {
    //console.log("componentDidMount")
    // console.log(this.props)

    if (
      this.props.selectedRecord?.uid !== undefined &&
      this.props.selectedRecord?.activityId !== undefined &&
      this.props.selectedRecord?.wbsId !== undefined &&
      this.props.selectedRecord?.projectId !== undefined
    ) {
      this.GetDatas();
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props.user);

      this.setState({ Loading: true, Status: "Loading" });
      if (this.props.selectedRecord?.uid !== undefined) {
        this.GetDatas();
      }
    }
  }

  createTag = () => {
    //console.log("GetDatas",)
    if (
      this.state.tag.name === "" ||
      this.state.tag.color?.code === undefined
    ) {
      //console.log("tag",this.state.tag.color?.code)

      const u = new Utils();
      u.addNotification("error", "Enter tag name and choose tag color.");
    } else {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.check(),
        },
        body: JSON.stringify(this.state.tag),
      };

      fetch("/api/tag", requestOptions)
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((r) => {
          console.log(r);
          if (r.status === 201) {
            let createdTag = r.body?.object;
            let items = { ...this.state.formData };
            items.tag.push({
              value: createdTag?.uid,
              label: createdTag?.name,
              color: createdTag?.color?.code,
            });

            this.setState({
              ...this.state,
              formData: items,
              counter: this.state.counter + 1,
            });
          } else if (r.status == 401) {
            window.location.replace("/login");
          } else if (r.status == 403) {
            this.setState({
              ...this.state,
              Status: "Error",
              LoadingError: true,
              LoadingErrorText:
                "You are not authorized to see this page. Please contact your system administrator.",
            });
          } else {
            this.setState({
              ...this.state,
              Status: "Error",
              LoadingError: true,
              LoadingErrorText:
                "An unexpected error occured. Please contact your system administrator.",
            });
          }
        });
    }
  };

  GetDatas = () => {
    //console.log("GetDatas",)

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/task/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r.body.object[0]);
        if (r.status == 200) {
          let secProfile = Auth.getResourceObject().securityProfile?.name;
          let data = r.body.object[0];

          // console.log("data tag",data?.tag)
          //tag servis property değiştirme
          let tag = data.tag.map(({ tag_uid, tag_name, tag_color, ...e }) => ({
            ...e,
            value: tag_uid,
            label: tag_name,
            color: tag_color,
          }));

          //console.log("new tag",tag)

          data.tag = tag;

          this.setState(
            {
              ...this.state,
              Loading: false,
              Status: "Load",
              formData: data,
              secProfile: secProfile,
            },
            this.getActivity()
          );

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  getActivity = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    //console.log("getActivity",'/api/projects/'+this.props.selectedRecord.projectId+'/wbs/'+this.props.selectedRecord.wbsId+'/activity/'+this.props.selectedRecord.activityId)

    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log("getActivity",r);
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            parentRecord: r.body.object !== null ? r.body.object[0] : null,
          });

          //console.log(this.state.formData)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  update = async () => {
    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      let data = {
        ...Object.assign(this.state.formData, {
          columnIndex: this.props.selectedRecord.columnIndex,
          cardIndex: this.props.selectedRecord.cardIndex,
        }),
      };
      //await this.props.save("PATCH",'/api/task/'+this.state.formData.uid,data);
      let tag = data.tag.map(({ value, label, color, ...e }) => ({
        ...e,
        tag_uid: value,
        tag_name: label,
        tag_color: color,
      }));
      data.tag = tag;

      //console.log("update",data)
      if (this.props?.pageType === "board") {
        await this.props.save(
          "PATCH",
          "/api/task/" + this.props.selectedRecord.uid,
          data,
          this.props.selectedRecord
        );
      } else {
        await this.props.save(
          "patch",
          "/api/task/" + this.props.selectedRecord.uid,
          data,
          this.props.selectedRecord,
          null,
          null,
          "task"
        );
      }
    }
  };

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        // console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  commit = async () => {
    //await this.update();
    //console.log(JSON.stringify(this.state.formData))
    let formData = this.state.formData;

    formData.stage = { uid: 2 };
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(formData),
    };

    fetch(
      "/api/task/" + this.props.selectedRecord.uid + "/commit",
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        // console.log(requestOptions);
        // console.log(r);
        if (r.status === 200) {
          //this.notify("success","Updated")
          //  console.log(r);
          // this.removeItem();

          this.setState({
            ...this.state,
            Status: "Update",
            ErrorMessage: "Updated Succesfully",
          });
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });

          this.notify("error", r.body.message);
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  compareSession = () => {
    var responsible = this.props.selectedRecord?.owner?.uid.toString();
    var createdBy = this.props.selectedRecord.createdBy?.uid.toString();
    var sessionUser = Auth.checkResourceId().toString();
    let secProfile = Auth.getResourceObject().securityProfile?.name;
    //   console.log(secProfile)
    if (
      createdBy === sessionUser ||
      sessionUser === responsible ||
      secProfile === "ADMIN"
    ) {
      return true;
    } else {
      return false;
    }
  };

  compareSessionResponsible = () => {
    var responsible = this.props.selectedRecord?.owner?.uid.toString();
    var createdBy = this.props.selectedRecord.createdBy?.uid.toString();
    var sessionUser = Auth.checkResourceId().toString();
    let secProfile = Auth.getResourceObject().securityProfile?.name;
    if (secProfile === "MUH" || secProfile === "USER") {
      return false;
    } else if (
      createdBy === sessionUser ||
      sessionUser === responsible ||
      secProfile === "ADMIN"
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    //  console.log("this.state",this.state)

    const createdDateasDate = new Date(this.props.selectedRecord?.createdDate);
    const createdDateStr =
      createdDateasDate.getFullYear() +
      "-" +
      (parseInt(createdDateasDate.getMonth() + 1) < 10
        ? "0" + parseInt(createdDateasDate.getMonth() + 1)
        : parseInt(createdDateasDate.getMonth() + 1)) +
      (createdDateasDate.getDate() < 10
        ? "-0" + createdDateasDate.getDate()
        : "-" + createdDateasDate.getDate());
    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          {/* todo bunun içi ayrı sayfa mı yapılmalı? this.props.children*/}
          <MultiSelect
            label="Tags"
            endpoint={"/api/tag"}
            searchAttribute="fullName"
            valueAttribute="uid"
            labelAttribute="name"
            backgroundColorAttribute="color"
            changeArea="tag"
            onChange={this.handleChangesTag}
            onCreate={this.onCreateTag}
            value={this.state.formData?.tag}
            width="100%"
            disabled={false}
            showTooltip={this.state.showTooltip}
            toggleTooltip={this.toggleTooltip}
            counter={this.state.counter}
            isCreatable={true}
          >
            <TooltipWithPage
              title={"Create Tag"}
              closePanel={this.toggleTooltip}
              style={{ width: "240px" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  width="95%"
                  onChange={this.handleChangesCreateTag}
                  changeArea="name"
                  label="Name"
                  value={this.state.tag.name}
                  required={true}
                />
                <ColorPicker
                  endpoint="/api/valuelistoptions/parent/20"
                  value={{
                    value: this.state.tag.color?.uid,
                    label: this.state.tag.color?.name,
                  }}
                  onChange={this.handleChangesCreateTag}
                  formId="color"
                  label="Tag Color"
                  required={true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "center",
                }}
              >
                <MainButton
                  className="button-2"
                  label={"Cancel"}
                  onClick={this.toggleTooltip}
                />
                <MainButton
                  className="button-1"
                  label={"Create"}
                  onClick={() => {
                    this.createTag();
                    this.toggleTooltip();
                  }}
                />
              </div>
            </TooltipWithPage>
          </MultiSelect>

          <div className="input-grid">
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="code"
              label="ID"
              value={this.state.formData.code}
              disabled={true}
              required={true}
            />
            <TextField
              width="100%"
              onChange={this.handleChanges}
              changeArea="name"
              label="Name"
              value={this.state.formData.name}
              disabled={!this.compareSession()}
              required={true}
            />
            <DateArea
              width="100%"
              onChange={this.handleChangesStartDate}
              changeArea="startDate"
              label="Start Date"
              value={this.state.formData.startDate}
              required={true}
              errorMessage={this.state.errorMessages["startDate"].message}
              error={this.state?.errorMessages["startDate"]?.error}
              warning={this.state?.errorMessages["startDate"]?.warning}
              disabled={
                this.state.formData.status.code !== "not_started" ||
                !this.compareSession()
                  ? true
                  : false
              }
            />
            <DateArea
              width="100%"
              onChange={this.handleChangesFinishDate}
              changeArea="finishDate"
              label="Finish Date"
              value={this.state.formData.finishDate}
              required={true}
              errorMessage={this.state?.errorMessages["finishDate"]?.message}
              error={this.state?.errorMessages["finishDate"]?.error}
              warning={this.state?.errorMessages["finishDate"]?.warning}
              disabled={
                this.state.formData.status.code === "completed" ||
                !this.compareSession()
                  ? true
                  : false
              }
            />
            {this.compareSessionResponsible() ? (
              <DynamicLookup
                endpoint="/api/resource/taskresponsible"
                required={true}
                onChange={this.handleChanges}
                value={{
                  value: this.state.formData.owner?.uid,
                  label: this.state.formData.owner?.fullName,
                }}
                formId="owner"
                searchAttribute="fullName"
                valueAttribute="uid"
                labelAttribute="fullName"
                width="100%"
                label="Responsible"
                withAvatar
              />
            ) : (
              <TextField
                width="100%"
                label="Responsible"
                value={this.state.formData.owner?.fullName}
                required={true}
                disabled={true}
              />
            )}
            <TextField
              width="100%"
              onChange={this.handleChanges}
              style={this.state.formData?.isBlocked ? { color: "red" } : ""}
              changeArea="status"
              label="Status"
              value={
                this.state.formData?.isBlocked
                  ? this.state.formData.status?.name + " (Blocked)"
                  : this.state.formData.status?.name
              }
              disabled={true}
            />
            {this.props.selectedRecord.createdBy != null ? (
              <TextField
                width="100%"
                disabled={true}
                label="Created By"
                value={this.props.selectedRecord.createdBy?.fullName}
              />
            ) : (
              ""
            )}
            {this.props.selectedRecord?.createdDate != null ? (
              <DateArea
                width="100%"
                disabled={true}
                type="date"
                label="Created Date"
                value={createdDateStr}
              />
            ) : (
              ""
            )}
          </div>

          <DynamicLookup
            endpoint={"/api/valuelist/code/hub_task_progress_calculation"}
            required={true}
            onChange={this.handleChanges}
            extraObject={"valueListOptions"}
            value={{
              value: this.state.formData?.progressCalculation?.uid,
              label: this.state.formData?.progressCalculation?.name,
            }}
            formId="progressCalculation"
            searchAttribute="name"
            valueAttribute="uid"
            labelAttribute="name"
            width="calc(50% - 15px)"
            label="Progress Calculation Method"
            disabled={
              this.state.formData?.actualUnit > 0 || !this.state.formData.isTask
                ? true
                : false ||
                  this.state.secProfile == "USER" ||
                  this.state.secProfile == "MUH" ||
                  this.state.secProfile == "BMUH"
            }
          />

          <TextArea2
            width="100%"
            rows="5"
            disabled={!this.compareSession()}
            label="Description"
            value={this.state.formData.description}
            onChange={this.handleChanges}
            changeArea="description"
          />

          {/*   <TextField width="47%" cols="100%" parentCallback = {this.handleChanges} changeArea="owner" label="Owner" disabled={true} value={this.state.formData.ownerInfo?this.state.formData.ownerInfo.fullName:""}/>*/}

          <Section title="Activity Details">
            <div className="input-grid">
              <TextField
                disabled={true}
                width="100%"
                label="Activity Code"
                value={this.state.parentRecord?.code}
              />
              <TextField
                disabled={true}
                width="100%"
                label="Activity Name"
                value={this.state.parentRecord?.name}
              />
              <DateArea
                disabled={true}
                width="100%"
                type="date"
                value={this.state.parentRecord?.p6Plan?.startDate}
                label="Activity Start Date"
              />
              <DateArea
                disabled={true}
                width="100%"
                type="date"
                value={this.state.parentRecord?.p6Plan?.finishDate}
                label="Activity Finish Date"
              />
            </div>
          </Section>
        </BodyArea>
        <ButtonArea>
          <MainButton
            onClick={this.update}
            key="newTaskButton"
            className="button-area-action"
            iconLineColor="#fff"
            icon={<HiSave />}
            label="Save"
          />
          {!this.state.formData.isCommitted &&
          this.state.formData.owner.uid === Auth.getResourceObject().uid &&
          this.state.formData.remainingUnit > 0 &&
          this.state.formData.isTask ? (
            <MainButton
              onClick={this.commit}
              key="newCommitButton"
              className="button-area-action"
              label="Commit"
            />
          ) : (
            ""
          )}
        </ButtonArea>
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default SideBarTaskDetails;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowDownLong, faPeopleGroup, faBriefcase, faUser, faFolderGrid, faNetworkWired, faBold, faUserGroup}  from '@fortawesome/pro-solid-svg-icons'
import LevelIcon from './LevelIcon';


class GroupName extends Component {
    //hasChild={} level={} type={} isExpanded={} handleExpand={} label={d.name}
    /* types
        obsParent
        obs
        resource
        role
        project
        wbs
        activity
    */

    handleExpand = (e) => {
        if(this.props.hasChild) {
            this.props.handleExpand(e, this.props.ind);
        } 
    }   
    
        
    render() {

        const icons = {
            obsParent: faPeopleGroup, 
            obs: faUserGroup, 
            resource: faUser, 
            role:faBriefcase,
            project: faFolderGrid, 
            wbs: faNetworkWired, 
            activity: faBold
        };

        return (
            <div onClick={this.handleExpand} style={{marginLeft:(this.props.level*12 + (!this.props.hasChild?14:0) )}} className={"hub-rmUtil-groupName" + (this.props.hasChild?" parent":"")}>
                <div className="iconWrapper">
                    {this.props.hasChild?<div className={"expand"+(this.props.isExpanded?"":" collapsed")}>
                        <FontAwesomeIcon icon={faArrowDownLong} size="sm" color={"#1D5BBF"} />
                    </div>:false}
                    <div className="type">
                        <LevelIcon type={this.props.type} size="sm" color={"#AAB7CC"} />
                    </div>
                </div>
                <span className="label">{this.props.label}</span>
            </div>
        );
    }
}

export default GroupName;
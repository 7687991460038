import React, { Component } from 'react';
import DataTable from '../../common/dataTable';
import PageHeader from '../../common/pageElements/pageHeader/'



import OtherWorkSidebar from './OtherWorkSidebar';


class OtherWorkList extends Component {
    render() {
        const dtConfig = {
            endPoint: "/api/otherwork",
            columns: [
                {id:"code", dataKey:"code", label:"Code", type:"text"}
                ,{id:"name", dataKey:"name", label:"Name", type:"text" }
                ,{id:"startDate", dataKey:"startDate", label:"Start Date", type:"date"}
                ,{id:"finishDate", dataKey:"finishDate", label:"Finish Date", type:"date"}
                ,{id:"actuals", dataKey:"actuals", label:"Actual Units", type:"hour"}
            ], 
            idAttribute: "uid",
            nameAttribute: "name",
            view: {
                visibleColumnList: ["uid", "code","name","startDate","finishDate", "actuals"],
                hideSelectbox: true,
                hideActionbar: true,
            },
            sideBar: <OtherWorkSidebar  />
        }

        return (
            <>
            <PageHeader
                title={"Other Works"}
                /> 
            <div className="hub-page" >
                <DataTable config={dtConfig}  />
            </div>
            </>
        );
    }
}

export default OtherWorkList;
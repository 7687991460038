import React from "react";

const HiFlipForward = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M14 6.00004H5C3.34315 6.00004 2 7.34319 2 9.00004C2 10.6569 3.34315 12 5 12H8M14 6.00004L11.3333 3.33337M14 6.00004L11.3333 8.66671"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiFlipForward;

import React, { Component } from 'react';
import './Tabs.css';

class TabContent extends Component {
    render() {
          //console.log("TabContent")
        //console.log(this.props)
        return (
            this.props.to===this.props.activePage?
            <div className="hub-tabContent">
                {this.props.children}
            </div>:''
        );
    }
}

export default TabContent;
import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import {DateUtils} from './../../common/Commons'
import TDBool from './TDBool'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons'


class TD extends Component {

    constructor(props) {
        super(props);
        this.state = {isOpen: false}
    }
 
    handleExpand = (e) => {
        this.props.toggleIsExpanded(e, this.props.hierTree);
    }

    openMenu = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({...this.state, isOpen:true}, () => {
            document.addEventListener("click", this.closeMenu);
        });
    }

    closeMenu = () => {
        this.setState({...this.state, isOpen:false}, () => {
            document.removeEventListener("click", this.closeMenu);
        });
    }


    handleRightClick = (e) => {
        this.props.handleRightClick(e);
    }

    render() {
        let util = new DateUtils();

        
        let label = "";
        if(this.props.value!==null) {
            switch(this.props.type) {
                case 'text':
                    label = this.props.value;
                    break;
                case 'date':
                    label = util.Date2Str(this.props.value);
                    break;
                case 'number':
                    label = this.props.value;
                    break;
                case 'staticLookup':
                    label = this.props.value.name;
                    break;
                case 'dynamicLookup':
                    label = this.props.value.name;
                    break;
                case 'lookup':
                    label = this.props.value.name;
                    break;
                case 'percentage':
                    label = this.props.value?(this.props.value).toFixed(0)+'%':'0%';
                    break;    
                case 'boolean':
                    label= <TDBool value={this.props.value}/>
                    break;
                case 'hour': 
                    label=this.props.value?(this.props.value).toFixed(0)+'h':'0h';
                    break;
                case 'day': 
                    label=this.props.value?(this.props.value).toFixed(0)+'d':'0d';
                    break;
                default:
                    return <td></td>
            } 
        }
        //console.log("widgetTd",this.props)
        return (
            <td>
                <div className="td" style={{width: this.props.width, display:"flex", overflow:"hidden"}} onContextMenu={this.handleRightClick}>
                {this.props.isExpandable?<div style={{float:'left', width:"16px", marginLeft:((this.props.level-1)*16)}}>
                    {this.props.hasChildren > 0?
                    <FontAwesomeIcon onClick={this.handleExpand} icon={this.props.isExpanded?faAngleDown:faAngleRight} color="#444" style={{ cursor:'pointer'}}/>
                    :<span>&nbsp;</span>}
                    </div>:false} 
                    <span style={{width: this.props.width-(26+(this.props.hierLevel-1)*16), overflow:"hidden", float:"left", display:"block", fontWeight:(this.props.isExpandable&&this.props.hasChildren>0?"bold":"normal")}}>
                        {this.props.linkTo===undefined?label:<Link to={this.props.linkTo}> {this.props.hierLevel?this.props.hierLevel:false} {label}</Link>}
                    </span>
                </div>
            </td>
        );
    }
}
 
export default TD;
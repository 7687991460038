import React, { Component } from 'react'
import PageArea from '../../common/sideBarPageContent/pageArea/pageArea'
import ButtonArea from '../../common/sideBarPageContent/buttonArea/buttonArea'
import MainButton from '../../common/buttons/MainButton'
import { Utils } from '../../common/Commons'
import './copyTaskInfo.css'



export default class copyTaskInfo extends Component {
  render() {
      let utils=new Utils();



    //console.log("copyTaskInfo",this.props,utils.getCopyTaskListObject())
    return (
            <div className="copy-task-body">
                <div className='copy-task-body-area'>

              
                    <span>You are copying <b>{this.props.copyList?.length} </b>tasks under <b>{this.props.selectedRecord?.code} - {this.props.selectedRecord?.name}</b></span>
                    <br></br>
                    <span>Please note that;</span>
                    <ul >
                        <li>During pasting, start and finish dates will be transferred as they are.</li>
                        {/*<li>The responsible area of ​​the sticking tasks will be set as "Çağıl Erk Demir".</li>*/}
                        <li>Assignments of the record will be transferred as they are.</li>
                    </ul>
                    <br></br>
                    <span><b>Task list going to paste;</b></span>
                    <div className="copy-task-list">
                        <ul>
                            {utils.getCopyTaskListObject().map((lst)=>
                                {
                                return <li>{lst.code} - {lst.name}</li>
                                })
                            }
                    
                        </ul>
                    </div>
                </div>
          
              
                <div className='copy-task-button-area'>
                    
                    <MainButton className="button-2" label={"Cancel"} onClick={this.props.onClose} />
                    <MainButton className="button-1" label={"Apply"} onClick={()=>{this.props.pasteTask(this.props.pasteType);this.props.onClose()}} />

                </div>
            </div>
 
    )
  }
}

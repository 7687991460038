import React, { Component } from 'react';
import {faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function format(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${year}-${month}-${date}`;
  }

class QuarterlyPicker extends Component {

     /* Dönemler başlangıç tarihinin metni ile olabilir. */
    
     constructor(props){ 
        super(props);
        const now = new Date();
        this.period = "quarterly";

        this.periods = [
            {periodId: 1, periodIdStr:"01", periodLabel:"Q1", periodName:"Q1"},
            {periodId: 2, periodIdStr:"04", periodLabel:"Q2", periodName:"Q2"},
            {periodId: 3, periodIdStr:"07", periodLabel:"Q3", periodName:"Q3"},
            {periodId: 4, periodIdStr:"10", periodLabel:"Q4", periodName:"Q4"}
          ];

        

        this.aligns = ["left", "center", "right"];

        this.state={
            startPeriod: now.getFullYear(),
            currentPeriod: now.getFullYear().toString()+"-"+(now.getMonth() + 1).toString().padStart(2, "0")+"-01",
            hover: "",
            limit: 32 // En fazla kaç ay seçileceği*
        }
    }
    nextPeriod = () => {
        this.setState({...this.state, startPeriod:this.state.startPeriod+1})
    }
    prevPeriod = () => {
        this.setState({...this.state, startPeriod:this.state.startPeriod-1})
    }

    selectDate = (d) => {
        this.props.selectDate(d);
    } 

    hoverDate = (d) => {
        this.setState({...this.state, hover:d})
    }

    clearHover = () => {
        this.setState({...this.state, hover:""})
    }
    
    render() {
        let firstPeriod = this.state.startPeriod.toString();
        let secondPeriod = (this.state.startPeriod+1).toString();

        // Tarih sınırı
        let dateLimit = "9999-99-99";
        if (this.props.startDate !== "" ) {
            // 48 olarak ekliyorum.
            let dt  = new Date(this.props.startDate);
            var newDate = new Date(dt.setMonth(dt.getMonth()+this.state.limit-1));
            dateLimit = format(newDate);
        }

    

        return (
            <>
            <div className="pickerWrapper">
                <div className="pickerColumn">
                    <div className="periodSelector">
                        <div className="icon" onClick={this.prevPeriod} ><FontAwesomeIcon icon={faChevronLeft} size="sm"/> </div>
                        <div className="label">{firstPeriod}</div> 
                    </div>    
                    <div className="periodBody">
                    {this.periods.map( (j, ind) => {
                            let dateKey = firstPeriod.toString()+"-"+j.periodIdStr+"-"+"01";
                            
                            let isHovered = "";
                            let isSelected = "";
                            let isSelectedFinish = "";
                            let isDisabled = "";

                            if(this.props.activeLayout === this.period) {

                                // Eğer değer seçili ise o değer esas alınır, eğer seçili değilse dateRange'deki daha önce seçili değer esas alınır.
                                let compStart = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.start:this.props.startDate;
                                let compFinish = (this.props.dateRange.period === this.period && this.props.finishDate==="")?this.props.dateRange.finish:this.props.finishDate;

                                isHovered = (((compStart!=="" && compFinish==="" && compStart<dateKey&&this.state.hover>=dateKey && dateKey < dateLimit)||(compStart<dateKey&&compFinish>=dateKey))?" hovered":"");
                                isSelected =  (compStart===dateKey?" selected":"");
                                isSelectedFinish =  (compFinish===dateKey?" selectedFinish":"");
                                isDisabled = (dateLimit<dateKey)?" disabled": "";
                            }

                            return (<div 
                                onMouseOver={()=>{this.hoverDate(dateKey)}}
                                onMouseLeave={this.clearHover} 
                                onClick={()=>{if(dateLimit>=dateKey){this.selectDate(dateKey)}}}  
                                className={"monthItem" + isSelected + isHovered + isSelectedFinish + isDisabled}
                                style={{width:"100%", borderRadius:6}}>
                                <div style={{width:"100%"}} className={"cellItem"+ ((this.state.currentPeriod===firstPeriod.toString()+"-"+j.periodIdStr+"-"+"01")?" today":"")}>{j.periodLabel}</div>
                            </div>)
                        }
                    )}
                    </div>
                </div>
                {/** SECOND COLUMN */}
                <div className="pickerColumn">
                    <div className="periodSelector">
                        <div className="label">{secondPeriod}</div> 
                        <div className="icon" onClick={this.nextPeriod} ><FontAwesomeIcon icon={faChevronRight} size="sm"/> </div>
                    </div>   
                    <div className="periodBody">
                    {this.periods.map((j, ind) => {
                                let dateKey = secondPeriod.toString()+"-"+j.periodIdStr+"-"+"01";
                                
                                let isHovered = "";
                                let isSelected = "";
                                let isSelectedFinish = "";
                                let isDisabled = "";

                                if(this.props.activeLayout === this.period) {

                                    // Eğer değer seçili ise o değer esas alınır, eğer seçili değilse dateRange'deki daha önce seçili değer esas alınır.
                                    let compStart = (this.props.dateRange.period === this.period && this.props.startDate==="")?this.props.dateRange.start:this.props.startDate;
                                    let compFinish = (this.props.dateRange.period === this.period && this.props.finishDate==="")?this.props.dateRange.finish:this.props.finishDate;
    
                                    isHovered = (((compStart!=="" && compFinish==="" && compStart<dateKey&&this.state.hover>=dateKey && dateKey < dateLimit)||(compStart<dateKey&&compFinish>=dateKey))?" hovered":"");
                                    isSelected =  (compStart===dateKey?" selected":"");
                                    isSelectedFinish =  (compFinish===dateKey?" selectedFinish":"");
                                    isDisabled = (dateLimit<dateKey)?" disabled": "";
                                }
    
                                return (<div 
                                    onMouseOver={()=>{this.hoverDate(dateKey)}}
                                    onMouseLeave={this.clearHover} 
                                    onClick={()=>{if(dateLimit>=dateKey){this.selectDate(dateKey)}}}  
                                    className={"monthItem" + isSelected + isHovered + isSelectedFinish + isDisabled}
                                    style={{width:"100%", borderRadius:6}}>
                                    <div style={{width:"100%"}} className={"cellItem"+ ((this.state.currentPeriod===secondPeriod.toString()+"-"+j.periodIdStr+"-"+"01")?" today":"")}>{j.periodLabel}</div>
                                    </div>)})}
                    </div>
                </div>
            </div>
            <div className="footNote">You can choose up to {this.state.limit} quarters</div>
            </>
        );
    }
}

export default QuarterlyPicker;
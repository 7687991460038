import React, { useRef, useState } from "react";

import FileBadge from "../../fileUpload/FileBadge";
import HubIcon from "../../hubIcon/HubIcon";
import HiDownload from "../../icons/HiDownload";
import { downloadFile } from "../../../api/file";
import { formatBytes, imageDetector, sliceFileName } from "../../Commons";
import HiServer from "../../icons/HiServer";
import MainButton from "../../buttons/MainButton";
import HiFileXL from "../../icons/HiFileXL";
import HiImageXL from "../../icons/HiImageXL";

const NoteFileItem = ({ fileData }) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const wrapperRef = useRef();

  const fileName = `${sliceFileName(fileData?.fileName, 12)}${fileData.extension}`;

  const toggleInfo = () => {
    setIsInfoOpen(true);
    window.addEventListener("mousedown", handleClickOutside);
  };

  const closeInfo = () => {
    setIsInfoOpen(false);
    window.removeEventListener("mousedown", handleClickOutside);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef && !wrapperRef.current?.contains(event?.target)) {
      closeInfo();
    }
  };

  const clickDownload = async () => {
    const response = await downloadFile(fileData?.hash);

    const blob = new Blob([response.data], { type: fileData.mimeType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="file-info-wrapper" onClick={toggleInfo}>
      <FileBadge
        fileName={fileName}
        editable={false}
        isImage={imageDetector(fileData)}
      />
      {isInfoOpen && (
        <div className="file-info" ref={wrapperRef}>
          <div className="main-icon">
            {imageDetector(fileData) ? <HiImageXL /> : <HiFileXL />}
          </div>
          <div className="description">
            <div className="file-name">{fileName}</div>
            <div className="file-summary">
              <div className="size">
                <HubIcon lineColor="#647795">
                  <HiServer />
                </HubIcon>
                {formatBytes(fileData?.size)}
              </div>
            </div>
          </div>
          <MainButton
            className="new-icon-button active"
            onClick={clickDownload}
            icon={<HiDownload />}
            iconSize={20}
          />
        </div>
      )}
    </div>
  );
};

export default NoteFileItem;

import HubIcon from "../hubIcon/HubIcon";
import HiSearch from "../icons/HiSearch";
import HiClose from "../icons/HiClose";
import "./TooltipSearch.scss";

const TooltipSearch = ({ onChange, value }) => {
  const handleClean = () => {
    onChange("");
  };

  return (
    <div className="tooltip-search">
      <HubIcon>
        <HiSearch />
      </HubIcon>
      <input
        onChange={(e) => onChange(e.target.value)}
        value={value}
        placeholder="Search"
      />

      {value !== "" && (
        <span className="close-icon" onClick={handleClean}>
          <HubIcon>
            <HiClose />
          </HubIcon>
        </span>
      )}
    </div>
  );
};

export default TooltipSearch;

import React from "react";

const HiHome = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill={bgColor ?? "none"}
    >
      <path
        d="M2.5 8.80442C2.5 8.32579 2.5 8.08648 2.56169 7.86609C2.61633 7.67087 2.70614 7.48725 2.82669 7.32426C2.96278 7.14026 3.15168 6.99333 3.52949 6.69948L9.18141 2.30355C9.47418 2.07584 9.62057 1.96198 9.78221 1.91821C9.92484 1.8796 10.0752 1.8796 10.2178 1.91821C10.3794 1.96198 10.5258 2.07584 10.8186 2.30355L16.4705 6.69948C16.8483 6.99333 17.0372 7.14026 17.1733 7.32426C17.2939 7.48725 17.3837 7.67087 17.4383 7.86609C17.5 8.08648 17.5 8.32579 17.5 8.80442V14.8335C17.5 15.767 17.5 16.2337 17.3183 16.5902C17.1586 16.9038 16.9036 17.1588 16.59 17.3185C16.2335 17.5002 15.7668 17.5002 14.8333 17.5002H5.16667C4.23325 17.5002 3.76654 17.5002 3.41002 17.3185C3.09641 17.1588 2.84144 16.9038 2.68166 16.5902C2.5 16.2337 2.5 15.767 2.5 14.8335V8.80442Z"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiHome;

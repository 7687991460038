const HiNumberList = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill={bgColor ?? "none"}
    >
      <path
        d="M17.5 10L7.49996 10M17.5 5H7.49996M17.5 15L7.49996 15M2.91663 3.75H3.74996V6.25M2.91663 9.16667H4.58329L2.91663 11.25H4.58329M2.91663 13.75H4.58329V15M4.58329 15H3.33329M4.58329 15V16.6667H2.91663"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiNumberList;

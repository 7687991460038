import React, { Component } from 'react';
import './PageFilterItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

import { DateUtils } from '../../common/Commons';

    import FilterItemFormDynamicLookup from './FilterItemFormDynamicLookup';
import TooltipWithPage from '../../common/tooltipWithPage/tooltipWithPage';
import OBSList from '../../pages/taskBoard/OBSList';
import Auth from '../../auth/Auth';
import TableWithSearch from '../../common/tableWithSearch';
class FilterItem extends Component {

    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
        this.state={isOpen:false, Loading:true, selectedFilter:null}
        this.dutils = new DateUtils();
        
    }


    onRemove = () => {
        this.props.removeFilter(this.props.column);
    }

    open = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({...this.state, isOpen:true}, () => {
            window.addEventListener("mousedown", this.handleClickOutside);
        });
    }

    close = () => {
        this.setState({...this.state, isOpen:false}, () => {
            window.removeEventListener("mousedown", this.handleClickOutside);
        });
    }


    /**
     * 
     * Set the wrapper ref
     * 
    **/


    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
          this.close();
        }
      }

    componentWillUnmount() {
        window.removeEventListener("mousedown", this.handleClickOutside);
    }


    FilterItemExpression = (t) => { 
        switch (t) {
            case "text":
                return this.props.column.filter.option.indicator + (this.props.column.filter.value!==null?" "+this.props.column.filter.value:"")
                break;
            case "date":
                return this.props.column.filter.option.indicator + (this.props.column.filter.value[0]!==null?" "+this.dutils.Date2Str(this.props.column.filter.value[0]):"") + (this.props.column.filter.value[1]!==null?" - "+this.dutils.Date2Str(this.props.column.filter.value[1]):"") 
                break;
            case "number":
                return this.props.column.filter.option.indicator + (this.props.column.filter.value[0]!==null?" "+this.props.column.filter.value[0].toString():"") + (this.props.column.filter.value[1]!==null?" - "+this.props.column.filter.value[1].toString():"") 
                break;
            case "percentage":
                return this.props.column.filter.option.indicator + (this.props.column.filter.value[0]!==null?" "+this.props.column.filter.value[0].toString():"") + (this.props.column.filter.value[1]!==null?" - "+this.props.column.filter.value[1].toString():"") 
                break;
            case "dynamicLookup":
                return this.props.column.filter.option.indicator +  (this.props.column.filter?.value?.length > 0?" " + this.props.column.filter.value.map(e=>e.label).join():"")
                break;
            case "staticLookup":
                return this.props.column.filter.option.indicator +  (this.props.column.filter?.value?.length > 0? " " + this.props.column.filter.value.map(e=>e.label).join():"")
                break;
            case "boolean":
                return this.props.column.filter.option.indicator
                break;
            default:
                return ""
                break;
        }
    }

    componentDidMount() { 
       // console.log("componentDidMount",this.props)
        this.getData()
     }

     componentDidUpdate(prevProps, prevState) { 
        
        if(this.props.column?.beAffected && this.props.affectAll!==null && this.props.affectAll?.value!==prevProps.affectAll?.value)
        {
           // console.log("1componentDidUpdate",prevProps.affectAll, this.props)

            this.getData()
        }
        
     } 


    getData = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };
      //  console.log(obs)


      let filterString=""
      //console.log("generateData",this.props.selectedFilters)
      if(this.props.column?.beAffected && this.props.affectAll!==null)
      {
        filterString+="?"
        filterString+=this.props.affectAll.dataKey+"="+this.props.affectAll.value.toString()
        
      }

     
        fetch(/*'/api/obs/teamboard'*/ this.props.column.endpoint+filterString, requestOptions)
        
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
             
                //console.log("r",r)
                
               if(r.status===200) {

               // console.log("r",r,r.body.object[0])
               

            
                this.setState({...this.state, Loading:false,  Status: "Load",data:r.body.object , selectedOBS:r.body.object[0]});

              //  console.log(this.state)
               // console.log(this.state.period.open)
            }
                else if(r.status===401) {
                    window.location.replace("/login");
                }
                else if(r.status===403) {
                    this.setState({...this.state, Status: "Error",LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state,Status: "Error", LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });



    }

    

    setFilter=(a,b)=>
    {
       // console.log(a,b,this.props.column)

        this.props.setFilter(this.props.column,b, b[this.props.column.labelAttribute], this.props.index)
    

        this.setState({...this.state, selectedFilter:b[this.props.column.labelAttribute], isOpen:false})

    }

    render() {
       // console.log("filterItem",this.props)
        return (
            !this.state.Loading?
            <div className="hub-dataTable-filterItem-wrapper" ref={this.wrapperRef}>
                <div className="hub-teamHub-teamPicker" >
                    <div className="label" onClick={this.open}>
                        <FontAwesomeIcon icon={faAngleDown} width={12} className="icon" />
                        {this.props.column.label}
                        
                    </div>
                    <div className="menu" onClick={this.open} >
                        {
                            this.props.column.filter && this.props.column.filter.option !== null?
                                <span className="expression">{}</span>
                                :
                                <span className="expression">{this.props.column.selectedFilter!==null?this.props.column.selectedFilter:"Select"}</span>

                                
                        }
                        <FontAwesomeIcon icon={faAngleDown} width={12} className="icon" />
                    </div>


                    
                </div>

               
                {/*this.state.isOpen?
                    this.FilterItemFormDynamicLookup(this.props.column.type)
                    :false*/} 
                    {this.state.isOpen && !this.state.Loading?
                        <div style={{ position: "relative"}}ref={this.wrapperRef}>
                            <TooltipWithPage title={this.state?.selected?.name} closePanel={this.close} style={{width:this.props.column.id==="obs"?"450px":"250px",transform:"translate(-16%)"}} arrowLocaiton={{marginLeft:"80px"}} >
                                {this.props.column.id==="obs"?
                                    <OBSList labelKey={this.props.column.labelAttribute} items={this.state.data} toggleSelectBar={this.setFilter} closePanel={this.closePanel} />
                                    :
                                    <TableWithSearch labelKey={this.props.column.labelAttribute} items={this.state.data} toggleSelectBar={this.setFilter} closePanel={this.closePanel} />
                                }
                                
                            </TooltipWithPage>
                        </div>
                    :false}
            </div>:false
            
        );
    }
}

export default FilterItem;
import { faFloppyDisk } from '@fortawesome/pro-duotone-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class LayoutItem extends Component {

    onClick = () => {
        this.props.onClick(this.props.item);
    }

    render() {
        return (
            <div onClick={this.onClick} className={"layoutItem"+(this.props.active?" active":"")}>
                <div className="label" > {this.props.item.isModified?<><FontAwesomeIcon size={"sm"} icon={faFloppyDisk} style={{"width": "14px", "height": "14px", "--fa-primary-color": "#ffffff", "--fa-secondary-color": "#B7C2D3", "--fa-secondary-opacity": "1"}} /> &nbsp;</>:false} {this.props.item.name} </div>
                {this.props.selected?<div className="selected" > <FontAwesomeIcon icon={faCheck} color={"#1D5BBF"} /> </div>:false}
            </div>
        );
    }
}

export default LayoutItem;
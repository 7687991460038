import React, { Component } from "react";
//import InputArea from './../../common/Inputs/Text/index.js';
import Auth from "../../auth/Auth";
import MainButton from "../../common/buttons/MainButton";
import SelectArea from "../../common/inputControls/Select/index.js";
import StatusScreen from "../../common/statusScreen/statusScreen.js";

import ButtonArea from "../../common/sideBarPageContent/buttonArea/buttonArea.js";
import BodyArea from "../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../common/sideBarPageContent/pageArea/pageArea.js";

import { Utils } from "../../common/Commons";

import NumberField from "../../common/inputControls/numberField";
import Tab from "../../common/tabs/Tab";
import TabGroup from "../../common/tabs/TabGroup";

import BooleanArea from "../../common/inputControls/booleanArea/booleanArea.js";
import TextField from "../../common/inputControls/textField";
import TextArea from "../../common/inputControls/textarea/TextArea";
import DynamicLookup from "../../common/inputControls/lookup/DynamicLookup";
import DateArea from "../../common/inputControls/dateArea";
import HiSave from "../../common/icons/HiSave.js";
class ActivityAssignmentDetails extends Component {
  /**/
  constructor() {
    super();

    this.state = {
      data: [],
      Loading: true,
      Status: "Loading",
      ErrorMessage: "",

      formData: {
        firstName: "",
        lastName: "",
        email: "",
      },
      errorMessages: {
        role: { message: "", error: false },
        remainingUnits: { message: "", error: false },
        startDate: { message: "", error: false },
        finishDate: { message: "", error: false },
      },

      requiredFields: [
        { id: "owner", label: "Resource" },
        { id: "remainingUnits", label: "remaining Units" },
        { id: "role", label: "Role" },
      ],
    };
  }

  handleChanges = (e, val) => {
    //console.log(e)
    //console.log(val)
    let items = { ...this.state.formData };

    items[e] = val;
    this.setState({
      ...this.state,
      formData: items,
    });
  };

  handleChangesP6 = (e, val) => {
    // console.log("handleChangesP6",e,val)
    //console.log(val)

    let items = { ...this.state.formData };

    items.p6Plan[e] = val;
    this.setState(
      {
        ...this.state,
        formData: items,
      },
      this.chechkRequiredFields(e, val)
    );
  };

  handleChangesStartDate = (e, val) => {
    // console.log(this.props)
    //console.log(val)

    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items.p6Plan[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items.p6Plan[e] > this.state.formData?.p6Plan?.finishDate) {
      errors[e].message = "Start Date can not be later than Finish Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;
      errors.finishDate.message = "";
      errors.finishDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  handleChangesFinishDate = (e, val) => {
    let errors = { ...this.state.errorMessages };
    let items = { ...this.state.formData };
    items.p6Plan[e] = val;
    const dateFormat = new Date(val).toLocaleDateString("tr-tr", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const dateYear = new Date(val).getFullYear();
    if (items.p6Plan[e] < this.state.formData?.p6Plan?.startDate) {
      errors[e].message = "Finish Date can not be earlier than Start Date";
      errors[e].error = true;
      errors[e].warning = false;
    } else if (
      dateFormat == "Invalid Date" ||
      dateYear > 2099 ||
      dateYear < 1900
    ) {
      errors[e].message = "Invalid Date Format";
      errors[e].error = true;
      errors[e].warning = false;
    } else {
      errors[e].message = "";
      errors[e].error = false;

      errors.startDate.message = "";
      errors.startDate.error = false;
    }

    this.setState({ ...this.state, formData: items, errorMessages: errors });
  };

  componentDidMount() {
    //console.log("componentDidMount")
    this.getDatas();
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate!!");

    //console.log(this.props);
    //
    if (prevProps.selectedRecord !== this.props.selectedRecord) {
      //console.log("componentDidUpdate!!");
      //  this.setState({data: this.props.user})
      //console.log(this.props);

      this.setState({ Loading: true, Status: "Loading" });
      this.getDatas();
    }
  }

  getDatas = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };
    //console.log("/api/projects/"+this.props.selectedRecord.projectId+"/wbs/"+this.props.selectedRecord.wbsId+"/activity/"+this.props.selectedRecord.activityId+"/activityAssignment/"+this.props.selectedRecord.uid)
    fetch(
      "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.activityId +
        "/activityAssignment/" +
        this.props.selectedRecord.uid,
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log("getdata",r)
        if (r.status == 200) {
          this.setState({
            ...this.state,
            Loading: false,
            Status: "Load",
            formData: r.body.object,
          });

          //console.log(this.state)
          // console.log(this.state.period.open)
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            Status: "Error",
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  chechkRequiredFields(fieldName, fieldValue) {
    //console.log("chechkRequiredFields",fieldName,fieldValue)

    let requiredFields = [...this.state.requiredFields];

    let errors = { ...this.state.errorMessages };
    for (var i = 0; i < requiredFields.length; i++) {
      if (requiredFields[i].id === fieldName) {
        if (fieldValue === "") {
          errors[fieldName].message = requiredFields[i].label + " is required.";
          errors[fieldName].error = true;
        }
        if (fieldValue !== "") {
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        // console.log("if üzeri",fieldName,fieldValue)
        if (fieldName === "remainingUnits" && fieldValue > 0) {
          //  console.log("if içi",fieldName,fieldValue,errors[fieldName])
          errors[fieldName].message = "";
          errors[fieldName].error = false;
        }

        //console.log(val)
        //console.log(requiredFields[i]);

        this.setState({ ...this.state, errorMessages: errors });
      }
    }
  }

  checkErrors() {
    let errorArray = { error: false, message: "" };
    //Object.entries(this.state.errorMessages).map((item,i) => {
    //console.log((Object.entries(this.state.errorMessages)).length)

    let arrayErrors = Object.entries(this.state.errorMessages);
    for (var i = 0; i < arrayErrors.length; i++) {
      //console.log(arrayErrors[i][1])

      if (arrayErrors[i][1].error && !arrayErrors[i][1].warning) {
        //console.log("ife girdik")
        errorArray.error = arrayErrors[i][1].error;
        errorArray.message = arrayErrors[i][1].message;
        break;
      }
    }

    return errorArray;
  }

  update = () => {
    let errors = { ...this.state.errorMessages };

    if (this.state.formData.p6Plan.remainingUnits <= 0) {
      //console.log("burası mı")
      errors.remainingUnits.message = "Remaining Unit can not be zero.";
      errors.remainingUnits.error = true;
      errors.remainingUnits.warning = false;
    }
    if (this.state.formData.p6Plan.startDate === "") {
      errors.startDate.message = "Start Date is required";
      errors.startDate.error = true;
      errors.startDate.warning = false;
    }
    if (this.state.formData.p6Plan.finishDate === "") {
      errors.finishDate.message = "Finish Date is required";
      errors.finishDate.error = true;
      errors.finishDate.warning = false;
    }
    if (this.state.formData.role === "") {
      errors.role.message = "Role is required";
      errors.role.error = true;
      errors.role.warning = false;
    }

    if (this.checkErrors().error) {
      const u = new Utils();
      u.addNotification("error", this.checkErrors().message);
    } else {
      this.props.save(
        "patch",
        "/api/activityAssignment/" + this.state.formData.uid,
        this.state.formData
      );
    }
  };

  render() {
    //console.log("activityAssignmentDetails",this.props)

    let source = this.props.project?.source?.code;

    return this.state.Status === "Load" ? (
      <PageArea>
        <BodyArea>
          <div className="input-grid">
            {source !== "hub" ? (
              <>
                <TextField
                  width="100%"
                  onChange={this.handleChanges}
                  changeArea="role"
                  value={this.state.formData.role?.name}
                  label="Role"
                  disabled={true}
                />

                <TextField
                  width="100%"
                  onChange={this.handleChanges}
                  changeArea="actualUnit"
                  label="Actual Unit (h)"
                  value={
                    this.state.formData.p6Plan.actualUnits
                      ? this.state.formData.p6Plan.actualUnits.toString() + "h"
                      : "0h"
                  }
                  disabled={true}
                />
                <NumberField
                  width="100%"
                  onChange={this.handleChanges}
                  changeArea="remainingUnit"
                  label="Remaining Unit (h)"
                  value={
                    this.state.formData.p6Plan?.remainingUnits
                      ? this.state.formData.p6Plan.remainingUnits.toString() +
                        "h"
                      : "0h"
                  }
                  disabled={false}
                  required={true}
                />

                <NumberField
                  width="100%"
                  onChange={this.handleChanges}
                  changeArea="atCompletionUnit"
                  label="At Completion (h)"
                  value={this.state.formData.p6Plan?.atCompletionUnits}
                  disabled={true}
                />
                <NumberField
                  width="100%"
                  onChange={this.handleChanges}
                  changeArea="plannedUnit"
                  label="Planned Unit (h)"
                  value={this.state.formData.p6Plan?.plannedUnits}
                  disabled={true}
                />
              </>
            ) : (
              <>
                <NumberField
                  width="100%"
                  onChange={this.handleChangesP6}
                  changeArea="remainingUnits"
                  value={this.state.formData?.p6Plan?.remainingUnits}
                  label="Remaining Units (h)"
                  required={true}
                  errorMessage={this.state.errorMessages["remaining"]?.message}
                  error={this.state?.errorMessages["remaining"]?.error}
                />

                <DynamicLookup
                  endpoint="/api/resourcerole"
                  onChange={this.handleChanges}
                  required={true}
                  formId="role"
                  searchAttribute="name"
                  valueAttribute="uid"
                  labelAttribute="name"
                  width="100%"
                  label="Assignment Role"
                  value={{
                    value: this.state.formData?.role?.uid,
                    label: this.state?.formData?.role?.name,
                  }}
                />

                <DateArea
                  width="100%"
                  onChange={this.handleChangesStartDate}
                  changeArea="startDate"
                  label="Start Date"
                  value={this.state.formData?.p6Plan?.startDate}
                  required={true}
                  errorMessage={this.state.errorMessages["startDate"].message}
                  error={this.state?.errorMessages["startDate"]?.error}
                  warning={this.state?.errorMessages["startDate"]?.warning}
                />
                <DateArea
                  width="100%"
                  onChange={this.handleChangesFinishDate}
                  changeArea="finishDate"
                  label="Finish Date"
                  value={this.state.formData?.p6Plan?.finishDate}
                  required={true}
                  errorMessage={
                    this.state?.errorMessages["finishDate"]?.message
                  }
                  error={this.state?.errorMessages["finishDate"]?.error}
                  warning={this.state?.errorMessages["finishDate"]?.warning}
                />
              </>
            )}
          </div>
        </BodyArea>
        {source === "hub" ? (
          <ButtonArea>
            <MainButton
              onClick={this.update}
              key="newTaskButton"
              className="button-area-action"
              iconLineColor="#fff"
              icon={<HiSave />}
              label="Save"
            />
          </ButtonArea>
        ) : (
          false
        )}
      </PageArea>
    ) : (
      <PageArea>
        <BodyArea>
          <StatusScreen
            label={this.state.Status}
            message={this.state.ErrorMessage}
            onClick={this.GetDatas}
            loadingType="inputs"
          />
        </BodyArea>
      </PageArea>
    );
  }
}

export default ActivityAssignmentDetails;

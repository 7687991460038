import React, { Component } from 'react';

import InputControl from '../InputControl';

class textArea extends Component {

    constructor(props) {
      super(props);
      this.state={v: '', focus:false, error:false, errorMessage: ''}
      this.handleFocus = this.handleFocus.bind(this);
      this.onChange = this.onChange.bind(this);
    }

    handleFocus(t) {
      let errorMessage = t===false&&this.props.required&&this.state.v.length==0?'This field is required.':'';
      let error = t===false&&this.props.required&&this.state.v.length==0?true:false;
      this.setState({...this.state, focus:t, error:error, errorMessage: errorMessage}) 
    }


    componentDidMount(){

      //console.log("componentDidMount TextField");
      this.setState({...this.state, v:this.props.value?this.props.value:''}) 
    }

    
    componentDidUpdate(prevProps)
    {
      if(prevProps.value!==this.props.value)
      {
         this.setState({...this.state, v:this.props.value?this.props.value:''}) 
      }
    }


    onChange(e)
    {   //console.log("onChange");
        //console.log(e);
        this.setState({...this.state, v: e.target.value}); 
       this.props.onChange!=undefined? this.updateUpperComponent(e):console.log("No OnChange")
    
    }

    updateUpperComponent = (e) =>{
        this.props.onChange!=undefined?this.props.onChange(this.props.changeArea,e.target.value):this.props.onKeyDown(this.props.changeArea,e.target.value)

    }


     listener = (event) => {
        // console.log("listener");
         //console.log(event);
      if (event.key === "Enter" || event.key === "NumpadEnter") {
        //console.log("Enter");
        event.preventDefault();
        // callMyFunction();
        this.onChange(event);
        this.updateUpperComponent(event);
        this.setState({...this.state, v: ''}); 
      }
    };

    render() {
        return (
            <InputControl className="textarea" inputValue={this.state.v} type="textarea" error={this.state.error} errorMessage={this.state.errorMessage} focus={this.state.focus} {...this.props}>
              <textarea 
                onChange={this.onChange} 
                disabled={this.props.disabled} 
                rows={this.props.rows!=undefined?this.props.rows:5} 
                type={this.props.type?this.props.type:"text"} 
                value={this.state.v} 
                defaultValue={this.state.v} 
                onFocus={()=>this.handleFocus(true)} 
                onBlur={()=>this.handleFocus(false)}
                style={{...this.props.inputStyle}}
                placeholder={this.props.placeholder}
                onKeyDown={this.props.onKeyDown!==undefined?this.listener:null}
               />
            </InputControl>
        );
    }

}

export default textArea;
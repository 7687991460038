import React, { Component } from 'react'
import CopyTaskInfo from './copyTaskInfo'
export default class modalTask extends Component {
    
  render() {
      //console.log("modalTask",this.props)
    return (
      <CopyTaskInfo selectedRecord={this.props.selectedRecord} data={this.props.data} selecteds={ this.props.selecteds} copyList={this.props.copyList} pasteTask={this.props.pasteTask}  pasteType={this.props.pasteType} onClose={this.props.onClose}/>    
      
      )
  }
}

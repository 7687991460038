import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../badge';

class Tab extends Component {

    handleClick = () => {
        this.props.onClick(this.props.to)
    }
    render() {
        return (<div to={this.props.to} onClick={this.handleClick} className={this.props.activePage==this.props.to?"hub-tab active":"hub-tab"} >
                    <div className="body">
                        <Badge style={{display:"inline-flex", position:"static", transform:"none", marginLeft: 6}} value={this.props.badgeContent}>{this.props.label}</Badge>
                    </div>
                    <div className="border"></div>
               </div>
               );  
    }
}

export default Tab;
import React, { Component } from "react";
import TextArea from "../../../../common/inputControls/textArea2/textArea";
import MainButton from "../../../../common/buttons/MainButton";
import { faChevronLeft } from "@fortawesome/pro-duotone-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateUtils } from "../../../../common/Commons";
import Section from "../../../../common/pageElements/section";
import TextField from "../../../../common/inputControls/textField";
import BlockResolve from "./blockResolve";
import TooltipWithPage from "../../../../common/tooltipWithPage/tooltipWithPage";
import Auth from "../../../../auth/Auth";
export default class blockDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      description: "",
      showBlockResolveTooltip: false,
    };
  }

  toggleBlockResolveTooltip = () => {
    this.setState({
      ...this.state,
      showBlockResolveTooltip: !this.state.showBlockResolveTooltip,
    });
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      description: this.props.selectedRecord.blockResolveDescription
        ? this.props.selectedRecord.blockResolveDescription
        : "",
    });
  }

  handleChanges = (e, val) => {
    this.setState({ ...this.state, description: val });
  };

  resolveBlock = (uid, object, colIndex, rowIndex, stage) => {
    //console.log("resolveBlock",uid,object,colIndex,rowIndex,stage)
    let blockObject = {};
    //let blockOwner={}

    //blockOwner.id=this.state.blockOwner.id
    blockObject.blockResolveDescription = this.state.description;
    blockObject.blockDescription = this.props.selectedRecord.blockDescription;
    blockObject.blockReason = this.props.selectedRecord.blockReason;
    blockObject.blockOwner = this.props.selectedRecord.blockOwner;
    blockObject.isBlocked = false;

    //  this.props.blockTask(this.props.selectedRecord.uid, blockObject,this.props.selectedRecord.stage?.code)
    this.props.blockTask(uid, object, stage);
  };

  render() {
    //console.log(this.state)
    let util = new DateUtils();
    let t = this.props.selectedRecord;
    //console.log(t)
    return (
      <div className="block-details-wrapper">
        <div className="go-back" onClick={this.props.toggleShowDetails}>
          <FontAwesomeIcon
            style={{ marginRight: "5px", color: "#0E73F6" }}
            icon={faChevronLeft}
            color={"black"}
          />
          Back to Task List
        </div>
        <div
          className="hub-button-area"
          style={{ padding: "0px 8px 8px 16px", position: "relative" }}
        >
          <MainButton
            disabled={t.isBlocked ? false : true}
            onClick={this.toggleBlockResolveTooltip}
            key="newTaskButton"
            className="button-2"
            label="Resolve"
          />
          {this.state.showBlockResolveTooltip ? (
            <TooltipWithPage
              title={"Resolve Block"}
              closePanel={this.toggleBlockResolveTooltip}
              style={{ width: "400px" }}
              arrowLocaiton={{ marginLeft: "8px" }}
            >
              <BlockResolve
                task={t}
                blockTask={this.resolveBlock}
                closePanel={this.toggleBlockResolveTooltip}
              />
            </TooltipWithPage>
          ) : (
            false
          )}
        </div>
        <div style={{ flex: 1, overflow: "auto" }}>
          <Section title="Task Details">
            <TextField disabled value={t.code} width="28%" label="Task Code" />
            <TextField disabled value={t.name} width="60%" label="Task Name" />
            <TextField
              disabled
              value={t.owner?.fullName}
              width="60%"
              label="Responsible"
            />
          </Section>
          <Section title="Block Information">
            <TextField
              disabled
              value={t.blockOwner?.fullName}
              width="30%"
              label="Blocked By"
            />
            <TextField
              disabled
              value={util.Date2StrHour(t.blockCreatedDate)}
              width="25%"
              label="Block Date"
            />
            <TextField
              disabled
              value={t.isBlocked ? "Blocked" : "Resolved"}
              width="35%"
              label="Block Status"
            />
            <TextField
              disabled
              value={t.blockOwner?.fullName}
              width="45%"
              label="Block Assignee"
            />
            <TextField
              disabled
              value={t.blockReason?.name}
              width="45%"
              label="Block Reason"
            />
            <div style={{ height: 310 }}>
              <TextArea
                disabled
                value={t.blockDescription}
                width="90%"
                label="Block Description"
              />
            </div>
          </Section>
          {t.blockResolveDate !== null ? (
            <Section title="Resolve Information">
              <TextField
                disabled
                value={t.blockResolveBy?.fullName}
                width="45%"
                label="Resolved By"
              />
              <TextField
                disabled
                value={util.Date2StrHour(t.blockResolveDate)}
                width="45%"
                label="Resolved Date"
              />
              <div style={{ height: 250 }}>
                <TextArea
                  disabled
                  value={t.blockResolveDescription}
                  width="90%"
                  label="Resolve Description"
                />
              </div>
            </Section>
          ) : (
            false
          )}
        </div>
      </div>
    );
  }
}

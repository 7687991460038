import React from "react";

import Badge from "../../badge";
import "../note.css";
import NoteIcon from "./noteIcon";
import NoteRightPanel from "./noteRightPanel";

const NoteComp = ({
  changeState,
  dataSet,
  retrieveConversation,
  selectedComment,
  deleteNote,
  hasScroll
}) => {
  const isHighlighted = selectedComment?.uid === dataSet?.uid;
  return (
    <div className={`noteComp ${isHighlighted ? "highlighted" : ""}`}>
      <Badge
        value={dataSet?.unread ? 1 : 0}
        style={{ right: 20, top: 5 }}
        variant="dot"
      >
        <NoteIcon
          dataSet={dataSet}
          firstName={dataSet.createdBy.firstName}
          lastName={dataSet.createdBy.lastName}
          fullName={dataSet.createdBy.fullName}
          color={dataSet.createdBy.color}
        />
      </Badge>
      <NoteRightPanel
        retrieveConversation={retrieveConversation}
        dataSet={dataSet}
        changeState={changeState}
        deleteNote={deleteNote}
        hasScroll = {hasScroll}
      />
    </div>
  );
};

export default NoteComp;

import React, { Component } from "react";
import MainButton from "./../../../../common/buttons/MainButton";
import {
  faAngleRight,
  faAngleLeft,
  faBackwardStep,
  faForwardStep,
} from "@fortawesome/pro-light-svg-icons";

class Pagination extends Component {
  render() {
    return (
      <div className="hub-widget-grid-pagination">
        <span style={{ paddingRight: "16px" }}>
          {this.props.page * this.props.perPage + 1} -{" "}
          {(this.props.page + 1) * this.props.perPage > this.props.totalRows
            ? this.props.totalRows
            : (this.props.page + 1) * this.props.perPage}{" "}
          of {this.props.totalRows}
        </span>
        <MainButton
          onClick={() => {
            this.props.setPage(0);
          }}
          disabled={this.props.page === 0 ? true : false}
          className="tiny-icon"
        />
        <MainButton
          onClick={() => {
            this.props.setPage(this.props.page - 1);
          }}
          disabled={this.props.page === 0 ? true : false}
          className="tiny-icon"
        />
        <MainButton
          onClick={() => {
            this.props.setPage(this.props.page + 1);
          }}
          disabled={
            this.props.page ===
            Math.floor((this.props.totalRows - 1) / this.props.perPage)
              ? true
              : false
          }
          className="tiny-icon"
        />
        <MainButton
          onClick={() => {
            this.props.setPage(
              Math.floor(this.props.totalRows / this.props.perPage)
            );
          }}
          disabled={
            this.props.page ===
            Math.floor((this.props.totalRows - 1) / this.props.perPage)
              ? true
              : false
          }
          className="tiny-icon"
        />
      </div>
    );
  }
}

export default Pagination;

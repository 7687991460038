import React, { Component } from "react";
import DataTable from "../../common/dataTable";

import MainButton from "../../common/buttons/MainButton";
import Modal from "../../common/modal";

import ActivityAssignmentExpanded from "./ActivityAssignmentExpanded";

import { faArrowsMaximize } from "@fortawesome/pro-light-svg-icons";
export default class SideBarActivityAssignments extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, refresh: false };
  }

  showModal = () => {
    this.setState({ ...this.state, showModal: true, refresh: false }, () => {
      document
        .getElementById("root")
        .appendChild(document.getElementById("hub-modalWrapper"));
    });
  };

  hideModal = () => {
    //console.log("hideModal")
    this.setState({ ...this.state, showModal: false, refresh: true });
  };

  render() {
    //console.log(this.props)
    const dtConfig = {
      endPoint:
        "/api/projects/" +
        this.props.selectedRecord.projectId +
        "/wbs/" +
        this.props.selectedRecord.wbsId +
        "/activity/" +
        this.props.selectedRecord.uid +
        "/activityAssignment",
      columns: [
        ,
        { id: "code", dataKey: "code", label: "code", type: "text" },
        { id: "role", dataKey: "role.name", label: "Role", type: "text" },

        {
          id: "p6Plan.plannedStart",
          dataKey: "p6Plan.plannedStart",
          label: "Planned Start (P6)",
          type: "date",
        },
        {
          id: "p6Plan.plannedFinish",
          dataKey: "p6Plan.plannedFinish",
          label: "Planned Finish (P6)",
          type: "date",
        },
        {
          id: "p6Plan.plannedDuration",
          dataKey: "p6Plan.plannedDuration",
          label: "Planned Duration (P6)",
          type: "day",
        },

        {
          id: "p6Plan.baselineStart",
          dataKey: "p6Plan.baselineStart",
          label: "Baseline Start (P6)",
          type: "date",
        },
        {
          id: "p6Plan.baselineFinish",
          dataKey: "p6Plan.baselineFinish",
          label: "Baseline Finish (P6)",
          type: "date",
        },
        {
          id: "p6Plan.baselineDuration",
          dataKey: "p6Plan.baselineDuration",
          label: "Baseline Duration (P6)",
          type: "day",
        },

        {
          id: "p6Plan.actualStart",
          dataKey: "p6Plan.actualStart",
          label: "Actual Start (P6)",
          type: "date",
        },
        {
          id: "p6Plan.actualFinish",
          dataKey: "p6Plan.actualFinish",
          label: "Actual Finish (P6)",
          type: "date",
        },
        {
          id: "p6Plan.actualDuration",
          dataKey: "p6Plan.actualDuration",
          label: "Actual Duration (P6)",
          type: "day",
        },

        {
          id: "p6Plan.baselineUnits",
          dataKey: "p6Plan.baselineUnits",
          label: "Baseline Units (P6)",
          type: "hour",
        },
        {
          id: "p6Plan.plannedUnits",
          dataKey: "p6Plan.plannedUnits",
          label: "Planned Units (P6)",
          type: "hour",
        },
        {
          id: "p6Plan.actualUnits",
          dataKey: "p6Plan.actualUnits",
          label: "Actual Units (P6)",
          type: "hour",
        },
        {
          id: "p6Plan.remainingUnits",
          dataKey: "p6Plan.remainingUnits",
          label: "Remaining Units (P6)",
          type: "hour",
        },
        {
          id: "p6Plan.atCompletionUnits",
          dataKey: "p6Plan.atCompletionUnits",
          label: "At Completion Units (P6)",
          type: "hour",
        },

        {
          id: "p6Plan.remainingDuration",
          dataKey: "p6Plan.remainingDuration",
          label: "Remaining Duration (P6)",
          type: "day",
        },
        {
          id: "p6Plan.atCompletionDuration",
          dataKey: "p6Plan.atCompletionDuration",
          label: "At Completion Duration (P6)",
          type: "day",
        },
      ],
      idAttribute: "uid",
      nameAttribute: "name",
      view: {
        visibleColumnList: [
          "role",
          "p6Plan.plannedStart",
          "p6Plan.plannedFinish",
          "p6Plan.baselineUnits",
          "p6Plan.plannedUnits",
          "p6Plan.actualUnits",
          "p6Plan.remainingUnits",
          "p6Plan.atCompletionUnits",
        ],
        mode: "list",
        hideToolbar: true,
        hideActionbar: true,
        hideSelectbox: true,
      },
    };

    return (
      <>
        <div
          className="hub-dataTable-toolBar"
          style={{ border: 0, height: "40px", justifyContent: "flex-end" }}
        >
          {/* */}{" "}
          <MainButton
            style={{
              border: "1px solid rgba(0,0,0,.15)",
              paddingRight: "8px",
              paddingLeft: "8px",
            }}
            className="tiny-text"
            onClick={this.showModal}
            label="Expand"
          />
        </div>
        <DataTable
          key="OWAssignmentTable"
          config={dtConfig}
          refresh={this.state.refresh}
          isSidebarTable
        />

        <Modal
          key="OWAssignmentTable-modal"
          title={this.props.selectedRecord.name}
          show={this.state.showModal}
          onClose={this.hideModal}
          height={"80%"}
          width={"90%"}
        >
          {/*  <DataTable key="OWAssignmentTable" config={dtConfig}  />*/}
          {this.state.showModal ? (
            <ActivityAssignmentExpanded
              selectedRecord={this.props.selectedRecord}
              project={this.props.project}
            />
          ) : (
            ""
          )}
        </Modal>
      </>
    );
  }
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

import "./CardToolbarIcon.css";

class CardToolbarIcon extends Component {
  render() {
    return (
      <div
        onClick={this.props.onClick}
        className={
          "hub-cardToolbarIcon" +
          (this.props.className ? " " + this.props.className : "") +
          (this.props.active ? " active" : "") +
          (this.props.hovered ? " hovered" : "") +
          (this.props.selected ? " selected" : "")
        }
      >
        <div className="icon">{this.props.icon}</div>
        {this.props.label ? (
          <div className={"label" + (this.props.selected ? " active" : "")}>
            {this.props.label}
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

export default CardToolbarIcon;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import HubIcon from "../common/hubIcon/HubIcon";

class NavigationLink extends Component {
  render() {
    return (
      <Link
        className={
          this.props.activeNavId === this.props.link.id
            ? "hub-navMenuLink hub-navActive"
            : "hub-navMenuLink"
        }
        key={this.props.link.id}
        to={this.props.link.to}
      >
        <div className="hub-icon">
          <HubIcon fontSize={20} bgColor="none" lineColor="#ffffff">
            {this.props.link.icon}
          </HubIcon>
        </div>

        <div className="hub-navMenuName">{this.props.link.label}</div>
      </Link>
    );
  }
}

export default NavigationLink;

import React, { Component } from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { faSquareCheck, faSquare, faAngleDown as faArrowAsc, faAngleUp as faArrowDesc  } from '@fortawesome/pro-regular-svg-icons'
import {faSpinner, faSquare as faSquareFilled} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MainButton from './../../../common/buttons/MainButton';
import Auth from './../../../auth/Auth';
import AddTaskModalFilter from './AddTaskModalFilter';
import {Utils, DateUtils} from './../../../common/Commons';
import ToggleSwitch from './ToggleSwitch';
import { eu, th } from 'date-fns/locale';

class AddTaskModal extends Component {
    constructor(props){
        super(props);
        this.state={tasks:[], filteredTasks: [], selected:[], 
            filters:{
                task_name: "",
                project_name: "", 
                wbs_name: "", 
                activity_name: "", 
                other_work:false,
                this_week:true,
            },
            Loading: true,
            periodStart: this.props.periodStart,
            periodFinish: this.props.periodFinish,
            timeReportLines: this.props.timeReportLines.map(l => { return l.assignmentId}),
            sortColumn: 'startDate',
            sortType: 'ASC'
         }
    }

    loadTasks = () => {
        
        let utils = new Utils();
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch('/api/assignment/'+utils.findGetParameter("resourceId"), requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                if(r.status==200) {
                    
                    this.setState({...this.state, Loading:false, tasks:r.body.assignment, filteredTasks:this.createFilteredTasks(r.body.assignment, this.state.filters)});
                }
                else if(r.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    }

    filter = (filter_id, val) => {
        let newState = {...this.state}
        newState.filters[filter_id] = val;
        newState.filteredTasks =  this.createFilteredTasks(this.state.tasks, newState.filters);
        this.setState(newState);
    }

    createFilteredTasks = (tasks, filters) => {
        // state'teki bulk task listesinden filtre uygulanmış yeni listeyi oluşturur.
        let filteredTasks = [];
        {tasks.map( (t, i) => {
                                        
            let valid = 1;
            
            // task_filter 
            if(filters.task_name.length > 0 && !(new RegExp(filters.task_name, "i")).test(t.taskName)){
                valid=0;
            }
            // project_filter 
            if(filters.project_name.length > 0 && !(new RegExp(filters.project_name, "i")).test(t.projectName)){
                valid=0;
            }
            // wbs_filter 
            if(filters.wbs_name.length > 0 && !(new RegExp(filters.wbs_name, "i")).test(t.wbsName)){
                valid=0;
            }
            // activity_filter 
            if(filters.activity_name.length > 0 && !(new RegExp(filters.activity_name, "i")).test(t.activityName)){
                valid=0;
            }

            // Daha önce timesheet'e ekli ise listeye gelmesin
            if (this.state.timeReportLines.indexOf(t.uid)>-1) {
                valid=0;
            }
           
            if (filters.other_work) {
                if (t.projectType !== 30) {
                    valid = 0;
                }

            } else {
                if (t.projectType== 30) {
                    valid = 0;
                }
            }
            if(filters.this_week)
            {
                if(t.finishDate<=this.state.periodStart)
                {
                    valid= 0;
                }
                if(t.startDate >= this.state.periodFinish)
                {
                    valid=0;
                }

            }
            if (valid == 1 ) {
                filteredTasks.push(t);
            }
        })}
        return filteredTasks;
    }
    
    add = () => {
       
        let tasks = [...this.state.selected];
        
        // Eklenen satırı tablodan kaldır
        let newTasks = [];
        this.state.tasks.map((t,i) => {
        if(this.state.selected.findIndex(x => x.uid === t.uid)<0)
            {
                newTasks.push(t);
            }
        })

        this.setState({...this.state, tasks:newTasks, filteredTasks:this.createFilteredTasks(newTasks, this.state.filters), selected:[]}, ()=> { this.props.addTimeReportLines(tasks)}  )
    
    }




    addAndClose = () => {
        let tasks = [...this.state.selected];
        this.props.addTimeReportLines(tasks);
        this.props.onClose();
    }

    selectTask = (i) => {
        // i => task'in uid

        let newState = {...this.state}
    
        let ind = newState.filteredTasks.findIndex((t) => t.uid === i);

        newState.filteredTasks[ind].isSelected = !newState.filteredTasks[ind].isSelected;

        if(newState.filteredTasks[ind].isSelected) {
            newState.selected.push(newState.filteredTasks[ind]);
        } else {
            newState.selected = newState.selected.filter(e => e.uid !== i);
        }
        
        newState.tasks[newState.tasks.findIndex((t) => t.uid === i)] = newState.filteredTasks[ind];
  
        this.setState(newState);
    
    }

    handleSelectAll = () => {
        switch(this.checkSelectAllState()) {
            case 'none':
              this.selectAll();
              break;
            case 'mixed':
                this.selectAll();
                break;
            case 'all': 
                this.deselectAll();
                break;
          }
    } 

    checkSelectAllState = () => {
        /*  
        Koşulun tek elden yönetilmesi için oluşturulmuş method. 
        3 değerden birini döner:  all, none, mixed
        */
        
        let ftIds = this.state.filteredTasks.map(t => t.uid).sort();
        let sIds = this.state.selected.map(t => t.uid).sort(); // Selected Tasks Ids    

        if( sIds.length===0) {
            return 'none'
        } else {
            if (JSON.stringify(ftIds) === JSON.stringify(sIds)) {
                return 'all';
            } else {
                return 'mixed';
            }
        }

    }

    deselectAll = () => {
        let newState = {...this.state}
        //Seçimler Kaldırılıyor;
        newState.selected = [];
        newState.tasks.map(t =>{t.isSelected = false; return t});
        newState.filteredTasks.map(t =>{t.isSelected = false; return t});
        this.setState(newState);
    }

    sortTasks = (tasks, sortColumn, sortType) => {

        let newTasks = [...tasks];
        newTasks.sort((a,b) => {
            let result = 0; 

            switch (sortColumn) {
                case 'remainingUnit':
                    result = a.remainingUnit > b.remainingUnit ? 1 : -1;
                    
                    break;
                case 'startDate':
                    result = new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
                    break;
                case 'finishDate':
                    result = new Date(a.finishDate).getTime() - new Date(b.finishDate).getTime();
                    break;
                default:
                    result = a[sortColumn]?.localeCompare(b[sortColumn], 'tr', {sensitivity: 'base'});    
                    break;
            }
          
            if (sortType === "DESC") {
                result = result * -1
            }
            return result;

        });

        return newTasks;
    }

    selectAll = () => {
        // Tüm selectler kaldırılıp sadece filteredValue'lar selected yapılır. 

        let newState = {...this.state}
        //Seçimler Kaldırılıyor;
        newState.selected = [];
        newState.tasks.map(t =>{t.isSelected = false; return t});

        //Filtered Value'lar seçili yapılıyor.
        newState.filteredTasks.map( (t, i) => {
            newState.selected.push(t);
            t.isSelected = true;
            // Benzer şekilde ham listede de selected apılıyor.
            newState.tasks[newState.tasks.findIndex((t) => t.uid === newState.filteredTasks[i].uid)] = newState.filteredTasks[i];
            return t;
        });

        this.setState(newState);
    }

    componentDidMount(){
        this.loadTasks();
    } 

    checkSort = (sortColumn) => {
        if( this.state.sortColumn === sortColumn) {
            return <FontAwesomeIcon icon={(this.state.sortType ==='ASC')?faArrowAsc:faArrowDesc} />
        } else {return false;}
    }

    setSort = (sortColumn) => {
        let newState = {...this.state}
        if( this.state.sortColumn === sortColumn) {
            newState.sortType = newState.sortType==='ASC'?'DESC':'ASC';
        } else {
            newState.sortColumn = sortColumn;
            newState.sortType = 'ASC';
        }
        this.setState(newState);
    }
    


    render() {        
        let dateUtils = new DateUtils();
        
        let ftIds = this.state.filteredTasks.map(t => t.uid).sort();
        let sIds = this.state.selected.map(t => t.uid).sort(); // Selected Tasks Ids
        
        let selectAllIcon;
        switch(this.checkSelectAllState()) {
            case 'all':
                selectAllIcon = faSquareCheck;
                break;
            case 'none':
                selectAllIcon = faSquare;
                break;
            case 'mixed': 
                selectAllIcon = faSquareFilled;
                break;
        }

        // Burada yapmak doğru değil. Ama v2'de revize ederiz. 
        let sortedTasks = this.state.filteredTasks;
        if(this.state.filteredTasks.length>0){
            sortedTasks = this.sortTasks([...this.state.filteredTasks], this.state.sortColumn, this.state.sortType) // sortedTasks 
        }

        

        return (
            <div className="hub-ts-modalWrapper">
                <div className="hub-ts-modal">
                    <div className="hub-ts-modalHeader">
                        <div className="hub-ts-modalHeader-title"><span className="icon"><FontAwesomeIcon icon={faPlus}/></span> Add Work</div>
                        <div className="hub-ts-modalHeader-buttons">
                        <span ><MainButton style={{marginRight:"15px", width:"70px"}} onClick={this.add} label="Add" /></span>
                        <span style={{marginRight:"8px"}}><MainButton onClick={this.addAndClose} label="Add and Close" /></span>
                         <span><MainButton className="button-2" onClick={this.props.onClose} label="Close" /></span>
                        </div>
                    </div>
                    <div className="hub-ts-modalBody">
                        <div className="hub-ts-modalBody-filter">
                            <AddTaskModalFilter filterId="task_name" filterValue={this.state.filters.task_name} onChange={this.filter} placeholder="Task Name / ID" />
                            <AddTaskModalFilter filterId="project_name" filterValue={this.state.filters.project_name} onChange={this.filter} placeholder="Project Name / ID" />
                            <AddTaskModalFilter filterId="wbs_name" filterValue={this.state.filters.wbs_name}  onChange={this.filter} placeholder="WBS Name / ID" />
                            <AddTaskModalFilter filterId="activity_name" filterValue={this.state.filters.activity_name} onChange={this.filter} placeholder="Activity Name / ID" />
                            <ToggleSwitch label="Other Work" filterId="other_work" filterValue={this.state.filters.other_work} onChange={this.filter} checked={this.state.filters.other_work} />
                            <ToggleSwitch label="This Week" filterId="this_week" filterValue={this.state.filters.this_week} onChange={this.filter}  checked={this.state.filters.this_week} />
                        </div>
                        <div className="hub-ts-modalBody-body">
                            {this.state.Loading===true?<div><FontAwesomeIcon icon={faSpinner} color="#D7EDFF" size="3x" spin={true}/></div>:this.state.tasks===undefined||this.state.tasks?.length===0?<span>No available tasks found.</span>:
                            <table cellPadding="0" className="hub-ts-modalBody-table" cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th><FontAwesomeIcon icon={selectAllIcon} onClick={this.handleSelectAll} style={{cursor:"pointer"}} /></th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('projectName')}}>Project {this.checkSort('projectName')}</th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('wbsName')}}>WBS {this.checkSort('wbsName')}</th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('activityName')}}>Activity {this.checkSort('activityName')}</th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('taskName')}}>Task {this.checkSort('taskName')}</th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('startDate')}}>Start {this.checkSort('startDate')}</th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('finishDate')}}>Finish {this.checkSort('finishDate')}</th>
                                        <th style={{cursor:'pointer'}} onClick={() => {this.setSort('remainingUnit')}}>Remaining {this.checkSort('remainingUnit')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedTasks.map( (t, i) => {
                                            return <tr onClick={() => {this.selectTask(t.uid)}} >
                                                <td><FontAwesomeIcon icon={t.isSelected?faSquareCheck:faSquare} /></td>
                                                <td>{t.projectName}</td>
                                                <td>{t.wbsName}</td>
                                                <td>{t.activityName}</td>
                                                <td>{t.taskName}</td>
                                                <td>{dateUtils.Date2Str(t.startDate)}</td>
                                                <td>{dateUtils.Date2Str(t.finishDate)}</td>
                                                <td>{t.remainingUnit?t.remainingUnit.toFixed(2):0}</td>
                                            </tr>
                                    })}
                                </tbody>
                            </table>
                                }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddTaskModal;
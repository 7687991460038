import React, { Component } from "react";

export default class metricChart extends Component {
  render() {
    return (
      <div className="metric-wrapper">
        <div className="hub-do-metricChart">
          {this.props.header ? (
            <div className="title">{this.props.header}</div>
          ) : (
            false
          )}
          {this.props.config?.nonPercent ? (
            <div className="value">{this.props.data.value ?? 0}</div>
          ) : (
            <>
              <div className="value">
                <span className="percantage-symbol"> % </span>
                {this.props.data.value ? this.props.data.value : 0}
              </div>
              {this.props.config.subText ? (
                <div className="subText">
                  {this.props.config.subText + " "}
                  {this.props.data.secondvalue + " "}
                  {this.props.config.subText2}
                </div>
              ) : (
                false
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

import React, { Component } from 'react'
import {Draggable} from 'react-beautiful-dnd';
import { Droppable} from 'react-beautiful-dnd';
import ToDoNewInput from './toDoNewInput'
export default class ToDoList extends Component {



  render() {

    let todoList=this.props.todoList;

    //console.log("todo",todoList)

    return (

   
    
      <Droppable droppableId={this.props.columnIndex.toString()+'-'+this.props.taskIndex.toString()} isDropDisabled={false} type="todo">
        {(droppableProvided) => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>

            {todoList.sort((a, b) => a?.orderNumber > b?.orderNumber ? 1 : -1).map((t,i)=>{
              return <Draggable isDragDisabled={false} key={t.uid} draggableId={(t.uid).toString()} index={i}>
                        {(draggableProvided, draggableSnapshot) => ( 
                              <div className='items' ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}{...draggableProvided.dragHandleProps} >
                                  {draggableProvided.placeholder}
                                  <ToDoNewInput 
                                    todo={t}
                                    buttonName={"Save"}
                                    changeArea={"name"}
                                    method={"PATCH"}
                                    task={this.props.task} 
                                    crud={this.props.crud}
                                    taskIndex={this.props.taskIndex} 
                                    columnIndex={this.props.columnIndex} 
                                    index={i}
                                    />
                              </div>
                        )}
                      </Draggable>
              })
            }
         {droppableProvided.placeholder}

        </div>
        )}
       
      </Droppable>     

    )
  }
}

import React, { useEffect, useRef, useState } from "react";

import NoteArea from "../../../common/sideBarPageContent/noteArea/noteArea.js";
import BodyArea from "../../../common/sideBarPageContent/bodyArea/bodyArea.js";
import PageArea from "../../../common/sideBarPageContent/pageArea/pageArea.js";
import StatusScreen from "../../../common/statusScreen/statusScreen.js";
import NoteList from "../../../common/note/noteList/noteList";
import RichTextEditor from "../../../common/richTextEditor/index.js";
import {
  createConversation,
  deleteConversation,
  getConversation,
  getConversationList,
  updateConversation,
} from "../../../api/task/index.js";
import { htmlToSlate } from "../../../common/Commons.js";

let emptyValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

let loadingValue = [
  {
    type: "paragraph",
    children: [{ text: "Sending..." }],
  },
];

let buttonIsLoading = false;

const TaskSideNotes = ({ selectedRecord }) => {
  const [conversationList, setConversationList] = useState([]);
  const [uiState, setUiState] = useState({
    Loading: true,
    Status: "Loading",
    ErrorMessage: "",
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [initialValue, setInitialValue] = useState(emptyValue);
  const [editorLoading, setEditorLoading] = useState(false);
  const [existingFiles, setExistingFiles] = useState([]);
  const [hasScroll, setHasScroll] = useState(false);

  const listRef = useRef();

  useEffect(() => {
    setUiState({ Loading: true, Status: "Loading" });
    getConversations();
  }, [selectedRecord.uid]);

  const getConversations = async () => {
    const response = await getConversationList(selectedRecord.uid);

    if (response.status == 200 && response.isSuccess) {
      setConversationList(response.data.object);
      setUiState((prevState) => {
        return {
          ...prevState,
          Loading: false,
          Status: "Load",
        };
      });
    } else if (!response.isSuccess) {
      setConversationList(response.data.object);
      setUiState((prevState) => {
        return {
          ...prevState,
          Loading: false,
          Status: "Load",
        };
      });
    } else if (response.status == 401) {
      window.location.replace("/login");
    } else if (response.status == 403) {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          LoadingError: true,
          LoadingErrorText:
            "You are not authorized to see this page. Please contact your system administrator.",
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          LoadingError: true,
          LoadingErrorText:
            "An unexpected error occured. Please contact your system administrator.",
        };
      });
    }
  };

  const createNote = async (message, mentions, files, lastValue) => {
    buttonIsLoading = true;
    loadingValue = lastValue;
    setEditorLoading(true);
    const response = await createConversation(
      selectedRecord.uid,
      message,
      files,
      mentions
    );

    if (response.status === 200) {
      listRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
      getConversations();
      setTimeout(() => {
        setExistingFiles([]);
        setEditorLoading(false);
      }, 150);
    } else if (response.status == 401) {
      window.location.replace("/login");
    } else if (response.status == 403) {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          ErrorMessage: response.data.message,
          LoadingError: true,
          LoadingErrorText:
            "You are not authorized to see this page. Please contact your system administrator.",
        };
      });
    } else if (response.status == 400) {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          Error: true,
          ErrorMessage: response.data.message,
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          ErrorMessage: response.data.message,
          LoadingError: true,
          LoadingErrorText:
            "An unexpected error occured. Please contact your system administrator.",
        };
      });
    }
  };

  const updateNote = async (message, mentions, files, lastValue) => {
    buttonIsLoading = true;
    loadingValue = lastValue;
    setEditorLoading(true);
    const response = await updateConversation(
      selectedRecord.uid,
      selectedComment.uid,
      message,
      files,
      mentions
    );

    if (response.status === 200) {
      setSelectedComment(null);
      getConversations();
      setInitialValue(emptyValue);
      setTimeout(() => {
        setExistingFiles([]);
        setIsEditMode(false);
        setEditorLoading(false);
      }, 150);
    } else if (response.status == 401) {
      window.location.replace("/login");
    } else if (response.status == 403) {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          ErrorMessage: response.data.message,
          LoadingError: true,
          LoadingErrorText:
            "You are not authorized to see this page. Please contact your system administrator.",
        };
      });
    } else if (response.status == 400) {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          Error: true,
          ErrorMessage: response.data.message,
        };
      });
    } else {
      setUiState((prevState) => {
        return {
          ...prevState,
          Status: "Error",
          ErrorMessage: response.data.message,
          LoadingError: true,
          LoadingErrorText:
            "An unexpected error occured. Please contact your system administrator.",
        };
      });
    }
  };

  const getComment = async (comment) => {
    buttonIsLoading = false;
    loadingValue = htmlToSlate(comment?.message);
    setExistingFiles(comment?.conversationFiles);
    setIsEditMode(true);
    setEditorLoading(true);
    const response = await getConversation(comment?.uid, selectedRecord?.uid);
    if (response.status === 200) {
      setExistingFiles(response.data?.object?.conversationFiles);
      setSelectedComment(response.data?.object);
      const nodeValue = htmlToSlate(response.data?.object?.message);
      setInitialValue(nodeValue);
      setTimeout(() => {
        setEditorLoading(false);
      }, 150);
    } else {
      alert("comment not found by the service");
      setTimeout(() => {
        setEditorLoading(false);
      }, 500);
    }
  };

  const deleteNote = async (uid, objectUid) => {
    buttonIsLoading = false;
    const response = await deleteConversation(uid, objectUid);
    isEditMode && setEditorLoading(true);
    if (response.status === 200) {
      if (isEditMode) {
        setInitialValue(emptyValue);
        setTimeout(() => {
          setExistingFiles([]);
          setIsEditMode(false);
          setEditorLoading(false);
        }, 150);
      }
      getConversations();
    } else if (response.status == 401) {
      window.location.replace("/login");
    } else if (response.status == 403) {
      this.setState({
        ...this.state,
        Status: "Error",
        ErrorMessage: response.data.message,
        LoadingError: true,
        LoadingErrorText:
          "You are not authorized to see this page. Please contact your system administrator.",
      });
    } else if (response.status == 400) {
      this.setState({
        ...this.state,
        Status: "Error",
        Error: true,
        ErrorMessage: response.data.message,
      });
    } else {
      this.setState({
        ...this.state,
        Status: "Error",
        ErrorMessage: response.data.message,
        LoadingError: true,
        LoadingErrorText:
          "An unexpected error occured. Please contact your system administrator.",
      });
    }
  };

  const handleCancel = (lastValue) => {
    buttonIsLoading = false;
    setSelectedComment(null);
    loadingValue = lastValue;
    setEditorLoading(true);
    setInitialValue(emptyValue);
    setTimeout(() => {
      setExistingFiles([]);
      setIsEditMode(false);
      setEditorLoading(false);
    }, 150);
  };

  return uiState.Status === "Load" ? (
    <PageArea style={{ overflow: "hidden" }}>
      <BodyArea setHasScroll={setHasScroll} style={{ padding: "0px" }}>
        <NoteList
          retrieveConversation={getComment}
          dataSet={conversationList}
          changeState={getConversations}
          selectedComment={selectedComment}
          deleteNote={deleteNote}
          ref={listRef}
          hasScroll={hasScroll}
        />
      </BodyArea>
      <NoteArea>
        {editorLoading && (
          <RichTextEditor
            initialValue={loadingValue}
            isEditMode={isEditMode}
            existingFiles={existingFiles}
            isLoading={buttonIsLoading}
            readOnly
          />
        )}
        {!editorLoading && (
          <RichTextEditor
            initialValue={initialValue}
            isEditMode={isEditMode}
            onClickAction={isEditMode ? updateNote : createNote}
            uid={selectedRecord.uid}
            shrinkable={!!conversationList?.length}
            onCancel={handleCancel}
            existingFiles={existingFiles}
          />
        )}
      </NoteArea>
    </PageArea>
  ) : (
    <StatusScreen
      label={uiState.Status}
      message={uiState.ErrorMessage}
      onClick={getConversations}
      loadingType="conversations"
    />
  );
};
export default TaskSideNotes;

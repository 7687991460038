import React, { Component } from 'react';
import './PageHeader.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faArrowLeft} from '@fortawesome/pro-regular-svg-icons'
class PageHeader extends Component {
    render() {
        return (
            <div className="hub-page-header">
                {this.props.title?
                    <div className="title">
                       {this.props.linkto!==undefined? <div className='hub-page-header-title-icon'><Link to={this.props.linkto}> <FontAwesomeIcon icon={faArrowLeft} color={"black"}   size={"1x"}  /></Link> </div>:false}
                        {this.props.title}
                    </div>
                :false}
                
                <div className="center">{this.props.left}</div>
                <div className="right">{this.props.right}</div>
            </div>
        );
    }
}

export default PageHeader;
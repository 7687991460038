const HiUnderline = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill={bgColor ?? "none"}
    >
      <path
        d="M15 3.3335V9.16683C15 11.9283 12.7615 14.1668 10 14.1668C7.23862 14.1668 5.00004 11.9283 5.00004 9.16683V3.3335M3.33337 17.5002H16.6667"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiUnderline;

export const  tagColors=[
    {name:"green",backgroundColor:"#377B58"},
    {name:"blue",backgroundColor:"#3368D6"},
    {name:"orange",backgroundColor:"#B15027"},
    {name:"navy",backgroundColor:"#e1e5f5"},
    {name:"yellow",backgroundColor:"#EDC748"},
    {name:"teal",backgroundColor:"#4BA664"},
    {name:"gray",backgroundColor:"#607180"},

    {name:"lightblue",backgroundColor:"#f4f7fc"},
    {name:"red",backgroundColor:"#DC3545"},


    {name:"lime",backgroundColor:"#4D7C26"},
    {name:"turquoise",backgroundColor:"#35788E"},


    {name:"aqua",backgroundColor:"#3171B5"},
    {name:"ultramarine",backgroundColor:"#565FE0"},
    {name:"purple",backgroundColor:"#864DD6"},
    {name:"pink",backgroundColor:"#BB328F"}



]



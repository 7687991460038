import React from "react";
import { ResponsivePie } from "@nivo/pie";
import GraphLegendSymbol from "../../common/icons/GraphLegendSymbol";



export default class PieChart extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          /*  height: this.props.configuration.heightDiv
            ? this.props.configuration.heightDiv
            : "150px", */
          height: "100%",
          maxHeight: "15vw",
        }}
      >
        <ResponsivePie
          theme={{
            legends: {
              text: {
                fontFamily: "Noto Sans",
                fontSize: 11,
                fontWeight: "400",
                lineHeight: "15px",
              },
            },
            labels: {
              text: {
                fontSize: 14,
                fontFamily: "Open Sans",
                fontWeight: "400",
                lineHeight: "30px",
              },
            },
          }}
          data={this.props.data}
          margin={{ top: 35, right: 0, bottom: 70, left: 0 }}
          innerRadius={0}
          //padAngle parçalar arası boşluk
          padAngle={3}
          //cornerRadius parçalar arası radius
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor="#ffffff"
          arcLinkLabelsTextOffset={3}
          arcLinkLabelsOffset={5}
          colors={["#1769F1", "#8225FA", "#FBBF26", "#E95A63"]}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#000000"
          arcLinkLabelsThickness={1.6}
          arcLinkLabelsColor={{ from: "color" }}
          arcLinkLabelsDiagonalLength={16}
          arcLinkLabelsStraightLength={22}
          arcLabelsSkipAngle={10}
/*           arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }} */
          legends={[
            {
              anchor: "bottom-left",
              direction: "column",
              justify: false,
              translateX: 0,
              translateY: 60,
              itemsSpacing: 0,
              symbolSpacing: 2,
              itemHeight: 15,
              itemTextColor: "#051530",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 15,
              symbolShape: GraphLegendSymbol,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
          {...this.props.configuration}
        />
      </div>
    );
  }
}

import React from "react";

const BarText = ({ ganttWidth, name }) => {
  return (
    <p className="bar-text" style={{ left: ganttWidth + 5 }}>
      {name}
    </p>
  );
};

export default BarText;

import React, { Component } from 'react';

class TextArea extends Component {

    render() {
        return (
            <div className="hub-formInput" style={{width:(this.props.pct?this.props.pct:'20')+"%"}}>
              <div className="hub-textField" style={{width:(this.props.pct?this.props.pct:'20')+"%"}}>
                <div className="label">{this.props.label}</div>
                <div className="formInput" read-only><textarea rows={this.props.rows?this.props.rows:3} style={{width:"100%"}} value={this.props.value} onChange={(e)=>this.props.onChange(this.props.changeArea,e.target.value)}/></div>
              </div>
            </div>
        );
    }

}

export default TextArea;
import React, { Component } from "react";

import "./tooltipWithPage.css";

export default class tooltipWithPage extends Component {
  render() {
    return (
      <div
        className="hub-tooltipWithPage-wrapper"
        style={{ width: 280, ...this.props.style }}
      >
        <div className="hub-tooltipWithPage-container">
          <div className="arrow" style={{ ...this.props.arrowLocaiton }} />
          <div
            className="hub-tooltipWithPage"
            style={{ ...this.props.inLineStyle }}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

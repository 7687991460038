import React from "react";

const HiAddUser = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill={bgColor ?? "none"}
    >
      <path
        d="M11.7847 14.2963C11.5423 13.5215 10.9171 12.9152 10.1181 12.6801C9.76319 12.5758 9.32708 12.5758 8.45485 12.5758H5.32985C4.45762 12.5758 4.02151 12.5758 3.66664 12.6801C2.86763 12.9152 2.24238 13.5215 2 14.2963M12.5 1V3.5M12.5 3.5V6M12.5 3.5H10M12.5 3.5H15M9.70485 7.72727C9.70485 9.2335 8.44565 10.4545 6.89235 10.4545C5.33905 10.4545 4.07985 9.2335 4.07985 7.72727C4.07985 6.22104 5.33905 5 6.89235 5C8.44565 5 9.70485 6.22104 9.70485 7.72727Z"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiAddUser;

import React, { Component } from "react";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainButton from "../buttons/MainButton";

class Modal extends Component {
  render() {
    return (
      <div
        id="hub-modalWrapper"
        className="hub-modalWrapper"
        style={{ display: this.props.show ? "block" : "none" }}
      >
        <div
          className="hub-modal"
          style={{
            width: this.props.width ? this.props.width : "80%",
            height: this.props.height ? this.props.height : "60%",
          }}
        >
          <div className="hub-modalHeader">
            <div className="hub-modalHeader-title">
              {this.props.icon ? (
                <span className="icon">
                  <FontAwesomeIcon icon={this.props.icon} />
                </span>
              ) : (
                false
              )}{" "}
              {this.props.title}
            </div>
            <div className="hub-modalHeader-buttons">
              <MainButton
                className="button-2"
                onClick={this.props.onClose}
                label="Close"
              />
            </div>
          </div>
          <div className="hub-modalBody">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Modal;

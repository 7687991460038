import React, { Component } from "react";
import "./PageFilter.css";
import PageFilterItem from "./PageFilterItem";
import MainButton from "../../common/buttons/MainButton";
import FilterPicker from "../../common/filterPicker/FilterPicker";

class PageFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: [],
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      selectedFilters: this.props.selectedFilters,
    });
  }

  setFilter = (filterProp, filter, selectedFilter, ind) => {
    //console.log("FILTER", filterProp, filter,selectedFilter,ind)

    let newSelectedfilters = [...this.state.selectedFilters];

    let index = newSelectedfilters.findIndex((x) => x.id === filterProp.id);
    let newFilter = {
      id: filterProp.id,
      dataKey: filterProp.dataKey,
      value: filter[filterProp.valueAttribute],
    };
    if (index === -1) {
      newSelectedfilters.push(newFilter);
    } else {
      newSelectedfilters[index] = newFilter;
    }
    //console.log("newSelectedfilters",newSelectedfilters,this.props.filters,newFilter.id)
    let affectAll = null;

    if (filterProp?.affect) {
      affectAll = newFilter;

      this.props.filters
        .filter((x) => x.beAffected && x.affectedId === newFilter.id)
        .map((f) => {
          //  console.log("oktay",f)

          f.selectedFilter = null;
          newSelectedfilters.find((x) => x.id === f.id).value = "null";
        });
    }

    this.setState({ ...this.state, selectedFilters: newSelectedfilters });
    this.props.setSelectedFilter(selectedFilter, ind, undefined, affectAll);
  };
  clearFilter = () => {
    let selectedFilters = this.state.selectedFilters;
    selectedFilters.map((f) => {
      f.value = "null";
    });

    this.setState({ ...this.state, selectedFilters: selectedFilters });

    //console.log(this.state.selectedFilters,selectedFilters)
    //this.props.setFilter(selectedFilters)
    this.props.setSelectedFilter(null, null, 1);
  };

  /*

    column iç değeri
       resource:
       {
        "id": "resource",
        "dataKey": "resource_id",
        "icon": "faUser",
        "label": "Resource",
        "type": "dynamicLookup",
        "endpoint": "/api/query/code/hub_filter_resource_by_obs",
        "searchAttribute": "full_name",
        "valueAttribute": "resource_id",
        "labelAttribute": "full_name",
        "beAffected": true,
        "affectedId": "obs",
        "selectedFilter": null
    }

    {
        "id": "obs",
        "dataKey": "obs_id",
        "label": "Department",
        "type": "dynamicLookup",
        "icon": "faBuildings",
        "endpoint": "/api/obs/teamboard",
        "searchAttribute": "uid",
        "valueAttribute": "uid",
        "labelAttribute": "name",
        "affect": true,
        "selectedFilter": null
    }

    */

  //? isVisible gözükmeyen filtreler için kullanılır.

  render() {
    let filterCount = this.props.filters.filter(
      (x) => x.isVisible === true || x.isVisible === undefined
    ).length;
    // console.log("PageFilter",this.props.filters,filterCount)
    return filterCount > 0 ? (
      <div className="hub-pageFilter">
        {this.props.filters
          .filter((x) => x.isVisible === true || x.isVisible === undefined)
          ?.map((x, i) => (
            /* <PageFilterItem setFilter={this.setFilter} column={x}  index={i} 
                        affectAll={this.props.affectAll.length>0?this.props.affectAll.find(y=>y?.id===x?.affectedId):null } /> */
            <div className="hub-pageFilter-item">
              <FilterPicker
                setFilter={this.setFilter}
                column={x}
                index={i}
                affectAll={
                  this.props.affectAll.length > 0
                    ? this.props.affectAll.find((y) => y?.id === x?.affectedId)
                    : null
                }
              ></FilterPicker>
            </div>
          ))}

        <div className="hub-pageFilter-button-area">
          <MainButton
            style={{ height: 32 }}
            className="hub-button button-area-action"
            label="Apply"
            onClick={() => this.props.setFilter(this.state.selectedFilters)}
          />
          <MainButton
            className="hub-button button-area-action red"
            style={{ backgroundColor: "#ea2246", height: 32 }}
            label="Clear"
            onClick={() => this.clearFilter()}
          />
        </div>
      </div>
    ) : (
      false
    );
  }
}

export default PageFilter;

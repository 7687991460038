import React, { Component } from 'react';
import MainButton from './../../../common/buttons/MainButton';
import TimeReportLine from './TimeReportLine';
import TimesheetTableTh from './TimesheetTableTh';
import TimesheetTHTotals from './TimesheetTHTotals';

import { faPlus, faBars } from '@fortawesome/free-solid-svg-icons'
import {faSquareCheck,faPercent} from '@fortawesome/pro-duotone-svg-icons'

import SideBar from './../../../common/dataTable/SideBar';
import TimesheetNotesSidebarContainer from './TimesheetNotesSidebarContainer'
import TimesheetProgressBar from './TimesheetProgressBar';

const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object)
class TimesheetTable extends Component {

    constructor(props) {
        super(props);
        const columns = [
            { id: "wbs_name", label:"WBS Name", dataKey:"wbs"}
            ,{ id: "activity_name", label:"Activity Name", dataKey:"Activity"}
            //,{ id: "remaining", label:"Remaining", dataKey:"remaining"}
            ,{ id: "planning", label:"Planned Units", dataKey:"assignmentInfo.plannedUnit"}
            ,{id:"assignment_start", label:"Assignment Start", dataKey:"assignmentInfo.startDate"}
            ,{id:"assignment_finish", label:"Assignment Finish", dataKey:"assignmentInfo.finishDate"}
            
        ]
        this.days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        this.state={columns: columns, visibleColumnList:["planning"], visibleColumns:[{ id: "planning", label:"Planned Units", dataKey:"assignmentInfo.plannedUnit"}]}
       
    }

    applyVisibleColumns = (cl) => {


        // Başlangıç değerleri constructor'da oluşuyor. Değişiklik yaparken ilgili bölüme dikkat etmek gerekiyor.
        // cl = Seçilen kolon id'lerinin dizisi

        let oldVisibileColumnList = []; 
        oldVisibileColumnList = [...this.state.visibleColumnList];
        let oldVisibileColumns = [];
        oldVisibileColumns = [...this.state.visibleColumns];

        let newState = {...this.state};
        newState.visibleColumns = [];
        
        newState.columns.map((c) => {
            if(cl.indexOf(c.id)>-1) {
                newState.visibleColumns.push({
                    
                    ...c, 
                    // Aşağıdaki karmaşanın sebebi eğer kayıt daha önce seçili ise ve bir değişiklik yapılmışsa, (width vs. kaybetmemek)
                    width: oldVisibileColumnList.indexOf(c.id)>-1?oldVisibileColumns[oldVisibileColumnList.indexOf(c.id)].width:c.type=="text"||c.type=="day"||c.type=="hour"?240:150
                });
            }
        })
        newState.visibleColumnList = cl
        this.setState(newState);  
    }

    render() {
        return (
            <div className='hub-ts-timesheetTableContainer'>
                <div className="hub-ts-timesheetTableWrapper">
                    <div className="hub-ts-timesheetTable">
                        <div className="hub-ts-timesheetTableHeader">
                            <div className="hub-ts-timesheetTable-th-totals" >
                                <TimesheetTHTotals units={this.props.timesheet.availability} label={"Available Hours"} width={119} />
                                <TimesheetTHTotals units={this.props.timesheet.actual} label={"Submitted Hours"} width={90} />
                            </div>
                            {this.props.selectedPeriod.days.map((p, i) => {    
                                let daystr = ()=> {return <div>{(p.day?this.months[new Date(p.day).getMonth()]:"")}<br />{(p.day?new Date(p.day).getDate().toString():"")}</div>};
                                return <TimesheetTableTh key={"ts-day"+i.toString()} title={(p.day?this.days[new Date(p.day).getDay()]:"")} label={daystr()} className={p.exception===1?"exception":false} subinfo={p.workhours}  total={this.props.timesheet.totals[i]}/>
                            })}
                            <TimesheetTableTh key={"ts-total"} label="Remaining" className="remaining" hideTotal={false} hideSubinfo={false} total={null}  />
                            <TimesheetTableTh key={"ts-remaining"} label="Done" className="done" hideTotal={false} hideSubinfo={false} total={null} />

                        </div>
                        <div className="hub-ts-timesheetTableBody">
                                {this.props.timesheet.timeReportLines.map( (p, i) => {
                                    return   <TimeReportLine timesheet={this.props.timesheet} isEditable={this.props.isEditable} setCompleted = {this.props.setCompleted} visibleColumns={this.props.visibleColumns} removeTimeReportLine={this.props.removeTimeReportLine} days={this.props.selectedPeriod.days}  setWorklogHours={this.props.setWorklogHours} setRemainingHour={this.props.setRemainingHour} lineRow={i} key={"TRL-"+p.uid.toString()} line={p} />
                                })}
                        </div>
                    </div>
                </div>
                 <SideBar title={this.props.timesheet.resource.fullName+"'s Timesheet Notes"} parentRecord={this.props.timesheet} selectedRecord={this.props.timesheet} idAttribute={this.props.timesheet.uid} nameAttribute={this.props.timesheet.name} toggleSidebar = {this.props.toggleSidebar} isOpen = {this.props.isSideBarOpen}>
                        <TimesheetNotesSidebarContainer selectedRecord={this.props.timesheet}/>       
                    </SideBar>
            </div>
        );
    }
}

export default TimesheetTable;
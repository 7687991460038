import React, { Component } from 'react'
import './progressBar.css'

export default class ProgressBar extends Component {
  render() {

    //console.log(this.props,this.props.remaining<=0)

    let result=0
    let ratio=0

   if(this.props.remaining===null || this.props.remaining+this.props.actual ===0 )
    {
      result=0
    }
    else if(this.props.remaining+this.props.actual>0)
    {
        result=parseInt((this.props.actual/(this.props.remaining+this.props.actual))*100)

        result=result>100?100:result
    }

    ratio=parseInt(result*(60/100))

   // console.log("result",result,ratio)
    return (
      
      <div className='card-progress'>
        <div className={'card-progress-label' + (this.props?.color?(" "+this.props?.color):"")}>% {result}</div>
        <div className={'card-progress-bar' + (this.props?.color?(" "+this.props?.color):"")}>
            <div className={'card-progress-bar-inline'+ (this.props?.color?(" "+this.props?.color):" red")} style={{width:ratio}}/>
        </div>
      </div>
   
/*
      <div style={{marginLeft:5,width:"50px"}} className={"tag fl" + (this.props.color)}>
        <a className={this.props.color} style={{width:"50px"}} >% {result}
        
        </a>
      
      </div>
         */
    )
  }
}

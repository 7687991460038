import React, { Component } from "react";

export default class TextWidget extends Component {
  render() {
    return (
      <div className="hub-do-textWidget">
        <div className="hub-do-title" style={this.props.config?.configuration?.style}>
          {this.props.config?.configuration?.label}
        </div>
      </div>
    );
  }
}

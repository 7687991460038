import { skeletonSet } from "../../../static/config";
import SkeletonLoader from "../../skeletonLoader";
import "./InputGroups.scss";

const InputGroups = () => {
  const { label, input, textArea } = skeletonSet;

  const skeletonList = [1, 2, 3, 4, 5, 6, 7, 8].map((skeleton) => (
    <div key={skeleton} className="input-wrapper">
      <SkeletonLoader
        width={label.width}
        height={label.height}
        borderRadius={label.borderRadius}
      />
      <SkeletonLoader
        width={input.width}
        height={input.height}
        borderRadius={input.borderRadius}
      />
    </div>
  ));

  return (
    <div className="input-group-container">
      <div className="input-grid">{skeletonList}</div>
      <div className="input-wrapper">
        <SkeletonLoader
          width={label.width}
          height={label.height}
          borderRadius={label.borderRadius}
        />
        <SkeletonLoader
          width={textArea.width}
          height={textArea.height}
          borderRadius={textArea.borderRadius}
        />
      </div>
    </div>
  );
};

export default InputGroups;

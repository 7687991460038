import React, { Component } from 'react';

class LayoutModalField extends Component {

    setField = () => {
        if(!this.props.isDisabled) {
            this.props.setField(this.props.field.code);
        }
    }

    render() {
        return (
            <div onClick={this.setField} className={"field"+(this.props.isActive?" active":"")+(this.props.isDisabled?" disabled":"")}>
                {this.props.field.name}
            </div>
        );
    }
}

export default LayoutModalField;
import React from "react";

const HiLine = ({ lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="18"
      viewBox="0 0 2 18"
      fill="none"
    >
      <rect width="2" height="18" rx="1" fill={lineColor} />
    </svg>
  );
};

export default HiLine;

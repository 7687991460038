import React, { Component } from "react";
import MainButton from "../common/buttons/MainButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo, faDownload } from "@fortawesome/pro-regular-svg-icons";
import HubIcon from "../common/hubIcon/HubIcon";
import HiInfoCircle from "../common/icons/HiInfoCircle";
import HiDownload from "../common/icons/HiDownload";
import HiMore from "../common/icons/HiMore";

export default class WidgetOptions extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      infoOpen: false,
      svgColor: "#D2DEF2",
    };
  }

  toggleMenu = () => {
    this.setState({ ...this.state, isMenuOpen: !this.state.isMenuOpen }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, isMenuOpen: false, infoOpen: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event?.target)) {
      this.closeMenu();
    }
  };

  toggleInfo = () => {
    this.setState({
      ...this.state,
      infoOpen: !this.state.infoOpen,
      isMenuOpen: false,
    });
  };

  exportCSV = () => {
    //   console.log("export",this.props.widgetRef)
    if (
      this.props.widgetRef !== undefined &&
      this.props.widgetRef.current !== null
    ) {
      this.props.widgetRef.current.exportToCSV();
      this.closeMenu();
    }
  };

  render() {
    return (
      <div className="hub-widget-options-wrapper">
        <span
          onMouseEnter={() =>
            this.setState({ ...this.state, svgColor: "#779DD9" })
          }
          onMouseLeave={() =>
            this.setState({ ...this.state, svgColor: "#D2DEF2" })
          }
        >
          <MainButton
            key={"widgetOptions"}
            iconLineColor={this.state.svgColor}
            iconSize={14}
            className={"button-5" + (this.state.isMenuOpen ? " active" : "")}
            icon={<HiMore />}
            onClick={this.toggleMenu}
          />
        </span>

        {this.state.isMenuOpen ? (
          <div className="hub-widget-options-menu" ref={this.wrapperRef}>
            <div
              className="hub-widget-options-menu-item"
              onClick={() => {
                this.toggleInfo();
              }}
            >
              <HubIcon fontSize={14}>
                <HiInfoCircle />
              </HubIcon>
              <span>Widget Info</span>
            </div>
            <div className="widget-menu-divider"></div>
            <div
              className="hub-widget-options-menu-item"
              onClick={() => {
                this.exportCSV();
              }}
            >
              <HubIcon fontSize={14}>
                <HiDownload />
              </HubIcon>
              <span>Download as CSV</span>
            </div>
            <div className="hub-widget-options-menu-item">
              <HubIcon fontSize={14}>
                <HiDownload />
              </HubIcon>
              <span>Download as PPTX</span>
            </div>
          </div>
        ) : (
          false
        )}
        {this.state.infoOpen && this.props.info ? (
          <div className="hub-widget-info-tooltip" ref={this.wrapperRef}>
            {this.props.info}
          </div>
        ) : (
          false
        )}
      </div>
    );
  }
}

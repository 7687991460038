import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class TimesheetTableTh extends Component {
    render() {
        return (    
            <div className={this.props.className?"hub-ts-timesheetTable-th "+this.props.className:"hub-ts-timesheetTable-th"}>
                <div className="hub-ts-timesheetTable-th-body" title={this.props.title}>
                    <div className="hub-ts-timesheetTable-th-title">{this.props.title}</div>
                    <div className="hub-ts-timesheetTable-th-label">{this.props.label}</div>
                    {this.props.icon?<span><FontAwesomeIcon icon={this.props.icon}/></span>:false}
                    {this.props.hideTotal?false:<div className="hub-ts-timesheetTable-th-total">{this.props.total!==null?this.props.total.toFixed(2):'\u00A0'}</div>}
                    {/*this.props.hideSubinfo?false:this.props.subinfo*/}
                </div>
            </div>
        );
    }
}

export default TimesheetTableTh;
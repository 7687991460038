import React, { Component } from 'react';
import DataTable from '../../common/dataTable';
import ProjectTile from './../projecttiles/ProjectTile';
import CreateProject from '../details/createProject';
import Auth from '../../auth/Auth';
import PageHeader from '../../common/pageElements/pageHeader/'


class ProjectList extends Component {


    constructor() {
        super();
    
      
        
        this.state = {
            
            createProject:false,
            loading:true
        }
    }

    componentDidMount() { this.getData() }

    getData = () => {

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };
        // ! Burası değişmeli kod olmalı.
       // fetch('/api/groupResources/parent/21', requestOptions)
        fetch('/api/groupResources/code/ProjectCreate', requestOptions)

        
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
                //console.log("projectList",Auth.checkResourceId(),r.body.object,r.body.object.findIndex(x=>x.resource.uid===parseInt(Auth.checkResourceId())));
                //console.log(this.props.selectedRecord);
                
               if(r.status===200) {

                let index=r.body?.object?.findIndex(x=>x.resource.uid===parseInt(Auth.checkResourceId()))
                this.setState({...this.state, loading:false,  createProject:index>-1?true:false  });

                //console.log(this.state.formData?.actuals)
               // console.log(this.state.period.open)
            }
                else if(r.status==401) {
                    window.location.replace("/login");
                }
                else if(r.status==403) {
                    this.setState({...this.state, Status: "Error",LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state,Status: "Error", LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                }
            });



        }


    render() {
        //console.log("this.state",this.state)

        let toolbarActionButtons=[]
        if(this.state.createProject)
        {
            toolbarActionButtons.push({
                code:"newProject",
                label: "New Project",
                onClick: 'showModal'
            })
        }

        const dtConfig = {
            endPoint: "/api/projects", 
            columns: [
                {id:"projectId", dataKey:"code", label:"Project ID", type:"text"}
                ,{id:"projectName", dataKey:"name", label:"Project Name", type:"text", linkTo:"/project/{uid}/details"}
                ,{id:"description", dataKey:"description", label:"Description", type:"text"}
                 ,{id:"percentComplete", dataKey:"percentComplete", label:"Percent Complete", type:"percentage"}
                 
                ,{id:"startDate", dataKey:"startDate", label:"Start Date", type:"date"}
                ,{id:"finishDate", dataKey:"finishDate", label:"Finish Date", type:"date"}

                ,{id:"obsInfo", dataKey:"ObsInfo.name", label:"OBS", type:"text"}
                ,{id:"dataDate", dataKey:"dataDate", label:"Data Date", type:"date"}
                ,{id:"status", dataKey:"status.name", label:"Status", type:"text"}

               
                ,{id:"p6Plan.plannedStart", dataKey:"p6Plan.plannedStart", label:"Planned Start (P6)", type:"date"}
                ,{id:"p6Plan.plannedFinish", dataKey:"p6Plan.plannedFinish", label:"Planned Finish (P6)", type:"date"}
                ,{id:"p6Plan.plannedDuration", dataKey:"p6Plan.plannedDuration", label:"Planned Duration (P6)", type:"day"}
                ,{id:"p6Plan.plannedUnits", dataKey:"p6Plan.plannedUnits", label:"Planned Units (P6)", type:"hour"}
                
                ,{id:"p6Plan.actualStart", dataKey:"p6Plan.actualStart", label:"Actual Start (P6)", type:"date"}
                ,{id:"p6Plan.actualFinish", dataKey:"p6Plan.actualFinish", label:"Actual Finish (P6)", type:"date"}
                ,{id:"p6Plan.actualDuration", dataKey:"p6Plan.actualDuration", label:"Actual Duration (P6)", type:"day"}
                ,{id:"p6Plan.actualUnits", dataKey:"p6Plan.actualUnits", label:"Actual Units (P6)", type:"hour"}

                ,{id:"p6Plan.baselineStart", dataKey:"p6Plan.baselineStart", label:"Baseline Start (P6)", type:"date"}
                ,{id:"p6Plan.baselineFinish", dataKey:"p6Plan.baselineFinish", label:"Baseline Finish (P6)", type:"date"}
                ,{id:"p6Plan.baselineDuration", dataKey:"p6Plan.baselineDuration", label:"Baseline Duration (P6)", type:"day"}
                ,{id:"p6Plan.baselineUnits", dataKey:"p6Plan.baselineUnits", label:"Baseline Units (P6)", type:"hour"}

                ,{id:"p6Plan.remainingDuration", dataKey:"p6Plan.remainingDuration", label:"Remaining Duration (P6)", type:"day"}
                ,{id:"p6Plan.remainingUnits", dataKey:"p6Plan.remainingUnits", label:"Remaining Units (P6)", type:"hour"}

                ,{id:"p6Plan.atCompletionDuration", dataKey:"p6Plan.atCompletionDuration", label:"At Completion Duration (P6)", type:"day"}
                ,{id:"p6Plan.atCompletionUnits", dataKey:"p6Plan.atCompletionUnits", label:"At Completion Units (P6)", type:"hour"}


                ,{id:"plannedStart", dataKey:"plannedStart", label:"Planned Start", type:"date"}
                ,{id:"plannedFinish", dataKey:"plannedFinish", label:"Planned Finish", type:"date"}
              
                ,{id:"plannedUnits", dataKey:"plannedUnit", label:"Planned Units", type:"hour"}
                
                ,{id:"actualStart", dataKey:"actualStart", label:"Actual Start", type:"date"}
                ,{id:"actualFinish", dataKey:"actualFinish", label:"Actual Finish", type:"date"}
            
                ,{id:"actualUnits", dataKey:"actualUnit", label:"Actual Units", type:"hour"}

            
              
                ,{id:"remainingUnits", dataKey:"remainingUnit", label:"Remaining Units", type:"hour"}

               
                ,{id:"atCompletionUnits", dataKey:"atCompletionUnit", label:"At Completion Units", type:"hour"}


            ], 
            view: {
                visibleColumnList: ["projectId", "projectName","percentComplete","startDate","finishDate"],
                mode:"tile"
            },
            tile: <ProjectTile />,
            modal: <CreateProject />,
            modalOptions:{height:"60%",width:"50%"},
            
            toolbarActionButtons:toolbarActionButtons
        }

        return (
            <>
            <PageHeader
                title={"Projects"}
                /> 
            <div className="hub-page" >
                <DataTable config={dtConfig}  />
            </div>
            </>
        );
    }
}

export default ProjectList;
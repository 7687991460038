import React, { Component } from "react";

import HiAddUser from "../../../../common/icons/HiAddUser";
import HubIcon from "../../../../common/hubIcon/HubIcon";

export default class NewAssignmentIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }
  render() {
    return (
      <div
        className={`avatar new ${this.props.isSelected ? "active" : ""}`}
        onClick={this.props.setAssignmentTab}
        style={{ right: this.props.right, position: "relative" }}
        onMouseEnter={() =>
          this.setState({
            ...this.state,
            isHovered: true,
          })
        }
        onMouseLeave={() =>
          this.setState({
            ...this.state,
            isHovered: false,
          })
        }
      >
        <HubIcon
          lineColor={
            this.state.isHovered || this.props.isSelected ? "#fff" : "#1769F1"
          }
        >
          <HiAddUser />
        </HubIcon>
      </div>
    );
  }
}

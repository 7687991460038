import React, { Component } from 'react';
import './WidgetGrid.css';
import Auth from './../../auth/Auth';
import Pagination from './Pagination';
import TD from './TD'
import TH from './TH';
import {Utils} from './../../common/Commons';
import MainButton from './../../common/buttons/MainButton';
import {DateUtils} from './../../common/Commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownToBracket, faEmptySet,faSpinner,faCopy,faClone,faTrash,faCircleInfo} from '@fortawesome/pro-duotone-svg-icons'
import LoadingScreen from '../../common/statusScreen/loadingScreen';

import Gantt from '../../common/dataTable/Gantt';
import GanttTH from '../../common/dataTable/GanttTH';

// bir objenin içindeki nested değerleri bulmaya yarıyor 
// resolvePath(window,'document.body.xyz') => undefined
// https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path

const resolvePath = (object, path, defaultValue) => path
   .split('.')
   .reduce((o, p) => o ? o[p] : defaultValue, object)

   function pad2(n) { return n < 10 ? '0' + n : n }


class WidgetGrid extends Component {

    constructor(props) {
        super(props);

        this.isScrolling = {}; //globalTimer

        this.justOnce = true; //globalTimer

        this.dutils = new DateUtils();

        // Visible Columns isminde bir AoO oluşturuyoruz. Orada bütün kolonlar var. Bu kolonlara width değişkenini türüne bağlı olarak ekliyoruz.
        // Yapılan değişikliklerde ApplyVisibleColumns'u değiştirmeyi unutmayalım
        let newConfig = this.props.config;
        //console.log(newConfig);
        newConfig.view.visibleColumns = [];
        this.props.config.columns.map((c) => {
            if(this.props.config.view.visibleColumnList.indexOf(c.id)>-1) {
                newConfig.view.visibleColumns.push({...c, width: c.width?c.width:(c.type==="text" || c.type==="hour" || c.type=="day" ) ?240:150});
            }
        }) 
        // newConfig' boş filter dizileri de ekleniyor. List id'leri tutmak için
        newConfig.view.filterList = []; 
        newConfig.view.filters = [];
        // default view mode:
        newConfig.view.mode = newConfig.view.mode?newConfig.view.mode:"list";

        newConfig.view.showSelect = newConfig.view.showSelect!==undefined?newConfig.view.showSelect:true;


        this.state = {
            isFilterOpen: false,
            orderBy: "",
            orderType: "ASC",
            selectedRows:[],
            perPage: 10, 
            showContextMenu: false, 
            anchorPoint:{x:0, y:0}, // Sağ tık için tıklanan pozisyonu tutacak.
            wPerMonth: 120, //Gantt bölümünde bir ayın genişliği
            page: 0,
            isSideBarOpen: false,
            config: newConfig,
            ganttRenderBy:0,
            ganttRenderCount: 100,
            ganttRenderOverwrap: 50,
            selectedRecord: {},
            hideGanttbar: false,
            selectedRecordRowNum: -1,
            data: [], // filtrelenmiş veri tablolara bu giderecek.
            rawData: [], // çekilen ham data
            sortString:"",
            sortColumn:"",
            showModal: false,
            newCreate:false,
            selecteds: []
        }


    }

    setPerPage = (perPage)  => {
        this.setState({...this.state, perPage: perPage});
    }

    prepareFilteredData = (filterSet, rawData) => {
        // State'i alır, 
        // state'deki datayı döndürerek filtrelere uyuyorsa valid olarak işaretler ve filteredData'ya yazar.
        // filteredData'yı döner
        
        let filteredData = [];

        rawData?.map( d => {
            let valid = 1;

            filterSet.map(f => {
                if(f.filter && f.filter !== null ) {
                    let v = this.validateFilter(f, d);
                    if(!v){valid = valid-1}
                }   
            })

            if(valid===1){
                filteredData.push(d);
            }
        })

        return filteredData;

    }

    

    /* Filter Methodları Sonu */



    setPage = (page) => {
        this.setState({...this.state, page:page});
    }

    
    componentDidMount() {
       // console.log("componentDidMount DataTable");
        this.getData();
    }


    componentDidUpdate(prevProps,prevStates)
    {
        if(prevStates.selectedRecord!==this.state.selectedRecord && this.state.newCreate===true) {
          this.setState({...this.state, newCreate:false})
        }
        
        if(this.props.refresh!==undefined && prevProps.refresh!==this.props.refresh) {
          this.getData();
        }

        if(prevProps.counter!==this.props.counter)
        {
          
            this.getData();
        }
    }

    prepareHierarchyData = (d) => {
        /* 
            configdeki group by ve sort by değerlerini alıp 
            kayıtların altına hub_hier_children isminde 
            rezerv bir kelime ile child kayıtları ekler.

            algoritma, 
            önce tüm child'ı olan kayıtların childlarını çıkar, 
            sonra ilk sıradan başlayarak tüm kayıtlardan başlayarak tüm kayıtların sırasını oluştur.

        */
       // console.log("prepareHierarchyData");

        let newData = [];
        const parentCol = this.state.config.hierarchy.parentColumn;
        const sortCol = this.state.config.hierarchy.sortColumn;
        const idAttr = this.state.config.idAttribute

        // tüm kayıtları önce artan şekilde sort eder.
        d.sort((a,b) => { return a[sortCol]-b[sortCol] });
        
        const fam = {} // family 
        const taskById = {};  // taskların ID leri ile ilişkili tutulduğu obje. 

        // Her şeyin parentının bulunduğu liste.
        d.map( (x, i) => {
            let famKey = x[parentCol]===null?"null":x[parentCol];
            if(!fam[famKey]){
                fam[famKey] = [];
            }
            fam[famKey].push(x[idAttr]); 
            taskById[x[idAttr]] = {...x, hub_hier_children:[], hub_hier_tree:[]}; 
        })

        let leveledList = [];


        const childrenCount = {};  // hierTree için çözemediğim parenttaki iç indexini yazma için kullanılan workaround
        if (fam["null"]) {
            
            let keyBuffer = [...fam["null"]];
            let i = 0;

            while(keyBuffer[i]) {

                if (!childrenCount[keyBuffer[i]]) {
                    childrenCount[keyBuffer[i]] = 0;
                }

                let level = ((taskById[[taskById[keyBuffer[i]][parentCol]]]?.hub_hier_level)?taskById[taskById[keyBuffer[i]][parentCol]]?.hub_hier_level+1:1);
                taskById[keyBuffer[i]].hub_hier_level = level;

                // hub_hier_tree set edilir - bir child elemanın dizideki ardışık yeri
                if(level===1) {
                    taskById[keyBuffer[i]].hub_hier_tree.push(i)
                } else {
                    taskById[keyBuffer[i]].hub_hier_tree.push(...taskById[taskById[keyBuffer[i]][parentCol]].hub_hier_tree);
                    taskById[keyBuffer[i]].hub_hier_tree.push(childrenCount[taskById[keyBuffer[i]][parentCol]]);
                    childrenCount[taskById[keyBuffer[i]][parentCol]] = childrenCount[taskById[keyBuffer[i]][parentCol]]+1;
                    //taskById[[taskById[keyBuffer[i]][parentCol]]].hub_hier_children.length
                }
                

                
                
                if (!leveledList[level]) {
                    leveledList[level] = [];
                }

                leveledList[level].push(keyBuffer[i]);

                if (fam[keyBuffer[i]]) {
                    keyBuffer.push(...fam[keyBuffer[i]])
                }

                i++;
            }

            for(let i = leveledList.length-1; i > 0; i=i-1) {
                leveledList[i].map(x => { 
                    if(taskById[x][parentCol]!==null) {
                        taskById[taskById[x][parentCol]].hub_hier_children.push(taskById[x]);
                    } 
                });
            }

            fam["null"].map(x => {
                newData.push(taskById[x]);
            })

        }  
        return newData;
    }

    dateDiff = (startingDate, endingDate) => {
 
        var startDate = startingDate;
        var endDate = endingDate;
    
        if (startDate > endDate) {
            var swap = startDate;
            startDate = endDate;
            endDate = swap;
        }
        var startYear = startDate.getFullYear();
        var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
        var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    
        var yearDiff = endDate.getFullYear() - startYear;
        var monthDiff = endDate.getMonth() - startDate.getMonth();
        if (monthDiff < 0) {
            yearDiff--;
            monthDiff += 12;
        }
        var dayDiff = endDate.getDate() - startDate.getDate();
        if (dayDiff < 0) {
            if (monthDiff > 0) {
                monthDiff--;
            } else {
                yearDiff--;
                monthDiff = 11;
            }
            dayDiff += daysInMonth[startDate.getMonth()];
        }
        
        return yearDiff*12.0+ + monthDiff*1.0 + dayDiff/daysInMonth[endDate.getMonth()];
    }
    
    getWidth = (startDate, finishDate, roadmapLeft = null, roadmapRight = null) =>  {   
        if (startDate === null || finishDate === null ) { return 0;}  
        if (roadmapLeft !== null && roadmapLeft > startDate) { startDate = roadmapLeft }
        return (finishDate  > startDate)?Math.round(this.dateDiff(startDate,finishDate)*this.state.wPerMonth):0;
    }
   
    getData = () =>
    {

        if (this.props.sampleData) {
            let filteredData = this.prepareFilteredData(this.state.config.view.filters, this.props.sampleData);
            // eğer configde hierarchy.isEnabled true ise veriyi hiyerarşik gösterim için hazırlıyoruz.
            if(this.state.config?.hierarchy?.isEnabled) {
                filteredData = this.prepareHierarchyData(filteredData);
            }
            this.setState({...this.state, Loading:false, data:filteredData, rawData:this.props.sampleData});
        } else {

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
            };

            // ! Buraya tekrar bakılacak sort için

            let filterString=""
           // console.log("generateData",this.state.sortString,this.state.config.endPoint)
            if(this.props?.selectedFilters?.length>0 || this.props.multiplexingFilter)
            {
                filterString+="?"

                this.props.selectedFilters.map((f,i)=>
                {
                   
                    filterString+=(i!==0?"&":"")+f.dataKey+"="+f.value.toString()
                })

                
                if(this.props.multiplexingFilter)
                {
                    filterString+= (this.props.selectedFilters.length>0?"&":"")+this.props.multiplexingAttribute+"="+this.props.multiplexingFilter.toString()

                }
            }
    
            //fetch(this.state.config.endPoint+'?'+this.state.sortString+filterString, requestOptions)

            fetch(this.state.config.endPoint+filterString, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {

                    //console.log("grid",r,r.body.object?.length)
                   
                    if(r.status===200 ) {   


                        let filteredData = this.prepareFilteredData(this.state.config.view.filters, r.body.object);
                        if(this.state.config?.hierarchy?.isEnabled) {
                            filteredData = this.prepareHierarchyData(filteredData);
                        }



                              //mode gantt ise buraya girer          
                        if(this.state.config.view.mode === "gantt")
                        {
                            let dutils = new DateUtils();
                            let ganttStart=null;
                            let ganttFinish=null;
                            

                            //console.log("filteredData",filteredData)

                            filteredData.map((d)=>{

                                //renk için var ama değişecek
                                d.status={name:"Active"}
                                d.objectTypeInfo={code:"wbss"}

                                d.startDate=d[this.state.config.view.startAttribute]
                                d.finishDate=d[this.state.config.view.finishAttribute]

                                d.startDateObj= new Date(d.startDate)
                                d.finishDateObj= new Date(d.finishDate)
                                d.ganttWidth= this.getWidth(d.startDateObj, d.finishDateObj) 
                                
                              
                                if (d.startDate !== null && d.finishDate !== null ) {
                                    if ( ganttStart === null  && ganttFinish  === null ) {
                                        ganttStart = new Date(d.startDate);
                                        ganttFinish = new Date(d.finishDate);
                                    } else {
                                        const xs = new Date(d.startDate);
                                        if(ganttStart > xs ) {
                                            ganttStart = xs;
                                        }
                                        const xf = new Date(d.finishDate);
                                        if(ganttFinish < xf ) {
                                            ganttFinish = xf;
                                        }
                                    }
                                    
                                }

                            })

                            filteredData.map((d)=>{
                                d.ganttLeft= this.getWidth(ganttStart, d.startDateObj) 
                            })
                           
                            this.setState({...this.state, Loading:r.body.object!==null && r.body.object?.length>0?false:true,noContent:r.body.object===null || r.body.object?.length===0?true:false, data:filteredData, rawData:r.body.object, page:0,ganttStart: ganttStart, ganttFinish: ganttFinish, ganttMonths: dutils.getMonths(new Date(ganttStart), new Date(ganttFinish)),});
                        }
                        else
                        {
                            this.setState({...this.state, Loading:r.body.object!==null && r.body.object?.length>0?false:true,noContent:r.body.object===null || r.body.object?.length===0?true:false, data:filteredData, rawData:r.body.object, page:0});
                        }
                        


                        
                    }
                    else if(r.status===401) {
                        window.location.replace("/login");
                    }
                    else if(r.status===403) {
                        this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else {
                        this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });
        }
         

    }

    parseLink = (link, record) => {
        
        const reps = link.match(/{(.|)*?}/gi);
        let newLink = link;

        reps.map( (r) => {
            var value = resolvePath(record, r.substring(1, r.length-1));
            newLink = newLink.replace(r, value);
        });
        
        return newLink;

    }

    makeTableRow = (d, i) => {
        return ( 
            <tr key={"tr-"+i.toString()}  onClick = {() => {this.selectRecord(d, i)}} className={this.state.selectedRecordRowNum==i?"active":""}>
                        {!this.state.config.view.hideSelectbox?<td align="center"  className="hub-widget-grid-picker">
                            <input type="checkbox" checked={this.state.selectedRows.indexOf(i)>-1} onChange={(e) => {this.selectRow(e, i)} }/>
                        </td>:false}
                    {this.state.config.view.visibleColumns.map( (c, ix) => {
                        let link = c.linkTo?this.parseLink(c.linkTo, d):undefined;
                        
                        return <TD 
                            key={"cell-"+i.toString()+"-"+c.id} 
                            isExpandable={ix===0&&this.state.config.hierarchy?.isEnabled} 
                            hasChildren={d.hub_hier_children?.length>0} 
                            level={d.hub_hier_level} 
                            isExpanded={d.hub_hier_is_expanded} 
                            width={c.width-12} 
                            type={c.type} 
                            linkTo={link} 
                            toggleIsExpanded = {this.toggleIsExpanded}
                            value={resolvePath(d,c.dataKey)} 
                            sideBar={this.openNewSideBar} 
                            hierLevel={d.hub_hier_level}
                            hierTree = {d.hub_hier_tree}
                            />
                    })}
                </tr>
                )
    }


    
    makeGanttTableRow = (d, i) => {

        let colorClass = "";
        if(!this.state.hideColor) {
            switch(d.objectType) {
                case 'wbs':
                     colorClass = "purple";
                break;
                case 'activity':
                     colorClass = "lightyellow"; 
                break;
                case 'task':
                     colorClass = (d.children && d.children.length>0?"lightteal":"false");
                break;
                default:
                     colorClass = "";
              }    
        }
        
        return ( 
            <tr key={"tr-"+i.toString()} onDoubleClick={this.openSidebar}  onClick = {() => {this.selectRecord(d, i)}} className={colorClass + " " + (this.state.selectedRecordRowNum==(i+this.state.ganttRenderBy)?"active":"")}>
              
                {this.state.config.view.visibleColumns.map( (c, ix) => {
            
                        let link = c.linkTo?this.parseLink(c.linkTo, d):undefined;
                        return <TD   key={"cell-"+i.toString()+"-"+c.id} hasChild={d.children && d.children.length>0?true:false} isExpanded={d.isExpanded}  width={c.width} type={c.type} linkTo={link} value={resolvePath(d,c.dataKey)} sideBar={this.openNewSideBar} />
                  
                })}
            </tr>)
    }

    

    handleResizeColumn = (i, w) => {
        // Resize durduğunda  view'a kolonun width'ini ekleyen method. 
        // i->kolon objesinin dizideki indexi
        // w yeni Deltawidth.   bu değer mevcut width'e + olarak eklenir.

        let newState = {...this.state};
        newState.config.view.visibleColumns[i].width = this.state.config.view.visibleColumns[i].width + w 
        this.setState(newState);
    }

    handleSort = (columnName,sortType) =>
    {
        //console.log(columnName+" "+sortType)
        this.setState({...this.state,  sortString:'&sort='+columnName+','+sortType, sortColumn:columnName}, () => { this.getData()});
    }


    notify = (type,message) => { const u = new Utils();  u.addNotification(type, message) };


   toggleIsExpanded = (e, tree) => {

        e.preventDefault();
        e.stopPropagation();    
                
        let newData = [...this.state.data];

        console.log(tree);

        let n = null;

      


        tree.map(x=>{
            if (n===null) {
                n = newData[x];
            } else {
                n = n.hub_hier_children[x]
            }

            console.log(n);
        });

        n.hub_hier_is_expanded = !n.hub_hier_is_expanded
        console.log("n",n);

        this.setState({...this.state, data: newData});
        
   }

   exportToCSV=()=>
   {
       // Prepare Data Array 
       let rows = []; 

       // Push Column Headers
       let row = this.state.config.view.visibleColumns.map( (c) => {
               return c.label 
           })
       rows.push(row);

       // Push Values if data exists
       if (this.state.data){  

            let visibleRows = [...this.state.data];
            let i = 0;

            while (visibleRows[i]) {
                if (visibleRows[i].hub_hier_children?.length>0) {
                    visibleRows.splice(i+1, 0, ...visibleRows[i].hub_hier_children);
                }
                i++;
            }
            
            visibleRows.map( d => {
               let row = this.state.config.view.visibleColumns.map( (c, ix) => { 
                   let label = ""; 
                   let value = resolvePath(d,c.dataKey); 
                   if(value!==null && value!==undefined) {
                       switch(c.type) {
                           case 'text':
                            label = value.replace(/#/g,"-");
                               break;
                           case 'date':
                               label = this.dutils.Date2Str(value);
                               break;
                           case 'number':
                               label = value;
                               break;
                           case 'staticLookup':
                               label = value?.name?value.name:"";
                               break;
                           case 'dynamicLookup':
                               label = value?.name?value.name:"";
                               break;
                           case 'lookup':
                               label = value?.name;
                               break;
                           case 'percentage':
                               label = value?(value).toFixed(0)+'%':'0%';
                               break;    
                           case 'boolean':
                               label= (value?"Yes":"No")
                               break;
                           case 'hour': 
                               label=value?(value).toFixed(0)+'h':'0h';
                               break;
                           case 'day': 
                               label=value?(value).toFixed(0)+'d':'0d';
                               break;
                       }
                   }
                   return "\""+label+"\""; 

               });
               rows.push(row);
           })
       }


       //c.type
       
       // Prepare Content

       var universalBOM = "\uFEFF";
       let csvContent = "data:text/csv;charset=utf-8," + universalBOM
           + rows.map(e => e.join(";")).join("\n");
       
           var encodedUri = encodeURI(csvContent);

           var link = document.createElement("a");
           link.setAttribute("href", encodedUri);
           
           // File Name 
           var date = new Date();
           let dateSuffix = date.getFullYear().toString() + pad2(date.getMonth() + 1) + pad2( date.getDate()) + pad2( date.getHours() ) + pad2( date.getMinutes() ) + pad2( date.getSeconds() ) 

           let fileName = (this.state.config.title?this.state.config.title:"export") +  "_" +dateSuffix;
           link.setAttribute("download", fileName+".csv");
           document.body.appendChild(link); // Required for FF

           link.click(); // This will download the data file named "my_data.csv".
           document.body.removeChild(link);

   }


   ganttContainerScrollHandler = (e,customScroll) => {

    // console.log("ganttContainerScrollHandler",e.target.scrollTop,this.state.selectedRows,this.state.data)
      
      if (window._preventEvent) {
          window._preventEvent = false;
          return;
      }

      if ( this.justOnce ) {
          e.stopPropagation();
          clearTimeout(this.isScrolling);
          this.isScrolling = setTimeout(() => {

              let scrollTop = customScroll?customScroll:e.target.scrollTop;
              
              let newRenderBy =  Math.floor((e.target.scrollTop)/27);
              
              if(this.state.ganttRenderBy !== newRenderBy) {

                  this.setState({...this.state, scroll:scrollTop,ganttRenderByLast: this.state.ganttRenderBy, ganttRenderBy:(newRenderBy > this.state.ganttRenderOverwrap?(newRenderBy-this.state.ganttRenderOverwrap):0)}, () => {
                  
                      window._preventEvent = true;
                      document.getElementById("hub-gantt-table-body").scrollTop = scrollTop;

                      if(!this.state.hideGanttbar){
                          document.getElementById("hub-gantt-body").scrollTop = scrollTop;
                      }
                      

                    
                  });

              }
              
          }, 150);

          document.getElementById("hub-gantt-table-header").scrollLeft = e.target.scrollLeft;

          if(!this.state.hideGanttbar){
              window._preventEvent = true;
              document.getElementById("hub-gantt-body").scrollTop = e.target.scrollTop;
          }
      }
      

      

  }



    render() {
        //console.log("grid",this.state)
        let pagedData = this.state.data?this.state.data.slice(this.state.page*this.state.perPage, (this.state.page+1)*this.state.perPage):"";

        let visibleRows = [...pagedData];
        let i = 0;

        while (visibleRows[i]) {
            if (visibleRows[i].hub_hier_is_expanded && visibleRows[i].hub_hier_children?.length>0) {
                visibleRows.splice(i+1, 0, ...visibleRows[i].hub_hier_children);
            }
            i++;
        }
        return (
            <>
           {/* <div className="hub-widget-grid-actionBar">
                <MainButton className="icon-button-2" icon={faDownToBracket} onClick={this.exportToCSV} tooltip={"Export to CSV"}/> 
        </div>*/}
            <div className="hub-widget-grid-wrapper">
                <div className="hub-widget-grid-tableArea" style={{paddingRight: (this.state.isSideBarOpen?"0":false), padding:(this.state.config.view?.mode==="util"?"0":false) }}>
                    {( this.state.config.view.mode !== "gantt" || this.state.config.view.mode === undefined  )?
                    this.state.Loading === false ?  // Liste Görünümü
                        <>
                           <div className="hub-widget-grid-tableArea-container">                        
                               <div className="hub-widget-grid">
                                   <table cellPadding="0" border="0" cellSpacing="0"  >
                                       <thead>
                                           <tr>
                                               {
                                                   this.state.config.view.visibleColumns.map( (c, i) => {
                                                       return <TH key={"th-"+c.id} handleResizeColumn={this.handleResizeColumn} column={c} ind={i} handleSort={this.handleSort} sortColumn={this.state.sortColumn}/>
                                                   })
                                               }
                                           </tr>
                                       </thead>
                                       {this.state.data && pagedData.length>0?<tbody>
                                               {  
                                                   visibleRows.map((d, i) => {
                                                       return ( 
                                                       this.makeTableRow(d,i)
                                                       )
                                                   })
                                               }
                                       </tbody>: !this.state.Loading?
                                              <div>
                                          
                                          </div>:""}
                                   </table>
                               </div>
                           </div>
                           {this.state.config.pagination?.hidePagination?false:this.state.data?.length>10?<Pagination hidePerPage={this.state.config.pagination?.hidePerPage} hideTotals={this.state.config.pagination?.hideTotals} setPage={this.setPage} setPerPage={this.setPerPage} totalRows={this.state.data?this.state.data.length:0} perPage={this.state.perPage} page={this.state.page}/>:false  }
                        </>
                    :
                    <div style={{position:"relative", height:"100px"}}>
                        <LoadingScreen noContent={this.state.noContent}/>
                    </div>
                    :false}



                        { this.state.config.view.mode === "gantt" && this.state.Loading === false? // gantt görünümü
                         
                         <>
                         
                             <div className="hub-dataTable-ganttContainer">
                                 
                               <div className="hub-gantt-table-wrapper" > 
                               
                                 <div className="hub-gantt-table"> 
 
                                 
                                   <div id="hub-gantt-table-header" className="hub-gantt-table-header">
                                     <table cellPadding="0" border="0" cellSpacing="0"  style={{position:"relative", top:"0"}}>
                                         <thead>
                                             <tr>
                                                 {
                                                     this.state.config.view.visibleColumns.map( (c, i) => {
                                                         
                                                         return <GanttTH key={"th-"+c.id} handleResizeColumn={this.handleResizeColumn} column={c} ind={i} handleSort={this.handleSort} sortColumn={this.state.sortColumn}/>
                                                     })
                                                 }
                                             </tr>
                                         </thead>
                                     </table>
                                   </div>
                                   <div className="hub-gantt-table-body" id="hub-gantt-table-body" onScroll={this.ganttContainerScrollHandler}>
                                       <div style={{height:"auto"}}>
                                
                                         {pagedData.length>0?
                                             <table cellPadding="0" border="0" cellSpacing="0"  >
                                                 <tbody> 
                                                     {   
                                                         pagedData.map((d, i) => {
                                                             return  this.makeGanttTableRow(d,i)
                                                         })
                                                         
                                                     }
                                                 </tbody>
                                             </table>
                                             :
                                             <LoadingScreen  icon={faEmptySet} message="No Record" noSpin/>
                                             }
                                       </div>
                                   </div>    
                                 </div>
 
                                
                                 {this.state.hideGanttbar?false:
                                 <Gantt wPerMonth={this.state.wPerMonth} selectRecord={this.selectRecord} selectedRecordRowNum={this.state.selectedRecordRowNum} ganttStart = {this.state.ganttStart} ganttMonths={this.state.ganttMonths} ganttFinish = {this.state.ganttFinish} ganttRenderCount = {this.state.ganttRenderCount} ganttRenderBy = {this.state.ganttRenderBy} ganttRenderByLast = {this.state.ganttRenderByLast} data={pagedData} dataLen={this.state.data.length}  ganttWidth={this.state.config.view.ganttWidth}/>
                                 }
                               </div>
                             
                             </div>
                             </>
                         :false}
                </div>   
            </div>
            </>
        );
    }
}

export default WidgetGrid;
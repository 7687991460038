import React, { Component } from 'react';
import './FilterItemForm.css';
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Auth from '../../../auth/Auth';



class FilterItemFormDynamicLookup extends Component {
    constructor(props) {
        super(props);
        this.state = {options:[], filter:{ option: null, value: [], valueIds:[]}, values:[]}
    }

    componentDidMount() {

        const options = [
            { value: 'null', label: 'Is Empty', indicator:"Is Empty"},
            ,{ value: 'notnull', label: 'Is Not Empty', indicator:"Is Not Empty" }
            ,{ value: 'isOneOf', label: 'Is One of', indicator:"=" }
            ,{ value: 'isNotOneOf', label: 'Is Not One of', indicator:'\u2260' }
          ];

        this.loadOptions("", (x) => {

            if(this.props.column.filter){
                this.setState({options: options, filter: this.props.column.filter, values:x});
            } else {
                this.setState({options: options, filter: {option: { value: 'isOneOf', label: 'Is One of', indicator:"=" }, value: [], valueIds:[]}, values:x});
            }

        });   

     

    }

    setFilter = () => {
        // filter olarak filter:{option:"", value:{}} değerini döner. option seçili koşulun codu, value ise türe göre çeşitli değerler
        if(this.state.filter){
            if(this.state.filter.option.value==="null" || this.state.filter.option.value==="notnull") {
                this.props.setFilter({option: this.state.filter.option, value:[], valueIds:[]});
            } else {
                if(this.state.filter.valueIds.length > 0) {
                    this.props.setFilter(this.state.filter);  
                } else {
                    this.props.setFilter(null);
                }
            }
        } else {
            this.props.setFilter(null);
        }
    }

    handleChange = (attr, value) => {
        let newFilter = {...this.state.filter};
        newFilter[attr] = value;
        this.setState({...this.state, filter: newFilter}, 
            () => {
                this.setFilter();
            })
    }

 
    setValue = (e) => {

        let newFilter = {...this.state.filter};
        newFilter.value = e;
        let valueIds = e.map(x => { return x.value})
        newFilter.valueIds = valueIds;
        this.setState({...this.state, filter: newFilter}, () => {
            this.setFilter();
        })
    }

    handleKeyPress(e){
        if(e.key === "Enter"){
            e.target.blur(); 
        }
    }

    loadOptions = async (inputText, callback) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
        };
        const response = await fetch(`${this.props.column.endpoint}?size=1000000&search=${this.props.column.searchAttribute}:${inputText}`, requestOptions);
            //=${this.props.column.searchAttribute}:${inputText}`, requestOptions);
        const json = await response.json();
        callback(json.object.map(i=>({label: i[this.props.column.labelAttribute], value:i[this.props.column.valueAttribute], object:i} ))) 
    }
     

 
    render() {

        return (
            <div className="hub-dataTable-filterItem-form-wrapper">
                <div className="hub-dataTable-filterItem-form-row">
                    <Select
                        onChange = {(v) => {this.handleChange("option", v) }}
                        options={this.state.options}
                        value={this.state.filter.option}
                        isClearable = {false}
                        styles = {{
                            container: base => ({
                                ...base,
                                flex: "1",
                              }),
                        }}
                    />
                </div>
                {(this.state.filter.option !== null && this.state.filter.option?.value !== "null" && this.state.filter.option.value !== "notnull")||this.state.filter.option===null?
                  <div className="hub-dataTable-filterItem-form-row">
                       <AsyncSelect
                            value={this.state.filter?.value}
                            onChange={this.setValue}
                            placeholder={"Search..."}
                            defaultOptions={this.state.values}
                            loadOptions={this.loadOptions}
                            menuPlacement="bottom"
                            maxMenuHeight={160}
                            isMulti
                            styles = {{
                                container: base => ({
                                    ...base,
                                    flex: "1",
                                  }),
                                multiValue: base => ({
                                    ...base, 
                                    backgroundColor:"none",
                                    fontFamily:"Open Sans"
                                })
                            }}
                        />
                  </div>:false}
            </div>
        );
    }
}

export default FilterItemFormDynamicLookup;
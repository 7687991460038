import React, { Component } from 'react';
import '../../../projects/details/ProjectDetails.css';


import Auth from '../../../auth/Auth';

import TextField from '../../../projects/details/TextField';
import ButtonArea from '../../../common/pageElements/buttonArea';


import Section from '../../../common/pageElements/section';
import SectionContainer from '../../../common/pageElements/sectionContainer';
import MainButton from '../../../common/buttons/MainButton';
import {faFloppyDisk}  from '@fortawesome/pro-light-svg-icons'
import Loader from '../../../Loader';


import { useParams } from 'react-router';

import {DateUtils, Utils} from '../../../common/Commons';

import LoadingScreen from '../../../common/statusScreen/loadingScreen';

import DynamicLookup from '../../../common/inputControls/lookup/DynamicLookup';

class Details extends Component {


    constructor(props) {
        super(props);
        this.state = {
            LoadingText: "Loading Project Details",
            Loading:true,
            LoadingError:false,
            LoadingErrorText:"",
            program:{},
        };
    }

    
    
    handleChanges = (e,val) =>{
    
        //console.log(e)
        //console.log(val)
            let items = {...this.state.project};
        
            items[e]=val;
            this.setState({
                ...this.state, project:items
            
            })

           // console.log(items)
            
        }


        
    
 handleChangesLookup = (e,val) =>{

   // console.log(e)
    //console.log(val)
 
        let items = {...this.state.project};
      
        items[e]=val.uid;
        items.programManagerInfo={uid:val.uid,fullName:val.fullName};
        this.setState({
             ...this.state, project:items
        
        })

        //console.log(items)
         
    }



    componentDidMount() {
        //this.getData();

        this.setState({...this.state, Loading:false, program:this.props.program});
    }



    getData = () =>{
         const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },

        };

        fetch('/api/program/'+this.props.params.programid, requestOptions)
            .then(response => response.json().then(data => ({status:response.status, body:data})))
            .then(r => {
               // console.log('Status',r,r.body.object)
                if(r.body.status==200) {
                    this.setState({...this.state, Loading:false, program:r.body.object});

                    this.props.selectedRecord(r.body.object[0])
                }
                else if(r.body.status==401) {
                    window.location.replace("/login");
                }
                else if(r.body.status==403) {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"You are not authorized to see this page. Please contact your system administrator."});
                }
                else {
                    this.setState({...this.state, Loading:false,LoadingError: true, LoadingText:"An unexpected error occured. Please contact your system administrator."});
                }
            });
    }


    
    update = () => {
         
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
                body: JSON.stringify(this.state.project)
            };

            fetch('/api/program/'+this.props.params.projectid, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {
                   
                    const u = new Utils();  
                   
                    if(r.status===200) {
                     this.setState({...this.state, Status: "Update" ,ErrorMessage:"Updated Succesfully"}, this.props.selectedRecord(r.body.object));
                        this.notify();
                    }
                    else if(r.status==401) {
                        window.location.replace("/login");
                    }
                    else if(r.status==403) {
                        u.addNotification("error", "You are not authorized to take this action. Please contact your system administrator..");
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message,LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else if(r.status==400) {
                        u.addNotification("error", r.body.message);
                        this.setState({...this.state,Status: "Error", LoadingError: true, ErrorMessage:r.body.message});
                    }
                    else {
                        //console.log(r.body.message);
                        u.addNotification("error", r.body.message);
                        this.setState({...this.state, Status: "Error",ErrorMessage:r.body.message, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }

                });

    }

    
    notify = () => { const u = new Utils();  u.addNotification("success", "Program saved successfully.") };

    render() {
        const du  = new DateUtils();
        //console.log(this.state.project.programManagerInfo)
        
     
            return this.state.Loading?
            <LoadingScreen/>
            :
             <div className="pageDetails">
               
                        <Section title="PROGRAM DETAILS">
                                <TextField disabled pct="20" label="Program ID" value={this.state.program.code} />
                                <TextField disabled pct="20" label="Program Name" value={this.state.program.name} />
                         
                        </Section>

             
                    </div>
           }
    
}

export default (props) => (
    <Details 
        {...props}
        params={useParams()}
        />
); 
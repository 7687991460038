import React, { Component } from 'react';
import './ErrorDiv.css';

class ErrorDiv extends Component {
    render() {
        if(this.props.show) {
            return (
                <div className="hub-errorDiv">
                    <strong>{this.props.operation} Error:</strong> {this.props.message}
                </div>
            );
        }
        else { return false }
    }
}

export default ErrorDiv;
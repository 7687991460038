import React, { Component } from 'react'
import MainButton from '../../../../common/buttons/MainButton'
import TextArea from '../../../../common/inputControls/textArea2/textArea';
import { Utils } from '../../../../common/Commons';
export default class blockResolve extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
        blockResolveDescription: "",
    }
  }


   onChangeBlockResolveDescription=(formId,value)=>{
        this.setState({...this.state, blockResolveDescription: value}); 
        //this.props.toggleBlock("blockDescription",e.target.value,this.props.task.uid,this.props.columnIndex,this.props.taskIndex )

    }



  saveBlock=()=>
    {
    // console.log("blockType",blockType,this.state)

        if(this.state.blockResolveDescription==="")
        {
            const u = new Utils();  
            u.addNotification("error", "Block resolve description must be entered.")
        }
        else
        {

        
        
            let blockObject={};
          
            
        
            blockObject.blockDescription=this.props.task.blockDescription
            blockObject.blockReason=this.props.task.blockReason
            blockObject.blockOwner=this.props.task.blockOwner
            blockObject.isBlocked=false
            blockObject.blockResolveDescription=this.state.blockResolveDescription

        

        // console.log("blockObject",this.props.task.uid, blockObject, this.props.columnIndex, this.props.cardIndex,this.props.task.stage.code)

            this.props.blockTask(this.props.task.uid, blockObject, this.props.columnIndex, this.props.cardIndex,this.props.task.stage.code)
            this.props.closePanel();
        }
    }



  render() {

    //console.log(this.props)
    return (
            <div>

                <div  style={{height:"100px"}}>
                    <TextArea required={true} width="98%" rows="2" label="Block Resolve Description" defaultValue={this.state.blockResolveDescription} value={this.state.blockResolveDescription} onChange={this.onChangeBlockResolveDescription} changeArea="blockResolveDescription" />
                </div>
               {/* <div  style={{display:"flex",justifyContent:"flex-end"}}>
                    <MainButton onClick={()=>this.saveBlock(2)} className="button-1" label="Save" />
                 </div>
                 */} 
            </div>
    )
  }
}

import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import HubIcon from "../hubIcon/HubIcon";
import HiArrowShortDown from "../icons/HiArrowShortDown";
import HiArrowShortRight from "../icons/HiArrowShortRight";

export default class hirerarchicaltem extends Component {
  render() {
    return (
      <div
        className={`hier-item-wrapper ${
          this.props.isSelected ? "selected" : ""
        }`}
        style={{
          paddingLeft: this.props.isHierarchicalMode
            ? (this.props.hierLevel - 1) * 26 + 10 + "px"
            : "0px",
        }}
      >
        {!!this.props.hasChild && this.props.isHierarchicalMode ? (
          <span
            className="expand-icon"
            onClick={(e) => this.props.toggleIsExpanded(this.props.index)}
          >
            <HubIcon lineColor={this.props.isSelected ? "#fff" : "#051530"}>
              {this.props.isExpanded ? (
                <HiArrowShortDown />
              ) : (
                <HiArrowShortRight />
              )}
            </HubIcon>
          </span>
        ) : (
          <span></span>
        )}
        <span className="team-label" onClick={this.props.onClick}>
          {this.props.label}
        </span>
      </div>
    );
  }
}

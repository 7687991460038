import React, { Component } from "react";
import { Resizable } from "re-resizable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";

class TH extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ordered: false,
      sortOrder: "asc",
      sortColumn: "",
    };
  }
  /*
    componentDidUpdate(prevProps,prevState)
    {
     
        if(prevState.ordered!==prevProps.sorted)
        {    
             console.log(this.props.column.dataKey)
            console.log("ife girdik")
         console.log(prevState)
         console.log(prevProps)
            //console.log(this.state.ordered)
       

           this.setState({...this.state,  ordered:!this.state.ordered})
        }
        
    }

*/
  componentDidMount() {
    //console.log("componentDidMount")
    this.setState({
      ...this.state,
      sortColumn: this.props.column.dataKey,
    });
  }

  getColumnInfo = () => {
    //console.log("th column");
    // console.log(e);
    //console.log(this.props.column);
    this.setState(
      {
        ...this.state,
        sortOrder: this.state.sortOrder === "asc" ? "desc" : "asc",
        sortColumn: this.props.column.dataKey,
      },
      this.props.handleSort(this.props.column.dataKey, this.state.sortOrder)
    );
  };

  render() {
    return (
      <th
        style={{
          width: this.props.column.width,
          overflow: "hidden",
          paddingLeft:
            !this.props.isHideSelectbox && this.props.ind == 0 ? 39 : 8,
        }}
        colSpan={!this.props.isHideSelectbox && this.props.ind == 0 ? 2 : 1}
        onClick={this.getColumnInfo}
      >
        <Resizable
          enable={{
            top: false,
            right: true,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minWidth={80}
          size={{ width: this.props.column.width }}
          onResizeStop={(e, direction, ref, d) => {
            this.props.handleResizeColumn(this.props.ind, d.width);
          }}
          handleClasses={{ right: "th-resize-handler" }}
        >
          <div
            className="th"
            style={{ width: this.props.column.width, overflow: "hidden" }}
          >
            <span>{this.props.column.label}</span>
            {this.state.sortColumn === this.props.sortColumn ? (
              <FontAwesomeIcon
                className="pointer"
                onClick={this.props.onCollapse}
                icon={this.state.sortOrder === "asc" ? faAngleDown : faAngleUp}
                color="grey"
                size="xl"
              />
            ) : (
              ""
            )}
          </div>
        </Resizable>
      </th>
    );
  }
}

export default TH;

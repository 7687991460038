const HiAttachment = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill={bgColor ?? "none"}
    >
      <path
        d="M17.6271 9.08296L10.1141 16.596C8.40556 18.3045 5.63546 18.3045 3.92692 16.596C2.21837 14.8874 2.21837 12.1173 3.92692 10.4088L11.4399 2.89578C12.579 1.75675 14.4257 1.75675 15.5647 2.89578C16.7037 4.0348 16.7037 5.88154 15.5647 7.02057L8.34633 14.2389C7.77682 14.8085 6.85345 14.8085 6.28394 14.2389C5.71442 13.6694 5.71442 12.7461 6.28394 12.1766L12.6184 5.84205"
        stroke={lineColor}
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HiAttachment;

import React from 'react';
import LoginAPI from './LoginAPI';
import {tokenVar,resourceId,resourceFullName} from './../auth/Auth.js';
import './Login.css'
import logo from './../images/beat_mark_white.png'
import TextField from '../common/inputControls/textField';
import MainButton from '../common/buttons/MainButton';
import ErrorDiv from '../common/ErrorDiv';


class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {username:"", password:"", error:""}
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleLoginResult = this.handleLoginResult.bind(this);
    }

    handleChange(e, i) {
        //console.log("login handleChange");
        //console.log(e);
        //console.log(i);
        let newState = this.state;
        newState[i] = e
        this.setState(newState);
       // console.log(this.state)
    }

    handleKeyDown=(e)=> {
       // console.log("handleKeyDown");
        if (e.key === 'Enter' || e.key==="NumpadEnter") {
            this.handleSubmit();
        }
    }
    
    handleLoginResult(r) {

       // console.log(r)
        if(r.status===200) {
            localStorage.setItem(tokenVar, r.body.token);
            
            this.getUserInfoFromToken();
            
        } else { this.setState({...this.state, error: r.body.message}) }
    }

    getUserInfoFromToken(){

        LoginAPI.getUserInfo(this.changePage);
        
    }

    changePage(){
            window.location.href = "/";
    }
    
    handleSubmit() {
        LoginAPI.login(JSON.stringify({username: this.state.username, password:this.state.password}), this.handleLoginResult);
    }

   render() {
        return (<div className="hub-loginPage">
                <div className="hub-loginWrapper">
                    <div className="hub-loginLogo">
                        <img src={logo} style={{height:48}}></img>
                    </div>
                    <div className="hub-login">
                        <ErrorDiv operation="Login" show={this.state.error.length>0?true:false} message={this.state.error} />
                        <TextField onChange={(area,e)=>(this.handleChange(e,'username' ))} onKeyDown={this.handleKeyDown} required width="100%" label="Username"/>
                        <TextField onChange={(area,e)=>(this.handleChange(e,'password' ))} onKeyDown={this.handleKeyDown} type="password" width="100%" label="Password" required/>
                        <div style={{clear:'both'}}></div>
                        <div style={{textAlign:'right'}}><MainButton onClick={this.handleSubmit} label="Login" /></div>
                    </div>
                </div>
            </div>);
    }
}

export default Login;
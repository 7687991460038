import React, { Component } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import './TsProgress.css';

class TsProgress extends Component {
    render() {
        let val = this.props.value?this.props.value:0
        return (
            <div className="hub-ts-progress">
                <div className="hub-ts-progress-completion" style={{width:val.toString()+"%"}}></div>
                <div className="hub-ts-progress-body">
                    <div className="hub-ts-progress-chart">
                        <PieChart style={{ height: '22px', width: '22px'}} 
                            data={[{ value: val, color: '#6716D0' }]}
                            totalValue={100}
                            lineWidth={48}
                            labelPosition={0}
                            background="#701EF633"
                            lengthAngle={-360}
                            startAngle={90}
                            rounded
                            animated
                        />
                    </div>
                    <div className="hub-ts-progress-label">
                        {val.toString()}%
                    </div>
                </div>
            </div>
        );
    }
}

export default TsProgress;
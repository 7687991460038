import React, { Component } from 'react';
import { Routes, Route } from "react-router-dom";

import Tab from '../../common/tabs/Tab';
import TabGroup from '../../common/tabs/TabGroup';

import TabContentGroup from '../../common/tabs/TabContentGroup';
import TabContent from '../../common/tabs/TabContent';
import AssignmentDetails from './AssignmentDetails.js'
import NewAssignment from './NewAssignment.js'

class OtherWorkAssignmentSidebar extends Component {
      
    constructor(props) {
        super(props);
        this.state = {
            activePage: "assignmentDetail",
            newCreate:false,
            selectedRecord:[]
        }
    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }

    componentDidMount()
    {
       //console.log("componentDidMount")
       this.setState({...this.state, newCreate:false,activePage:"assignmentDetail",selectedRecord:this.props.selectedRecord});
    }

    componentDidUpdate(prevPros)
    {

      //console.log("otherowrk assignment sidebar")
      //console.log(this.props)

 
      if(this.props.newCreate!==prevPros.newCreate)
        {
          //console.log("OtherWorkAssignmentSidebar")
          //console.log(prevPros)
         //console.log(this.props)
         this.setState({...this.state, newCreate:this.props.newCreate,activePage:"newAssignment", selectedRecord:this.props.selectedRecord});
        }

         if(this.props.selectedRecord!==prevPros.selectedRecord)
        {
          //console.log("selectedRecord")
          //console.log(prevPros)
         //console.log(this.props)
          this.setState({...this.state, newCreate:false,activePage:"assignmentDetail",selectedRecord:this.props.selectedRecord});
        }
    }

    setPageToDetails =(e)=>{
      //console.log("setPageToDetails");
      //console.log(e);

        this.setState({...this.state, activePage:"assignmentDetail" ,newCreate:false ,selectedRecord:e });
    }

 
    render() {
       
        return (
          <div className="hub-tabGroup-wrapper"  >
            <TabGroup >
               { !this.state.newCreate?
               <Tab to="assignmentDetail" onClick={this.setActivePage} label="Detail" activePage={this.state.activePage} />:""}
               { this.state.newCreate?
               <Tab to="newAssignment" onClick={this.setActivePage} label="New Assignment" activePage={this.state.activePage} />:""}
            </TabGroup> 
            <TabContent to="assignmentDetail" activePage={this.state.activePage}>
                <AssignmentDetails selectedRecord={this.state.selectedRecord} title={this.props.title}  save={this.props.save} />
            </TabContent>
             <TabContent to="newAssignment" activePage={this.state.activePage}>
                <NewAssignment selectedRecord={this.props.selectedRecord} parentRecord={this.props.parentRecord} title={this.props.title} changePage={this.setPageToDetails} save={this.props.save}/>
            </TabContent>
          </div>
        );
    }
}




export default OtherWorkAssignmentSidebar;

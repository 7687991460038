import "./SkeletonLoader.css";

const SkeletonLoader = ({ width, height, borderRadius }) => {
  const style = {
    width: width || "100%",
    height: height || "15px",
    borderRadius: borderRadius || "6px",
  };

  return <div className="skeleton" style={style} />;
};

export default SkeletonLoader;

import React from "react";

const HiMyTimeSheets = ({ bgColor, lineColor }) => {
  return (
    <svg
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.83335 14.1667L6.23795 16.1896C6.39067 16.9533 6.46703 17.3351 6.66639 17.6207C6.84219 17.8725 7.08416 18.0709 7.36559 18.1939C7.68474 18.3333 8.0741 18.3333 8.85283 18.3333H11.1472C11.9259 18.3333 12.3153 18.3333 12.6344 18.1939C12.9159 18.0709 13.1579 17.8725 13.3337 17.6207C13.533 17.3351 13.6094 16.9533 13.7621 16.1896L14.1667 14.1667M5.83335 5.83334L6.23795 3.81036C6.39067 3.04675 6.46703 2.66495 6.66639 2.37936C6.84219 2.12751 7.08416 1.92914 7.36559 1.80615C7.68474 1.66667 8.0741 1.66667 8.85283 1.66667H11.1472C11.9259 1.66667 12.3153 1.66667 12.6344 1.80615C12.9159 1.92914 13.1579 2.12751 13.3337 2.37936C13.533 2.66495 13.6094 3.04675 13.7621 3.81036L14.1667 5.83334M10 7.5V10L11.25 11.25M15.8334 10C15.8334 13.2217 13.2217 15.8333 10 15.8333C6.77836 15.8333 4.16669 13.2217 4.16669 10C4.16669 6.77834 6.77836 4.16667 10 4.16667C13.2217 4.16667 15.8334 6.77834 15.8334 10Z"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiMyTimeSheets;

import React, { Component } from 'react'
import Auth from '../../../auth/Auth';
import PieChart from '../../../widgets/pieChart';
import GaugeChart from '../../../widgets/gaugeChart';
import BarChart from '../../../widgets/barChart';
import MetricChart from '../../../widgets/metricChart';
import WidgetGrid from '../../../widgets/grid';
import AreaChart from '../../../widgets/areaChart';
import Widget from '../../../widgets/Widget';
import LoadingScreen from '../../statusScreen/loadingScreen';
import WidgetGroup from '../../../widgets/widgetGroup/WidgetGroup';
import TextWidget from '../../../widgets/textWidget';
export default class widgetCreator extends Component {
    constructor(props) {
        
        super(props);
        this.widgetRef = React.createRef();
        this.state={
             data: {},
             config:{},
             loading:true
            }
    }
    componentDidMount() 
    { 
        this.getData() 
    }


    getData = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
        };
        fetch('/api/widget/code/'+this.props.code, requestOptions)
        .then(response => response.json().then(data => ({status:response.status, body:data})))
        .then(r => {
           // console.log(r)
            if (r.status===200) {
                this.setState({...this.state, loading:false, data:r.body.object,config:JSON.parse(r.body.object?.configuration ) });
            } else if (r.status===401) {
                window.location.replace("/login");
            } else if (r.status===403) {
                this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
            } else {
                this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
            }
        });
    }


    getWidget(){
      //  console.log("getWidget",this.state.config.widgetType,this.state)
  
        switch (this.state.config.widgetType) {
            
            case "staticText": 
            return <TextWidget config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute} widgetRef={this.widgetRef}/>
            case "groupText": 
            return <WidgetGroup childs = {this.props.childs} config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute} widgetRef={this.widgetRef}/>
            case "pie":
                return  <PieChart config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
                
            case "gauge":
                return <GaugeChart config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute} widgetRef={this.widgetRef}/>

            case "bar":
                return <BarChart config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>
            case "metric":
                return <MetricChart config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute} widgetRef={this.widgetRef}/>
            case "grid":
                return <Widget title={this.state.config.title}  link = {this.state.config.link?this.state.config.link:false}  widgetRef={this.widgetRef}>
                            <div style={{display:"flex", position:"relative"}}>
                                <WidgetGrid config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute} ref={this.widgetRef}/> 
                            </div>
                         </Widget>
            case "area":
                return <AreaChart config={this.state.config} selectedFilters={this.props.selectedFilters} counter={this.props.counter} multiplexingFilter={this.props.multiplexingFilter} multiplexingAttribute={this.props.multiplexingAttribute}/>

                default:
                    return false

            }
       }


  render() {
    //console.log("props",this.widgetRef)
    return (
       
         this.getWidget() 
    )
  }
}

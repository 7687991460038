import React, { Component } from "react";
import TimesheetWorklog from "./TimesheetWorklog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faAngleRight,
} from "@fortawesome/pro-regular-svg-icons";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import TsDeadline from "./metrics/TsDeadline";
import TsProgress from "./metrics/TsProgress";
import ContextMenu from "./../../../common/contextMenu/contextMenu";
import ContextItem from "./../../../common/contextMenu/contextItem";

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object);

class TimeReportLine extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      completionRatio: this.calculateCompletionRatio(),
    });
  }
  setWorklogHours = (worklogRow, newHours) => {
    this.props.setWorklogHours(this.props.lineRow, worklogRow, newHours);
  };

  setRemainingHour = (newHours) => {
    this.props.setRemainingHour(this.props.lineRow, newHours);
    this.setState({
      ...this.state,
      completionRatio: this.calculateCompletionRatio(),
    });
  };

  openMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ ...this.state, isOpen: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, isOpen: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
  };

  onDelete = () => {
    this.props.removeTimeReportLine(this.props.line);
  };

  onComplete = () => {
    this.props.setCompleted(this.props.lineRow, !this.props.line.isCompleted);
  };

  calculateCompletionRatio = () => {
    if (
      parseFloat(this.props.line.assignmentInfo.actualUnit) +
        parseFloat(this.props.line.actual) +
        parseFloat(this.props.line.remaining) >
      0.0
    ) {
      let result = (
        ((parseFloat(this.props.line.assignmentInfo.actualUnit) +
          parseFloat(this.props.line.actual)) *
          100) /
        (parseFloat(this.props.line.assignmentInfo.actualUnit) +
          parseFloat(this.props.line.actual) +
          parseFloat(this.props.line.remaining))
      ).toFixed(0);
      return result >= 100 ? 100 : result;
    } else {
      return 0;
    }
  };

  render() {
    return (
      <div className="hub-ts-timesheetTableRow">
        <div className="hub-ts-timesheetTable-td task">
          <div>
            <div className="header orange">
              <div className="task-code">11-0002</div>{" "}
              <div className="project-name">{this.props.line.project}</div>
            </div>
            <div className="task-name">
              {" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  "/project/" +
                  this.props.line?.projectId.toString() +
                  "/wbs?taskId=" +
                  this.props.line?.taskId
                }
              >
                {this.props.line.task}
              </a>
            </div>
            <div style={{ marginTop: "4px" }}>
              {
                // Additional Columns
                this.props.visibleColumns.map((c) => {
                  return (
                    <div className="sub-info">
                      {c.label}:{" "}
                      <strong>{resolvePath(this.props.line, c.dataKey)}</strong>
                    </div>
                  );
                })
              }
            </div>
            <div className="metrics">
              {/*<TsEffortStatus primaryValue={450} secondaryValue={700} />*/}
              <TsProgress
                value={
                  this.props.line.isCompleted
                    ? 100
                    : this.calculateCompletionRatio()
                }
              />
              <TsDeadline label="30 Mar" />
            </div>
            {/* 
                            <div className="details"> 
                                <span>See Detail</span> <FontAwesomeIcon icon={faAngleRight} />
                            </div>    
                            */}
          </div>
          {this.props.isEditable ? (
            <div
              onClick={this.openMenu}
              className={this.state.isOpen ? "icon open" : "icon"}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
              {this.state.isOpen ? (
                <ContextMenu>
                  <ContextItem
                    color={"#000000"}
                    onClick={this.onDelete}
                    icon={faTrashCan}
                    label="Delete"
                  />
                </ContextMenu>
              ) : (
                false
              )}
            </div>
          ) : (
            false
          )}
        </div>
        {this.props.line.worklogs.map((p, i) => {
          return (
            <div
              className={
                this.props.days[i]?.exception
                  ? "hub-ts-timesheetTable-td exception"
                  : "hub-ts-timesheetTable-td"
              }
            >
              <TimesheetWorklog
                disabled={this.props.isEditable}
                setWorklogHours={this.setWorklogHours}
                key={
                  "TRL-" + this.props.line.uid.toString() + "-D" + i.toString()
                }
                worklogRow={i}
                val={p.actual === "" ? 0 : p.actual}
              />
            </div>
          );
        })}

        <div className="hub-ts-timesheetTable-td totalCell">
          <div className="remaining">
            <TimesheetWorklog
              disabled={
                (this.props.line.isCompleted ? false : this.props.isEditable) &&
                this.props.line.projectId != 85
              }
              setRemainingHour={this.setRemainingHour}
              worklogRow={99}
              val={this.props.line.isCompleted ? 0 : this.props.line.remaining}
              key={"TRL-" + this.props.line.uid.toString() + "-ETC"}
            />
          </div>
          <div className="total">
            {this.props.line.actual}
            <br /> Total
          </div>
        </div>
        <div className="hub-ts-timesheetTable-td done">
          <input
            disabled={
              this.props.isEditable === false ||
              this.props.line.projectId === 85
            }
            type="checkbox"
            checked={this.props.line.isCompleted}
            onChange={this.onComplete}
          />
        </div>
      </div>
    );
  }
}

export default TimeReportLine;

import React from "react";

const HiFavorite = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      viewBox="0 0 20 20"
    >
      <path
        d="M9.75256 2.73315C9.95774 2.31746 10.0603 2.10962 10.1996 2.04321C10.3208 1.98543 10.4616 1.98543 10.5827 2.04321C10.722 2.10962 10.8246 2.31746 11.0298 2.73314L12.9765 6.67686C13.037 6.79958 13.0673 6.86094 13.1116 6.90858C13.1508 6.95076 13.1978 6.98494 13.25 7.00922C13.309 7.03664 13.3767 7.04654 13.5121 7.06633L17.8665 7.70279C18.325 7.76981 18.5543 7.80332 18.6604 7.91531C18.7527 8.01275 18.7961 8.14665 18.7785 8.27972C18.7583 8.43267 18.5923 8.59434 18.2604 8.91767L15.1107 11.9855C15.0125 12.0811 14.9634 12.1289 14.9317 12.1858C14.9037 12.2362 14.8857 12.2915 14.8788 12.3487C14.8709 12.4134 14.8825 12.4809 14.9057 12.616L15.6489 16.9491C15.7272 17.4062 15.7664 17.6347 15.6928 17.7703C15.6287 17.8883 15.5148 17.971 15.3828 17.9955C15.231 18.0236 15.0258 17.9157 14.6154 17.6999L10.7227 15.6527C10.6014 15.5889 10.5407 15.557 10.4768 15.5445C10.4203 15.5334 10.3621 15.5334 10.3055 15.5445C10.2416 15.557 10.181 15.5889 10.0597 15.6527L6.16693 17.6999C5.75652 17.9157 5.55132 18.0236 5.39959 17.9955C5.26758 17.971 5.15366 17.8883 5.08958 17.7703C5.01592 17.6347 5.05512 17.4062 5.1335 16.9491L5.87668 12.616C5.89985 12.4809 5.91143 12.4134 5.9036 12.3487C5.89665 12.2915 5.87866 12.2362 5.85061 12.1858C5.81894 12.1289 5.76984 12.0811 5.67166 11.9855L2.52198 8.91767C2.19002 8.59433 2.02403 8.43267 2.00383 8.27972C1.98626 8.14665 2.02968 8.01275 2.12199 7.91531C2.22809 7.80332 2.45736 7.76981 2.9159 7.70279L7.27028 7.06633C7.40569 7.04654 7.4734 7.03664 7.53237 7.00922C7.58458 6.98494 7.63158 6.95076 7.67077 6.90858C7.71503 6.86094 7.74532 6.79958 7.8059 6.67686L9.75256 2.73315Z"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiFavorite;

import React, { Component } from 'react';
//import InputArea from './../../common/Inputs/Text/index.js';

import Tab from '../../common/tabs/Tab';
import TabGroup from '../../common/tabs/TabGroup';


import ActivityAssignmentDetails from './ActivityAssignmentDetails';

import TabContent from '../../common/tabs/TabContent';

import NewAssignment from './NewAssignment.js'
import NewActivityAssignment from './newObjects/newActivityAssignment';

class ActivityAssignmentSidebar extends Component {
/**/
   constructor(props) {
        super(props);
        this.state = {
            activePage: "assignmentDetail",
            newCreate:false,
            selectedRecord:[]
        }
    }


    setActivePage = (page) => {
      this.setState({...this.state, activePage:page});
    }

    componentDidMount()
    {
       //console.log("componentDidMount")
       this.setState({...this.state, newCreate:false,activePage:"assignmentDetail",selectedRecord:this.props.selectedRecord});
    }

    componentDidUpdate(prevProps)
    {

      //console.log(this.props)

            if(prevProps.newCreate===false && this.props.newCreate===true)
        {
          //console.log("new true")
          
            this.setState({...this.state, activePage:"newAssignment",selectedRecord:this.props.selectedRecord});

        }

          if(prevProps.newCreate===true && this.props.newCreate===false)
        {
          //console.log("new true")
          
             this.setState({...this.state, newCreate:false,activePage:"assignmentDetail",selectedRecord:this.props.selectedRecord});

        }

         if(this.props.selectedRecord!==prevProps.selectedRecord)
        {
          //console.log("selectedRecord")
          //console.log(prevPros)
         //console.log(this.props)
          this.setState({...this.state, newCreate:false,activePage:"assignmentDetail",selectedRecord:this.props.selectedRecord});
        }
    }

    setPageToDetails =(e)=>{
      //console.log("setPageToDetails");
      //console.log(e);

        this.setState({...this.state, activePage:"assignmentDetail" ,newCreate:false ,selectedRecord:e });
    }

 
    render() {
      // console.log("activityassignmentsidebar",this.props)
        return (
          <div className="hub-tabGroup-wrapper"  >
            <TabGroup >
               { !this.props.newCreate?
               <Tab to="assignmentDetail" onClick={this.setActivePage} label="Detail" activePage={this.state.activePage} />:""}
               { this.props.newCreate?
               <Tab to="newAssignment" onClick={this.setActivePage} label="New Assignment" activePage={this.state.activePage} />:""}
            </TabGroup> 
            <TabContent to="assignmentDetail" activePage={this.state.activePage}>
                <ActivityAssignmentDetails selectedRecord={this.state.selectedRecord} title={this.props.title} save={this.props.save} project={this.props.project} />
            </TabContent>
             <TabContent to="newAssignment" activePage={this.state.activePage}>
                <NewActivityAssignment selectedRecord={this.props.selectedRecord} parentRecord={this.props.parentRecord} title={this.props.title} changePage={this.setPageToDetails} save={this.props.save}/>
            </TabContent>
          </div>
        );
    }
}

export default ActivityAssignmentSidebar;
import React, { Component, useState } from "react";
import Row from "./Row";
import "./Timeline.css";
import { DateUtils } from "./../../../common/Commons";
import { Utils } from "./../../../common/Commons";
import Auth from "./../../../auth/Auth";

import LoadingScreen from "../../../common/statusScreen/loadingScreen";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import Select, { components } from "react-select";
import MainButton from "../../../common/buttons/MainButton";

import SideBar from "./../../../common/dataTable/SideBar";

import TaskBoardSidebarContainer from "../../taskBoard/sideBar/TaskBoardSidebarContainer";
import HiDownload from "../../../common/icons/HiDownload";
import HiFlipForward from "../../../common/icons/HiFlipForward";
import ToggleButtonGroup from "../../../common/buttons/ToggleButtonGroup";
import Toolbar from "../../../common/pageElements/toolbar/Toolbar";
import HiCollapse from "../../../common/icons/HiCollapse";
import HiExpand from "../../../common/icons/HiExpand";
import PickerButton from "../../../common/buttons/PickerButton";
import Avatar from "../../../common/avatar";
import HubIcon from "../../../common/hubIcon/HubIcon";
import HiArrowShortDown from "../../../common/icons/HiArrowShortDown";
import SearchSelect from "../../../common/inputControls/SearchSelect";
import THBasic from "../../../common/tableElements/THBasic";
import TheadBasic from "../../../common/tableElements/TheadBasic";
import HiTimelineCursor from "../../../common/icons/HiTimelineCursor";
import TRBar from "../../../common/tableElements/TRBar";
import Item from "./Item";
import TH from "../../../common/tableElements/TH";
import Thead from "../../../common/tableElements/Thead";
import TR from "../../../common/tableElements/TR";
import TD from "../../../common/tableElements/TD";
import HiArrowShortRight from "../../../common/icons/HiArrowShortRight";
import { Resizable } from "re-resizable";
import { perPage } from "../../../static/config";
import Pagination from "../../../common/tableElements/Pagination";

const INIT_COLUMNS = [
  {
    id: "taskName",
    dataKey: "name",
    label: "Resource / Task",
    type: "text",
    width: 300,
  },
  {
    id: "startDate",
    dataKey: "startDate",
    label: "Start Date",
    type: "date",
    width: 120,
  },
  {
    id: "finishDate",
    dataKey: "finishDate",
    label: "Finish Date",
    type: "date",
    width: 120,
  },
  {
    id: "percentComplete",
    dataKey: "percentComplete",
    label: "% Complete",
    type: "percentage",
    width: 120,
  },
];

function pad2(n) {
  return n < 10 ? "0" + n : n;
}

const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

const getHeaderLists = (startDate, finishDate) => {
  let weeks = [];
  let months = [];
  let years = [];

  let start = getMonday(startDate);

  for (var d = start; d <= finishDate; d.setDate(d.getDate() + 7)) {
    months.push(new Date(d));
    if (years[d.getFullYear()] !== undefined) {
      years[d.getFullYear()] = years[d.getFullYear()] + 1;
    } else {
      years[d.getFullYear()] = 1;
    }
  }

  return { months: months, years: years };
};

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.utils = new Utils();
    this.state = {
      rawTasks: [],
      tasks: [],
      data: [],
      periods: [],
      rawData: [],
      filterLists: {
        projects: [],
        resources: [],
      },
      filter: null,
      page: 0,
      perPage: 25,
      groupedData: {},
      groupedDataList: [],
      ganttStart: new Date(),
      ganttFinish: new Date(),
      Loading: true,
      mode: "hierarchy",
      isSideBarOpen: false,
      sidebarWidth: 700,
      selectedRecord: {},
      selectedRecordIndex: [],
      hoveredRecord: {},
      collapseStatus: false,
      expandArray: [],
      scroll: 0,
      width: 750,
      columns: INIT_COLUMNS,
    };
    this.dutils = new DateUtils();
  }

  setMode = (mode) => {
    this.setState({ ...this.state, mode: mode }, () => {
      this.updateUserView("mode", mode);
    });
  };

  updateUserView = (attributeName, attribute, attribute2) => {
    if (true) {
      let mode = this.state.mode;
      let expandArray = this.state.expandArray;
      let selectedRecord = {};
      if (this.state.selectedRecord?.uid) {
        selectedRecord = { uid: this.state.selectedRecord?.uid };
      }

      let isSideBarOpen = this.state.isSideBarOpen;
      let scroll = this.state.scroll;
      let sidebarWidth = this.state.sidebarWidth;
      let collapseStatus = this.state.collapseStatus;
      let selectedRecordIndex = this.state.selectedRecordIndex;

      switch (attributeName) {
        case "mode":
          mode = attribute;
          break;
        case "expandArray":
          expandArray = attribute;
          break;
        case "collapseStatus":
          collapseStatus = attribute;
          break;
        case "selectedRecord":
          selectedRecord = attribute;
          selectedRecordIndex = attribute2;
          break;
        case "isSideBarOpen":
          isSideBarOpen = attribute;
          break;

        case "scroll":
          scroll = attribute;
          break;
        case "sidebarWidth":
          sidebarWidth = attribute;
          break;
        default:

        // code block
      }

      this.utils.updateUserView("timeline", {
        mode: mode,
        expandArray: expandArray,
        selectedRecord: selectedRecord,
        isSideBarOpen: isSideBarOpen,
        collapseStatus: collapseStatus,
        scroll: scroll,
        sidebarWidth: sidebarWidth,
        selectedRecordIndex: selectedRecordIndex,
      });
    }
  };

  exportToCSV = () => {
    // Prepare Data Array
    let rows = [];

    // Push Column Headers
    let row = [
      "Resource",
      "Project",
      "WBS",
      "Activity",
      "Task",
      "Start",
      "Finish",
      "Planned Units",
      "Actual Units",
      "Remaining Units",
      "Task % Complete",
      "Assignment % Complete",
    ];
    rows.push(row);

    // Push Values if data exists
    if (this.state.rawData) {
      this.state.rawData.object?.map((d) => {
        let row = [
          '"' + d.first_name + " " + d.last_name + '"', // Resource
          '"' + d.project_name + '"',
          '"' + d.wbs_name + '"',
          '"' + d.activity_name + '"',
          '"' + d.name + '"',
          '"' + this.dutils.Date2Str(d.start_date) + '"',
          '"' + this.dutils.Date2Str(d.finish_date) + '"',
          '"' + d.planned_unit + '"',
          '"' + d.actual_unit + '"',
          '"' + d.remaining_unit + '"',
          '"' + Math.round(d.percent_complete * 100) + '"',
          '"' + Math.round(d.assignee_complete * 100) + '"',
        ];
        rows.push(row);
      });
    }

    // Prepare Content

    var universalBOM = "\uFEFF";
    let csvContent =
      "data:text/csv;charset=utf-8," +
      universalBOM +
      rows.map((e) => e.join(";")).join("\n");

    var encodedUri = encodeURI(csvContent);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);

    // File Name
    var date = new Date();
    let dateSuffix =
      date.getFullYear().toString() +
      pad2(date.getMonth() + 1) +
      pad2(date.getDate()) +
      pad2(date.getHours()) +
      pad2(date.getMinutes()) +
      pad2(date.getSeconds());

    let fileName = "timeline" + "_" + dateSuffix;
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    document.body.removeChild(link);
  };

  toggleSidebar = () => {
    this.setState(
      { ...this.state, isSideBarOpen: !this.state.isSideBarOpen },
      () => {
        this.updateUserView("isSideBarOpen", this.state.isSideBarOpen);
      }
    );
  };

  openSidebar = () => {
    //console.log("openSidebar",type.target)
    this.setState({ ...this.state, isSideBarOpen: true });
  };

  setSidebarWidth = (width) => {
    //console.log("isSideBarOpen",width)
    this.setState({ ...this.state, sidebarWidth: width }, () => {
      this.updateUserView("sidebarWidth", width);
    });
  };

  selectedRecord = (record, resource, index, rowNum) => {
    //console.log(record,resource,index,rowNum)
    let createdBy = {
      uid: record.created_by,
      fullName: record.created_by_name,
    };
    let timelineIndex = { resource: resource, index: index };
    Object.assign(record, {
      wbsId: record.wbs_id,
      activityId: record.activity_id,
      projectId: record.project_id,
      createdDate: record.created_date,
      createdBy: createdBy,
      timelineIndex: timelineIndex,
    });

    //console.log("selectedRecord",record,resource,index)
    this.setState(
      {
        ...this.state,
        selectedRecord: record,
        selectedRecordIndex: [resource, index],
      },

      () => {
        this.updateUserView("selectedRecord", { uid: record.uid }, [
          resource,
          index,
        ]);
        this.findTaskPosition(record.left - 50);
      }
    );
  };

  componentDidMount() {
    // console.log("componentDidMount");
    this.getData(
      this.props.selectedOBS,
      this.props.startDate,
      this.props.finishDate,
      (x) => {
        this.groupData(x);
      }
    );
  }

  componentDidUpdate(prevProps) {
    //console.log("componentDidUpdate",this.props.selectedOBS);
    if (
      prevProps.selectedOBS.uid !== this.props.selectedOBS.uid ||
      prevProps.startDate !== this.props.startDate ||
      prevProps.finishDate !== this.props.finishDate
    ) {
      this.getData(
        this.props.selectedOBS,
        this.props.startDate,
        this.props.finishDate,
        (x) => {
          this.groupData(x);
        }
      );
    }
  }

  filterRawData = (filters, body) => {
    return body;
  };

  /*
        Lazım olur diye biıraktım, ne işe yaradığı anlaşılamadığında yorumlu bölge silinebilir.
        dateDiff = (startingDate, endingDate) => {
    
            var startDate = startingDate;
            var endDate = endingDate;
        
            if (startDate > endDate) {
                var swap = startDate;
                startDate = endDate;
                endDate = swap;
            }
            var startYear = startDate.getFullYear();
            var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
            var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        
            var yearDiff = endDate.getFullYear() - startYear;
            var monthDiff = endDate.getMonth() - startDate.getMonth();
            if (monthDiff < 0) {
                yearDiff--;
                monthDiff += 12;
            }
            var dayDiff = endDate.getDate() - startDate.getDate();
            if (dayDiff < 0) {
                if (monthDiff > 0) {
                    monthDiff--;
                } else {
                    yearDiff--;
                    monthDiff = 11;
                }
                dayDiff += daysInMonth[startDate.getMonth()];
            }
            
            return yearDiff*12.0+ + monthDiff*1.0 + dayDiff/daysInMonth[endDate.getMonth()];
        }
    */

  dateDiff = (startingDate, endingDate) => {
    const diffTime = Math.abs(endingDate - startingDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays / 7;
  };

  getWidth = (
    startDate,
    finishDate,
    roadmapLeft = null,
    roadmapRight = null
  ) => {
    if (startDate === null || finishDate === null) {
      return 0;
    }
    if (roadmapLeft !== null && roadmapLeft > startDate) {
      startDate = roadmapLeft;
    }
    return finishDate > startDate
      ? Math.round(this.dateDiff(startDate, finishDate) * 150)
      : 0;
  };

  getData = async (
    obs,
    startDate,
    finishDate,
    callback = () => {
      void 0;
    }
  ) => {
    //console.log("timeline get data",obs,startDate, finishDate)
    //console.log("startDate",startDate,obs!==undefined )

    let viewObject = await this.utils.getUserView("timeline");

    let view = viewObject?.general;

    //  console.log("viewObject",view)
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    let serchString = "";
    if (
      obs !== undefined ||
      (startDate !== undefined && startDate !== "") ||
      (finishDate !== undefined && finishDate !== "")
    ) {
      serchString += "?";
    }

    if (obs !== undefined) {
      serchString += "obsId=" + obs.uid.toString();
      // serchString='?obsId='+obs.uid;
    }
    /**/
    if (startDate !== undefined && startDate !== "") {
      serchString += "&startDate=" + startDate;
      // serchString='?obsId='+obs.uid;
    } else if (startDate === undefined || startDate === "") {
      serchString += "&startDate=";
    }
    if (finishDate !== undefined && finishDate !== "") {
      serchString += "&finishDate=" + finishDate;
      // serchString='?obsId='+obs.uid;
    } else if (finishDate === undefined || finishDate === "") {
      serchString += "&finishDate=";
    }

    //console.log("serchString",serchString)
    // query/code/completedOnBudgetPie
    fetch("/api/query/code/hub_timeline_tasks" + serchString, requestOptions)
      //fetch('/api/timeline'+serchString, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status === 200) {
          //console.log("r",r,"/api/query/code/hub_timeline_tasks"+serchString)

          let filteredData = this.filterRawData({}, r.body.object);
          let dutils = new DateUtils();

          let groupedData = {};
          let groupedDataList = [];
          let ganttStart = new Date();
          let ganttFinish = new Date();

          // Data tarihe göre sıralanıyor.
          if (filteredData !== null) {
            filteredData.sort((a, b) => {
              return new Date(a.start_date) - new Date(b.start_date);
            });

            filteredData.map((d) => {
              // İlk döngü kayıtları kaynaklara göre grupluyor.
              // Aynı zamanda ilk başlayan ve son biten kayıtlar üzerinden gantt start ve gantt finishi oluşturuyor.
              if (!groupedData[d.unique_name]) {
                groupedData[d.unique_name] = {
                  uid: d?.resource_uid,
                  color: d?.color,
                  colorCode: d?.color_code,
                  firstName: d?.first_name,
                  lastName: d?.last_name,
                  resource_name: d.first_name + " " + d.last_name,
                  role_name: d.role_name,
                  resource_id: d.resource_id,
                  is_expanded: false,
                  tasks: [],
                  rows: [],
                  hasAvatar: d?.crop_image_hash && d?.profile_image_hash,
                };
                groupedDataList.push(d.unique_name);
              }

              groupedData[d.unique_name].tasks.push(d);

              if (ganttStart > new Date(d.start_date)) {
                ganttStart = new Date(d.start_date);
              }

              if (ganttFinish < new Date(d.finish_date)) {
                ganttFinish = new Date(d.finish_date);
              }
            });
          }

          // Gantt Start ilgili haftanın ilk gününe alınıyor.

          ganttStart = getMonday(ganttStart); //Burada getMonday dateUtils den kullanılmalı. Teknik Borç

          // Gantt Start bir hafta öne çekilir, Gantt Finish 2 hafta ileri itilir.
          ganttStart.setDate(ganttStart.getDate() - 7);

          ganttFinish = getMonday(ganttFinish);
          ganttFinish.setDate(ganttFinish.getDate() + 21);

          // satırlar oluşuyor ve data satırlara dağıtılıyor.
          // Aynı zamanda width'ler ve left'ler hesaplanıyor.

          groupedDataList.map((g) => {
            let newGroupedDataItem = groupedData[g];

            // İlk row'u oluştur.

            newGroupedDataItem.rows.push({
              finishDate: new Date(0),
              tasks: [],
            });

            newGroupedDataItem.tasks.map((t) => {
              let newTask = t;

              //console.log("newTask", newTask);

              // Taskın width ve leftini hesapla
              newTask.width = this.getWidth(
                new Date(t.start_date),
                new Date(t.finish_date)
              );
              newTask.left = this.getWidth(ganttStart, new Date(t.start_date));

              // Taskı bir lane'e at

              // lane dağıtımı
              var w = 0;

              while (newGroupedDataItem.rows[w] !== undefined) {
                w++; // continue'dan dolayı en başta yapıyoruz arttırımı.
                let j = w - 1; // ama kontrollerde arttırılmamışı kullanmamız gerkeiyor.

                //Eğer lane'e uyarsa bu lane'e ekliyor.
                if (
                  newGroupedDataItem.rows[j].finishDate < new Date(t.start_date)
                ) {
                  newGroupedDataItem.rows[j].tasks.push(newTask);
                  newGroupedDataItem.rows[j].finishDate = new Date(
                    t.finish_date
                  );
                  break;
                }
                //Eğer hiç bir lane'e uymaz ve başka lane yoksa yeni lane ekliyor.
                if (newGroupedDataItem.rows[w] === undefined) {
                  newGroupedDataItem.rows.push({
                    finishDate: new Date(0),
                    tasks: [],
                  });
                }
              }
            });

            groupedData[g] = newGroupedDataItem;
          });

          let periods = dutils.getWeeks(ganttStart, ganttFinish);

          // Listeyi alfabetik sıralıyoruz.
          groupedDataList.sort((a, b) => {
            return groupedData[a].resource_name > groupedData[b].resource_name
              ? 1
              : -1;
          });

          // Filterleri oluşturuyoruz.
          let resourceFiltreList = [];
          groupedDataList.map((fr) => {
            resourceFiltreList.push({
              value: fr,
              label: groupedData[fr].resource_name,
              uid: groupedData[fr].uid,
              color: groupedData[fr].color,
              firstName: groupedData[fr].firstName,
              lastName: groupedData[fr].lastName,
              colorCode: groupedData[fr].colorCode,
              hasAvatar: groupedData[fr]?.hasAvatar,
            });
          });

          // toolbar'daki tarih metinleri için.
          let dataStartDate = new Date();
          dataStartDate.setDate(dataStartDate.getDate() - 30);
          let dataFinishDate = new Date();
          dataFinishDate.setDate(dataFinishDate.getDate() + 60);

          // bugün çizgisi için soldan ne kadar uzak?
          let todayLeft = this.getWidth(ganttStart, new Date());

          //console.log("todayLeft",todayLeft)

          this.setState(
            {
              ...this.state,
              Loading: false,
              data: filteredData,
              rawData: r.body,
              ganttStart: ganttStart,
              ganttFinish: ganttFinish,
              dataStartDate: dataStartDate,
              dataFinishDate: dataFinishDate,
              todayLeft: todayLeft,
              groupedData: groupedData,
              groupedDataList: groupedDataList,
              periods: periods,
              filterLists: { resources: resourceFiltreList },
            },
            () => {
              this.initializeExpandStatus(view);
            }
          );
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  //Veri geldikten sonraki user viewları düzenler
  initializeExpandStatus = (view) => {
    // console.log("initializeExpandStatus",view,this.state)

    let selectedRecord = this.state.selectedRecord;

    let selectedRecordIndex = this.state.selectedRecordIndex;
    let scroll = this.state.scroll;

    let newData = this.state.groupedData;
    let list = this.state.groupedDataList;
    let expandArray = [];

    if (view?.expandArray) {
      expandArray = view?.expandArray;
    }

    if (view?.collapseStatus !== undefined) {
      let isExpand = view.collapseStatus;
      list.map((lst) => {
        // console.log(lst,expandArray.findIndex(x=>x.code===lst))
        if (expandArray.findIndex((x) => x.code === lst) !== -1) {
          isExpand = !view.collapseStatus;
        } else {
          isExpand = view.collapseStatus;
        }
        //console.log(lst)
        newData[lst].is_expanded = isExpand;
      });
    }

    if (view?.selectedRecord) {
      //console.log("selectedRecord",this.state)
      if (this.state.data.find((x) => x.uid === view?.selectedRecord?.uid)) {
        selectedRecord = this.state.data.find(
          (x) => x.uid === view?.selectedRecord?.uid
        );
        selectedRecordIndex = view?.selectedRecordIndex;

        let createdBy = {
          uid: selectedRecord.created_by,
          fullName: selectedRecord.created_by_name,
        };
        //let timelineIndex={resource:resource, index:index}
        Object.assign(selectedRecord, {
          wbsId: selectedRecord.wbs_id,
          activityId: selectedRecord.activity_id,
          projectId: selectedRecord.project_id,
          createdDate: selectedRecord.created_date,
          createdBy: createdBy,
          /*timelineIndex:timelineIndex*/
        });
      }
    }

    if (view?.scroll && view?.scroll > 0) {
      scroll = view?.scroll;
    }

    /* eğer Gantt linki tıklandıysa o kaydın açılma özelliği */

    this.setState(
      {
        ...this.state,
        //data: newData,
        //rawData: newRawData,
        // expandMode:projectView?.expandMode?projectView?.expandMode:this.state.expandMode,
        //expandArray:projectView?.expandArray?projectView?.expandArray:this.state.expandArray,
        selectedRecord: selectedRecord ? selectedRecord : {},
        selectedRecordIndex: selectedRecordIndex,
        isSideBarOpen: view?.isSideBarOpen
          ? view?.isSideBarOpen
          : this.state.isSideBarOpen,
        mode: view?.mode ? view?.mode : this.state.mode,
        groupedData: newData,
        collapseStatus: view?.collapseStatus
          ? view?.collapseStatus
          : this.state.collapseStatus,
        sidebarWidth: view?.sidebarWidth
          ? view?.sidebarWidth
          : this.state.sidebarWidth,
        expandArray: expandArray,
        scroll: scroll,
      },
      () => {
        this.findToday();
        this.findScrollPositionVertical(scroll);
      }
    );
  };

  //onScroll={this.ganttContainerScrollHandler}

  leftPaneScrollHandler = (e) => {
    //console.log(e.target.scrollTop,e.target.scrollLeft)

    document.getElementById("hub-thub-tline-right-body").scrollTop =
      e.target.scrollTop;
    document.getElementById("hub-thub-tline-left-header").scrollLeft =
      e.target.scrollLeft;

    if (Math.abs(e.target.scrollTop - this.state.scroll) > 100) {
      this.setState({ ...this.state, scroll: e.target.scrollTop }, () => {
        this.updateUserView("scroll", e.target.scrollTop);
      });
    }
  };

  RightPaneScrollHandler = (e) => {
    //console.log("leftPaneScrollHandler",e.target.scrollTop)

    document.getElementById("hub-thub-tline-left").scrollTop =
      e.target.scrollTop;
    document.getElementById("hub-thub-tline-right-header").scrollLeft =
      e.target.scrollLeft;
  };

  findToday = () => {
    //document.getElementById("hub-thub-tline-right-header").scrollLeft = this.state.todayLeft-100;
    document.getElementById("hub-thub-tline-right-body").scrollLeft =
      this.state.todayLeft - 100;
  };

  findScrollPositionVertical = (scroll) => {
    document.getElementById("hub-thub-tline-left").scrollTop = scroll;
    document.getElementById("hub-thub-tline-right-body").scrollTop = scroll;
  };

  findTaskPosition = (leftIndex) => {
    document.getElementById("hub-thub-tline-right-header").scrollLeft =
      leftIndex;
    document.getElementById("hub-thub-tline-right-body").scrollLeft = leftIndex;
  };

  setPage = (page) => {
    this.setState({ ...this.state, page: page });
  };

  setFilter = (v) => {
    this.setState({ ...this.state, filter: v });
  };

  collapseExpandAll = () => {
    let newData = this.state.groupedData;
    let list = this.state.groupedDataList;

    list.map((lst) => {
      newData[lst].is_expanded = !this.state.collapseStatus;
    });
    this.setState(
      {
        ...this.state,
        groupedData: newData,
        collapseStatus: !this.state.collapseStatus,
        expandArray: [],
      },
      () => {
        this.updateUserView("collapseStatus", this.state.collapseStatus);
      }
    );
  };

  handleExpand = (x) => {
    let newGroupedData = { ...this.state.groupedData };

    //console.log("x", x, newGroupedData[x], this.state.collapseStatus);
    newGroupedData[x].is_expanded = !newGroupedData[x].is_expanded;

    let expandArray = this.configureExpandArray(x);

    //console.log("expandArray",expandArray)

    this.setState(
      { ...this.state, groupedData: newGroupedData, expandArray: expandArray },
      () => {
        this.updateUserView("expandArray", expandArray);
      }
    );
  };

  configureExpandArray = (code, data) => {
    let newData = this.state.groupedData[code];
    // * ExpandArray açılacak veya kapanacak kayıtları tutar.
    // Collapse mod ise sadece açıkları tutar
    // Expand mode ise sadece kapalıları tutar.
    // ilk 2 if collapse mode için, son iki if expande mode için

    let expandArray = this.state.expandArray;

    //console.log("newData", newData,this.state.collapseStatus , expandArray)

    if (this.state.collapseStatus && !newData.is_expanded) {
      expandArray.push({ code: code, resource_id: newData.resource_id });
    } else if (
      this.state.collapseStatus &&
      newData.is_expanded &&
      expandArray.findIndex(
        (x) => x.code === code && x.resource_id === newData.resource_id
      ) !== -1
    ) {
      //console.log("else if")
      expandArray.splice(
        expandArray.findIndex(
          (x) => x.code === code && x.resource_id === newData.resource_id
        ),
        1
      );
    }

    // * Expand mode için tutulan kayıtlar
    else if (!this.state.collapseStatus && newData.is_expanded) {
      expandArray.push({ code: code, resource_id: newData.resource_id });
    } else if (
      !this.state.collapseStatus &&
      !newData.is_expanded &&
      expandArray.findIndex(
        (x) => x.code === code && x.resource_id === newData.resource_id
      ) !== -1
    ) {
      //console.log("else if")
      expandArray.splice(
        expandArray.findIndex(
          (x) => x.code === code && x.resource_id === newData.resource_id
        ),
        1
      );
    }

    //  this.updateUserView("expandMode",this.state.expandMode,expandArray)

    //console.log("configureExpandArray",expandArray)

    return expandArray;
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };
  //sidebar update
  updateRecord = (method, endpoint, data, selectedRecord) => {
    //console.log("updateRecord",data,selectedRecord)
    //console.log(JSON.stringify(this.state.formData))

    const requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
      body: JSON.stringify(data),
    };

    fetch(endpoint, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(JSON.stringify(data));
        // console.log(r);
        if (r.status === 200) {
          //  console.log(r);
          // this.removeItem();

          //this.setState({...this.state, Status: "Update" ,ErrorMessage:"Updated Succesfully"});

          let selectedRecord2 = selectedRecord;

          selectedRecord2.name = data.name;
          selectedRecord2.start_date = data.startDate;
          selectedRecord2.finish_date = data.finishDate;
          let newGroupedData = this.state.groupedData;

          //let task=r.body.object;
          //console.log(data.columnIndex,data.cardIndex);
          newGroupedData[selectedRecord.timelineIndex.resource].tasks[
            selectedRecord.timelineIndex.index
          ] = selectedRecord;

          this.setState({ ...this.state, groupedData: newGroupedData });
          /* */
          this.notify("success", "Updated");
        } else if (r.status === 201) {
          let newColumns = this.state.columns;

          let task = r.body.object;

          Object.assign(task, { toDo: [] });

          let taskResource = {
            taskId: task.uid,
            resourceList: null,
            assignmentList: [],
          };

          newColumns[0].task.unshift(r.body.object);
          newColumns[0].taskResource.push(taskResource);
          //console.log("taskId",task.uid)
          //  console.log("newcolumns",newColumns)

          this.setState({ ...this.state, columns: newColumns });

          this.notify("success", "Created");
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else if (r.status == 400) {
          this.setState({
            ...this.state,
            Status: "Error",
            Error: true,
            ErrorMessage: r.body.message,
          });

          this.notify("error", r.body.message);
        } else {
          console.log(r.body.message);
          this.setState({
            ...this.state,
            Status: "Error",
            ErrorMessage: r.body.message,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  };

  render() {
    //console.log("timeline",this.state)

    let innerWidth = this.state.periods.length * 150;
    let pagedData = this.state.groupedDataList
      ? this.state.groupedDataList.slice(
          this.state.page * this.state.perPage,
          (this.state.page + 1) * this.state.perPage
        )
      : "";
    let dutils = new DateUtils();

    if (this.state.filter?.value) {
      pagedData = this.state.groupedDataList.filter(
        (w) => w === this.state.filter.value
      );
    }

    let rowCount = 0;
    if (this.state.mode === "hierarchy") {
      pagedData.map((x) => {
        rowCount =
          rowCount +
          (this.state.groupedData[x].is_expanded
            ? this.state.groupedData[x].tasks.length + 1
            : 1);
      });
      // console.log("rowCount",rowCount)
    } else {
      pagedData.map((x) => {
        rowCount = rowCount + this.state.groupedData[x].rows.length;
      });
    }

    //console.log("pagedData",this.state.groupedData)

    const modes = [
      { label: "Timeline", value: "timeline" },
      { label: "Hierarchy", value: "hierarchy" },
    ];

    const handleModeChange = (selectedMode) => {
      this.setMode(selectedMode);
    };

    const handleResizeLeft = (w) => {
      let newWidth = this.state.width + w;
      this.setState({ ...this.state, width: newWidth });
    };

    const setColumns = (newColumns) =>
      this.setState({ ...this.state, columns: newColumns });

    const handleResizeColumn = (columnId, newWidth) => {
      // Clone the columns array
      const clonedColumns = [...this.state.columns];

      // Find the index of the column to update
      const columnIndex = clonedColumns.findIndex(
        (column) => column.id === columnId
      );

      // If the column is found, create a new column object with the updated width
      if (columnIndex !== -1) {
        const updatedColumn = {
          ...clonedColumns[columnIndex],
          width: newWidth,
        };

        // Replace the old column with the new updated column
        clonedColumns[columnIndex] = updatedColumn;

        // Update the state with the new columns array
        setColumns([...clonedColumns]);
      }
    };

    let rowLength = this.state.groupedDataList
      ? this.state.groupedDataList?.length
      : 0;

    const startEntry = this.state.page * perPage + 1;
    const endEntry = Math.min((this.state.page + 1) * perPage, rowLength);

    const isPrevButtonDisabled = this.state.page === 0;
    const isNextButtonDisabled =
      this.state.page === Math.floor((rowLength - 1) / perPage);

    const totalPages = Math.ceil(rowLength / perPage);

    const handleClickNext = () => {
      this.setState({
        ...this.state,
        page: isNextButtonDisabled ? this.state.page : this.state.page + 1,
      });
    };

    const handleClickPrev = () => {
      this.setState({
        ...this.state,
        page: isPrevButtonDisabled ? this.state.page : this.state.page - 1,
      });
    };

    const handleMouseOver = (resource, index) => {
      this.setState({
        ...this.state,
        hoveredRecord: { resource: resource, index: index },
      });
    };

    const handleMouseLeave = () => {
      this.setState({
        ...this.state,
        hoveredRecord: null,
      });
    };

    /*     const arrangeMinWidth = (column, isExpandable = false) => {
      const { expandable, type } = column;
      let minWidth;
      if (expandable & isExpandable) {
        minWidth = 180;
      } else if (type === "tag") {
        minWidth = 120;
      } else {
        minWidth = 80;
      }

      return minWidth;
    };

    // Arrange first column width for expand and hierarchy spaces
    const arrangeWidth = (column, row, isExpandable = false) => {
      const { width, expandable } = column;
      let tdWidth =
        expandable && isExpandable ? width - row?.hierLevel * 30 : width;
      return tdWidth;
    }; */

    return !this.state.Loading ? (
      <div className="hub-thub-tline">
        <Toolbar>
          <div className="tline-left-panel">
            <PickerButton
              label="List Type"
              selected={{
                id: 1,
                name: "Resource",
              }}
              /*  toggleSelected={this.setBoardType} */
              style={{ marginRight: "10px" }}
              options={[
                {
                  id: 1,
                  name: "Resouce",
                },
                {
                  id: 2,
                  name: "Activity",
                },
              ]}
              tooltipStyle={{
                width: "153px",
                transform: "translate(0px,16px)",
                left: "86px",
              }}
              arrowLocation={{ marginLeft: "120px" }}
            />
            <div style={{ width: "250px" }}>
              <SearchSelect
                value={this.state.filter}
                onChange={this.setFilter}
                placeholder={"Search Resource"}
                defaultOptions={this.state.filterLists.resources}
                options={this.state.filterLists.resources}
                withAvatar
              />
            </div>

            <div className="left-panel-buttons">
              {this.state.mode === "hierarchy" ? (
                <MainButton
                  className="secondary black"
                  icon={
                    this.state.collapseStatus ? <HiCollapse /> : <HiExpand />
                  }
                  style={{ height: 36, padding: "0 5px 0 10px" }}
                  iconSize={16}
                  label={this.state.collapseStatus ? "Collapse" : "Expand"}
                  onClick={this.collapseExpandAll}
                />
              ) : (
                false
              )}
            </div>
          </div>
          <div className="tline-right-panel">
            {/*<div className="date-info"><b>Data Range:</b> {dutils.Date2StrNew(this.state.dataStartDate)} - {dutils.Date2StrNew(this.state.dataFinishDate)}</div>*/}
            <MainButton
              className="secondary black"
              label="Scroll to Today"
              onClick={this.findToday}
              style={{ height: "36px", padding: "0 5px 0 10px" }}
              icon={<HiFlipForward />}
            />
            <ToggleButtonGroup
              options={modes}
              initialSelection="hierarchy"
              onChange={handleModeChange}
            />
            <MainButton
              className="secondary black"
              style={{ width: 36, height: 36 }}
              icon={<HiDownload />}
              onClick={this.exportToCSV}
              tooltip={"Export to CSV"}
              tooltipLocation="bottom-left"
            />

            {/* <MainButton className={this.state.isSideBarOpen?"icon-button-2 active":"icon-button-2"} onClick={this.toggleSidebar} icon={faSidebarFlip}/>*/}
          </div>
        </Toolbar>
        <div className="hub-thub-tline-body-wrapper">
          <div
            className="hub-thub-tline-wrapper"
            style={{
              width: "max-content",
              overflow: "auto",
            }}
          >
            <div
              className="hub-thub-tline-header"
              style={{
                width: this.state.mode === "timeline" ? "100%" : "max-content",
              }}
            >
              <div
                className="hub-thub-tline-left"
                id="hub-thub-tline-left-header"
                style={{
                  overflow: "hidden",
                  width: "100%",
                }}
              >
                {this.state.mode === "timeline" ? (
                  <TheadBasic style={{ width: "100%" }}>
                    <THBasic
                      style={{
                        width: "100%",
                        boxShadow: "0px -1px 0px 0px #d2def2 inset",
                      }}
                    >
                      Resource
                    </THBasic>
                  </TheadBasic>
                ) : (
                  <Thead>
                    {this.state.columns.map((column) => (
                      <TH
                        column={column}
                        onResizeColumn={handleResizeColumn}
                        /*   minWidth={() => arrangeMinWidth(column)} */
                      >
                        {column?.label}
                      </TH>
                    ))}
                    <div className="cb"></div>
                  </Thead>
                )}
              </div>
            </div>
            <div
              className="hub-thub-tline-body"
              style={{
                width: this.state.mode === "timeline" ? "100%" : "max-content",
              }}
            >
              <div
                className="hub-thub-tline-left"
                id="hub-thub-tline-left"
                onScroll={this.leftPaneScrollHandler}
              >
                {pagedData.map((y, k) => {
                  let t = this.state.groupedData[y];
                  if (this.state.mode === "timeline") {
                    return (
                      <div
                        className="hub-thub-tline-left-row"
                        style={{
                          height: t.rows.length * 44,
                          lineHeight: (t.rows.length * 44).toString() + "px",
                          borderBottom: "1px solid rgba(14, 63, 145, 0.10)",
                          width: "100%",
                        }}
                      >
                        <div
                          className="tline-resource-name"
                          style={{ paddingLeft: 0 }}
                        >
                          <Avatar
                            firstName={t?.firstName}
                            lastName={t?.lastName}
                            uid={t?.uid}
                            color={t?.color}
                            style={{
                              width: 24,
                              height: 24,
                            }}
                            size="sm"
                            letterCount={1}
                            hasAvatar={t?.hasAvatar}
                          />
                          {t.resource_name}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <TR
                          onClick={() => this.handleExpand(y)}
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            borderBottom: "1px solid rgba(14, 63, 145, 0.1)",
                            width: "100%",
                          }}
                        >
                          <span className="tline-resource-expand">
                            {t.is_expanded ? (
                              <HubIcon fontSize={18} lineColor="#1769F1">
                                <HiArrowShortDown />
                              </HubIcon>
                            ) : (
                              <HubIcon fontSize={18} lineColor="#1769F1">
                                <HiArrowShortRight />
                              </HubIcon>
                            )}
                          </span>
                          <div className="tline-resource-name">
                            <Avatar
                              firstName={t?.firstName}
                              lastName={t?.lastName}
                              uid={t?.uid}
                              color={t?.color}
                              style={{
                                width: 24,
                                height: 24,
                              }}
                              letterCount={1}
                              hasAvatar={t?.hasAvatar}
                            />
                            {t.resource_name}
                          </div>
                        </TR>

                        {t.is_expanded
                          ? t.tasks.map((x, i) => {
                              let row = {
                                name: x.name,
                                startDate: x.start_date,
                                finishDate: x.finish_date,
                                percentComplete: Math.round(
                                  x.percent_complete * 100
                                ),
                                colorCode: x?.color_code,
                                hasAvatar: x?.hasAvatar,
                              };

                              return (
                                <TR
                                  isSelected={
                                    this.state.selectedRecordIndex[0] === y &&
                                    this.state.selectedRecordIndex[1] === i
                                  }
                                  onClick={() =>
                                    this.selectedRecord(x, y, i, k)
                                  }
                                  onDoubleClick={() => this.toggleSidebar()}
                                  onMouseEnter={() => handleMouseOver(y, i)}
                                  isHovered={
                                    this.state.hoveredRecord?.resource === y &&
                                    this.state.hoveredRecord?.index === i
                                  }
                                  onMouseLeave={handleMouseLeave}
                                  style={{ cursor: "pointer" }}
                                >
                                  {this.state.columns.map((column, index) => {
                                    return (
                                      <TD
                                        style={{
                                          padding:
                                            index === 0
                                              ? "0 10px 0 45px"
                                              : "0 10px",
                                        }}
                                        column={column}
                                        row={row}
                                        width={column?.width}
                                      />
                                    );
                                  })}
                                  <div className="cb"></div>
                                </TR>
                              );
                            })
                          : false}
                      </>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <Resizable
            enable={{
              top: false,
              right: false,
              bottom: false,
              left: true,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            minWidth={200}
            maxWidth={"100%"}
            defaultSize={{ width: 750 }}
            size={{ width: this.state.width }}
            onResizeStop={(e, direction, ref, d) => {
              handleResizeLeft(d.width);
            }}
            style={{ overflow: "auto" }}
            handleClasses={{ left: "tline-resizer" }}
          >
            <div
              className="hub-thub-tline-wrapper"
              style={{
                display: "flex",
                overflow: "auto",
                flex: 1,
                height: "100%",
              }}
            >
              <div className="hub-thub-tline-header">
                <div
                  className="hub-thub-tline-right"
                  id="hub-thub-tline-right-header"
                  style={{ overflow: "hidden" }}
                >
                  <TheadBasic
                    style={{ width: innerWidth + 15, position: "relative" }}
                  >
                    {this.state.periods.map((column) => (
                      <THBasic
                        key={column.id}
                        style={{ width: 150, justifyContent: "center" }}
                      >
                        {column.str}
                      </THBasic>
                    ))}
                  </TheadBasic>
                </div>
              </div>
              <div className="hub-thub-tline-body">
                <div
                  className="hub-thub-tline-right"
                  style={{ position: "relative" }}
                  id="hub-thub-tline-right-body"
                  onScroll={this.RightPaneScrollHandler}
                >
                  <div
                    className="hub-thub-tline-marker-line"
                    style={{
                      left: this.state.todayLeft,
                      height: rowCount * 44,
                    }}
                  >
                    <HiTimelineCursor />
                  </div>
                  {pagedData.map((t, k) => {
                    if (this.state.mode === "timeline") {
                      return this.state.groupedData[t].rows.map((r) => {
                        return (
                          <TRBar
                            style={{ width: innerWidth, cursor: "pointer" }}
                          >
                            {r?.tasks.map((i) => {
                              return (
                                <Item
                                  mode={this.state.mode}
                                  item={i}
                                  boardType={this.props.boardType}
                                />
                              );
                            })}
                          </TRBar>
                        );
                      });
                    } else {
                      return (
                        <>
                          <TRBar style={{ width: innerWidth }} />
                          {this.state.groupedData[t].is_expanded
                            ? this.state.groupedData[t].tasks.map((r, i) => {
                                let newArray = [];
                                newArray.push(r);
                                return (
                                  <TRBar
                                    isSelected={
                                      this.state.selectedRecordIndex[0] === t &&
                                      this.state.selectedRecordIndex[1] === i
                                    }
                                    style={{
                                      width: innerWidth,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.selectedRecord(r, t, i, k)
                                    }
                                    onMouseEnter={() => handleMouseOver(t, i)}
                                    isHovered={
                                      this.state.hoveredRecord?.resource ===
                                        t &&
                                      this.state.hoveredRecord?.index === i
                                    }
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    {newArray.map((i) => {
                                      return (
                                        <Item
                                          mode={this.state.mode}
                                          item={i}
                                          boardType={this.props.boardType}
                                        />
                                      );
                                    })}
                                  </TRBar>
                                );
                              })
                            : false}
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </Resizable>

          {this.state.isSideBarOpen ? (
            <SideBar
              parentRecord={this.state.selectedRecord}
              selectedRecord={this.state.selectedRecord}
              idAttribute={"uid"}
              nameAttribute={"name"}
              toggleSidebar={this.toggleSidebar}
              isOpen={this.state.isSideBarOpen}
              setSidebarWidth={this.setSidebarWidth}
              sidebarWidth={this.state.sidebarWidth}
            >
              {/* */}
              <TaskBoardSidebarContainer
                selectedRecord={this.state.selectedRecord}
                save={this.updateRecord}
                isNewTask={this.state.isNewTask}
              />
            </SideBar>
          ) : (
            false
          )}
        </div>
        <div className="hub-thub-tline-footer">
          {!this.state.filter?.value ? (
            <Pagination
              totalRows={rowLength}
              perPage={perPage}
              page={this.state.page}
              startEntry={startEntry}
              endEntry={endEntry}
              totalPages={totalPages}
              onClickNext={handleClickNext}
              onClickPrev={handleClickPrev}
              isNextButtonDisabled={isNextButtonDisabled}
              isPrevButtonDisabled={isPrevButtonDisabled}
            />
          ) : (
            <Pagination
              totalRows={1}
              perPage={1}
              page={0}
              startEntry={1}
              endEntry={1}
              totalPages={1}
              isNextButtonDisabled
              isPrevButtonDisabled
            />
          )}
        </div>
      </div>
    ) : (
      <LoadingScreen />
    );
  }
}

export default Timeline;

import React from "react";
import NoteComp from "../noteComp/noteComp";
import HubIcon from "../../hubIcon/HubIcon";
import HiInfoCircle from "../../icons/HiInfoCircle";

const NoteList = React.forwardRef(
  (
    {
      changeState,
      selectedComment,
      dataSet,
      retrieveConversation,
      deleteNote,
      hasScroll,
    },
    ref
  ) => {
    const retrieveList = () => {
      changeState();
    };

    return !!dataSet?.length ? (
      <div className="note-list" ref={ref}>
        {dataSet
          .sort((a, b) => (a.createdDate < b.createdDate ? 1 : -1))
          .map((d, i) => {
            return (
              <NoteComp
                retrieveConversation={retrieveConversation}
                dataSet={d}
                changeState={retrieveList}
                selectedComment={selectedComment}
                deleteNote={deleteNote}
                hasScroll={hasScroll}
              />
            );
          })}
      </div>
    ) : (
      <div className="no-comment">
        <HubIcon lineColor="#779DD9">
          <HiInfoCircle />
        </HubIcon>
        <p> There is no content yet.</p>
      </div>
    );
  }
);

export default NoteList;

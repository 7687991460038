import React, { Component } from "react";
import MainButton from "./../../../../common/buttons/MainButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faPenToSquare,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import DynamicLookup from "./../../../../common/inputControls/lookup/DynamicLookup";
import TextArea from "./../../../../common/inputControls/textArea2/textArea";
import TooltipWithPage from "./../../../../common/tooltipWithPage/tooltipWithPage";
import BlockCreate from "./../../sideBar/blocked/blockCreate";
import BlockResolve from "./../../sideBar/blocked/blockResolve";

import CardDetailSection from "./../comps/CardDetailSection";
import { DateUtils, Utils } from "../../../../common/Commons";

import "./cardBlock.css";
import { faC } from "@fortawesome/pro-thin-svg-icons";

export default class cardBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlockResolveTooltip: false,
      showBlockTooltip: false,
      mode: "", // new, blocked, resolved
      blockResolveDescription: "",
      blockDescription: "",
      blockOwner: "",
      blockReason: "",
    };
  }

  componentDidMount() {
    //console.log("componentDidMount",this.props.task.blockReason)
    let blockDescription = this.props.task.blockDescription;
    let blockReason = this.props.task.blockReason;
    let blockOwner = this.props.task.blockOwner;

    this.setState({
      ...this.state,
      mode:
        this.props.task.isBlocked === false &&
        this.props.task.blockResolveDescription === null
          ? "new"
          : this.props.task.blockResolveDescription === null
          ? "blocked"
          : "resolved",
      blockDescription: blockDescription,
      blockReason: blockReason,
      blockOwner: blockOwner,
    });
  }

  toggleBlockTooltip = () => {
    this.setState({
      ...this.state,
      showBlockTooltip: !this.state.showBlockTooltip,
    });
  };

  toggleBlockResolveTooltip = () => {
    this.setState({
      ...this.state,
      showBlockResolveTooltip: !this.state.showBlockResolveTooltip,
    });
  };

  // form ID işlevsiz gibi ancak aslında tek bir alan üzerinden formId ile ilerlesi planlanan bir form olması sebebiyle böyle görünüyor. Alt bölümün değişmesi lazım;
  onChangeBlockDescription = (formId, value) => {
    this.setState({ ...this.state, blockDescription: value });
  };
  onChangeBlockReason = (formId, value) => {
    this.setState({ ...this.state, blockReason: value });
  };
  onChangeBlockOwner = (formId, value) => {
    this.setState({ ...this.state, blockOwner: value });
  };
  onChangeBlockResolveDescription = (formId, value) => {
    this.setState({ ...this.state, blockResolveDescription: value });
  };

  saveBlock = (blockType) => {
    // console.log("saveBlock",blockType,this.state,this.props.columnIndex,this.props.task.cardIndex)
    const u = new Utils();
    if (
      blockType === 1 &&
      (this.state.blockDescription === "" ||
        this.state.blockDescription === null ||
        this.state.blockOwner === "" ||
        this.state.blockOwner === null ||
        this.state.blockReason === "" ||
        this.state.blockReason === null)
    ) {
      u.addNotification("error", "All areas must be entered.");
      return;
    }

    if (
      blockType === 2 &&
      (this.state.blockResolveDescription === "" ||
        this.state.blockResolveDescription === null)
    ) {
      u.addNotification("error", "Block Resolve Description must be entered.");
      return;
    } else {
      let blockObject = {};

      if (blockType === 1) {
        //blockOwner.id=this.state.blockOwner.id
        blockObject.blockDescription = this.state.blockDescription;
        blockObject.blockReason = this.state.blockReason;
        blockObject.blockOwner = this.state.blockOwner;
        blockObject.isBlocked = true;
        blockObject.blockResolveDescription = null;
      }

      if (blockType === 2) {
        //blockOwner.id=this.state.blockOwner.id
        blockObject.blockDescription = this.props.task.blockDescription;
        blockObject.blockReason = this.props.task.blockReason;
        blockObject.blockOwner = this.props.task.blockOwner;

        blockObject.blockResolveDescription =
          this.state.blockResolveDescription;
        blockObject.isBlocked = false;
      }

      console.log(
        this.props.task.uid,
        blockObject,
        this.props.columnIndex,
        this.props.task.cardIndex,
        this.props.task.stage.code
      );

      this.props.blockTask(
        this.props.task.uid,
        blockObject,
        this.props.columnIndex,
        this.props.task.cardIndex,
        this.props.task.stage.code
      );
      this.setState({
        ...this.state,
        mode: blockType === 2 ? "resolved" : "blocked",
      });
    }
  };

  //  new, blocked, resolved, resolve
  chageMode = (mode) => {
    this.setState({ ...this.state, mode: mode });
  };

  cancel = () => {
    // console.log("cancel",this.state)
    this.setState({
      ...this.state,
      mode:
        this.props.task.isBlocked === false &&
        this.props.task.blockResolveDescription === null
          ? "new"
          : this.props.task.blockResolveDescription === null
          ? "blocked"
          : "resolved",
    });

    if (this.state.mode === "new" && this.state.blockDescription === null) {
      this.props.closeBlock();
    }
  };

  render() {
    //console.log("block",this.props.task.name, this.props.task)
    let util = new DateUtils();
    let t = this.props.task;
    if (this.props.activeTab === "block" && this.state.mode !== "") {
      return (
        <CardDetailSection
          markerLeft={94 + this.props.markerLeft}
          key="cardBlockSection"
          show={this.props.show}
        >
          <div className="card-section-header">
            <div className="title">
              <span>
                <FontAwesomeIcon icon={faBan} />
              </span>
              <span>Block List</span>
            </div>
            <div className="block-button-bar">
              {this.state.mode === "new" ? (
                <>
                  <MainButton
                    style={{ marginRight: 0 }}
                    onClick={() => this.saveBlock(1)}
                    label="Save"
                    className="new-plain-button"
                  />
                  <MainButton
                    style={{ marginRight: 0 }}
                    label="Cancel"
                    onClick={() => this.cancel()}
                    className="new-plain-button"
                  />
                </>
              ) : (
                false
              )}
              {this.state.mode === "resolved" ? (
                <MainButton
                  style={{ marginRight: 0 }}
                  label="Add New"
                  onClick={() => this.chageMode("new")}
                  className="new-plain-button"
                />
              ) : (
                false
              )}
              {this.state.mode === "renew" || this.state.mode === "resolve" ? (
                <>
                  <MainButton
                    style={{ marginRight: 0 }}
                    onClick={() =>
                      this.saveBlock(this.state.mode === "resolve" ? 2 : 1)
                    }
                    label="Save"
                    className="new-plain-button"
                  />
                  <MainButton
                    style={{ marginRight: 0 }}
                    label="Cancel"
                    onClick={() => this.cancel()}
                    className="new-plain-button"
                  />
                </>
              ) : (
                false
              )}
              {this.state.mode === "blocked" ? (
                <MainButton
                  style={{ marginRight: 0 }}
                  onClick={() => this.chageMode("new")}
                  label="Edit"
                  className="new-plain-button"
                />
              ) : (
                false
              )}
              {this.state.mode === "blocked" ? (
                <MainButton
                  style={{ marginRight: 0 }}
                  label="Resolve"
                  onClick={() => this.chageMode("resolve")}
                  className="new-plain-button"
                />
              ) : (
                false
              )}
            </div>
          </div>
          {this.state.mode === "new" ? (
            <div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 10,
                  }}
                >
                  <DynamicLookup
                    inputWrapperStyle={{
                      border: "1px solid #D2DEF2",
                      borderRadius: "6px",
                    }}
                    type="card"
                    required={true}
                    endpoint="/api/valuelistoptions/parent/29"
                    value={{
                      value: this.state.blockReason?.uid,
                      label: this.state.blockReason?.name,
                    }}
                    onChange={this.onChangeBlockReason}
                    formId="uid"
                    searchAttribute="name"
                    valueAttribute="uid"
                    labelAttribute="name"
                    width="50%"
                    label="Block Reason"
                  />
                  <DynamicLookup
                    inputWrapperStyle={{
                      border: "1px solid #D2DEF2",
                      borderRadius: "6px",
                    }}
                    type="card"
                    required={true}
                    endpoint="/api/resource/blockowner"
                    value={{
                      value: this.state.blockOwner?.uid,
                      label: this.state.blockOwner?.fullName,
                    }}
                    onChange={this.onChangeBlockOwner}
                    formId="blockOwner"
                    searchAttribute="fullName"
                    valueAttribute="uid"
                    labelAttribute="fullName"
                    width="50%"
                    label="Block Owner"
                  />
                </div>
                <div style={{ height: "100px", marginTop: 10 }}>
                  <TextArea
                    inputStyle={{ fontSize: "12px" }}
                    inputWrapperStyle={{
                      border: "1px solid #D2DEF2",
                      borderRadius: "6px",
                    }}
                    required={true}
                    width="98%"
                    rows="2"
                    label=" Description"
                    defaultValue={this.state.blockDescription}
                    value={this.state.blockDescription}
                    onChange={this.onChangeBlockDescription}
                    changeArea="blockDescription"
                  />
                </div>
              </div>
            </div>
          ) : (
            false
          )}
          {this.state.mode === "blocked" ||
          this.state.mode === "resolved" ||
          this.state.mode === "resolve" ? (
            <div
              className={
                "block" + (this.state.mode === "resolved" ? " resolved" : "")
              }
            >
              <div className="block-header">
                <div
                  className={
                    "block-reason" +
                    (this.state.mode === "resolved" ? " resolved" : "")
                  }
                >
                  {t.blockReason?.name}
                </div>
                <div className="block-date">
                  {" "}
                  {util.Date2StrMonthDayHour(t.blockCreatedDate)}
                </div>
              </div>

              {t.blockOwner ? (
                <div
                  title="Block Owner and Created Date"
                  className="block-owner"
                >
                  {t.blockOwner?.fullName}
                </div>
              ) : (
                <div className="block-footer" />
              )}

              <div className="block-description">{t.blockDescription}</div>
            </div>
          ) : (
            false
          )}

          {this.state.mode === "resolved" ? (
            <>
              <div className="block-line"></div>
              <div className="resolve">
                <div className="block-header">
                  <div className="block-resolve-reason">Resolved</div>
                  <div className="block-date">
                    {" "}
                    {util.Date2StrHour(t.blockResolveDate)}
                  </div>
                </div>

                {t.blockResolveBy ? (
                  <div
                    title="Resolved By and Resolve Date"
                    className="block-owner"
                  >
                    {t.blockResolveBy?.fullName}
                  </div>
                ) : (
                  <div className="block-footer" />
                )}
                <div className="block-description">
                  {t.blockResolveDescription}
                </div>
              </div>
            </>
          ) : (
            false
          )}

          {this.state.mode === "resolve" ? (
            <div className="resolvesss">
              <div style={{ height: "100px" }}>
                <TextArea
                  required={true}
                  width="98%"
                  rows="2"
                  label="Block Resolve Description"
                  defaultValue={this.state.blockResolveDescription}
                  value={this.state.blockResolveDescription}
                  onChange={this.onChangeBlockResolveDescription}
                  changeArea="blockResolveDescription"
                />
              </div>
            </div>
          ) : (
            false
          )}

          {/* aşağıdaki kısım kaldırılacak!!! */}
          {/*t.isBlocked ?
              <div className='block-button-area' style={{display:"flex",justifyContent:"flex-end",paddingBottom:"10px"}}>
                <div className='items' style={{position:"relative"}}> 
                  <MainButton icon={faPenToSquare }style={{height:"25px", lineHeight:"23px"}} onClick={this.toggleBlockTooltip} className="button-2" label="Edit" />
                  {this.state.showBlockTooltip?
                        <TooltipWithPage title={"Block Task"} closePanel={this.toggleBlockTooltip} style={{width:"400px"}}>
                            <BlockCreate task={t} blockTask={this.props.blockTask} closePanel={this.toggleBlockTooltip}/>
                        </TooltipWithPage>
                  :false}   
                </div>
                <div className='items' style={{position:"relative"}}> 
                  <MainButton icon={faCircleCheck} style={{height:"25px", lineHeight:"23px"}} onClick={ this.toggleBlockResolveTooltip} className="button-1" label="Resolve" />
                  {this.state.showBlockResolveTooltip?
                        <TooltipWithPage title={"Resolve Block"} closePanel={this.toggleBlockResolveTooltip} style={{width:"400px"}}>
                            <BlockResolve task={t} blockTask={this.props.blockTask} closePanel={this.toggleBlockResolveTooltip}/>
                        </TooltipWithPage>
                  :false}   
                </div>
              </div>
                  :false*/}
        </CardDetailSection>
      );
    } else {
      return false;
    }
  }
}

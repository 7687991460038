import React, { Component } from "react";
import ActionButtons from "./ActionButtons";
import ReportPeriodPicker from "./ReportPeriodPicker";
import "./TimesheetDetails.css";
import TimesheetProgressBar from "./TimesheetProgressBar";
import MainButton from "./../../../common/buttons/MainButton";
import {
  faAngleLeft,
  faAngleDown,
  faCheck,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPaperPlane,
  faFloppyDisk,
} from "@fortawesome/free-regular-svg-icons";

import TimesheetTable from "./TimesheetTable";
import sampleData from "./sampleData";
import AddTaskModalWrapper from "./AddTaskModal";

import Auth from "./../../../auth/Auth";
import { Utils } from "./../../../common/Commons";

import SideBar from "./../../../common/dataTable/SideBar";
import "../../../common/spinner.css";
import ColumnPicker from "./../../../common/dataTable/ColumnPicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEmptySet, faSpinner } from "@fortawesome/pro-duotone-svg-icons";
class TimesheetDetail extends Component {
  constructor(props) {
    super(props);
    const columns = [
      { id: "wbs_name", label: "WBS Name", dataKey: "wbs" },
      { id: "activity_name", label: "Activity Name", dataKey: "Activity" },
      //,{ id: "remaining", label:"Remaining", dataKey:"remaining"}
      {
        id: "planning",
        label: "Planned Units",
        dataKey: "assignmentInfo.plannedUnit",
      },
      {
        id: "assignment_start",
        label: "Assignment Start",
        dataKey: "assignmentInfo.startDate",
      },
      {
        id: "assignment_finish",
        label: "Assignment Finish",
        dataKey: "assignmentInfo.finishDate",
      },
    ];
    this.state = {
      Loading: true,
      LoadingText: "Loading..",
      isEditable: true,
      showWFButtons: 0, // 0 gösterme 1 submit, 2 approve ve reject 3 sadece reject
      isSideBarOpen: false,
      periodStart: "",
      periodFinish: "",
      columns: columns,
      visibleColumnList: ["planning"],
      visibleColumns: [
        {
          id: "planning",
          label: "Planned Units",
          dataKey: "assignmentInfo.plannedUnit",
        },
      ],
    };
  }

  //initialPeriodOrder={this.state.initialPeriodOrder} selectedPeriod={this.state.selectedPeriod} reportingPeriods={this.state.reportingPeriods} timesheet={this.state.timesheet}
  applyVisibleColumns = (cl) => {
    // Başlangıç değerleri constructor'da oluşuyor. Değişiklik yaparken ilgili bölüme dikkat etmek gerekiyor.
    // cl = Seçilen kolon id'lerinin dizisi

    let oldVisibileColumnList = [];
    oldVisibileColumnList = [...this.state.visibleColumnList];
    let oldVisibileColumns = [];
    oldVisibileColumns = [...this.state.visibleColumns];

    let newState = { ...this.state };
    newState.visibleColumns = [];

    newState.columns.map((c) => {
      if (cl.indexOf(c.id) > -1) {
        newState.visibleColumns.push({
          ...c,
          // Aşağıdaki karmaşanın sebebi eğer kayıt daha önce seçili ise ve bir değişiklik yapılmışsa, (width vs. kaybetmemek)
          width:
            oldVisibileColumnList.indexOf(c.id) > -1
              ? oldVisibileColumns[oldVisibileColumnList.indexOf(c.id)].width
              : c.type == "text" || c.type == "day" || c.type == "hour"
              ? 240
              : 150,
        });
      }
    });
    newState.visibleColumnList = cl;
    this.setState(newState);
  };

  notify = (type, message) => {
    const u = new Utils();
    u.addNotification(type, message);
  };

  toggleSidebar = () => {
    //console.log(this.props)

    this.setState({ ...this.state, isSideBarOpen: !this.state.isSideBarOpen });
  };

  componentDidMount() {
    let tasks = [];
    let utils = new Utils();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    };

    fetch(
      "/api/reportingperiod/resource/" + utils.findGetParameter("resourceId"),
      requestOptions
    )
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status == 200) {
          // console.log(r)
          let periods = r.body;

          let selectedPeriod = parseInt(utils.findGetParameter("timeperiodId"));
          let initialPeriodOrder = 0;
          let selectedPeriodObj = {};

          periods.map((p, i) => {
            if (utils.findGetParameter("timeperiodId") == p.id) {
              selectedPeriodObj = p;
              selectedPeriod = i;
              initialPeriodOrder = i < 2 ? 0 : i - 1;
            }
          });

          this.setState(
            {
              ...sampleData,
              timeperiodId: parseInt(utils.findGetParameter("timeperiodId")),
              resourceId: parseInt(utils.findGetParameter("resourceId")),
              showTasks: false,
              initialPeriodOrder: initialPeriodOrder,
              selectedPeriod: selectedPeriod,
              reportingPeriods: periods,
              timesheet: {},
            },
            () => {
              this.loadTimesheet();
            }
          );
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "You are not authorized to see this page. Please contact your system administrator.",
          });
        } else {
          this.setState({
            ...this.state,
            LoadingError: true,
            LoadingErrorText:
              "An unexpected error occured. Please contact your system administrator.",
          });
        }
      });
  }

  selectPeriod = (uid, initial) => {
    // Seçilen period'un uidsi

    console.log("selectPeriod");

    let selectedPeriod = this.state.selectPeriod;

    this.state.reportingPeriods.map((p, i) => {
      if (uid == p.id) {
        selectedPeriod = i;
      }
    });

    this.setState(
      {
        ...this.state,
        Loading: true,
        selectedPeriod: selectedPeriod,
        initialPeriodOrder: initial,
        timeperiodId: uid,
      },
      () => {
        this.loadTimesheet();
      }
    );
  };

  loadTimesheet = () => {
    let selectedPeriodObj =
      this.state.reportingPeriods[this.state.selectedPeriod];

    // console.log("selectedPeriodObj",this.state.selectedPeriod, this.state.reportingPeriods )
    if (selectedPeriodObj?.timesheetId === -1) {
      var isEdit = false;
      if (
        parseInt(Auth.checkResourceId()) === parseInt(this.state.resourceId)
      ) {
        // console.log("isEdit")
        isEdit = true;
      }
      this.setState({
        ...this.state,
        Loading: false,
        timesheet: {},
        isEditable: isEdit,
      });
    } else {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.check(),
        },
      };

      let utils = new Utils();

      // Timesheet varsa detayları alınıyor.
      fetch(
        "/api/timesheetdetails/timesheet/resource/" +
          this.state.resourceId +
          "/timeperiod/" +
          this.state.timeperiodId,
        requestOptions
      )
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((r) => {
          if (r.status === 200) {
            let newTimesheet = { ...r.body.object };
            let totals = [];

            // console.log("newTimesheet",newTimesheet)

            // totals dizisi reportperiod dizisi üzerinden oluşturuluyor.
            for (
              var d = new Date(selectedPeriodObj.startDate);
              d <= new Date(selectedPeriodObj.finishDate);
              d.setDate(d.getDate() + 1)
            ) {
              totals.push(0.0);
            }

            let responsibles = []; //Program Manager'lar buttonlar için

            //reportLine'lar totals'a ekleniyor.
            newTimesheet.timeReportLines.map((l) => {
              if (l.responsible) {
                if (l.responsible.uid) {
                  responsibles.push(l.responsible.uid);
                }
              }

              l.worklogs.map((w, i) => {
                totals[i] = totals[i] + w.actual;
              });
            });

            newTimesheet.totals = totals;

            let isEditable = true;
            // kişi kendi veya müdürü olduğu
            // kişilerin timesheetlerini düzenleyebilir.
            const loggedInResourceId = Auth.checkResourceId();

            /*
                    console.log("loggedInResourceId",loggedInResourceId)
                    console.log("newTimesheet.resource.id",newTimesheet.resource.id)
                    console.log(loggedInResourceId != newTimesheet.resource.id)
                    console.log("newTimesheet.status.code",newTimesheet.status.code)
                    console.log("newTimesheet.status.code",newTimesheet.stage.code)
                    console.log("newTimesheet.resource.managerId",newTimesheet.resource.managerId)

                     console.log('open' !== newTimesheet.status.code)

                     */
            //Bu neden vardı??
            /*&& (loggedInResourceId != newTimesheet.resource.managerId)*/
            if (
              parseInt(Auth.checkResourceId()) !==
              parseInt(newTimesheet.resource.uid)
            ) {
              isEditable = false;
            }
            // sadece  open  statüsündeki kayıtlar düzenlenebilir. (stage revision ve open)
            if ("open" !== newTimesheet.status.code) {
              isEditable = false;
            }

            // Butonların gösterimi
            let showWFButtons = 0;
            // stageler
            // open
            // chief_engineer_approval
            // program_manager_approval
            // approved
            // posted
            // revision

            // Show WF Buttons
            // 1 -> Submiti göster
            // 2 -> Approve Reject
            // 3 -> Sadece Reject

            //console.log("showwf",newTimesheet.stage.code,responsibles,Auth.checkResourceId())

            if (
              "open" == newTimesheet.status.code &&
              parseInt(newTimesheet.resource.uid) ==
                parseInt(Auth.checkResourceId())
            ) {
              showWFButtons = 1;
            }

            if (
              newTimesheet.stage.code == "chief_engineer_approval" &&
              parseInt(newTimesheet.resource.managerId) ==
                parseInt(Auth.checkResourceId())
            ) {
              showWFButtons = 2;
            }

            if (
              newTimesheet.stage.code == "program_manager_approval" &&
              responsibles.indexOf(parseInt(Auth.checkResourceId())) > -1
            ) {
              showWFButtons = 2;
            }

            /*
                    if(newTimesheet.stage.code=="approved" ve kullanıcı PKO ise) {
                        showWFButtons = 2;
                    }
                    */

            this.setState({
              ...this.state,
              periodStart: r.body.object.timeperiod.startDate,
              periodFinish: r.body.object.timeperiod.finishDate,
              Loading: false,
              timesheet: newTimesheet,
              isEditable: isEditable,
              showWFButtons: showWFButtons,
            });
          } else if (r.status == 401) {
            window.location.replace("/login");
          } else if (r.status == 403) {
            this.setState({
              ...this.state,
              LoadingError: true,
              LoadingErrorText:
                "You are not authorized to see this page. Please contact your system administrator.",
            });
          } else {
            this.setState({
              ...this.state,
              LoadingError: true,
              LoadingErrorText:
                "An unexpected error occured. Please contact your system administrator.",
            });
          }
        });
    }
  };

  submitButtonController(action) {
    if (action === "Submit") {
      let control = this.checkDailyWorklog();

      // console.log("control",control)
      if (control === 1) {
        this.saveTimesheet(() => {
          this.routeWF(action);
        });
      }

      // console.log("fordan çıktık")
      // this.notify("success","Submit")
    }
  }

  routeWF(action) {
    //console.log("routeWF")

    // Action değeri Approve, Revision, Submit değerlerinden birini alabilir.

    fetch("/api/timesheet/" + this.state.timesheet.uid + "/action/" + action, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r,r.status)
        if (r.status == 200) {
          if (r.body.isSuccess) {
            this.notify("success", "Submitted");
            document.location.reload();
          }
        } else if (r.status == 401) {
          //window.location.replace("/login");
        } else if (r.status == 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else if (r.status == 400) {
          this.notify("error", r.body.message);
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  }

  addTimeReportLines = (
    tasks,
    cb = () => {
      void 0;
    }
  ) => {
    // Fonksiyon array tasks şeklinde AddTaskModal altındaki task datasının arrayını alır ve bunları timereportline olarak bu state'e ekler.
    let a = []; // eklenecek kayıtlar için dizi oluşturuluyor.

    if (tasks.length > 0) {
      tasks.map((t) => {
        a.push({
          assignmentId: t.uid,
          timesheetId: this.state.timesheet.uid,
        });
      });

      fetch("/api/timeentry", {
        method: "POST",
        body: JSON.stringify(a),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Auth.check(),
        },
      })
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((r) => {
          if (r.status == 200) {
            let newReportLines = [...r.body.object];
            let newTimesheet = { ...this.state.timesheet };

            newReportLines.map((l) => {
              newTimesheet.timeReportLines.push(l);
            });

            this.setState({ ...this.state, timesheet: newTimesheet }, cb());
          } else if (r.status == 401) {
            //window.location.replace("/login");
          } else if (r.status == 403) {
            //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
          } else {
            //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
          }
        });
    }
  };

  removeTimeReportLine = (line) => {
    fetch("/api/timeentry", {
      method: "DELETE",
      body: JSON.stringify({ ids: [line.uid] }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status == 200) {
          let newReportLines = this.state.timesheet.timeReportLines.filter(
            (l) => {
              return l.uid !== line.uid;
            }
          );

          let newTotals = [];
          line.worklogs.map((w, i) => {
            newTotals[i] = this.state.timesheet.totals[i] - w.actual;
          });

          this.setState({
            ...this.state,
            timesheet: {
              ...this.state.timesheet,
              actual: this.state.timesheet.actual - line.actual,
              totals: newTotals,
              timeReportLines: newReportLines,
            },
          });
        } else if (r.status == 401) {
          //window.location.replace("/login");
        } else if (r.status == 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  };

  createTimesheet = () => {
    fetch("/api/timesheet", {
      method: "POST",
      body: JSON.stringify({
        resourceId: this.state.resourceId,
        timeperiodId: this.state.timeperiodId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        if (r.status == 200) {
          let newPeriods = this.state.reportingPeriods;

          newPeriods[this.state.selectedPeriod].timesheetId = r.body.object.uid;

          this.setState(
            { ...this.state, reportingPeriods: newPeriods, Loading: true },
            () => {
              this.loadTimesheet();
            }
          );
        } else if (r.status == 401) {
          window.location.replace("/login");
        } else if (r.status == 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  };

  setCompleted = (lineRow, val) => {
    let newState = { ...this.state };

    newState.timesheet.timeReportLines[lineRow].isCompleted = val;

    // newState.timesheet.timeReportLines[lineRow].isCompleted = val;
    this.setState(newState);

    // console.log(this.state.timesheet.timeReportLines[lineRow].completed)
  };

  setRemainingHour = (lineRow, newHours) => {
    //console.log("setRemainingHour");
    //7console.log(newHours);
    //console.log(lineRow);
    let newState = { ...this.state };

    newState.timesheet.timeReportLines[lineRow].remaining =
      parseFloat(newHours);
    newState.timesheet.timeReportLines[lineRow].modifiedRemaining =
      parseFloat(newHours);
    newState.timesheet.timeReportLines[lineRow].isRemainingModified = true;
    // console.log(this.state);

    //  this.setState(newState);
  };

  setWorklogHours = (lineRow, worklogRow, newHours) => {
    //console.log(lineRow+" "+worklogRow+" "+newHours)

    let newState = { ...this.state };

    // Delta Calculation
    const delta =
      newHours -
      this.state.timesheet.timeReportLines[lineRow].worklogs[worklogRow].actual;

    // new line total actual
    newState.timesheet.timeReportLines[lineRow].actual =
      this.state.timesheet.timeReportLines[lineRow].actual + delta;
    // timesheet total actuals
    newState.timesheet.actual = this.state.timesheet.actual + delta;
    // Worklog Cell
    newState.timesheet.timeReportLines[lineRow].worklogs[worklogRow].actual =
      newHours;

    // daily totals
    newState.timesheet.totals[worklogRow] =
      this.state.timesheet.totals[worklogRow] + delta;

    /* if(newState.timesheet.totals[worklogRow]>24)
        {
            this.notify("error","Timesheet daily worklog must less then 24 hour")
            return;
        }
        */
    // Line Remaining
    if (this.state.timesheet.timeReportLines[lineRow].remaining !== 0) {
      //  console.log("remaining 0")
      newState.timesheet.timeReportLines[lineRow].remaining = (
        this.state.timesheet.timeReportLines[lineRow].remaining - delta
      ).toFixed(2);
    } else {
      //console.log("remaining not 0")
      //console.log(delta);
      // console.log(newState.timesheet.timeReportLines[lineRow].actual)
      newState.timesheet.timeReportLines[lineRow].remaining = (
        this.state.timesheet.timeReportLines[lineRow].modifiedRemaining -
        newState.timesheet.timeReportLines[lineRow].actual
      ).toFixed(2);
    }
    newState.timesheet.timeReportLines[lineRow].remaining =
      newState.timesheet.timeReportLines[lineRow].remaining < 0
        ? 0
        : newState.timesheet.timeReportLines[lineRow].remaining;
    // Total Remaining
    /*
        newState.timesheet.remaining =   this.state.timesheet.remaining - delta;
        newState.timesheet.remaining = newState.timesheet.remaining<0?0:newState.timesheet.remaining
        */

    this.setState(newState);
  };

  showTasks = () => {
    // Assignment seçme modelini açar
    this.setState({ ...this.state, showTasks: true });
  };

  hideTasks = () => {
    // Assignment seçme modelini kapatır
    this.setState({ ...this.state, showTasks: false });
  };

  checkIsCompleted(timesheet) {
    let reportLines = timesheet.timeReportLines;
    for (let i = 0; i < reportLines.length; i++) {
      if (reportLines[i].isCompleted) {
        timesheet.timeReportLines[i].remaining = 0;
      }
    }
    return timesheet;
  }

  checkDailyWorklog = () => {
    //console.log("checkDailyWorklog")

    let array = this.state.timesheet.totals;
    //console.log("checkDailyWorklog",array)
    for (let i = 0; i < array.length; i++) {
      if (array[i] > 24) {
        this.notify("error", "Timesheet daily worklog must less then 24 hour");
        return 0;
      }
    }

    let reportLines = this.state.timesheet.timeReportLines;
    for (let i = 0; i < reportLines.length; i++) {
      if (
        (reportLines[i].isCompleted !== true ||
          reportLines[i].isCompleted === undefined) &&
        reportLines[i].remaining === 0
      ) {
        this.notify(
          "error",
          "If remaining is 0, then Is Completed must be checked."
        );
        return 0;
      }
    }

    //console.log("before Save")
    //this.saveTimesheet();

    return 1;
  };

  saveController() {
    let control = this.checkDailyWorklog();

    //  console.log("control",control)
    if (control === 1) {
      this.saveTimesheet();
    }

    // console.log("fordan çıktık")
    // this.notify("success","Submit")
  }

  saveTimesheet = (
    callback = function (e) {
      void 0;
    }
  ) => {
    //is completedlerin remaininglerini 0 yapar
    this.checkIsCompleted(this.state.timesheet);
    //console.log(this.state.timesheet)

    //console.log("saveTimesheet")

    fetch("/api/timesheetdetails/timesheet", {
      method: "PATCH",
      body: JSON.stringify(this.state.timesheet),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth.check(),
      },
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((r) => {
        //console.log(r);
        //console.log("saved");

        if (r.status === 200) {
          // Buraya notification gelecek. Save
          this.notify("success", "Saved");

          callback();
        } else if (r.status === 401) {
          //window.location.replace("/login");
        } else if (r.status === 403) {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else if (r.status === 400) {
          this.notify("error", "Not saved. " + r.body.message);
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
        } else {
          //this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
        }
      });
  };

  render() {
    //console.log(this.state)

    return (
      <div className="hub-ts-timesheetDetailPage">
        {this.state.Loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "absolute",
              left: "50%",
              top: "50%",
              fontWeight: "bold",
              transform: "translate(-50%,-50%)",
            }}
          >
            <FontAwesomeIcon
              icon={faSpinner}
              color={"#D7EDFF"}
              size={"4x"}
              className="spinner2"
            />
            <br /> Loading...
          </div>
        ) : (
          <>
            <div className="hub-ts-timesheetDetailPage-Header">
              <ActionButtons>
                <MainButton
                  onClick={this.showTasks}
                  disabled={
                    this.state.isEditable &&
                    (this.state.timesheet?.stage?.code == "open" ||
                      this.state.timesheet?.stage?.code == "revision")
                      ? false
                      : true
                  }
                  label="Add Work"
                />
                <ColumnPicker
                  applyVisibleColumns={this.applyVisibleColumns}
                  columns={this.state.columns}
                  visibleColumnList={this.state.visibleColumnList}
                />
              </ActionButtons>
              <ReportPeriodPicker
                selectPeriod={this.selectPeriod}
                initialPeriodOrder={this.state.initialPeriodOrder}
                selectedPeriod={this.state.selectedPeriod}
                reportingPeriods={this.state.reportingPeriods}
              />
              {/*<TimesheetProgressBar availability={this.state.timesheet.availability} actual={this.state.timesheet.actual} />*/}
              <ActionButtons>
                {this.state.showWFButtons == 2 ? (
                  <MainButton
                    style={{
                      color: "#19470a",
                      borderColor: "#19470a",
                      backgroundColor: "#d2f0c7",
                    }}
                    onClick={() => {
                      this.routeWF("Approve");
                    }}
                    label="Approve"
                  />
                ) : (
                  false
                )}
                {this.state.showWFButtons == 2 ||
                this.state.showWFButtons == 3 ? (
                  <MainButton
                    style={{
                      color: "#78180d",
                      borderColor: "#78180d",
                      backgroundColor: "#fadfdc",
                    }}
                    onClick={() => {
                      this.routeWF("Revision");
                    }}
                    label="Send Back to Revision"
                  />
                ) : (
                  false
                )}
                {this.state.showWFButtons == 1 ? (
                  <MainButton
                    onClick={() => {
                      this.submitButtonController("Submit");
                    }}
                    label="Submit"
                  />
                ) : (
                  false
                )}
                {this.state.isEditable ? (
                  <MainButton
                    onClick={() => {
                      this.saveController();
                    }}
                    label="Save"
                  />
                ) : (
                  false
                )}
                <MainButton
                  className="button-2"
                  label="Conversation"
                  onClick={this.toggleSidebar}
                />
              </ActionButtons>
            </div>
            {this.state.reportingPeriods[this.state.selectedPeriod]
              .timesheetId > -1 ? (
              <TimesheetTable
                visibleColumns={this.state.visibleColumns}
                isEditable={this.state.isEditable}
                setCompleted={this.setCompleted}
                removeTimeReportLine={this.removeTimeReportLine}
                showTasks={this.showTasks}
                setWorklogHours={this.setWorklogHours}
                setRemainingHour={this.setRemainingHour}
                timesheet={this.state.timesheet}
                selectedPeriod={
                  this.state.reportingPeriods[this.state.selectedPeriod]
                }
                isSideBarOpen={this.state.isSideBarOpen}
                toggleSidebar={this.toggleSidebar}
              />
            ) : (
              <div
                className="hub-ts-timesheetTableWrapper"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MainButton
                  disabled={!this.state.isEditable}
                  onClick={this.createTimesheet}
                  className="button-2"
                  label="Create Timesheet"
                />
              </div>
            )}
          </>
        )}
        {this.state.showTasks ? (
          <AddTaskModalWrapper
            timeReportLines={this.state.timesheet.timeReportLines}
            render="1"
            onClose={this.hideTasks}
            addTimeReportLines={this.addTimeReportLines}
            periodStart={this.state.periodStart}
            periodFinish={this.state.periodFinish}
          />
        ) : (
          false
        )}
      </div>
    );
  }
}

export default TimesheetDetail;

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import './SideBar.css';
import { Resizable } from "re-resizable";
import HubIcon from '../hubIcon/HubIcon';
import HiClose from '../icons/HiClose';


class SideBar extends Component {

    constructor(props) {
        super(props);
        this.state={width: this.props.sidebarWidth?this.props.sidebarWidth:580}
    }

    handleResizeColumn = (w) => {
        // Resize durduğunda  view'a kolonun width'ini ekleyen method. 
        // i->kolon objesinin dizideki indexi
        // w yeni Deltawidth.   bu değer mevcut width'e + olarak eklenir.

        let newState = {...this.state};
        newState.width = newState.width + w
        this.setState(newState);

        
        if(this.props.setSidebarWidth)
        {
            this.props.setSidebarWidth(newState.width);
        }   
        
    }
 
    
    render() {
        //console.log("sidebar",this.props)
        return (
            <Resizable 
            enable={ {top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
            minWidth={600}
            defaultSize={ {width:this.state.width}}
            size={{ width: this.state.width }}
            onResizeStop={(e, direction, ref, d) => {
                this.handleResizeColumn(d.width); 
            }}
            className="hub-sideBar-resizer"
            style={{display:this.props.isOpen?"flex":"none"}}
            handleClasses={{left:"hub-sideBar-resize-handler"}}
            >
                <div className="hub-sideBar-wrapper" style={{overflowX:"hidden"}}> 
                    <div className="hub-sideBar">
                        <div className="header">
                            <span className="title">{this.props.title && this.props.title !==""?this.props.title:this.props.selectedRecord[this.props.nameAttribute]?this.props.selectedRecord[this.props.nameAttribute]:''}
                                {this.props.parentRecord?.resource?.Manager?<div style={{fontSize: "13px",  paddingTop: "10px"}}>Manager: {this.props.parentRecord?.resource?.Manager}</div>:''}                    
                                {this.props.selectedRecord?.resource?.obsInfo?.name?<div style={{fontSize: "13px" }}>OBS: {this.props.selectedRecord?.resource?.obsInfo?.name}</div>:''}
                            </span>
                            <div onClick={this.props.toggleSidebar}  className="closeIcon" style={{cursor:"pointer"}}>
                                <HubIcon fontSize = {16} lineColor = "#1254C1"><HiClose /></HubIcon>
                            </div>
                        </div>
                        <div className="body" style={{width: this.state.width - 10}}>
                            {this.props.parentRecord?this.props.children:!this.props.selectedRecord[this.props.idAttribute] && !this.props.sidebarType?.includes("Create")?<div className="noRows"><span> No item selected </span>
                            </div>:this.props.children}
                        </div>

                    </div>
                </div>
            </Resizable>
        );
    }
}

export default SideBar;
const HiFolder = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 15 15"
      fill={bgColor ?? "none"}
    >
      <path
        d="M8 5.06944L7.44223 3.98489C7.2817 3.67275 7.20143 3.51667 7.08169 3.40265C6.97579 3.30181 6.84816 3.22513 6.70804 3.17814C6.54958 3.125 6.3701 3.125 6.01115 3.125H4.1C3.53995 3.125 3.25992 3.125 3.04601 3.23097C2.85785 3.32418 2.70487 3.47291 2.60899 3.65584C2.5 3.86381 2.5 4.13606 2.5 4.68056V5.06944M2.5 5.06944H10.1C10.9401 5.06944 11.3601 5.06944 11.681 5.22839C11.9632 5.36821 12.1927 5.59131 12.3365 5.86571C12.5 6.17766 12.5 6.58603 12.5 7.40278V9.54167C12.5 10.3584 12.5 10.7668 12.3365 11.0787C12.1927 11.3531 11.9632 11.5762 11.681 11.7161C11.3601 11.875 10.9401 11.875 10.1 11.875H4.9C4.05992 11.875 3.63988 11.875 3.31901 11.7161C3.03677 11.5762 2.8073 11.3531 2.66349 11.0787C2.5 10.7668 2.5 10.3584 2.5 9.54167V5.06944Z"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiFolder;

import React from "react";

const HiEdit2 = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <path
        d="M12.0001 6.66671L9.33343 4.00004M1.66675 14.3334L3.92299 14.0827C4.19865 14.0521 4.33648 14.0367 4.46531 13.995C4.57961 13.958 4.68838 13.9058 4.78867 13.8396C4.90172 13.7651 4.99978 13.667 5.1959 13.4709L14.0001 4.66671C14.7365 3.93033 14.7365 2.73642 14.0001 2.00004C13.2637 1.26366 12.0698 1.26366 11.3334 2.00004L2.52923 10.8042C2.33311 11.0003 2.23505 11.0984 2.16051 11.2114C2.09437 11.3117 2.04209 11.4205 2.00509 11.5348C1.96339 11.6636 1.94807 11.8015 1.91744 12.0771L1.66675 14.3334Z"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={lineColor}
      />
    </svg>
  );
};

export default HiEdit2;

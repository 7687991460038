import React from 'react';
import AreaChart from './areaChart';
import Auth from '../../auth/Auth';
import LoadingScreen from '../../common/statusScreen/loadingScreen';

 
export default class AreaChartRunner extends React.Component { 

         
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data:[],
            rawData:[],
            sortString:"",
            sortColumn:"",

        }
        // This binding is necessary to make `this` work in the callback
        this.generateData = this.generateData.bind(this);
    }

    componentDidMount() {
        this.generateData();
    }

    
    componentDidUpdate(prevProps, prevState) 
    { 
   
        if(prevProps.counter!==this.props.counter)
        {
          
            this.generateData();
        }

    } 
    
        
    // * Şekillendirilmiş datayı hazırlar     
    createShapedData=(data)=>
    {   let config=this.props.config?.configuration
        //console.log(data,config)
        let newData=[]
        

        config.keys.map((key,i)=>
        {
            //console.log(key,i)

            newData.push({id:key,label:i.toString(),data:[]})

            data.map((d,j)=>
            {
               // console.log("içerde",newData,i)
                newData[i].data[j]={x:d[config.indexBy] ,y:d[key]}
                
            })

        })

        //console.log("newData",newData)
        return newData

    }

    generateData = () => {

        if (this.props.sampleData) {
            this.setState({...this.state, loading:false, data:this.props.sampleData, rawData:this.props.sampleData});
        } else {

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': "Bearer " + Auth.check() },
            };

            let filterString=""
            //console.log("generateData",this.props.selectedFilters)
            if(this.props.selectedFilters.length>0 || this.props.multiplexingFilter)
            {
                filterString+="?"

                this.props.selectedFilters.map((f,i)=>
                {
                   
                    filterString+=(i!==0?"&":"")+f.dataKey+"="+f.value.toString()
                })

                
                if(this.props.multiplexingFilter)
                {
                    filterString+= (this.props.selectedFilters.length>0?"&":"")+this.props.multiplexingAttribute+"="+this.props.multiplexingFilter.toString()

                }
            }
    
            fetch("/api/query/code/"+this.props.config.query+filterString, requestOptions)
                .then(response => response.json().then(data => ({status:response.status, body:data})))
                .then(r => {
                   //console.log(r,this.props.config)
                    if(r.status===200 ) {  
                        
                        
                        this.setState({...this.state, loading:r.body.object!==null?false:true, noContent:r.body.object===null?true:false, data:r.body.object===null?[]:this.createShapedData(r.body.object)});
                    }
                    else if(r.status===401) {
                        window.location.replace("/login");
                    }
                    else if(r.status===403) {
                        this.setState({...this.state, LoadingError: true, LoadingErrorText:"You are not authorized to see this page. Please contact your system administrator."});
                    }
                    else {
                        this.setState({...this.state, LoadingError: true, LoadingErrorText:"An unexpected error occured. Please contact your system administrator."});
                    }
                });
        }
    }

    render() {
        return <>
            {(this.state.loading?<div style={{position:"relative", height:"100px"}}><LoadingScreen noContent={this.state.noContent}/></div>:<AreaChart data = {this.state.data} configuration={this.props.config.configuration}/>)}
         </>
    }

}
import React from "react";

const HiCopy = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={bgColor ?? "none"}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_2753_41271)">
        <path
          d="M6.99992 1.33524C6.54988 1.34134 6.27972 1.36739 6.06126 1.4787C5.81038 1.60653 5.60641 1.8105 5.47858 2.06139C5.36727 2.27984 5.34121 2.55001 5.33512 3.00004M12.9999 1.33524C13.45 1.34134 13.7201 1.36739 13.9386 1.4787C14.1895 1.60653 14.3934 1.8105 14.5213 2.06139C14.6326 2.27984 14.6586 2.55 14.6647 3.00003M14.6647 9.00003C14.6586 9.45007 14.6326 9.72024 14.5213 9.93869C14.3934 10.1896 14.1895 10.3936 13.9386 10.5214C13.7201 10.6327 13.45 10.6587 12.9999 10.6648M14.6666 5.33337V6.6667M9.33329 1.33337H10.6666M3.46659 14.6667H8.53325C9.27999 14.6667 9.65336 14.6667 9.93857 14.5214C10.1895 14.3936 10.3934 14.1896 10.5213 13.9387C10.6666 13.6535 10.6666 13.2801 10.6666 12.5334V7.46671C10.6666 6.71997 10.6666 6.3466 10.5213 6.06139C10.3934 5.8105 10.1895 5.60653 9.93857 5.4787C9.65336 5.33337 9.27999 5.33337 8.53325 5.33337H3.46659C2.71985 5.33337 2.34648 5.33337 2.06126 5.4787C1.81038 5.60653 1.60641 5.8105 1.47858 6.06139C1.33325 6.3466 1.33325 6.71997 1.33325 7.46671V12.5334C1.33325 13.2801 1.33325 13.6535 1.47858 13.9387C1.60641 14.1896 1.81038 14.3936 2.06126 14.5214C2.34648 14.6667 2.71985 14.6667 3.46659 14.6667Z"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke={lineColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_2753_41271">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HiCopy;

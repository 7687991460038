import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './SideBar.css';
import { Resizable } from "re-resizable";
import GanttItem from './GanttItem';

let scrollTop = 142;

class Gantt extends Component {

    constructor(props) {
        super(props);
        this.state={width: 500}
    }


    componentDidMount() {
         // Yer bir ayın genişliği
        this.setState({...this.state,  innerWidth:this.props.ganttMonths.length*this.props.wPerMonth, width:this.props.ganttWidth!==undefined?this.props.ganttWidth:500})
    }


    handleResizeColumn = (w) => {
        // Resize durduğunda  view'a kolonun width'ini ekleyen method. 
        // i->kolon objesinin dizideki indexi
        // w yeni Deltawidth. bu değer mevcut width'e + olarak eklenir.

        // panel resize edildiğinde scroll değişiyor. korumak için ekliyorum.
        const t = document.getElementById("hub-gantt-table-body").scrollTop;
        //console.log(t);

        let newState = {...this.state};
        newState.width = newState.width + w
        this.setState(newState, ()=> {
            document.getElementById("hub-gantt-table-body").scrollTop = scrollTop;
            document.getElementById("hub-gantt-body").scrollTop = scrollTop;
        });
        
    }

    
    ganttContainerScrollHandler = (e) => {
        if (window._preventEvent) {
            window._preventEvent = false;
            return;
        }

        //document.getElementById("hub-gantt-table-header").scrollLeft = e.target.scrollLeft;
        this._preventEvent = true;
        document.getElementById("hub-gantt-table-body").scrollTop = e.target.scrollTop;
        //document.getElementById("hub-gantt-header").style.top =  e.target.scrollTop.toString() + "px";
    }

 
    
    render() { 
       // console.log("gantt props", this.props)
        return (
            <Resizable 
            enable={ {top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false}}
            minWidth={250}
            defaultSize={ {width:this.state.width}}
            size={{ width: this.state.width }}
            onResizeStart={(e, direction, ref) => {
                scrollTop = document.getElementById("hub-gantt-table-body").scrollTop;
            }}
            onResize={(e, direction, ref, d) => {
                document.getElementById("hub-gantt-body").scrollTop = scrollTop;
                document.getElementById("hub-gantt-table-body").scrollTop = scrollTop;
            }}
            onResizeStop={(e, direction, ref, d) => {
                this.handleResizeColumn(d.width); 
            }}
            style={{overflow:"auto"}}
            handleClasses={{left:"hub-gantt-resize-handler"}}
            >
              <div className="hub-gantt" style={{display:"flex", overflow:"auto", flex:1, height:"100%"}}>
                <div className="hub-gantt-wrapper" id="hub-gantt-wrapper" style={{display:"flex", flexDirection:"column"}}> 
                    <div className="hub-gantt-header" id="hub-gantt-header" style={{width: this.state.innerWidth}}> 
                        {this.props.ganttMonths.map(m => { 
                            return <div key={"ganttMonth-"+m.str} className="hub-gantt-header-month" style={{width: this.props.wPerMonth}}>{m.str}</div>
                        })}
                    </div>
                     
                    <div className="hub-gantt-body" onScroll={this.ganttContainerScrollHandler} id="hub-gantt-body"> 
                    <div style={{height:this.props.dataLen*27}}>
                    <div style={{height:this.props.ganttRenderBy*27}}></div>

                        {this.props.data.map((d, i)=>{

                            let color = "";
                            if(d.status!==null) {
                                switch(d.status.name) {
                                    case 'Active':
                                        color = "green";
                                        break;
                                    case 'Completed':
                                        color = "brown";
                                        break;
                                    case 'In Progress':
                                        color = "green";
                                        break;
                                    case 'Not Started':
                                        color = "blue";
                                        break;
                                } 
                            }
                            
                            return (<div onClick={() => { this.props.selectRecord(d, i) }} key={"ganttRow-"+i.toString()} id={"hub-gantt-row-"+i.toString()} className={this.props.selectedRecordRowNum==(i+this.props.ganttRenderBy)?"hub-gantt-row active":"hub-gantt-row"}
                             style={{width: this.state.innerWidth}} > 
                                {this.props.ganttMonths.map(m => { 
                                    return <div key={"ganttRow-"+i.toString()+m.str} className="hub-gantt-row-bg" style={{width: this.props.wPerMonth}}></div>
                                })}
                                {(d.startDate !== null && d.finishDate !== null)?
                                    <GanttItem isSelectedRow = {this.props.selectedRecordRowNum==(i+this.props.ganttRenderBy)} isMilestone={d.extType==="Start Milestone" || d.extType==="Finish Milestone"?true:false} ganttSort = {i} hasChild={d.children && d.children.length>0} color={color} key={"ganttItem-"+i.toString()}  data={d} />
                                :false
                                }
                            </div>)
                        })}
                        </div>
                    </div>
                </div>
                </div>
            </Resizable>
        );
    }
}

export default Gantt;
import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import { faCircleCheck } from '@fortawesome/pro-duotone-svg-icons'
export default class ActivityTaskList extends Component {



  render() {

    let todoList=this.props.todoList;

    //console.log("todo",todoList)

    return (
      todoList.map((t,i)=>{
          return <div className='card-to-do-wrapper'>
                    <FontAwesomeIcon 
                      title={t?.isBlocked?"Blocked":"Is Completed"} 
                      icon={t?.status?.code==="completed"?faCircleCheck:faCircleCheck} 
                      style={(t?.status?.code==="completed"?{height:20, marginRight:10, cursor:"pointer", "--fa-primary-color": "#ffffff", "--fa-secondary-color": "#005eff", "--fa-secondary-opacity": "1",}
                        :t?.isBlocked?{height:20, marginRight:10, color:"red", cursor:"pointer"}:
                        {height:20, marginRight:10, color:"#D2DEF2", cursor:"pointer"})} 
                 
                    />
                    <div className='new-to-do-description-wrapper'>
                          <div className='new-to-do-description' >{t.name}</div>
                    </div>
                </div>
      })

    )
  }
}

import React, { Component } from 'react';
import './Widget.css';
import WidgetHeader from './WidgetHeader';

class Widget extends Component {
   
    render() {
       // console.log(this.props)
        return (
            <div className="hub-widget" style={{...this.props.style}}>
                <WidgetHeader title={this.props.title} link={this.props.link} info={this.props.info} icon={this.props.icon}  widgetRef={this.props.widgetRef}/>
                {/*<div className="body">{this.props.children}</div>*/}

                <div className="body">{this.props.children}</div>
            </div>
        );
    }
}

export default Widget;
import React, { Component } from 'react'
import './dashboard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChartColumn} from '@fortawesome/free-solid-svg-icons'
export default class DashboardHeader extends Component {
  render() {
    return (
    <div className='hub-dashboard-page-container-header'>
        <div className='container-header-icon'>
            <FontAwesomeIcon icon={faChartColumn} color={"white"}   size={"1x"} />
        </div>
         <div className='container-header-title'>
            {this.props.title}
         </div>

    </div>
    )
  }
}

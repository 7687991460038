import React, { Component } from "react";
import { DateUtils } from "../../../common/Commons";
import HubIcon from "../../../common/hubIcon/HubIcon";
import HiFolder from "../../../common/icons/HiFolder";
class Item extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorpoint: {}, showSummary: false };
  }

  getSummary = () => {
    let du = new DateUtils();
    if (this.state.showSummary) {
      return (
        <div
          className={"hub-gantt-summary"}
          style={{
            zIndex: 9999,
            top: this.state.anchorpoint.y + 5,
            left: this.state.anchorpoint.x + 5,
          }}
        >
          <div className="task-name">{this.props.item.name}</div>
          <div className="project-title">
            <HubIcon lineColor="#637189">
              <HiFolder />
            </HubIcon>
            <span className="project-name">{this.props.item.project_name}</span>
          </div>
          <div className="summary-divider" />
          {/*   <div>WBS: {this.props.item.wbs_name}</div>
                <div>Activity: {this.props.item.activity_name}</div>
                      Owner: {this.props.item.first_name + " "+this.props.item.last_name}<br />*/}
          <div className="summary-article">
            Start / Finish
            <span className="summary-bold">
              {du.Date2Str(this.props.item.real_start_date)} -{" "}
              {du.Date2Str(this.props.item.real_finish_date)}
            </span>
          </div>
          <div className="summary-article">
            Planned
            <span className="summary-bold">
              {this.props.item.planned_unit}
              <span className="summary-unit">h</span>
            </span>
          </div>
          <div className="summary-article">
            Actual
            <span className="summary-bold">
              {this.props.item.actual_unit}
              <span className="summary-unit">h</span>
            </span>
          </div>
          <div className="summary-article">
            Remaining
            <span className="summary-bold">
              {this.props.item.remaining_unit}
              <span className="summary-unit">h</span>
            </span>
          </div>
          <div className="summary-article">
            {this.props.boardType === "Activity"
              ? "Project % Complete:"
              : "Task % Complete:"}
            <span className="summary-bold">
              {Math.round(this.props.item.percent_complete * 100)}
            </span>
          </div>
          <div className="summary-article">
            {this.props.boardType === "Activity"
              ? "Activity % Complete:"
              : "Assignee % Complete:"}
            <span className="summary-bold">
              {Math.round(this.props.item.assignee_complete * 100)}
            </span>
          </div>
        </div>
      );
    } else {
      return false;
    }
  };

  handleMouseOver = (e) => {
    this.setState({
      ...this.state,
      anchorpoint: { x: e.pageX, y: e.pageY },
      showSummary: true,
    });
  };

  handleMouseLeave = (e) => {
    this.setState({ ...this.state, anchorpoint: {}, showSummary: false });
  };

  render() {
    //console.log(this.props.item.name,this.props.item.real_finish_date, this.props.item.finish_date ,this.props.item.real_finish_date===this.props.item.finish_date)

    // Filtre taskı keserse kestiği yeri dotted yapar
    let startDot =
      this.props.item.real_start_date === this.props.item.start_date;
    let finishDot =
      this.props.item.real_finish_date === this.props.item.finish_date;
    return (
      <>
        <div
          className={
            "hub-thub-tline-item " +
            (!startDot ? "left " : "") +
            (!finishDot ? "right " : "") +
            this.props.item.color_code
          }
          onMouseMove={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          style={{
            left: this.props.item.left,
            overflow: "hidden",
            height: 20,
            borderRadius: 3,
            width: this.props.item.width,
            paddingLeft: this.props.mode === "timeline" ? false : 0,
          }}
        >
          {this.props.mode === "timeline" ? (
            this.props.item.name
          ) : (
            <div
              className="progress-bar"
              style={{
                width:
                  Math.round(
                    this.props.item.assignee_complete * 100
                  ).toString() + "%",
              }}
            ></div>
          )}
        </div>
        {this.props.mode === "timeline" ? (
          false
        ) : (
          <>
            <div
              className="hub-thub-tline-item-name"
              onMouseMove={this.handleMouseOver}
              onMouseLeave={this.handleMouseLeave}
              style={{
                left: this.props.item.left + this.props.item.width + 15,
                overflow: "hidden",
                color: this.props.item.assignee_complete === 1 ? "green" : "",
              }}
            >
              {this.props.item.name}
            </div>
          </>
        )}
        {this.getSummary()}
      </>
    );
  }
}

export default Item;

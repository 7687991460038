import React, { Component } from 'react'
import SearchArea from '../../common/inputControls/searchArea'
import HierarchicalTable from '../../common/hierarchicalTable/hierarchicalTable'
export default class OBSList extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       searchString:"",
       description:false
    }
  }



    setSearchString = (v) => {this.setState({...this.state, searchString:v})}


  render() {
    return (
      <div>
        <div className='obs-search-area' style={{width:"90%"}}>
            <SearchArea onChange={this.setSearchString} value={this.state.searchString} />
        </div>
        <HierarchicalTable labelKey={this.props.labelKey} data={this.props.items} searchString={this.state.searchString} toggleSelectBar={this.props.toggleSelectBar}/>
    
         
      </div>
    )
  }
}

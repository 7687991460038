import React, { Component } from "react";
import TooltipWithPage from "../tooltipWithPage/tooltipWithPage";
import MainButton from "../buttons/MainButton";

import {
  faClipboard,
  faListCheck,
  faCheckToSlot,
} from "@fortawesome/pro-light-svg-icons";
//import { faListCheck,faCheckToSlot} from '@fortawesome/pro-regular-svg-icons'
export default class ActionBarActions extends Component {
  render() {
    //console.log("ActionBarActions",this.props)
    return (
      <TooltipWithPage
        title={"Actions"}
        closePanel={this.props.toggleShowActions}
        arrowLocaiton={{ marginLeft: "25px" }}
        inLineStyle={{ padding: "20px" }}
        style={{ width: "185px", transform: "translate(-20%)" }}
      >
        <div className="hub-datatable-actions-list">
          {this.props.projectSource === "hub" ? (
            <>
              <div className="actions-list-items">
                <MainButton
                  className="button-2"
                  label={"Add WBS"}
                  style={{ width: 145, textAlign: "inherit" }}
                  onClick={() => {
                    this.props.openNewSideBar("wbsCreate");
                    this.props.toggleShowActions();
                  }}
                />
              </div>
              <div className="actions-list-items">
                <MainButton
                  className="button-2"
                  label={"Add Activity"}
                  style={{ width: 145, textAlign: "inherit" }}
                  onClick={() => {
                    this.props.openNewSideBar("activityCreate");
                    this.props.toggleShowActions();
                  }}
                />
              </div>
            </>
          ) : (
            false
          )}
          <div className="actions-list-items">
            <MainButton
              className="button-2"
              label={"Add Task"}
              style={{ width: 145, textAlign: "inherit" }}
              onClick={() => {
                this.props.openNewSideBar("taskCreateScratch");
                this.props.toggleShowActions();
              }}
            />
          </div>
        </div>
      </TooltipWithPage>
    );
  }
}

import React, { Component } from 'react'

import PageLayout from '../../../common/pageElements/pageLayout/pageLayout'

export default class ProgramDashboards extends Component {

  


  render() {

    //console.log("ProgramDashboards",this.props)
    return (
      < PageLayout code={/*this.props.program?.code+"Dashboard"*/ "chiefDashboard" }/>
    )
  }
}

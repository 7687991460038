import React from "react";

const HiOtherWorks = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      fill={bgColor ?? "none"}
      viewBox="0 0 20 20"
    >
      <path
        d="M10 17.5L9.91664 17.3749C9.33777 16.5066 9.04834 16.0725 8.66594 15.7582C8.3274 15.4799 7.93732 15.2712 7.51802 15.1438C7.04439 15 6.52261 15 5.47904 15H4.33335C3.39993 15 2.93322 15 2.5767 14.8183C2.2631 14.6586 2.00813 14.4036 1.84834 14.09C1.66669 13.7335 1.66669 13.2668 1.66669 12.3333V5.16667C1.66669 4.23325 1.66669 3.76654 1.84834 3.41002C2.00813 3.09641 2.2631 2.84144 2.5767 2.68166C2.93322 2.5 3.39993 2.5 4.33335 2.5H4.66669C6.53353 2.5 7.46695 2.5 8.17999 2.86331C8.80719 3.18289 9.31713 3.69282 9.63671 4.32003C10 5.03307 10 5.96649 10 7.83333M10 17.5V7.83333M10 17.5L10.0834 17.3749C10.6623 16.5066 10.9517 16.0725 11.3341 15.7582C11.6726 15.4799 12.0627 15.2712 12.482 15.1438C12.9556 15 13.4774 15 14.521 15H15.6667C16.6001 15 17.0668 15 17.4233 14.8183C17.7369 14.6586 17.9919 14.4036 18.1517 14.09C18.3334 13.7335 18.3334 13.2668 18.3334 12.3333V5.16667C18.3334 4.23325 18.3334 3.76654 18.1517 3.41002C17.9919 3.09641 17.7369 2.84144 17.4233 2.68166C17.0668 2.5 16.6001 2.5 15.6667 2.5H15.3334C13.4665 2.5 12.5331 2.5 11.8201 2.86331C11.1928 3.18289 10.6829 3.69282 10.3633 4.32003C10 5.03307 10 5.96649 10 7.83333"
        stroke={lineColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiOtherWorks;

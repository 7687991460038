import React from "react";

const HiCamera = ({ bgColor, lineColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      fill={bgColor ?? "none"}
    >
      <path
        d="M3.16675 11.1695C3.16675 10.7024 3.16675 10.4688 3.18624 10.2721C3.37422 8.37486 4.87511 6.87397 6.77236 6.68599C6.96907 6.6665 7.21522 6.6665 7.70753 6.6665C7.89722 6.6665 7.99207 6.6665 8.0726 6.66163C9.1009 6.59935 10.0014 5.95034 10.3856 4.9945C10.4157 4.91965 10.4438 4.83527 10.5001 4.6665C10.5563 4.49774 10.5845 4.41336 10.6146 4.33851C10.9988 3.38267 11.8993 2.73366 12.9276 2.67138C13.0081 2.6665 13.097 2.6665 13.2749 2.6665H19.7252C19.9031 2.6665 19.9921 2.6665 20.0726 2.67138C21.1009 2.73366 22.0013 3.38267 22.3856 4.33851C22.4157 4.41336 22.4438 4.49774 22.5001 4.6665C22.5563 4.83527 22.5845 4.91965 22.6146 4.9945C22.9988 5.95034 23.8993 6.59935 24.9276 6.66163C25.0081 6.6665 25.1029 6.6665 25.2926 6.6665C25.7849 6.6665 26.0311 6.6665 26.2278 6.68599C28.1251 6.87397 29.6259 8.37486 29.8139 10.2721C29.8334 10.4688 29.8334 10.7024 29.8334 11.1695V21.5998C29.8334 23.84 29.8334 24.9602 29.3974 25.8158C29.0139 26.5684 28.402 27.1804 27.6494 27.5639C26.7937 27.9998 25.6736 27.9998 23.4334 27.9998H9.56675C7.32654 27.9998 6.20643 27.9998 5.35079 27.5639C4.59814 27.1804 3.98622 26.5684 3.60272 25.8158C3.16675 24.9602 3.16675 23.84 3.16675 21.5998V11.1695Z"
        stroke="white"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.5001 21.9998C19.4456 21.9998 21.8334 19.612 21.8334 16.6665C21.8334 13.721 19.4456 11.3332 16.5001 11.3332C13.5546 11.3332 11.1667 13.721 11.1667 16.6665C11.1667 19.612 13.5546 21.9998 16.5001 21.9998Z"
        stroke={lineColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HiCamera;

import http from "../http-common";

const basePath = (id) => `/api/task/${id}/conversation`;

export const getConversationList = (taskId) => {
  return http.get(basePath(taskId));
};

export const createConversation = (taskId, message, files, mentions) => {
  return http.post(basePath(taskId), {
    message: message,
    files: files,
    mentions: mentions,
  });
};

export const deleteConversation = (conversationId, taskId) => {
  return http.delete(`${basePath(taskId)}/${conversationId}`);
};

export const updateConversation = (
  taskId,
  conversationId,
  message,
  files,
  mentions
) => {
  return http.patch(`${basePath(taskId)}/${conversationId}`, {
    message: message,
    files: files,
    mentions: mentions,
  });
};

export const getConversation = (conversationId, taskId) => {
  return http.get(`${basePath(taskId)}/${conversationId}`);
};

export const getMentionCharacters = (taskId) => {
  return http.get(`${basePath(taskId)}/mentionUsers`);
};

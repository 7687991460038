import React, { Component } from 'react';

class TimesheetTHTotals extends Component {
    render() {
        return (
            <div style={{width:(this.props.width?this.props.width:85)}}>
                <div className="hub-ts-timesheetTable-th-total-unit">{this.props.units} Hours</div>
                <div className="hub-ts-timesheetTable-th-total-label">{this.props.label}</div>
            </div>
        );
    }
}

export default TimesheetTHTotals;
import React, { Component } from "react";
import { DateUtils, compareDates } from "./../../Commons";
import HubIcon from "../../hubIcon/HubIcon";
import MainButton from "../../buttons/MainButton";
import HiTrash from "../../icons/HiTrash";
import HiEdit1 from "../../icons/HiEdit1";
import HiMore from "../../icons/HiMore";
import Auth from "../../../auth/Auth";
import NewModal from "../../newModal";

export default class noteInfo extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      isMenuOpen: false,
      infoOpen: false,
      isModalOpened: false,
    };
  }

  toggleMenu = () => {
    this.setState({ ...this.state, isMenuOpen: !this.state.isMenuOpen }, () => {
      window.addEventListener("mousedown", this.handleClickOutside);
    });
  };

  closeMenu = () => {
    this.setState({ ...this.state, isMenuOpen: false, infoOpen: false }, () => {
      window.removeEventListener("mousedown", this.handleClickOutside);
    });
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current?.contains(event?.target)) {
      this.closeMenu();
    }
  };

  handleEdit = () => {
    this.setState({
      ...this.state,
      isMenuOpen: false,
    });
  };
  changeState = () => {
    // console.log("noteInfo");
    this.props.changeState();
  };

  compareSession = () => {
    var createdBy = this.props.dataSet.createdBy.uid.toString();
    var sessionUser = Auth.checkResourceId().toString();

    if (createdBy === sessionUser) {
      return true;
    } else {
      return false;
    }
  };

  openModal = () => {
    this.setState({
      ...this.state,
      isModalOpened: true,
      isMenuOpen: false,
    });
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      isModalOpened: false,
    });
  };

  render() {
    let util = new DateUtils();

    return (
      <>
        <div className="note-info">
          <div className="name">{this.props.dataSet.createdBy.fullName}</div>
          <div className="date">
            {util.Date2StrHour(this.props.dataSet.createdDate)}
          </div>
          {compareDates(
            this.props.dataSet.createdDate,
            this.props.dataSet.lastModifiedDate
          ) && (
            <>
              <div className="divider" />
              <div className="date">Edited</div>
            </>
          )}
          <div
            className="note-options-wrapper"
            style={{
              display: this.compareSession() ? "flex" : "none",
              right: this.props.hasScroll ? "12px" : "30px",
            }}
          >
            <MainButton
              key="conversationOptions"
              iconLineColor="#A5BDE5"
              iconSize={14}
              className="note-settings"
              icon={<HiMore />}
              onClick={this.toggleMenu}
            />

            {this.state.isMenuOpen && (
              <div className="note-options-menu" ref={this.wrapperRef}>
                <div
                  className="note-options-menu-item"
                  onClick={() => {
                    this.props.retrieveConversation(this.props.dataSet);
                    this.handleEdit();
                  }}
                >
                  <HubIcon fontSize={14}>
                    <HiEdit1 />
                  </HubIcon>
                  <span>Edit</span>
                </div>
                <div className="note-menu-divider"></div>
                <div
                  className="note-options-menu-item"
                  onClick={() => {
                    this.openModal();
                  }}
                >
                  <HubIcon fontSize={14}>
                    <HiTrash />
                  </HubIcon>
                  <span>Delete</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.isModalOpened && (
          <NewModal
            title="Delete Confirmation"
            width="332px"
            onClose={this.closeModal}
          >
            <div className="note-delete-content">
              <p className="delete-typo"> Your comment will be deleted.</p>
              <div className="actions">
                <div className="cancel">
                  <MainButton
                    onClick={this.closeModal}
                    label="Cancel"
                    className="secondary"
                  />
                </div>
                <MainButton
                  onClick={() => {
                    this.props.deleteNote(
                      this.props.dataSet.uid,
                      this.props.dataSet.objectUid
                    );
                    this.closeModal();
                  }}
                  label="Delete"
                  icon={<HiTrash />}
                  className="alert"
                />
              </div>
            </div>
          </NewModal>
        )}
      </>
    );
  }
}

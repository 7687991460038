import React, { Component } from "react";
import PageHeader from "../../common/pageElements/pageHeader";
import TimesheetDetail from "./tsdetails";

class Timesheet extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <PageHeader title="My Timesheet" />
        <div className="hub-page" style={{ backgroundColor: "#f5f7fa" }}>
          <TimesheetDetail />
        </div>
      </>
    );
  }
}

export default Timesheet;

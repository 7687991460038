import React, { Component } from "react";
import CardDetailSection from "../comps/CardDetailSection";
import MainButton from "../../../../common/buttons/MainButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faClipboardListCheck,
  faPerson,
  faCheck,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import DynamicLookup from "../../../../common/inputControls/lookup/DynamicLookup";
import NumberField from "../../../../common/inputControls/numberField";
import { Utils } from "../../../../common/Commons";
export default class NewAssignment extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.state = {
      mode: "new",
      showPanel: false,
      errorMessages: {
        owner: { message: "" },
        finishDate: { message: "" },
        name: { message: "", error: false },
      },
      requiredFields: [{ id: "owner", label: "Resource" }],
      formData: {
        taskId: "",
        status: {},
        etc: 0,
      },
    };
  }

  handleChanges = (e, val) => {
    //console.log("handleChanges",e,val)
    let items = { ...this.state.formData };
    items[e] = val;

    if (e === "owner") {
      let errors = { ...this.state.errorMessages };
      errors.owner.message = "";
      items.role = val.roleInfo;
      this.setState({ ...this.state, formData: items, errorMessages: errors });
    } else {
      this.setState({ ...this.state, formData: items });
    }
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.assignment?.uid !== prevProps.assignment?.uid) {
      this.initialize();
    }
  }

  initialize = () => {
    let assignment = this.props.taskResource.find(
      (y) => y.uid === this.props.assignment?.uid
    );
    let form = {
      taskId: this.props.task.uid,
      status: {
        uid: 31,
        code: "not_started",
        name: "Not Started",
        color: null,
      },
      owner: null,
      actualUnit: 0,
      remainingUnit: null,
      startDate: this.props.task.startDate,
      finishDate: this.props.task.finishDate,
    };
    if (assignment !== undefined) {
      form.owner = assignment?.owner;
      form.actualUnit = assignment?.actualUnit;
      form.remainingUnit = assignment?.remainingUnit;
      form.role = assignment?.role;
      //form.uid=assignment?.uid;
    }

    //console.log("componentDidMount",assignment,this.props)

    this.setState({
      ...this.state,
      Loading: false,
      Status: "Load",
      formData: form,
    });
  };

  assignmentOperations = (Method) => {
    const u = new Utils();
    if (
      Method == "POST" &&
      (this.state.formData.owner === null ||
        this.state.formData.remainingUnit <= 0)
    ) {
      if (this.state.formData.owner === null) {
        u.addNotification("error", "Resource Must Selected");
      } else if (this.state.formData.remainingUnit <= 0) {
        u.addNotification("error", "Remaining Must Be Higher Than 0");
      }
    } else if (Method == "DELETE" && this.props.assignment?.actualUnit > 0) {
      u.addNotification("error", "Record has actuals cannot be deleted");
    } else if (Method == "PATCH" && this.state.formData.remainingUnit <= 0) {
      u.addNotification("error", "Remaining Must Be Higher Than 0");
    } else {
      //console.log(Method,this.props.columnIndex,this.props.task.uid,this.props.assignment.uid,this.props.assignmentIndex,this.state.formData)
      this.props.assignmentOperations(
        Method,
        this.props.columnIndex,
        this.props.task.uid,
        this.props.assignment?.uid,
        this.props?.assignmentIndex,
        this.state.formData
      );
      u.addNotification(
        "success",
        this.props.assignmentType === "edit" ? "Updated" : "Created"
      );
      //this.props.closePanel();

      this.props.setAssignmentTab(
        "newAssignment",
        "edit",
        this.props.assignment
      );
    }
  };

  render() {
    //console.log("NewAssignment",this.props)

    if (this.props.activeTab === "newAssignment") {
      return (
        <CardDetailSection
          markerLeft={this.props.markerLeft}
          key="cardToDoSection"
        >
          <div className="card-section-header">
            <div className="title">
              <span>
                <FontAwesomeIcon icon={faPerson} />
              </span>
              <span>Assignments</span>
            </div>
            <div className="button-bar">
              {this.state.mode === "new" ? (
                <>
                  <MainButton
                    style={{ marginRight: 0 }}
                    onClick={() =>
                      this.assignmentOperations(
                        this.props.assignmentType === "edit" ? "PATCH" : "POST"
                      )
                    }
                    label="Save"
                    className="new-plain-button"
                  />
                  {/*  <MainButton style={{marginRight:0}}icon={faCheck}  onClick={()=>this.assignmentOperations("DELETE")} label="Delete" className="new-plain-button" />
                   */}
                  <MainButton
                    style={{ marginRight: 0 }}
                    label="Cancel"
                    onClick={() => this.props.closePanel()}
                    className="new-plain-button"
                  />
                </>
              ) : (
                false
              )}
            </div>
          </div>
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
              ></div>
              <DynamicLookup
                endpoint={
                  "/api/resource/assignmentNotInOwner/" + this.props.task?.uid
                }
                onChange={this.handleChanges}
                formId="owner"
                searchAttribute="fullName"
                valueAttribute="uid"
                labelAttribute="fullName"
                width="100%"
                label="Resource"
                required={true}
                value={{
                  value: this.state.formData?.owner?.uid,
                  label: this.state.formData?.owner?.fullName,
                }}
                autoFocus
                errorMessage={this.state.errorMessages["owner"].message}
                disabled={this.props.assignmentType === "edit" ? true : false}
                error={this.state?.errorMessages["owner"]?.error}
              />

              <DynamicLookup
                endpoint="/api/resourcerole"
                onChange={this.handleChanges}
                formId="role"
                searchAttribute="name"
                valueAttribute="uid"
                labelAttribute="name"
                width="100%"
                label="Assignment Role"
                value={{
                  value: this.state?.formData?.role?.uid,
                  label: this.state?.formData?.role?.name,
                }}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: 10,
                  marginRight: 0,
                }}
              >
                {this.props.assignmentType === "edit" ? (
                  <NumberField
                    width="47%"
                    onChange={this.handleChanges}
                    changeArea="actualUnit"
                    value={this.state.formData?.actualUnit?.toString()}
                    disabled
                    label="Actual Units"
                    required={true}
                  />
                ) : (
                  false
                )}

                <NumberField
                  width={this.props.assignmentType === "edit" ? "47%" : "100%"}
                  onChange={this.handleChanges}
                  changeArea="remainingUnit"
                  label="Remaining Unit (h)"
                  required={true}
                  value={
                    this.state.formData?.remainingUnit
                      ? this.state.formData?.remainingUnit.toString()
                      : "0"
                  }
                  style={{ marginRight: 0 }}
                />
              </div>
            </div>
          </div>
        </CardDetailSection>
      );
    } else {
      return false;
    }
  }
}
